import { useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Form, Header, Input, Segment } from 'semantic-ui-react';
import styled from 'styled-components';

import { DatasetStatsFilters, useGetDatasetRowCountQuery, useGetDatasetStatsQuery } from 'src/api/datasets';
import { Header as PageHeader, HorizontalDivider, Row } from 'src/styles';
import theme from 'src/styles/theme';
import { Dataset } from 'src/types';
import PieChartWithLegend from '../../reporting/stats/PieChartWithLegend';

const TooltipContent = styled.div`
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;

  p {
    margin: 0 !important;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    color: ${theme.primary};
  }
`;

type Props = {
  dataset: Dataset;
};

const errorLabels: Record<string, string> = {
  // billing: 'Billing',
  Total: 'Total',
  blacklist: 'Blacklisted',
  carrier: 'Carrier Excluded',
  connect: 'Connect Excluded',
  // cross_duplicate: 'Cross Duplicate',
  // duplicate: 'Duplicate',
  // filter: 'Filter',
  // invalid: 'Invalid',
  // landline: 'Landline',
  leadscore: 'Leadscore Failed',
  // required: 'Required',
  // unknown: 'Unknown',
  // '': 'No error',
};

const DatasetV2Stats = ({ dataset }: Props) => {
  const [filters, setFilters] = useState<DatasetStatsFilters>({
    dateRange: { start: null, end: null },
  });

  const { data: counts, isLoading: countsLoading } = useGetDatasetRowCountQuery(dataset.id);
  const { data: stats, isLoading: statsLoading } = useGetDatasetStatsQuery({
    datasetId: dataset.id,
    dateRange: filters.dateRange,
  });

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setFilters(prevFilter => ({ ...prevFilter, dateRange: { start, end } }));
  };

  const countsData = useMemo(() => {
    if (!counts) return [];

    return [
      {
        label: 'Dialable',
        count: counts.dialable ?? 0,
      },
      { label: 'Final', count: counts.final ?? 0 },
      { label: 'Carrier Excluded', count: counts.carriers ?? 0 },
      { label: 'Connects Excluded', count: counts.connects ?? 0 },
      { label: 'Leadscore Failed', count: counts.leadscores ?? 0 },
      { label: 'API Rejected', count: counts.rejected ?? 0 },
    ];
  }, [counts]);

  const masterStatusesCountsData = useMemo(() => {
    if (!stats) return [];

    const rawData = [];
    for (const name in stats.statuses) {
      rawData.push({
        label: name,
        count: stats.statuses[name],
      });
    }

    return rawData;
  }, [stats]);

  const [leadscoreData, showLeadscoreCounts] = useMemo(() => {
    if (!stats) return [[], false];

    const rawData = [];
    for (const name in stats.range) {
      rawData.push({
        name,
        count: stats.range[name],
      });
    }

    const show = rawData.some(data => Number(data.count) > 0);

    return [rawData, show];
  }, [stats]);

  const funnelData = useMemo(() => {
    if (!stats) return [];

    const total = Object.values(stats.errorsCodes).reduce((sum, count) => sum + count, 0);

    const rawData = [
      {
        label: 'Total',
        residual: total,
        count: total,
      },
    ];

    let residualRecords = total;
    for (const name in stats.errorsCodes) {
      if (!name) continue;

      residualRecords -= stats.errorsCodes[name] ?? 0;
      rawData.push({
        label: errorLabels[name],
        residual: residualRecords,
        count: stats.errorsCodes[name ?? ''],
      });
    }

    rawData.push({
      label: 'Remaining',
      residual: residualRecords,
      count: residualRecords,
    });

    return rawData;
  }, [stats]);

  return (
    <Segment>
      <PageHeader style={{ marginBottom: 0 }}>Dataset Stats</PageHeader>

      <HorizontalDivider style={{ margin: '1rem -1rem' }} />

      <Form>
        <Form.Group>
          <Form.Field>
            <label>Date Range</label>
            <DatePicker
              selected={filters.dateRange.start}
              onChange={handleDateChange}
              selectsRange
              startDate={filters.dateRange.start}
              endDate={filters.dateRange.end}
              customInput={<Input icon="calendar" iconPosition="left" />}
            />
          </Form.Field>
        </Form.Group>
      </Form>

      {showLeadscoreCounts && (
        <div style={{ height: 300, marginBottom: '2rem' }}>
          <Header textAlign="center">Leadscore</Header>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={leadscoreData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip formatter={(value: number) => value.toLocaleString()} />
              <Legend />
              <Bar dataKey="count" fill={theme.primary} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}

      <Row style={{ justifyContent: 'space-around' }}>
        <div>
          <Header textAlign="center">Penetration</Header>
          <PieChartWithLegend data={countsData} loading={countsLoading} />
        </div>
        <div>
          <Header textAlign="center">Master Statuses</Header>
          <PieChartWithLegend data={masterStatusesCountsData} loading={statsLoading} />
        </div>
      </Row>

      <div style={{ height: 300, marginBottom: '2rem' }}>
        <Header textAlign="center">Error Funnel</Header>
        {/* <FunnelChartWithLegend data={funnelData} loading={statsLoading} /> */}
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={funnelData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" />
            <YAxis />
            <Tooltip
              content={(props: any) => (
                <TooltipContent>
                  <p>{props.payload[0]?.payload.label || ''}</p>
                  <ul>
                    <li>Count: {(props.payload[0]?.payload.count || 0).toLocaleString()}</li>
                    <li>Remaining: {(props.payload[0]?.payload.residual || 0).toLocaleString()}</li>
                  </ul>
                </TooltipContent>
              )}
            />
            {/* <Legend /> */}
            <Bar dataKey="residual" fill={theme.primary} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Segment>
  );
};

export default DatasetV2Stats;
