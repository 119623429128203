import { useCallback, useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';

import { useGetTelnyxJobsRecentQuery, useRefetchTelnyxPhonesMutation } from 'src/api/auth/account-telnyx';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { JobStatus } from 'src/types';

const TelnyxPhonesRefetchButton = () => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync, isLoading: mutateLoading } = useRefetchTelnyxPhonesMutation();
  const { data: job, isLoading: jobLoading, refetch } = useGetTelnyxJobsRecentQuery();

  const activeJobRunning = job?.status === JobStatus.Processing || job?.status === JobStatus.Pending;

  const loading = mutateLoading || jobLoading || activeJobRunning;

  useEffect(() => {
    let timeout: number | null = null;
    if (activeJobRunning) {
      timeout = setInterval(refetch, 3000);
    }
    return () => {
      if (timeout !== null) {
        clearInterval(timeout);
        timeout = null;
      }
    };
  }, [activeJobRunning, refetch]);

  const onClick = useCallback(async () => {
    setApiMessage(undefined);
    try {
      await mutateAsync();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
      setTimeout(() => setApiMessage(undefined), 3000);
    }
  }, [mutateAsync]);

  const jobStage = job && job.stage;
  let jobProgress = 0;
  switch (jobStage) {
    case 1:
      jobProgress = job?.progress1 || 0;
      break;
    case 2:
      jobProgress = job?.progress2 || 0;
      break;
    case 3:
      jobProgress = job?.progress3 || 0;
      break;
  }

  return (
    <Button
      type="button"
      title="Refetch Data from Telnyx"
      onClick={!loading ? onClick : undefined}
      color={(apiMessage && !apiMessage.success && 'red') || undefined}
    >
      <Icon name={apiMessage?.message ? 'warning sign' : 'sync alternate'} loading={loading} />
      {apiMessage?.message || (loading ? `Stage ${jobStage} - ${(jobProgress * 100).toFixed(0)}%` : 'Refetch')}
    </Button>
  );
};

export default TelnyxPhonesRefetchButton;
