import { useQuery } from 'react-query';

import { HubspotDeal } from 'src/types';
import { ApiResponse, http } from '../http-common';

export type GetHubspotDealsResponse = ApiResponse & {
  deals: HubspotDeal[];
  paging: {
    next: {
      after: string;
      link: string;
    };
  };
};

export type GetHubspotDealsFilters = {
  search?: string;
};

export type GetHubspotDealsParams = GetHubspotDealsFilters & {
  limit: number;
  after: string;
};

export const useGetHubspotDealsQuery = (params: GetHubspotDealsParams) => {
  return useQuery(['admin/hubspot', 'deals', params], async () => {
    const res = await http.get<GetHubspotDealsResponse>('/api/admin/hubspot/deals', { params });
    return res.data;
  });
};
