import { isEmpty } from 'lodash';
import { ChangeEvent, useCallback, useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import {
  CreateSendgridInboundParseSettingInput,
  useCreateSendgridInboundParseSettingMutation,
} from 'src/api/auth/account-sendgrid';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';

type ValidationErrors = {
  name?: string;
};

const AddSendgridInboundParseSetting = () => {
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { isLoading, mutateAsync } = useCreateSendgridInboundParseSettingMutation();
  const [formdata, setFormdata] = useState<CreateSendgridInboundParseSettingInput>({ name: '' });

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  const onSubmit = async () => {
    setApiMessage(undefined);

    const validationErrors: ValidationErrors = {};

    if (formdata.name === '') {
      validationErrors.name = 'Name is required';
    }

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors);
      return;
    }

    try {
      await mutateAsync(formdata);
      setOpen(false);
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        <Button type="button" color="blue">
          <Icon name="plus" />
          Add Setting
        </Button>
      }
    >
      <Modal.Header>Create Inbound Parse Setting</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit} onChange={onChange}>
          <Form.Input label="Name" error={errors.name} name="name" />

          <Form.Button loading={isLoading} color="blue" fluid>
            Create
          </Form.Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AddSendgridInboundParseSetting;
