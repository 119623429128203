import { Divider, Grid, Header, Label, Message, Segment, Table } from 'semantic-ui-react';

import { BigqueryTable } from 'src/types';

const betaUrl = process.env.REACT_APP_BETA_URL;

type Props = {
  table: BigqueryTable;
  token: string;
};

const DatasetDocs = ({ table, token }: Props) => {
  return (
    <Grid style={{ marginTop: 0 }}>
      <Grid.Row>
        <Grid.Column width={10}>
          <Segment>
            <Header as="h3">Send a single record</Header>

            <p>
              The following is a simple example of a POST with JSON data. This endpoint also accepts query string
              parameters and the following content-types: <code>multipart/form-data</code>,{' '}
              <code>application/x-www-form-urlencoded</code>, and <code>application/json</code>.
            </p>

            <p>
              <strong>Please send all data in string format.</strong> The following example contains only a few fields
              to demonstrate the JSON formatting. This dataset may have additional columns defined. You can view the
              full schema on the right side of the page. If you need additional columns added to your dataset, please
              contact an admin.
            </p>

            <p>
              If the data you are sending contains a column that is not defined in your dataset, the data will be
              ignored and the columns will be listed in the <code>ignoredColumns</code> property of the response.
            </p>

            <Message>
              <pre>
                POST {`${betaUrl}/api/datasets/${table.slug}`}
                <br />
                Authorization: Bearer {token}
                <br />
                Content-type: application/json
                <br />
                <br />
                {`{\n    "firstname": "Joe",\n    "lastname": "Sample",\n    "zip": "12345",\n    "phone": "5035555555"\n}`}
              </pre>
            </Message>

            <Divider />

            <Header as="h3">Send records in bulk</Header>

            <Message>
              <pre>
                POST {`${betaUrl}/api/datasets/${table.slug}/bulk`}
                <br />
                Authorization: Bearer {token}
                <br />
                Content-type: multipart/form-data
                <br />
                <br />
                file=@text.csv
              </pre>
            </Message>

            <p>
              <strong>Sample CSV Data</strong>
            </p>

            <Message>
              <pre>
                firstname,lastname,zip,phone
                <br />
                Joe,Sample,12345,5035555555
                <br />
                Bob,White,44444,9712223333
                <br />
                Lily,Hoyt,98765,5096667777
              </pre>
            </Message>

            <Divider />

            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h3">Success response</Header>

                  <Message success>
                    <pre>
                      Status Code: 200 OK
                      <br />
                      Content-type: application/json
                      <br />
                      <br />
                      {`{\n    "success": true,\n    "insertCount": 1\n}`}
                    </pre>
                  </Message>

                  <Message success>
                    <pre>
                      Status Code: 200 OK
                      <br />
                      Content-type: application/json
                      <br />
                      <br />
                      {`{\n    "success": true,\n    "insertCount": 1,\n    "message": "Warning: some data was not imported...",\n    "ignoredColumns": ["foo"]\n}`}
                    </pre>
                  </Message>
                </Grid.Column>

                <Grid.Column>
                  <Header as="h3">Error responses</Header>

                  <Message error>
                    <pre>
                      Status Code: 400 Bad Request
                      <br />
                      Content-type: application/json
                      <br />
                      <br />
                      {`{\n    "success": false,\n    "message": "Invalid dataset"\n}`}
                    </pre>
                  </Message>

                  <Message error>
                    <pre>
                      Status Code: 403 Forbidden
                      <br />
                      Content-type: application/json
                      <br />
                      <br />
                      {`{\n    "success": false,\n    "message": "Forbidden"\n}`}
                    </pre>
                  </Message>

                  <Message error>
                    <pre>
                      Status Code: 422 Unprocessable Entity
                      <br />
                      Content-type: application/json
                      <br />
                      <br />
                      {`{\n    "success": false,\n    "message": "Unprocessable Entity"\n}`}
                    </pre>
                  </Message>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Grid.Column>
        <Grid.Column width={6}>
          <Segment>
            <Header as="h3">Schema</Header>

            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Column</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Required</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {(table.columns || [])
                  .filter(c => c.name !== '__created_at')
                  .map(c => (
                    <Table.Row key={c.name}>
                      <Table.Cell style={{ fontWeight: c.required ? 'bold' : undefined }}>{c.name}</Table.Cell>
                      <Table.Cell>{c.type}</Table.Cell>
                      <Table.Cell>
                        <Label color={c.required ? 'green' : undefined}>{c.required ? 'Yes' : 'No'}</Label>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>

            <Header as="h3">Notes</Header>

            <p>
              As noted in the documentation on the left, <strong>please send all data in string format.</strong>{' '}
              Although the data is accepted only as strings, validation rules will be applied based on the field type
              defined in the schema above. If validation fails for any field, the data in that field may be lost but the
              row will still be inserted to the dataset.
            </p>

            <p>
              <strong>Data Validation</strong>
            </p>

            <ul>
              <li>
                <code>TIMESTAMP</code> data should be formatted: <code>YYYY-MM-DD HH:MM[:SS[.SSSSSS]]</code>
              </li>
            </ul>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default DatasetDocs;
