import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { useCreateVoiceIntegrityTrustProductMutation } from 'src/api/auth/account-twilio';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { UseCaseOptions } from 'src/config';

type ValidationErrors = {
  friendlyName?: string;
  avgDailyCalls?: string;
  employeeCount?: string;
  notes?: string;
  useCase?: string;
};

type Formdata = {
  friendlyName: string;
  avgDailyCalls: string;
  employeeCount: string;
  notes: string;
  useCase: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
};

const TrustHubVoiceIntegrityModal = ({ open, onClose }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [formdata, setFormdata] = useState<Formdata>({
    friendlyName: '',
    avgDailyCalls: '',
    notes: '',
    employeeCount: '',
    useCase: '',
  });
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { data: user } = useGetUserProfileQuery();
  const { mutateAsync, isLoading: createLoading } = useCreateVoiceIntegrityTrustProductMutation();

  const handleClose = useCallback(() => {
    setApiMessage(undefined);
    setFormdata({ friendlyName: '', avgDailyCalls: '', notes: '', employeeCount: '', useCase: '' });
    setErrors({});

    onClose();
  }, [onClose]);

  const validate = useCallback((input: Formdata): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    if (!input.useCase) validationErrors.useCase = 'Required';

    if (!input.avgDailyCalls) validationErrors.avgDailyCalls = 'Required';
    else if (Number.isNaN(Number(input.avgDailyCalls))) validationErrors.avgDailyCalls = 'Must be a number';
    else if (Number(input.avgDailyCalls) < 0) validationErrors.avgDailyCalls = 'Must be greater than 0';

    if (!input.employeeCount) validationErrors.employeeCount = 'Required';
    else if (Number.isNaN(Number(input.employeeCount))) validationErrors.employeeCount = 'Must be a number';
    else if (Number(input.employeeCount) < 0) validationErrors.employeeCount = 'Must be greater than 0';

    // if (!input.notes) validationErrors.notes = 'Required';

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onChange = useCallback((_, { name, value }) => {
    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    if (!isEmpty(validate(formdata))) return;

    try {
      const { reviewStatus } = await mutateAsync({
        friendlyName: formdata.friendlyName,
        useCase: formdata.useCase,
        avgDailyCalls: Number(formdata.avgDailyCalls),
        employeeCount: Number(formdata.employeeCount),
        notes: formdata.notes,
      });

      setApiMessage({
        success: true,
        status: 200,
        message: `Profile created successfully. Review status: ${reviewStatus}`,
      });
    } catch (e) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [formdata, mutateAsync, validate]);

  let friendlyName = '';
  const account = user?.active_account;
  if (account && account.name) {
    friendlyName = account.name;
  }
  const customerProfile = account?.business_profile;
  if (customerProfile && customerProfile.business_name) {
    friendlyName = customerProfile.business_name;
  }

  return (
    <Modal open={open} size="tiny" onClose={handleClose}>
      <Modal.Header>Voice Integrity</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        {apiMessage?.success ? (
          <Button type="button" onClick={handleClose} fluid color="green">
            Done
          </Button>
        ) : (
          <Form onSubmit={onSubmit}>
            <Form.Input
              name="friendlyName"
              label="Friendly Name"
              placeholder={friendlyName}
              value={formdata.friendlyName}
              onChange={onChange}
              error={errors.friendlyName}
            />

            <Form.Select
              name="useCase"
              label="Use Case"
              value={formdata.useCase}
              options={UseCaseOptions}
              onChange={onChange}
              error={errors.useCase}
            />

            <Form.Input
              name="avgDailyCalls"
              label="Avg. Business Day Call Volume"
              value={formdata.avgDailyCalls}
              onChange={onChange}
              error={errors.avgDailyCalls}
            />

            <Form.Input
              name="employeeCount"
              label="Business Employee Count"
              value={formdata.employeeCount}
              onChange={onChange}
              error={errors.employeeCount}
            />

            <Form.Input
              name="notes"
              label="Additional Notes"
              value={formdata.notes}
              onChange={onChange}
              error={errors.notes}
            />

            <Button fluid color="blue" loading={createLoading}>
              Create
            </Button>
          </Form>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default TrustHubVoiceIntegrityModal;
