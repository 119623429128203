import { useCallback, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { useListenToVoiceRecordingMutation } from 'src/api/voice-recordings';
import { VoiceCallDetailRecord } from 'src/types';

const AudioContainer = styled.div`
  position: relative;
  audio {
    position: absolute;
    top: 0;
    right: 50px;
    z-index: 1;
    height: 100%;
  }
`;

const AudioPlayer = ({ recording }: { recording: VoiceCallDetailRecord }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { mutateAsync } = useListenToVoiceRecordingMutation();

  const onClick = useCallback(async () => {
    setIsVisible(prev => !prev);
    try {
      await mutateAsync(recording.id);
    } catch (e) {
      console.error(e);
    }
  }, [mutateAsync, recording.id]);

  return (
    <AudioContainer>
      <Button icon onClick={onClick}>
        <Icon name={isVisible ? 'close' : 'play'} />
      </Button>
      {isVisible && <audio autoPlay controls src={recording.recordingUrl} />}
    </AudioContainer>
  );
};

export default AudioPlayer;
