import { isEmpty } from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  DropdownItemProps,
  DropdownProps,
  Form,
  Header,
  Icon,
  InputOnChangeData,
  Label,
  Popup,
} from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';

import { useGetUserProfileQuery } from 'src/api/auth';
import { useListDatasetsQuery } from 'src/api/datasets';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useSaveWebhookConfigMutation } from 'src/api/webhooks';
import ApiMessage from 'src/components/ApiMessage';
import BodyTemplateEditor from 'src/components/BodyTemplateEditor';
import { DsMetadataPropertyOptions } from 'src/data';
import { Note } from 'src/styles';
import {
  BaseSchema,
  LeadscoreDefaultRates,
  newWebhookResponseField,
  newWebhookTransformation,
  RequestContentTypes,
  RequestMethods,
  ResponseContentTypes,
  WebhookConfig,
  WebhookFieldTimeFormats,
  WebhookFieldTypes,
} from 'src/types';
import { formatWebhookConfig } from 'src/utils';
import { Layout } from './style';
import WebhookRemoveFieldModal from './WebhookRemoveFieldModal';

const FieldTypeOptions: DropdownItemProps[] = WebhookFieldTypes.map(t => ({ key: t, value: t, text: t }));

const TimeFormatOptions: DropdownItemProps[] = WebhookFieldTimeFormats.map(f => ({ key: f, value: f, text: f }));

type ValidationErrors = {
  // General
  name?: string;
  enabled?: string;
  rejectOnCustomerNameNotFound?: string;
  customFields?: { [id: string]: string };
  dataEnrichment?: string;
  // Request
  url?: string;
  method?: string;
  timeout?: string;
  contentType?: string;
  bodyTemplate?: string;
  successText?: string;
};

const getInitialFormdata = (config: WebhookConfig): WebhookConfig => {
  const next = _cloneDeep(config);

  // Set any missing defaults and/or apply data type conversions
  // if (next.schedule && !next.schedule.timezone) {
  //   next.schedule.timezone = 'America/New_York';
  // }

  return next;
};

type Props = {
  config: WebhookConfig;
  initialHeaders: { [key: string]: string };
  webhookConfigData: { [key: string]: string };
};

interface Pair {
  key: string;
  value: string;
}

type HTTPHeader = Pair;
type ConfigDataPair = Pair;

const EditWebhookConfigForm = ({ config, initialHeaders, webhookConfigData }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [formdata, setFormdata] = useState<WebhookConfig>(() => getInitialFormdata(config));
  const [saved, setSaved] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [viewErrors, setViewErrors] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({} as ValidationErrors);
  const { data: user } = useGetUserProfileQuery();
  const { data: datasets } = useListDatasetsQuery({ limit: 500, offset: 0 });
  const { mutateAsync, isLoading: saveLoading } = useSaveWebhookConfigMutation();

  const accountLeadscoreRates = user?.active_account?.billing.leadscorePlus.rates;

  const convertPairs = (h: { [key: string]: string }): Pair[] => {
    if (h == null) {
      return [];
    }

    const keys = Object.keys(h);
    return keys.map(k => {
      return { key: k, value: h[k] };
    });
  };

  const [headers, setHeaders] = useState<HTTPHeader[]>(convertPairs(initialHeaders));
  const [configData, setConfigData] = useState<ConfigDataPair[]>(convertPairs(webhookConfigData));

  // TODO: This does not work when using back/forward browser buttons
  // At least in Chrome, it only prevents page close and reload
  useEffect(() => {
    const preventNavigation = (e: any) => {
      e.preventDefault();
      e.returnValue = '';
      return '';
    };

    if (!saved) {
      window.addEventListener('beforeunload', preventNavigation);
    }

    return () => {
      window.removeEventListener('beforeunload', preventNavigation);
    };
  }, [saved]);

  useEffect(() => {
    if (!config) return;
    setFormdata(getInitialFormdata(config));
  }, [config]);

  const validate = useCallback(
    (c: WebhookConfig) => {
      let errors = {} as ValidationErrors;

      // General
      if (!c.name.trim()) {
        errors.name = 'Name is required';
      }

      const customFieldErrors: { [id: string]: string } = {};
      c.custom_fields?.forEach(f => {
        if (!f.name) {
          customFieldErrors[f.id] = 'Name is required';
        }
        if (!!BaseSchema.find(baseField => f.name === baseField.name)) {
          customFieldErrors[f.id] = 'Name already in use';
        }
        if (
          f.name &&
          !!(c.custom_fields || []).find(customField => f.name === customField.name && f.id !== customField.id)
        ) {
          customFieldErrors[f.id] = 'Name already in use';
        }
      });
      if (!isEmpty(customFieldErrors)) {
        errors.customFields = customFieldErrors;
      } else {
        delete errors.customFields;
      }

      if (!c.request.url) {
        errors.url = 'URL is required';
      } else if (!/http(s)?:\/\//.test(c.request.url)) {
        errors.url = 'URL must start with http:// or https://';
      }

      if (!c.request.method) {
        errors.method = 'Method is required';
      }

      if (!c.advanced && !c.request.content_type) {
        errors.contentType = 'Content-Type is required';
      }

      const isValid = isEmpty(errors);

      if (!viewErrors) {
        errors = {} as ValidationErrors;
      }

      setErrors(errors);
      setIsValid(isValid);

      return isValid;
    },
    [setIsValid, viewErrors]
  );

  const saveConfig = useCallback(
    async (c: WebhookConfig, headers: HTTPHeader[], force = false) => {
      if (saved && !force) {
        return;
      }
      const newHeaders: { [key: string]: string } = {};
      for (const header of headers) {
        if (header.key !== '') {
          newHeaders[header.key as keyof typeof newHeaders] = header.value;
        }
      }
      c.request.headers = newHeaders;

      const newCongfigData: { [key: string]: string } = {};
      for (const data of configData) {
        if (data.key !== '') {
          newCongfigData[data.key as keyof typeof newCongfigData] = data.value;
        }
      }
      c.data = newCongfigData;

      setApiMessage(undefined);

      const config = formatWebhookConfig(c);

      try {
        await mutateAsync({ config });
        setSaved(true);
      } catch (e: any) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [configData, mutateAsync, saved]
  );

  useEffect(() => {
    validate(formdata);
  }, [formdata, validate, headers]);

  const onChange = useCallback((_: any, { checked, name, value }: any) => {
    setFormdata(prev => {
      // Convert numeric values to numbers
      const v = typeof checked !== 'undefined' ? checked : value;
      const next = _cloneDeep(prev);
      _set(next, name, v);
      return next;
    });
    setSaved(false);
  }, []);

  const toggleViewErrors = () => setViewErrors(prev => !prev);

  const FromColumnOptions = useMemo(() => {
    const cols: string[] = [...BaseSchema.map(c => c.name), ...DsMetadataPropertyOptions.map(o => o.value as string)];

    if (datasets && datasets.data.length > 0) {
      for (const d of datasets.data) {
        if (d.custom_fields === null) continue;
        for (const cf of d.custom_fields) {
          cols.push(cf.name);
        }
      }
    }

    if (formdata.custom_fields && formdata.custom_fields.length > 0) {
      formdata.custom_fields.forEach(f => {
        cols.push(f.name);
      });
    }

    return [...new Set(cols.filter(Boolean))].map(col => ({ key: col, value: col, text: col }));
  }, [formdata, datasets]);

  const setHeaderKey = (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const clone = [...headers];
    clone[data.name].key = data.value;
    setHeaders(clone);
    setSaved(false);
  };

  const setHeaderValue = (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const clone = [...headers];
    clone[data.name].value = data.value;
    setHeaders(clone);
    setSaved(false);
  };

  const setConfigDataKey = (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const clone = [...configData];
    clone[data.name].key = data.value;
    setConfigData(clone);
    setSaved(false);
  };

  const setConfigDataValue = (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const clone = [...configData];
    clone[data.name].value = data.value;
    setConfigData(clone);
    setSaved(false);
  };

  const addCustomField = useCallback(() => {
    setFormdata(prev => {
      const next = _cloneDeep(prev);
      _set(next, 'custom_fields', [...(next.custom_fields || []), { id: uuid(), type: 'STRING' }]);
      return next;
    });
    setSaved(false);
  }, []);

  const removeCustomField = useCallback((fieldId: string) => {
    setFormdata(prev => {
      const next = _cloneDeep(prev);
      _set(
        next,
        'custom_fields',
        (next.custom_fields || []).filter(f => f.id !== fieldId)
      );
      return next;
    });
    setSaved(false);
  }, []);

  const updateCustomField = useCallback(
    (id: string) =>
      (_: any, { name, value }: InputOnChangeData | DropdownProps) => {
        setFormdata(prev => {
          const next = _cloneDeep(prev);

          _set(
            next,
            `custom_fields`,
            (next.custom_fields || []).map(f => (f.id === id ? { ...f, [name]: value } : f))
          );

          return next;
        });
        setSaved(false);
      },
    []
  );

  return (
    <Form style={{ position: 'relative' }} onSubmit={() => saveConfig(formdata, headers, true)}>
      <ApiMessage data={apiMessage} />

      <div
        style={{ position: 'absolute', top: '0', right: '0', zIndex: 100, display: 'flex', justifyContent: 'flex-end' }}
      >
        {isValid ? (
          <Button size="mini" compact color="green" style={{ marginLeft: '0.5rem' }} type="button">
            <Icon name="check" />
            Valid
          </Button>
        ) : (
          <Popup
            trigger={
              <Button
                size="mini"
                compact
                color="red"
                style={{ marginLeft: '0.5rem' }}
                type="button"
                onClick={toggleViewErrors}
              >
                <Icon name="dont" />
                Invalid
                <Icon name={viewErrors ? 'eye' : 'eye slash'} style={{ marginLeft: '0.5rem', marginRight: 0 }} />
              </Button>
            }
          >
            {viewErrors ? 'Hide' : 'Show'} validation errors
          </Popup>
        )}

        <Button size="mini" compact color={saveLoading ? 'blue' : saved ? 'green' : 'red'} style={{ margin: 0 }}>
          {saveLoading ? <Icon name="spinner" loading /> : <Icon name={saved ? 'check' : 'dont'} />}
          {saveLoading ? 'Saving...' : saved ? 'Saved' : 'Unsaved'}
        </Button>
      </div>

      <Layout>
        <div style={{ gridArea: 'general' }}>
          <Header>General</Header>

          <Form.Input label="Name" name="name" value={formdata.name} onChange={onChange} error={errors.name} />
          <Form.Checkbox
            label="Enabled"
            name="enabled"
            toggle
            checked={formdata.enabled}
            onChange={onChange}
            error={errors.enabled}
          />
          <Form.Checkbox
            toggle
            label="Advanced"
            name="advanced"
            checked={formdata.advanced || false}
            onChange={onChange}
          />
          <Form.Checkbox
            toggle
            label="Append Customer Data"
            name="appendCustomerData"
            checked={formdata.appendCustomerData || false}
            onChange={onChange}
          />
          <Form.Checkbox
            label="Reject on customer name not found"
            name="reject_on_customer_not_found"
            toggle
            checked={formdata.reject_on_customer_not_found}
            onChange={onChange}
            error={errors.rejectOnCustomerNameNotFound}
          />

          <Header>Custom Fields</Header>
          {(formdata.custom_fields || []).map(f => {
            return (
              <Form.Group key={f.id} widths="equal">
                <Form.Select
                  fluid
                  label="Type"
                  placeholder="Type"
                  name="type"
                  options={FieldTypeOptions}
                  onChange={updateCustomField(f.id)}
                  error={!f.type && 'Type is required'}
                  value={f.type}
                />

                <Form.Input
                  fluid
                  value={f.name}
                  name="name"
                  label="Name"
                  onChange={updateCustomField(f.id)}
                  // onBlur={() => updateCustomField(f.id)(null, { name: 'name', value: snakeCase(f.name) })}
                  error={errors.customFields?.[f.id]}
                />

                <WebhookRemoveFieldModal onRemoveField={removeCustomField} fieldId={f.id} />
              </Form.Group>
            );
          })}

          <Button
            type="button"
            compact
            size="mini"
            color="blue"
            style={{ marginBottom: '1rem' }}
            onClick={addCustomField}
          >
            <Icon name="plus" /> Add Custom Field
          </Button>

          <Header>Data Enrichment</Header>

          <Form.Group style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Form.Checkbox
              label="Enable Phone Lookup"
              name="data_enrichment"
              toggle
              checked={formdata.data_enrichment}
              onChange={onChange}
              error={errors.dataEnrichment}
            />
            <Form.Input
              placeholder={accountLeadscoreRates?.phoneLookup || LeadscoreDefaultRates.phoneLookup}
              size="mini"
              onChange={onChange}
              name="data_enrichment_rate"
              value={formdata.data_enrichment_rate || ''}
            />
          </Form.Group>
          <Note>
            With Data Enrichment enabled, we will attempt to lookup and append the customer's first and last name as
            well as their physical address (address1, city, state and zip).
          </Note>
          {formdata.data_enrichment && (
            <Form.Field>
              <Label color="blue">
                {formdata.data_enrichment_rate ||
                  accountLeadscoreRates?.phoneLookup ||
                  LeadscoreDefaultRates.phoneLookup}
                <Label.Detail>Per Lead</Label.Detail>
              </Label>
            </Form.Field>
          )}

          <Form.Checkbox
            label="Override existing data"
            name="data_enrichment_override"
            toggle
            checked={formdata.data_enrichment_override}
            onChange={onChange}
            error={errors.dataEnrichment}
          />
          <Note>
            Enrichment data will only be added into empty fields and will not overwrite any existing data found in the
            dataset unless the "Override existing data" toggle is enabled.
          </Note>
          <Note>
            Keep in mind that this also applies to data that was previously enriched, even if you disable phone lookup
            enrichment above.
          </Note>
        </div>

        <div style={{ gridArea: 'request' }}>
          <Header>Request</Header>
          <Form.Input
            placeholder="https://example.com/api/path"
            label="URL"
            value={formdata.request.url}
            name="request.url"
            onChange={onChange}
            error={errors.url}
          />
          <Form.Select
            placeholder="Select a method"
            label="Method"
            value={formdata.request.method}
            name="request.method"
            onChange={onChange}
            error={errors.method}
            clearable
            options={RequestMethods.map(m => ({ ...m, key: m.value }))}
          />
          <Form.Input
            label="Timeout (seconds)"
            value={formdata.request.timeout || ''}
            placeholder="3"
            type="number"
            name="request.timeout"
            onChange={onChange}
            error={errors.timeout}
          />
          {!formdata.advanced && (
            <Form.Select
              label="Content-Type"
              value={formdata.request.content_type}
              name="request.content_type"
              onChange={onChange}
              error={errors.contentType}
              clearable
              options={RequestContentTypes.map(t => ({ ...t, key: t.value }))}
            />
          )}
          {/* <Form.Input
            label="Body Template"
            value={formdata.request.body_template}
            name="request.body_template"
            onChange={onChange}
            error={errors.bodyTemplate}
          /> */}
          <Form.Input
            label="Success Text"
            value={formdata.request.success_text}
            name="request.success_text"
            onChange={onChange}
            error={errors.successText}
          />
          <Note>
            We will search for this text in the response of the API request to determine if it was successful.
          </Note>

          <Form.Field>
            <label>Headers</label>
            {headers.map((header, idx) => (
              <Form.Group key={idx} widths="equal">
                <Form.Input name={idx} placeholder="key" value={header.key} onChange={setHeaderKey} />
                <Form.Input name={idx} placeholder="value" value={header.value} onChange={setHeaderValue} />
                <Button
                  type="button"
                  color="red"
                  icon
                  onClick={() => {
                    setHeaders([...headers.slice(0, idx), ...headers.slice(idx + 1)]);
                    setSaved(false);
                  }}
                >
                  <Icon name="trash" />
                </Button>
              </Form.Group>
            ))}
            <Button
              type="button"
              compact
              size="mini"
              color="blue"
              onClick={() => {
                setHeaders([...headers, { key: '', value: '' }]);
              }}
            >
              <Icon name="plus" />
              Add Header
            </Button>
          </Form.Field>

          {!formdata.advanced && (
            <Form.Field>
              <label>Data</label>
              <Note>
                Add static parameters to the request. Each of the following key/value pairs will be added to the request
                data in addition to the customer data we are able to load from the database.
              </Note>

              {configData.map((data, idx) => (
                <Form.Group key={idx} widths="equal">
                  <Form.Input name={idx} placeholder="key" value={data.key} onChange={setConfigDataKey} />
                  <Form.Input name={idx} placeholder="value" value={data.value} onChange={setConfigDataValue} />
                  <Button
                    type="button"
                    color="red"
                    icon
                    onClick={() => {
                      setConfigData([...configData.slice(0, idx), ...configData.slice(idx + 1)]);
                      setSaved(false);
                    }}
                  >
                    <Icon name="trash" />
                  </Button>
                </Form.Group>
              ))}
              <Button
                type="button"
                compact
                size="mini"
                color="blue"
                onClick={() => {
                  setConfigData([...configData, { key: '', value: '' }]);
                }}
              >
                <Icon name="plus" />
                Add Parameter
              </Button>
            </Form.Field>
          )}

          <Header>Data Transformation</Header>

          {formdata.transformations?.map((t, i) => (
            <Form.Group key={t.id} widths="equal">
              <Form.Select
                fluid
                label="Field(s)"
                placeholder="Any"
                name={`transformations.${i}.fields`}
                onChange={onChange}
                value={t.fields || []}
                options={FromColumnOptions}
                multiple
              />

              <Form.Input fluid label="From" name={`transformations[${i}].from`} onChange={onChange} value={t.from} />

              <Form.Input fluid label="To" name={`transformations[${i}].to`} onChange={onChange} value={t.to} />

              <Button
                type="button"
                color="red"
                icon
                style={{ marginTop: '1.5rem' }}
                onClick={() => {
                  onChange(null, {
                    name: 'transformations',
                    value: formdata.transformations?.filter((_, j) => i !== j),
                  });
                }}
              >
                <Icon name="trash" />
              </Button>
            </Form.Group>
          ))}

          <Button
            type="button"
            compact
            size="mini"
            color="blue"
            onClick={() => {
              setFormdata(prev => {
                const next = _cloneDeep(prev);
                next.transformations = [...(next.transformations || []), newWebhookTransformation()];
                return next;
              });
            }}
          >
            <Icon name="plus" />
            Add Transformation
          </Button>
        </div>

        <div style={{ gridArea: 'body' }}>
          {formdata.advanced ? (
            <>
              <Header>Body Template</Header>

              <BodyTemplateEditor
                initialValue={formdata.request.body_template || ''}
                onChange={value => onChange(null, { name: 'request.body_template', value })}
                tags={FromColumnOptions.map(o => o.value)}
              />
            </>
          ) : (
            <>
              <Header>Field Mappings</Header>
              <Note>
                Create a mapping for each field you would like to send in the request. The associated customer data will
                be loaded from the data in your datasets and appended to the request using the given parameter name, if
                provided. If no parameter name is entered, the same name as the "from" field will be used.
              </Note>

              {formdata.field_mappings?.map((fieldMapping, i) => (
                <Form.Group key={i} widths="equal">
                  <Form.Select
                    fluid
                    placeholder="Select a field"
                    onChange={onChange}
                    name={`field_mappings[${i}].from`}
                    value={fieldMapping.from}
                    label="From"
                    options={FromColumnOptions}
                  />
                  <Form.Input
                    fluid
                    placeholder={fieldMapping.from || 'parameter name'}
                    value={fieldMapping.to}
                    name={`field_mappings[${i}].to`}
                    onChange={onChange}
                    label="To"
                  />
                  <Form.Select
                    fluid
                    placeholder="Select a type"
                    value={fieldMapping.type}
                    options={FieldTypeOptions}
                    onChange={onChange}
                    name={`field_mappings[${i}].type`}
                    label="Type"
                  />
                  {fieldMapping.type === 'TIMESTAMP' && (
                    <Form.Select
                      fluid
                      clearable
                      placeholder="YYYY-MM-DDTHH:mm:ssZ07:00"
                      value={fieldMapping.time_format || ''}
                      options={TimeFormatOptions}
                      onChange={onChange}
                      name={`field_mappings[${i}].time_format`}
                      label="Time Format"
                    />
                  )}
                  <Button
                    type="button"
                    color="red"
                    icon
                    style={{ marginTop: '1.5rem' }}
                    onClick={() => {
                      onChange(null, {
                        name: 'field_mappings',
                        value: formdata.field_mappings?.filter((_, j) => i !== j),
                      });
                    }}
                  >
                    <Icon name="trash" />
                  </Button>
                </Form.Group>
              ))}
              <Button
                type="button"
                compact
                size="mini"
                color="blue"
                onClick={() => {
                  onChange(null, {
                    name: 'field_mappings',
                    value: [...(formdata.field_mappings || []), { from: '', to: '', type: 'STRING' }],
                  });
                }}
              >
                <Icon name="plus" />
                Add Field Mapping
              </Button>
            </>
          )}
        </div>

        <div style={{ gridArea: 'response' }}>
          <Header>Parse Response</Header>
          <Note>
            When this webhook is triggered, the response back to the source that triggered the webhook will receive the
            data parsed below.
          </Note>
          <Note>
            This is especially useful when used in combination with a Feed. The data returned here will be made
            available to the feed widgets that follow after it using the field names ("key"s) defined below. Inside the
            Feed you will have access to them through <code>{`response.{key}`}</code>. For example, if you define a key
            below of <code>foo</code>, you can access that inside the feed as <code>response.foo</code>
          </Note>

          <Form.Select
            label="Content-Type"
            value={formdata.response.contentType}
            name="response.contentType"
            onChange={onChange}
            options={ResponseContentTypes.map(t => ({ ...t, key: t.value }))}
          />
          {formdata.response.contentType === 'TEXT' ? (
            <Note>
              When parsing the webhook response as <strong>plain text</strong>, you will be able to define regular
              expressions in the rules below to match and extract data to then be used in your rules.
            </Note>
          ) : (
            formdata.response.contentType && (
              <Note>
                When parsing the webhook response as <strong>{formdata.response.contentType}</strong>, you will be able
                to use a path syntax like <code>foo.bar</code> to extract a variable from the webhook response to then
                use in your rules.
              </Note>
            )
          )}

          {formdata.response.fields?.map((f, i) => (
            <Form.Group key={f.id}>
              <Form.Input
                label="Pattern"
                placeholder={formdata.response.contentType === 'TEXT' ? '"bar".+(true|false)' : 'foo.bar'}
                value={f.pattern}
                name={`response.fields[${i}].pattern`}
                onChange={onChange}
              />

              <Form.Input label="Key" value={f.key} name={`response.fields[${i}].key`} onChange={onChange} />

              <Button
                color="red"
                icon
                style={{ marginTop: '1.5rem' }}
                onClick={() =>
                  onChange(null, {
                    name: `response.fields`,
                    value: formdata.response.fields?.filter((_, j) => i !== j) || [],
                  })
                }
              >
                <Icon name="trash" />
              </Button>
            </Form.Group>
          ))}

          <Button
            type="button"
            compact
            size="mini"
            color="blue"
            onClick={() => {
              onChange(null, {
                name: 'response.fields',
                value: [...(formdata.response.fields || []), newWebhookResponseField()],
              });
            }}
          >
            <Icon name="plus" />
            Add Field
          </Button>
        </div>
      </Layout>
    </Form>
  );
};

export default EditWebhookConfigForm;
