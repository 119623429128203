import { format, parse } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { Header, Placeholder, PlaceholderLine, Segment, Table } from 'semantic-ui-react';

import { useGetAccountReportingHourlyQuery } from 'src/api/auth/account-reporting';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { AccountReporting } from 'src/types';
import { ReportingFilters } from '.';

type Props = {
  config: AccountReporting;
  filters: ReportingFilters;
};

const HourlyCallStats = ({ config, filters }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { data, error, isLoading } = useGetAccountReportingHourlyQuery({ ...filters, timezone: userTimezone });

  useEffect(() => {
    if (error) {
      apiErrorHandler(error, setApiMessage);
    }
  }, [error]);

  const hourlyStats = useMemo(() => {
    return data?.hourlyStats.map(item => {
      let connectDiv = 0;
      if (config.datascore.dials) connectDiv += item.dials;
      const connectsPercent = connectDiv === 0 ? 0 : (item.connects / connectDiv) * 100 || 0;

      let transfersDiv = 0;
      if (config.datascore.dials) transfersDiv += item.dials;
      if (config.datascore.inbounds) transfersDiv += item.inbounds;
      const transfersPercent = transfersDiv === 0 ? 0 : (item.transfers / transfersDiv) * 100 || 0;

      return {
        ...item,
        connectsPercent,
        transfersPercent,
      };
    });
  }, [data, config]);

  return (
    <Segment>
      <Header textAlign="center">Hourly Call Stats</Header>

      <ApiMessage data={apiMessage} />

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Hour</Table.HeaderCell>

            {config.datascore.enabled && (
              <>
                {config.datascore.dials && <Table.HeaderCell>Dials</Table.HeaderCell>}
                {config.datascore.connects && <Table.HeaderCell>Connects</Table.HeaderCell>}
                {config.datascore.connects_pct && <Table.HeaderCell>Connect %</Table.HeaderCell>}
                {config.datascore.inbounds && <Table.HeaderCell>Inbounds</Table.HeaderCell>}
                {config.datascore.transfers && <Table.HeaderCell>Transfers</Table.HeaderCell>}
                {config.datascore.transfers_pct && <Table.HeaderCell>Transfer %</Table.HeaderCell>}
              </>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {isLoading ? (
            <Table.Row>
              <Table.Cell>
                <Placeholder>
                  <PlaceholderLine />
                </Placeholder>
              </Table.Cell>
              <Table.Cell>
                <Placeholder>
                  <PlaceholderLine />
                </Placeholder>
              </Table.Cell>
              <Table.Cell>
                <Placeholder>
                  <PlaceholderLine />
                </Placeholder>
              </Table.Cell>
              <Table.Cell>
                <Placeholder>
                  <PlaceholderLine />
                </Placeholder>
              </Table.Cell>
              <Table.Cell>
                <Placeholder>
                  <PlaceholderLine />
                </Placeholder>
              </Table.Cell>
              <Table.Cell>
                <Placeholder>
                  <PlaceholderLine />
                </Placeholder>
              </Table.Cell>
              <Table.Cell>
                <Placeholder>
                  <PlaceholderLine />
                </Placeholder>
              </Table.Cell>
            </Table.Row>
          ) : (
            hourlyStats?.map((stat, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  <strong>{format(parse(stat.hour, 'HH:mm', new Date()), 'ha').toLowerCase()}</strong>
                </Table.Cell>

                {config.datascore.enabled && (
                  <>
                    {config.datascore.dials && <Table.Cell>{stat.dials}</Table.Cell>}
                    {config.datascore.connects && <Table.Cell>{stat.connects}</Table.Cell>}
                    {config.datascore.connects_pct && <Table.Cell>{stat.connectsPercent.toFixed(2)}%</Table.Cell>}
                    {config.datascore.inbounds && <Table.Cell>{stat.inbounds}</Table.Cell>}
                    {config.datascore.transfers && <Table.Cell>{stat.transfers}</Table.Cell>}
                    {config.datascore.transfers_pct && <Table.Cell>{stat.transfersPercent.toFixed(2)}%</Table.Cell>}
                  </>
                )}
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    </Segment>
  );
};

export default HourlyCallStats;
