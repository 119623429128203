import { useMutation, useQuery } from 'react-query';

import { BillingPaymentIntentAction } from 'src/types';
import {
  AccountBillingSubscriptionPaymentsResponse,
  BillingConnectProTransactionsResponse,
  BillingLeadscorePlusTransactionsResponse,
} from '../accounts';
import { ApiResponse, http } from '../http-common';
import { PaymentIntentResponse } from '../models';

export type ListBillingLeadscorePlusTransactionsParams = {
  limit: number;
  offset: number;
};

export const useListBillingLeadscorePlusTransactionsQuery = (params: ListBillingLeadscorePlusTransactionsParams) => {
  return useQuery(['auth/account', 'billing/leadscore-plus/transactions', params], async () => {
    const res = await http.get<BillingLeadscorePlusTransactionsResponse>(
      `/api/auth/account/billing/leadscore-plus/transactions`,
      {
        params,
      }
    );
    return res.data.transactions;
  });
};
export type ListBillingConnectProTransactionsParams = {
  limit: number;
  offset: number;
};

export const useListBillingConnectProTransactionsQuery = (params: ListBillingConnectProTransactionsParams) => {
  return useQuery(['auth/account', 'billing/connect-pro/transactions', params], async () => {
    const res = await http.get<BillingConnectProTransactionsResponse>(
      `/api/auth/account/billing/connect-pro/transactions`,
      {
        params,
      }
    );
    return res.data.transactions;
  });
};

export type ListBillingSubscriptionPaymentsParams = {
  limit: number;
  offset: number;
};

export const useListBillingSubscriptionPaymentsQuery = (params: ListBillingSubscriptionPaymentsParams) => {
  return useQuery(['auth/account', 'billing/subscription/payments', params], async () => {
    const res = await http.get<AccountBillingSubscriptionPaymentsResponse>(
      `/api/auth/account/billing/subscription/payments`,
      { params }
    );
    return res.data.payments;
  });
};

export type BillingPaymentIntentInput = {
  action: BillingPaymentIntentAction;
  amount: number;
  paymentMethodId: string;
};

export const useAccountBillingPaymentIntentMutation = () => {
  return useMutation(async (input: BillingPaymentIntentInput) => {
    const res = await http.post<PaymentIntentResponse>(`/api/auth/account/billing/payment-intent`, input);
    return res.data;
  });
};

export type BillingCancelPaymentIntentInput = {
  paymentIntentId: string;
};

export const useAccountBillingCancelPaymentIntentMutation = () => {
  return useMutation(async (params: BillingCancelPaymentIntentInput) => {
    const res = await http.delete<ApiResponse>(`/api/auth/account/billing/payment-intent`, { params });
    return res.data;
  });
};
