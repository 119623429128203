import { Helmet } from 'react-helmet-async';

import Breadcrumbs from 'src/components/Breadcrumbs';
import PaginatedTable from 'src/components/PaginatedTable';
import { Container, Header, Row } from 'src/styles';
import AddWebhookConfig from './AddWebhookConfig';
import ListWebhookConfigsBody from './ListWebhookConfigsBody';

const WebhookConfigsList = () => {
  return (
    <Container>
      <Helmet>
        <title>Webhooks Configs | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header style={{ marginBottom: 0 }}>
          <Breadcrumbs crumbs={['Webhooks', 'Configs']} />
        </Header>
        <AddWebhookConfig />
      </Row>

      <PaginatedTable headers={['Status', 'Name', 'URL', '']} renderBody={ListWebhookConfigsBody} />
    </Container>
  );
};

export default WebhookConfigsList;
