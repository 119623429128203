import { useCallback } from 'react';
import { Button } from 'semantic-ui-react';

import { useRefreshModelsMutation } from 'src/api/admin/models';

const RefreshModels = () => {
  const { isLoading, mutateAsync } = useRefreshModelsMutation();

  const onClick = useCallback(async () => {
    await mutateAsync();
  }, [mutateAsync]);

  return (
    <Button color="blue" onClick={onClick} loading={isLoading}>
      Refresh Models
    </Button>
  );
};

export default RefreshModels;
