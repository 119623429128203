import { isEmpty } from 'lodash';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Button, DropdownItemProps, Form, InputOnChangeData, Table } from 'semantic-ui-react';

import {
  BandwidthPurchasePostalCodesInput,
  PostalCodeStats,
  // useGetTwilioStudioFlowsQuery,
  usePurchasePostalCodesMutation,
} from 'src/api/auth/account-bandwidth';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useListVoiceConfigsQuery } from 'src/api/voice-configs';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  stats: PostalCodeStats;
};

type ValidationErrors = {
  howMany?: string;
  voiceConfigID?: string;
};

type Formdata = {
  howMany: string;
  group: 'city' | 'state';
  voiceConfigID: string;
};

type StateCounts = { [key: string]: string };

const BandwidthPurchasePostalCodesForm = ({ onCancel, onSuccess, stats }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<Formdata>({ howMany: '1', group: 'city', voiceConfigID: '' });
  const [stateCounts, setStateCounts] = useState<StateCounts>(
    Object.keys(stats.byState).reduce((counts, s) => {
      return { ...counts, [s]: '1' };
    }, {} as StateCounts)
  );
  const { mutateAsync: purchasePostalCodes, isLoading: purchaseLoading } = usePurchasePostalCodesMutation();

  const { data: voiceConfigs, isLoading: voiceConfigsLoading } = useListVoiceConfigsQuery({ limit: 100, offset: 0 });

  const voiceConfigOptions = useMemo<DropdownItemProps[]>(() => {
    if (!voiceConfigs?.data) {
      return [];
    }

    return voiceConfigs?.data.map(c => ({
      key: c.id,
      text: c.name,
      value: c.id,
      description: 'Voice Config',
    }));
  }, [voiceConfigs?.data]);

  const validate = useCallback((input: Formdata): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    if (Number.isNaN(Number(input.howMany))) {
      validationErrors.howMany = 'must be a number';
    } else if (Number(input.howMany) % 1 !== 0) {
      validationErrors.howMany = 'must be a whole number';
    } else if (Number(input.howMany) <= 0) {
      validationErrors.howMany = 'must be greater than 0';
    }

    if (input.voiceConfigID === '') {
      validationErrors.voiceConfigID = 'required';
    }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onChangeGroup = useCallback((_, { value }) => {
    setFormdata(prev => ({ ...prev, group: value === 'city' ? 'city' : 'state' }));
  }, []);

  const onChangeHowMany = useCallback(
    (_, { value }) => {
      const howMany = String(value);

      setStateCounts(
        Object.keys(stats.byState).reduce((counts, s) => {
          return { ...counts, [s]: howMany };
        }, {} as StateCounts)
      );

      setFormdata(prev => {
        const next = { ...prev, howMany };
        validate(next);
        return next;
      });
    },
    [stats.byState, validate]
  );

  const onChangeStateCount = useCallback(
    (s: string) =>
      (_: ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData) => {
        setStateCounts(prev => ({
          ...prev,
          [s]: String(value),
        }));
      },
    []
  );

  const onChangeVoiceConfigID = useCallback(
    (_, { value }) =>
      setFormdata(prev => {
        const next = { ...prev, voiceConfigID: String(value) };
        validate(next);
        return next;
      }),
    [validate]
  );

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    if (!isEmpty(validate(formdata))) {
      return;
    }

    try {
      const input: BandwidthPurchasePostalCodesInput = {
        group: formdata.group,
        voiceConfigID: formdata.voiceConfigID,
        counts: [],
      };

      switch (formdata.group) {
        case 'city': {
          input.counts = Object.keys(stats.byCity).map(s => {
            const [city, state] = s.split(',');
            return { city, state, count: Number(formdata.howMany), latLng: stats.byCity[s].latLng };
          });
          break;
        }

        case 'state':
          input.counts = Object.keys(stateCounts).map(s => ({
            state: s,
            count: Number(stateCounts[s]),
          }));
          break;
      }

      await purchasePostalCodes(input);

      onSuccess();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [formdata, onSuccess, purchasePostalCodes, stateCounts, stats.byCity, validate]);

  let phoneCount = 0;

  switch (formdata.group) {
    case 'city':
      let multiplier = 1;
      if (!Number.isNaN(Number(formdata.howMany))) {
        multiplier = Number(formdata.howMany);
      }

      phoneCount = stats.cities * multiplier;
      break;

    case 'state':
      Object.keys(stateCounts).forEach(s => {
        phoneCount += Number(stateCounts[s]);
      });
      break;
  }

  return (
    <Form onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Raw</Table.HeaderCell>
            <Table.HeaderCell>Unique</Table.HeaderCell>
            <Table.HeaderCell>Valid</Table.HeaderCell>
            <Table.HeaderCell>
              <Form.Radio
                label="Cities"
                name="group"
                value="city"
                checked={formdata.group === 'city'}
                onChange={onChangeGroup}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Form.Radio
                label="States"
                name="group"
                value="state"
                checked={formdata.group === 'state'}
                onChange={onChangeGroup}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{stats.raw}</Table.Cell>
            <Table.Cell>{stats.unique}</Table.Cell>
            <Table.Cell>{stats.valid}</Table.Cell>
            <Table.Cell>{stats.cities}</Table.Cell>
            <Table.Cell>{stats.states}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Form.Group widths="equal">
        <Form.Input
          label={`How many phone numbers would you like to purchase per ${formdata.group}?`}
          value={formdata.howMany}
          onChange={onChangeHowMany}
          error={errors.howMany}
        />

        <Form.Select
          label="Select voice config to assign new numbers to"
          clearable
          loading={voiceConfigsLoading}
          options={voiceConfigOptions}
          value={formdata.voiceConfigID}
          onChange={onChangeVoiceConfigID}
          error={errors.voiceConfigID}
        />
      </Form.Group>

      {formdata.group === 'state' && (
        <Table celled compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>State</Table.HeaderCell>
              <Table.HeaderCell>Zip Count</Table.HeaderCell>
              <Table.HeaderCell>Purchase Count</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {Object.keys(stats.byState).map(s => (
            <Table.Row>
              <Table.Cell>
                <strong>{s}</strong>
              </Table.Cell>
              <Table.Cell>{stats.byState[s]}</Table.Cell>
              <Table.Cell collapsing>
                <Form.Input value={stateCounts[s]} onChange={onChangeStateCount(s)} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table>
      )}

      <Row>
        <Button fluid type="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="green" fluid loading={purchaseLoading}>
          Buy {phoneCount} Phones
        </Button>
      </Row>
    </Form>
  );
};

export default BandwidthPurchasePostalCodesForm;
