import { format, parse } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { Button, Form, Icon, Loader, Table } from 'semantic-ui-react';

import { AdminGetAccountsReportingParams } from 'src/api/admin/account-reporting';
import {
  ReportingStatOverTimeAttributes,
  useDetailReportingStatsQuery,
  useListAccountsQuery,
} from 'src/api/admin/accounts';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { Container, Header as PageHeader, Row } from 'src/styles';
import theme from 'src/styles/theme';
import { ReportingTimeRange, ReportingTimeRanges, TimezoneOptions } from 'src/types';
import { reportingTimeRangeToDateStrings } from 'src/utils';

const IntervalOptions = [
  { text: 'Hourly', value: 'hourly' },
  { text: 'Daily', value: 'daily' },
  { text: 'Weekly', value: 'weekly' },
  { text: 'Monthly', value: 'monthly' },
];

const getInitialStatsFilters = (query: URLSearchParams): AdminGetAccountsReportingParams => {
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const timeRange = query.get('timeRange');
  const voiceConfigId = query.get('voiceConfigId');
  const timezone = query.get('timezone');
  const interval = query.get('interval');
  return {
    timeRange: timeRange ? (timeRange as ReportingTimeRange) : ('today' as ReportingTimeRange),
    startDate: startDate ? startDate : format(new Date(), 'yyyy-MM-dd'),
    endDate: endDate ? endDate : format(new Date(), 'yyyy-MM-dd'),
    voiceConfigId: voiceConfigId ? Number(voiceConfigId) : undefined,
    timezone: timezone ? timezone : Intl.DateTimeFormat().resolvedOptions().timeZone,
    interval: interval ? interval : 'hourly',
  };
};

const AdminReportingV2OverTime = () => {
  const { push, replace } = useHistory();
  const query = useSearchQuery();
  const id = query.get('id') ? query.get('id') : '';
  const [daterange, setDaterange] = useState<[Date | null, Date | null]>([null, null]);
  const [statsFilters, setStatsFilters] = useState<AdminGetAccountsReportingParams>(() => {
    const r = getInitialStatsFilters(query);
    setDaterange([parse(r.startDate, 'yyyy-MM-dd', new Date()), parse(r.endDate, 'yyyy-MM-dd', new Date())]);
    return r;
  });

  const [direction, setDirection] = useState<'ascending' | 'descending' | undefined>('ascending');
  const [column, setColumn] = useState<typeof ReportingStatOverTimeAttributes[number]>('time');

  const { data: accounts, isLoading: isLoadingAccounts } = useListAccountsQuery({
    limit: 1000,
    offset: 0,
  });

  const { data, isLoading } = useDetailReportingStatsQuery({
    startDate: statsFilters.startDate,
    endDate: statsFilters.endDate,
    accountId: Number(id),
    voiceConfigId: statsFilters.voiceConfigId,
    timezone: statsFilters.timezone,
    interval: statsFilters.interval,
  });

  const totals = useMemo(() => {
    const intObj = {
      dials: 0,
      connects: 0,
      inbounds: 0,
      transfers: 0,
      humans: 0,
      xferToCallcenter: 0,
    };
    if (!data?.data) return intObj;
    return data?.data.reduce((acc, stat) => {
      acc.dials += stat.dials;
      acc.connects += stat.connects;
      acc.inbounds += stat.inbounds;
      acc.transfers += stat.transfers;
      acc.humans += stat.humans;
      acc.xferToCallcenter += stat.xfer_to_callcenter;
      return acc;
    }, intObj);
  }, [data]);

  const onChangeTimeRange = useCallback(
    (d, { value }) => {
      let startDate = format(d[0] || new Date(), 'yyyy-MM-dd');
      let endDate = format(d[1] || new Date(), 'yyyy-MM-dd');
      const timeRange = value;
      if (value !== 'custom') {
        [startDate, endDate] = reportingTimeRangeToDateStrings(value as ReportingTimeRange);
      }
      query.set('startDate', startDate);
      query.set('endDate', endDate);
      query.set('timeRange', timeRange);
      replace({ search: query.toString() });
    },
    [query, replace]
  );

  const onChangeDates = useCallback(
    (d: [Date | null, Date | null]) => {
      setDaterange(d);
      const [startDate, endDate] = d;
      if (startDate !== null && endDate !== null) {
        setStatsFilters(prev => ({
          ...prev,
          timeRange: 'custom' as ReportingTimeRange,
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
        }));
        onChangeTimeRange(d, { value: 'custom' });
      }
    },
    [onChangeTimeRange]
  );

  const onChangeVoiceConfigFilter = useCallback((_, { value }) => {
    setStatsFilters(prev => ({ ...prev, voiceConfigId: value }));
  }, []);

  const updateSort = (col: typeof ReportingStatOverTimeAttributes[number]) => () => {
    const newDirection = column === col ? (direction === 'ascending' ? 'descending' : 'ascending') : 'descending';
    setDirection(newDirection);
    setColumn(col);
  };

  useEffect(() => {
    setStatsFilters(() => {
      const r = getInitialStatsFilters(query);
      setDaterange([parse(r.startDate, 'yyyy-MM-dd', new Date()), parse(r.endDate, 'yyyy-MM-dd', new Date())]);
      return r;
    });
  }, [query]);

  if (isLoadingAccounts) {
    return (
      <Container>
        <Loader active inline="centered" />
      </Container>
    );
  }

  return (
    <Container>
      <Helmet>
        <title>Reporting - Account Over Time - Admin | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Row style={{ alignItems: 'center' }}>
          <Button
            icon
            color="blue"
            onClick={() => push(`/admin/accounts/reporting?${query.toString()}`)}
            style={{ marginRight: '1rem' }}
          >
            <Icon name="arrow left" />
          </Button>
          <PageHeader style={{ margin: 0 }}>
            <span style={{ color: theme.gray }}>Admin / Accounts / Reporting</span>{' '}
            <span style={{ padding: '0 0.5rem' }}>/</span> Over Time
          </PageHeader>
        </Row>
      </Row>

      <Form style={{ marginTop: 10 }}>
        <Form.Group>
          <Form.Field>
            <label>Account</label>
            <Form.Select
              clearable
              search
              placeholder="All Accounts"
              onChange={(d, { value }) => {
                if (!value) {
                  value = '';
                }
                query.set('id', value as string);
                push({ search: query.toString() });
              }}
              options={
                accounts?.data.map(account => {
                  return { key: account.id, value: account.id.toString(), text: account.name };
                }) || []
              }
              value={id?.toString()}
            />
          </Form.Field>

          <Form.Field>
            <label>Date Range</label>
            <Form.Select
              onChange={onChangeTimeRange}
              options={ReportingTimeRanges.map(tr => ({ ...tr, key: tr.value }))}
              value={statsFilters.timeRange}
            />
          </Form.Field>
          <Form.Field style={{ width: 240 }}>
            <label>Custom Range</label>
            <DatePicker
              selectsRange
              startDate={daterange[0]}
              endDate={daterange[1]}
              customInput={<Form.Input fluid icon="calendar" iconPosition="left" />}
              monthsShown={2}
              showPopperArrow={false}
              onChange={onChangeDates}
            />
          </Form.Field>
          <Form.Field>
            <Form.Select
              label="Interval"
              options={IntervalOptions}
              value={statsFilters.interval}
              onChange={(_, { value }) => {
                const interval = value?.toString() || 'Hour';
                query.set('interval', interval);
                push({ search: query.toString() });
              }}
            />
          </Form.Field>
          <Form.Field>
            <Form.Select
              label="Voice Config"
              disabled={id === '0'}
              clearable
              search
              placeholder="No filter"
              onChange={onChangeVoiceConfigFilter}
              loading={isLoadingAccounts}
              options={
                data?.voiceConfigs?.map(config => {
                  return { key: config.id, value: config.id, text: config.name };
                }) || []
              }
              value={statsFilters.voiceConfigId}
            />
          </Form.Field>
          <Form.Field>
            <Form.Select
              label="Timezone"
              options={TimezoneOptions}
              value={statsFilters.timezone}
              onChange={(_, { value }) => {
                query.set('timezone', value?.toString() || 'UTC');
                push({ search: query.toString() });
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>

      <Table compact="very" striped sortable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell sorted={column === 'time' ? direction : undefined} onClick={updateSort('time')}>
              {statsFilters?.interval
                ? statsFilters?.interval.charAt(0).toUpperCase() + statsFilters?.interval.slice(1)
                : 'Hourly'}
            </Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'dials' ? direction : undefined} onClick={updateSort('dials')}>
              Dials
            </Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'connects' ? direction : undefined} onClick={updateSort('connects')}>
              Connects
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'connect_pct' ? direction : undefined}
              onClick={updateSort('connect_pct')}
            >
              Connects %
            </Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'inbounds' ? direction : undefined} onClick={updateSort('inbounds')}>
              Inbounds
            </Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'transfers' ? direction : undefined} onClick={updateSort('transfers')}>
              Transfers
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'transfer_pct' ? direction : undefined}
              onClick={updateSort('transfer_pct')}
            >
              Transfers %
            </Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'humans' ? direction : undefined} onClick={updateSort('humans')}>
              Humans
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'xfer_to_callcenter' ? direction : undefined}
              onClick={updateSort('xfer_to_callcenter')}
            >
              Xfer to CallCenter
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'xfer_to_callcenter_pct' ? direction : undefined}
              onClick={updateSort('xfer_to_callcenter_pct')}
            >
              Transfer %
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {isLoading && (
            <Table.Row>
              <Table.Cell colSpan={12} style={{ textAlign: 'center', padding: 40 }}>
                <Loader inline active />
              </Table.Cell>
            </Table.Row>
          )}
          {data?.data.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={12} style={{ textAlign: 'center', padding: 40 }}>
                No data found
              </Table.Cell>
            </Table.Row>
          )}
          {data?.data
            .sort((a, b) => {
              if (typeof a[column] === 'string') {
                return direction === 'ascending'
                  ? a[column].toString().localeCompare(b[column].toString())
                  : b[column].toString().localeCompare(a[column].toString());
              }
              return direction === 'ascending'
                ? Number(a[column]) - Number(b[column])
                : Number(b[column]) - Number(a[column]);
            })
            .map(stat => (
              <Table.Row key={stat.time}>
                <Table.Cell>
                  <div className="ui label">{stat.time}</div>
                </Table.Cell>
                <Table.Cell>{stat.dials.toLocaleString()}</Table.Cell>
                <Table.Cell>{stat.connects.toLocaleString()}</Table.Cell>
                <Table.Cell>{stat.connect_pct}%</Table.Cell>
                <Table.Cell>{stat.inbounds.toLocaleString()}</Table.Cell>
                <Table.Cell>{stat.transfers.toLocaleString()}</Table.Cell>
                <Table.Cell>{stat.transfer_pct}%</Table.Cell>
                <Table.Cell>{stat.humans.toLocaleString()}</Table.Cell>
                <Table.Cell>{stat.xfer_to_callcenter.toLocaleString()}</Table.Cell>
                <Table.Cell>{stat.xfer_to_callcenter_pct}%</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>
              <b>Totals:</b>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <b>{totals.dials.toLocaleString()}</b>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <b>{totals.connects.toLocaleString()}</b>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <b>{totals.dials > 0 ? ((totals.connects / totals.dials) * 100).toFixed(2) : 100}%</b>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <b>{totals.inbounds.toLocaleString()}</b>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <b>{totals.transfers.toLocaleString()}</b>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <b>{totals.transfers > 0 ? ((totals.inbounds / totals.transfers) * 100).toFixed(2) : 100}%</b>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <b>{totals.humans.toLocaleString()}</b>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <b>{totals.xferToCallcenter.toLocaleString()}</b>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <b>{totals.humans > 0 ? ((totals.xferToCallcenter / totals.humans) * 100).toFixed(2) : 100}%</b>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Container>
  );
};

export default AdminReportingV2OverTime;
