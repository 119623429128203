import { useMemo } from 'react';
import { Button, Icon, Label, Modal, Table } from 'semantic-ui-react';

import { useGetDatasetQuery, useListDatasetListsQuery } from 'src/api/datasets';
import { DatasetsV2UploadJob, JobStatus } from 'src/types';

const betaUrl = process.env.REACT_APP_BETA_URL;

const DatasetIngestJobDetailsModal = ({ id: jobId, status, queued, payload }: DatasetsV2UploadJob) => {
  const { data: dataset } = useGetDatasetQuery(payload.dataset_id);
  const { data: lists } = useListDatasetListsQuery({ datasetId: payload.dataset_id, limit: 10, offset: 0 });

  const list = useMemo(() => {
    return lists?.data.find(l => l.id === payload.list_id);
  }, [lists, payload.list_id]);

  return (
    <Modal
      trigger={
        <Button title="View Details" icon>
          <Icon name="eye" />
        </Button>
      }
    >
      <Modal.Header>Job Details</Modal.Header>
      <Modal.Content>
        {status === JobStatus.Error && typeof payload.error !== 'undefined' && payload.error.length > 0 && (
          <Label color="red">
            Error
            <Label.Detail>{payload.error}</Label.Detail>
          </Label>
        )}

        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell style={{ width: 130 }}>Created At</Table.Cell>
              <Table.Cell>{queued}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Dataset</Table.Cell>
              <Table.Cell>
                {dataset?.name}{' '}
                <Label>
                  {payload.dataset_id} <Label.Detail>ID</Label.Detail>
                </Label>
              </Table.Cell>
            </Table.Row>

            {payload.list_id && (
              <Table.Row>
                <Table.Cell>List</Table.Cell>
                <Table.Cell>
                  {list?.name}{' '}
                  <Label>
                    {payload.list_id} <Label.Detail>ID</Label.Detail>
                  </Label>
                </Table.Cell>
              </Table.Row>
            )}

            {typeof payload.raw_gs_path !== 'undefined' && (
              <Table.Row>
                <Table.Cell>Raw Data</Table.Cell>
                <Table.Cell>
                  <a href={`${betaUrl}/api/jobs/${jobId}/download?stage=raw`} style={{ marginRight: '1rem' }}>
                    <Icon name="download" /> {payload.raw_gs_path.slice(payload.raw_gs_path.indexOf('0-raw'))}
                  </a>
                  <Label>
                    {payload.raw_num_rows} <Label.Detail>Rows</Label.Detail>
                  </Label>
                </Table.Cell>
              </Table.Row>
            )}

            <Table.Row>
              <Table.Cell>Field Mappings</Table.Cell>
              <Table.Cell>
                {payload.field_mappings.map(f => (
                  <Label key={f}>{f}</Label>
                ))}
              </Table.Cell>
            </Table.Row>

            {typeof payload.parsed_gs_path !== 'undefined' && (
              <Table.Row>
                <Table.Cell>Parsed Data</Table.Cell>
                <Table.Cell>
                  <a href={`${betaUrl}/api/jobs/${jobId}/download?stage=parsed`} style={{ marginRight: '1rem' }}>
                    <Icon name="download" /> {payload.parsed_gs_path.slice(payload.parsed_gs_path.indexOf('1-parsed'))}
                  </a>
                  <Label>
                    {payload.parsed_num_rows} <Label.Detail>Rows</Label.Detail>
                  </Label>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  );
};

export default DatasetIngestJobDetailsModal;
