import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Loader, Placeholder, PlaceholderParagraph, Table } from 'semantic-ui-react';

import { useListAccountApiTokensQuery } from 'src/api/accounts';
import { useListWebhookConfigsQuery } from 'src/api/webhooks';
import LinkWithDetail from 'src/components/LinkWithDetail';
import { RenderProps } from 'src/components/PaginatedTable';
import theme from 'src/styles/theme';
import DeleteWebhookConfig from './DeleteWebhookConfig';
import DuplicateWebhookConfig from './DuplicateWebhookConfig';
import ToggleWebhookConfigEnabledButton from './ToggleWebhookConfigEnabledButton';

const betaURL = process.env.REACT_APP_BETA_URL;

const PlaceholderRow = () => (
  <Table.Row>
    <Table.Cell collapsing>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell collapsing>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell collapsing>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
  </Table.Row>
);

const ListWebhookConfigsBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: configs, isLoading: webhooksLoading } = useListWebhookConfigsQuery({
    limit,
    offset: limit * (currentPage - 1),
  });
  const { data: apiTokens, isLoading: tokensLoading } = useListAccountApiTokensQuery({ limit: 1, offset: 0 });

  useEffect(() => {
    let count = 1;
    if (typeof configs !== 'undefined') {
      count = Math.ceil(configs.total / limit);
    }
    setPageCount(count);
  }, [configs, limit, setPageCount]);

  let token = '{api_token}';
  if (apiTokens && apiTokens.data.length > 0) {
    token = apiTokens.data[0].token;
  }

  if (webhooksLoading) {
    return <PlaceholderRow />;
  }

  if (!configs?.data.length) {
    return (
      <Table.Row>
        <Table.Cell colSpan={5}>
          <span style={{ color: theme.gray }}>No configs found</span>
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      {configs?.data.map(c => (
        <Table.Row key={c.id}>
          <Table.Cell collapsing>
            <ToggleWebhookConfigEnabledButton config={c} />
          </Table.Cell>
          <Table.Cell>
            <LinkWithDetail to={`/webhooks/configs/${c.id}`} text={c.name} detail={c.id} />
          </Table.Cell>
          <Table.Cell>
            {tokensLoading ? <Loader inline active /> : `${betaURL}/api/call-transfer/${c.id}?token=${token}`}
          </Table.Cell>
          <Table.Cell textAlign="right" collapsing>
            <DuplicateWebhookConfig id={c.id} />
            <Link to={`/webhooks/configs/${c.id}`}>
              <Button as="div" color="blue" icon title="Edit config">
                <Icon name="pencil" />
              </Button>
            </Link>
            <DeleteWebhookConfig id={c.id} />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default ListWebhookConfigsBody;
