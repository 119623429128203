import isEmpty from 'lodash/isEmpty';
import { useCallback, useRef, useState } from 'react';
import { Button, DropdownProps, Form, FormProps, Icon, InputOnChangeData, Modal } from 'semantic-ui-react';

import { UploadFileInput, useUploadFileMutation } from 'src/api/convoso';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';

const getInitialFormdata = (id: number): UploadFileInput => ({
  id: id || 0,
  checkDupes: false,
  sendAllFields: false,
  listId: '',
  status: '',
});

type ValidationErrors = {
  checkDupes?: boolean;
  sendAllFields?: boolean;
  listId?: string;
  status?: string;
};

type Props = {
  id: number;
};

const StatusOptions = [
  { key: 'none', text: 'None', value: 'none' },
  { key: 'locked', text: 'Locked', value: 'locked' },
  { key: 'released', text: 'Released', value: 'released' },
];

const UploadConvosoFile = ({ id }: Props) => {
  const modalRef = useRef(null);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<UploadFileInput>(() => getInitialFormdata(id));
  const { mutateAsync, isLoading } = useUploadFileMutation();

  const onCloseModal = () => {
    setApiMessage(undefined);
    setErrors({});
    setFormdata(getInitialFormdata(id));
  };

  const validate = useCallback((input: UploadFileInput): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    if (input.listId === '') {
      validationErrors.listId = 'list id is required';
    }
    if (input.status === '') {
      validationErrors.status = 'status is required';
    }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onSubmit = useCallback(
    async (_event: React.FormEvent<HTMLFormElement>, _data: FormProps) => {
      setApiMessage(undefined);

      if (!isEmpty(validate(formdata))) {
        return;
      }

      try {
        const data = await mutateAsync(formdata);

        if (!data.success) {
          const e = { success: false, status: 400, message: data.message || 'Unknown Error' };
          setApiMessage(e);
          return;
        }

        setFormdata(getInitialFormdata(id));

        if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
          // @ts-ignore
          modalRef.current.handleClose();
        }
      } catch (e: any) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [formdata, id, mutateAsync, validate]
  );

  const onChange = useCallback(
    ({ name, value }: DropdownProps | InputOnChangeData): void => {
      setFormdata(prev => {
        const next = { ...prev, [name]: value };
        validate(next);
        return next;
      });
    },
    [validate]
  );

  return (
    <Modal
      size="mini"
      ref={modalRef}
      trigger={
        <Button color="blue">
          <Icon name="upload" /> Upload to Convoso
        </Button>
      }
      onClose={onCloseModal}
    >
      <Modal.Header>Upload to Convoso</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Form.Input
            error={errors.listId}
            label="List ID"
            name="listId"
            value={formdata.listId}
            onChange={(e, d) => onChange(d)}
          />

          <Form.Select
            error={errors.status}
            label="Status"
            name="status"
            value={formdata.status}
            onChange={(e, d) => onChange(d)}
            options={StatusOptions}
          />

          <Form.Checkbox
            label="Check Duplicates"
            checked={formdata.checkDupes}
            onChange={(_, { checked }) => onChange({ name: 'checkDupes', value: checked })}
          />

          <Form.Checkbox
            label="Upload All Fields"
            checked={formdata.sendAllFields}
            onChange={(_, { checked }) => onChange({ name: 'sendAllFields', value: checked })}
          />

          <Button content="Upload" color="blue" loading={isLoading} fluid />
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default UploadConvosoFile;
