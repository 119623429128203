import { delay } from 'lodash';
import { FocusEvent, useCallback, useMemo } from 'react';
import { Dropdown, DropdownItemProps, DropdownProps, Form, Icon } from 'semantic-ui-react';

import { useListBlacklistsQuery } from 'src/api/blacklists';
import NodeOverlay from '../../components/NodeOverlay';
import { OverlayProps } from '../../types';
import { BlacklistData } from '../types';

const BlacklistOverlay = ({ node, updateNode, onSave }: OverlayProps<BlacklistData>) => {
  const { id: nodeID, data } = node;

  const saveOnStateUpdate = useCallback(
    // INFO: This delay is needed to because setNodes is async but we can not wait for it to finish
    () => delay(() => onSave('state-updated'), 100),
    [onSave]
  );

  const { data: blacklists } = useListBlacklistsQuery({ limit: 100, offset: 0 });

  const listsOptions: DropdownItemProps[] = useMemo(
    () => blacklists?.data.map(({ name, id }) => ({ key: id, value: id, text: name })) || [],
    [blacklists?.data]
  );

  return (
    <NodeOverlay>
      <NodeOverlay.Header>
        <Icon name="ban" color="violet" size="large" />

        <NodeOverlay.Title>Blacklist</NodeOverlay.Title>
      </NodeOverlay.Header>

      <NodeOverlay.Description>Scrubs out data that is blacklisted in one or more lists.</NodeOverlay.Description>

      <NodeOverlay.Content>
        <Form>
          <Form.Input
            label="Name"
            type="text"
            defaultValue={data?.name}
            onBlur={(event: FocusEvent<HTMLInputElement>) => {
              const value = event.target.value;
              updateNode(node.id, { name: value });
              saveOnStateUpdate();
            }}
          />

          <h3>Lists</h3>

          <Dropdown
            placeholder="Lists"
            disabled={listsOptions.length === 0}
            fluid
            search
            selection
            clearable
            defaultValue={data?.listId && parseInt(data.listId)}
            options={listsOptions}
            onChange={(_: any, { value }: DropdownProps) => {
              updateNode(nodeID, { listId: String(value) });
              saveOnStateUpdate();
            }}
          />
        </Form>
      </NodeOverlay.Content>
    </NodeOverlay>
  );
};

export default BlacklistOverlay;
