import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { Header, Segment } from 'semantic-ui-react';

import { useGetAccountReportingCarriersQuery } from 'src/api/auth/account-reporting';
import { ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { ReportingFilters } from '.';
import SortableCarrierTable, { CarrierData } from './SortableCarrierTable';

type Props = {
  filters: ReportingFilters;
};

const CarrierStats = ({ filters }: Props) => {
  const { data, error, isLoading } = useGetAccountReportingCarriersQuery({
    startDate: filters.startDate,
    endDate: filters.endDate,
    voiceConfigId: filters.voiceConfigId,
  });

  const errorMsg: ApiMessageData | undefined = error
    ? {
        status: Number((error as AxiosError).response?.status) || 500,
        message: (error as AxiosError).response?.data?.message || '',
        success: false,
      }
    : undefined;

  const carrierCallsGrouped = useMemo(() => {
    const carrierCallsGroupedMap: { [key: string]: CarrierData } = {};

    data?.groupedCarrierCalls.forEach(group => {
      const { carrier, count, duration } = group;

      const carrierName = carrier || '';

      if (!carrierCallsGroupedMap[carrierName]) {
        carrierCallsGroupedMap[carrierName] = {
          name: carrierName,
          '0 - 10s': 0,
          '10 - 120s': 0,
          '> 120s': 0,
          '> 300s': 0,
          total: 0,
        };
      }

      carrierCallsGroupedMap[carrierName][duration] = Number(count) || 0;
      carrierCallsGroupedMap[carrierName].total += Number(count) || 0;
    });

    return Object.values(carrierCallsGroupedMap);
    // .sort((a, b) => {
    //   return b['10 - 120s'] - a['10 - 120s'];
    // });
  }, [data?.groupedCarrierCalls]);

  // const carrierCalls = carrierCallsGrouped.sort((a, b) => b.total - a.total);
  const carrierMaxCallCount = carrierCallsGrouped.reduce((max, { total }) => Math.max(max, total), 0);

  return (
    <Segment style={{ height: 450, overflowY: 'scroll' }}>
      <Header textAlign="center">Calls / Carrier (Duration Grouping)</Header>

      {errorMsg && <ApiMessage data={errorMsg} />}

      <SortableCarrierTable carrierData={carrierCallsGrouped} loading={isLoading} maxCount={carrierMaxCallCount} />
    </Segment>
  );
};

export default CarrierStats;
