import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Label, Loader, Statistic, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { useGetBigqueryTablesIngestedCountQuery, useListBigqueryTablesQuery } from 'src/api/bigquery';
import { RenderProps } from 'src/components/PaginatedTable';
import theme from 'src/styles/theme';
import DeleteDataset from './DeleteDataset';

const StatsGroup = styled(Statistic.Group)`
  margin: 0 -0.5em !important;
  align-items: center !important;

  & > .statistic {
    margin: 0 0.5em !important;
  }
`;

const Stats = ({ success, error }: { success: number | null; error: number | null }) => {
  return (
    <StatsGroup size="mini">
      <Statistic color={success ? 'green' : undefined}>
        <Statistic.Value>{success || '-'}</Statistic.Value>
      </Statistic>
      <span style={{ color: theme.gray }}>/</span>
      <Statistic color={error ? 'red' : undefined}>
        <Statistic.Value>{error || '-'}</Statistic.Value>
      </Statistic>
    </StatsGroup>
  );
};

const betaUrl = process.env.REACT_APP_BETA_URL;

const AlltimeIngested = ({ tableId }: { tableId: number }) => {
  const { data: count, isLoading } = useGetBigqueryTablesIngestedCountQuery(tableId);
  if (isLoading) return <Loader active inline />;
  if (typeof count !== 'number') return <Label color="red">Failed to load count</Label>;
  return <>{count}</>;
};

const ListDatasetsBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: tables } = useListBigqueryTablesQuery({ limit, offset: limit * (currentPage - 1) });

  useEffect(() => {
    let count = 1;
    if (typeof tables !== 'undefined') {
      count = Math.ceil(tables.total / limit);
    }
    setPageCount(count);
  }, [tables, limit, setPageCount]);

  return tables?.data.map(t => (
    <Table.Row key={t.id}>
      <Table.Cell>{t.name}</Table.Cell>
      <Table.Cell>
        {!t.slug ? <span style={{ color: theme.gray }}>Not Available</span> : `${betaUrl}/api/datasets/${t.slug}`}
      </Table.Cell>
      <Table.Cell>
        <StatsGroup size="mini">
          <Statistic>
            <Statistic.Value>
              <AlltimeIngested tableId={t.id} />
            </Statistic.Value>
          </Statistic>
        </StatsGroup>
      </Table.Cell>
      <Table.Cell>
        <Stats success={t.ingested_24h_success} error={t.ingested_24h_error} />
      </Table.Cell>
      <Table.Cell>
        <Stats success={t.ingested_1h_success} error={t.ingested_1h_error} />
      </Table.Cell>
      <Table.Cell>
        <Stats success={t.ingested_5m_success} error={t.ingested_5m_error} />
      </Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        {!!t.slug && (
          <>
            {/* <DatasetUploadModal table={t} /> */}
            <Link to={{ pathname: `/datasets/v1/${t.id}` }}>
              <Button as="div" color="blue" icon title="Edit Dataset">
                <Icon name="pencil" />
              </Button>
            </Link>
            <DeleteDataset id={t.id} />
          </>
        )}
      </Table.Cell>
    </Table.Row>
  ));
};

export default ListDatasetsBody;
