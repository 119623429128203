import { Grid, Header, Message, Segment } from 'semantic-ui-react';

import PaginatedTable from 'src/components/PaginatedTable';
import { Account, BillingConnectProType } from 'src/types';
import { accountConnectProBillingIsCurrent } from 'src/utils';
import AccountBillingHistoryOverview from './AccountBillingHistoryOverview';
import AccountBillingSubscriptionPaymentsListBody from './AccountBillingSubscriptionPaymentsListBody';
import AutoPay from './AutoPay';
import BillingConnectProTransactionsListBody from './BillingConnectProTransactionsListBody';
import CurrentBalance from './CurrentBalance';
import MonthlySubscription from './MonthlySubscription';

type Props = {
  account: Account;
};

const AccountBillingConnectProPane = ({ account: a }: Props) => {
  return (
    <>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Segment>
              {!a.billing.connectPro.enabled ? (
                <Message success>
                  <Message.Header>🎉 Free Tier Active</Message.Header>
                  <Message.Content>No payment required at this time.</Message.Content>
                </Message>
              ) : (
                <>
                  {!accountConnectProBillingIsCurrent(a) && (
                    <Message error>
                      <Message.Header>ConnectPro Balance At or Below Zero</Message.Header>
                      <Message.Content>Please add funds to your account to keep using all features.</Message.Content>
                    </Message>
                  )}

                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        {a.billing.connectPro.type === BillingConnectProType.monthly ? (
                          <MonthlySubscription account={a} />
                        ) : (
                          <CurrentBalance
                            account={a}
                            billingConfig="connectPro"
                            currentBalance={a.currentBalanceConnectPro || 0}
                          />
                        )}
                      </Grid.Column>

                      <Grid.Column>
                        <AutoPay account={a} billingConfig="connectPro" />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </>
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <AccountBillingHistoryOverview />

      <Header as="h4">
        ConnectPro {a.billing.connectPro.type === BillingConnectProType.monthly ? 'Subscription Payment' : 'Billing'}{' '}
        History
      </Header>

      {a.billing.connectPro.type === BillingConnectProType.monthly ? (
        <PaginatedTable
          headers={['Details', 'Created At', 'Amount', 'Status', '']}
          renderBody={AccountBillingSubscriptionPaymentsListBody}
        />
      ) : (
        <PaginatedTable
          headers={['Details', 'Created At', 'Amount', '']}
          renderBody={BillingConnectProTransactionsListBody}
        />
      )}
    </>
  );
};

export default AccountBillingConnectProPane;
