import { useCallback, useState } from 'react';
import { DropdownItemProps, Form, FormSelectProps, StrictFormSelectProps } from 'semantic-ui-react';

import { useListAccountsQuery } from 'src/api/admin/accounts';

// NOTE: Even though FormSelectProps extends StrictFormSelectProps itself...
// for some reason, Omit<FormSelectProps, 'options'> alone was not providing type hints
// when using <SelectAccounts /> in other components.
type Props = Omit<FormSelectProps, 'options'> &
  Omit<StrictFormSelectProps, 'options'> & {
    omitAccountIds?: number[];
  };

const SelectAccounts = ({ omitAccountIds = [], onChange: parentOnChange, ...selectProps }: Props) => {
  const [search, setSearch] = useState('');
  const { data: accounts, isLoading } = useListAccountsQuery({ limit: 0, offset: 0, enabled: true, search });

  const onChange = useCallback(
    (event, data) => {
      if (typeof parentOnChange !== 'undefined') {
        parentOnChange(event, data);
      }
      setSearch('');
    },
    [parentOnChange]
  );

  const options: DropdownItemProps[] =
    accounts?.data.map(a => ({ key: a.id, text: a.name, value: a.id })).filter(a => !omitAccountIds.includes(a.key)) ||
    [];

  return (
    <Form.Select
      {...selectProps}
      options={options}
      search
      searchQuery={search}
      onSearchChange={(_, { searchQuery }) => setSearch(searchQuery)}
      onChange={onChange}
      loading={isLoading}
    />
  );
};

export default SelectAccounts;
