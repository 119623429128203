import { useQuery } from 'react-query';

import { PaginatedResponse, VoiceConfig } from 'src/types';
import { ApiResponse, http } from '../http-common';

type VoiceConfigsResponse = ApiResponse & {
  configs: PaginatedResponse<VoiceConfig>;
};

type ListVoiceConfigsParams = {
  limit: number;
  offset: number;
  accountID: number;
};

export const useListVoiceConfigsQuery = (params: ListVoiceConfigsParams) => {
  return useQuery(['admin/voice', 'configs', params], async () => {
    const res = await http.get<VoiceConfigsResponse>(`/api/admin/accounts/${params.accountID}/voice/configs`, {
      params,
    });
    return res.data.configs;
  });
};
