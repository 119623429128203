import { capitalize } from 'lodash';
import { Header, Message, Tab } from 'semantic-ui-react';
import styled from 'styled-components';

import ScheduleTableForm, { getInitialScheduleData } from 'src/pages/user/models/schedule/ScheduleTableForm';
import { Account, BusinessProfile, BusinessProfileContact, CallCenter } from 'src/types';

const ProfileLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;
`;

const ProfileItem = styled.div`
  label {
    color: ${p => p.theme.gray};
    font-size: 0.8rem;
  }

  p {
    margin-bottom: 0 !important;
  }
`;

type Props = {
  account: Account;
};

const Unknown = () => <span style={{ opacity: 0.15 }}>Unknown</span>;

const BusinessProfileTabPane = ({ profile }: { profile: BusinessProfile }) => {
  return (
    <Tab.Pane>
      <ProfileLayout>
        {(Object.keys(profile) as (keyof BusinessProfile)[]).map(key => {
          if (['id', 'primary_contact', 'secondary_contact'].includes(key)) {
            return null;
          }

          return (
            <ProfileItem key={key}>
              <label>{capitalize(key.replace(/_/g, ' '))}</label>
              <p>{profile[key] || <Unknown />}</p>
            </ProfileItem>
          );
        })}
      </ProfileLayout>
    </Tab.Pane>
  );
};

const BusinessProfileContactData = ({ contact }: { contact: BusinessProfileContact }) => {
  return (
    <ProfileLayout>
      {(Object.keys(contact) as (keyof BusinessProfileContact)[]).map(key => {
        if (typeof contact[key] !== 'string') {
          return null;
        }

        return (
          <ProfileItem key={key}>
            <label>{capitalize(key.replace(/_/g, ' '))}</label>
            <p>{contact[key] || <Unknown />}</p>
          </ProfileItem>
        );
      })}
    </ProfileLayout>
  );
};

const BusinessProfileContactsTabPane = ({ profile }: { profile: BusinessProfile }) => {
  return (
    <Tab.Pane>
      <Header as="h4">Primary Contact</Header>
      <BusinessProfileContactData contact={profile.primary_contact} />

      <Header as="h4">Secondary Contact</Header>
      <BusinessProfileContactData contact={profile.secondary_contact} />
    </Tab.Pane>
  );
};

const BusinessProfileCallCenterTabPane = ({ callCenter }: { callCenter: CallCenter }) => {
  return (
    <Tab.Pane>
      <ProfileLayout style={{ marginBottom: '1rem' }}>
        <ProfileItem>
          <label>Inbound phone number</label>
          <p>{callCenter.inbound_phone_number || <Unknown />}</p>
        </ProfileItem>
      </ProfileLayout>

      <ScheduleTableForm
        schedule={callCenter.schedule || getInitialScheduleData()}
        setSchedule={() => {
          //
        }}
      />
    </Tab.Pane>
  );
};

const OnboardingIncompleteMessage = () => {
  return (
    <Message error>
      <Message.Header>Account Onboarding Incomplete</Message.Header>
      <Message.Content>This account has not completed the onboarding process.</Message.Content>
    </Message>
  );
};

const AdminAccountOnboarding = ({ account }: Props) => {
  const bp = account.business_profile;
  const cc = account.call_center;

  if (!bp || !cc) {
    return <OnboardingIncompleteMessage />;
  }

  return (
    <div>
      {account.onboarding_completed_at === null && <OnboardingIncompleteMessage />}

      <Tab
        panes={[
          {
            menuItem: 'Business Profile',
            render: () => <BusinessProfileTabPane profile={bp} />,
          },
          {
            menuItem: 'Contacts',
            render: () => <BusinessProfileContactsTabPane profile={bp} />,
          },
          {
            menuItem: 'Call Center',
            render: () => <BusinessProfileCallCenterTabPane callCenter={cc} />,
          },
        ]}
      />
    </div>
  );
};

export default AdminAccountOnboarding;
