import { useEffect, useState } from 'react';
import { Edge, Node } from 'react-flow-renderer';
import { DropdownItemProps } from 'semantic-ui-react';

import { NodeTypes } from './types';

export const hasConnectedOutputWidget = (edges: Edge<any>[], nodes: Node<NodeTypes>[]) => {
  const outputWidgetsIDs = nodes.reduce((acc, node) => {
    if (node.type === '_output') {
      return [...acc, node.id];
    }
    return acc;
  }, [] as string[]);

  if (outputWidgetsIDs.length === 0) return false;

  const connectedEdges = edges.filter(edge => outputWidgetsIDs.includes(edge.target));

  return connectedEdges.length >= 1;
};

export const getConnectedNodes = (edges: Edge<any>[], nodes: Node<NodeTypes>[], nodeId: string) => {
  const connectedSources = edges.reduce((acc, edge) => {
    if (edge.target === nodeId) {
      acc.push(edge.source);
    }
    return acc;
  }, [] as string[]);

  return nodes.filter(node => connectedSources.includes(node.id));
};

type Source = {
  table: string;
  columns: string[];
  nodeID: string;
};

export const getConnectedSources = (edges: Edge<any>[], nodes: Node<NodeTypes>[], nodeId: string) => {
  const connectedSources = getConnectedNodes(edges, nodes, nodeId);

  const sources = connectedSources.reduce((acc, node) => {
    const columns = node.data.datasetColumnsOutput || [];

    const sources = {
      table: node.data.table || '',
      columns: columns.map(column => column.value as string),
      nodeID: node.id,
    };

    return [...acc, sources];
  }, [] as Source[]);

  return sources;
};

export const getConnectedSourcesColumns = (edges: Edge<any>[], nodes: Node<NodeTypes>[], nodeId: string) => {
  const connectedSources = getConnectedNodes(edges, nodes, nodeId);

  // We are using a map to remove duplicates options
  const sourceColumnsMap = connectedSources.reduce((acc, node) => {
    const columns = node.data.datasetColumnsOutput || [];

    const values = columns.reduce(
      (acc, column) => ({
        ...acc,
        [column.key]: column,
      }),
      {}
    );

    return {
      ...acc,
      ...values,
    };
  }, {} as { [key: string]: DropdownItemProps });

  return Object.values(sourceColumnsMap);
};

export const useGetDatasetsTableID = (edges: Edge<any>[], nodes: Node<NodeTypes>[], nodeId: string) => {
  const [datasetsTableID, setDatasetsTableID] = useState<string[]>([]);

  useEffect(() => {
    const connectedSources = getConnectedNodes(edges, nodes, nodeId);

    const datasetsTableID = connectedSources.reduce(
      (acc, node) => (node.data.table ? [...acc, node.data.table] : acc),
      [] as string[]
    );

    setDatasetsTableID(datasetsTableID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeId]);

  return datasetsTableID;
};
