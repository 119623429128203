import { formatDistanceToNow } from 'date-fns';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import {
  Button,
  Dropdown,
  DropdownItemProps,
  Form,
  Header,
  Icon,
  Input,
  Label,
  Placeholder,
  PlaceholderParagraph,
  Table,
} from 'semantic-ui-react';
import styled from 'styled-components';

import { useGetTelnyxPhonesQuery, useGetTelnyxTexmlAppsQuery } from 'src/api/auth/account-telnyx';
import { useListVoiceConfigsQuery } from 'src/api/voice-configs';
import PaginatedTable, { RenderProps } from 'src/components/PaginatedTable';
import { Row } from 'src/styles';
import theme from 'src/styles/theme';
import { TelnyxPhone, TelnyxTexmlApp, VoiceConfig } from 'src/types';
import TelnyxPhonesRefetchButton from './TelnyxPhonesRefetchButton';
import TelnyxReassignVoiceConfigModal from './TelnyxReassignVoiceConfigModal';
import TelnyxReleasePhonesModal from './TelnyxReleasePhonesModal';

const { REACT_APP_BETA_URL: betaURL } = process.env;
const DOWNLOAD_URL = `${betaURL}/api/auth/account/telnyx/phones/download`;
const TABLE_HEADERS = ['Purchase Date', 'Phone Number', 'Configuration'];

const ClippedText = styled.span`
  max-width: 500px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

type TableBodyProps = RenderProps & {
  isSidSelected: (sid: string) => boolean;
  search: string;
  selectedDates: [Date, Date] | [null, null];
  profiles: string[];
  setSids: Dispatch<SetStateAction<{ sid: string; checked: boolean }[]>>;
  updateSelectedSid: (sid: string, checked: boolean) => void;
};

const TableBody = ({
  currentPage,
  isSidSelected,
  limit,
  search,
  selectedDates,
  setPageCount,
  setSids,
  profiles,
  updateSelectedSid,
}: TableBodyProps) => {
  const { data: vcData, isLoading: vcLoading } = useListVoiceConfigsQuery({ limit: 1000, offset: 0 });
  const { data: ipData, isLoading: ipLoading } = useGetTelnyxPhonesQuery({
    limit,
    offset: limit * (currentPage - 1),
    search,
    profiles,
    startDate: selectedDates[0] !== null ? selectedDates[0].toISOString() : '',
    endDate: selectedDates[1] !== null ? selectedDates[1].toISOString() : '',
  });
  const { data: appData, isLoading: appsLoading } = useGetTelnyxTexmlAppsQuery();

  const isLoading = vcLoading || ipLoading || appsLoading;

  useEffect(() => {
    const phones = ipData?.phones?.data;
    if (!phones) return;

    setSids(phones.map(p => ({ sid: p.sid, checked: false })));
  }, [ipData?.phones?.data, setSids, updateSelectedSid]);

  useEffect(() => {
    const total = ipData?.phones?.total || 1;
    const count = Math.ceil(total / limit);
    setPageCount(count);
  }, [ipData, limit, setPageCount]);

  const incomingPhones = useMemo<TelnyxPhone[]>(() => ipData?.phones?.data || [], [ipData?.phones?.data]);

  const texmlApps = useMemo(() => {
    const apps = new Map<string, TelnyxTexmlApp>();

    if (appsLoading || !appData?.apps) return apps;

    appData.apps.forEach(a => {
      apps.set(a.id, a);
    });

    return apps;
  }, [appData?.apps, appsLoading]);

  const appToVoiceConfig = useMemo(() => {
    const m = new Map<string, VoiceConfig>();

    if (isLoading || !appData?.apps?.length || !vcData?.data?.length) return m;

    appData.apps.forEach(a => {
      a.friendly_name
        .split(' ')
        .filter(s => s.startsWith('vcid:'))
        .forEach(s => {
          const vcID = s.split(':')[1];
          const vc = vcData?.data?.find(c => c.id === vcID);
          if (!vc) return;
          m.set(a.id, vc);
        });
    });

    return m;
  }, [isLoading, appData?.apps, vcData?.data]);

  const onCheckPhone = useCallback(
    (sid: string) => {
      const checked = !isSidSelected(sid);
      updateSelectedSid(sid, checked);
    },
    [isSidSelected, updateSelectedSid]
  );

  if (isLoading) {
    return (
      <>
        {Array(10)
          .fill(0)
          .map((_, i) => (
            <Table.Row key={i} style={{ position: 'relative', height: '48px' }}>
              <Table.Cell>
                <Placeholder>
                  <PlaceholderParagraph>
                    <Placeholder.Line />
                  </PlaceholderParagraph>
                </Placeholder>
              </Table.Cell>
              {TABLE_HEADERS.map(h => (
                <Table.Cell key={h}>
                  <Placeholder>
                    <PlaceholderParagraph>
                      <Placeholder.Line />
                    </PlaceholderParagraph>
                  </Placeholder>
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
      </>
    );
  }

  return (
    <>
      {incomingPhones.map(p => (
        <Table.Row key={p.phone_number}>
          <Table.Cell>
            <Form.Checkbox value={p.sid} checked={isSidSelected(p.sid)} onChange={() => onCheckPhone(p.sid)} />
          </Table.Cell>
          <Table.Cell>
            <Label title={p.created_at}>
              {formatDistanceToNow(new Date(p.created_at), { addSuffix: true, includeSeconds: true })}
            </Label>
          </Table.Cell>
          <Table.Cell>{p.phone_number}</Table.Cell>
          <Table.Cell>
            <Row>
              <strong style={{ width: 100 }}>TeXML App</strong>
              <ClippedText style={!p.connection_id ? { color: theme.gray } : undefined}>
                {texmlApps.get(p.connection_id)?.friendly_name || 'Unconfigured'}
              </ClippedText>
            </Row>
            <Row>
              <strong style={{ width: 100 }}>Voice Config</strong>
              <ClippedText style={!p.connection_id ? { color: theme.gray } : undefined}>
                {appToVoiceConfig.get(p.connection_id)?.name || 'Unconfigured'}
              </ClippedText>
            </Row>
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

const getSelectedDates = ([start, end]: [Date | null, Date | null]): [Date, Date] | [null, null] => {
  if (start === null || end === null) return [null, null];
  return [start, end];
};

const TelnyxPhonesPaginatedTable = () => {
  const [search, setSearch] = useState('');
  const [daterange, setDaterange] = useState<[Date | null, Date | null]>([null, null]);
  const [profiles, setProfiles] = useState<string[]>([]);
  const [sids, setSids] = useState<{ sid: string; checked: boolean }[]>([]);
  const [isReassignVoiceConfigModalOpen, setIsReassignVoiceConfigModalOpen] = useState(false);
  const [isReleaseModalOpen, setIsReleaseModalOpen] = useState(false);
  const { data: vcData, isLoading: vcLoading } = useListVoiceConfigsQuery({ limit: 1000, offset: 0 });
  const { data: appData, isLoading: appsLoading } = useGetTelnyxTexmlAppsQuery();

  const isLoading = vcLoading || appsLoading;

  const profileOptions = useMemo(() => {
    const apps: DropdownItemProps[] = [];

    if (isLoading || !appData?.apps?.length || !vcData?.data?.length) return apps;

    appData.apps.forEach(a => {
      a.friendly_name
        .split(' ')
        .filter(s => s.startsWith('vcid:'))
        .forEach(s => {
          const vcID = s.split(':')[1];
          const vc = vcData?.data?.find(c => c.id === vcID);
          if (!vc) return;
          apps.push({
            key: a.id,
            value: `connection_id:${a.id}`,
            text: vc.name,
            description: 'Voice Config',
          });
        });
    });

    return apps;
  }, [isLoading, appData?.apps, vcData?.data]);

  const selectedDates = getSelectedDates(daterange);

  const updateSelectedSid = useCallback(
    (sid: string, checked: boolean) =>
      setSids(prev => {
        return [...prev].map(s => {
          if (s.sid !== sid) return s;
          s.checked = checked;
          return s;
        });
      }),
    []
  );

  const isSidSelected = useCallback(
    (sid: string) => {
      return sids.filter(s => s.sid === sid && s.checked).length > 0;
    },
    [sids]
  );

  const onSelectAll = useCallback((checked: boolean) => {
    setSids(prev => {
      return [...prev].map(s => {
        s.checked = checked;
        return s;
      });
    });
  }, []);

  const onChangeDates = useCallback((daterange: [Date | null, Date | null]) => {
    setDaterange(daterange);
  }, []);

  const selectedSids = sids.filter(s => s.checked).map(s => s.sid);
  const allSelected = sids.length > 0 && selectedSids.length === sids.length;

  return (
    <>
      <Row style={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
        <Header style={{ marginBottom: 0 }}>Active Numbers</Header>

        <div>
          <Button as="a" href={DOWNLOAD_URL} basic disabled>
            <Icon name="download" /> Export CSV
          </Button>

          <Dropdown button floating text="Actions" disabled={selectedSids.length === 0} direction="left">
            <Dropdown.Menu>
              <Dropdown.Item
                key="release"
                text={`Release ${selectedSids.length} Selected`}
                disabled={selectedSids.length === 0}
                onClick={() => setIsReleaseModalOpen(true)}
              />
              <Dropdown.Item
                key="reassign-voice-config"
                text={`Reassign Voice Config to ${selectedSids.length} Selected`}
                disabled={selectedSids.length === 0}
                onClick={() => setIsReassignVoiceConfigModalOpen(true)}
              />
            </Dropdown.Menu>
          </Dropdown>

          <TelnyxPhonesRefetchButton />

          <TelnyxReleasePhonesModal
            sids={selectedSids}
            open={isReleaseModalOpen}
            onSuccess={() => setSids([])}
            onClose={() => setIsReleaseModalOpen(false)}
          />
          <TelnyxReassignVoiceConfigModal
            sids={selectedSids}
            open={isReassignVoiceConfigModalOpen}
            onSuccess={() => setSids([])}
            onClose={() => setIsReassignVoiceConfigModalOpen(false)}
          />
        </div>
      </Row>

      <Form onSubmit={e => e.preventDefault()}>
        <Form.Group>
          <Form.Input
            placeholder="Search by digits"
            value={search}
            onChange={(_, { value }) => setSearch(String(value))}
          />

          <div className="field" style={{ width: 240, minWidth: 240 }}>
            <ReactDatePicker
              selectsRange
              startDate={daterange[0]}
              endDate={daterange[1]}
              placeholderText="Purchase Date"
              // TODO: this line is causing the following warning:
              // "Warning: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?"
              customInput={<Input fluid icon="calendar" iconPosition="left" />}
              monthsShown={2}
              showPopperArrow={false}
              onChange={onChangeDates}
            />
          </div>

          <Dropdown
            placeholder="All Voice Configs"
            selection
            options={profileOptions}
            multiple
            clearable
            search
            onChange={(_, { value }) => setProfiles(value as string[])}
            value={profiles}
            loading={isLoading}
            style={{ minWidth: 300, maxWidth: 800 }}
            renderLabel={itme => ({
              content: itme.text,
              detail: itme.description,
            })}
          />

          <Form.Button color="blue" icon>
            <Icon name="search" />
          </Form.Button>
        </Form.Group>
      </Form>

      <PaginatedTable
        checkbox
        onSelectAll={onSelectAll}
        allSelected={allSelected}
        headers={TABLE_HEADERS}
        renderBody={(props: RenderProps) => (
          <TableBody
            {...props}
            selectedDates={selectedDates}
            updateSelectedSid={updateSelectedSid}
            isSidSelected={isSidSelected}
            setSids={setSids}
            search={search}
            profiles={profiles}
          />
        )}
      />
    </>
  );
};

export default TelnyxPhonesPaginatedTable;
