import { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useDuplicateWebhookConfigMutation } from 'src/api/webhooks';

type Props = {
  id: string;
};

const DuplicateWebhookConfig = ({ id }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync, isLoading } = useDuplicateWebhookConfigMutation();

  const onClick = async () => {
    setApiMessage(undefined);
    try {
      await mutateAsync(id);
    } catch (e) {
      apiErrorHandler(e, setApiMessage);
      setTimeout(() => setApiMessage(undefined), 5000);
    }
  };

  if (apiMessage) {
    return (
      <Button type="button" color="red">
        {apiMessage.message}
      </Button>
    );
  }

  return (
    <Button color="green" icon title="Duplicate" onClick={onClick} loading={isLoading}>
      <Icon name="copy" />
    </Button>
  );
};

export default DuplicateWebhookConfig;
