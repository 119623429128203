import { List } from 'semantic-ui-react';
import styled from 'styled-components';

export const ContentInfoContainer = styled(List)`
  padding: 0.5rem !important;
`;

export const ContentInfoItem = styled(List.Item)`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #727478;
`;
