import { useEffect } from 'react';
import { Table } from 'semantic-ui-react';

import { useListAccountsQuery } from 'src/api/accounts';
import { useGetUserProfileQuery } from 'src/api/auth';
import { RenderProps } from 'src/components/PaginatedTable';
import SwitchAccountButton from 'src/components/SwitchAccountButton';
import { Account } from 'src/types';

const getAccountRoles = (account: Account, user_id?: number): string[] => {
  let roles: string[] = [];

  if (!user_id) {
    return roles;
  }

  const [userAccess] = account.user_access.filter(ua => ua.user_id === user_id);
  if (typeof userAccess !== 'undefined') {
    roles = userAccess.roles;
  }

  return roles;
};

const AccountsListBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: accounts } = useListAccountsQuery({ limit, offset: limit * (currentPage - 1) });

  const { data: user } = useGetUserProfileQuery();

  useEffect(() => {
    let count = 1;
    if (typeof accounts !== 'undefined') {
      count = Math.ceil(accounts.total / limit);
    }
    setPageCount(count);
  }, [accounts, limit, setPageCount]);

  return accounts?.data.map(account => (
    <Table.Row key={account.id}>
      <Table.Cell>{account.name}</Table.Cell>
      <Table.Cell>{getAccountRoles(account, user?.id).join(', ')}</Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        <SwitchAccountButton id={account.id} />
      </Table.Cell>
    </Table.Row>
  ));
};

export default AccountsListBody;
