import { useCallback, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Modal } from 'semantic-ui-react';

import { useRunDataflowMutation } from 'src/api/dataflows';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';

type RunDataflowProps = {
  open: boolean;
  onClose: () => void;
};

const RunDataflow = ({ onClose: parentOnClose, open }: RunDataflowProps) => {
  const { id } = useParams<{ id: string }>();
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { isLoading, mutateAsync } = useRunDataflowMutation();
  const { push } = useHistory();

  const timeout = useRef<NodeJS.Timeout>();

  const onClose = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = undefined;
    }
    // setApiMessage(undefined);
    // parentOnClose();
    push(`/dataflows/${id}/history`);
  }, [id, push]);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    try {
      const { message } = await mutateAsync({ dataflowId: Number(id) });
      setApiMessage({ success: true, status: 200, message });
      timeout.current = setTimeout(onClose, 2000);
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [id, mutateAsync, onClose]);

  return (
    <Modal size="tiny" open={open} onClose={onClose}>
      <Modal.Header>Run Dataflow</Modal.Header>
      <Modal.Content>
        <p>
          Running this dataflow will use the last published version of the dataflow. If you want to use the most
          up-to-date configuration, be sure to publish any unsaved changes.
        </p>

        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Form.Group widths="equal">
            {apiMessage?.success ? (
              <Form.Button fluid type="button" color="blue" onClick={onClose}>
                View History
              </Form.Button>
            ) : (
              <>
                <Form.Button loading={isLoading} color="blue" fluid>
                  Confirm
                </Form.Button>
                <Form.Button fluid type="button" onClick={onClose}>
                  Cancel
                </Form.Button>
              </>
            )}
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default RunDataflow;
