import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { Form, Grid, Message } from 'semantic-ui-react';

import { useListAccountsQuery } from 'src/api/admin/accounts';
import { useGetUserProfileQuery } from 'src/api/auth';
import Breadcrumbs from 'src/components/Breadcrumbs';
import PaginatedTable from 'src/components/PaginatedTable';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { Container, Header, Row } from 'src/styles';
import { accountLeadscorePlusBillingIsCurrent } from 'src/utils';
import { ListLeadscoreConfigFilters } from '../../../../../api/admin/leadscore-plus';
import AddLeadscoreConfig from './AdminAddLeadscoreConfig';
import ListLeadscoreConfigsBody from './AdminListLeadscoreConfigsBody';

export const STATUS_FILTERS = [
  { key: 'enabled', value: true, text: 'Enabled' },
  { key: 'disabled', value: false, text: 'Disabled' },
];

const getFiltersFromQuery = (query: URLSearchParams): ListLeadscoreConfigFilters => {
  let accountId = Number(query.get('accountId'));
  if (query.get('accountId') === '' || isNaN(accountId)) {
    accountId = -1;
  }
  let enabled;
  switch (query.get('enabled')) {
    case '1':
      enabled = true;
      break;
    case '0':
      enabled = false;
      break;
    default:
      enabled = undefined;
      break;
  }
  return { accountId, enabled };
};

const AdminListLeadscoreConfigs = () => {
  const { push, replace } = useHistory();
  const { data: user } = useGetUserProfileQuery();
  const query = useSearchQuery();
  const [filters, setFilters] = useState<ListLeadscoreConfigFilters>(() => getFiltersFromQuery(query));
  const a = user?.active_account;
  const { data: accounts, isLoading: accountsLoading } = useListAccountsQuery({
    limit: 1000,
    offset: 0,
    enabled: true,
  });

  useEffect(() => {
    let shouldReplace = false;

    if (!query.has('accountId')) {
      query.set('accountId', '');
      shouldReplace = true;
    }

    if (!query.has('enabled')) {
      query.set('enabled', '1');
      shouldReplace = true;
    }

    if (shouldReplace) {
      replace({ search: query.toString() });
    }
  });

  useEffect(() => {
    setFilters(getFiltersFromQuery(query));
  }, [query]);

  const onSubmit = useCallback(() => {
    query.set('page', '1');
    push({ search: query.toString() });
  }, [push, query]);

  const accountOptions = [
    { key: -1, text: 'All', value: -1 },
    { key: 0, text: 'System', value: 0 },
    ...(accounts?.data.map(a => ({ key: a.id, text: a.name, value: a.id })) || []),
  ];

  return (
    <Container>
      <Helmet>
        <title>Configs - LeadScore+ | datascore</title>
      </Helmet>

      {a && !accountLeadscorePlusBillingIsCurrent(a) && (
        <Grid>
          <Grid.Row centered>
            <Grid.Column width={8}>
              <Message error>
                <Message.Header>LeadScore+ Balance At or Below Zero</Message.Header>
                <Message.Content>Please add funds to your account to keep using all features.</Message.Content>
              </Message>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header style={{ marginBottom: 0 }}>
          <Breadcrumbs crumbs={['Admin', 'LeadScore+', 'Configs']} />
        </Header>

        <Row>
          <AddLeadscoreConfig />
        </Row>
      </Row>

      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Dropdown
            label="Status"
            placeholder="All"
            selection
            clearable
            options={STATUS_FILTERS}
            value={typeof filters.enabled === 'undefined' ? '' : filters.enabled}
            onChange={(_, { value }) => {
              let enabled = '';
              switch (value) {
                case '':
                  enabled = '';
                  break;
                default:
                  enabled = Number(value).toString();
                  break;
              }

              query.set('enabled', enabled);
              query.set('page', '1');
              push({ search: query.toString() });

              // NOTE: the useEffect hook above (watching query) will set the filter state for us
            }}
          />

          <Form.Dropdown
            loading={accountsLoading}
            label="Account"
            placeholder="All"
            selection
            search
            clearable
            options={accountOptions}
            value={filters.accountId !== -1 ? filters.accountId : ''}
            onChange={(_, { value }) => {
              let accountId = Number(value);
              if (value === '' || isNaN(accountId)) {
                accountId = -1;
              }

              query.set('accountId', accountId !== -1 ? accountId.toString() : '');
              query.set('page', '1');
              push({ search: query.toString() });

              // NOTE: the useEffect hook above (watching query) will set the filter state for us
            }}
          />
        </Form.Group>
      </Form>

      <PaginatedTable
        headers={['Status', 'Name', 'Account', '']}
        renderBody={props => <ListLeadscoreConfigsBody {...props} filters={filters} />}
      />
    </Container>
  );
};

export default AdminListLeadscoreConfigs;
