import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { DropdownItemProps, Form } from 'semantic-ui-react';

import { TwilioCallStatsParams } from 'src/api/admin/account-twilio';
import { ListAccountsFilters } from 'src/api/admin/accounts';
import Breadcrumbs from 'src/components/Breadcrumbs';
import PaginatedTable, { RenderProps } from 'src/components/PaginatedTable';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { Container, Header, Row } from 'src/styles';
import { ReportingTimeRange, ReportingTimeRanges } from 'src/types';
import { reportingTimeRangeToDateStrings } from 'src/utils';
import AdminAccountsTwilioCallStatsBody from './AdminAccountsBandwidthCallStatsBody';

export const STATUS_FILTERS = [
  { key: 'enabled', value: true, text: 'Enabled' },
  { key: 'disabled', value: false, text: 'Disabled' },
];

export const DirectionOptions: DropdownItemProps[] = [
  { key: 'outbound-api', value: 'outbound-api', text: 'Outbound API' },
  { key: 'outbound-dial', value: 'outbound-dial', text: 'Outbound Dial' },
  { key: 'inbound', value: 'inbound', text: 'Inbound' },
];

export const StatusOptions: DropdownItemProps[] = [
  { key: 'busy', value: 'busy', text: 'Busy' },
  { key: 'failed', value: 'failed', text: 'Failed' },
  { key: 'no-answer', value: 'no-answer', text: 'No Answer' },
];

const getFiltersFromQuery = (query: URLSearchParams): ListAccountsFilters => {
  let enabled;
  switch (query.get('enabled')) {
    case '1':
      enabled = true;
      break;

    case '0':
      enabled = false;
      break;

    default:
      enabled = undefined;
      break;
  }

  return { enabled };
};

const getInitialStatsFilters = (): TwilioCallStatsParams => {
  const defaultTimeRange = 'today';

  const [startDate, endDate] = reportingTimeRangeToDateStrings(defaultTimeRange);

  return {
    directions: ['outbound-api', 'inbound'],
    statuses: ['busy', 'failed', 'no-answer'],
    timeRange: defaultTimeRange,
    startDate,
    endDate,
  };
};

const AdminReportingBandwidthCallStats = () => {
  const { push, replace } = useHistory();
  const query = useSearchQuery();
  const [filters, setFilters] = useState<ListAccountsFilters>(() => getFiltersFromQuery(query));
  const [statsFilters, setStatsFilters] = useState<TwilioCallStatsParams>(() => getInitialStatsFilters());

  useEffect(() => {
    if (!query.has('enabled')) {
      query.set('enabled', '1');
      replace({ search: query.toString() });
    }
  });

  useEffect(() => {
    setFilters(getFiltersFromQuery(query));
  }, [query]);

  const onChangeTimeRange = useCallback((_, { value }) => {
    const [startDate, endDate] = reportingTimeRangeToDateStrings(value as ReportingTimeRange);
    setStatsFilters(prev => ({ ...prev, timeRange: value as ReportingTimeRange, startDate, endDate }));
  }, []);

  return (
    <Container>
      <Helmet>
        <title>Twilio Call Stats - Reporting - Admin | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header style={{ marginBottom: 0 }}>
          <Breadcrumbs crumbs={['Admin', 'Reporting', 'Bandwidth Call Stats']} />
        </Header>
      </Row>

      <Form>
        <Form.Group>
          <Form.Select
            label="Status"
            placeholder="All"
            clearable
            options={STATUS_FILTERS}
            value={typeof filters.enabled === 'undefined' ? '' : filters.enabled}
            onChange={(_, { value }) => {
              let enabled = '';
              switch (value) {
                case '':
                  enabled = '';
                  break;
                default:
                  enabled = Number(value).toString();
                  break;
              }

              query.set('enabled', enabled);
              query.set('page', '1');
              push({ search: query.toString() });

              // NOTE: the useEffect hook above (watching query) will set the filter state for us
            }}
          />

          <Form.Select
            label="Direction(s)"
            placeholder="All"
            clearable
            multiple
            options={DirectionOptions}
            value={statsFilters.directions}
            onChange={(_, { value }) => setStatsFilters(prev => ({ ...prev, directions: value as string[] }))}
          />

          <Form.Select
            label="Status(es)"
            placeholder="All"
            clearable
            multiple
            options={StatusOptions}
            value={statsFilters.statuses}
            onChange={(_, { value }) => setStatsFilters(prev => ({ ...prev, statuses: value as string[] }))}
          />

          <Form.Field>
            <label>
              Time Range{' '}
              <span style={{ opacity: 0.4, fontSize: '0.9em' }}>
                ({statsFilters.startDate} - {statsFilters.endDate})
              </span>
            </label>
            <Form.Select
              onChange={onChangeTimeRange}
              options={ReportingTimeRanges.filter(tr => tr.value !== 'custom').map(tr => ({
                ...tr,
                key: tr.value,
              }))}
              value={statsFilters.timeRange}
            />
          </Form.Field>
        </Form.Group>
      </Form>

      <PaginatedTable
        headers={[
          'ID',
          'Name',
          'Completed',
          'Busy / Failed / No Answer',
          'Total Seizure',
          'Answer/Seizure',
          'Avg Duration',
          'Duration <= 6s',
          'Duration >= 30s',
        ]}
        renderBody={(props: RenderProps) => (
          <AdminAccountsTwilioCallStatsBody {...props} filters={filters} statsFilters={statsFilters} />
        )}
      />
    </Container>
  );
};

export default AdminReportingBandwidthCallStats;
