import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Icon, Message } from 'semantic-ui-react';

import { useListAccountApiTokensQuery } from 'src/api/accounts';
import { useGetDatasetQuery } from 'src/api/datasets';
import Loading from 'src/components/Loading';
import { Container, Header as PageHeader, Row } from 'src/styles';
import theme from 'src/styles/theme';
import DatasetV2Docs from './DatasetV2Docs';

const DocsDatasetV2 = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const { data: dataset, isLoading: tableLoading } = useGetDatasetQuery(params.id);
  const { data: apiTokens, isLoading: tokensLoading } = useListAccountApiTokensQuery({ limit: 1, offset: 0 });

  if (tableLoading || tokensLoading) return <Loading />;

  if (!dataset) {
    return (
      <Container>
        <Helmet>
          <title>Dataset Not Found | datascore</title>
        </Helmet>

        <Message error>
          <Message.Header>🚨 Error, Not Found.</Message.Header>
          <Message.Content>Unable to locate datset for id: {decodeURIComponent(params.id)}</Message.Content>
        </Message>
      </Container>
    );
  }

  let token = '{api_token}';
  if (apiTokens && apiTokens.data.length > 0) {
    token = apiTokens.data[0].token;
  }

  return (
    <Container>
      <Helmet>
        <title>Dataset Docs | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Row style={{ alignItems: 'center' }}>
          <Button icon color="blue" onClick={history.goBack} style={{ marginRight: '1rem' }}>
            <Icon name="arrow left" />
          </Button>

          <PageHeader style={{ margin: 0 }}>
            <span style={{ color: theme.gray }}>Datasets v2</span> <span style={{ padding: '0 0.5rem' }}>/</span>{' '}
            {dataset.name}
          </PageHeader>
        </Row>
      </Row>

      <DatasetV2Docs dataset={dataset} token={token} />
    </Container>
  );
};

export default DocsDatasetV2;
