import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ApiResponse, http } from '../http-common';
import { ModelsResponse } from '../models';

export type ListModelsParams = {
  limit: number;
  offset: number;
  enabled?: number;
  accountId?: number;
};

export const useListModelsQuery = (params: ListModelsParams) => {
  return useQuery(['admin/models', params], async () => {
    const res = await http.get<ModelsResponse>('/api/admin/models', { params });
    return res.data.models;
  });
};

export const useRefreshModelsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const res = await http.post<ApiResponse>('/api/admin/models/refresh');
      return res.data;
    },
    {
      onSuccess: () => {
        // queryClient.removeQueries(['accounts', id]); // drop the cached result.
        queryClient.invalidateQueries(['admin/models']);
      },
    }
  );
};
