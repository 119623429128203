import { cloneDeep, set } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useState } from 'react';
import { Button, Form, Message, Modal } from 'semantic-ui-react';

import { useUpdateAccountMutation } from 'src/api/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Account, AccountBillingConfig, BillingAutopay } from 'src/types';
import { formatBillingAutopay } from 'src/utils';

const OPTIONS = ['500', '1000', '1500', '2000', '2500', '5000', '10000', '20000'];

type ValidationErrors = {
  threshold?: string;
  amount?: string;
};

type Props = {
  account: Account;
  billingConfig: AccountBillingConfig;
};

const AccountBillingAutoPay = ({ account, billingConfig }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData | undefined>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<BillingAutopay>(() => cloneDeep(account.billing[billingConfig].autopay));
  const { mutateAsync, isLoading } = useUpdateAccountMutation();

  const onClose = useCallback(() => {
    setApiMessage(undefined);
    setErrors({});
    setFormdata(cloneDeep(account.billing[billingConfig].autopay));
    setOpen(false);
  }, [account.billing, billingConfig]);

  const validate = useCallback((input: BillingAutopay) => {
    const autopay = formatBillingAutopay(input);

    const validationErrors: ValidationErrors = {};

    // NOTE: formatBilling() prevents this from happening now
    // if (Number.isNaN(Number(billing[billingType].autopay.threshold))) {
    //   validationErrors.threshold = 'Invalid number';
    // } else
    if (autopay.threshold < 1) {
      validationErrors.threshold = 'Please enter a positive amount';
    }

    if (autopay.amount <= 0) {
      validationErrors.amount = 'Please select an amount';
    }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onChange = useCallback(
    (_, { name, value }) => {
      setFormdata(prev => {
        const next = cloneDeep(prev);
        set(next, name, value);
        validate(next);
        return next;
      });
    },
    [validate]
  );

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    if (!isEmpty(validate(formdata))) {
      return;
    }

    try {
      const autopay = formatBillingAutopay(formdata);
      autopay.enabled = true;
      await mutateAsync({
        id: account.id,
        billing: {
          ...account.billing,
          [billingConfig]: {
            ...account.billing[billingConfig],
            autopay,
          },
        },
      });
      setApiMessage({ success: true, status: 200, message: 'Thank you! AutoPay is now active.' });
      setTimeout(onClose, 3000);
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [account.billing, account.id, billingConfig, formdata, mutateAsync, onClose, validate]);

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button type="button" color="blue" fluid>
          {account.billing[billingConfig].autopay.enabled ? 'AutoPay Settings' : 'Enroll Now'}
        </Button>
      }
    >
      <Modal.Header>Enroll in AutoPay</Modal.Header>
      <Modal.Content>
        {!account.billing.defaultPaymentMethod ? (
          <>
            <Message error>
              <Message.Header>Default Payment Method Required</Message.Header>
              <Message.Content>
                You must return to the billing screen and set a default payment method before you can enable AutoPay.
              </Message.Content>
            </Message>
            <Button type="button" color="blue" onClick={onClose} fluid>
              OK
            </Button>
          </>
        ) : apiMessage?.success === true ? (
          <>
            <ApiMessage data={apiMessage} />
            <Button type="button" color="blue" onClick={onClose} fluid>
              Done
            </Button>
          </>
        ) : (
          <>
            <Form onSubmit={onSubmit}>
              <ApiMessage data={apiMessage} />

              <Form.Field>
                <label>Threshold</label>
                <p>
                  When you account balance drops below this number we will automatically charge the default payment
                  method selected on your account. If the account balance is greater than the Auto Pay amount then the
                  account will be charged the sum of the balance and the Auto Pay amount.
                </p>

                <Form.Input name="threshold" value={formdata.threshold} error={errors.threshold} onChange={onChange} />
              </Form.Field>

              <Form.Field>
                <label>Amount</label>
                <p>The amount of credits you would like added to your account (excluding fees).</p>

                <Form.Select
                  name="amount"
                  value={String(formdata.amount)}
                  error={errors.amount}
                  onChange={onChange}
                  clearable
                  options={OPTIONS.map(a => ({
                    key: a,
                    value: a,
                    text: '$' + Number(a).toLocaleString(),
                  }))}
                />
              </Form.Field>

              <Button fluid color="blue" loading={isLoading}>
                Enable AutoPay
              </Button>
            </Form>
          </>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default AccountBillingAutoPay;
