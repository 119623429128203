import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Icon, Message } from 'semantic-ui-react';

import { useListAccountApiTokensQuery } from 'src/api/accounts';
import { useGetBigqueryTableQuery } from 'src/api/bigquery';
import Loading from 'src/components/Loading';
import { Container, Header as PageHeader, Row } from 'src/styles';
import theme from 'src/styles/theme';
import DatasetAddColumnModal from './DatasetAddColumnModal';
import DatasetDeleteColumnModal from './DatasetDeleteColumnModal';
import DatasetDocs from './DatasetDocs';
import DatasetSettings from './DatasetSettings';

const EditDataset = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const { data: table, isLoading: tableLoading } = useGetBigqueryTableQuery(Number(params.id));
  const { data: apiTokens, isLoading: tokensLoading } = useListAccountApiTokensQuery({ limit: 1, offset: 0 });

  if (tableLoading || tokensLoading) return <Loading />;

  if (!table) {
    return (
      <Container>
        <Helmet>
          <title>Dataset Not Found | datascore</title>
        </Helmet>

        <Message error>
          <Message.Header>🚨 Error, Not Found.</Message.Header>
          <Message.Content>Unable to locate datset for id: {decodeURIComponent(params.id)}</Message.Content>
        </Message>
      </Container>
    );
  }

  let token = '{api_token}';
  if (apiTokens && apiTokens.data.length > 0) {
    token = apiTokens.data[0].token;
  }

  return (
    <Container>
      <Helmet>
        <title>Edit Dataset | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Row style={{ alignItems: 'center' }}>
          <Button icon color="blue" onClick={history.goBack} style={{ marginRight: '1rem' }}>
            <Icon name="arrow left" />
          </Button>

          <PageHeader style={{ margin: 0 }}>
            <span style={{ color: theme.gray }}>Datasets</span> <span style={{ padding: '0 0.5rem' }}>/</span>{' '}
            {table.name}
          </PageHeader>
        </Row>

        <div>
          <DatasetAddColumnModal table={table} />
          <DatasetDeleteColumnModal table={table} />
        </div>
      </Row>

      <DatasetSettings table={table} />

      <DatasetDocs table={table} token={token} />
    </Container>
  );
};

export default EditDataset;
