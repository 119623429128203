import { useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useDeleteWebhookConfigMutation } from 'src/api/webhooks';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';

type Props = {
  id: string;
};

const DeleteWebhookConfig = ({ id }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync, isLoading } = useDeleteWebhookConfigMutation();

  const onClose = () => {
    setApiMessage(undefined);
    setOpen(false);
  };

  const onSubmit = async (): Promise<void> => {
    setApiMessage(undefined);

    try {
      await mutateAsync(id);
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Modal
      size="mini"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={onClose}
      trigger={
        <Button color="red" icon title="Delete">
          <Icon name="trash" />
        </Button>
      }
    >
      <Modal.Header>Delete Config</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <p style={{ marginTop: 0 }}>
            <strong>Are you sure?</strong> This action cannot be undone.
          </p>

          <Row>
            <Button fluid type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button color="red" fluid loading={isLoading}>
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default DeleteWebhookConfig;
