import { ReactNode, useMemo, useState } from 'react';
import { Dropdown, DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';

import { useGetColumnOptions } from 'src/api/dataflows';
import { Filter } from './types';

type FilterItemProps = {
  filter: Filter;
  columns: DropdownItemProps[];
  table?: string;
  updateFilter: (filter: Filter) => void;
  action: ReactNode;
  onSave: () => void;
};

export const OPERATORS = [
  { key: 'neq', value: 'neq', text: 'Not Equal' },
  { key: 'eq', value: 'eq', text: 'Equal' },
  { key: 'in', value: 'in', text: 'IN' },
];

const FilterItem = ({ filter, columns, table, updateFilter, action, onSave }: FilterItemProps) => {
  const [customOptions, setCustomOptions] = useState<DropdownItemProps[]>([]);

  const { data: columnOptionsData } = useGetColumnOptions({
    tableID: table || '',
    column: filter?.column || '',
  });

  const multipleConditions = typeof filter.operator !== 'undefined' && filter.operator === 'in';

  const conditionsOptions: DropdownItemProps[] = useMemo(() => {
    if (!columnOptionsData?.options) return [];
    return columnOptionsData.options.map(name => ({ key: name, value: name, text: name }));
  }, [columnOptionsData?.options]);

  let conditionsValue: string | string[] = filter.conditions || [];
  if (!multipleConditions) {
    conditionsValue = conditionsValue[0] || '';
  }

  return (
    <Form.Group grouped>
      <Form.Field>
        <Dropdown
          placeholder="Column"
          disabled={!columns.length || !table}
          fluid
          search
          selection
          clearable
          defaultValue={filter.column}
          options={columns}
          onChange={(_: any, { value }: DropdownProps) => {
            updateFilter({ id: filter.id, column: String(value), conditions: [] });
            onSave();
          }}
        />
      </Form.Field>
      <Form.Field>
        <Dropdown
          placeholder="Operator"
          disabled={!columns.length || !table}
          fluid
          selection
          clearable
          defaultValue={filter.operator}
          options={OPERATORS}
          onChange={(_: any, { value }: DropdownProps) => {
            updateFilter({ id: filter.id, operator: String(value) });
            onSave();
          }}
        />
      </Form.Field>
      <Form.Field>
        <Dropdown
          placeholder="Condition"
          disabled={!columns.length || !table}
          fluid
          selection
          clearable
          search
          allowAdditions={conditionsOptions.length === 0}
          onAddItem={(_, { value }: DropdownProps) => {
            setCustomOptions([...customOptions, { key: value, text: value, value: String(value) }]);
          }}
          multiple={multipleConditions}
          options={conditionsOptions.length === 0 ? customOptions : conditionsOptions}
          value={conditionsValue}
          onChange={(_: any, { value }: DropdownProps) => {
            let conditions = [];
            if (typeof value === 'string') {
              conditions = [value];
            } else {
              conditions = value as string[];
            }
            updateFilter({ id: filter.id, conditions });
            onSave();
          }}
        />
      </Form.Field>
      {action}
    </Form.Group>
  );
};

export default FilterItem;
