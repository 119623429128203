import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Label, Table } from 'semantic-ui-react';

import { ListAccountsFilters, useListAccountsQuery } from 'src/api/admin/accounts';
import LinkWithDetail from 'src/components/LinkWithDetail';
import { RenderProps } from 'src/components/PaginatedTable';
import SwitchAccountButton from 'src/components/SwitchAccountButton';
import { Row } from 'src/styles';
import ToggleAccountEnabledButton from './ToggleAccountEnabledButton';

type Props = RenderProps & {
  filters: ListAccountsFilters;
};

const AdminAccountsListBody = ({ limit, currentPage, setPageCount, filters }: Props) => {
  const history = useHistory();
  const { data: accounts } = useListAccountsQuery({ limit, offset: limit * (currentPage - 1), ...filters });

  useEffect(() => {
    let count = 1;
    if (typeof accounts !== 'undefined') {
      count = Math.ceil(accounts.total / limit);
    }
    setPageCount(count);
  }, [accounts, limit, setPageCount]);

  return (
    <>
      {accounts?.data.map(account => {
        return (
          <Table.Row key={account.id}>
            <Table.Cell collapsing>
              <Label>{account.id}</Label>
            </Table.Cell>
            <Table.Cell collapsing>
              <ToggleAccountEnabledButton account={account} />
            </Table.Cell>
            <Table.Cell>
              <Row style={{ flexDirection: 'column' }}>
                <LinkWithDetail to={`/admin/accounts/${account.id}`} text={account.name} detail={account.ownerEmail} />
              </Row>
            </Table.Cell>
            <Table.Cell>
              {account.billing.enabled && account.billing.connectPro.enabled ? (
                <>
                  <Label color="blue">
                    {account.billing.connectPro.rate} per {account.billing.connectPro.type}
                  </Label>
                  <Label color={Number(account.currentBalanceConnectPro) <= 0 ? 'red' : undefined}>
                    {(account.currentBalanceConnectPro || 0).toFixed(2)}
                  </Label>
                </>
              ) : (
                <>
                  <Label>Disabled</Label>
                  <Label style={{ opacity: 0.5 }}>N/A</Label>
                </>
              )}
            </Table.Cell>
            <Table.Cell>
              {account.billing.enabled && account.billing.leadscorePlus.enabled ? (
                <>
                  <Label color="blue">Enabled</Label>
                  <Label color={Number(account.currentBalanceLeadscorePlus) <= 0 ? 'red' : undefined}>
                    {(account.currentBalanceLeadscorePlus || 0).toFixed(2)}
                  </Label>
                </>
              ) : (
                <>
                  <Label>Disabled</Label>
                  <Label style={{ opacity: 0.5 }}>N/A</Label>
                </>
              )}
            </Table.Cell>
            <Table.Cell textAlign="right" collapsing>
              <SwitchAccountButton id={account.id} />
              <Button
                icon="pencil"
                color="blue"
                onClick={() => history.push(`/admin/accounts/${account.id}`)}
                title="Edit"
              />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
};

export default AdminAccountsListBody;
