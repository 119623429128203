import { Helmet } from 'react-helmet-async';

import BillingWarning from 'src/components/BillingWarning';
import PaginatedTable from 'src/components/PaginatedTable';
import { Container, Header } from 'src/styles';
import ListModelsBody from './ListModelsBody';

const ModelsList = () => {
  return (
    <Container>
      <Helmet>
        <title>Models | datascore</title>
      </Helmet>

      <BillingWarning />

      <Header>Models</Header>

      <PaginatedTable headers={['Name', 'Type', 'Versions', '']} renderBody={ListModelsBody} />
    </Container>
  );
};

export default ModelsList;
