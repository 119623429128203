import { Grid, Header, Segment } from 'semantic-ui-react';

import { Account } from 'src/types';
import AccountBillingAlertsForm from './AccountBillingAlertsForm';
import AccountBillingPaymentMethods from './AccountBillingPaymentMethods';

type Props = {
  account: Account;
};

const AccountBillingGeneral = ({ account: a }: Props) => {
  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column width={6}>
            <Header as="h4">Alert Email(s)</Header>
            <p>
              If your account balance drops below $100, we will send an email notification to the email(s) listed below.
            </p>

            <AccountBillingAlertsForm account={a} />
          </Grid.Column>

          <Grid.Column width={10}>
            <Header as="h4">Payment Methods</Header>
            <AccountBillingPaymentMethods />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default AccountBillingGeneral;
