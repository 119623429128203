import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Divider, Message, Segment } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import Loading from 'src/components/Loading';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader } from 'src/styles';
import theme from 'src/styles/theme';
import TelnyxBuyNumbers from './TelnyxBuyNumbers';
import TelnyxPhonesPaginatedTable from './TelnyxPhonesPaginatedTable';

const AccountTelnyx = () => {
  const { data: user, isLoading: userLoading } = useGetUserProfileQuery();
  const telnyxProfile = useMemo(() => user?.active_account.telnyx_profile, [user]);

  const isAdmin = user?.role === 'admin';

  const canBuyPhoneNumbers = isAdmin;

  if (userLoading) return <Loading />;

  if (typeof user === 'undefined') return <NotFound />;

  const adminActionRequired = !telnyxProfile?.billing_group_id;

  if (adminActionRequired) {
    return (
      <Layout>
        <Message negative>
          <Message.Header>An admin needs to enable this feature</Message.Header>
          <Message.Content>
            Please email{' '}
            <a href="mailto:support@datascore.ai" target="_blank" rel="noreferrer">
              support@datascore.ai
            </a>{' '}
            and ask about the status of your Telnyx account setup.
          </Message.Content>
        </Message>
      </Layout>
    );
  }

  return (
    <Layout>
      {canBuyPhoneNumbers ? (
        <TelnyxBuyNumbers />
      ) : (
        <Message negative>
          <Message.Header>Purchase limit hit</Message.Header>
          <Message.Content>
            Please email{' '}
            <a href="mailto:support@datascore.ai" target="_blank" rel="noreferrer">
              support@datascore.ai
            </a>{' '}
            and ask about the purchase limit status of your Telnyx account setup.
          </Message.Content>
        </Message>
      )}

      <Divider />

      <TelnyxPhonesPaginatedTable />
    </Layout>
  );
};

const Layout: React.FC = ({ children }) => {
  return (
    <Container>
      <Helmet>
        <title>Telnyx - Account | datascore</title>
      </Helmet>

      <PageHeader>
        <span style={{ color: theme.gray }}>Account</span> <span style={{ padding: '0 0.5rem' }}>/</span> Telnyx
      </PageHeader>

      <Segment>{children}</Segment>
    </Container>
  );
};

export default AccountTelnyx;
