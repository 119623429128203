import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'semantic-ui-react';

import { useUpdateAccountMutation } from 'src/api/accounts';
import { useGetUserProfileQuery } from 'src/api/auth';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';

type ValidationErrors = {
  outbound_limit?: string;
};

type Formdata = {
  outbound_limit: number;
};

const AccountVoiceRateLimitForm = () => {
  const { data: user, isLoading: userLoading } = useGetUserProfileQuery();
  const voice = user?.active_account?.voice;
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<Formdata>({ outbound_limit: 0 });
  const { mutateAsync: updateAccount, isLoading: updateLoading } = useUpdateAccountMutation();

  useEffect(() => {
    setFormdata({ outbound_limit: voice?.outbound_limit || 0 });
  }, [voice?.outbound_limit]);

  const max = useMemo(() => voice?.outbound_limit_max || 60, [voice?.outbound_limit_max]);

  const validate = useCallback(
    (input: Formdata) => {
      const errors: ValidationErrors = {};

      if (input.outbound_limit > 0 && input.outbound_limit > max) {
        errors.outbound_limit = `Outbound limit must be less than or equal to ${max}`;
      }

      setErrors(errors);

      return errors;
    },
    [max]
  );

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    if (!isEmpty(validate(formdata))) {
      return;
    }

    try {
      await updateAccount({
        id: user?.active_account?.id || 0,
        voice_outbound_limit: formdata.outbound_limit,
      });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [formdata, updateAccount, user?.active_account?.id, validate]);

  return (
    <Form onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      <Form.Group>
        <Form.Input
          label="Global Outbound Calls (per minute)"
          placeholder={max}
          type="number"
          min={0}
          loading={userLoading || updateLoading}
          value={formdata.outbound_limit || ''}
          error={errors.outbound_limit}
          onChange={(_, { value }) => setFormdata(prev => ({ ...prev, outbound_limit: Number(value) }))}
          onBlur={onSubmit}
        />
      </Form.Group>
    </Form>
  );
};

export default AccountVoiceRateLimitForm;
