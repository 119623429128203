import styled from 'styled-components';

export const WidgetRow = styled.div`
  margin-bottom: 0.5rem;
`;

export const Widget = styled.div`
  display: flex;
  align-items: center;

  height: 20px;
  padding: 16px 8px;
  line-height: 1.2;
  width: fit-content;

  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
`;
