import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Label, Table } from 'semantic-ui-react';

import { ListPipelinesFilters, useListPipelinesQuery } from 'src/api/admin/pipelines';
import LinkWithDetail from 'src/components/LinkWithDetail';
import { RenderProps } from 'src/components/PaginatedTable';
import AdminDeletePipeline from './AdminDeletePipeline';
import AdminDuplicatePipeline from './AdminDuplicatePipeline';
import AdminTogglePipelineEnabledButton from './AdminTogglePipelineEnabledButton';

type Props = RenderProps & {
  filters?: ListPipelinesFilters;
};

const AdminListPipelinesBody = ({ limit, currentPage, setPageCount, filters }: Props) => {
  const { data: pipelines } = useListPipelinesQuery({ limit, offset: limit * (currentPage - 1), ...filters });

  useEffect(() => {
    let count = 1;
    if (typeof pipelines !== 'undefined') {
      count = Math.ceil(pipelines.total / limit);
    }
    setPageCount(count);
  }, [pipelines, limit, setPageCount]);

  return (
    <>
      {pipelines?.data.map(p => (
        <Table.Row key={p.id}>
          <Table.Cell collapsing>
            <AdminTogglePipelineEnabledButton pipeline={p} />
          </Table.Cell>
          <Table.Cell>
            <LinkWithDetail to={`/admin/pipelines/${p.id}`} text={p.name} detail={String(p.id)} />
          </Table.Cell>
          <Table.Cell>{p.account_id === 0 ? <Label>System</Label> : p.account?.name}</Table.Cell>
          <Table.Cell textAlign="right" collapsing>
            <AdminDuplicatePipeline pipeline={p} />
            <Link to={`/admin/pipelines/${p.id}`}>
              <Button as="div" color="blue" icon title="Edit Pipeline">
                <Icon name="pencil" />
              </Button>
            </Link>
            <AdminDeletePipeline id={p.id} />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default AdminListPipelinesBody;
