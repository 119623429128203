import { useQuery } from 'react-query';

import { BlacklistsResponse } from '../blacklists';
import { http } from '../http-common';

export type ListBlacklistsParams = {
  limit: number;
  offset: number;
  accountId?: number;
};

export const useListBlacklistsQuery = (params: ListBlacklistsParams) => {
  return useQuery(['admin/blacklists', params], async () => {
    const res = await http.get<BlacklistsResponse>('/api/admin/blacklists', { params });
    return res.data.blacklists;
  });
};
