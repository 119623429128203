import { useCallback } from 'react';
import { Button } from 'semantic-ui-react';

import { useRemoveBigqueryTableMutation } from 'src/api/admin/bigquery';

type Props = {
  datasetId: number;
  tableId: number;
};

const RemoveBigqueryTable = ({ datasetId, tableId }: Props) => {
  const { isLoading, mutateAsync } = useRemoveBigqueryTableMutation();

  const onClick = useCallback(async () => {
    await mutateAsync({ datasetId, tableId });
  }, [datasetId, tableId, mutateAsync]);

  return <Button icon="trash" color="red" onClick={onClick} loading={isLoading} />;
};

export default RemoveBigqueryTable;
