import { endOfDay, formatDistanceToNow, startOfDay } from 'date-fns';
import { useEffect } from 'react';
import { Button, Icon, Loader, Table } from 'semantic-ui-react';

import { useListVoiceRecordingsQuery, VoiceCallRecordingFilters } from 'src/api/voice-recordings';
import { RenderProps } from 'src/components/PaginatedTable';
import { Note } from 'src/styles';
import theme from 'src/styles/theme';
import { msToHourMinSec } from 'src/utils';
import AudioPlayer from './AudioPlayer';
import VoiceCallDetailsModal from './VoiceCallDetailsModal';

const { REACT_APP_BETA_URL: betaURL } = process.env;

const formatToMinSec = (ms: number) => {
  const [, m, s] = msToHourMinSec(ms);
  return m > 0 ? `${m} min ${s} sec` : `${s} sec`;
};

type Props = RenderProps & { filters: VoiceCallRecordingFilters };

const ListVoiceCallRecordingsBody = ({
  limit,
  currentPage,
  setPageCount,
  filters: {
    timeRange,
    startDate,
    endDate,
    search,
    directions,
    voiceConfigIDs,
    conversationIDs,
    dispositions,
    sortBy,
    sortDirection,
  },
}: Props) => {
  const { data: recordings, isLoading } = useListVoiceRecordingsQuery({
    limit,
    offset: limit * (currentPage - 1),
    timeRange,
    startDate: startOfDay(startDate),
    endDate: endOfDay(endDate),
    search,
    directions,
    voiceConfigIDs,
    conversationIDs,
    dispositions,
    sortBy,
    sortDirection,
  });

  useEffect(() => {
    let count = 1;
    if (typeof recordings !== 'undefined') {
      count = Math.ceil(recordings.total / limit);
    }
    setPageCount(count);
  }, [recordings, limit, setPageCount]);

  if (isLoading) {
    return (
      <Table.Row>
        <Table.Cell colSpan={9} style={{ textAlign: 'center' }}>
          <Loader inline active />
        </Table.Cell>
      </Table.Row>
    );
  }

  if (recordings?.count === 0) {
    return (
      <Table.Row>
        <Table.Cell colSpan={9}>No Recordings Found</Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      {recordings?.data.map(r => (
        <Table.Row key={r.id}>
          <Table.Cell>
            <strong>{r.phone}</strong>
            <span style={{ display: 'block', color: theme.gray, fontSize: '0.8rem', lineHeight: '1rem' }}>{r.id}</span>
          </Table.Cell>
          <Table.Cell>
            <span title={r.createdAt}>
              {formatDistanceToNow(new Date(r.createdAt), { addSuffix: true, includeSeconds: true })}
            </span>
          </Table.Cell>
          <Table.Cell>{r.direction}</Table.Cell>
          <Table.Cell>{formatToMinSec(r.durationMs)}</Table.Cell>
          <Table.Cell>{r.voiceConfigName}</Table.Cell>
          <Table.Cell>{r.conversationName || <Note>None</Note>}</Table.Cell>
          <Table.Cell>{r.disposition || <Note>None</Note>}</Table.Cell>
          <Table.Cell textAlign="center" collapsing>
            <AudioPlayer recording={r} />
          </Table.Cell>
          <Table.Cell collapsing>
            <VoiceCallDetailsModal recording={r} />
            <a href={`${betaURL}/api/voice/recordings/${r.recordingId}/download`}>
              <Button title="Download" icon>
                <Icon name="download" />
              </Button>
            </a>
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default ListVoiceCallRecordingsBody;
