import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  PaginatedResponse,
  ReportingTimeRange,
  SortDirection,
  VoiceCallDetailRecord,
  VoiceCallDirection,
  VoiceRecording,
} from 'src/types';
import { ApiResponse, http } from './http-common';

export type VoiceRecordingResponse = ApiResponse & {
  recording?: VoiceRecording;
};

export type VoiceRecordingDispositionsResponse = ApiResponse & {
  dispositions?: Array<string>;
};

export type VoiceRecordingsResponse = ApiResponse & {
  recordings: PaginatedResponse<VoiceCallDetailRecord>;
};

export type VoiceCallRecordingFilters = {
  timeRange: ReportingTimeRange;
  startDate: Date;
  endDate: Date;
  sortBy: string;
  sortDirection: SortDirection;
  search?: string;
  directions?: VoiceCallDirection[];
  dispositions?: string[];
  voiceConfigIDs?: string[];
  conversationIDs?: string[];
};

export type ListVoiceRecordingsParams = VoiceCallRecordingFilters & {
  limit: number;
  offset: number;
};

export const useListVoiceRecordingsQuery = (params: ListVoiceRecordingsParams) => {
  return useQuery(['voice', 'recordings', params], async () => {
    const res = await http.get<VoiceRecordingsResponse>('/api/voice/recordings', { params });
    return res.data.recordings;
  });
};

export const useGetVoiceRecordingQuery = (id: string) => {
  return useQuery(['voice', 'recordings', id], async () => {
    if (!id) return undefined;
    const res = await http.get<VoiceRecordingResponse>(`/api/voice/recordings/${id}`);
    return res.data.recording;
  });
};

export const useListenToVoiceRecordingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      const res = await http.post<ApiResponse>(`/api/voice/recordings/${id}/listen`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['voice', 'recordings']);
      },
    }
  );
};

export const useSaveVoiceRecordingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (recording: VoiceRecording) => {
      const res = await http.post<ApiResponse>(`/api/voice/recordings/${recording.id}`, recording);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['voice', 'recordings']);
      },
    }
  );
};

export const useDeleteVoiceRecordingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      const res = await http.delete<ApiResponse>(`/api/voice/recordings/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['voice', 'recordings']);
      },
    }
  );
};

export const useGetVoiceRecordingDispositionsQuery = () => {
  return useQuery(['voice', 'recordings', 'dispositions'], async () => {
    const res = await http.get<VoiceRecordingDispositionsResponse>(`/api/voice/recordings/dispositions`);
    return res.data.dispositions;
  });
};
