import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Icon, Segment } from 'semantic-ui-react';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useGetPipelineQuery, useRunPipelineMutation } from 'src/api/pipelines';
import ApiMessage from 'src/components/ApiMessage';
import Breadcrumbs from 'src/components/Breadcrumbs';
import JobHistory from 'src/components/JobHistory';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader, Row } from 'src/styles';
import { JobType } from 'src/types';
import EditPipelineForm from './EditPipelineForm';
import PipelineJobDetailsModal from './PipelineJobDetailsModal';
import { PlaceholderLayout } from './style';

const PipelinesEdit = () => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { id } = useParams<{ id: string }>();
  const [isFormValid, setIsFormValid] = useState(false);
  const { goBack } = useHistory();
  const { mutateAsync, isLoading: isPipelineLoading } = useRunPipelineMutation();
  const { data: pipeline, isLoading } = useGetPipelineQuery(Number(id));

  const runPipeline = useCallback(async () => {
    setApiMessage(undefined);

    try {
      await mutateAsync(Number(id));
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [id, mutateAsync]);

  return (
    <Container>
      <Helmet>
        <title>Edit Pipeline | datascore</title>
      </Helmet>

      <ApiMessage data={apiMessage} />

      <Row style={{ alignItems: 'center' }}>
        <Button icon color="blue" onClick={goBack} style={{ marginRight: '1rem' }}>
          <Icon name="arrow left" />
        </Button>
        <PageHeader style={{ margin: 0 }}>
          <Breadcrumbs crumbs={['Pipelines', 'Edit']} />
        </PageHeader>

        <Button
          color="blue"
          style={{ marginLeft: 'auto', marginRight: '0' }}
          onClick={runPipeline}
          loading={isPipelineLoading}
          disabled={!isFormValid}
        >
          Run Now
        </Button>
      </Row>

      <Segment>
        {isLoading ? (
          <PlaceholderLayout />
        ) : typeof pipeline !== 'undefined' ? (
          <EditPipelineForm pipeline={pipeline} isValid={isFormValid} setIsValid={setIsFormValid} />
        ) : (
          <NotFound />
        )}
      </Segment>

      <JobHistory details={PipelineJobDetailsModal} jobType={JobType.Pipeline} />
    </Container>
  );
};

export default PipelinesEdit;
