import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Divider, Header, Icon, List, Segment, Table } from 'semantic-ui-react';

import { useListAccountApiTokensQuery } from 'src/api/accounts';
import { useGetSendGridInboundParseSettingQuery } from 'src/api/auth/account-sendgrid';
import Loading from 'src/components/Loading';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader, Row } from 'src/styles';
import theme from 'src/styles/theme';
import InboundParseSettingForm from './InboundParseSettingForm';

const betaUrl = process.env.REACT_APP_BETA_URL;

const AccountSendGridInboundParse = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { data: apiTokens, isLoading: apiTokensLoading } = useListAccountApiTokensQuery({ limit: 1, offset: 0 });
  const { data: setting, isLoading: settingLoading } = useGetSendGridInboundParseSettingQuery(Number(params.id));

  const apiToken = useMemo<string | null>(() => {
    if (apiTokensLoading) return null;
    if (!apiTokens || !apiTokens.data || apiTokens.data.length === 0) return null;
    return apiTokens?.data[0].token;
  }, [apiTokens, apiTokensLoading]);

  if (settingLoading) return <Loading />;

  if (typeof setting === 'undefined') return <NotFound />;

  const webhookUrl = `${betaUrl}/api/sg/inbound-parse/${setting.token}?token=${apiToken}`;

  return (
    <Container>
      <Helmet>
        <title>Inbound Parse - SendGrid - Account | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center' }}>
        <Button icon color="blue" onClick={history.goBack} style={{ marginRight: '1rem' }}>
          <Icon name="arrow left" />
        </Button>
        <PageHeader style={{ margin: 0 }}>
          <span style={{ color: theme.gray }}>Account</span> <span style={{ padding: '0 0.5rem' }}>/</span>{' '}
          <span style={{ color: theme.gray }}>SendGrid</span> <span style={{ padding: '0 0.5rem' }}>/</span> Inbound
          Parse
        </PageHeader>
      </Row>

      <Segment>
        <Header>Webhook Configuration</Header>

        <p>
          To use the logic and actions defined below, you will also need to add a new inbound parse setting to your
          SendGrid account. Follow the steps below to complete your configuration.
        </p>

        <List ordered>
          <List.Item>
            Start by visiting your{' '}
            <a href="https://app.sendgrid.com/settings/parse" target="_blank" rel="noreferrer">
              Inbound Parse Settings
            </a>{' '}
            in Sendgrid.
          </List.Item>
          <List.Item>
            Click on the "<strong>Add Host & URL</strong>" button at the top right corner.
          </List.Item>
          <List.Item>Select your domain and optionally set a subdomain.</List.Item>
          <List.Item>
            Make sure to add the MX Record to your DNS using{' '}
            <a
              href="https://docs.sendgrid.com/for-developers/parsing-email/setting-up-the-inbound-parse-webhook#set-up-an-mx-record"
              target="_blank"
              rel="noreferrer"
            >
              these instructions
            </a>
            .
          </List.Item>
          <List.Item>
            Copy your webhook url below and paste it into the "<strong>Destination URL</strong>" field.
          </List.Item>
          <List.Item>
            Check the box next to "<strong>Check incoming emails for spam</strong>".
          </List.Item>
          <List.Item>Finally, click the "Add" button to complete the setup.</List.Item>
        </List>

        <Table definition>
          <Table.Row>
            <Table.Cell collapsing>Webhook URL</Table.Cell>
            <Table.Cell>{webhookUrl}</Table.Cell>
          </Table.Row>
        </Table>
      </Segment>

      <Segment>
        <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Header style={{ margin: 0 }}>Reply comes in ...</Header>
        </Row>

        <Divider />

        <InboundParseSettingForm parseSetting={setting} />
      </Segment>
    </Container>
  );
};

export default AccountSendGridInboundParse;
