import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ReportingTimeRange } from 'src/types';
import { AccountResponse } from '../accounts';
import { ApiResponse, http } from '../http-common';

export type TwilioCallStatsResponse = ApiResponse & {
  stats?: {
    completed: number;
    busy: number;
    failed: number;
    no_answer: number;
    avg_duration: number;
    less_than_6s: number;
    greater_than_30s: number;
  };
};

export type TwilioCallStatsParams = {
  directions?: string[];
  statuses?: string[];
  timeRange: ReportingTimeRange;
  startDate: string;
  endDate: string;
};

export const useGetAccountTwilioCallStatsQuery = (id: number, params: TwilioCallStatsParams) => {
  return useQuery(['admin/accounts', id, 'twilio-call-stats', params], async () => {
    const res = await http.get<TwilioCallStatsResponse>(`/api/admin/accounts/${id}/twilio-call-stats`, { params });
    return res.data.stats;
  });
};

export const useCreateTwilioSubAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await http.post<AccountResponse>(`/api/admin/accounts/${id}/create-twilio-subaccount`);
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['admin/accounts', account.id]);
        queryClient.invalidateQueries(['accounts', account.id]);
        queryClient.invalidateQueries(['accounts']);
        queryClient.invalidateQueries(['auth/profile']);
        queryClient.invalidateQueries(['auth/account']);
      },
    }
  );
};

export const useSubmitBusinessProfileToTwilioMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await http.post<AccountResponse>(`/api/admin/accounts/${id}/submit-business-profile`);
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['admin/accounts', account.id]);
        queryClient.invalidateQueries(['accounts', account.id]);
        queryClient.invalidateQueries(['accounts']);
        queryClient.invalidateQueries(['auth/profile']);
        queryClient.invalidateQueries(['auth/account']);
      },
    }
  );
};

export type UpdateAccountTwilioInput = {
  id: number;
  account_sid: string;
  auth_token: string;
  purchase_limit: number;
};

export const useUpdateAccountTwilioMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, ...params }: UpdateAccountTwilioInput) => {
      const res = await http.post<AccountResponse>(`/api/admin/accounts/${id}/twilio-profile`, params);
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['admin/accounts', account.id]);
        queryClient.invalidateQueries(['accounts', account.id]);
        queryClient.invalidateQueries(['accounts']);
        queryClient.invalidateQueries(['auth/profile']);
        queryClient.invalidateQueries(['auth/account']);
      },
    }
  );
};
