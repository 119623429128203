import { get } from 'lodash';
import { useMemo } from 'react';
import { Button, DropdownItemProps, Form, Icon } from 'semantic-ui-react';

import BrooksCimaCertProperties from 'src/data/brooks-cima-cert-types.json';
import theme from 'src/styles/theme';
import { ComparisonOperatorOptions, RuleFilter } from 'src/types';

type Props = {
  columns: DropdownItemProps[];
  filters: RuleFilter[];
  addFilter: () => void;
  removeFilter: (id: string) => void;
  updateFilter: (id: string, filter: RuleFilter) => void;
};

const RuleFilters = ({ columns, filters, addFilter, removeFilter, updateFilter }: Props) => {
  columns = useMemo(() => {
    return columns.map(c => {
      const cimaProp = get(BrooksCimaCertProperties, c.value as string);
      if (cimaProp && typeof cimaProp !== 'string') {
        if (cimaProp.friendlyName) {
          c.text = cimaProp.friendlyName;
        }
      }
      return c;
    });
  }, [columns]);

  return (
    <>
      {filters.map(f => {
        let operatorOptions = ComparisonOperatorOptions.filter(
          o => !['null', 'not_null', 'time_after', 'time_before'].includes(o.value)
        );

        let cimaOptions: DropdownItemProps[] = [];

        const cimaProp = get(BrooksCimaCertProperties, f.field);
        if (cimaProp && typeof cimaProp !== 'string') {
          let cimaOperators: string[] = [];
          switch (cimaProp.type) {
            case 'enum':
              cimaOperators = ['eq', 'neq', 'in', 'not_in'];
              break;
            case 'integer':
            case 'decimal':
              cimaOperators = ['eq', 'neq', 'gt', 'gte', 'lt', 'lte'];
              break;
          }

          if (cimaOperators.length > 0) {
            operatorOptions = operatorOptions.filter(o => cimaOperators.includes(o.value as string));
          }

          if (cimaProp.type === 'enum' && cimaProp.enum && cimaProp.enum.length > 0) {
            cimaOptions = cimaProp.enum.map((e: { value: string; text: string }) => ({ ...e, key: e.value }));
          }
        }

        const multiple = f.operator === 'in' || f.operator === 'not_in';

        return (
          <div key={f.id}>
            <Form.Group widths="equal">
              <Form.Select
                fluid
                search
                placeholder="Column"
                clearable
                options={columns}
                value={f.field}
                onChange={(_, { value }) =>
                  updateFilter(f.id, { ...f, field: value as string, operator: '', value: '' })
                }
              />

              <Form.Select
                fluid
                placeholder="Operator"
                clearable
                options={operatorOptions}
                value={f.operator}
                onChange={(_, { value }) => {
                  const nextMultiple = value === 'in' || value === 'not_in';
                  updateFilter(f.id, {
                    ...f,
                    operator: value as string,
                    value: (nextMultiple ? f.value : (f.value || '').split(',')[0]) || '',
                  });
                }}
              />

              {cimaOptions.length > 0 ? (
                <Form.Select
                  fluid
                  clearable
                  multiple={multiple}
                  placeholder={multiple ? 'Value(s)' : 'Value'}
                  value={multiple ? (f.value && (f.value || '').split(',')) || [] : (f.value || '').split(',')[0] || ''}
                  options={cimaOptions}
                  onChange={(_, { value }) =>
                    updateFilter(f.id, { ...f, value: multiple ? (value as string[]).join(',') : (value as string) })
                  }
                />
              ) : (
                f.operator !== 'null' && (
                  <Form.Input
                    fluid
                    placeholder={multiple ? 'Value(s)' : 'Value'}
                    value={f.value || ''}
                    onChange={(_, { value }) => updateFilter(f.id, { ...f, value })}
                  />
                )
              )}

              <Button type="button" color="red" icon onClick={() => removeFilter(f.id)}>
                <Icon name="trash" />
              </Button>
            </Form.Group>

            {multiple && cimaOptions.length === 0 && (
              <p style={{ color: theme.gray, marginTop: '-0.75rem' }}>Separate multiple values with a comma.</p>
            )}

            {cimaProp && typeof cimaProp !== 'string' && (
              <>
                {cimaProp.description && (
                  <p style={{ color: theme.gray, marginTop: '-0.75rem' }}>{cimaProp.description}</p>
                )}
                {cimaProp.range && cimaProp.range.length === 2 && (
                  <p style={{ color: theme.gray, marginTop: '-0.75rem' }}>
                    Range: {cimaProp.range[0]} - {cimaProp.range[1]}
                  </p>
                )}
              </>
            )}
          </div>
        );
      })}

      <Button type="button" compact size="mini" color="blue" style={{ marginBottom: '1rem' }} onClick={addFilter}>
        <Icon name="plus" /> Add Filter
      </Button>
    </>
  );
};

export default RuleFilters;
