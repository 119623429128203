import { useCallback, useState } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';

import TrustHubCnamModal from './TrustHubCnamModal';
import TrustHubShakenStirModal from './TrustHubShakenStirModal';
import TrustHubVoiceIntegrityModal from './TrustHubVoiceIntegrityModal';

const TrustHubCreateProfileDropdown = () => {
  const [activeModal, setActiveModal] = useState<'shaken' | 'cnam' | 'voice' | null>(null);

  const onClose = useCallback(() => setActiveModal(null), []);

  return (
    <>
      <Button.Group color="blue">
        <Dropdown floating labeled button text="Create Profile" icon="plus" className="icon">
          <Dropdown.Menu>
            <Dropdown.Item text="SHAKEN/STIR" onClick={() => setActiveModal('shaken')} />
            <Dropdown.Item text="CNAM" onClick={() => setActiveModal('cnam')} />
            <Dropdown.Item text="Voice Integrity" onClick={() => setActiveModal('voice')} />
          </Dropdown.Menu>
        </Dropdown>
      </Button.Group>

      <TrustHubShakenStirModal open={activeModal === 'shaken'} onClose={onClose} />
      <TrustHubCnamModal open={activeModal === 'cnam'} onClose={onClose} />
      <TrustHubVoiceIntegrityModal open={activeModal === 'voice'} onClose={onClose} />
    </>
  );
};

export default TrustHubCreateProfileDropdown;
