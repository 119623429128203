import { useEffect } from 'react';
import { Node } from 'react-flow-renderer';
import { DropdownItemProps, Form, Icon } from 'semantic-ui-react';

import { NodeData } from '../../types';
import FilterItem from './FilterItem';
import { useNodeActions } from './hooks';
import { Filter, UpdateNode } from './types';

type FilterProps = {
  filters?: Filter[];
  columns: DropdownItemProps[];
  table?: string;
  updateNode: UpdateNode;
  node: Node<NodeData & { filters?: Filter[] }>;
  onSave: () => void;
};

const Filters = ({ filters, columns, table, updateNode, node, onSave }: FilterProps) => {
  const { addFilter, removeFilter, updateFilter } = useNodeActions(updateNode, node);

  useEffect(() => {
    if (!Boolean(filters?.length)) {
      addFilter();
    }
  }, [addFilter, filters]);

  return (
    <>
      {filters?.map((filter, index) => {
        const isLastElement = index === filters.length - 1;

        const Action = isLastElement ? (
          <Form.Button fluid color="blue" type="button" onClick={() => addFilter()} icon disabled={!table}>
            <Icon name="plus" />
          </Form.Button>
        ) : (
          <Form.Button fluid color="red" type="button" onClick={() => removeFilter(filter.id)} icon disabled={!table}>
            <Icon name="delete" />
          </Form.Button>
        );

        return (
          <FilterItem
            key={filter.id}
            filter={filter}
            columns={columns}
            table={table}
            updateFilter={(filter: Filter) => updateFilter(filter)}
            action={Action}
            onSave={onSave}
          />
        );
      })}
    </>
  );
};

export default Filters;
