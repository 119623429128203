import { isEmpty, uniq } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, Form, TextArea } from 'semantic-ui-react';

import { useUpdateAccountMutation } from 'src/api/accounts';
import theme from 'src/styles/theme';
import { Account } from 'src/types';
import { cleanPhone } from 'src/utils';

type Props = {
  account: Account;
};

type Formdata = Pick<Account, 'name' | 'test_phones'>;

type ValidationErrors = {
  name?: string;
  test_phones?: string;
};

const getInitialFormdata = (account: Account): Formdata => {
  return {
    name: account.name || '',
    test_phones: account.test_phones || '',
  };
};

const AccountGeneralSettings = ({ account }: Props) => {
  const [formdata, setFormdata] = useState<Formdata>(() => getInitialFormdata(account));
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { mutateAsync, isLoading: mutationIsLoading } = useUpdateAccountMutation();

  const validate = useCallback((input: Formdata): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    if (typeof input.name === 'undefined') {
      validationErrors.name = 'name is required';
    }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onChange = useCallback((_, { name, value }) => {
    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  const onBlurPhones = useCallback(() => {
    let v = (formdata.test_phones || '').split('\n').map(cleanPhone).filter(Boolean);
    v = uniq(v);
    if (v.length > 10) {
      v = v.slice(0, 10);
    }
    setFormdata(prev => ({ ...prev, test_phones: v.join('\n') }));
  }, [formdata.test_phones]);

  const onSubmit = useCallback(async () => {
    if (!isEmpty(validate(formdata))) {
      return;
    }

    await mutateAsync({ id: account.id, name: formdata.name, test_phones: formdata.test_phones || '' });
  }, [account.id, mutateAsync, formdata, validate]);

  return (
    <Form onSubmit={onSubmit}>
      <Form.Input label="Name" name="name" value={formdata.name} onChange={onChange} error={errors.name} />

      <Form.Field>
        <label>Test Phone Numbers (Limit 10)</label>
        <p style={{ color: theme.gray, fontSize: '0.9em' }}>
          Enter a list of phone numbers (one per line) that you plan on testing with. These phone numbers will be
          allowed to bypass the deduplication filters which are normally set to two weeks.
        </p>
        <TextArea
          name="test_phones"
          value={formdata.test_phones || ''}
          onBlur={onBlurPhones}
          onChange={onChange}
          error={errors.test_phones}
        />
      </Form.Field>

      <Button color="blue" loading={mutationIsLoading}>
        Save
      </Button>
    </Form>
  );
};

export default AccountGeneralSettings;
