import styled from 'styled-components';

import theme from 'src/styles/theme';

export const AdminHeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #191919;
  display: flex;
  z-index: 6000;
  justify-content: flex-end;
`;

export const AdminHeaderMenu = styled.div`
  padding: 0 0.5rem;
  display: flex;
  justify-content: flex-end;
  position: relative;

  .item {
    position: relative;
    padding: 0.5rem;
    color: #fff;

    &:hover {
      background-color: #333;
    }

    &:after {
      content: '';
      display: block;
      background: ${theme.primary};
      height: 2px;
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
      position: absolute;
      top: 0;
      left: 0.7rem;
      right: 0.7rem;
      transform: scaleX(0);
      transition: transform 100ms ease-out;
    }
    &.active:not(.ui.dropdown):after,
    &.ui.dropdown.item--active:after {
      transform: scaleX(1);
    }
  }

  .title {
    padding: 0.5rem 0.5rem;
    color: rgba(255, 255, 255, 0.5);
  }

  .vertical.divider {
    width: 1px;
    background: rgba(255, 255, 255, 0.25);
    margin: 0 0.5rem;
  }

  a,
  button,
  .ui.dropdown {
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;

    :hover {
      background: #333;
    }
  }

  /* a, */
  /* button, */
  .ui.dropdown {
    flex: 1;
    display: flex;
    padding: 0.5rem;
    align-items: center;
  }
`;
