import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Divider, Grid, Header, Message, Segment } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { useGetTwilioTotalIncomingPhoneCountQuery } from 'src/api/auth/account-twilio';
import Loading from 'src/components/Loading';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader } from 'src/styles';
import theme from 'src/styles/theme';
import TrustHubCreateProfileDropdown from './TrustHubCreateProfileDropdown';
import TrustHubProfiles from './TrustHubProfiles';
import TwilioBuyNumbers from './TwilioBuyNumbers';
import TwilioCallQueue from './TwilioCallQueue';
import TwilioPhonesPaginatedTable from './TwilioPhonesPaginatedTable';
import TwilioStudioFlows from './TwilioStudioFlows';

const NO_LIMIT = 0;

const AccountTwilio = () => {
  const { data: user, isLoading } = useGetUserProfileQuery();
  const twilioProfile = useMemo(() => user?.active_account.twilio_profile, [user]);

  const { data: totalCount, isLoading: isTotalCountLoading } = useGetTwilioTotalIncomingPhoneCountQuery();

  const isAdmin = user?.role === 'admin';

  const canBuyPhoneNumbers = useMemo(() => {
    if (!twilioProfile || isTotalCountLoading || !totalCount) return false;

    if (twilioProfile.purchase_limit === NO_LIMIT) return true;

    const canBuy = twilioProfile.purchase_limit > totalCount.count;

    return canBuy || isAdmin;
  }, [isAdmin, isTotalCountLoading, totalCount, twilioProfile]);

  if (isLoading || isTotalCountLoading) return <Loading />;

  if (typeof user === 'undefined') return <NotFound />;

  const adminActionRequired = !twilioProfile?.account_sid || !twilioProfile?.auth_token;

  if (adminActionRequired) {
    return (
      <Layout>
        <Message negative>
          <Message.Header>An admin needs to enable this feature</Message.Header>
          <Message.Content>
            Please email{' '}
            <a href="mailto:support@datascore.ai" target="_blank" rel="noreferrer">
              support@datascore.ai
            </a>{' '}
            and ask about the status of your Twilio account setup.
          </Message.Content>
        </Message>
      </Layout>
    );
  }

  return (
    <Layout>
      {isAdmin && (
        <>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Header>Call Queue</Header>
              </Grid.Column>
              <Grid.Column width={8}>
                <TwilioCallQueue />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider />

          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Header>Studio Flows</Header>
              </Grid.Column>
              <Grid.Column width={8}>
                <TwilioStudioFlows />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider />

          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Header>Trust Hub Profiles</Header>
                <TrustHubCreateProfileDropdown />
              </Grid.Column>
              <Grid.Column width={12}>
                <TrustHubProfiles />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider />
        </>
      )}

      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <Header>Buy Numbers</Header>
          </Grid.Column>

          <Grid.Column width={12}>
            {canBuyPhoneNumbers ? (
              <TwilioBuyNumbers />
            ) : (
              <Message negative>
                <Message.Header>Purchase limit hit</Message.Header>
                <Message.Content>
                  Please email{' '}
                  <a href="mailto:support@datascore.ai" target="_blank" rel="noreferrer">
                    support@datascore.ai
                  </a>{' '}
                  and ask about the purchase limit status of your Twilio account setup.
                </Message.Content>
              </Message>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider />

      <TwilioPhonesPaginatedTable />
    </Layout>
  );
};

const Layout: React.FC = ({ children }) => {
  return (
    <Container>
      <Helmet>
        <title>Twilio - Account | datascore</title>
      </Helmet>

      <PageHeader>
        <span style={{ color: theme.gray }}>Account</span> <span style={{ padding: '0 0.5rem' }}>/</span> Twilio
      </PageHeader>

      <Segment>{children}</Segment>
    </Container>
  );
};

export default AccountTwilio;
