import { useMutation, useQuery, useQueryClient } from 'react-query';

import { BusinessProfile, CallCenter } from 'src/types';
import { ApiResponse, http } from './http-common';

export type BusinessProfileResponse = ApiResponse & {
  business_profile: BusinessProfile;
};

export const useGetBusinessProfileQuery = () => {
  return useQuery(
    ['busines-profile'],
    async () => {
      const res = await http.get<BusinessProfileResponse>('/api/auth/onboarding/business-profile');
      return res.data;
    },
    { retry: false }
  );
};

export const useUpdateBusinessProfileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: BusinessProfile) => {
      const res = await http.post<ApiResponse>(`/api/auth/onboarding/business-profile`, params);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['busines-profile']);
      },
    }
  );
};

export type CallCenterResponse = ApiResponse & {
  call_center: CallCenter;
};

export const useGetCallCenterQuery = () => {
  return useQuery(
    ['call-center'],
    async (): Promise<CallCenterResponse> => {
      const res = await http.get<CallCenterResponse>('/api/auth/onboarding/call-center');
      return res.data;
    },
    { retry: false }
  );
};

export const useUpdateCallCenterMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: CallCenter) => {
      const res = await http.post<ApiResponse>(`/api/auth/onboarding/call-center`, params);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['call-center']);
      },
    }
  );
};

export const useCompleteOnboardingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const res = await http.post<ApiResponse>(`/api/auth/onboarding/complete`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/profile']);
      },
    }
  );
};
