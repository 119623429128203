import { formatDistanceToNow, isAfter } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Icon, Label, Table } from 'semantic-ui-react';

import { useListUserInvitesQuery, useResetUserInviteMutation } from 'src/api/admin/user-invites';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { RenderProps } from 'src/components/PaginatedTable';
import { AccountUserAccessRoles, UserRoles } from 'src/types';
import DeleteUserInvite from './DeleteUserInvite';

const { REACT_APP_BETA_CLIENT_URL: betaUrl } = process.env;

const AdminUserInvitesListBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: invites } = useListUserInvitesQuery({ limit, offset: limit * (currentPage - 1) });
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { isLoading, mutateAsync } = useResetUserInviteMutation();

  useEffect(() => {
    let count = 1;
    if (typeof invites !== 'undefined') {
      count = Math.ceil(invites.total / limit);
    }
    setPageCount(count);
  }, [invites, limit, setPageCount]);

  const resetInvite = useCallback(
    async (id: number) => {
      try {
        await mutateAsync(id);
      } catch (error) {
        apiErrorHandler(error, setApiMessage);
        setTimeout(() => setApiMessage(undefined), 2000);
      }
    },
    [mutateAsync]
  );

  return (
    <>
      <Helmet>
        <title>User Invites - Admin | datascore</title>
      </Helmet>

      {invites?.data.map(invite => {
        const now = new Date();
        const expiresAt = new Date(invite.expires_at);

        const expiration = isAfter(expiresAt, now) ? (
          <Label>{formatDistanceToNow(expiresAt)}</Label>
        ) : (
          <Label color="red">Expired</Label>
        );

        const inviteUrl = `${betaUrl}/#/auth/accept-invite/${invite.token}`;

        return (
          <Table.Row key={invite.id}>
            <Table.Cell>
              <strong>{invite.email}</strong>
            </Table.Cell>
            <Table.Cell>
              <Label color={invite.role === 'admin' ? 'blue' : undefined}>
                {UserRoles.find(role => role.value === invite.role)?.text}
              </Label>
            </Table.Cell>
            <Table.Cell>
              {invite.account_roles.map(r => (
                <Label>{AccountUserAccessRoles.find(role => role.value === r)?.text}</Label>
              ))}
            </Table.Cell>
            <Table.Cell>
              <a href={inviteUrl} target="_blank" rel="noreferrer">
                {inviteUrl}
              </a>
            </Table.Cell>
            <Table.Cell>{expiration}</Table.Cell>
            <Table.Cell collapsing>
              {apiMessage ? (
                <Button color="red">{apiMessage.message}</Button>
              ) : (
                !isAfter(expiresAt, now) && (
                  <Button
                    color="blue"
                    onClick={() => resetInvite(invite.id)}
                    loading={isLoading}
                    icon
                    title="Extend Expiration Time"
                  >
                    <Icon name="refresh" />
                  </Button>
                )
              )}
              <DeleteUserInvite id={invite.id} />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
};

export default AdminUserInvitesListBody;
