import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Table } from 'semantic-ui-react';

import { useListAllAkkioModelsQuery } from 'src/api/admin/leadscore-plus';
import LinkWithDetail from 'src/components/LinkWithDetail';
import { RenderProps } from 'src/components/PaginatedTable';
import DeleteAkkioModel from './DeleteAkkioModel';
import DuplicateAkkioModel from './DuplicateAkkioModel';
import ToggleAkkioModelEnabledButton from './ToggleAkkioModelEnabledButton';

const AdminLeadscorePlusAkkioModelsListBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: models } = useListAllAkkioModelsQuery({ limit, offset: limit * (currentPage - 1) });
  const history = useHistory();

  useEffect(() => {
    let count = 1;
    if (typeof models !== 'undefined') {
      count = Math.ceil(models.total / limit);
    }
    setPageCount(count);
  }, [models, limit, setPageCount]);

  return (
    <>
      {models?.data.map(m => {
        const modelPath = `/admin/leadscore-plus/akkio-models/${m.id}`;

        return (
          <Table.Row key={m.id}>
            <Table.Cell collapsing>
              <ToggleAkkioModelEnabledButton model={m} />
            </Table.Cell>
            <Table.Cell>
              <LinkWithDetail to={modelPath} text={m.name} /* detail={String(m.id)} */ />
            </Table.Cell>
            <Table.Cell textAlign="right" collapsing>
              <DuplicateAkkioModel id={m.id} />
              <Button icon="pencil" color="blue" onClick={() => history.push(modelPath)} />
              <DeleteAkkioModel id={m.id} />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
};

export default AdminLeadscorePlusAkkioModelsListBody;
