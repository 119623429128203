import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Loader } from 'semantic-ui-react';

import { useGetAccountBillingDetailsQuery } from 'src/api/billing';
import useSearchQuery from 'src/hooks/useSearchQuery';
import AddPaymentMethod from './AddPaymentMethod';
import PaymentMethod from './PaymentMethod';
import RemovePaymentMethod from './RemovePaymentMethod';
import SetDefaultPaymentMethod from './SetDefaultPaymentMethod';

const AccountBillingPaymentMethods = () => {
  const { replace } = useHistory();
  const { data: paymentMethods, isLoading } = useGetAccountBillingDetailsQuery();
  const query = useSearchQuery();

  useEffect(() => {
    if (query.has('setup_intent')) {
      // NOTE: Stripe Return URL looks like:
      // ?setup_intent=seti_XXXXXXXX
      // &setup_intent_client_secret=seti_XXXXXXXX
      // &redirect_status=succeeded
      // #/models/:modelId/predict/:jobId/payment
      query.delete('setup_intent');
      query.delete('setup_intent_client_secret');
      query.delete('redirect_status');

      replace({ search: query.toString() });
    }

    if (window.location.search) {
      window.location.replace(`${window.location.origin}/${window.location.hash}`);
    }
  }, [query, replace]);

  if (isLoading) return <Loader active size="tiny" />;

  return (
    <Grid columns={2}>
      {(paymentMethods || []).map(pm => (
        <Grid.Column key={pm.id}>
          <PaymentMethod
            paymentMethod={pm}
            extra={
              <>
                {/* <Button floated="right" size="tiny" basic style={{ marginLeft: '1rem' }}>Edit</Button> */}
                <SetDefaultPaymentMethod paymentMethod={pm} />
                <RemovePaymentMethod paymentMethod={pm} />
              </>
            }
          />
        </Grid.Column>
      ))}
      <Grid.Column>
        <AddPaymentMethod redirect="#/account/billing/settings" />
      </Grid.Column>
    </Grid>
  );
};

export default AccountBillingPaymentMethods;
