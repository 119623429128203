import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { CSSProperties, useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory } from 'react-router-dom';
import { CheckboxProps, DropdownProps, Form, Input, Loader } from 'semantic-ui-react';

import { useListDatasetsQuery } from 'src/api/datasets';
import { DashboardData, DashboardDataFilters, useGetDashboardDataQuery, useGetListDates } from 'src/api/reporting';
import Breadcrumbs from 'src/components/Breadcrumbs';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { Container, Header as PageHeader } from 'src/styles';
import { Dataset } from 'src/types';

const statusColorMap: Record<string, string> = {
  Success: '#92D04F',
  'Not Called': 'black',
  'Contact Recall': 'black',
  Recall: 'black',
  Contact: 'red',
  Complete: 'red',
  'Sys Complete': 'red',
  'DNC Lit': 'red',
  'DNC Contact': 'red',
  'DNC Client': 'red',
};

const ReportingDashboardColumns = ['status', 'count'] as const;

type ReportingDashboardColumn = typeof ReportingDashboardColumns[number];

type DatasetStats = { dataset: Dataset; count: number };

type DashboarDataWithDetails = DashboardData & { details?: Record<string, DatasetStats> };

const DatePickerListDatesColumns: string[] = [
  'dataset_documents.created_at',
  'vw_de_lead_summary.most_recent_call_date',
];

const ReportingDashboard = () => {
  const [filters, setFilters] = useState<DashboardDataFilters>({
    dataset_ids: [],
    breakdown_by_dataset: false,
    doc_date_range: {},
    call_date_range: {},
  });
  const { data: datasets, isLoading: datasetLoading } = useListDatasetsQuery({ limit: 100, offset: 0 });
  const { replace } = useHistory();
  const query = useSearchQuery();
  const [column, setColumn] = useState<ReportingDashboardColumn>();
  const [datasetSelected, setDatasetselected] = useState<string>('');
  const [direction, setDirection] = useState<'ascending' | 'descending'>('ascending');

  const onChangeFilters = useCallback((_, { checked, name, value }: DropdownProps | CheckboxProps) => {
    setFilters(prev => {
      const v = typeof checked !== 'undefined' ? checked : value;
      return { ...prev, [name]: v };
    });
  }, []);

  const updateSort = useCallback(
    (col: ReportingDashboardColumn) => () => {
      const newDirection = column === col ? (direction === 'ascending' ? 'descending' : 'ascending') : 'ascending';
      setDirection(newDirection);
      setColumn(col);
      setDatasetselected('');
      // update query params
      query.set('column', col);
      query.set('direction', newDirection);
      replace({ search: query.toString() });
    },
    [column, direction, query, replace]
  );

  const updateDatasetSort = useCallback(
    (datasetId: string) => () => {
      const newDirection =
        datasetSelected === datasetId ? (direction === 'ascending' ? 'descending' : 'ascending') : 'ascending';
      setColumn(undefined);
      setDirection(newDirection);
      setDatasetselected(datasetId);
      // update query params
      query.set('direction', newDirection);
      replace({ search: query.toString() });
    },
    [datasetSelected, direction, query, replace]
  );

  const { data: dashboardData, isLoading } = useGetDashboardDataQuery(filters);

  const { data: listDates } = useGetListDates({ columns: DatePickerListDatesColumns });

  // Convert dataset_document dates to an array of Date objects
  const [availableDatesDoc, availableDatesCall] = useMemo(() => {
    if (!listDates) return [[], []];
    return [
      listDates['dataset_documents.created_at'].map(dateString => new Date(dateString + 'T00:00:00')),
      listDates['vw_de_lead_summary.most_recent_call_date'].map(dateString => new Date(dateString + 'T00:00:00')),
    ];
  }, [listDates]);

  const [data] = useMemo(() => {
    const rawData = dashboardData?.data || [];
    if (!filters.breakdown_by_dataset) return [rawData as DashboarDataWithDetails[], []];

    const uniqueStatus = new Map<string, Record<string, { dataset: Dataset; count: number }>>();
    const allDatasets = new Map<string, Dataset>();

    for (const raw of rawData) {
      if (!raw.dataset) continue;
      if (!uniqueStatus.has(raw.status)) {
        uniqueStatus.set(raw.status, {
          [raw.dataset.id]: {
            dataset: raw.dataset,
            count: raw.count,
          },
        });
      } else {
        const statusDatasets = uniqueStatus.get(raw.status);
        if (!statusDatasets) continue;
        if (statusDatasets[raw.dataset.id]) {
          statusDatasets[raw.dataset.id].count += raw.count;
        } else {
          statusDatasets[raw.dataset.id] = {
            dataset: raw.dataset,
            count: raw.count,
          };
        }
      }

      if (!allDatasets.has(raw.dataset.id)) allDatasets.set(raw.dataset.id, raw.dataset);
    }

    for (const [datasetId, completeDataset] of allDatasets.entries()) {
      for (const [status, datasetsAvailables] of uniqueStatus.entries()) {
        if (datasetsAvailables[datasetId]) continue;

        datasetsAvailables[datasetId] = {
          dataset: completeDataset,
          count: 0,
        };

        uniqueStatus.set(status, datasetsAvailables);
      }
    }

    const cleanData: DashboarDataWithDetails[] = [];

    for (const [status, datasets] of uniqueStatus.entries()) {
      let total = 0;

      for (const datasetId in datasets) {
        total += datasets[datasetId].count;
      }

      cleanData.push({
        count: total,
        dataset: null,
        details: datasets,
        status,
      });
    }

    return [cleanData, Array.from(allDatasets.values())];
  }, [dashboardData?.data, filters.breakdown_by_dataset]);

  const sortedData = useMemo(() => {
    const sortByGeneralStats = (a: DashboarDataWithDetails, b: DashboarDataWithDetails) => {
      if (!column) return 1;
      if (direction === 'ascending') {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    };

    const sortByDatasetStats = (a: DashboarDataWithDetails, b: DashboarDataWithDetails) => {
      const aDataset = a.details?.[datasetSelected] ?? { count: 0 };
      const bDataset = b.details?.[datasetSelected] ?? { count: 0 };
      if (direction === 'ascending') {
        return aDataset.count > bDataset.count ? 1 : -1;
      } else {
        return aDataset.count < bDataset.count ? 1 : -1;
      }
    };

    return data.sort(datasetSelected !== '' ? sortByDatasetStats : sortByGeneralStats);
  }, [column, data, direction, datasetSelected]);

  const grandTotalMapped = useMemo(() => {
    const countMap: Record<string, number> = {};

    countMap['total'] = 0;
    for (const raw of data) {
      countMap['total'] += raw.count;

      if (!raw.details) continue;
      for (const datasetId of Object.keys(raw.details)) {
        const datasetsStats = raw.details[datasetId];
        if (!countMap[datasetsStats.dataset.name]) {
          countMap[datasetsStats.dataset.name] = datasetsStats.count;
        } else {
          countMap[datasetsStats.dataset.name] += datasetsStats.count;
        }
      }
    }

    return countMap;
  }, [data]);

  const handleDocDatesChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setFilters(prevFilters => ({
      ...prevFilters,
      doc_date_range: { start: start !== null ? start : undefined, end: end !== null ? end : undefined },
    }));
  };

  const handleCallDatesChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setFilters(prevFilters => ({
      ...prevFilters,
      call_date_range: { start: start !== null ? start : undefined, end: end !== null ? end : undefined },
    }));
  };

  const getCommonPinningStyles = (column: Column<DashboarDataWithDetails>): CSSProperties => {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
    return {
      boxShadow: isLastLeftPinnedColumn ? '-4px 0 4px -4px gray inset' : undefined,
      left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
      opacity: isPinned ? 0.95 : 1,
      position: isPinned ? 'sticky' : 'relative',
      zIndex: isPinned ? 1 : 0,
      backgroundColor: 'white',
    };
  };

  const columns = useMemo<ColumnDef<DashboarDataWithDetails>[]>(() => {
    const docDateStr = () => {
      return filters?.doc_date_range?.start && filters?.doc_date_range?.end
        ? `&doc_date_range=${JSON.stringify(filters.doc_date_range)}`
        : '';
    };

    const callDateStr = () => {
      return filters?.call_date_range?.start && filters?.call_date_range?.end
        ? `&call_date_range=${JSON.stringify(filters.call_date_range)}`
        : '';
    };

    const cols: ColumnDef<DashboarDataWithDetails>[] = [];
    cols.push({
      id: 'status',
      header: () => {
        return (
          <div className="pointer" onClick={updateSort('status')}>
            Master Status
          </div>
        );
      },
      accessorFn: (row: DashboarDataWithDetails) => row.status,
      cell: ({ getValue, row }) => {
        return (
          <Link
            style={{
              color: statusColorMap[row.original.status],
            }}
            to={{
              pathname: `/reporting/lead-summary`,
              search: `master_lead_status=${row.original.status}${filters.dataset_ids
                ?.map(id => `&dataset_ids[]=${id}`)
                .join('')}${docDateStr()}${callDateStr()}`,
            }}
          >
            {getValue()}
          </Link>
        );
      },
      footer: () => {
        return (
          <div style={{ textAlign: 'left' }}>
            <strong>Grand Total</strong>
          </div>
        );
      },
      enablePinning: true,
      sortingFn: 'alphanumeric',
    });
    if (filters.breakdown_by_dataset) {
      const seenDatasetIds: Set<string> = new Set();
      sortedData.forEach(sd => {
        for (const [key, ds] of Object.entries(sd.details || {})) {
          if (seenDatasetIds.has(key)) return;
          seenDatasetIds.add(key);
          cols.push({
            id: `${key}-dataset`,
            header: () => {
              return (
                <div className="pointer" onClick={updateDatasetSort(key)}>
                  {ds.dataset.name}
                </div>
              );
            },
            columns: [
              {
                id: `${key}-count`,
                header: () => {
                  return (
                    <div className="pointer" onClick={updateDatasetSort(key)}>
                      Count
                    </div>
                  );
                },
                accessorFn: (row: DashboarDataWithDetails) => row.details?.[key].count || 0,
                cell: ({ getValue, row }) => {
                  return (
                    <Link
                      to={{
                        pathname: `/reporting/lead-summary`,
                        search: `master_lead_status=${row.original.status}${
                          key ? `&dataset_ids[]=${key}` : ``
                        }${docDateStr()}${callDateStr()}`,
                      }}
                    >
                      <div className="pointer" style={{ textAlign: 'right' }} onClick={updateDatasetSort(key)}>
                        {getValue()}
                      </div>
                    </Link>
                  );
                },
                footer: () => {
                  return (
                    <Link
                      to={{
                        pathname: `/reporting/lead-summary`,
                        search: `${key ? `dataset_ids[]=${key}` : ``}${docDateStr()}${callDateStr()}`,
                      }}
                    >
                      <div style={{ textAlign: 'right' }}>{grandTotalMapped[ds.dataset.name].toLocaleString()}</div>
                    </Link>
                  );
                },
                enablePinning: true,
                sortingFn: 'basic',
              },
              {
                id: `${key}-percent`,
                header: `%`,
                accessorFn: (row: DashboarDataWithDetails) =>
                  (((row.details?.[key].count || 0) / grandTotalMapped[ds.dataset.name]) * 100).toFixed(2) + '%',
                cell: ({ getValue }: { getValue: () => string }) => (
                  <div style={{ textAlign: 'right' }}>{getValue()}</div>
                ),
                footer: () => <div style={{ textAlign: 'right' }}>100.00%</div>,
              },
            ],
            accessorFn: (row: DashboarDataWithDetails) => row.details?.[key].count || 0,
            enableSorting: true,
            sortingFn: 'basic',
          });
        }
      });
      cols.push({
        id: 'total',
        header: () => {
          return (
            <div className="pointer" onClick={updateSort('count')}>
              Total
            </div>
          );
        },
        columns: [
          {
            header: () => {
              return (
                <div className="pointer" onClick={updateSort('count')}>
                  Count
                </div>
              );
            },
            accessorKey: 'count',
            cell: ({ getValue, row }) => {
              return (
                <Link
                  to={{
                    pathname: `/reporting/lead-summary`,
                    search: `master_lead_status=${row.original.status}${filters.dataset_ids
                      ?.map(id => `&dataset_ids[]=${id}`)
                      .join('')}${docDateStr()}${callDateStr()}`,
                  }}
                >
                  <div style={{ textAlign: 'right' }}>{(getValue() as number).toLocaleString()}</div>
                </Link>
              );
            },
            footer: () => {
              return (
                <Link
                  to={{
                    pathname: `/reporting/lead-summary`,
                    search: `${docDateStr()}${callDateStr()}`,
                  }}
                >
                  <div style={{ textAlign: 'right' }}>{grandTotalMapped['total'].toLocaleString()}</div>
                </Link>
              );
            },
            sortingFn: 'basic',
          },
          {
            header: '%',
            accessorFn: (row: DashboarDataWithDetails) =>
              ((row.count / grandTotalMapped['total']) * 100).toFixed(2) + '%',
            cell: ({ getValue }) => <div style={{ textAlign: 'right' }}>{getValue()}</div>,
            footer: () => <div style={{ textAlign: 'right' }}>100.00%</div>,
          },
        ],
        accessorKey: 'count',
        enableSorting: true,
        sortingFn: 'basic',
      });
      return cols;
    }
    cols.push({
      id: 'count',
      header: () => {
        return (
          <div className="pointer" onClick={updateSort('count')}>
            Count
          </div>
        );
      },
      accessorKey: 'count',
      cell: ({ getValue, row }) => {
        return (
          <Link
            to={{
              pathname: `/reporting/lead-summary`,
              search: `master_lead_status=${row.original.status}${filters.dataset_ids
                ?.map(id => `&dataset_ids[]=${id}`)
                .join('')}${docDateStr()}${callDateStr()}`,
            }}
          >
            <div style={{ textAlign: 'right' }}>
              <strong>{(getValue() as number).toLocaleString()}</strong>
            </div>
          </Link>
        );
      },
      footer: () => {
        return (
          <Link
            to={{
              pathname: `/reporting/lead-summary`,
              search: `${filters.dataset_ids
                ?.map(id => `&dataset_ids[]=${id}`)
                .join('')}${docDateStr()}${callDateStr()}`,
            }}
          >
            <div style={{ textAlign: 'right' }}>
              <strong>{grandTotalMapped['total'].toLocaleString()}</strong>
            </div>
          </Link>
        );
      },
      enableSorting: true,
      sortingFn: 'basic',
    });
    cols.push({
      id: 'percent',
      header: '%',
      accessorFn: (row: DashboarDataWithDetails) => ((row.count / grandTotalMapped['total']) * 100).toFixed(2) + '%',
      cell: ({ getValue }) => <div style={{ textAlign: 'right' }}>{getValue()}</div>,
      footer: () => <div style={{ textAlign: 'right' }}>100.00%</div>,
      enableSorting: false,
    });
    return cols;
  }, [sortedData, filters, grandTotalMapped, updateDatasetSort, updateSort]);

  const [sorting, setSorting] = useState<SortingState>([]);

  const tableInstance = useReactTable({
    columns,
    data: sortedData,
    initialState: {
      columnPinning: {
        left: ['status'],
      },
    },
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel<DashboarDataWithDetails>(),
  });

  return (
    <Container>
      <Helmet>
        <title>Dashboard - Reporting | datascore</title>
      </Helmet>

      <PageHeader>
        <Breadcrumbs crumbs={['Reporting', 'Dashboard']} />
      </PageHeader>

      <Form>
        <Form.Group>
          <Form.Select
            label="Dataset(s)"
            clearable
            multiple
            placeholder="All Datasets"
            name="dataset_ids"
            onChange={onChangeFilters}
            loading={datasetLoading}
            value={filters.dataset_ids || []}
            options={datasets?.data.map(ds => ({ key: ds.id, value: ds.id, text: ds.name })) || []}
          />
          <Form.Field>
            <label>Created Date</label>
            <DatePicker
              selected={filters?.doc_date_range?.start}
              onChange={handleDocDatesChange}
              startDate={filters?.doc_date_range?.start}
              endDate={filters?.doc_date_range?.end}
              selectsRange
              includeDates={availableDatesDoc}
              customInput={<Input icon="calendar" iconPosition="left" />}
            />
          </Form.Field>
          <Form.Field>
            <label>Recent Call Date</label>
            <DatePicker
              selected={filters?.call_date_range?.start}
              onChange={handleCallDatesChange}
              startDate={filters?.call_date_range?.start}
              endDate={filters?.call_date_range?.end}
              selectsRange
              includeDates={availableDatesCall}
              customInput={<Input icon="calendar" iconPosition="left" />}
            />
          </Form.Field>
          <Form.Checkbox
            toggle
            label="Breakdown by Dataset"
            checked={filters.breakdown_by_dataset || false}
            name="breakdown_by_dataset"
            onChange={onChangeFilters}
          />
        </Form.Group>
      </Form>

      <div className="table-container">
        <table className="tanstack-table">
          <thead>
            {tableInstance.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  const { column } = header;
                  return (
                    <th key={header.id} colSpan={header.colSpan} style={{ ...getCommonPinningStyles(column) }}>
                      {header.isPlaceholder ? null : (
                        <div
                          className={header.column.getCanSort() ? 'pointer' : ''}
                          onClick={header.column.getToggleSortingHandler()}
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === 'asc'
                                ? 'Sort ascending'
                                : header.column.getNextSortingOrder() === 'desc'
                                ? 'Sort descending'
                                : 'Clear sort'
                              : undefined
                          }
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {isLoading ? (
            <tbody>
              <tr>
                <td colSpan={3}>
                  <Loader active inline />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {tableInstance.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    const { column } = cell;
                    return (
                      <td key={cell.id} style={{ ...getCommonPinningStyles(column) }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          )}
          <tfoot>
            {tableInstance.getFooterGroups().map(footerGroup => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map(footer => {
                  const { column } = footer;
                  return (
                    <td key={footer.id} colSpan={footer.colSpan} style={{ ...getCommonPinningStyles(column) }}>
                      {footer.isPlaceholder ? null : flexRender(footer.column.columnDef.footer, footer.getContext())}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </Container>
  );
};

export default ReportingDashboard;
