import { useCallback, useMemo, useState } from 'react';
import { DropdownItemProps, Form, Icon, Table } from 'semantic-ui-react';

import {
  useGetTwilioAvailablePhonesMutation,
  useGetTwilioStudioFlowsQuery,
  useProvisionTwilioAvailablePhonesMutation,
} from 'src/api/auth/account-twilio';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useListVoiceConfigsQuery } from 'src/api/voice-configs';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { TwilioAvailablePhone, TwilioAvailablePhoneSearchType } from 'src/types';
import SearchByPostalCodes from './SearchByPostalCodes';

const headers = ['Phone Number', 'Friendly Name', 'Description', ''];

const LimitOptions = ['1', '5', '10', '25', '50', '100'].map(l => ({ key: l, text: l, value: l }));

const SearchTypeOptions: DropdownItemProps[] = [
  {
    key: TwilioAvailablePhoneSearchType['area-code'],
    text: 'Area Code',
    value: TwilioAvailablePhoneSearchType['area-code'],
  },
  {
    key: TwilioAvailablePhoneSearchType['contains'],
    text: 'Contains',
    value: TwilioAvailablePhoneSearchType['contains'],
  },
  {
    key: TwilioAvailablePhoneSearchType['locality'],
    text: 'Locality',
    value: TwilioAvailablePhoneSearchType['locality'],
  },
];

const TwilioBuyNumbers = () => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [searchType, setSearchType] = useState<TwilioAvailablePhoneSearchType>(
    SearchTypeOptions[0].value as TwilioAvailablePhoneSearchType
  );
  const [selectedWebhookUrl, setSelectedWebhookUrl] = useState('');
  const [phones, setPhones] = useState<TwilioAvailablePhone[]>([]);
  const { mutateAsync: searchPhones, isLoading: searchLoading } = useGetTwilioAvailablePhonesMutation();
  const { mutateAsync: provisionPhones, isLoading: provisionLoading } = useProvisionTwilioAvailablePhonesMutation();

  const { data, isLoading: flowsLoading } = useGetTwilioStudioFlowsQuery();
  const { data: voiceConfigs, isLoading: voiceConfigsLoading } = useListVoiceConfigsQuery({ limit: 100, offset: 0 });
  const webhookUrlOptions = useMemo<DropdownItemProps[]>(() => {
    let items =
      data?.flows?.map(f => ({
        key: f.sid,
        text: f.friendly_name,
        value: f.webhook_url,
        description: 'Studio Flow',
      })) || [];

    if (voiceConfigs?.data) {
      items = items.concat(
        voiceConfigs?.data.map(voiceConfig => {
          return {
            key: voiceConfig.id,
            text: voiceConfig.name,
            value: voiceConfig.id,
            description: 'Voice Config',
          };
        })
      );
    }

    return items;
  }, [data?.flows, voiceConfigs?.data]);

  const onLimitChange = useCallback((_, { value }) => {
    setLimit(Number(value));
  }, []);

  const onSubmitSearch = async () => {
    setApiMessage(undefined);

    try {
      const data = await searchPhones({ limit, search, searchType });
      if (!data.phones) {
        throw new Error('Request failed');
      }
      setPhones(data.phones);
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  const isValidUrl = (input: string) => {
    try {
      new URL(input);
      return true;
    } catch {
      return false;
    }
  };

  const onSubmitBuy = async () => {
    setApiMessage(undefined);

    try {
      const provisionPhoneParams = { phoneNumbers: phones.map(p => p.phone_number), webhookUrl: '', voiceConfigID: '' };
      if (isValidUrl(selectedWebhookUrl)) {
        provisionPhoneParams.webhookUrl = selectedWebhookUrl;
      } else {
        provisionPhoneParams.voiceConfigID = selectedWebhookUrl;
      }

      await provisionPhones(provisionPhoneParams);
      setPhones([]);
      setSearch('');
      setSelectedWebhookUrl('');
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  let searchPlaceholder = '';
  switch (searchType) {
    case TwilioAvailablePhoneSearchType['area-code']:
      searchPlaceholder = 'digits';
      break;
    case TwilioAvailablePhoneSearchType.contains:
      searchPlaceholder = 'letters or digits';
      break;
    case TwilioAvailablePhoneSearchType.locality:
      searchPlaceholder = 'city, state';
      break;
  }

  return (
    <div>
      <ApiMessage data={apiMessage} />

      <div style={{ display: 'flex' }}>
        <Form onSubmit={onSubmitSearch}>
          <Form.Group>
            <Form.Select
              options={SearchTypeOptions}
              value={searchType}
              name="searchType"
              onChange={(_, { value }) => setSearchType(value as TwilioAvailablePhoneSearchType)}
            />
            <Form.Input
              placeholder={searchPlaceholder}
              value={search}
              onChange={(_, { value }) => setSearch(String(value))}
            />
            <Form.Select options={LimitOptions} value={String(limit)} onChange={onLimitChange} compact />
            <Form.Button color="blue" icon loading={searchLoading} style={{ margin: 0 }}>
              <Icon name="search" />
            </Form.Button>
          </Form.Group>
        </Form>

        <div style={{ display: 'flex', alignItems: 'center', margin: '0 1rem 1rem' }}>
          <span style={{ color: '#bbb' }}> OR </span>
        </div>

        <div style={{ margin: '0 0 1rem' }}>
          <SearchByPostalCodes />
        </div>
      </div>

      {phones.length > 0 && (
        <>
          <Form onSubmit={onSubmitBuy}>
            <Form.Group>
              <Form.Select
                placeholder="Select flow or voice config to assign new numbers to"
                loading={flowsLoading || voiceConfigsLoading}
                clearable
                options={webhookUrlOptions}
                value={selectedWebhookUrl}
                onChange={(_, { value }) => setSelectedWebhookUrl(String(value))}
              />
              <Form.Button color="green" loading={provisionLoading}>
                Buy All
              </Form.Button>
            </Form.Group>
          </Form>

          <Table padded>
            <Table.Header>
              <Table.Row>
                {headers.map((h, i) => (
                  <Table.HeaderCell key={`${i}-${h}`}>{h}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body style={{ position: 'relative' }}>
              {phones.map(p => (
                <Table.Row key={p.phone_number}>
                  <Table.Cell>{p.phone_number}</Table.Cell>
                  <Table.Cell>{p.friendly_name}</Table.Cell>
                  <Table.Cell>{p.description}</Table.Cell>
                  <Table.Cell textAlign="right" collapsing>
                    <Row style={{ justifyContent: 'flex-end' }}></Row>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      )}
    </div>
  );
};

export default TwilioBuyNumbers;
