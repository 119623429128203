import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  BandwidthApplication,
  BandwidthAvailablePhone,
  BandwidthAvailablePhoneSearchType,
  BandwidthPhone,
  BandwidthPhonesRefetchJob,
  Delimiter,
  PaginatedResponse,
} from 'src/types';
import { ApiResponse, http } from '../http-common';

export type ReleaseBandwidthPhonesParams = {
  phones: string[];
};

export const useReleaseBandwidthPhonesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: ReleaseBandwidthPhonesParams) => {
      const res = await http.delete<ApiResponse>(`/api/auth/account/bandwidth/phones`, { params });
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'bandwidth/phones']);
      },
    }
  );
};

export type ProvisionBandwidthAvailablePhonesParams = {
  phoneNumbers: string[];
  voiceConfigID: string;
};

export const useProvisionBandwidthAvailablePhonesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: ProvisionBandwidthAvailablePhonesParams) => {
      const res = await http.post<ApiResponse>(`/api/auth/account/bandwidth/available-phones`, params);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'bandwidth/phones']);
      },
    }
  );
};

export type GetBandwidthAvailablePhonesParams = {
  limit: number;
  search: string;
  searchType: BandwidthAvailablePhoneSearchType;
};

export type GetBandwidthAvailablePhonesResponse = {
  phones: BandwidthAvailablePhone[];
};

export const useGetBandwidthAvailablePhonesMutation = () => {
  return useMutation(async (params: GetBandwidthAvailablePhonesParams) => {
    const res = await http.get<GetBandwidthAvailablePhonesResponse>(`/api/auth/account/bandwidth/available-phones`, {
      params,
    });
    return res.data;
  }, {});
};

export type GetPostalCodeStatsInput = {
  mode: 'file' | 'text';
  // file inputs
  file?: File;
  delimiter: Delimiter;
  fieldMappings: string[];
  hasHeader: boolean;
  // text inputs
  zips: string;
};

export type CityCounts = { [city: string]: { count: number; latLng: number[] } };

export type PostalCodeStats = {
  raw: number;
  unique: number;
  valid: number;
  cities: number;
  byCity: CityCounts;
  states: number;
  byState: { [state: string]: number };
};

export type GetPostalCodeStatsResponse = ApiResponse & {
  stats: PostalCodeStats;
};

export const useGetPostalCodeStatsMutation = () => {
  return useMutation(async (input: GetPostalCodeStatsInput) => {
    if (input.mode === 'file' && !input.file) {
      const err = {
        response: {
          status: 422,
          statusText: 'Unprocessable Entity',
          data: { success: false, errors: [{ error: 'file is required' }] },
        },
      };
      throw err;
    }

    const fd = new FormData();
    fd.append('mode', input.mode);
    if (typeof input.file !== 'undefined') {
      fd.append('file', input.file);
    }
    fd.append('delimiter', input.delimiter);
    fd.append('field_mappings', input.fieldMappings.join(','));
    fd.append('has_header', input.hasHeader ? '1' : '0');
    fd.append('zips', input.zips);

    const res = await http.post<GetPostalCodeStatsResponse>(`/api/auth/account/bandwidth/postal-code-stats`, fd);
    return res.data.stats;
  }, {});
};

export type PurchaseData = {
  city?: string;
  state: string;
  count: number;
  latLng?: number[];
};

export type BandwidthPurchasePostalCodesInput = {
  group: 'city' | 'state';
  voiceConfigID: string;
  counts: PurchaseData[];
};

export type PurchasePostalCodesResponse = ApiResponse & {
  purchaseCounts: { [key: string]: number };
};

export const usePurchasePostalCodesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: BandwidthPurchasePostalCodesInput) => {
      const res = await http.post<PurchasePostalCodesResponse>(
        `/api/auth/account/bandwidth/purchase-postal-codes`,
        input
      );
      return res.data.purchaseCounts;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['jobs']);
      },
    }
  );
};

export type GetBandwidthPhonesResponse = ApiResponse & {
  phones: PaginatedResponse<BandwidthPhone>;
};

export type GetBandwidthPhonesParams = {
  limit: number;
  offset: number;
  search: string;
  startDate?: string;
  endDate?: string;
  profiles?: string[];
};

export const useGetBandwidthPhonesQuery = (params: GetBandwidthPhonesParams) => {
  return useQuery(
    ['auth/account', 'bandwidth/phones', params],
    async () => {
      const res = await http.get<GetBandwidthPhonesResponse>(`/api/auth/account/bandwidth/phones`, {
        params,
      });
      return res.data;
    },
    { retry: false }
  );
};

type GetBandwidthJobsRecentResponse = {
  job?: BandwidthPhonesRefetchJob;
};

export const useGetBandwidthJobsRecentQuery = () => {
  const queryClient = useQueryClient();

  return useQuery(
    ['auth/account', 'bandwidth/jobs'],
    async () => {
      const res = await http.get<GetBandwidthJobsRecentResponse>(`/api/auth/account/bandwidth/jobs/recent`);
      return res.data.job;
    },
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'bandwidth/phones']);
      },
    }
  );
};

export const useRefetchBandwidthPhonesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const res = await http.post<ApiResponse>(`/api/auth/account/bandwidth/jobs/phones-refetch`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'bandwidth/jobs']);
        queryClient.invalidateQueries(['jobs']);
      },
    }
  );
};

export type ReassignBandwidthPhonesVoiceConfigParams = {
  phones: string[];
  configID: string;
};

export const useReassignBandwidthPhonesVoiceConfigMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: ReassignBandwidthPhonesVoiceConfigParams) => {
      const res = await http.post<ApiResponse>(`/api/auth/account/bandwidth/jobs/phones-reassign-voice-config`, data);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'bandwidth/jobs']);
        queryClient.invalidateQueries(['jobs']);
      },
    }
  );
};

export type GetBandwidthTotalPhoneCountResponse = ApiResponse & {
  count: number;
};

export const useGetBandwidthTotalIncomingPhoneCountQuery = () => {
  return useQuery(
    ['auth/account', 'bandwidth/phones', 'count'],
    async () => {
      const res = await http.get<GetBandwidthTotalPhoneCountResponse>(`/api/auth/account/bandwidth/phones/count`);
      return res.data;
    },
    { retry: false }
  );
};

export type GetBandwidthTexmlAppsResponse = {
  apps?: BandwidthApplication[];
};

export const useGetBandwidthTexmlAppsQuery = () => {
  return useQuery(
    ['auth/account', 'bandwidth/apps'],
    async () => {
      const res = await http.get<GetBandwidthTexmlAppsResponse>(`/api/auth/account/bandwidth/apps`);
      return res.data;
    },
    {}
  );
};
