import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Message, Segment } from 'semantic-ui-react';

import Loading from 'src/components/Loading';
import { Container, Header } from 'src/styles';

// TODO: Placeholder Page
const AdminUsersEdit = () => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 250);
  }, []);

  return (
    <Container>
      <Helmet>
        <title>Edit User - Admin | datascore</title>
      </Helmet>

      <Header>Admin / Users / Edit</Header>
      {loading ? (
        <Loading />
      ) : (
        <Segment>
          <Message warning>
            <Message.Header>🚧 Under Construction</Message.Header>
            <Message.Content>This page isn't ready yet. Check back soon.</Message.Content>
          </Message>
        </Segment>
      )}
    </Container>
  );
};

export default AdminUsersEdit;
