import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { Loader } from 'semantic-ui-react';

import { AgentAttributionData, P1ClosesData } from 'src/api/reporting';

export const sortFunc =
  (column: string, direction: string) =>
  (a: { [key: string]: string | number }, b: { [key: string]: string | number }) => {
    if (!column) return 1;
    if (direction === 'ascending') {
      return a[column] > b[column] ? 1 : -1;
    } else {
      return a[column] < b[column] ? 1 : -1;
    }
  };

export const TableDefinition = <T extends AgentAttributionData | P1ClosesData>({
  columns,
  sortedData,
  sortingLabel,
  isDataLoading,
}: {
  columns: ColumnDef<T>[];
  sortedData: T[];
  sortingLabel: string;
  isDataLoading: boolean;
}) => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: sortingLabel,
      desc: false,
    },
  ]);

  const tableInstance = useReactTable({
    columns,
    data: sortedData || [],
    initialState: {
      sorting,
    },
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel<AgentAttributionData | P1ClosesData>(),
  });

  return (
    <table className="tanstack-table">
      <thead>
        {tableInstance.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              return (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'white',
                    zIndex: 1,
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={header.column.getCanSort() ? 'pointer' : ''}
                      onClick={header.column.getToggleSortingHandler()}
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === 'asc'
                            ? 'Sort ascending'
                            : header.column.getNextSortingOrder() === 'desc'
                            ? 'Sort descending'
                            : 'Clear sort'
                          : undefined
                      }
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      {isDataLoading ? (
        <tbody>
          <tr>
            <td colSpan={6}>
              <Loader active inline />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {tableInstance.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      )}
      <tfoot>
        {tableInstance.getFooterGroups().map(footerGroup => (
          <tr
            key={footerGroup.id}
            style={{
              position: 'sticky',
              bottom: 0,
              backgroundColor: 'white',
              zIndex: 1,
            }}
          >
            {footerGroup.headers.map(footer => {
              return (
                <td key={footer.id} colSpan={footer.colSpan}>
                  {footer.isPlaceholder ? null : flexRender(footer.column.columnDef.footer, footer.getContext())}
                </td>
              );
            })}
          </tr>
        ))}
      </tfoot>
    </table>
  );
};
