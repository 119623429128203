import { Table } from 'semantic-ui-react';

import { Account } from 'src/types';
import AdminAccountSetAkkioModelCostForm from './AdminAccountSetAkkioModelCostForm';
import RemoveAkkioModel from './RemoveAkkioModel';

type Props = {
  account: Account;
};

const AdminAccountAkkioModelsListBody = ({ account }: Props) => {
  if (account.akkioModelAccess === null) return null;

  return (
    <>
      {account.akkioModelAccess.map(ma => {
        // if (typeof ma.akkioModel === 'undefined') return null;
        return (
          <Table.Row key={ma.akkioModel.id}>
            <Table.Cell>{ma.akkioModel.name}</Table.Cell>
            <Table.Cell>
              <AdminAccountSetAkkioModelCostForm modelAccess={ma} />
            </Table.Cell>
            <Table.Cell textAlign="right" collapsing>
              <RemoveAkkioModel accountId={ma.accountId} modelId={ma.akkioModelId} />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
};

export default AdminAccountAkkioModelsListBody;
