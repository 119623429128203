import { Helmet } from 'react-helmet-async';

import PaginatedTable from 'src/components/PaginatedTable';
import { Container, Header, Row } from 'src/styles';
import AccountsListBody from './AccountsListBody';

const AccountsList = () => {
  return (
    <Container>
      <Helmet>
        <title>Accounts | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header style={{ marginBottom: 0 }}>Accounts</Header>
        {/* <AddAccount /> */}
      </Row>

      <PaginatedTable headers={['Name', 'Role', '']} renderBody={AccountsListBody} />
    </Container>
  );
};

export default AccountsList;
