import { useMemo } from 'react';
import { DropdownItemProps, Form, Message } from 'semantic-ui-react';

import { useListFeedsQuery } from 'src/api/feeds';
import { SendgridInboundParseActionTrigger } from 'src/types';

const frontendUrl = process.env.REACT_APP_FRONTEND_URL;

type Props = {
  trigger: SendgridInboundParseActionTrigger;
  onChange: ({ name, value }: { name: string; value: string }) => void;
};

const TriggerDsFeedSettings = ({ trigger: action, onChange }: Props) => {
  const { data: feeds, isLoading } = useListFeedsQuery({ limit: 10, offset: 0 });

  const dsFeedOptions = useMemo<DropdownItemProps[]>(() => {
    if (isLoading) return [{ key: 'loading', value: '', text: 'Loading...', disabled: true }];
    if (!feeds) return [{ key: 'not-found', value: '', text: 'No results found', disabled: true }];
    return feeds.data.map(f => ({ key: f.id, value: String(f.id), text: `${f.name} (${f.url})` }));
  }, [feeds, isLoading]);

  return (
    <>
      <Form.Select
        label="Datascore Feed to Trigger"
        name="feed_id"
        options={dsFeedOptions}
        onChange={(_, { name, value }) => onChange({ name, value: String(value) })}
        loading={isLoading}
        value={action.payload.feed_id || ''}
        // error={dsFeedOptions.length === 1 && dsFeedOptions[0].disabled}
      />
      {dsFeedOptions.length === 1 && dsFeedOptions[0].disabled && (
        <Message error visible>
          <Message.Header>No feeds found</Message.Header>
          <Message.Content>
            Have you created any <a href={`${frontendUrl}/feeds`}>Feeds</a> yet?
          </Message.Content>
        </Message>
      )}
    </>
  );
};

export default TriggerDsFeedSettings;
