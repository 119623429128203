import { useCallback } from 'react';
import { Button } from 'semantic-ui-react';

import { useDeleteAkkioModelAccountAccessMutation } from 'src/api/admin/accounts';

type Props = {
  accountId: number;
  modelId: number;
};

const RemoveAkkioModel = ({ accountId, modelId }: Props) => {
  const { isLoading, mutateAsync } = useDeleteAkkioModelAccountAccessMutation();

  const onClick = useCallback(async () => {
    await mutateAsync({ accountId, modelId });
  }, [accountId, modelId, mutateAsync]);

  return <Button icon="trash" color="red" onClick={onClick} loading={isLoading} />;
};

export default RemoveAkkioModel;
