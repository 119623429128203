import { Grid, Header } from 'semantic-ui-react';

import AccountBillingAlertsForm from 'src/pages/user/account/billing/settings/AccountBillingAlertsForm';
import { Account } from 'src/types';

type Props = {
  account: Account;
};

const AdminAccountBillingGeneral = ({ account }: Props) => {
  return (
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          <Header as="h4">Alert Email(s)</Header>

          <p>
            If the account balance drops below $100, we will send an email notification to the email(s) listed below.
          </p>

          <AccountBillingAlertsForm account={account} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default AdminAccountBillingGeneral;
