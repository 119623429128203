import { Fzf } from 'fzf';
import { cloneDeep } from 'lodash';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { BaseEditor, BaseText, createEditor, Descendant, Editor, Node, Range, Transforms } from 'slate';
import { HistoryEditor, withHistory } from 'slate-history';
import { Editable, ReactEditor, Slate, useFocused, useSelected, withReact } from 'slate-react';
import styled from 'styled-components';

import theme from 'src/styles/theme';

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor;

export type ParagraphElement = {
  type: 'paragraph';
  children: (BaseText | CustomElement)[];
};

export type TagElement = {
  type: 'tag';
  name: string;
  children: BaseText[];
};

export type CustomElement = ParagraphElement | TagElement;

// export type FormattedText = { text: string; bold?: boolean; italic?: boolean; underline?: boolean; code?: boolean };

// export type CustomText = FormattedText;

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    // Text: CustomText;
  }
}

const StyledEditable = styled(Editable)`
  margin: 0;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  padding: 0.78571429em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0 0 0 0 transparent inset;
  box-shadow: 0 0 0 0 transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 1em;
  line-height: 1.2857;

  &:focus {
    color: rgba(0, 0, 0, 0.95);
    border-color: #85b7d9;
    border-radius: 0.28571429rem;
    background: #fff;
    -webkit-box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.35) inset;
    box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.35) inset;
    -webkit-appearance: none;
  }

  p {
    margin-bottom: 0 !important;
    overflow-wrap: anywhere;
  }
`;

const TagList = styled.div`
  top: -9999px;
  left: -9999px;
  position: absolute;
  z-index: 5;
  padding: 3px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow-y: auto;
`;

const TagListItem = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  padding: 1px 3px;
  border-radius: 3px;
  background: ${p => (p.isActive ? 'rgba(89, 106, 240, 0.2)' : 'transparent')};

  :hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const StyledTag = styled.span<{ selected?: boolean; focused?: boolean }>`
  padding: 3px 3px 2px;
  margin: 0 1px;
  vertical-align: baseline;
  display: inline-block;
  border-radius: 4px;
  background-color: #eee;
  font-size: 0.9em;
  box-shadow: ${p => (p.selected && p.focused ? '0 0 0 2px #B4D5FF' : 'none')};
`;

export const Portal = ({ children }: { children?: ReactNode }) => {
  return typeof document === 'object' ? ReactDOM.createPortal(children, document.body) : null;
};

const serialize = (value: Descendant[]): string => {
  return value
    .map(n => {
      const custom = n as CustomElement;

      if (custom.type === 'paragraph') {
        const paragraph = n as ParagraphElement;
        return paragraph.children
          .map(c => {
            if ((c as CustomElement).type === 'tag') {
              const tag = c as TagElement;
              return `{{${tag.name}}}`;
            }

            const text = c as BaseText;
            if (text.text) {
              return text.text;
            }

            return '';
          })
          .join('');
      }

      if (custom.type === 'tag') {
        return `{{${custom.name}}}`;
      }

      return Node.string(n);
    })
    .join('\n');
};

const deserialize = (value: string): Descendant[] => {
  if (!value) return [{ type: 'paragraph', children: [{ text: '' }] }];

  return value.split('\n').map((text: string) => {
    const matches = text.match(/(\{\{[^}]+\}\})/g);
    if (matches) {
      const children = [];

      for (let i = 0; i < matches.length; i++) {
        if (text.indexOf(matches[i]) > 0) {
          children.push({ text: text.slice(0, text.indexOf(matches[i])) });
        }

        const match = matches[i];
        const name = match.slice(2, -2);
        children.push({ type: 'tag', name, children: [{ text: '' }] });

        text = text.slice(text.indexOf(matches[i]) + matches[i].length);
      }

      if (text.length > 0) {
        children.push({ text });
      }

      return { type: 'paragraph', children } as ParagraphElement;
    }

    return { type: 'paragraph', children: [{ text }] };
  });
};

// const input = '{ "foo": "123",\n  "bar": "{{Admiral Dodd Rancit}}",\n  "baz": "{{Bala-Tik}}" }';
// const output = serialize(deserialize(input));
// console.log(input);
// console.log(output);
// console.log(input === output);

type Props = {
  initialValue: string;
  onChange: (value: string) => void;
  tags: string[];
};

const BodyTemplateEditor = ({ initialValue, onChange, tags }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [target, setTarget] = useState<Range | undefined>();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const renderPlaceholder = useCallback(({ attributes, children }) => {
    attributes.style = { ...attributes.style, color: theme.gray, top: undefined };

    return (
      <div {...attributes}>
        <p>{children}</p>
      </div>
    );
  }, []);
  const editor = useMemo(() => withTags(withReact(withHistory(createEditor()))), []);

  // const filteredTags = tags.filter(c => c.toLowerCase().includes(search.toLowerCase())).slice(0, 10);
  const filteredTags = useMemo(() => new Fzf(tags).find(search), [search, tags]);

  const onKeyDown = useCallback(
    (event: any) => {
      if (target && filteredTags.length > 0) {
        const tagListPadding = 3;

        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const prevIndex = index >= filteredTags.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);

            if (ref.current) {
              const listHeight = Math.ceil(ref.current.getBoundingClientRect().height);
              const nextItem = ref.current.querySelectorAll('div')[prevIndex];
              const itemHeight = Math.ceil(nextItem.getBoundingClientRect().height);
              const nextOffsetTop = nextItem.offsetTop;
              const currScrollTop = ref.current.scrollTop;

              let nextScrollTop = nextOffsetTop - listHeight + itemHeight + tagListPadding;
              if (nextScrollTop < 0) {
                nextScrollTop = 0;
              }

              if (nextScrollTop > currScrollTop || nextOffsetTop < currScrollTop) {
                ref.current.scrollTo(0, nextScrollTop);
              }
            }
            break;

          case 'ArrowUp':
            event.preventDefault();
            const nextIndex = index <= 0 ? filteredTags.length - 1 : index - 1;
            setIndex(nextIndex);

            if (ref.current) {
              const listHeight = Math.ceil(ref.current.getBoundingClientRect().height);
              const nextItem = ref.current.querySelectorAll('div')[nextIndex];
              const nextOffsetTop = nextItem.offsetTop;
              const currScrollTop = ref.current.scrollTop;

              let nextScrollTop = nextOffsetTop - tagListPadding;
              if (nextScrollTop < 0) {
                nextScrollTop = 0;
              }

              if (nextScrollTop < currScrollTop || currScrollTop + listHeight < nextScrollTop) {
                ref.current.scrollTo(0, nextScrollTop);
              }
            }
            break;

          case 'Tab':
          case 'Enter':
            event.preventDefault();
            Transforms.select(editor, target);
            insertTag(editor, filteredTags[index].item);
            setTarget(undefined);
            break;

          case 'Escape':
            event.preventDefault();
            setTarget(undefined);
            break;
        }
      }
    },
    [filteredTags, editor, index, target]
  );

  useEffect(() => {
    if (target && filteredTags.length > 0) {
      const el = ref.current;
      if (!el) return;

      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();

      const portalHeight = 225;
      if (rect.top + portalHeight > window.innerHeight) {
        el.style.top = 'auto';
        el.style.bottom = `${window.innerHeight - rect.top + window.scrollY}px`;
      } else {
        el.style.top = `${rect.top + window.scrollY + 24}px`;
      }

      el.style.left = `${rect.left + window.scrollX}px`;
    }
  }, [filteredTags.length, editor, index, search, target]);

  return (
    <Slate
      editor={editor}
      initialValue={deserialize(initialValue)}
      onChange={(value: Descendant[]) => {
        const { selection } = editor;

        if (selection && Range.isCollapsed(selection)) {
          const [cursor] = Range.edges(selection);

          const blockStart = cloneDeep(cursor);
          blockStart.offset = 0;

          const blockRange = Editor.range(editor, blockStart, cursor);
          const blockText = Editor.string(editor, blockRange);

          const tagRange = cloneDeep(blockRange);
          tagRange.anchor.offset = blockText.lastIndexOf('{{') || 0;
          if (tagRange.anchor.offset < 0) {
            tagRange.anchor.offset = 0;
          }

          const tagText = Editor.string(editor, tagRange);
          const tagMatch = tagText && tagText.match(/^\{{2}(.+)?$/);

          if (tagMatch) {
            setTarget(tagRange);
            setSearch(tagMatch[1] || '');
            setIndex(0);
            return;
          }
        }

        setTarget(undefined);

        onChange(serialize(value));
      }}
    >
      <StyledEditable
        onKeyDown={onKeyDown}
        placeholder="Enter some text..."
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        renderPlaceholder={renderPlaceholder}
      />
      {target && filteredTags.length > 0 && (
        <Portal>
          <TagList ref={ref} data-cy="tags-portal">
            {filteredTags.map((tag, i) => (
              <TagListItem
                key={tag.item}
                onClick={() => {
                  Transforms.select(editor, target);
                  insertTag(editor, tag.item);
                  setTarget(undefined);
                }}
                isActive={i === index}
              >
                {tag.item}
              </TagListItem>
            ))}
          </TagList>
        </Portal>
      )}
    </Slate>
  );
};

const withTags = (editor: CustomEditor) => {
  const { isInline, isVoid, markableVoid } = editor;

  editor.isInline = el => el.type === 'tag' || isInline(el);

  editor.isVoid = el => el.type === 'tag' || isVoid(el);

  editor.markableVoid = el => el.type === 'tag' || markableVoid(el);

  return editor;
};

const insertTag = (editor: any, name: any) => {
  const tag: TagElement = {
    type: 'tag',
    name,
    children: [{ text: '' }],
  };
  Transforms.insertNodes(editor, tag);
  Transforms.move(editor);
};

// Borrow Leaf renderer from the Rich Text example.
// In a real project you would get this via `withRichText(editor)` or similar.
const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const Element = (props: any) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case 'tag':
      return <Tag {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Tag = ({ attributes, children, element }: any) => {
  const selected = useSelected();
  const focused = useFocused();

  const style: React.CSSProperties = {};
  // See if our empty text child has any styling marks applied and apply those
  if (element.children[0].bold) {
    style.fontWeight = 'bold';
  }
  if (element.children[0].italic) {
    style.fontStyle = 'italic';
  }

  return (
    <StyledTag
      {...attributes}
      contentEditable={false}
      data-cy={`tag-${element.name.replace(' ', '-')}`}
      selected={selected}
      focused={focused}
      style={style}
    >
      {'{{'}
      {element.name}
      {'}}'}
      {children}
    </StyledTag>
  );
};

export default BodyTemplateEditor;
