import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';

import { useGetUserProfileQuery, useLogoutMutation } from 'src/api/auth';
import { AccountPermission } from 'src/types';
import { hasAccountAccessRoles, hasAccountPermission } from 'src/utils';
import { HeaderMenuItem } from './styles';

const UserDropdown = () => {
  const { data: user } = useGetUserProfileQuery();
  const { mutateAsync } = useLogoutMutation();

  const onLogoutClick = async (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    try {
      await mutateAsync();
    } catch (e: any) {}
  };

  if (!user) return null;

  const isAdmin = user.role === 'admin';

  const isOnboardingCompleted = Boolean(user.active_account.onboarding_completed_at);

  const hasAccessToBilling = hasAccountAccessRoles(user, ['owner', 'billing']);

  const hasAccessToBandwidth = hasAccountPermission(user, AccountPermission.BANDWIDTH) || isAdmin;

  const hasAccessToTelnyx = hasAccountPermission(user, AccountPermission.TELNYX) || isAdmin;

  const hasAccessToTwilio = hasAccountPermission(user, AccountPermission.TWILIO) || isAdmin;

  const hasAccessToSendgrid = hasAccountPermission(user, AccountPermission.SENDGRID) || isAdmin;

  return (
    <HeaderMenuItem>
      <Dropdown text={`${user.active_account.name} (${user.email})`} direction="left">
        <Dropdown.Menu>
          <Dropdown.Header>User</Dropdown.Header>
          <Link to={`/welcome`} className="item">
            Welcome
          </Link>

          {!isOnboardingCompleted && (
            <Link to={`/onboarding/business-profile`} className="item">
              Onboarding
            </Link>
          )}

          <Link to={`/auth/user/settings`} className="item">
            Profile
          </Link>

          <Dropdown.Divider />

          <Dropdown.Header>Account</Dropdown.Header>
          <Link to={`/account/settings`} className="item">
            General
          </Link>

          {hasAccessToBilling && (
            <Link to={`/account/billing/settings`} className="item">
              Billing
            </Link>
          )}

          {(hasAccessToBandwidth || hasAccessToSendgrid || hasAccessToTelnyx || hasAccessToTwilio) &&
            (isOnboardingCompleted || isAdmin) && <Dropdown.Divider />}

          {hasAccessToBandwidth && (isOnboardingCompleted || isAdmin) && (
            <Link to={`/account/bandwidth`} className="item">
              Bandwidth
            </Link>
          )}

          {hasAccessToSendgrid && (isOnboardingCompleted || isAdmin) && (
            <Link to={`/account/sendgrid`} className="item">
              SendGrid
            </Link>
          )}

          {hasAccessToTelnyx && (isOnboardingCompleted || isAdmin) && (
            <Link to={`/account/telnyx`} className="item">
              Telnyx
            </Link>
          )}

          {hasAccessToTwilio && (isOnboardingCompleted || isAdmin) && (
            <Link to={`/account/twilio`} className="item">
              Twilio
            </Link>
          )}

          <Dropdown.Divider />

          <Link to={`/accounts`} className="item">
            Switch Account
          </Link>
          <Dropdown.Item onClick={onLogoutClick}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </HeaderMenuItem>
  );
};

export default UserDropdown;
