import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import { MenubarItem, Nav, NavContainer } from './styles';

const OnboardingMenubar = () => {
  useEffect(() => {
    document.body.classList.remove('nav--collapsed');
  }, []);

  return (
    <NavContainer>
      <Nav>
        <MenubarItem>
          <NavLink to="/onboarding/business-profile">
            <Icon name="building" size="large" />
            <span>Business Profile</span>
          </NavLink>
        </MenubarItem>

        <MenubarItem>
          <NavLink to="/onboarding/call-center">
            <Icon name="phone" size="large" />
            <span>Call Center</span>
          </NavLink>
        </MenubarItem>

        {/* <MenubarItem>
          <NavLink to="/onboarding/schedule-interview">
            <Icon name="calendar alternate" size="large" />
            <span>Schedule Interview</span>
          </NavLink>
        </MenubarItem> */}
      </Nav>
    </NavContainer>
  );
};

export default OnboardingMenubar;
