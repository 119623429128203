import { Form, Label, Table } from 'semantic-ui-react';

import { Schedule } from 'src/types';
import { Days, TimeOptions } from './ScheduleTableForm';

type Props = {
  schedule: Schedule;
};

const ScheduleTable = ({ schedule }: Props) => (
  <Table collapsing compact>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell></Table.HeaderCell>
        <Table.HeaderCell>Start</Table.HeaderCell>
        <Table.HeaderCell>End</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {Days.filter(({ value }) => schedule.config[value].enabled).map(({ value, text }) => (
        <Table.Row
          key={value}
          positive={schedule.config[value].enabled}
          negative={schedule.config[value].enabled && schedule.config[value].end < schedule.config[value].start}
        >
          <Table.Cell>
            <Form.Checkbox name={`${value}.enabled`} checked={schedule.config[value].enabled} label={text} />
          </Table.Cell>
          <Table.Cell>
            <Form.Select options={TimeOptions} name={`${value}.start`} value={schedule.config[value].start} />
          </Table.Cell>
          <Table.Cell>
            <Form.Select options={TimeOptions} name={`${value}.end`} value={schedule.config[value].end} />
          </Table.Cell>
          <Table.Cell>
            {schedule.config[value].enabled && schedule.config[value].end < schedule.config[value].start && (
              <Label color="red">End value cannot be less than start value.</Label>
            )}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

export default ScheduleTable;
