import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Dataflow, DataflowRevision, DataflowSettings, JobType, PaginatedResponse } from '../types';
import { ApiResponse, http } from './http-common';

export type DataflowResponse = ApiResponse & {
  dataflow?: Dataflow;
};

export type DataflowsResponse = ApiResponse & {
  dataflows: PaginatedResponse<Dataflow>;
};

export type DataflowRevisionsResponse = ApiResponse & {
  revisions: PaginatedResponse<DataflowRevision>;
  unpublishedCount: number;
};

export type OverlayDataResponse = ApiResponse & {
  bigquery_datasets: BigQueryDatasets[];
};

export type AddDataflowInput = {
  name: string;
};

export const useAddDataflowMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: AddDataflowInput) => {
      const res = await http.post<DataflowResponse>('/api/dataflows/', input);
      return res.data;
    },
    {
      onSuccess: (_, { name }) => {
        queryClient.removeQueries(['dataflows', name]); // drop the cached result.
        queryClient.invalidateQueries(['dataflows']);
      },
    }
  );
};

export const useDuplicateDataflowMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (dataflowId: number) => {
      const res = await http.post<DataflowResponse>(`/api/dataflows/${dataflowId}/duplicate`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.removeQueries(['dataflows']); // drop the cached result.
        queryClient.invalidateQueries(['dataflows']);
      },
    }
  );
};

type ListDataflowsParams = {
  limit: number;
  offset: number;
};

export const useListDataflowsQuery = (params: ListDataflowsParams) => {
  return useQuery(['dataflows', params], async () => {
    const res = await http.get<DataflowsResponse>('/api/dataflows', { params });
    return res.data.dataflows;
  });
};

export const useListRevisionsQuery = (dataflowId: number, params: ListDataflowsParams) => {
  return useQuery(['dataflows', 'revisions', dataflowId, params], async () => {
    const res = await http.get<DataflowRevisionsResponse>(`/api/dataflows/${dataflowId}/revisions`, { params });
    return res.data;
  });
};

export const useGetDataflowQuery = (dataflowId: number) => {
  return useQuery(['dataflow', dataflowId], async () => {
    const res = await http.get<DataflowResponse>(`/api/dataflows/${dataflowId}`);
    return res.data.dataflow;
  });
};

type UpdateDataflowInput = DataflowSettings & {
  dataflowId: number;
};

export const useUpdateDataflowMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ dataflowId, ...params }: UpdateDataflowInput) => {
      const res = await http.post<DataflowResponse>(`/api/dataflows/${dataflowId}`, params);
      return res.data;
    },
    {
      onSuccess: (_, { dataflowId }) => {
        queryClient.removeQueries(['dataflow', dataflowId]); // drop the cached result.
        queryClient.invalidateQueries(['dataflow', dataflowId]);
      },
    }
  );
};

export const useSaveRevisionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ dataflowId, ...input }: Omit<DataflowRevision, 'id' | 'created'>) => {
      const res = await http.post(`/api/dataflows/${dataflowId}/revisions`, input);
      return res.data;
    },
    {
      onSuccess: (_, { dataflowId }) => {
        queryClient.invalidateQueries(['dataflows', 'revisions', dataflowId]);
      },
    }
  );
};

type PublishRevisionInput = {
  dataflowId: number;
  revisionId: number;
};

export const usePublishRevisionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ dataflowId, revisionId }: PublishRevisionInput) => {
      const res = await http.post<ApiResponse>(`/api/dataflows/${dataflowId}/revisions/${revisionId}/publish`);
      return res.data;
    },
    {
      onSuccess: (_, { dataflowId }) => {
        queryClient.invalidateQueries(['dataflows', 'revisions', dataflowId]);
      },
    }
  );
};

export enum WidgetType {
  BigQuery = 'load_bigquery',
  Filter = 'filter',
  Output = 'output',
}

type BigQueryDatasets = {
  name: string;
  table_id: string;
  columns: {
    name: string;
    type: string;
  }[];
};

export const useGetWidgetDataQuery = (widgetType: WidgetType) => {
  return useQuery(
    ['widget-data', widgetType],
    async () => {
      const res = await http.get<OverlayDataResponse>('/api/dataflows/overlay-data', {
        params: { type: widgetType },
      });
      return res.data.bigquery_datasets;
    },
    { refetchOnWindowFocus: false }
  );
};

type RunDataflowInput = {
  dataflowId: number;
};

export const useRunDataflowMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ dataflowId }: RunDataflowInput) => {
      const res = await http.post<ApiResponse>(`/api/dataflows/${dataflowId}/run`);
      return res.data;
    },
    {
      onSuccess: (_, { dataflowId }) => {
        queryClient.invalidateQueries(['jobs', JobType.Dataflow, dataflowId]);
      },
    }
  );
};

type GetColumnOptionsInput = {
  tableID: string;
  column: string;
};

type GetColumnOptionsResponse = ApiResponse & {
  options: string[];
};

const AllowedColumns = ['status', 'location', 'campaign_id', 'source_id', 'list_id', 'state', 'list_name'];

export const useGetColumnOptions = (params: GetColumnOptionsInput) => {
  const { tableID, column } = params;

  return useQuery(
    ['column-options', tableID, column],
    async () => {
      if (!AllowedColumns.includes(params.column))
        return {
          success: true,
          options: [],
        };

      const res = await http.get<GetColumnOptionsResponse>('/api/dataflows/column-options', {
        params,
      });

      return res.data;
    },
    { refetchOnWindowFocus: false }
  );
};
