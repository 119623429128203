import { Helmet } from 'react-helmet-async';
import { Message } from 'semantic-ui-react';

import JobHistory from 'src/components/JobHistory';
import PaginatedTable from 'src/components/PaginatedTable';
import { Container, Header, Row } from 'src/styles';
import { JobType } from 'src/types';
import DatasetsJobDetailModal from './DatasetsJobDetailModal';
import ListDatasetsBody from './ListDatasetsBody';

const DatasetsList = () => {
  return (
    <Container>
      <Helmet>
        <title>Datasets v1 | datascore</title>
      </Helmet>

      <Message warning>
        <Message.Header>DEPRECATION WARNING!</Message.Header>
        <Message.Content>
          Be aware that the v1 datasets feature is going away soon. Please migrate any data still stored in the
          dataset(s) below to the new and improved v2 datasets feature.
        </Message.Content>
      </Message>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header style={{ marginBottom: 0 }}>Datasets v1</Header>
        {/* <AddDataset /> */}
      </Row>

      <PaginatedTable
        headers={['Name', 'Endpoint', 'Ingested Alltime', '24h', '1h', '5m', '']}
        renderBody={ListDatasetsBody}
      />

      <JobHistory jobType={JobType.DatasetUpload} details={DatasetsJobDetailModal} />
    </Container>
  );
};

export default DatasetsList;
