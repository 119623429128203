import { useCallback, useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { useRemoveDatasetFieldMutation } from 'src/api/datasets';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { Dataset } from 'src/types';

type Props = {
  dataset: Dataset;
  fieldId: string;
};

const DataseV2RemoveFieldModal = ({ dataset, fieldId }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [open, setOpen] = useState(false);
  const { mutateAsync, isLoading } = useRemoveDatasetFieldMutation();

  const onClose = useCallback(() => {
    setOpen(false);
    setApiMessage(undefined);
  }, []);

  const onSubmit = async () => {
    setApiMessage(undefined);

    try {
      await mutateAsync({ datasetId: dataset.id, fieldId });
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button type="button" color="red" icon style={{ marginTop: 24, width: 40, height: 38 }}>
          <Icon name="trash" />
        </Button>
      }
    >
      <Modal.Header>Remove Field from Dataset</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <p style={{ marginTop: 0 }}>
            <strong>Are you sure?</strong> This action cannot be undone.
          </p>

          <Row>
            <Button type="button" disabled={isLoading} onClick={onClose} fluid>
              Cancel
            </Button>

            <Button loading={isLoading} onClick={onSubmit} color="red" fluid>
              Remove Field
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default DataseV2RemoveFieldModal;
