import { Segment } from 'semantic-ui-react';

import PaginatedTable from 'src/components/PaginatedTable';
import { Header, HorizontalDivider } from 'src/styles';
import { Dataset } from 'src/types';
import DatasetV2ListsBody from './DatasetV2ListsBody';

type Props = {
  dataset: Dataset;
};

const DatasetV2Lists = ({ dataset }: Props) => {
  return (
    <Segment>
      <Header style={{ marginBottom: 0 }}>Lists</Header>

      <HorizontalDivider style={{ margin: '1rem -1rem' }} />

      <PaginatedTable
        headers={['ID', 'Name', 'Ingested Alltime', '']}
        renderBody={props => <DatasetV2ListsBody {...props} dataset={dataset} />}
      />
    </Segment>
  );
};

export default DatasetV2Lists;
