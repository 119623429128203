import { memo, useMemo } from 'react';
import { Position } from 'react-flow-renderer';
import { Divider, Icon, List, Popup } from 'semantic-ui-react';

import { useGetWidgetDataQuery, WidgetType } from 'src/api/dataflows';
import {
  NodeContainer,
  NodeHandle,
  NodeHandleContainer,
  NodeHeader,
  NodeHeaderTitle,
  NodeHeaderTitleContainer,
} from '../../components/NodeOverlay/styles';
import { NodeContainerProps } from '../../components/NodeOverlay/types';
import { BigQueryData } from '../types';
import { BigQueryNodeContent, BigQueryNodeContentItem } from './styles';

type ContentInfoProps = {
  data?: BigQueryData;
};

const ContentInfo = ({ data }: ContentInfoProps) => {
  const { table, daterange, hasFixedDateRange, relativeDateRange } = data || {};

  const { data: widgetData } = useGetWidgetDataQuery(WidgetType.BigQuery);

  const tableName = useMemo(() => widgetData?.find(({ table_id }) => table_id === table)?.name, [widgetData, table]);

  const dates = useMemo(
    () => [daterange?.map(d => (d ? new Date(d).toLocaleDateString('en-US') : '')).join(' - ')],
    [daterange]
  );

  return (
    <BigQueryNodeContent>
      <List>
        <List.Item>
          <BigQueryNodeContentItem>
            Table: <b>{tableName}</b>
          </BigQueryNodeContentItem>

          <BigQueryNodeContentItem>
            Date range: <b>{hasFixedDateRange ? dates : relativeDateRange?.label}</b>
          </BigQueryNodeContentItem>
        </List.Item>
      </List>
    </BigQueryNodeContent>
  );
};

const BigQueryNode = ({ data, selected }: NodeContainerProps<BigQueryData>) => {
  return (
    <NodeContainer selected={selected}>
      <NodeHeader>
        <NodeHeaderTitleContainer>
          <Icon name="searchengin" color="violet" />
          <NodeHeaderTitle>{data?.name || 'BigQuery'}</NodeHeaderTitle>
        </NodeHeaderTitleContainer>

        <Popup
          content="Load data from BigQuery table."
          trigger={<Icon name="info circle" color="grey" style={{ position: 'relative', zIndex: '99' }} />}
        />
      </NodeHeader>

      <Divider />

      <ContentInfo data={data} />

      <NodeHandleContainer position={Position.Bottom}>
        <NodeHandle type="source" position={Position.Bottom} />
      </NodeHandleContainer>
    </NodeContainer>
  );
};

export default memo(BigQueryNode);
