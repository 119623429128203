import { useCallback, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useUpdateQualifaiConversationMutation } from 'src/api/qualifai-conversations';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { QualifaiConversation } from 'src/types';

type Props = {
  conversation: QualifaiConversation;
};

const ToggleQualifaiConversationEnabledButton = ({ conversation }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync, isLoading } = useUpdateQualifaiConversationMutation();

  const onClose = useCallback(() => {
    setApiMessage(undefined);
    setOpen(false);
  }, []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    try {
      await mutateAsync({ ...conversation, enabled: !conversation.enabled });
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [mutateAsync, conversation, onClose]);

  return (
    <Modal
      size="mini"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button type="button" color={conversation.enabled ? 'green' : 'red'} size="tiny">
          {conversation.enabled ? 'Enabled' : 'Disabled'}
        </Button>
      }
    >
      <Modal.Header>{conversation.enabled ? 'Disable' : 'Enabled'} Conversation</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <p style={{ marginTop: 0 }}>
            <strong>Are you sure?</strong> This action cannot be undone.
          </p>

          <Row>
            <Button fluid type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button color={conversation.enabled ? 'red' : 'green'} fluid loading={isLoading}>
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default ToggleQualifaiConversationEnabledButton;
