import { formatDistanceToNow } from 'date-fns';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Icon, Label, Table } from 'semantic-ui-react';

import { ListUsersFilters, useListUsersQuery } from 'src/api/admin/users';
import { RenderProps } from 'src/components/PaginatedTable';
import theme from 'src/styles/theme';
import ToggleUserEnabledButton from './ToggleUserEnabledButton';

type Props = RenderProps & {
  filters?: ListUsersFilters;
};

const AdminUsersListBody = ({ currentPage, filters, limit, setPageCount }: Props) => {
  const { data: users } = useListUsersQuery({ limit, offset: limit * (currentPage - 1), ...filters });

  useEffect(() => {
    let count = 1;
    if (typeof users !== 'undefined') {
      count = Math.ceil(users.total / limit);
    }
    setPageCount(count);
  }, [users, limit, setPageCount]);

  return (
    <>
      <Helmet>
        <title>Users - Admin | datascore</title>
      </Helmet>

      {users?.data.map(user => (
        <Table.Row key={user.id}>
          <Table.Cell collapsing>
            <Label>{user.id}</Label>
          </Table.Cell>
          <Table.Cell collapsing>
            <ToggleUserEnabledButton user={user} />
          </Table.Cell>
          <Table.Cell>
            <strong>
              {user.firstname} {user.lastname}
            </strong>
            <span style={{ display: 'block', color: theme.gray, fontSize: '0.8rem', lineHeight: '1rem' }}>
              {user.email}
            </span>
          </Table.Cell>
          <Table.Cell>
            <Label color={user.role === 'admin' ? 'blue' : undefined}>{user.role}</Label>
          </Table.Cell>
          <Table.Cell>
            {user.last_login && (
              <>
                <strong title={user.last_login.created_at}>
                  {formatDistanceToNow(new Date(user.last_login.created_at), { addSuffix: true, includeSeconds: true })}
                </strong>
                <span style={{ display: 'block', color: theme.gray, fontSize: '0.8rem', lineHeight: '1rem' }}>
                  {user.last_login.ip_address}
                </span>
              </>
            )}
          </Table.Cell>
          <Table.Cell>
            {user.active_account && (
              <Link to={`/admin/accounts/${user.active_account.id}`}>
                <Icon name="external" />
                {user.active_account.name}
              </Link>
            )}
          </Table.Cell>
          <Table.Cell textAlign="right" collapsing>
            {/* <Button icon="pencil" color="blue" onClick={() => push(`/admin/users/${user.id}`)} /> */}
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default AdminUsersListBody;
