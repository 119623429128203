import { FC } from 'react';
import { Icon, Label } from 'semantic-ui-react';

import { JobStatus } from 'src/types';
import { getJobStatusColor } from 'src/utils';

type Props = {
  status: JobStatus;
};

const JobStatusLabel: FC<Props> = ({ status }) => {
  return (
    <Label color={getJobStatusColor(status)}>
      {JobStatus[status]}
      {status === JobStatus.Error && <Icon name="info circle" style={{ marginRight: 0, marginLeft: '0.5rem' }} />}
    </Label>
  );
};

export default JobStatusLabel;
