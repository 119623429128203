import { memo } from 'react';
import { Position } from 'react-flow-renderer';
import { Icon, Popup } from 'semantic-ui-react';

import {
  NodeContainer,
  NodeHandle,
  NodeHeader,
  NodeHeaderTitle,
  NodeHeaderTitleContainer,
} from '../../components/NodeOverlay/styles';
import { NodeContainerProps } from '../../components/NodeOverlay/types';

const OutputNode = ({ data, selected }: NodeContainerProps) => {
  return (
    <NodeContainer selected={selected}>
      <NodeHandle type="target" position={Position.Top} />

      <NodeHeader>
        <NodeHeaderTitleContainer>
          <Icon name="rss" color="violet" />
          <NodeHeaderTitle>{data?.name || 'Output'}</NodeHeaderTitle>
        </NodeHeaderTitleContainer>

        <Popup
          content="Output a data source."
          trigger={<Icon name="info circle" color="grey" style={{ position: 'relative', zIndex: '99' }} />}
        />
      </NodeHeader>
    </NodeContainer>
  );
};

export default memo(OutputNode);
