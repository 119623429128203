import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItemProps, Form, Message } from 'semantic-ui-react';

import { useGetTwilioStudioFlowsQuery } from 'src/api/auth/account-twilio';
import { SendgridInboundParseActionTrigger } from 'src/types';

type Props = {
  trigger: SendgridInboundParseActionTrigger;
  onChange: ({ name, value }: { name: string; value: string }) => void;
};

const TriggerTwilioStudioFlowSettings = ({ trigger: action, onChange }: Props) => {
  const { data, isLoading } = useGetTwilioStudioFlowsQuery();

  const studioFlowOptions = useMemo<DropdownItemProps[]>(() => {
    if (isLoading) return [{ key: 'loading', value: '', text: 'Loading...', disabled: true }];
    if (!data?.flows) return [{ key: 'not-found', value: '', text: 'Not results found', disabled: true }];
    return data.flows.map(f => ({ key: f.sid, value: f.sid, text: f.friendly_name }));
  }, [data?.flows, isLoading]);

  return (
    <>
      <Form.Select
        label="Studio Flow to Trigger"
        name="flow_sid"
        options={studioFlowOptions}
        onChange={(_, { name, value }) => onChange({ name, value: String(value) })}
        loading={isLoading}
        value={action.payload.flow_sid || ''}
        // error={studioFlowOptions.length === 1 && studioFlowOptions[0].disabled}
      />
      {studioFlowOptions.length === 1 && studioFlowOptions[0].disabled && (
        <Message error visible>
          <Message.Header>No studio flows found</Message.Header>
          <Message.Content>
            Please double check your account's <Link to={{ pathname: '/account/twilio' }}>Twilio Credentials</Link> are
            set correctly.
          </Message.Content>
        </Message>
      )}
    </>
  );
};

export default TriggerTwilioStudioFlowSettings;
