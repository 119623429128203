import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import { Logo } from 'src/components/Header/styles';
import logo from 'src/images/datascore.png';
import { Center as SCenter, Container } from 'src/styles';

const Center = styled(SCenter)`
  p {
    text-align: left !important;
  }
`;

const Tos = () => (
  <Container flex>
    <Helmet>
      <title>Terms of Service | datascore</title>
    </Helmet>

    <Logo>
      <img src={logo} style={{ height: 15 }} alt="datascore" />
    </Logo>

    <Center style={{ flexDirection: 'column' }}>
      <div style={{ maxWidth: '700px' }}>
        <p>
          <br />
        </p>
        <h1 style={{ textAlign: 'center' }}>MASTER SERVICES AGREEMENT</h1>
        <p>
          <br />
        </p>
        <p>
          This MASTER SERVICES AGREEMENT (this "<strong>Agreement</strong>"), dated as of <mark>[2/27/2023]</mark> (the
          "<strong>Effective Date</strong>") is between DataScore Enterprises, Inc. (the "<strong>Company</strong>"),
          and <mark>[US Health Advisors]</mark> ("<strong>Client</strong>").
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>WHEREAS</strong>, the Company is engaged in the business of providing data analytics services related
          to marketing and customer sales; and
        </p>
        <p>
          <strong>WHEREAS</strong>, Client is in a business that includes marketing and customer sales; and
        </p>
        <p>
          <strong>WHEREAS</strong>, Client desires to engage the services of the Company pursuant to the terms and
          conditions of this Agreement.
        </p>
        <p>
          <strong>NOW THEREFORE</strong>, in consideration of the mutual promises, covenants, and agreements contained
          herein, the parties hereby agree as follows:
        </p>
        <ol>
          <li>
            <h3>Contracted Services</h3>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          This Agreement shall apply to the delivery of data analytics services, and other functions as further
          described in one or more Statements of Work (each, a "<strong>SOW</strong>") that may be proposed and approved
          by the parties. Each SOW shall be in substantially the form set forth in Exhibit A. Any such approved SOW
          shall be incorporated herein by reference (the services and functions described in any SOW are hereafter
          referred to as the "<strong>Services</strong>"). In the event the scope of the Services is expanded, revised,
          or modified, for any SOW incorporated herein, the parties shall prepare and sign an amended or new SOW (or
          change order), which likewise shall be attached hereto and incorporated herein by reference. Absent the
          execution of an SOW, this Agreement does not, in and of itself, represent a commitment by Client to receive
          any Services from the Company or pay the Company any fees, or a commitment by the Company to provide any
          Services to Client. To be effective, a SOW must be signed by both parties. Unless otherwise set forth in a
          SOW, the Services shall include access to Company&rsquo;s online reporting dashboard ("Dashboard").
        </p>
        <ol start={2}>
          <li>
            <h3>Term of Agreement </h3>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          (a) The term of this Agreement will commence on the Effective Date and will continue for a period of [
          <mark>1</mark> month] (the "<strong>Initial Term</strong>"). This Agreement will automatically renew for
          successive [<mark>1</mark> month] terms (each, a "<strong>Subsequent Term</strong>") unless terminated in
          writing by either party as provided below. The Initial Term and the Subsequent Terms are referred to herein as
          the "<strong>Term</strong>").
        </p>
        <p>
          <br />
        </p>
        <p>
          (b) Either party shall have the option to terminate this Agreement, without cause, by providing the other
          party thirty (30) days&rsquo; written notice of its intention to terminate this Agreement without cause. In
          the event a SOW provides for a different termination notice period, the SOW termination clause will control
          for that specific SOW only.
        </p>
        <p>
          <br />
        </p>
        <ol start={3}>
          <li>
            <h3>Fees and Payment Terms</h3>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          In consideration for the Services performed by the Company, as set forth in any SOW, Client agrees to
          compensate the Company at the applicable rates set forth in each SOW. Such rates are inclusive of any federal,
          state, or local sales or use taxes, and any other taxes or fees assessed on, or in connection with, any of the
          Services. Client will pay all undisputed invoices within thirty (30) days of receipt thereof.
        </p>
        <p>
          <br />
        </p>
        <ol start={4}>
          <li>
            <h3>Change Orders; Out of Scope Services</h3>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          To the extent Client requires or requests additional services or services that exceed the scope of the
          Services set forth in any SOW incorporated herein, such additional or out of scope services shall be set forth
          in a change order ("<strong>Change Order</strong>"). Once a Change Order is signed by both parties, it will be
          incorporated into this Agreement and have the same legal effect as the SOW that is incorporated into this
          Agreement.
        </p>
        <p>
          <br />
        </p>
        <ol start={5}>
          <li>
            <h3>Ownership of Client Work Product; Data</h3>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          (a) Client shall own all right, title and interest in and to all Client Work Product (as defined below) and
          the Intellectual Property contained therein. Company shall own all right, title and interest in and to the
          Services (other than the Client Work Product) and the Intellectual Property contained therein, including any
          and all improvements and modifications to the Services made at any time. "Client Work Product" means the
          content contained in written (including electronic) reports generated in connection with the Services and
          provided or made available to Client by Company. For clarity, Client Work Product does not include Client
          Data. "Intellectual Property" means all intellectual property and proprietary rights, including without
          limitation, trade secrets, patents, trademarks, and copyrights.
        </p>
        <p>
          <br />
        </p>
        <p>
          (b) All Client data, information or materials provided or submitted by Client to Company in the course of
          utilizing the Services (the "Client Data") shall be the exclusive property of Client and shall be considered
          Confidential Information of Client. Following termination or expiration of this Agreement, Company will return
          to Client all Client Data. Client will have sole responsibility for the accuracy, quality, integrity,
          legality, reliability, appropriateness of and copyright permissions for all Client Data. Company may use such
          Client Data during the term of this Agreement and solely for the purpose of delivering the Services. Client
          represents, warrants and covenants that it will provide notices and obtain consents from its customers
          relating to the collection, use, processing, transfer, storage and disclosure of Client Data, as required by
          applicable laws. If Client authorizes Company to access data through third party providers, Client and Company
          agree that Company is acting as a limited agent of Client in gathering such data, which shall be deemed Client
          Data, and which Company shall treat as Client Data, including returning such data to Client upon the
          expiration or termination of this Agreement. Notwithstanding the foregoing, Company may use Client Data in
          aggregate and anonymized form, which will not include information that identifies or would reasonably be
          expected to identify Client, to provide services and/or to develop and improve features of Company services,
          and Client will have no ownership interests in such aggregate/anonymized data.
        </p>
        <p>
          <br />
        </p>
        <p>
          (c) Company reserves all rights not expressly granted to Client in this Agreement. Except for the limited
          rights and licenses expressly granted under this Agreement, nothing in this Agreement grants, by implication,
          waiver, estoppel, or otherwise, to Client or any third party any intellectual property rights or other right,
          title, or interest in or to the Company Intellectual Property.
        </p>
        <p>
          <br />
        </p>
        <p>
          (d) If Company grants access to Client to Company&rsquo;s Dashboard (as defined in an SOW), the following
          terms apply. Company hereby grants Client a non-exclusive, non-transferable right to access and use the
          Dashboard, solely for Client&rsquo;s internal use in accordance with and for the purpose of the terms and
          conditions of this Agreement. Client shall be responsible for the use of the Dashboard by any user Client
          allows to access the Dashboard, including any such user&rsquo;s violation of any term of this Agreement. In
          connection with the Dashboard, Client shall not directly or indirectly, in whole or part: (i) copy, modify, or
          create derivative works; (ii) rent, lease, lend, sell, license, sublicense, assign, distribute, publish,
          transfer, or otherwise make it available; (iii) reverse engineer, disassemble, decompile, decode, adapt, or
          otherwise attempt to derive or gain access to any software component; (iv) remove any proprietary notices; (v)
          use the Dashboard in any manner or for any purpose that infringes, misappropriates, or otherwise violates any
          intellectual property or other right of any person, or that violates any applicable law; or (vi) use the
          Dashboard for any purpose competitive to Company, including development of a competing product or
          benchmarking. Company may, with or without prior notice to Client, immediately suspend Client&rsquo;s access
          and use of the Dashboard if and when (a) Client breaches any of Client&rsquo;s responsibilities under this
          Agreement, (b) Client uses or attempts to use the Dashboard in any manner that does not comply with this
          Agreement, or (c) Company believes suspicious activity has occurred in connection with Client&rsquo;s account.
        </p>
        <p>
          <br />
        </p>
        <ol start={6}>
          <li>
            <h3>Independent Contractor</h3>
          </li>
        </ol>

        <p>
          The parties enter into this Agreement as independent contractors and nothing within this Agreement shall be
          construed to create a joint venture, partnership, agency, or other employment relationship between the
          parties. All Company employees who are assigned to perform Services at any Client owned or leased facility
          shall be considered to be an employee of the Company only and will not be considered an agent or employee of
          Client for any purpose. The Company will be solely responsible for payment of all compensation owed to its
          employees, including all applicable federal, state and local employment taxes and will make deductions for all
          taxes and withholdings required by law.
        </p>
        <p>
          <br />
        </p>
        <ol start={7}>
          <li>
            <h3>Confidentiality</h3>
          </li>
        </ol>

        <p>
          (a) Each party agrees to hold in strictest confidence and not to disclose, make any use of, except for the
          benefit of the other party, lecture upon or publish, any of the Confidential Information (as defined below) of
          the other party (the "<strong>Disclosing Party</strong>") which may be disclosed to such party (the "
          <strong>Receiving Party</strong>") during the course of the Company&rsquo;s performance of the Services
          hereunder, except as the Disclosing Party may otherwise consent to in writing in its sole and absolute
          discretion, and each party further agrees that it shall implement reasonable security controls to protect the
          Disclosing Party&rsquo;s Confidential Information from unauthorized access, disclosure, or use. The Receiving
          Party further agrees not to deliver, reproduce or in any way allow such Confidential Information, or any
          documentation relating to such Confidential Information, to be delivered or used by any third parties without
          the specific written direction or consent of a duly authorized representative of the Disclosing Party.
        </p>
        <p>
          (b) The term "<strong>Confidential Information</strong>" shall mean any and all trade secrets, confidential
          knowledge, data or any other proprietary information pertaining to any business of the Disclosing Party or any
          of its clients, customers or consultants, licensees or affiliates. By way of illustration but not limitation,
          "Confidential Information" includes (i) inventions, ideas, improvements, discoveries, trade secrets,
          processes, data, programs, source code, web site designs, knowledge, know-how, designs, techniques, formulas,
          test data, computer code, security procedures and processes, passwords, user IDs, customer information,
          affiliate information, customer lists, affiliate lists, other works of authorship and designs whether or not
          patentable, copyrightable, or otherwise protected by law; (ii) information regarding research, development,
          new products and services, marketing plans and strategies, merchandising and selling, business plans,
          strategies, forecasts, projections, profits, investments, operations, financings, records, budgets and
          financial statements, licenses, prices and costs, suppliers and customers; and (iii) practices and methods of
          doing business of specific parties with whom the Disclosing Party transacts business, and information
          regarding the skills and compensation of other employees of the Disclosing Party and independent contractors
          performing services for the Disclosing Party.
        </p>
        <p>
          <br />
        </p>
        <p>
          (c) Confidential Information does not include: (i) information which is or becomes in the public domain
          through no fault or act of the Receiving Party; (ii) information which was independently developed by the
          Receiving Party without the use of or reliance on the Disclosing Party&rsquo;s Confidential Information (as
          shown by the Receiving Party&rsquo;s files and records); (iii) information which was provided to the Receiving
          Party by a third party under no duty of confidentiality to the Disclosing Party; or (iv) information which is
          required to be disclosed by law or court order, provided, however, prompt prior notice thereof shall be given
          to the Disclosing Party so that it may seek a protective order or other appropriate remedy.
        </p>
        <p>
          <br />
        </p>
        <p>
          (d) The parties agree that the unauthorized disclosure of any of the foregoing Confidential Information by
          either party may cause irreparable injury to the Disclosing Party, inadequately compensable in monetary
          damages. Accordingly, in addition to all other remedies available at law, the Disclosing Party may seek to
          obtain an injunction or other equitable relief against the breach or threatened breach of this Section 7 by
          the Receiving Party, without the posting of bond, if permitted by law.
        </p>
        <p>
          <br />
        </p>
        <ol start={8}>
          <li>
            <h3>Company Representations and Warranties</h3>
          </li>
        </ol>

        <p>
          <br />
        </p>
        <p>The Company represents and warrants to Client as follows:</p>
        <p>
          <br />
        </p>
        <p>
          (a) The entering into and performance of this Agreement by the Company does not and will not: (i) violate or
          conflict with any other contract or agreement to which the Company is a party; or (ii) violate any applicable
          law or government regulation.
        </p>
        <p>
          <br />
        </p>
        <p>
          (b) The Company is duly organized, validly existing, has full and adequate power to own its property and
          conduct its business as now conducted, is in good standing, and is duly licensed.
        </p>
        <p>
          <br />
        </p>
        <p>
          (c) EXCEPT AS EXPRESSLY PROVIDED HEREIN AND IN ANY APPLICABLE SOW, ALL SERVICES ARE PROVIDED "AS IS," AND THE
          COMPANY HEREBY DISCLAIMS ALL EXPRESS OR IMPLIED WARRANTIES WITH RESPECT TO THE SERVICES AND CLIENT WORK
          PRODUCT PROVIDED UNDER THIS AGREEMENT, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTIBILITY OR
          FITNESS FOR A PARTICULAR PURPOSE OR USE, OR ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE, AND THE
          COMPANY MAKES NO OTHER REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS, IMPLIED, OR STATUTORY.
        </p>
        <p>
          <br />
        </p>
        <ol start={9}>
          <li>
            <h3>Client Representations and Warranties</h3>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>Client represents and warrants to the Company as follows:</p>
        <p>
          <br />
        </p>
        <p>
          (a) The entering into and performance of this Agreement by the Client does not and will not: (i) violate or
          conflict with any other contract or agreement to which the Company is a party; or (ii) violate any applicable
          law or government regulation.
        </p>
        <p>
          <br />
        </p>
        <p>
          (b) Client is duly organized, validly existing, has full and adequate power to own its property and conduct
          its business as now conducted, is in good standing, and is duly licensed.
        </p>
        <p>
          <br />
        </p>
        <ol start={10}>
          <li>
            <h3>Indemnification; Insurance; Limitation of Liability</h3>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          (a) Company General Indemnification. The Company shall defend, hold harmless, and indemnify the Client, and
          its affiliates and their respective directors, officers, employees, agents, and assigns ("
          <strong>Client Indemnified Parties</strong>"), from and against any and all claims, suits, actions, demands,
          and proceedings filed against the Client or any Client Indemnified Party by any third party (collectively "
          <strong>Claims</strong>"), and any damages, losses, expenses, liabilities or costs of any kind, (including but
          not limited to reasonable attorneys&rsquo; fees, witness fees and court costs) reasonably incurred in
          connection with such Claims, which are finally awarded, or otherwise determined by settlement or other dispute
          resolution action or process, and which are directly relating to: (a) bodily injury or death to any person
          (and including specifically bodily injury or death to any employee of Company or its agent) caused by the
          negligence or willful misconduct of Company or anyone acting on behalf of Company or arising out of or in any
          way related to the performance of the Services; (b) loss, disappearance, or damage to tangible property of any
          person caused by the negligence or willful misconduct Company or anyone acting on behalf of Company; (c) any
          claim with respect to Company&rsquo;s breach of any obligation in Section 7 (Confidentiality); and (d) any
          violation of applicable law by Company in the provision of Services under this Agreement.
        </p>
        <p>
          <br />
        </p>
        <p>
          (b) Client Indemnification. Client shall defend, hold harmless, and indemnify the Company, its affiliates, and
          their respective directors, officers, employees, agents, and assigns ("
          <strong>Company Indemnified Parties</strong>"), from and against any and all claims, suits, actions, demands,
          and proceedings filed against the Company or any Company Indemnified Party by any third party (collectively "
          <strong>Claims</strong>"), and any damages, losses, expenses, liabilities or costs of any kind, (including but
          not limited to reasonable attorneys&rsquo; fees, witness fees and court costs) reasonably incurred in
          connection with such Claims, which are finally awarded, or otherwise determined by settlement or other dispute
          resolution action or process, and which are directly relating to: (a) bodily injury or death to any person
          (and including specifically bodily injury or death to any employee of Client or its agent) caused by the
          negligence or willful misconduct of Client or anyone acting on behalf of Client or arising out of or in any
          way related to the performance of the Services; (b) loss, disappearance, or damage to tangible property of any
          person caused by the negligence or willful misconduct of Client or anyone acting on behalf of Client; (c) any
          claim with respect to Client&rsquo;s breach of any obligation in Section 7 (Confidentiality; (d)
          Company&rsquo;s use of Client Data in accordance with this Agreement; and (e) any violation of applicable law
          by Client.
        </p>
        <p>
          <br />
        </p>
        <p>
          (c) Third Party Intellectual Property Claim. In the event that the Services or Client Work Product or any part
          thereof becomes the subject of a claim that the Services or Client Work Product are infringing or
          misappropriating the intellectual property rights of any third-party and that would prevent or limit
          Client&rsquo;s use of the Services or Client Work Product, the Company shall, at its discretion and sole
          expense: (i) procure for Client the right to continue to use the Services or Client Work Product as
          contemplated by this Agreement; (ii) modify the Services or Client Work Product, or portion thereof that is
          alleged to be infringing, so that they no longer infringe, wrongfully use or misappropriate the
          third-party&rsquo;s intellectual property rights; or (iii) replace or modify the applicable Services or Client
          Work Product, or portion thereof, that are alleged to be infringing, with a replacement that is non-infringing
          and non-misappropriating, provided that the replacement or modified Services or Client Work Product are not
          less than equivalent in performance and all other material characteristics to the Services or Client Work
          Product being replaced, and are otherwise reasonably satisfactory to Client. If the Company reasonably
          determines that none of (i), (ii) or (iii) are available, the Company shall give written notice to Client of
          such determination, and in no case later than thirty (30) days after Company makes the above determination,
          refund to Client the amounts paid to the Company by Client under this Agreement for the infringing Client Work
          Product or Service, less an amount for Client&rsquo;s use of the Service or Client Work Product up to the time
          of return, being an amortization of the amounts paid over a 12 month period. Upon receipt of such notice,
          Client: (a) shall immediately cease any further use of the Services or Client Work Product; and (b) has the
          right, in its sole discretion, to immediately terminate this Agreement.
        </p>
        <p>
          <br />
        </p>
        <p>
          (d) Intellectual Property Indemnification. The Company shall defend, indemnify and hold harmless Client
          Indemnified Parties against any injury, damage, liability, loss, penalty, interest and expense incurred
          (including reasonable attorneys&rsquo; fees) arising out of Claims resulting from any infringement or alleged
          infringement of any patent issued under the laws of the United States and Canada on the date of delivery of
          the Services or a copyright or trade secret on the Services or Client Work Product, or any portion thereof,
          provided pursuant to the terms of this Agreement. Provided, however, that such indemnification will not apply
          to any claims that arise out of or result from any infringement claim made by any third party based upon the
          Services or Client Work Product or any component or portion thereof: (a) that is not developed by the Company,
          or developed other than with the Company&rsquo;s knowledge or authorization; (b) that is made in whole or in
          part in accordance with Client&rsquo;s designs, specifications or instructions, other than by the Company or
          with the Company&rsquo;s knowledge or authorization; (c) that is modified after shipment, if the alleged
          infringement relates to such modification, and such modification is not made by the Company or is made without
          the Company&rsquo;s knowledge or authorization; (d) that is combined with other products, processes or
          materials of Client or any third party where the alleged infringement relates to such combination, other than
          a combination by the Company, or with the Company&rsquo;s knowledge or authorization; or (e) to the extent
          Client continues allegedly infringing activity after being notified thereof or fails to implement a more
          current release made available to Client that would prevent or avoid the alleged infringement without
          significant loss of performance or functionality. The foregoing remedies in this Section 10(d) constitute
          Client&rsquo;s sole and exclusive remedies, and Company&rsquo;s entire liability, with respect to infringement
          claims by third parties.
        </p>
        <p> </p>
        <p>
          (e) Indemnification Procedure. As a condition precedent to the indemnification obligations set forth above,
          the indemnified party shall, upon learning of any Claims subject to the obligations set forth in this Section
          10: (i) notify the other party promptly in writing upon knowledge of any claim for which it may be entitled to
          indemnification under this Agreement; (ii) permit indemnifying party to have the sole right to control the
          defense and settlement of any such lawsuit, provided the indemnifying party shall not, in such event, engage
          in the defense or settlement of any such Claim(s) without the indemnified party&rsquo;s prior written consent;
          and (iii) provide reasonable assistance to the indemnifying party, at indemnifying party&rsquo;s expense, in
          the defense of such lawsuit.
        </p>
        <p>
          <br />
        </p>
        <p>
          (f) IN NO EVENT WILL: (A) EITHER PARTY OR ITS AFFILIATES BE LIABLE TO THE OTHER PARTY OR ITS AFFILIATES FOR
          ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, LOSSES OR EXPENSES (INCLUDING, BUT NOT
          LIMITED TO, BUSINESS INTERRUPTION, LOST BUSINESS, LOST PROFITS OR LOST SAVINGS) OF ANY NATURE ARISING OUT OF
          OR RELATED TO THIS AGREEMENT, EVEN IF SUCH PARTY WILL HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES,
          AND REGARDLESS OF THE NEGLIGENCE OR OTHER FAULT OF EITHER PARTY AND REGARDLESS OF WHETHER SUCH LIABILITY
          ARISES IN CONTRACT, NEGLIGENCE, TORT, STRICT LIABILITY OR ANY OTHER THEORY OF LIABILITY; AND (B) THE TOTAL
          LIABILITY ARISING OUT OF OR RELATING TO THIS AGREEMENT, OTHER THAN FEES DUE FOR SERVICES RENDERED BUT
          INCLUDING WITHOUT LIMITATION ANY PERFORMANCE OR NON-PERFORMANCE HEREUNDER, REGARDLESS OF THE FORM OF THE CAUSE
          OF ACTION, WHETHER IN CONTRACT, TORT, STATUTE, OR OTHERWISE, IN NO EVENT SHALL EXCEED THE TOTAL FEES PAID BY
          CLIENT TO COMPANY DURING THE TWELVE MONTHS PRECEDING THE EVENT GIVING RISE TO A CLAIM.
        </p>
        <p>
          <br />
        </p>
        <ol start={11}>
          <li>
            <h3>Termination</h3>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          If either party believes that the other party has failed in any material respect to perform its obligations
          under this Agreement (including any SOW issued hereunder), then that party may provide written notice to the
          other party describing the alleged failure in reasonable detail. If the breaching party does not cure the
          material breach within thirty (30) calendar days after receipt of such notice, then the non-breaching party
          may terminate this Agreement for cause by providing written notice to the breaching party. No expiration or
          termination will affect Client&apos;s obligation to pay all Fees that may have become due before such
          expiration or termination.
        </p>
        <p>
          <br />
        </p>
        <ol start={12}>
          <li>
            <h3>Miscellaneous</h3>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          (a) Non-Restrictive Relationship. The Company may provide the same or similar services to other customers.
        </p>
        <p>
          <br />
        </p>
        <p>
          (b) Notices. All notices, requests, demands, claims and other communications to either party hereunder shall
          be in writing and shall be given to the address set forth beneath such party&rsquo;s signature on the
          signature pages hereto. Any notice, request, demand, claim or other communication hereunder shall be in
          writing and shall be deemed to have been duly given when received if personally delivered; 24 hours after
          transmission, if transmitted by email or facsimile (with confirmation of receipt),; the date after it is sent,
          if sent for next day delivery to a domestic address by recognized overnight delivery service (e.g., Federal
          Express); and upon receipt, if sent by certified or registered mail, return receipt requested.
        </p>
        <p>
          <br />
        </p>
        <p>
          (c) Further Assurances. In case at any time after the Effective Date any further action is necessary or
          desirable to carry out the purposes of this Agreement, each of the parties will take such further action
          (including the execution and delivery of such further instruments and documents) as the other party may
          reasonably request.
        </p>
        <p>
          <br />
        </p>
        <p>
          (d) Amendments and Waivers. Any provision of this Agreement may be amended or waived if, but only if, such
          amendment or waiver is in writing and is signed, in the case of an amendment, by each of the parties, or in
          the case of a waiver, by the party against whom the waiver is to be effective. No failure or delay by either
          party in exercising any right, power or privilege hereunder shall operate as a waiver thereof, nor shall any
          single or partial exercise thereof preclude any other or further exercise thereof or the exercise of any other
          right, power or privilege. The rights and remedies herein provided shall be cumulative and are not exclusive
          of any rights or remedies provided by law.
        </p>
        <p>
          <br />
        </p>
        <p>
          (e) Force Majeure. The parties shall be relieved of their obligations hereunder (except for the payment of
          money) if and to the extent that any of the following events hinder, limit or make commercially impracticable
          the performance by either party of any of its obligations hereunder: act of God, war, pandemic or epidemic,
          civil commotion, riot, acts of public enemies, blockade or embargo, fire, explosion, casualty, accident,
          flood, sabotage, national defense requirements, labor trouble, strike, lockout or injunction; governmental
          requests, laws, regulations, orders or actions, whether valid or invalid (including, without limitation,
          import or export prohibitions or priorities, requisitions, allocations and price adjustment restrictions);
          inability to obtain power, materials, facilities, equipment, communication or transportation; or any other
          event, whether or not of the class or kind enumerated herein, beyond the control of the party claiming relief
          under this Section 12(e) such as cannot be circumvented by reasonable diligence and without unusual expense.
          The party claiming relief under this Section 12(e) shall notify the other party in writing of the events
          causing delay or default in performance. The party failing to fulfill its obligations shall, however, take
          reasonable steps to remove or otherwise address the impediment to action.
        </p>
        <p>
          <br />
        </p>
        <p>
          (f) Successors and Assigns. The provisions of this Agreement shall be binding upon and inure to the benefit of
          the parties and their respective successors and assigns; provided, that no party may assign, delegate or
          otherwise transfer any of its rights or obligations under this Agreement without the prior written consent of
          the other party, which consent shall not be unreasonably withheld or delayed. Any attempt by a party to
          transfer its rights, duties, or obligations under this Agreement except as expressly provided in this
          Agreement is void.
        </p>
        <p>
          <br />
        </p>
        <p>
          (g) Governing Law; Venue. This Agreement shall be governed by and construed in accordance with the laws of the
          State Washington, without regard to any conflicts of law provisions thereof. The parties hereby agree that the
          exclusive venue and jurisdiction for any disputes they may bring shall be in the federal and state courts
          located in the state of Washington, King County. This choice of jurisdiction and venue does not prevent the
          Company from seeking injunctive or provisional relief with respect to a violation of intellectual property
          rights or enforcement or recognition of any award or order in any appropriate jurisdiction.
        </p>
        <p>
          <br />
        </p>
        <p>
          (h) Counterparts. This Agreement may be executed in counterparts, including by email of a PDF document,
          facsimile, or other electronic transmission, each of which will be deemed an original, but all of which
          together will constitute one and the same agreement.
        </p>
        <p>
          <br />
        </p>
        <p>
          (i) Entire Agreement. This Agreement, including any attachments, embodies and constitutes all prior agreements
          and understandings, both oral and written, between the parties with respect to the subject matter of this
          Agreement. In the event of any direct conflict or inconsistency between the provisions of this Agreement and
          any SOW, the SOW shall control only to the extent that the applicable provisions in the SOW expressly
          supersede the applicable provisions of this Agreement and identify the section or sections of this Agreement
          that they are intended to supersede. No representation, inducement, promise, understanding, condition or
          warranty not set forth herein has been made or relied upon by either party in entering into this Agreement.
          Neither this Agreement nor any provision hereof is intended to confer upon any person other than the parties
          any rights or remedies hereunder.
        </p>
        <p>
          <br />
        </p>
        <p>
          (j) Severability. If any provision hereof is or becomes illegal, invalid or unenforceable under the laws of a
          particular jurisdiction, such provision shall be fully severable with respect to such laws; this Agreement
          shall be construed and enforced in such jurisdiction as if such provision had never comprised a part hereof;
          the remaining provisions hereof shall remain in full force and effect in such jurisdiction and shall not be
          affected by such provision or by its severance herefrom; and all of the provisions hereof shall remain in full
          force and effect in all other jurisdictions and shall not be affected by the severance of such provision under
          the laws of such jurisdiction. Furthermore, in lieu of such provision, there shall be added automatically for
          purposes of such jurisdiction as part of this Agreement a provision as similar in terms to such illegal,
          invalid or unenforceable provision as may be possible and be legal, valid and enforceable in such
          jurisdiction.
        </p>
        <p>
          <br />
        </p>
        <p>
          (k) Survival. All provisions which are intended to survive termination or expiration of this Agreement, shall
          so survive, including Sections 3, 5 through 12.
        </p>
        <p>
          <br />
        </p>
        <p>
          (l) Publicity. Company may disclose Client as a customer of Company and use Client&rsquo;s trademarks, name
          and logo on Company&rsquo;s web site and promotional materials, provided that Company will not alter or modify
          the Company logo (except as to size) and any goodwill attaching to Client&rsquo;s trademarks will belong to
          Client.
        </p>
        <p>
          <br />
        </p>
        <p>
          The Parties agree that no chargebacks are allowed for any reason. Chargebacks refer to the reversal of a
          payment made by one Party to the other through a credit card issuer or other financial institution. If there
          is a dispute regarding payment or performance under the Agreement, the Parties agree to resolve such dispute
          through arbitration or litigation. The prevailing Party in any such dispute is entitled to recover its
          reasonable attorneys&apos; fees and costs. This provision applies even after the Agreement is terminated or
          expired. This is the whole Agreement and there were no other agreements before this one.
        </p>
      </div>
    </Center>
  </Container>
);

export default Tos;
