import { Header, Label } from 'semantic-ui-react';

import { Row } from 'src/styles';
import { Account, AccountBillingConfig } from 'src/types';
import AccountBillingAddFunds from './AccountBillingAddFunds';

type Props = {
  account: Account;
  billingConfig: AccountBillingConfig;
  currentBalance: number;
};

const CurrentBalance = ({ account: a, billingConfig, currentBalance }: Props) => {
  return (
    <>
      <Row style={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
        <Header as="h4" style={{ marginBottom: 0 }}>
          Current Balance
        </Header>
        <Label color={currentBalance <= 0 ? 'red' : 'green'} size="big" style={{ marginRight: '0.25em' }}>
          {currentBalance.toFixed(2)}
        </Label>
      </Row>

      <AccountBillingAddFunds account={a} billingConfig={billingConfig} />
    </>
  );
};

export default CurrentBalance;
