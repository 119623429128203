import { Grid, Placeholder, Segment, Statistic } from 'semantic-ui-react';

type Props = {
  label: string;
  value: any;
  loading?: boolean;
};

const Stat = ({ label, loading = true, value }: Props) => (
  <Grid.Column>
    <Segment textAlign="center">
      <Statistic>
        <Statistic.Value>
          {loading ? (
            <Placeholder>
              <Placeholder.Paragraph>
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          ) : (
            value
          )}
        </Statistic.Value>
        <Statistic.Label>{label}</Statistic.Label>
      </Statistic>
    </Segment>
  </Grid.Column>
);

export default Stat;
