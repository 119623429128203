import { useEffect } from 'react';
import { Label, Table } from 'semantic-ui-react';

import { useListModelsQuery } from 'src/api/admin/models';
import { RenderProps } from 'src/components/PaginatedTable';
import { ModelSource, ModelStage, ModelType } from 'src/types';
import { getModelVersionColor } from 'src/utils';

const AdminModelsListBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: models } = useListModelsQuery({ limit, offset: limit * (currentPage - 1), enabled: 1 });
  // const history = useHistory();

  useEffect(() => {
    let count = 1;
    if (typeof models !== 'undefined') {
      count = Math.ceil(models.total / limit);
    }
    setPageCount(count);
  }, [models, limit, setPageCount]);

  return models?.data.map(model => (
    <Table.Row key={model.id}>
      <Table.Cell collapsing>
        <Label color={!model.enabled ? 'red' : undefined}>{model.enabled ? 'Visible' : 'Hidden'}</Label>
      </Table.Cell>
      <Table.Cell>{model.display_name}</Table.Cell>
      <Table.Cell>{ModelSource[model.source]}</Table.Cell>
      <Table.Cell>{ModelType[model.type]}</Table.Cell>
      <Table.Cell>{model.name}</Table.Cell>
      <Table.Cell>
        {model.versions?.map(v => (
          <Label key={`${model.id}-${v.version}`} color={getModelVersionColor(v)}>
            {v.version}
            <Label.Detail>{ModelStage[v.stage]}</Label.Detail>
          </Label>
        ))}
      </Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        {/* <Button icon="pencil" color="blue" onClick={() => history.push(`/admin/models/${model.id}`)} /> */}
      </Table.Cell>
    </Table.Row>
  ));
};

export default AdminModelsListBody;
