import { useCallback, useMemo } from 'react';
import { Bar, BarChart, Brush, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import theme from 'src/styles/theme';
import { ThresholdCounts, ThresholdRange } from 'src/types';

type Props = {
  thresholdCounts: ThresholdCounts;
  range: ThresholdRange;
  setRange: (e: ThresholdRange) => void;
};

const ThresholdCountsChart = ({ thresholdCounts, range, setRange }: Props) => {
  const data = useMemo(() => {
    if (typeof thresholdCounts === 'undefined') {
      return [];
    }

    const counts = Object.entries(thresholdCounts).map(([threshold, count]) => ({
      threshold,
      count,
    }));

    return counts;
  }, [thresholdCounts]);

  const onBrushChange = useCallback(
    ({ startIndex, endIndex }) => {
      let total = 0;
      for (let i = startIndex; i <= endIndex; i++) {
        total += data[i].count;
      }
      const start = Number(data[startIndex].threshold);
      const end = Math.min(Number(data[endIndex].threshold) + 0.1, 1);

      if (start === range.start && end === range.end) return;

      setRange({ start, end, total });
    },
    [data, range, setRange]
  );

  return (
    <ResponsiveContainer>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="threshold" />
        <YAxis />
        <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
        <Tooltip cursor={{ fill: 'rgba(0,0,0,0.1)' }} />
        <Bar dataKey="count" fill={theme.primary} />
        <Brush dataKey="threshold" stroke={theme.primary} onChange={onBrushChange} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ThresholdCountsChart;
