import { Divider, Grid, Header, Message, Segment } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';

const betaUrl = process.env.REACT_APP_BETA_URL;

type Props = {
  token: string;
};

const BillingDocs = ({ token }: Props) => {
  const { data: user } = useGetUserProfileQuery();

  const rate = user?.active_account.billing.connectPro.rate || 0.3;

  // const scrollToID = useCallback(
  //   (id: string) => (e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>) => {
  //     e.preventDefault();

  //     const el = document.querySelector(`#${id}`);
  //     if (el !== null && el instanceof HTMLElement) {
  //       const offset = 12;

  //       let offsetTop = window.scrollY + el.getBoundingClientRect().top;
  //       if (offsetTop >= offset) {
  //         offsetTop -= offset;
  //       }

  //       const adminHeader = document.querySelector('header#admin-header');
  //       if (adminHeader) {
  //         offsetTop -= adminHeader.getBoundingClientRect().height;
  //       }

  //       const header = document.querySelector('header#header');
  //       if (header) {
  //         offsetTop -= header.getBoundingClientRect().height;
  //       }

  //       window.scrollTo({
  //         left: 0,
  //         top: offsetTop,
  //         behavior: 'smooth',
  //       });
  //     }
  //   },
  //   []
  // );

  const startDate = '2024-01-01';
  const endDate = '2024-01-02';
  const connects = 1234;
  const cost = (connects * rate).toFixed(2);
  const voiceConfigId = '00000000-0000-0000-0000-000000000000';

  return (
    <Grid style={{ marginTop: 0 }}>
      <Grid.Row>
        <Grid.Column>
          {/* <Segment id="table-of-contents">
            <Header as="h3">Table of Contents</Header>

            <List bulleted>
              <List.Item>
                <button className="anchor" onClick={scrollToID('get-history')}>
                  Get History
                </button>
              </List.Item>
            </List>
          </Segment> */}

          <Segment id="get-history">
            <Header as="h3">Get History</Header>

            <p>
              This endpoint will return a list of the account's billing history with an entry for each day between the
              given <code>start</code> and <code>end</code> dates.{' '}
              <strong>Both the start and end date parameters are required.</strong>
            </p>

            <Message>
              <pre>
                GET {`${betaUrl}/api/billing/history?start=${startDate}&end=${endDate}`}
                <br />
                Authorization: Bearer {token}
              </pre>
            </Message>

            <p>
              If you are unable to send the api key in the <code>Authorization</code> Header, you also have the option
              to pass it in the URL via the <code>token</code> query string parameter instead.
            </p>

            <Message>
              <pre>GET {`${betaUrl}/api/billing/history?token=${token}&start=${startDate}&end=${endDate}`}</pre>
            </Message>

            <p>
              By default, the data will be returned as JSON. If you would prefer to download a CSV, you can pass the{' '}
              <code>format</code> query string parameter with a value of <code>csv</code>.
            </p>

            <Message>
              <pre>
                GET {`${betaUrl}/api/billing/history?token=${token}&start=${startDate}&end=${endDate}&format=csv`}
              </pre>
            </Message>

            <p>
              By default, the data is returned in the format per date. We have the option to get the response per date +
              voice_config_id. For that you have to pass the <code>itemized</code> query string parameter with a value{' '}
              <code>1</code>. In the response you will see the <code>voiceConfigId</code> with a format similar to{' '}
              <code>{voiceConfigId}</code>. The same case for the csv, you will see the second column{' '}
              <code>voice_config_id</code>.
            </p>

            <Message>
              <pre>
                GET{' '}
                {`${betaUrl}/api/billing/history?token=${token}&start=${startDate}&end=${endDate}&format=csv&itemized=1`}
              </pre>
            </Message>

            <Divider />

            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h3">Success response</Header>

                  <Message success>
                    <pre>
                      Status Code: 200 OK
                      <br />
                      Content-type: application/json
                      <br />
                      <br />
                      {`{\n  "success": true,\n  "history": [\n    {\n      "connectProUsage": ${connects},\n      "connectProUsageCost": ${cost},\n      "connectProUsageType": "connect",\n      "date": "${startDate}",\n    },\n  ],\n  "input": {\n    "start": "${startDate}",\n    "end": "${endDate}",\n    "format": "json"\n  }\n}`}
                    </pre>
                  </Message>

                  <Message success>
                    <pre>
                      Status Code: 200 OK
                      <br />
                      Content-type: text/csv
                      <br />
                      <br />
                      {`date,connect_pro_usage,connect_pro_usage_type,connect_pro_usage_cost\n${startDate},${connects},connect,${cost}`}
                    </pre>
                  </Message>
                </Grid.Column>

                <Grid.Column>
                  <Header as="h3">Error responses</Header>

                  <Message error>
                    <pre>
                      Status Code: 400 Bad Request
                      <br />
                      Content-type: application/json
                      <br />
                      <br />
                      {`{\n  "success": false,\n  "message": "invalid start time: unable to parse time: foobar"\n}`}
                    </pre>
                  </Message>

                  <Message error>
                    <pre>
                      Status Code: 403 Forbidden
                      <br />
                      Content-type: application/json
                      <br />
                      <br />
                      {`{\n  "success": false,\n  "message": "Forbidden"\n}`}
                    </pre>
                  </Message>

                  <Message error>
                    <pre>
                      Status Code: 422 Unprocessable Content
                      <br />
                      Content-type: application/json
                      <br />
                      <br />
                      {`{\n  "success": false,\n  "errors": [\n    {\n      "field": "End",\n      "error": "End is required"\n    }\n  ],\n}`}
                    </pre>
                  </Message>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default BillingDocs;
