import { Button, Icon, Modal, Table } from 'semantic-ui-react';

import { DataflowJob } from 'src/types';

const betaUrl = process.env.REACT_APP_BETA_URL;

type Props = {
  job: DataflowJob;
};

const DataflowJobDetailsModal = ({ job }: Props) => (
  <Modal
    trigger={
      <Button title="View Details" icon>
        <Icon name="eye" />
      </Button>
    }
  >
    <Modal.Header>Job Details</Modal.Header>
    <Modal.Content>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Created At</Table.Cell>
            <Table.Cell>{job.queued}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Dataflow ID</Table.Cell>
            <Table.Cell>{job.payload.dataflow_id}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Revision ID</Table.Cell>
            <Table.Cell>{job.payload.revision_id}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Output(s)</Table.Cell>
            <Table.Cell>
              {job.payload.outputs &&
                job.payload.outputs?.map((filePath, i) => (
                  <a
                    key={i}
                    href={`${betaUrl}/api/dataflows/${job.objectid}/download?job_id=${job.id}&idx=${i}`}
                    style={{ marginRight: '0.5rem 1rem' }}
                  >
                    <Icon name="download" /> {filePath.split('/').pop()}
                  </a>
                ))}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Modal.Content>
  </Modal>
);

export default DataflowJobDetailsModal;
