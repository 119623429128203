import { useState } from 'react';
import { Form, Loader } from 'semantic-ui-react';

import { useUpdateAccountMutation } from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Account } from 'src/types';

type Props = {
  account: Account;
};

const AdminAccountBillingEnabled = ({ account }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [billingEnabled, setBillingEnabled] = useState(account.billing.enabled || false);
  const { isLoading, mutateAsync } = useUpdateAccountMutation();

  const onChange = async (enabled: boolean) => {
    setBillingEnabled(enabled);

    try {
      await mutateAsync({ id: account.id, billing: { ...account.billing, enabled } });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Form>
      <ApiMessage data={apiMessage} />

      <Form.Group>
        <Form.Checkbox
          toggle
          label={billingEnabled ? 'Enabled' : 'Disabled'}
          checked={billingEnabled}
          onChange={(_, { checked }) => onChange(Boolean(checked))}
        />

        <Form.Field style={{ position: 'relative', width: 32 }}>
          <Loader active={isLoading} size="small" />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

export default AdminAccountBillingEnabled;
