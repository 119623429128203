import { useGetUserProfileQuery } from './api/auth';
import AuthRoutes from './AuthRoutes';
import AdminHeader from './components/AdminHeader';
import Header from './components/Header';
import Loading from './components/Loading';
import Menubar from './components/Menubar';
import Support from './components/Support';
import Routes from './Routes';
import { Main } from './styles';

const App = () => {
  const { data: user, isLoading } = useGetUserProfileQuery();

  if (isLoading) {
    return <Loading />;
  }

  if (!user) {
    return <AuthRoutes />;
  }

  return (
    <>
      <AdminHeader />
      <Header />
      <Menubar />
      <Main>
        <Routes />
        <Support />
      </Main>
    </>
  );
};

export default App;
