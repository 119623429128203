import { isArray } from 'lodash';
import { Button, Icon, Label, Modal, Table } from 'semantic-ui-react';

import { JobStatus, PipelineJob } from 'src/types';

const betaUrl = process.env.REACT_APP_BETA_URL;

const PipelineJobDetailsModal = ({ id: jobId, status, queued, payload }: PipelineJob) => {
  return (
    <Modal
      trigger={
        <Button title="View Details" icon>
          <Icon name="eye" />
        </Button>
      }
    >
      <Modal.Header>Job Details</Modal.Header>
      <Modal.Content>
        {status === JobStatus.Error && typeof payload.error !== 'undefined' && payload.error.length > 0 && (
          <Label color="red">
            Error
            <Label.Detail>{payload.error}</Label.Detail>
          </Label>
        )}

        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell style={{ width: 130 }}>Created At</Table.Cell>
              <Table.Cell>{queued}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Pipeline ID</Table.Cell>
              <Table.Cell>{payload.pipeline_id}</Table.Cell>
            </Table.Row>

            {typeof payload.sql !== 'undefined' && (
              <Table.Row>
                <Table.Cell>SQL</Table.Cell>
                <Table.Cell>{payload.sql}</Table.Cell>
              </Table.Row>
            )}

            {typeof payload.params !== 'undefined' && isArray(payload.params) && (
              <Table.Row>
                <Table.Cell>Params</Table.Cell>
                <Table.Cell>
                  {payload.params.map((p, i) => (
                    <Label key={`${i}-${p.Value}`}>{p.Value}</Label>
                  ))}
                </Table.Cell>
              </Table.Row>
            )}

            {typeof payload.raw_gs_path !== 'undefined' && (
              <Table.Row>
                <Table.Cell>Raw Data</Table.Cell>
                <Table.Cell>
                  <a href={`${betaUrl}/api/jobs/${jobId}/download?stage=raw`} style={{ marginRight: '1rem' }}>
                    <Icon name="download" /> {payload.raw_gs_path.slice(payload.raw_gs_path.indexOf('0-raw'))}
                  </a>
                  <Label>
                    {payload.raw_num_rows} <Label.Detail>Rows</Label.Detail>
                  </Label>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  );
};

export default PipelineJobDetailsModal;
