import { formatDistanceToNow } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

import { useCreateAccountMetadataRebuildJobMutation, useGetAccountRecentJobsQuery } from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { JobStatus } from 'src/types';

const AdminAccountMetadataRebuildButton = () => {
  const { id } = useParams<{ id: string }>();
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync, isLoading: mutateLoading } = useCreateAccountMetadataRebuildJobMutation(Number(id));
  const { data: job, isLoading: jobLoading, refetch } = useGetAccountRecentJobsQuery(Number(id));

  const activeJobRunning = job?.status === JobStatus.Processing || job?.status === JobStatus.Pending;

  const loading = mutateLoading || jobLoading || activeJobRunning;

  useEffect(() => {
    let timeout: number | null = null;
    if (activeJobRunning) {
      timeout = setInterval(refetch, 3000);
    }
    return () => {
      if (timeout !== null) {
        clearInterval(timeout);
        timeout = null;
      }
    };
  }, [activeJobRunning, refetch]);

  const onClick = useCallback(async () => {
    setApiMessage(undefined);
    try {
      await mutateAsync();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
      setTimeout(() => setApiMessage(undefined), 3000);
    }
  }, [mutateAsync]);

  return (
    <div>
      <Button
        type="button"
        title="Rebuild Metadata"
        onClick={!loading ? onClick : undefined}
        color={(apiMessage && !apiMessage.success && 'red') || undefined}
      >
        <Icon name={apiMessage?.message ? 'warning sign' : 'sync alternate'} loading={loading} />
        {apiMessage?.message || (loading ? `Rebuilding...` : 'Rebuild Metadata')}
      </Button>
      <p style={{ marginTop: 3, color: 'rgb(153, 153, 153)', fontSize: '14px' }}>
        {job?.started &&
          `Last updated: ${formatDistanceToNow(new Date(job?.started), { addSuffix: true, includeSeconds: true })}`}
      </p>
    </div>
  );
};

export default AdminAccountMetadataRebuildButton;
