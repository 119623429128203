import { useMutation, useQuery, useQueryClient } from 'react-query';

import { User, UserInvite } from 'src/types';
import { ApiResponse, http } from '../http-common';

export type UserResponse = ApiResponse & {
  user?: User;
};

export type UserInviteResponse = ApiResponse & {
  invite?: UserInvite;
};

export const useGetUserProfileQuery = () => {
  return useQuery(
    ['auth/profile'],
    async () => {
      const res = await http.get<UserResponse>('/api/auth/profile');
      return res.data.user;
    },
    { retry: false, refetchOnWindowFocus: false }
  );
};

export type UpdateUserProfileInput = {
  firstname: string;
  lastname: string;
  email: string;
};

export const useUpdateUserProfileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: UpdateUserProfileInput) => {
      const res = await http.post<UserResponse>('/api/auth/profile', input);
      return res.data.user;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/profile']);
      },
    }
  );
};

export type ChangePasswordInput = {
  current_password: string;
  new_password: string;
  confirm_password: string;
};

export const useChangePasswordMutation = () => {
  return useMutation(async (input: ChangePasswordInput) => {
    const res = await http.post<ApiResponse>('/api/auth/change-password', input);
    return res.data;
  });
};

export type GetUserInviteParams = {
  token: string;
};

export const useGetUserInviteQuery = (params: GetUserInviteParams) => {
  return useQuery(
    ['auth/user-invite', params.token],
    async () => {
      const res = await http.get<UserInviteResponse>('/api/auth/user-invite', { params });
      return res.data.invite;
    },
    { retry: false, refetchOnWindowFocus: false }
  );
};

export type AcceptInviteInput = {
  token: string;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  confirm: string;
  tos: boolean;
};

export const useAcceptInviteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: AcceptInviteInput) => {
      const submitData = {
        ...input,
        email: input.email.toLowerCase().trim(),
      };

      const res = await http.post<UserResponse>('/api/auth/user-invite', submitData);
      return res.data.user;
    },
    {
      onSuccess: (_, { token }) => {
        queryClient.invalidateQueries(['auth/user-invite', token]);
        queryClient.removeQueries(['auth/user-invite', token]);
        queryClient.invalidateQueries(['auth/profile']);
        queryClient.removeQueries(['auth/profile']);
      },
    }
  );
};

export type LoginInput = {
  email: string;
  password: string;
};

export const useLoginMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: LoginInput) => {
      const res = await http.post<UserResponse>('/api/auth/login', {
        ...input,
        email: input.email.toLowerCase().trim(),
      });
      return res.data.user;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/profile']);
        queryClient.removeQueries(['auth/profile']);
      },
    }
  );
};

export type ForgotPasswordInput = {
  email: string;
};

export const useForgotPasswordMutation = () => {
  return useMutation(async (input: ForgotPasswordInput) => {
    const res = await http.post<ApiResponse>('/api/auth/forgot-password', {
      ...input,
      email: input.email.toLowerCase().trim(),
    });
    return res.data;
  });
};

export type ResetPasswordInput = {
  token: string;
  password: string;
};

export const useResetPasswordMutation = () => {
  return useMutation(async (input: ResetPasswordInput) => {
    const res = await http.post<ApiResponse>('/api/auth/reset-password', input);
    return res.data;
  });
};

type SwitchAccountInput = {
  id: number;
};

export const useSwitchAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: SwitchAccountInput) => {
      const res = await http.post<UserResponse>('/api/auth/switch-account', input);
      return res.data.user;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['accounts']);

        queryClient.invalidateQueries(['auth/account']);
        queryClient.removeQueries(['auth/account']);

        queryClient.invalidateQueries(['auth/profile']);
        queryClient.removeQueries(['auth/profile']);
      },
    }
  );
};

export const useLogoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      await http.get('/api/auth/logout');
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([]);
        queryClient.removeQueries([]);
      },
    }
  );
};
