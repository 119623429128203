import { useEffect } from 'react';
import { Label, Loader, Statistic, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { TwilioCallStatsParams, useGetAccountTwilioCallStatsQuery } from 'src/api/admin/account-twilio';
import { ListAccountsFilters, useListAccountsQuery } from 'src/api/admin/accounts';
import { RenderProps } from 'src/components/PaginatedTable';
import { Row } from 'src/styles';
import theme from 'src/styles/theme';
import { Account } from 'src/types';

const StatsGroup = styled(Statistic.Group)`
  margin: 0 -0.5em !important;
  align-items: center !important;

  & > .statistic {
    margin: 0 0.5em !important;
  }
`;

type RowProps = {
  account: Account;
  filters: TwilioCallStatsParams;
};

const AccountTwilioCallStatsRow = ({ account: a, filters }: RowProps) => {
  const { data: stats, isLoading } = useGetAccountTwilioCallStatsQuery(a.id, filters);

  if (isLoading) {
    return (
      <Table.Row key={a.id}>
        <Table.Cell collapsing>
          <Label>{a.id}</Label>
        </Table.Cell>
        <Table.Cell>
          <Row style={{ flexDirection: 'column' }}>
            <strong>{a.name}</strong>
            <span style={{ display: 'block', color: theme.gray, fontSize: '0.8rem', lineHeight: '1rem' }}>
              {a.created_by?.email}
            </span>
          </Row>
        </Table.Cell>
        <Table.Cell colSpan={7}>
          <Loader inline active />
        </Table.Cell>
      </Table.Row>
    );
  }

  if (!stats) {
    return (
      <Table.Row key={a.id}>
        <Table.Cell collapsing>
          <Label>{a.id}</Label>
        </Table.Cell>
        <Table.Cell>
          <Row style={{ flexDirection: 'column' }}>
            <strong>{a.name}</strong>
            <span style={{ display: 'block', color: theme.gray, fontSize: '0.8rem', lineHeight: '1rem' }}>
              {a.created_by?.email}
            </span>
          </Row>
        </Table.Cell>
        <Table.Cell colSpan={7}>
          <Label color="red">Unable to load stats</Label>
        </Table.Cell>
      </Table.Row>
    );
  }

  const totalSeizure = stats.busy + stats.failed + stats.no_answer;

  return (
    <Table.Row key={a.id}>
      <Table.Cell collapsing>
        <Label>{a.id}</Label>
      </Table.Cell>
      <Table.Cell>
        <Row style={{ flexDirection: 'column' }}>
          <strong>{a.name}</strong>
          <span style={{ display: 'block', color: theme.gray, fontSize: '0.8rem', lineHeight: '1rem' }}>
            {a.created_by?.email}
          </span>
        </Row>
      </Table.Cell>
      <Table.Cell>
        <Statistic size="mini">
          <Statistic.Value>{stats.completed.toLocaleString()}</Statistic.Value>
        </Statistic>
      </Table.Cell>
      <Table.Cell>
        <StatsGroup size="mini" style={{ opacity: 0.5 }}>
          <Statistic title="Busy">
            <Statistic.Value>{stats.busy.toLocaleString()}</Statistic.Value>
          </Statistic>
          <span style={{ color: theme.gray }}>/</span>
          <Statistic title="Failed">
            <Statistic.Value>{stats.failed.toLocaleString()}</Statistic.Value>
          </Statistic>
          <span style={{ color: theme.gray }}>/</span>
          <Statistic title="No Answer">
            <Statistic.Value>{stats.no_answer.toLocaleString()}</Statistic.Value>
          </Statistic>
        </StatsGroup>
      </Table.Cell>
      <Table.Cell>
        <Statistic size="mini">
          <Statistic.Value>{totalSeizure.toLocaleString()}</Statistic.Value>
        </Statistic>
      </Table.Cell>
      <Table.Cell>
        <Statistic size="mini">
          <Statistic.Value>{totalSeizure > 0 ? (stats.completed / totalSeizure).toFixed(2) : 0}</Statistic.Value>
        </Statistic>
      </Table.Cell>
      <Table.Cell>
        <Statistic size="mini">
          <Statistic.Value>{stats.avg_duration.toFixed(2)}</Statistic.Value>
        </Statistic>
      </Table.Cell>
      <Table.Cell>
        <StatsGroup size="mini">
          <Statistic>
            <Statistic.Value>{stats.less_than_6s.toLocaleString()}</Statistic.Value>
          </Statistic>
          <span style={{ color: theme.gray }}>/</span>
          <Statistic>
            <Statistic.Value>
              {stats.completed > 0 ? ((stats.less_than_6s / stats.completed) * 100).toFixed(2) : 0}%
            </Statistic.Value>
          </Statistic>
        </StatsGroup>
      </Table.Cell>
      <Table.Cell>
        <StatsGroup size="mini">
          <Statistic>
            <Statistic.Value>{stats.greater_than_30s.toLocaleString()}</Statistic.Value>
          </Statistic>
          <span style={{ color: theme.gray }}>/</span>
          <Statistic>
            <Statistic.Value>
              {stats.completed > 0 ? ((stats.greater_than_30s / stats.completed) * 100).toFixed(2) : 0}%
            </Statistic.Value>
          </Statistic>
        </StatsGroup>
      </Table.Cell>
    </Table.Row>
  );
};

type Props = RenderProps & {
  filters?: ListAccountsFilters;
  statsFilters: TwilioCallStatsParams;
};

const AdminAccountsTwilioCallStatsBody = ({ limit, currentPage, setPageCount, filters, statsFilters }: Props) => {
  const { data: accounts } = useListAccountsQuery({ limit, offset: limit * (currentPage - 1), ...filters });

  useEffect(() => {
    let count = 1;
    if (typeof accounts !== 'undefined') {
      count = Math.ceil(accounts.total / limit);
    }
    setPageCount(count);
  }, [accounts, limit, setPageCount]);

  return (
    <>
      {accounts?.data.map(a => (
        <AccountTwilioCallStatsRow account={a} filters={statsFilters} />
      ))}
    </>
  );
};

export default AdminAccountsTwilioCallStatsBody;
