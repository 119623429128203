import { Button, Icon } from 'semantic-ui-react';

import { useDuplicatePipelineMutation } from 'src/api/pipelines';

type Props = {
  id: number;
};

const DuplicatePipeline = ({ id }: Props) => {
  const { mutateAsync, isLoading } = useDuplicatePipelineMutation();

  const onClick = function () {
    mutateAsync(id);
  };

  return (
    <Button color="green" icon title="Duplicate" onClick={onClick} loading={isLoading}>
      <Icon name="copy" />
    </Button>
  );
};

export default DuplicatePipeline;
