import { createElement } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useGetUserProfileQuery } from './api/auth';
import Loading from './components/Loading';
import NotFound from './pages/not-found';

const AdminRoute = ({ children, component, ...rest }: RouteProps) => {
  const { data: user, isLoading } = useGetUserProfileQuery();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isLoading) {
          return <Loading />;
        }

        if (typeof user === 'undefined') {
          return <Redirect to={{ pathname: '/', state: { from: location } }} />;
        }

        if (user.role !== 'admin') {
          return <NotFound />;
        }

        if (component) {
          return createElement(component, rest);
        }

        return children;
      }}
    />
  );
};

export default AdminRoute;
