import { formatDistanceToNow } from 'date-fns';
import { useEffect } from 'react';
import { Label, Table } from 'semantic-ui-react';

import { useListBillingConnectProTransactionsQuery } from 'src/api/auth/account-billing';
import { RenderProps } from 'src/components/PaginatedTable';
import { Row } from 'src/styles';
import theme from 'src/styles/theme';

const BillingConnectProTransactionsListBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: transactions } = useListBillingConnectProTransactionsQuery({
    limit,
    offset: limit * (currentPage - 1),
  });

  useEffect(() => {
    let count = 1;
    if (typeof transactions !== 'undefined') {
      count = Math.ceil(transactions.total / limit);
    }
    setPageCount(count);
  }, [transactions, limit, setPageCount]);

  if (!transactions || transactions?.data.length === 0) {
    return (
      <Table.Row>
        <Table.Cell colSpan={5} style={{ color: theme.gray }}>
          No transactions found.
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      {transactions?.data.map(t => {
        return (
          <Table.Row key={t.id}>
            <Table.Cell>
              <Row style={{ flexDirection: 'column' }}>
                <strong>{t.title}</strong>
                <span style={{ color: theme.gray }}>{t.note}</span>
              </Row>
            </Table.Cell>
            <Table.Cell>
              <Label title={t.createdAt}>
                {formatDistanceToNow(new Date(t.createdAt), { addSuffix: true, includeSeconds: true })}
              </Label>
            </Table.Cell>
            <Table.Cell>
              <Label color={t.amount > 0 ? 'green' : 'red'}>{t.amount.toFixed(2)}</Label>
            </Table.Cell>
            <Table.Cell textAlign="right" collapsing>
              {/* <SwitchAccountButton id={t.id} />
              <Button icon="pencil" color="blue" onClick={() => history.push(`/admin/accounts/${t.id}`)} title="Edit" /> */}
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
};

export default BillingConnectProTransactionsListBody;
