import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { Tab, TabProps } from 'semantic-ui-react';

import PaginatedTable from 'src/components/PaginatedTable';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { Container, Header, Row } from 'src/styles';
import AddConvosoFile from './AddConvosoFile';
import AddConvosoProfile from './AddConvosoProfile';
import ListConvosoFilesBody from './ListConvosoFilesBody';
import ListConvosoProfilesBody from './ListConvosoProfilesBody';

const ConvosoList = () => {
  const query = useSearchQuery();
  const { push, replace } = useHistory();
  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    if (!query.has('tab')) {
      const search = new URLSearchParams();
      search.set('tab', 'files');
      replace({ search: search.toString() });
      return;
    }

    switch (query.get('tab')) {
      case 'profiles':
        setActiveTab(1);
        break;

      default:
      case 'files':
        setActiveTab(0);
        break;
    }
  }, [query, replace]);

  const onTabChange = useCallback(
    (_event: React.MouseEvent<HTMLDivElement>, { activeIndex }: TabProps): void => {
      // setActiveTab(Number(activeIndex));
      const search = new URLSearchParams();
      search.set('tab', activeIndex === 0 ? 'files' : 'profiles');
      push({ search: search.toString() });
    },
    [push]
  );

  const panes = [
    {
      menuItem: 'Files',
      render: () => (
        <>
          <Helmet>
            <title>Files - Convoso | datascore</title>
          </Helmet>

          <PaginatedTable headers={['ID', 'Name', 'Created', 'Last Status', '']} renderBody={ListConvosoFilesBody} />
        </>
      ),
    },
    {
      menuItem: 'Profiles',
      render: () => (
        <>
          <Helmet>
            <title>Profiles - Convoso | datascore</title>
          </Helmet>

          <PaginatedTable headers={['Name', 'Auth Token', '']} renderBody={ListConvosoProfilesBody} />
        </>
      ),
    },
  ];

  return (
    <Container>
      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header style={{ marginBottom: 0 }}>Convoso</Header>
        {activeTab === 0 && <AddConvosoFile />}
        {activeTab === 1 && <AddConvosoProfile />}
      </Row>

      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        onTabChange={onTabChange}
        renderActiveOnly
        activeIndex={activeTab}
      />
    </Container>
  );
};

export default ConvosoList;
