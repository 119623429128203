import { useCallback, useState } from 'react';
import { DropdownItemProps, Form, FormSelectProps, StrictFormSelectProps } from 'semantic-ui-react';

import { useListAllAkkioModelsQuery } from 'src/api/admin/leadscore-plus';

// NOTE: Even though FormSelectProps extends StrictFormSelectProps itself...
// for some reason, Omit<FormSelectProps, 'options'> alone was not providing type hints
// when using <SelectModels /> in other components.
type Props = Omit<FormSelectProps, 'options'> &
  Omit<StrictFormSelectProps, 'options'> & {
    omitModelIds?: number[];
  };

const SelectAkkioModels = ({ omitModelIds = [], onChange: parentOnChange, ...selectProps }: Props) => {
  const [search, setSearch] = useState('');
  const { data: models, isLoading } = useListAllAkkioModelsQuery({ limit: 100, offset: 0 });

  const onChange = useCallback(
    (event, data) => {
      if (typeof parentOnChange !== 'undefined') {
        parentOnChange(event, data);
      }
      setSearch('');
    },
    [parentOnChange]
  );

  const options: DropdownItemProps[] =
    models?.data.map(m => ({ key: m.id, text: m.name, value: m.id })).filter(m => !omitModelIds.includes(m.key)) || [];

  return (
    <Form.Select
      {...selectProps}
      options={options}
      search
      searchQuery={search}
      onSearchChange={(_, { searchQuery }) => setSearch(searchQuery)}
      onChange={onChange}
      loading={isLoading}
    />
  );
};

export default SelectAkkioModels;
