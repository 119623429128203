import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { ConvosoFile, ConvosoProfile, Delimiter, PaginatedResponse } from 'src/types';
import { ApiResponse, http } from './http-common';

export type ConvosoFileResponse = ApiResponse & {
  file?: ConvosoFile;
};

export type ConvosoFilesResponse = ApiResponse & {
  files: PaginatedResponse<ConvosoFile>;
};

export type ConvosoProfileResponse = ApiResponse & {
  profile: ConvosoProfile;
};

export type ConvosoProfilesResponse = ApiResponse & {
  profiles: PaginatedResponse<ConvosoProfile>;
};

export type AddFileInput = {
  profile_id: number;
  file?: File;
  delimiter: Delimiter;
  fieldMappings: string[];
};

export const useAddFileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: AddFileInput) => {
      if (!input.file) return { success: false, message: 'Missing file' };

      const fd = new FormData();
      fd.append('delimiter', input.delimiter);
      fd.append('file', input.file);
      fd.append('field_mappings', input.fieldMappings.join(','));
      fd.append('profile_id', String(input.profile_id));

      const res = await http.post<ConvosoFileResponse>(`/api/convoso/files`, fd);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['convoso/files']);
      },
    }
  );
};

export type UploadFileInput = {
  id: number;
  checkDupes: boolean;
  sendAllFields: boolean;
  listId: string;
  status: string;
};

export const useUploadFileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, ...input }: UploadFileInput) => {
      const res = await http.post<ConvosoFileResponse>(`/api/convoso/files/${id}/upload`, input);
      return res.data;
    },
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries(['convoso/files', id]);
      },
    }
  );
};

export const useGetConvosoFileQuery = (id: number) => {
  return useQuery(['convoso/files', id], async () => {
    const res = await http.get<ConvosoFileResponse>(`/api/convoso/files/${id}`);
    return res.data.file;
  });
};

export type ListConvosoFilesInput = {
  limit: number;
  offset: number;
};

export const useListConvosoFilesQuery = (params: ListConvosoFilesInput) => {
  return useQuery(['convoso/files', params], async () => {
    const res = await http.get<ConvosoFilesResponse>('/api/convoso/files', { params });
    return res.data.files;
  });
};

export type ListConvosoProfilesInput = {
  limit: number;
  offset: number;
};

export const useListConvosoProfilesQuery = (params: ListConvosoProfilesInput) => {
  return useQuery(['convoso/profiles', params], async () => {
    const res = await http.get<ConvosoProfilesResponse>('/api/convoso/profiles', { params });
    return res.data.profiles;
  });
};

export type AddConvosoProfileInput = {
  name: string;
  auth_token: string;
};

export const useAddConvosoProfileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: AddConvosoProfileInput) => {
      const res = await http.post<ConvosoProfileResponse>('/api/convoso/profiles', input);
      return res.data.profile;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['convoso/profiles']);
      },
    }
  );
};

export const useDeleteConvosoProfileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await http.delete<ApiResponse>(`/api/convoso/profiles/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['convoso/profiles']);
      },
    }
  );
};
