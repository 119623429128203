import { Button, Icon, Modal, Table } from 'semantic-ui-react';

import { WebhookLog } from 'src/types';

type Props = {
  item: WebhookLog;
};

const WebhookLogModal = ({ item }: Props) => (
  <Modal
    trigger={
      <Button title="View Details" icon>
        <Icon name="eye" />
      </Button>
    }
  >
    <Modal.Header>Log</Modal.Header>
    <Modal.Content>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>ID</Table.Cell>
            <Table.Cell>{item.id}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Account ID</Table.Cell>
            <Table.Cell>{item.accountId}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Created At</Table.Cell>
            <Table.Cell>{item.createdAt}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Started At</Table.Cell>
            <Table.Cell>{item.startedAt}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Ended At</Table.Cell>
            <Table.Cell>{item.endedAt}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Total Duration (ms)</Table.Cell>
            <Table.Cell>{item.durationMs}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Slug</Table.Cell>
            <Table.Cell>{item.slug}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Local Request Data</Table.Cell>
            <Table.Cell>{item.localReqData}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Local Response Status</Table.Cell>
            <Table.Cell>{item.localResStatus}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Local Error</Table.Cell>
            <Table.Cell>{item.localError}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Remote Request Data</Table.Cell>
            <Table.Cell style={{ wordBreak: 'break-all' }}>{item.proxyReqData}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Remote Response Data</Table.Cell>
            <Table.Cell>{item.proxyResData}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Remote Response Status</Table.Cell>
            <Table.Cell>{item.proxyResStatus}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Remote Error</Table.Cell>
            <Table.Cell>{item.proxyError}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Remote Request Duration (ms)</Table.Cell>
            <Table.Cell>{item.proxyReqMs}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Modal.Content>
  </Modal>
);

export default WebhookLogModal;
