import { delay } from 'lodash';
import { FocusEvent, useCallback, useMemo } from 'react';
import { Form, Icon } from 'semantic-ui-react';

import Filters from '../../components/Filters';
import NodeOverlay from '../../components/NodeOverlay';
import { getConnectedNodes, getConnectedSourcesColumns } from '../../hooks';
import { OverlayProps } from '../../types';
import { FiltersData } from '../types';

const FilterOverlay = ({ node, updateNode, onSave, nodes, edges }: OverlayProps<FiltersData>) => {
  const { id: nodeID, data } = node;

  const saveOnStateUpdate = useCallback(
    // INFO: This delay is needed to because setNodes is async but we can not wait for it to finish
    () => delay(() => onSave('state-updated'), 100),
    [onSave]
  );

  const connectedSources = getConnectedNodes(edges, nodes, nodeID);
  const table = useMemo(() => {
    if (!connectedSources || connectedSources.length === 0) {
      return '';
    }

    return connectedSources[0].data.table;
  }, [connectedSources]);

  const connectedSourcesColumns = getConnectedSourcesColumns(edges, nodes, nodeID);

  return (
    <NodeOverlay>
      <NodeOverlay.Header>
        <Icon name="searchengin" color="violet" size="large" />

        <NodeOverlay.Title>Filter</NodeOverlay.Title>
      </NodeOverlay.Header>

      <NodeOverlay.Description>Filter a data source.</NodeOverlay.Description>

      <NodeOverlay.Content>
        <Form>
          <Form.Input
            label="Name"
            type="text"
            defaultValue={data?.name}
            onBlur={(event: FocusEvent<HTMLInputElement>) => {
              const value = event.target.value;

              updateNode(node.id, { name: value });
              saveOnStateUpdate();
            }}
          />

          <h3>Filters</h3>
          <Filters
            filters={data.filters}
            columns={connectedSourcesColumns}
            table={table}
            updateNode={updateNode}
            node={node}
            onSave={saveOnStateUpdate}
          />
        </Form>
      </NodeOverlay.Content>
    </NodeOverlay>
  );
};

export default FilterOverlay;
