import { useCallback, useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { useCreateBandwidthSubAccountMutation } from 'src/api/admin/account-bandwidth';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';

const TriggerButton = styled.button`
  background: none;
  border: none;
  color: ${p => p.theme.primary};
  cursor: pointer;
  float: right;
`;

type Props = {
  id: number;
};

const CreateBandwidthSubAccountModal = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { isLoading, mutateAsync } = useCreateBandwidthSubAccountMutation();

  const onClose = () => {
    setApiMessage(undefined);
    setOpen(false);
  };

  const onSubmit = useCallback(async (): Promise<void> => {
    setApiMessage(undefined);

    try {
      await mutateAsync(props.id);
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [mutateAsync, props.id]);

  return (
    <Modal
      size="tiny"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={onClose}
      trigger={
        <TriggerButton type="button">
          <Icon name="plus" /> Create New
        </TriggerButton>
      }
    >
      <Modal.Header>Create Bandwidth SubAccount</Modal.Header>
      <Modal.Content>
        <p>This will create a new sub-account in Bandwidth and link it to this Account.</p>

        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Row>
            <Button fluid type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button loading={isLoading} color="blue" fluid>
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default CreateBandwidthSubAccountModal;
