import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Label, Placeholder, PlaceholderParagraph, Table } from 'semantic-ui-react';

import { useListPipelinesQuery } from 'src/api/pipelines';
import LinkWithDetail from 'src/components/LinkWithDetail';
import { RenderProps } from 'src/components/PaginatedTable';
import theme from 'src/styles/theme';
import DeletePipeline from './DeletePipeline';
import DuplicatePipeline from './DuplicatePipeline';
import TogglePipelineEnabledButton from './TogglePipelineEnabledButton';

const PlaceholderRow = () => (
  <Table.Row>
    <Table.Cell collapsing>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell textAlign="right">
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
  </Table.Row>
);

const ListPipelinesBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: pipelines, isLoading } = useListPipelinesQuery({ limit, offset: limit * (currentPage - 1) });

  useEffect(() => {
    let count = 1;
    if (typeof pipelines !== 'undefined') {
      count = Math.ceil(pipelines.total / limit);
    }
    setPageCount(count);
  }, [pipelines, limit, setPageCount]);

  if (isLoading) {
    return <PlaceholderRow />;
  }

  if (!pipelines?.data.length) {
    return (
      <Table.Row>
        <Table.Cell colSpan={5}>
          <span style={{ color: theme.gray }}>No pipelines found</span>
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      {pipelines?.data.map(p => (
        <Table.Row key={p.id}>
          <Table.Cell collapsing>
            <Label>{p.id}</Label>
          </Table.Cell>
          <Table.Cell collapsing>
            <TogglePipelineEnabledButton pipeline={p} />
          </Table.Cell>
          <Table.Cell>
            <LinkWithDetail to={`/pipelines/${p.id}`} text={p.name} />
          </Table.Cell>
          <Table.Cell textAlign="right" collapsing>
            <DuplicatePipeline id={p.id} />
            <Link to={`/pipelines/${p.id}`}>
              <Button as="div" color="blue" icon title="Edit Pipeline">
                <Icon name="pencil" />
              </Button>
            </Link>
            <DeletePipeline id={p.id} />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default ListPipelinesBody;
