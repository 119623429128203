import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { Button, DropdownItemProps, Icon, Message, Segment, Select } from 'semantic-ui-react';
import styled from 'styled-components';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { ScheduleModelFormdata, useGetModelQuery, useSchedulerPredictMutation } from 'src/api/models';
import ApiMessage from 'src/components/ApiMessage';
import BillingWarning from 'src/components/BillingWarning';
import JobHistory from 'src/components/JobHistory';
import Loading from 'src/components/Loading';
import { Container, Header, HorizontalDivider, Row, Subhead } from 'src/styles';
import theme from 'src/styles/theme';
import { JobType, ModelStage, ModelType, ModelVersion } from 'src/types';
import ScheduleImportForm from './ScheduleImportForm';
import ScheduleJobDetailsModal from './ScheduleJobDetailsModal';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ModelsEngagementSchedule = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [schedulerVisible, setSchedulerVisible] = useState<boolean>(false);
  const { data: model, isLoading: pageLoading } = useGetModelQuery(Number(id));
  const { isLoading: formLoading, mutateAsync } = useSchedulerPredictMutation();
  const [modelVersion, setModelVersion] = useState<ModelVersion>();

  const versions: DropdownItemProps[] = useMemo(() => {
    if (!model?.versions) return [];

    const versions = model.versions.reduce((acc, v) => {
      if (v.stage === ModelStage.Archived) return acc;

      return [
        ...acc,
        {
          key: v.id,
          text: `${v.version} ${ModelStage[v.stage]}`,
          value: v.id,
        },
      ];
    }, [] as DropdownItemProps[]);

    if (!modelVersion) {
      // First valid option
      const version = model.versions.find(v => v.id === versions[0].value);
      setModelVersion(version);
    }

    return versions;
  }, [model?.versions, modelVersion]);

  if (pageLoading) return <Loading />;

  if (!model) {
    return (
      <Container>
        <Helmet>
          <title>Model Not Found | datascore</title>
        </Helmet>

        <Message error>
          <Message.Header>🚨 Error, Not Found.</Message.Header>
          <Message.Content>Unable to locate model for id: {id}</Message.Content>
        </Message>
      </Container>
    );
  }

  const handleSubmit = async (input: ScheduleModelFormdata): Promise<void> => {
    setApiMessage(undefined);

    if (!modelVersion) {
      const e = { success: false, status: 400, message: 'Unable to locate model version' };
      setApiMessage(e);
      throw e;
    }

    try {
      await mutateAsync({
        modelId: model.id,
        modelVersion: modelVersion.version,
        ...input,
      });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
      throw e;
    }
  };

  return (
    <Container>
      <Helmet>
        <title>Schedule Model | datascore</title>
      </Helmet>

      <BillingWarning />

      <Row style={{ alignItems: 'center' }}>
        <Button icon color="blue" onClick={history.goBack} style={{ marginRight: '1rem' }}>
          <Icon name="arrow left" />
        </Button>
        <Header style={{ margin: 0 }}>
          <span style={{ color: theme.gray }}>Models</span> <span style={{ padding: '0 0.5rem' }}>/</span> Schedule
        </Header>
      </Row>

      <Segment>
        <Row style={{ justifyContent: 'space-between' }}>
          <div>
            <Subhead style={{ margin: 0 }}>{ModelType[model.type]}</Subhead>
            <HeaderContainer>
              <Header style={{ margin: 0 }}>{model.display_name}</Header>

              {versions.length > 0 && (
                <Select
                  options={versions}
                  value={modelVersion?.id || ''}
                  onChange={(_, { value }) => {
                    const version = model.versions?.find(v => v.id === value);
                    if (version) setModelVersion(version);
                  }}
                />
              )}
            </HeaderContainer>
          </div>

          <div>
            <Button onClick={() => setSchedulerVisible(!schedulerVisible)}>
              <Icon name={schedulerVisible ? 'eye slash' : 'eye'} /> Scheduler
            </Button>
          </div>
        </Row>

        {schedulerVisible && (
          <>
            <HorizontalDivider style={{ margin: '1rem -1rem' }} />

            <ScheduleImportForm handleSubmit={handleSubmit} model={model || undefined} loading={formLoading} />

            <ApiMessage data={apiMessage} />
          </>
        )}
      </Segment>

      <JobHistory details={ScheduleJobDetailsModal} jobType={JobType.Predict} />
    </Container>
  );
};

export default ModelsEngagementSchedule;
