import { useCallback, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';

import { useSavePipelineMutation } from 'src/api/admin/pipelines';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { Pipeline } from 'src/types';

type Props = {
  pipeline: Pipeline;
};

const AdminTogglePipelineEnabledButton = ({ pipeline }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync, isLoading } = useSavePipelineMutation();

  const onClose = useCallback(() => {
    setApiMessage(undefined);
    setOpen(false);
  }, []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    try {
      await mutateAsync({ pipeline: { ...pipeline, enabled: !pipeline.enabled } });
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [mutateAsync, onClose, pipeline]);

  return (
    <Modal
      size="mini"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button type="button" color={pipeline.enabled ? 'green' : 'red'} size="tiny">
          {pipeline.enabled ? 'Enabled' : 'Disabled'}
        </Button>
      }
    >
      <Modal.Header>{pipeline.enabled ? 'Disable' : 'Enabled'} Pipeline</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <p style={{ marginTop: 0 }}>
            <strong>Are you sure?</strong> This action cannot be undone.
          </p>

          <Row>
            <Button fluid type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button color={pipeline.enabled ? 'red' : 'green'} fluid loading={isLoading}>
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AdminTogglePipelineEnabledButton;
