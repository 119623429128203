import { useMutation } from 'react-query';

import { ApiResponse, http } from './http-common';

export type UploadPublicFileResponse = ApiResponse & {
  url?: string;
};

export type StorageUploadPublicFileInput = {
  file?: File;
};

export const useStorageUploadPublicFileMutation = () => {
  return useMutation(async (input: StorageUploadPublicFileInput) => {
    if (!input.file) return { success: false, message: 'Missing file' };

    const fd = new FormData();
    fd.append('file', input.file);

    const res = await http.post<UploadPublicFileResponse>(`/api/storage/public`, fd);
    return res.data;
  });
};
