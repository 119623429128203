import { useCallback, useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import theme from 'src/styles/theme';
import { EngagementCounts } from 'src/types';

type Props = {
  engagementCounts: EngagementCounts;
  start: number;
  end: number;
};

const EngagementCountsChart = ({ engagementCounts, start, end }: Props) => {
  const data = useMemo(() => {
    if (typeof engagementCounts === 'undefined') {
      return [];
    }

    const counts = Object.entries(engagementCounts).map(([date, thresholdCounts]) => {
      const c: { [key: string]: any } = { date };
      Object.entries(thresholdCounts).forEach(([threshold, count]) => {
        c[threshold] = count;
      });
      return c;
    });

    return counts;
  }, [engagementCounts]);

  const rangeIncludes = useCallback(
    threshold => {
      if (threshold < start) return false;
      if (threshold > end - 0.1) return false;

      return true;
    },
    [start, end]
  );

  return (
    <ResponsiveContainer>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
        <Tooltip cursor={{ fill: 'rgba(0,0,0,0.1)' }} />
        {rangeIncludes(0) && <Bar dataKey="0.0" stackId="a" fill={theme.palette.charts.purple} />}
        {rangeIncludes(0.1) && <Bar dataKey="0.1" stackId="a" fill={theme.palette.charts.pink} />}
        {rangeIncludes(0.2) && <Bar dataKey="0.2" stackId="a" fill={theme.palette.charts.cherry} />}
        {rangeIncludes(0.3) && <Bar dataKey="0.3" stackId="a" fill={theme.palette.charts.red} />}
        {rangeIncludes(0.4) && <Bar dataKey="0.4" stackId="a" fill={theme.palette.charts.orange} />}
        {rangeIncludes(0.5) && <Bar dataKey="0.5" stackId="a" fill={theme.palette.charts.yellow} />}
        {rangeIncludes(0.6) && <Bar dataKey="0.6" stackId="a" fill={theme.palette.charts.green} />}
        {rangeIncludes(0.7) && <Bar dataKey="0.7" stackId="a" fill={theme.palette.charts.lightGreen} />}
        {rangeIncludes(0.8) && <Bar dataKey="0.8" stackId="a" fill={theme.palette.charts.aqua} />}
        {rangeIncludes(0.9) && <Bar dataKey="0.9" stackId="a" fill={theme.palette.charts.aqua} />}
        {/* <Brush dataKey="threshold" stroke={theme.primary} onChange={onBrushChange} /> */}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default EngagementCountsChart;
