import update from 'immutability-helper';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { Button, DropdownItemProps, Form, Grid, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Row } from 'src/styles';
import theme from 'src/styles/theme';
import {
  SendgridInboundParseAction,
  SendgridInboundParseActionRule,
  SendgridInboundParseActionTrigger,
  SendgridInboundParseSetting,
} from 'src/types';
import TriggerDsBlacklistSettings from './TriggerDsBlacklistSettings';
import TriggerDsFeedSettings from './TriggerDsFeedSettings';
import TriggerSendGridEmailSettings from './TriggerSendGridEmailSettings';
import TriggerTwilioStudioFlowSettings from './TriggerTwilioStudioFlowSettings';

export const ParseActionTriggers = {
  TWILIO_STUDIO_FLOW: 'twilio-studio-flow',
  SENDGRID_EMAIL: 'sendgrid-email',
  DS_FEED: 'ds-feed',
  DS_BLACKLIST: 'ds-blacklist',
};

const FieldOptions: DropdownItemProps[] = [
  { key: 'from', value: 'from', text: 'From' },
  { key: 'to', value: 'to', text: 'To' },
  { key: 'subject', value: 'subject', text: 'Subject' },
  { key: 'body', value: 'body', text: 'Body' },
];

const OperatorOptions: DropdownItemProps[] = [
  { key: 'contains', value: 'contains', text: 'Contains' },
  { key: 'equals', value: 'equals', text: 'Equals' },
  { key: 'starts-with', value: 'starts-with', text: 'Starts with' },
  // { key: 'ends-with', value: 'ends-with', text: 'Ends with' },
];

const TriggerOptions: DropdownItemProps[] = [
  {
    key: ParseActionTriggers.DS_BLACKLIST,
    value: ParseActionTriggers.DS_BLACKLIST,
    text: 'Blacklist Email and/or Phone',
  },
  { key: ParseActionTriggers.DS_FEED, value: ParseActionTriggers.DS_FEED, text: 'Trigger Datascore Feed' },
  {
    key: ParseActionTriggers.SENDGRID_EMAIL,
    value: ParseActionTriggers.SENDGRID_EMAIL,
    text: 'Send Email (via SendGrid)',
    description: 'Coming Soon',
    disabled: true,
  },
  {
    key: ParseActionTriggers.TWILIO_STUDIO_FLOW,
    value: ParseActionTriggers.TWILIO_STUDIO_FLOW,
    text: 'Trigger Twilio Studio Flow',
    description: 'Coming Soon',
    disabled: true,
  },
];

export const DragHandle = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      margin: '0 0 1rem',
    }}
  >
    <span
      style={{
        background: 'rgba(0,0,0,0.05)',
        padding: '0.5rem',
        borderRadius: theme.borderRadius.normal,
        cursor: 'grab',
      }}
    >
      <Icon name="bars" style={{ margin: 0 }} />
    </span>
  </div>
);

const TextSeparator = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1rem 1rem 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background: rgba(0, 0, 0, 0.05);
    z-index: 0;
    transform: translateY(-50%);
  }

  span {
    background: white;
    padding: 0 1rem 0 0;
    position: relative;
    z-index: 1;
    color: ${p => p.theme.gray};
    letter-spacing: 0.25ch;
  }
`;

const getInitialRuleData = (): SendgridInboundParseActionRule => ({
  id: 0,
  order: 0,
  field: String(FieldOptions[0].value),
  operator: String(OperatorOptions[0].value),
  value: '',
});

const getInitialTriggerData = (): SendgridInboundParseActionTrigger => ({
  id: 0,
  order: 0,
  next: String(TriggerOptions[0].value),
  payload: {},
});

type Props = {
  action: SendgridInboundParseAction;
  actionIndex: number;
  setSetting: Dispatch<SetStateAction<SendgridInboundParseSetting>>;
};

const ParseActionForm = ({ action, actionIndex, setSetting }: Props) => {
  const onChangeAction = useCallback(
    ({ name, value }: { name: string; value: string }) => {
      setSetting(prev => update(prev, { actions: { [actionIndex]: { [name]: { $set: value } } } }));
    },
    [actionIndex, setSetting]
  );

  const onDeleteAction = useCallback(() => {
    setSetting(prev => update(prev, { actions: { $splice: [[actionIndex, 1]] } }));
  }, [actionIndex, setSetting]);

  const addRule = useCallback(() => {
    setSetting(prev =>
      update(prev, {
        actions: { [actionIndex]: { rules: { $push: [getInitialRuleData()] } } },
      })
    );
  }, [actionIndex, setSetting]);

  const removeRule = useCallback(
    (idx: number) => () => {
      setSetting(prev => update(prev, { actions: { [actionIndex]: { rules: { $splice: [[idx, 1]] } } } }));
    },
    [actionIndex, setSetting]
  );

  const onChangeRule = useCallback(
    (idx: number) =>
      ({ name, value }: { name: string; value: string }) => {
        setSetting(prev =>
          update(prev, { actions: { [actionIndex]: { rules: { [idx]: { [name]: { $set: value } } } } } })
        );
      },
    [actionIndex, setSetting]
  );

  const addTrigger = useCallback(() => {
    setSetting(prev =>
      update(prev, {
        actions: {
          [actionIndex]: {
            triggers: { $push: [getInitialTriggerData()] },
          },
        },
      })
    );
  }, [actionIndex, setSetting]);

  const removeTrigger = useCallback(
    (idx: number) => () => {
      setSetting(prev => update(prev, { actions: { [actionIndex]: { triggers: { $splice: [[idx, 1]] } } } }));
    },
    [actionIndex, setSetting]
  );

  const onChangeTrigger = useCallback(
    (idx: number) =>
      ({ name, value }: { name: string; value: string }) => {
        setSetting(prev =>
          update(prev, { actions: { [actionIndex]: { triggers: { [idx]: { [name]: { $set: value } } } } } })
        );
      },
    [actionIndex, setSetting]
  );

  const onChangeTriggerPayload = useCallback(
    (idx: number) =>
      ({ name, value }: { name: string; value: string }) => {
        setSetting(prev =>
          update(prev, {
            actions: { [actionIndex]: { triggers: { [idx]: { payload: { [name]: { $set: value } } } } } },
          })
        );
      },
    [actionIndex, setSetting]
  );

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <Form.Input
              label="Action Name"
              name="name"
              value={action.name}
              style={{ fontWeight: 'bold' }}
              onChange={(_, { name, value }) => onChangeAction({ name, value })}
            />

            <Row>
              <Button type="button" color="red" icon onClick={onDeleteAction}>
                <Icon name="trash" />
              </Button>
            </Row>
          </Grid.Column>
          <Grid.Column width={12}>
            <TextSeparator>
              <span>If</span>
            </TextSeparator>

            {action.rules?.map((r, i) => (
              <div key={`a-${actionIndex}-r-${i}`}>
                <Row>
                  {/* TODO: Make these drag and droppable */}
                  {/* <DragHandle /> */}
                  <Form.Group>
                    <Form.Select
                      name="field"
                      options={FieldOptions}
                      value={r.field}
                      onChange={(_, { name, value }) => onChangeRule(i)({ name, value: String(value) })}
                    />
                    <Form.Select
                      name="operator"
                      options={OperatorOptions}
                      value={r.operator}
                      onChange={(_, { name, value }) => onChangeRule(i)({ name, value: String(value) })}
                    />
                    <Form.Input
                      placeholder="search term(s)"
                      name="value"
                      value={r.value}
                      onChange={(_, { name, value }) => onChangeRule(i)({ name, value })}
                    />
                  </Form.Group>

                  <div style={{ display: 'flex', margin: '0 1rem 1rem' }}>
                    <Button type="button" icon color="red" onClick={removeRule(i)}>
                      <Icon name="trash" />
                    </Button>
                  </div>
                </Row>

                <p style={{ color: theme.gray, marginTop: '-0.5rem' }}>Separate multiple search terms with a comma.</p>

                <TextSeparator>
                  <span>And</span>
                </TextSeparator>
              </div>
            ))}

            <Form.Field>
              <Button type="button" icon onClick={addRule}>
                <Icon name="plus" /> Add Rule
              </Button>
            </Form.Field>

            <TextSeparator>
              <span>Then</span>
            </TextSeparator>

            {action.triggers?.map((t, i) => (
              <div key={`a-${actionIndex}-t-${i}`}>
                <Row>
                  <Form.Select
                    name="next"
                    options={TriggerOptions}
                    value={t.next}
                    onChange={(_, { name, value }) => onChangeTrigger(i)({ name, value: String(value) })}
                  />

                  <div style={{ display: 'flex', margin: '0 1rem 1rem' }}>
                    <Button type="button" icon color="red" onClick={removeTrigger(i)}>
                      <Icon name="trash" />
                    </Button>
                  </div>
                </Row>

                {t.next === ParseActionTriggers.DS_BLACKLIST && (
                  <TriggerDsBlacklistSettings trigger={t} onChange={onChangeTriggerPayload(i)} />
                )}
                {t.next === ParseActionTriggers.DS_FEED && (
                  <TriggerDsFeedSettings trigger={t} onChange={onChangeTriggerPayload(i)} />
                )}
                {t.next === ParseActionTriggers.SENDGRID_EMAIL && (
                  <TriggerSendGridEmailSettings trigger={t} onChange={onChangeTriggerPayload(i)} />
                )}
                {t.next === ParseActionTriggers.TWILIO_STUDIO_FLOW && (
                  <TriggerTwilioStudioFlowSettings trigger={t} onChange={onChangeTriggerPayload(i)} />
                )}

                <TextSeparator>
                  <span>And</span>
                </TextSeparator>
              </div>
            ))}

            <Form.Field>
              <Button type="button" icon onClick={addTrigger}>
                <Icon name="plus" /> Add Trigger
              </Button>
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default ParseActionForm;
