import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Button, Divider, Form, Icon, Loader, Message, StepProps } from 'semantic-ui-react';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useCompleteOnboardingMutation, useGetCallCenterQuery, useUpdateCallCenterMutation } from 'src/api/onboarding';
import ApiMessage from 'src/components/ApiMessage';
import { CallCenter, ScheduleConfigDay } from 'src/types';
import ScheduleTableForm, { getInitialScheduleData } from '../models/schedule/ScheduleTableForm';

type ValidationErrors = {
  inbound_phone_number?: string;
  schedule?: string;
};

const validateRequired = (obj: CallCenter): ValidationErrors => {
  const errors: ValidationErrors = {};

  if (!obj.inbound_phone_number) {
    errors.inbound_phone_number = 'This field is required';
  }

  const scheduleValues = Object.values(obj.schedule?.config || {});

  const hasConflicts = scheduleValues.some(value => {
    const { enabled, end, start } = value as ScheduleConfigDay;

    return enabled && end < start;
  });

  const isSomeDaySelected = scheduleValues.some(value => (value as ScheduleConfigDay).enabled);

  if (hasConflicts) {
    errors.schedule = 'End time must be after start time';
  }

  if (!isSomeDaySelected) {
    errors.schedule = 'Please select at least one day';
  }

  return errors;
};

const StepCallCenter = ({ onBack, onContinue }: StepProps) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { data, isLoading } = useGetCallCenterQuery();
  const [formData, setFormData] = useState<CallCenter>(() => ({
    id: 0,
    inbound_phone_number: '',
    schedule_id: 0,
    schedule: getInitialScheduleData(),
  }));
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { mutateAsync: updateCallCenter, isLoading: isUpdateLoading } = useUpdateCallCenterMutation();
  const { mutateAsync: completeOnboarding, isLoading: isCompleteLoading } = useCompleteOnboardingMutation();

  useEffect(() => {
    if (!data?.call_center) return;

    const schedule = data.call_center.schedule;

    setFormData(prev => ({
      ...data.call_center,
      schedule_id: data.call_center.schedule_id || prev.schedule_id,
      schedule: !schedule || isEmpty(schedule) ? prev.schedule : schedule,
    }));

    if (!data?.call_center.schedule) return;
  }, [data?.call_center]);

  // Updates the state of form data based on the input name
  const onChange = useCallback((_, { name, value }) => setFormData(prev => ({ ...prev, [name]: value })), []);

  const onSubmit = useCallback(
    async (force = false) => {
      setApiMessage(undefined);

      const validationErrors = validateRequired(formData);
      setErrors(validationErrors);

      if (!isEmpty(validationErrors) && !force) {
        setApiMessage({ success: false, status: 400, message: 'Please check for validation errors above.' });
        return;
      }

      try {
        await updateCallCenter(formData);
      } catch (e) {
        apiErrorHandler(e, setApiMessage);
        return;
      }

      // onContinue();
      try {
        await completeOnboarding();
      } catch (e) {
        apiErrorHandler(e, setApiMessage);
        return;
      }
    },
    [formData, updateCallCenter, completeOnboarding]
  );

  if (isLoading) return <Loader active />;

  return (
    <div style={{ maxWidth: '540px' }}>
      <h1>Call Center</h1>

      <p>Please provide the following details about your schedule.</p>

      <Form onSubmit={() => onSubmit()}>
        <Form.Input
          width="8"
          name="inbound_phone_number"
          label="Inbound Phone Number / SIP"
          value={formData.inbound_phone_number}
          error={errors.inbound_phone_number}
          onChange={onChange}
        />

        <ScheduleTableForm
          schedule={formData.schedule || getInitialScheduleData()}
          setSchedule={s => setFormData(prev => ({ ...prev, schedule: s }))}
        />

        {errors.schedule && (
          <Message error visible>
            <Message.Content>{errors.schedule}</Message.Content>
          </Message>
        )}

        <Divider />

        <ApiMessage data={apiMessage} />

        <Button type="button" basic floated="left" onClick={onBack}>
          <Icon name="chevron left" />
          Back
        </Button>

        <Button floated="right" color="blue" loading={isUpdateLoading || isCompleteLoading}>
          Complete Onboarding
          <Icon name="chevron right" />
        </Button>
      </Form>
    </div>
  );
};

export default StepCallCenter;
