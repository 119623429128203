import { delay } from 'lodash';
import { FocusEvent, useCallback } from 'react';
import { Form, Icon } from 'semantic-ui-react';

import NodeOverlay from '../../components/NodeOverlay';
import { OverlayProps } from '../../types';

const OutputOverlay = ({ node, updateNode, onSave }: OverlayProps) => {
  const { data } = node;

  const saveOnStateUpdate = useCallback(
    // INFO: This delay is needed to because setNodes is async but we can not wait for it to finish
    () => delay(() => onSave('state-updated'), 100),
    [onSave]
  );

  return (
    <NodeOverlay>
      <NodeOverlay.Header>
        <Icon name="rss" color="violet" size="large" />

        <NodeOverlay.Title>Output</NodeOverlay.Title>
      </NodeOverlay.Header>

      <NodeOverlay.Description>Output a data source.</NodeOverlay.Description>

      <NodeOverlay.Content>
        <Form>
          <Form.Input
            label="Name"
            type="text"
            defaultValue={data?.name}
            onBlur={(event: FocusEvent<HTMLInputElement>) => {
              const value = event.target.value;

              updateNode(node.id, { name: value });
              saveOnStateUpdate();
            }}
          />
        </Form>
      </NodeOverlay.Content>
    </NodeOverlay>
  );
};

export default OutputOverlay;
