import { Helmet } from 'react-helmet-async';
import { Link, Redirect } from 'react-router-dom';
import { Grid, Header, Icon, Label, Segment, SemanticWIDTHS } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import Loading from 'src/components/Loading';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader } from 'src/styles';
import theme from 'src/styles/theme';
import { AccountPermission } from 'src/types';
import { hasAccountPermission } from 'src/utils';

const frontendUrl = process.env.REACT_APP_FRONTEND_URL;

const Welcome = () => {
  const { data: user, isLoading } = useGetUserProfileQuery();

  if (isLoading) return <Loading />;

  if (!user) return <NotFound />;

  const isAdmin = user?.role === 'admin';
  const isOnboardingCompleted = Boolean(user?.active_account?.onboarding_completed_at);
  if (!isOnboardingCompleted && !isAdmin) {
    return <Redirect to="/onboarding" />;
  }

  let colCount = 0;
  if (hasAccountPermission(user, AccountPermission.DATASETS)) colCount += 1;
  if (hasAccountPermission(user, AccountPermission.BLACKLISTS)) colCount += 1;
  if (hasAccountPermission(user, AccountPermission.MODELS)) colCount += 1;
  if (hasAccountPermission(user, AccountPermission.FEEDS)) colCount += 1;
  if (hasAccountPermission(user, AccountPermission.REPORTING)) colCount += 1;

  return (
    <Container>
      <Helmet>
        <title>Welcome | datascore</title>
      </Helmet>

      <PageHeader>Welcome</PageHeader>

      <Segment>
        <p style={{ margin: 0 }}>
          Thanks for signing up and welcome to Datascore! Here are a few things to get you started.
        </p>
      </Segment>

      <Grid columns={colCount as SemanticWIDTHS}>
        <Grid.Row>
          {hasAccountPermission(user, AccountPermission.DATASETS) && (
            <Grid.Column>
              <Link to="/datasets">
                <Segment textAlign="center">
                  <Icon name="database" size="huge" style={{ marginTop: '1rem' }} color="blue" />
                  <Header style={{ marginTop: '0.5rem' }}>Import</Header>
                  <p style={{ color: theme.gray }}>Upload your customer data into one or more Dataset(s).</p>
                </Segment>
              </Link>
            </Grid.Column>
          )}

          {hasAccountPermission(user, AccountPermission.BLACKLISTS) && (
            <Grid.Column>
              <Link to="/blacklists">
                <Segment textAlign="center">
                  <Icon name="minus circle" size="huge" style={{ marginTop: '1rem' }} color="blue" />
                  <Header style={{ marginTop: '0.5rem' }}>Suppress</Header>
                  <p style={{ color: theme.gray }}>
                    Upload a copy of your DNC file to our Blacklist API for internal scrubbing.
                  </p>
                </Segment>
              </Link>
            </Grid.Column>
          )}

          {hasAccountPermission(user, AccountPermission.MODELS) && (
            <Grid.Column>
              <Link to="/models">
                <Segment textAlign="center">
                  <Icon name="compress" size="huge" style={{ marginTop: '1rem' }} color="blue" />
                  <Header style={{ marginTop: '0.5rem' }}>Model</Header>
                  <p style={{ color: theme.gray }}>
                    Use Machine Learning to find the best time to contact your customers.
                  </p>
                </Segment>
              </Link>
            </Grid.Column>
          )}

          {hasAccountPermission(user, AccountPermission.FEEDS) && (
            <Grid.Column>
              <a href={`${frontendUrl}/feeds`}>
                <Segment textAlign="center">
                  <Icon name="code branch" size="huge" style={{ marginTop: '1rem' }} color="blue" />
                  <Header style={{ marginTop: '0.5rem' }}>Process</Header>
                  <p style={{ color: theme.gray }}>
                    Control how your realtime or scheduled data is processed through our Feeds.
                  </p>
                </Segment>
              </a>
            </Grid.Column>
          )}

          {hasAccountPermission(user, AccountPermission.REPORTING) && (
            <Grid.Column>
              <Link to="/reporting">
                <Segment textAlign="center">
                  <Icon name="pie chart" size="huge" style={{ marginTop: '1rem' }} color="blue" />
                  <Header style={{ marginTop: '0.5rem' }}>Analyze</Header>
                  <p style={{ color: theme.gray }}>
                    View stats like number of dials and connects in the Reporting dashboard.
                  </p>

                  <Label attached="top right" color="green">
                    New!
                  </Label>
                </Segment>
              </Link>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default Welcome;
