import { Table } from 'semantic-ui-react';

import { AccountUserAccess } from 'src/types';
import AssignAccountUserAccessRole from './AssignAccountUserAccessRole';
import RemoveUserAccess from './RemoveUserAccess';

type Props = {
  userAccess: AccountUserAccess[];
};

const AdminAccountUsersListBody = ({ userAccess }: Props) => {
  return (
    <>
      {userAccess.map(ua => {
        return (
          <Table.Row key={`${ua.account_id}-${ua.user_id}`}>
            <Table.Cell>{ua.user?.email}</Table.Cell>
            <Table.Cell>
              <AssignAccountUserAccessRole accountId={ua.account_id} userId={ua.user_id} roles={ua.roles} />
            </Table.Cell>
            <Table.Cell textAlign="right" collapsing>
              <RemoveUserAccess accountId={ua.account_id} userId={ua.user_id} />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
};

export default AdminAccountUsersListBody;
