import { useRef, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';

import { PostalCodeStats } from 'src/api/auth/account-twilio';
import GetPostalCodeStatsForm from './GetPostalCodeStatsForm';
import PurchasePostalCodesForm from './PurchasePostalCodesForm';

const SearchByPostalCodes = () => {
  const modalRef = useRef(null);
  const [stats, setStats] = useState<PostalCodeStats | undefined>();

  const onCancel = () => {
    if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
      // @ts-ignore
      modalRef.current.handleClose();
    }
  };

  const onClose = () => {
    setStats(undefined);
  };

  return (
    <Modal
      ref={modalRef}
      trigger={
        <Button color="blue" type="button">
          Search by Postal Codes
        </Button>
      }
      onClose={onClose}
    >
      <Modal.Header>Search by Postal Codes</Modal.Header>
      <Modal.Content>
        {typeof stats === 'undefined' ? (
          <GetPostalCodeStatsForm onCancel={onCancel} onSuccess={stats => setStats(stats)} />
        ) : (
          <PurchasePostalCodesForm onCancel={onCancel} onSuccess={onCancel} stats={stats} />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default SearchByPostalCodes;
