import { format, parse } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

import { AdminGetAccountsReportingParams } from 'src/api/admin/account-reporting';
import { ListAccountsFilters } from 'src/api/admin/accounts';
import Breadcrumbs from 'src/components/Breadcrumbs';
import PaginatedTable, { RenderProps } from 'src/components/PaginatedTable';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { Container, Header, Row } from 'src/styles';
import { ReportingTimeRange, ReportingTimeRanges } from 'src/types';
import AdminAccountsReportingBody from './AdminAccountsReportingBody';

export const STATUS_FILTERS = [
  { key: 'enabled', value: true, text: 'Enabled' },
  { key: 'disabled', value: false, text: 'Disabled' },
];

const getFiltersFromQuery = (query: URLSearchParams): ListAccountsFilters => {
  let enabled;
  switch (query.get('enabled')) {
    case '1':
      enabled = true;
      break;

    case '0':
      enabled = false;
      break;

    default:
      enabled = undefined;
      break;
  }

  return { enabled };
};

const getInitialStatsFilters = (): AdminGetAccountsReportingParams => {
  let lastMonth = new Date().getMonth() - 1;
  if (lastMonth < 1) {
    lastMonth = 12;
  }

  return {
    timeRange: 'today',
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  };
};

const AdminReportingV1 = () => {
  const { push, replace } = useHistory();
  const query = useSearchQuery();
  const [filters, setFilters] = useState<ListAccountsFilters>(() => getFiltersFromQuery(query));
  const [daterange, setDaterange] = useState<[Date | null, Date | null]>([null, null]);
  const [statsFilters, setStatsFilters] = useState<AdminGetAccountsReportingParams>(() => {
    const d = getInitialStatsFilters();
    setDaterange([parse(d.startDate, 'yyyy-MM-dd', new Date()), parse(d.endDate, 'yyyy-MM-dd', new Date())]);
    return d;
  });

  useEffect(() => {
    if (!query.has('enabled')) {
      query.set('enabled', '1');
      replace({ search: query.toString() });
    }
  });

  useEffect(() => {
    setFilters(getFiltersFromQuery(query));
  }, [query]);

  const onChangeTimeRange = useCallback((d, { value }) => {
    let startDate = '';
    let endDate = '';

    let lastMonth = new Date().getMonth() - 1;
    if (lastMonth < 0) {
      lastMonth = 11;
    }

    let prevMonth = new Date().getMonth() - 2;
    if (prevMonth < 0) {
      prevMonth = 11;
    }
    switch (value) {
      case 'today':
        startDate = endDate = format(new Date(), 'yyyy-MM-dd');
        break;

      case 'yesterday':
        startDate = endDate = format(new Date(Date.now() - 86400000), 'yyyy-MM-dd');
        break;

      case 'this week':
        // startDate = sunday, endDate = today
        startDate = format(new Date(Date.now() - 86400000 * new Date().getDay()), 'yyyy-MM-dd');
        endDate = format(new Date(), 'yyyy-MM-dd');
        break;

      case 'last week':
        startDate = format(new Date(Date.now() - 86400000 * (new Date().getDay() + 7)), 'yyyy-MM-dd');
        endDate = format(new Date(Date.now() - 86400000 * new Date().getDay()), 'yyyy-MM-dd');
        break;

      case 'this month':
        startDate = format(new Date().setDate(1), 'yyyy-MM-dd');
        endDate = format(new Date(), 'yyyy-MM-dd');
        break;

      case 'last month':
        startDate = format(new Date().setMonth(lastMonth, 1), 'yyyy-MM-dd');
        endDate = format(new Date(new Date().setDate(1) - 86500000), 'yyyy-MM-dd');
        break;

      case 'previous month':
        startDate = format(new Date().setMonth(prevMonth, 1), 'yyyy-MM-dd');
        endDate = format(new Date(new Date().setMonth(lastMonth, 1) - 86500000), 'yyyy-MM-dd');
        break;

      case 'this year':
        startDate = format(new Date().setMonth(0, 1), 'yyyy-MM-dd');
        endDate = format(new Date(), 'yyyy-MM-dd');
        break;

      case 'custom':
        startDate = format(d[0] || new Date(), 'yyyy-MM-dd');
        endDate = format(d[1] || new Date(), 'yyyy-MM-dd');
        break;
    }
    setDaterange([parse(startDate, 'yyyy-MM-dd', new Date()), parse(endDate, 'yyyy-MM-dd', new Date())]);
    setStatsFilters(prev => ({ ...prev, timeRange: value as ReportingTimeRange, startDate, endDate }));
  }, []);

  const onChangeDates = useCallback(
    (d: [Date | null, Date | null]) => {
      setDaterange(d);
      const [startDate, endDate] = d;
      if (startDate !== null && endDate !== null) {
        setStatsFilters(prev => ({
          ...prev,
          timeRange: 'custom' as ReportingTimeRange,
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
        }));
        onChangeTimeRange(d, { value: 'custom' });
      }
    },
    [onChangeTimeRange]
  );

  return (
    <Container>
      <Helmet>
        <title>Reporting v1 - Admin | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header style={{ marginBottom: 0 }}>
          <Breadcrumbs crumbs={['Admin', 'Reporting', 'v1']} />
        </Header>
      </Row>

      <Form>
        <Form.Group>
          <Form.Select
            label="Status"
            placeholder="All"
            clearable
            options={STATUS_FILTERS}
            value={typeof filters.enabled === 'undefined' ? '' : filters.enabled}
            onChange={(_, { value }) => {
              let enabled = '';
              switch (value) {
                case '':
                  enabled = '';
                  break;
                default:
                  enabled = Number(value).toString();
                  break;
              }

              query.set('enabled', enabled);
              query.set('page', '1');
              push({ search: query.toString() });

              // NOTE: the useEffect hook above (watching query) will set the filter state for us
            }}
          />
          <Form.Field>
            <label>Date Range</label>
            <Form.Select
              onChange={onChangeTimeRange}
              options={ReportingTimeRanges.map(tr => ({ ...tr, key: tr.value }))}
              value={statsFilters.timeRange}
            />
          </Form.Field>
          <Form.Field style={{ width: 240 }}>
            <label>Custom Range</label>
            <DatePicker
              selectsRange
              startDate={daterange[0]}
              endDate={daterange[1]}
              customInput={<Form.Input fluid icon="calendar" iconPosition="left" />}
              monthsShown={2}
              showPopperArrow={false}
              onChange={onChangeDates}
            />
          </Form.Field>
        </Form.Group>
      </Form>

      <PaginatedTable
        headers={['ID', 'Name', 'Dials', 'Connects', 'Connect %', 'Inbounds', 'Transfers', 'Transfer %']}
        renderBody={(props: RenderProps) => (
          <AdminAccountsReportingBody {...props} filters={filters} statsFilters={statsFilters} />
        )}
      />
    </Container>
  );
};

export default AdminReportingV1;
