import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, Form, Grid, Header } from 'semantic-ui-react';

import { useUpdateAccountMutation } from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Note } from 'src/styles';
import { Account, AccountVoice } from 'src/types';

type ValidationErrors = {
  outbound_limit?: string;
  outbound_limit_max?: string;
  vad_timeout_ms?: string;
};

type Props = {
  account: Account;
};

const getInitialFormdata = (account: Account): AccountVoice => {
  return {
    outbound_limit: account.voice?.outbound_limit || 0,
    outbound_limit_max: account.voice?.outbound_limit_max || 0,
    vad_timeout_ms: account.voice?.vad_timeout_ms || 0,
  };
};

const AdminAccountVoice = ({ account }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [formdata, setFormdata] = useState<AccountVoice>(() => getInitialFormdata(account));
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { mutateAsync, isLoading: mutationIsLoading } = useUpdateAccountMutation();

  const validate = useCallback((input: AccountVoice): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    if (input.outbound_limit > 0 && input.outbound_limit > (input.outbound_limit_max || 60)) {
      validationErrors.outbound_limit = 'Outbound limit must be less than or equal to Outbound limit max';
    }

    if (input.vad_timeout_ms < 0) {
      validationErrors.vad_timeout_ms = 'VAD Timeout must be greater than or equal to 0';
    }
    if (input.vad_timeout_ms % 20 !== 0) {
      validationErrors.vad_timeout_ms = 'VAD Timeout must be a multiple of 20';
    }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    if (!isEmpty(validate(formdata))) {
      return;
    }

    try {
      await mutateAsync({ id: account.id, voice: { ...formdata } });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [account.id, mutateAsync, formdata, validate]);

  return (
    <Form onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      <Header as="h4">Rate Limits</Header>

      <Grid>
        <Grid.Row>
          <Grid.Column width={6}>
            <Form.Input
              placeholder={formdata.outbound_limit_max || '60'}
              label="Outbound Limit (per minute)"
              name="outbound_limit"
              type="number"
              min="0"
              value={formdata.outbound_limit || ''}
              error={errors.outbound_limit}
              onChange={(_, { value }) => setFormdata(prev => ({ ...prev, outbound_limit: parseInt(value) }))}
            />
            <Note>
              This limit is also shown to the user on the voice configs page. Any user with access to that page will be
              able to change it there.
            </Note>
          </Grid.Column>

          <Grid.Column width={6}>
            <Form.Input
              placeholder="60"
              label="Outbound Limit MAX (per minute)"
              name="outbound_limit_max"
              type="number"
              min="0"
              value={formdata.outbound_limit_max || ''}
              error={errors.outbound_limit_max}
              onChange={(_, { value }) => setFormdata(prev => ({ ...prev, outbound_limit_max: parseInt(value) }))}
            />
            <Note>
              This limit is an admin-only setting. It is the upper limit that this account will be allowed to use.
            </Note>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={6}>
            <Form.Input
              placeholder={formdata.vad_timeout_ms || '500'}
              label="VAD Timeout (ms)"
              name="vad_timeout_ms"
              type="number"
              min="0"
              value={formdata.vad_timeout_ms || ''}
              error={errors.vad_timeout_ms}
              onChange={(_, { value }) => setFormdata(prev => ({ ...prev, vad_timeout_ms: parseInt(value) }))}
            />
            <Note>
              VAD (Voice Activity Detection) Timeout in milliseconds. This is the minimum amount of silence that must be
              detected before the Human Detection API will consider the prospect done speaking and will transcribe the
              audio and make a prediction.
            </Note>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Button color="blue" loading={mutationIsLoading}>
              Save
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default AdminAccountVoice;
