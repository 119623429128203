import { useMutation, useQuery } from 'react-query';

import { Delimiter, Model, PaginatedResponse, PredictJob, Schedule } from 'src/types';
import { ApiResponse, http } from './http-common';

export type ModelResponse = ApiResponse & {
  model?: Model;
};

export type ModelsResponse = ApiResponse & {
  models: PaginatedResponse<Model>;
};

export type PredictJobResponse = ApiResponse & {
  job?: PredictJob;
};

export type PaymentIntentResponse = ApiResponse & {
  clientSecret: string;
  paymentIntentId: string;
};

export type ListModelsParams = {
  limit: number;
  offset: number;
};

export const useListModelsQuery = (params: ListModelsParams) => {
  return useQuery(['models', params], async () => {
    const res = await http.get<ModelsResponse>('/api/models', { params });
    return res.data.models;
  });
};

export const useGetModelQuery = (id: number) => {
  return useQuery(['models', id], async () => {
    const res = await http.get<ModelResponse>(`/api/models/${id}`);
    return res.data.model;
  });
};

export type ScheduleModelFormdata = {
  file?: File;
  delimiter: Delimiter;
  fieldMappings: string[];
  schedule: Schedule;
  startDate: Date | null;
  lookahead: number;
  hourlyCapacity: number;
  feedId: number | null;
};

export type ScheduleModelInput = ScheduleModelFormdata & {
  modelId: number;
  modelVersion: string;
};

export const useSchedulerPredictMutation = () => {
  return useMutation(async (input: ScheduleModelInput) => {
    if (!input.file) return { success: false, reason: 'Missing file' };

    const fd = new FormData();
    fd.append('model_version', input.modelVersion);
    fd.append('delimiter', input.delimiter);
    fd.append('file', input.file);
    fd.append('field_mappings', input.fieldMappings.join(','));
    fd.append('schedule', JSON.stringify(input.schedule));
    fd.append('start_date', (input.startDate && input.startDate.toISOString()) || '');
    fd.append('lookahead', String(input.lookahead));
    fd.append('hourly_capacity', String(input.hourlyCapacity));
    if (input.feedId) {
      fd.append('feed_id', String(input.feedId));
    }

    const res = await http.post<PredictJobResponse>(`/api/models/${input.modelId}/scheduler/predict`, fd);
    return res.data;
  });
};
