import { useEffect } from 'react';
import { Button, Icon, Label, Loader } from 'semantic-ui-react';

import { useGetTwilioCallQueueCallCountQuery, useGetTwilioCallQueueCurrentJobQuery } from 'src/api/auth/account-twilio';
import { JobStatus } from 'src/types';
import CancelCallQueueModal from './CancelCallQueueModal';

const TwilioCallQueue = () => {
  const { data, isLoading } = useGetTwilioCallQueueCallCountQuery();
  const { data: job, refetch } = useGetTwilioCallQueueCurrentJobQuery();

  const activeJobRunning = job?.status === JobStatus.Processing || job?.status === JobStatus.Pending;

  useEffect(() => {
    let timeout: number | null = null;
    if (activeJobRunning) {
      timeout = setInterval(refetch, 5e3);
    }
    return () => {
      if (timeout !== null) {
        clearInterval(timeout);
        timeout = null;
      }
    };
  }, [activeJobRunning, refetch]);

  return (
    <>
      <div style={{ marginBottom: '1rem' }}>
        <Label>
          {isLoading ? (
            <span style={{ display: 'inline-block', position: 'relative', width: 10, height: 12 }}>
              <Loader active size="mini" />
            </span>
          ) : (
            <>
              {(data?.count || 0).toLocaleString()}
              {(data?.count || 0) >= 10_000 ? '+' : ''}
            </>
          )}
          <Label.Detail>Queue Length</Label.Detail>
        </Label>
      </div>

      {activeJobRunning ? (
        <Button>
          <Icon loading name="spinner" />
          Job #{job.id} - {JobStatus[job.status]}
        </Button>
      ) : (
        <CancelCallQueueModal />
      )}
    </>
  );
};

export default TwilioCallQueue;
