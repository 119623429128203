import { FC, PropsWithChildren } from 'react';
import { Divider, Header, Placeholder } from 'semantic-ui-react';
import styled from 'styled-components';

export const GridLayout = styled.div`
  display: grid;
  grid-template-areas:
    'general v1 request v2 body'
    'h1 h1 h1 h1 h1'
    'response v3 actions actions actions';
  grid-template-columns: 2fr auto 2fr auto 3fr;
  grid-template-rows: auto auto auto;
  gap: 1rem;
`;

export const Layout: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  return (
    <GridLayout>
      {children}

      <Divider className="full" vertical style={{ gridArea: 'v1', position: 'relative' }} />
      <Divider className="full" vertical style={{ gridArea: 'v2', position: 'relative' }} />
      <Divider style={{ gridArea: 'h1', position: 'relative' }} />
      <Divider className="full" vertical style={{ gridArea: 'v3', position: 'relative' }} />
    </GridLayout>
  );
};

export const PlaceholderItem = ({ small }: { small?: boolean }) => (
  <Placeholder fluid>
    <Placeholder.Header image>
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder.Header>

    {!small && (
      <>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>

        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>

        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </>
    )}
  </Placeholder>
);

export const PlaceholderLayout = () => {
  return (
    <Layout>
      <div style={{ gridArea: 'general' }}>
        <Header>General</Header>
        <PlaceholderItem />
        <Header>Custom Fields</Header>
        <PlaceholderItem />
      </div>

      <div style={{ gridArea: 'request' }}>
        <Header>Request</Header>
        <PlaceholderItem />
      </div>

      <div style={{ gridArea: 'body' }}>
        <Header>Datasets</Header>
        <PlaceholderItem />
        <Header>Field Mappings</Header>
        <PlaceholderItem />
      </div>
    </Layout>
  );
};
