import { FC } from 'react';
import { Divider, Header, Icon, Placeholder } from 'semantic-ui-react';
import styled from 'styled-components';

export const GridLayout = styled.div`
  display: grid;
  grid-template-areas: 'name v1 sch sch sch' 'h h h h h' 'ext v2 mod v3 ing';
  grid-template-columns: 4fr auto 3fr auto 3fr;
  grid-template-rows: auto auto auto;
  gap: 1rem;
`;

export const Layout: FC = ({ children }) => {
  return (
    <GridLayout>
      {children}

      <Divider className="full" vertical style={{ gridArea: 'v1', position: 'relative' }} />

      <Divider fitted style={{ gridArea: 'h', margin: '0.5rem 0' }} />

      <Divider vertical style={{ gridArea: 'v2', position: 'relative' }}>
        <Icon name="arrow right" color="grey" />
      </Divider>

      <Divider vertical style={{ gridArea: 'v3', position: 'relative' }}>
        <Icon name="arrow right" color="grey" />
      </Divider>
    </GridLayout>
  );
};

export const PlaceholderItem = ({ small }: { small?: boolean }) => (
  <Placeholder fluid>
    <Placeholder.Header image>
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder.Header>

    {!small && (
      <>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>

        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>

        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </>
    )}
  </Placeholder>
);

export const PlaceholderLayout = () => {
  return (
    <Layout>
      <div style={{ gridArea: 'name' }}>
        <PlaceholderItem small />
      </div>

      <div style={{ gridArea: 'sch' }}>
        <PlaceholderItem small />
      </div>

      <div style={{ gridArea: 'ext' }}>
        <Header>Extract</Header>
        <PlaceholderItem />
      </div>

      <div style={{ gridArea: 'mod' }}>
        <Header>Model</Header>
        <PlaceholderItem />
      </div>

      <div style={{ gridArea: 'ing' }}>
        <Header>Ingest</Header>
        <PlaceholderItem />
      </div>
    </Layout>
  );
};
