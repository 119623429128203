import isEmpty from 'lodash/isEmpty';
import { useCallback, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Form, Header } from 'semantic-ui-react';

import { LoginInput, useLoginMutation } from 'src/api/auth';
import { ApiMessageData, APIValidationError } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import theme from 'src/styles/theme';
import Circle from '../Circle';
import { Container, ContainerLeft, ContainerRight, GridCenter, Title } from '../styles';

const isDev = process.env.NODE_ENV === 'development';

type ValidationErrors = {
  email?: string;
  password?: string;
};

const AuthLogin = () => {
  const location = useLocation<{ redirect?: string }>();
  const history = useHistory();
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<LoginInput>({
    email: isDev ? 'admin@datascore.ai' : '',
    password: isDev ? 'admin' : '',
  });
  const { mutateAsync, isLoading } = useLoginMutation();

  const validate = useCallback((input: LoginInput): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    if (input.email === '') {
      validationErrors.email = 'Email is required';
    }
    if (input.password === '') {
      validationErrors.password = 'Password is required';
    }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onSubmit = async () => {
    setApiMessage(undefined);

    if (!isEmpty(validate(formdata))) {
      return;
    }

    try {
      const submitData = {
        ...formdata,
        email: formdata.email.toLowerCase().trim(),
      };

      setFormdata(submitData);

      await mutateAsync(submitData);

      history.push(location.state?.redirect || '/');
    } catch (e: any) {
      // apiErrorHandler(e, setApiError);

      if (e.response && e.response.data) {
        if (e.response.data.message) {
          setApiMessage({ success: false, status: e.response.status, message: e.response.data.message });
        }

        if (e.response.data.errors) {
          const { errors } = e.response.data;

          const validationErrors: ValidationErrors = {};

          errors.forEach(({ field, error }: APIValidationError) => {
            switch (field.toLowerCase()) {
              case 'email':
                validationErrors.email = error;
                break;

              case 'password':
                validationErrors.password = error;
                break;
            }
          });

          setErrors(validationErrors);
        }
      }
    }
  };

  const onChange = useCallback((_, { name, value }) => setFormdata(prev => ({ ...prev, [name]: value })), []);

  return (
    <GridCenter>
      <Container>
        <ContainerLeft>
          <Header as="h2">
            <span style={{ fontSize: '0.9em', marginRight: '0.4rem' }}>👋</span> Welcome Back!
          </Header>
          <p style={{ color: theme.gray }}>Please enter your details.</p>

          <ApiMessage data={apiMessage} />

          <Form onSubmit={onSubmit}>
            <Form.Input label="Email" name="email" value={formdata.email} onChange={onChange} error={errors.email} />

            <Form.Input
              label="Password"
              type="password"
              name="password"
              value={formdata.password}
              onChange={onChange}
              error={errors.password}
            />

            <Button fluid color="blue" loading={isLoading}>
              Login
            </Button>

            <p style={{ margin: '1rem 0 0', textAlign: 'center' }}>
              <Link to={{ pathname: '/auth/forgot-password', state: location.state }}>Forgot Password?</Link>
            </p>
          </Form>
        </ContainerLeft>

        <ContainerRight>
          <Title to="/">datascore</Title>
          <Circle />
        </ContainerRight>
      </Container>
    </GridCenter>
  );
};

export default AuthLogin;
