import { Table } from 'semantic-ui-react';

import { Account } from 'src/types';
import AdminAccountSetModelCostForm from './AdminAccountSetModelCostForm';
import RemoveModel from './RemoveModel';

type Props = {
  account: Account;
};

const AdminAccountModelsListBody = ({ account }: Props) => {
  if (account.model_access === null) return null;

  return (
    <>
      {account.model_access
        .filter(ma => Boolean(ma.model))
        .map(ma => {
          if (!ma.model) return null;
          return (
            <Table.Row key={ma.model.id}>
              <Table.Cell>{ma.model.display_name}</Table.Cell>
              <Table.Cell>
                <AdminAccountSetModelCostForm modelAccess={ma} />
              </Table.Cell>
              <Table.Cell textAlign="right" collapsing>
                <RemoveModel accountId={ma.account_id} modelId={ma.model.id} />
              </Table.Cell>
            </Table.Row>
          );
        })}
    </>
  );
};

export default AdminAccountModelsListBody;
