import { isEmpty } from 'lodash';
import { ChangeEvent, useCallback, useState } from 'react';
import { Button, Form, Icon, InputOnChangeData, Modal } from 'semantic-ui-react';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useSaveScheduleMutation } from 'src/api/schedules';
import ApiMessage from 'src/components/ApiMessage';
import { Schedule } from 'src/types';
import ScheduleTableForm from '../../models/schedule/ScheduleTableForm';

type ValidationErrors = {
  name?: string;
};

const EditSchedule = ({ schedule }: { schedule: Schedule }) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { mutateAsync, isLoading } = useSaveScheduleMutation();
  const [formdata, setFormdata] = useState<Schedule>(() => schedule);

  const onChange = useCallback((_: ChangeEvent<HTMLInputElement>, { name, value }: InputOnChangeData) => {
    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
    setErrors({});
  }, []);

  const onSubmit = async () => {
    setApiMessage(undefined);

    const validationErrors: ValidationErrors = {};

    if (formdata.name === '') {
      validationErrors.name = 'Name is required';
    }

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors);
      return;
    }

    try {
      await mutateAsync({ schedule: formdata });
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Modal
      // size="large"
      open={open}
      onClose={onClose}
      onOpen={() => {
        setOpen(true);
        setFormdata(schedule);
      }}
      trigger={
        <Button floated="right" color="blue" icon>
          <Icon name="pencil" />
        </Button>
      }
    >
      <Modal.Header>Edit Schedule</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Form.Input label="Name" error={errors.name} name="name" onChange={onChange} value={formdata.name} />

          <ScheduleTableForm schedule={formdata} setSchedule={setFormdata} />

          <Form.Button loading={isLoading} color="blue" fluid>
            Save
          </Form.Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default EditSchedule;
