import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Icon, Segment } from 'semantic-ui-react';

import { useGetLeadscoreConfigQuery } from 'src/api/admin/leadscore-plus';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader, Row } from 'src/styles';
import theme from 'src/styles/theme';
import EditLeadscoreConfigForm from './AdminEditLeadscoreConfigForm';
import { PlaceholderLayout } from './styles';

const AdminEditLeadscoreConfig = () => {
  const { id } = useParams<{ id: string }>();
  const [isFormValid, setIsFormValid] = useState(false);
  const { goBack } = useHistory();
  const { data: config, isLoading } = useGetLeadscoreConfigQuery(id);

  return (
    <Container>
      <Helmet>
        <title>Edit Config - LeadScore+ | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Row style={{ alignItems: 'center' }}>
          <Button icon color="blue" onClick={goBack} style={{ marginRight: '1rem' }}>
            <Icon name="arrow left" />
          </Button>
          <PageHeader style={{ margin: 0 }}>
            <span style={{ color: theme.gray }}>Admin</span> <span style={{ padding: '0 0.5rem' }}>/</span>{' '}
            <span style={{ color: theme.gray }}>LeadScore+</span> <span style={{ padding: '0 0.5rem' }}>/</span>{' '}
            <span style={{ color: theme.gray }}>Configs</span> <span style={{ padding: '0 0.5rem' }}>/</span> Edit
          </PageHeader>
        </Row>
      </Row>

      <Segment>
        {isLoading ? (
          <PlaceholderLayout />
        ) : typeof config !== 'undefined' ? (
          <EditLeadscoreConfigForm config={config} isValid={isFormValid} setIsValid={setIsFormValid} />
        ) : (
          <NotFound />
        )}
      </Segment>
    </Container>
  );
};

export default AdminEditLeadscoreConfig;
