import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Icon, Segment } from 'semantic-ui-react';

import { useGetQualifaiConversationQuery } from 'src/api/qualifai-conversations';
import Breadcrumbs from 'src/components/Breadcrumbs';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader, Row } from 'src/styles';
import EditQualifaiConversationForm from './EditQualifaiConversationForm';
import { PlaceholderLayout } from './style';

const VoiceConversationEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { goBack } = useHistory();
  const { data: conversation, isLoading } = useGetQualifaiConversationQuery(id);

  return (
    <Container>
      <Helmet>
        <title>Edit Voice Conversation | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center' }}>
        <Button icon color="blue" onClick={goBack} style={{ marginRight: '1rem' }}>
          <Icon name="arrow left" />
        </Button>

        <PageHeader style={{ margin: 0 }}>
          <Breadcrumbs crumbs={['Voice', 'Conversations', 'Edit']} />
        </PageHeader>
      </Row>

      <Segment>
        {isLoading ? (
          <PlaceholderLayout />
        ) : typeof conversation !== 'undefined' ? (
          <EditQualifaiConversationForm conversation={conversation} />
        ) : (
          <NotFound />
        )}
      </Segment>
    </Container>
  );
};

export default VoiceConversationEdit;
