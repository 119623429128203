import { useCallback, useMemo, useState } from 'react';
import { DropdownItemProps, Form, Grid, Header, Icon, Table } from 'semantic-ui-react';

import {
  useGetBandwidthAvailablePhonesMutation,
  useProvisionBandwidthAvailablePhonesMutation,
} from 'src/api/auth/account-bandwidth';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useListVoiceConfigsQuery } from 'src/api/voice-configs';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { BandwidthAvailablePhone, BandwidthAvailablePhoneSearchType } from 'src/types';
import BandwidthSearchByPostalCodes from './BandwidthSearchByPostalCodes';

const headers = ['Phone Number', 'Friendly Name', 'Description', ''];

const LimitOptions = ['1', '5', '10', '25', '50', '100'].map(l => ({ key: l, text: l, value: l }));

const SearchTypeOptions: DropdownItemProps[] = [
  {
    key: BandwidthAvailablePhoneSearchType['area-code'],
    text: 'Area Code',
    value: BandwidthAvailablePhoneSearchType['area-code'],
  },
  {
    key: BandwidthAvailablePhoneSearchType['locality'],
    text: 'Locality',
    value: BandwidthAvailablePhoneSearchType['locality'],
  },
];

const BandwidthBuyNumbers = () => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [searchType, setSearchType] = useState<BandwidthAvailablePhoneSearchType>(
    SearchTypeOptions[0].value as BandwidthAvailablePhoneSearchType
  );
  const [selectedVoiceConfigID, setSelectedVoiceConfigID] = useState('');
  const [phones, setPhones] = useState<BandwidthAvailablePhone[]>([]);
  const { mutateAsync: searchPhones, isLoading: searchLoading } = useGetBandwidthAvailablePhonesMutation();
  const { mutateAsync: provisionPhones, isLoading: provisionLoading } = useProvisionBandwidthAvailablePhonesMutation();
  const { data: voiceConfigs, isLoading: voiceConfigsLoading } = useListVoiceConfigsQuery({ limit: 100, offset: 0 });

  const voiceConfigOptions = useMemo<DropdownItemProps[]>(() => {
    if (!voiceConfigs?.data) {
      return [];
    }

    return voiceConfigs?.data.map(c => ({
      key: c.id,
      text: c.name,
      value: c.id,
      description: 'Voice Config',
    }));
  }, [voiceConfigs?.data]);

  const onLimitChange = useCallback((_, { value }) => {
    setLimit(Number(value));
  }, []);

  const onSubmitSearch = async () => {
    setApiMessage(undefined);

    try {
      const data = await searchPhones({ limit, search, searchType });
      if (!data.phones.length) {
        const apiError = {
          response: {
            success: false,
            status: 400,
            data: { message: 'No phones found matching that search criteria' },
          },
        };
        throw apiError;
      }
      setPhones(data.phones);
    } catch (e: any) {
      setPhones([]);
      apiErrorHandler(e, setApiMessage);
    }
  };

  const onSubmitBuy = async () => {
    setApiMessage(undefined);

    try {
      if (selectedVoiceConfigID === '') {
        const apiError = {
          response: {
            success: false,
            status: 400,
            data: { message: 'Voice Config ID is required' },
          },
        };
        throw apiError;
      }

      const provisionPhoneParams = {
        phoneNumbers: phones.map(p => p.phone_number),
        voiceConfigID: selectedVoiceConfigID,
      };

      await provisionPhones(provisionPhoneParams);
      setPhones([]);
      setSearch('');
      setSelectedVoiceConfigID('');
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  let searchPlaceholder = '';
  switch (searchType) {
    case BandwidthAvailablePhoneSearchType['area-code']:
      searchPlaceholder = 'digits';
      break;
    case BandwidthAvailablePhoneSearchType.locality:
      searchPlaceholder = 'city, state';
      break;
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={4}>
          <Header>Buy Numbers</Header>
        </Grid.Column>

        <Grid.Column width={12}>
          <ApiMessage data={apiMessage} />

          <div style={{ display: 'flex' }}>
            <Form onSubmit={onSubmitSearch}>
              <Form.Group>
                <Form.Select
                  options={SearchTypeOptions}
                  value={searchType}
                  name="searchType"
                  onChange={(_, { value }) => setSearchType(value as BandwidthAvailablePhoneSearchType)}
                />
                <Form.Input
                  placeholder={searchPlaceholder}
                  value={search}
                  onChange={(_, { value }) => setSearch(String(value))}
                />
                <Form.Select options={LimitOptions} value={String(limit)} onChange={onLimitChange} compact />
                <Form.Button color="blue" icon loading={searchLoading} style={{ margin: 0 }}>
                  <Icon name="search" />
                </Form.Button>
              </Form.Group>
            </Form>

            <div style={{ display: 'flex', alignItems: 'center', margin: '0 1rem 1rem' }}>
              <span style={{ color: '#bbb' }}> OR </span>
            </div>

            <div style={{ margin: '0 0 1rem' }}>
              <BandwidthSearchByPostalCodes />
            </div>
          </div>

          {phones.length > 0 && (
            <>
              <Form onSubmit={onSubmitBuy}>
                <Form.Group>
                  <Form.Select
                    placeholder="Select voice config to assign new numbers to"
                    loading={voiceConfigsLoading}
                    clearable
                    options={voiceConfigOptions}
                    value={selectedVoiceConfigID}
                    onChange={(_, { value }) => setSelectedVoiceConfigID(String(value))}
                  />
                  <Form.Button color="green" loading={provisionLoading}>
                    Buy All
                  </Form.Button>
                </Form.Group>
              </Form>

              <Table padded>
                <Table.Header>
                  <Table.Row>
                    {headers.map((h, i) => (
                      <Table.HeaderCell key={`${i}-${h}`}>{h}</Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>

                <Table.Body style={{ position: 'relative' }}>
                  {phones.map(p => (
                    <Table.Row key={p.phone_number}>
                      <Table.Cell>{p.phone_number}</Table.Cell>
                      <Table.Cell>{p.friendly_name}</Table.Cell>
                      <Table.Cell>{p.description}</Table.Cell>
                      <Table.Cell textAlign="right" collapsing>
                        <Row style={{ justifyContent: 'flex-end' }}></Row>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default BandwidthBuyNumbers;
