import { Form } from 'semantic-ui-react';

import { BigqueryDataset } from 'src/types';

type Props = {
  dataset: BigqueryDataset;
};

const AdminBigqueryDatasetName = ({ dataset }: Props) => {
  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Input readOnly label="Name" name="name" value={dataset.name} disabled />
        <Form.Input readOnly label="Dataset ID" value={dataset.dataset_id} disabled />
      </Form.Group>
    </Form>
  );
};

export default AdminBigqueryDatasetName;
