import { Icon } from 'semantic-ui-react';

import { useDatasetListRowCountQuery } from 'src/api/datasets';

type Props = {
  listId: string;
  datasetId: string;
};

const DatasetV2ListsRowCount = ({ listId, datasetId }: Props) => {
  const { data, isLoading } = useDatasetListRowCountQuery({ listId, datasetId });

  if (isLoading) {
    return <Icon loading name="spinner" />;
  }

  return <>{data?.count || 0}</>;
};

export default DatasetV2ListsRowCount;
