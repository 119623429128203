import { useState } from 'react';
import { Form, InputOnChangeData, Table } from 'semantic-ui-react';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useSaveVoiceConfigMutation } from 'src/api/voice-configs';
import { VoiceConfig } from 'src/types';

type Props = {
  config: VoiceConfig;
  defaultRate?: number;
  defaultType?: string;
};

const AdminAccountBillingVoiceConfigForm = ({ config, defaultRate }: Props) => {
  const { isLoading, mutateAsync } = useSaveVoiceConfigMutation();
  const [billingRate, setBillingRate] = useState<string>(String(config.billing_rate || ''));
  const [error, setError] = useState<ApiMessageData>();

  const onChangeBillingRate = (_e: React.ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData): void => {
    if (!Number.isNaN(Number(value))) {
      setBillingRate(value);
    }
  };

  const onSubmit = async () => {
    setError(undefined);
    try {
      config.billing_rate = Number(billingRate) || 0;
      await mutateAsync({ config });
    } catch (e: any) {
      apiErrorHandler(e, setError);
    }
  };

  return (
    <Table.Row key={config.id}>
      <Table.Cell>{config.name}</Table.Cell>
      <Table.Cell>
        <Form onSubmit={onSubmit}>
          <Form.Input
            width={4}
            placeholder={defaultRate || 'Use account default'}
            onChange={onChangeBillingRate}
            value={billingRate}
            loading={isLoading}
            onBlur={onSubmit}
            error={error && `${error.status} ${error.message}`}
          />
        </Form>
      </Table.Cell>
    </Table.Row>
  );
};

export default AdminAccountBillingVoiceConfigForm;
