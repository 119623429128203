import { capitalize, isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Button, Divider, DropdownProps, Form, Icon, Input, InputOnChangeData, Label, Loader } from 'semantic-ui-react';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useGetBusinessProfileQuery, useUpdateBusinessProfileMutation } from 'src/api/onboarding';
import ApiMessage from 'src/components/ApiMessage';
import { UseCaseOptions } from 'src/config';
import { BusinessProfile, BusinessProfileContact } from 'src/types';
import { StepProps } from './types';

const BUSINESS_TYPES = [
  'Sole Proprietorship',
  'Partnership',
  'Limited Liability Corporation',
  'Co-operative',
  'Non-profit Corporation',
  'Corporation',
];

const BUSINESS_INDUSTRIES = [
  'AUTOMOTIVE',
  'AGRICULTURE',
  'BANKING',
  'CONSUMER',
  'EDUCATION',
  'ENGINEERING',
  'ENERGY',
  'OIL_AND_GAS',
  'FAST_MOVING_CONSUMER_GOODS',
  'FINANCIAL',
  'FINTECH',
  'FOOD_AND_BEVERAGE',
  'GOVERNMENT',
  'HEALTHCARE',
  'HOSPITALITY',
  'INSURANCE',
  'LEGAL',
  'MANUFACTURING',
  'MEDIA',
  'ONLINE',
  'RAW_MATERIALS',
  'REAL_ESTATE',
  'RELIGION',
  'RETAIL',
  'JEWELRY',
  'TECHNOLOGY',
  'TELECOMMUNICATIONS',
  'TRANSPORTATION',
  'TRAVEL',
  'ELECTRONICS',
  'NOT_FOR_PROFIT',
];

const JOB_POSITIONS = ['Director', 'GM', 'VP', 'CEO', 'CFO', 'General Counsel', 'Other'];

const httpPrefix = /^https?:\/\//;

type ValidationErrors = {
  [key: string]: string | ValidationErrors | undefined;
  ein_number?: string;
  business_name?: string;
  business_type?: string;
  business_industry?: string;
  website_url?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  zip?: string;
  primary_contact?: {
    first_name?: string;
    last_name?: string;
    title?: string;
    job_position?: string;
    phone?: string;
    email?: string;
  };
  secondary_contact?: {
    first_name?: string;
    last_name?: string;
    title?: string;
    job_position?: string;
    phone?: string;
    email?: string;
  };
  // CNAM Registration
  cnam_display_name?: string;
  // Voice Integrity Trust Product
  avg_daily_calls?: string;
  employee_count?: string;
  use_case?: string;
  notes?: string;
};

const validateRequired = (obj: BusinessProfile | BusinessProfileContact, omit?: string[]): ValidationErrors => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (typeof value === 'object' && value !== null) {
      const errors = validateRequired(value, omit);
      if (isEmpty(errors)) return acc;

      return { ...acc, [key]: errors };
    }

    const shouldIgnore = omit && omit.includes(key);

    if (!value && !shouldIgnore) {
      acc[key] = 'This field is required';
    }

    return acc;
  }, {} as ValidationErrors);
};

const StepBusinessProfile = ({ onBack, onContinue }: StepProps) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { data, isLoading } = useGetBusinessProfileQuery();
  const { mutateAsync: updateProfile, isLoading: isUpdateLoading } = useUpdateBusinessProfileMutation();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [businessProfile, setBusinessProfile] = useState<
    BusinessProfile & { [key: string]: string | number | BusinessProfileContact | null }
  >({
    primary_contact: {} as BusinessProfileContact,
    secondary_contact: {} as BusinessProfileContact,
  } as BusinessProfile);

  useEffect(() => {
    if (!data?.business_profile) return;

    if (data.business_profile.website_url && httpPrefix.test(data.business_profile.website_url)) {
      data.business_profile.website_url = data.business_profile.website_url.replace(httpPrefix, '');
    }

    setBusinessProfile(data.business_profile);
  }, [data?.business_profile]);

  const onSubmit = useCallback(
    async (force = false) => {
      setApiMessage(undefined);

      const validationErrors = validateRequired(businessProfile, ['address_2', 'notes']);

      ['avg_daily_calls', 'employee_count'].forEach(key => {
        if (businessProfile[key] && Number.isNaN(Number(businessProfile[key]))) {
          validationErrors[key] = 'Must be a number';
        } else if (businessProfile[key] && Number(businessProfile[key]) <= 0) {
          validationErrors[key] = 'Must be greater than 0';
        }
      });

      setErrors(validationErrors);

      if (!isEmpty(validationErrors) && !force) {
        setApiMessage({ success: false, status: 400, message: 'Please check for validation errors above.' });
        return;
      }

      try {
        await updateProfile({
          ...businessProfile,
          website_url: 'https://' + businessProfile.website_url,
          avg_daily_calls: Number(businessProfile.avg_daily_calls),
          employee_count: Number(businessProfile.employee_count),
        });
      } catch (e) {
        apiErrorHandler(e, setApiMessage);
        return;
      }

      onContinue();
    },
    [businessProfile, onContinue, updateProfile]
  );

  const onChange = useCallback((_, { name, value, checked }) => {
    setBusinessProfile(prev => ({ ...prev, [name]: checked ?? value }));
  }, []);

  const onChangeProfile = useCallback(
    (key: string) =>
      (_: any, { name, value, checked }: InputOnChangeData | DropdownProps) =>
        setBusinessProfile(prev => {
          if (key !== 'primary_contact' && key !== 'secondary_contact') return prev;

          return {
            ...prev,
            [key]: {
              ...prev[key],
              [name]: checked ?? value,
            },
          };
        }),
    []
  );

  if (isLoading) return <Loader active />;

  return (
    <div style={{ maxWidth: '800px' }}>
      <h1>Business Profile</h1>

      <p>Please provide the following details about your business.</p>

      <Form onSubmit={() => onSubmit()}>
        <Form.Group>
          <Form.Input
            width="5"
            name="ein_number"
            label="EIN #"
            error={errors.ein_number}
            value={businessProfile.ein_number}
            onChange={onChange}
          />
          <Form.Input
            width="11"
            name="business_name"
            label="Business Name"
            error={errors.business_name}
            value={businessProfile.business_name}
            onChange={onChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Select
            width="5"
            fluid
            name="business_type"
            label="Business Type"
            error={errors.business_type}
            value={businessProfile.business_type}
            onChange={onChange}
            options={BUSINESS_TYPES.map(opt => ({
              key: opt,
              value: opt,
              text: opt,
            }))}
          />
          <Form.Select
            width="5"
            fluid
            name="business_industry"
            label="Business Industry"
            error={errors.business_industry}
            value={businessProfile.business_industry}
            onChange={onChange}
            options={BUSINESS_INDUSTRIES.map(opt => ({
              key: opt,
              value: opt,
              text: capitalize(opt.replace(/_/g, ' ')),
            }))}
          />
          <Form.Field width="6" error={Boolean(errors.website_url)}>
            <label>Website URL</label>
            <Input
              label="https://"
              placeholder="example.com"
              name="website_url"
              value={(businessProfile.website_url || '').replace(httpPrefix, '')}
              onChange={onChange}
            />
            {errors.website_url && (
              <Label prompt pointing>
                {errors.website_url}
              </Label>
            )}
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Input
            width="10"
            name="address_1"
            label="Address Line 1"
            error={errors.address_1}
            value={businessProfile.address_1}
            onChange={onChange}
          />
          <Form.Input
            width="6"
            name="address_2"
            label="Address Line 2"
            error={errors.address_2}
            value={businessProfile.address_2 || ''}
            onChange={onChange}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input name="city" label="City" error={errors.city} value={businessProfile.city} onChange={onChange} />
          <Form.Input
            name="state"
            label="State"
            error={errors.state}
            value={businessProfile.state}
            onChange={onChange}
          />
          <Form.Input name="zip" label="Zip" error={errors.zip} value={businessProfile.zip} onChange={onChange} />
        </Form.Group>

        <h3>Business Contact</h3>

        <Form.Group widths="equal">
          <Form.Input
            name="first_name"
            label="First Name"
            error={errors.primary_contact?.first_name}
            value={businessProfile.primary_contact.first_name}
            onChange={onChangeProfile('primary_contact')}
          />
          <Form.Input
            name="last_name"
            label="Last Name"
            error={errors.primary_contact?.last_name}
            value={businessProfile.primary_contact.last_name}
            onChange={onChangeProfile('primary_contact')}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            name="title"
            label="Job Title"
            error={errors.primary_contact?.title}
            value={businessProfile.primary_contact.title}
            onChange={onChangeProfile('primary_contact')}
          />
          <Form.Select
            fluid
            name="job_position"
            label="Job Position"
            error={errors.primary_contact?.job_position}
            value={businessProfile.primary_contact.job_position}
            onChange={onChangeProfile('primary_contact')}
            options={JOB_POSITIONS.map(opt => ({
              key: opt,
              value: opt,
              text: opt,
            }))}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            name="phone"
            label="Phone"
            error={errors.primary_contact?.phone}
            value={businessProfile.primary_contact.phone}
            onChange={onChangeProfile('primary_contact')}
          />
          <Form.Input
            name="email"
            label="Email"
            error={errors.primary_contact?.email}
            value={businessProfile.primary_contact.email}
            onChange={onChangeProfile('primary_contact')}
          />
        </Form.Group>

        <h5>Secondary Contact</h5>

        <Form.Group widths="equal">
          <Form.Input
            name="first_name"
            label="First Name"
            error={errors.secondary_contact?.first_name}
            value={businessProfile.secondary_contact.first_name}
            onChange={onChangeProfile('secondary_contact')}
          />
          <Form.Input
            name="last_name"
            label="Last Name"
            error={errors.secondary_contact?.last_name}
            value={businessProfile.secondary_contact.last_name}
            onChange={onChangeProfile('secondary_contact')}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            name="title"
            label="Job Title"
            error={errors.secondary_contact?.title}
            value={businessProfile.secondary_contact.title || ''}
            onChange={onChangeProfile('secondary_contact')}
          />
          <Form.Select
            fluid
            name="job_position"
            label="Job Position"
            error={errors.secondary_contact?.job_position}
            value={businessProfile.secondary_contact.job_position}
            onChange={onChangeProfile('secondary_contact')}
            options={JOB_POSITIONS.map(opt => ({
              key: opt,
              value: opt,
              text: opt,
            }))}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            name="phone"
            label="Phone"
            error={errors.secondary_contact?.phone}
            value={businessProfile.secondary_contact.phone}
            onChange={onChangeProfile('secondary_contact')}
          />
          <Form.Input
            name="email"
            label="Email"
            error={errors.secondary_contact?.email}
            value={businessProfile.secondary_contact.email}
            onChange={onChangeProfile('secondary_contact')}
          />
        </Form.Group>

        <h3>Call Branding</h3>

        <Form.Input
          fluid
          name="cnam_display_name"
          label="Display Name"
          error={errors.cnam_display_name}
          value={businessProfile.cnam_display_name}
          onChange={onChange}
        />

        <h3>Voice Integrity</h3>

        <Form.Group widths="equal">
          <Form.Input
            fluid
            name="avg_daily_calls"
            label="Avg. Business Day Call Volume"
            error={errors.avg_daily_calls}
            value={String(businessProfile.avg_daily_calls || '')}
            onChange={onChange}
          />
          <Form.Input
            fluid
            name="employee_count"
            label="Employee Count"
            error={errors.employee_count}
            value={String(businessProfile.employee_count || '')}
            onChange={onChange}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Select
            fluid
            name="use_case"
            label="Use Case"
            error={errors.use_case}
            value={businessProfile.use_case}
            options={UseCaseOptions}
            onChange={onChange}
          />
          <Form.Input
            fluid
            name="notes"
            label="Notes"
            error={errors.notes}
            value={businessProfile.notes}
            onChange={onChange}
          />
        </Form.Group>

        <Divider />

        <ApiMessage data={apiMessage} />

        <Button type="button" basic floated="left" onClick={onBack}>
          <Icon name="chevron left" />
          Back
        </Button>

        <Button floated="right" color="blue" loading={isUpdateLoading}>
          Continue
          <Icon name="chevron right" />
        </Button>

        {!isEmpty(errors) && (
          <Button
            type="button"
            floated="right"
            color="blue"
            basic
            loading={isUpdateLoading}
            onClick={() => onSubmit(true)}
          >
            Save and Continue
            <Icon name="chevron right" />
          </Button>
        )}
      </Form>
    </div>
  );
};

export default StepBusinessProfile;
