const theme = {
  borderColor: '#ddd',
  black: '#0b0b0b',
  gray: '#999',
  lightBlue: '#f5f6fa',
  darkBlue: '#1b3756',
  primary: '#596af0',
  primaryHover: '#3f51b5',
  brand: '#3e34ca',
  borderRadius: {
    normal: '4px',
    large: '8px',
  },
  palette: {
    charts: {
      blue: '#2E48B4',
      aqua: '#008D8F',
      lightGreen: '#3EAD66',
      green: '#5E9603',
      yellow: '#F2B705',
      orange: '#D97904',
      red: '#D92B04',
      cherry: '#8C0E03',
      pink: '#991670',
      purple: '#6B1799',
    },
  },
};

export default theme;
