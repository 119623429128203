import styled from 'styled-components';

export const FlowHeaderContainer = styled.div`
  position: relative;
  z-index: 2;
  display: grid;
  grid-area: h;
  align-items: center;
  gap: 16px;
  grid-template-columns: 2fr 3fr 2fr;
  height: 50px;
  background-color: #191919;
  color: #dde2ff;
  padding: 0 1rem;
`;

export const FlowHeaderLeft = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const BackButton = styled.button`
  border: none;
  background: transparent;
  color: #dde2ff;
  cursor: pointer;
  padding: 0;
  width: auto;
  display: inline-block;
`;

export const FlowHeaderTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
  justify-self: center;
  cursor: pointer;
`;

export const FlowHeaderRight = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
`;
