import { Link } from 'react-router-dom';
import { Button, Message, Modal } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { accountConnectProBillingIsCurrent } from 'src/utils';

const BillingWarning = () => {
  const { data: user, isLoading } = useGetUserProfileQuery();

  if (isLoading) return null;

  if (!user || !user.active_account) return null;

  if (user.role === 'admin') return null;

  const a = user.active_account;

  const open = !accountConnectProBillingIsCurrent(a);

  return (
    <Modal open={open} size="tiny">
      <Modal.Header>Account Inactive</Modal.Header>
      <Modal.Content>
        <Message error>
          <Message.Header>ConnectPro Balance At or Below Zero</Message.Header>
          <Message.Content>Please add credits to your account to continue using this feature.</Message.Content>
        </Message>

        <Link to="/account/billing/settings">
          <Button type="button" color="blue" fluid>
            Go to Billing
          </Button>
        </Link>
      </Modal.Content>
    </Modal>
  );
};

export default BillingWarning;
