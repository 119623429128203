import { Table } from 'semantic-ui-react';

import { useGetTwilioStudioFlowsQuery } from 'src/api/auth/account-twilio';
import theme from 'src/styles/theme';
import FlushNonConnects from './FlushNonConnects';

const TwilioStudioFlows = () => {
  const { data } = useGetTwilioStudioFlowsQuery();

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data?.flows?.map(f => (
          <Table.Row key={f.sid}>
            <Table.Cell>
              <strong>{f.friendly_name}</strong>
              <span style={{ display: 'block', color: theme.gray, fontSize: '0.8rem', lineHeight: '1rem' }}>
                {f.sid}
              </span>
            </Table.Cell>
            <Table.Cell textAlign="right" collapsing>
              <FlushNonConnects flow={f} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default TwilioStudioFlows;
