import { useMutation, useQuery, useQueryClient } from 'react-query';

import { AkkioModel, AkkioModelConfig, LeadscoreConfig, PaginatedResponse } from 'src/types';
import { ApiResponse, http } from '../http-common';

export type LeadscoreConfigsResponse = ApiResponse & {
  configs: PaginatedResponse<LeadscoreConfig>;
};

export type LeadscoreConfigResponse = ApiResponse & {
  config?: LeadscoreConfig;
};

export type ListLeadscoreConfigsParams = {
  limit: number;
  offset: number;
  accountId?: number;
  enabled?: boolean;
};

export type ListLeadscoreConfigFilters = {
  accountId?: number;
  enabled?: boolean;
};

export type ListAkkioModelsParams = {
  limit: number;
  offset: number;
};

export const useListAkkioModelsQuery = (params: ListAkkioModelsParams) => {
  return useQuery(['admin', 'leadscore-plus', 'akkio-models', params], async () => {
    const res = await http.get<AkkioModelsResponse>('/api/admin/leadscore-plus/akkio-models', { params });
    return res.data.models;
  });
};

export const useListLeadscoreConfigsQuery = (params: ListLeadscoreConfigsParams) => {
  return useQuery(['admin', 'leadscore-plus', 'configs', params], async () => {
    const res = await http.get<LeadscoreConfigsResponse>('/api/admin/leadscore-plus/configs', { params });
    return res.data.configs;
  });
};

export const useGetLeadscoreConfigQuery = (id: string) => {
  return useQuery(['admin', 'leadscore-plus', 'configs', id], async () => {
    if (!id) return undefined;
    const res = await http.get<LeadscoreConfigResponse>(`/api/admin/leadscore-plus/configs/${id}`);
    return res.data.config;
  });
};

export type AddLeadscoreConfigParams = {
  name: string;
  accountId: number;
};

export const useAddLeadscoreConfigMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: AddLeadscoreConfigParams) => {
      const res = await http.post<LeadscoreConfigResponse>(`/api/admin/leadscore-plus/configs`, params);
      return res.data.config;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin', 'leadscore-plus', 'configs']);
      },
    }
  );
};

export type SaveLeadscoreConfigParams = {
  config: LeadscoreConfig;
};

export const useSaveLeadscoreConfigMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: SaveLeadscoreConfigParams) => {
      const res = await http.post<LeadscoreConfigResponse>(
        `/api/admin/leadscore-plus/configs/${params.config.id}`,
        params.config
      );
      return res.data.config;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin', 'leadscore-plus', 'configs']);
      },
    }
  );
};

export type DuplicateLeadscoreInput = {
  leadscoreId: string;
  accountId: number;
  name: string;
};

export const useDuplicateLeadscoreConfigMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (duplicateLeadScoreInput: DuplicateLeadscoreInput) => {
      const res = await http.post<ApiResponse>(
        `/api/admin/leadscore-plus/configs/${duplicateLeadScoreInput.leadscoreId}/duplicate`,
        duplicateLeadScoreInput
      );
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin', 'leadscore-plus', 'configs']);
      },
    }
  );
};

export const useDeleteLeadscoreConfigMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      const res = await http.delete<ApiResponse>(`/api/admin/leadscore-plus/configs/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin', 'leadscore-plus', 'configs']);
      },
    }
  );
};

export type AkkioModelsResponse = ApiResponse & {
  models: PaginatedResponse<AkkioModel>;
};

export type AkkioModelResponse = ApiResponse & {
  model: AkkioModel;
};

export const useListAllAkkioModelsQuery = (params: ListAkkioModelsParams) => {
  return useQuery(['admin/leadscore-plus', 'akkio-models', params], async () => {
    const res = await http.get<AkkioModelsResponse>(`/api/admin/leadscore-plus/akkio-models`, { params });
    return res.data.models;
  });
};

export const useGetAkkioModelQuery = (id: number) => {
  return useQuery(['admin/leadscore-plus', 'akkio-models', id], async () => {
    const res = await http.get<AkkioModelResponse>(`/api/admin/leadscore-plus/akkio-models/${id}`);
    return res.data.model;
  });
};

export type AddAkkioModelInput = {
  name?: string;
};

export const useAddAkkioModelMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: AddAkkioModelInput) => {
      const res = await http.post<AkkioModelResponse>(`/api/admin/leadscore-plus/akkio-models`, input);
      return res.data.model;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/leadscore-plus', 'akkio-models']);
      },
    }
  );
};

export const useDeleteAkkioModelMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await http.delete<AkkioModelResponse>(`/api/admin/leadscore-plus/akkio-models/${id}`);
      return res.data.model;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/leadscore-plus', 'akkio-models']);
      },
    }
  );
};

type UpdateAkkioModelInput = {
  id: number;
  enabled?: boolean;
  name?: string;
  config?: AkkioModelConfig;
};

export const useUpdateAkkioModelMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, ...input }: UpdateAkkioModelInput) => {
      const res = await http.post<AkkioModelResponse>(`/api/admin/leadscore-plus/akkio-models/${id}`, input);
      return res.data.model;
    },
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries(['admin/leadscore-plus', 'akkio-models']);
      },
    }
  );
};

export const useDuplicateAkkioModelMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await http.post<ApiResponse>(`/api/admin/leadscore-plus/akkio-models/${id}/duplicate`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/leadscore-plus', 'akkio-models']);
      },
    }
  );
};
