import { useQuery } from 'react-query';

import { FeedsResponse } from '../feeds';
import { http } from '../http-common';

export type ListFeedsParams = {
  limit: number;
  offset: number;
  accountId?: number;
};

export const useListFeedsQuery = (params: ListFeedsParams) => {
  return useQuery(['admin/feeds', params], async () => {
    const res = await http.get<FeedsResponse>(`/api/admin/feeds`, { params });
    return res.data.feeds;
  });
};
