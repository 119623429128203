import { ColumnDef } from '@tanstack/react-table';
import { LocationDescriptor } from 'history';
import React, { useCallback, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { AgentAttributionData, useGetAgentAttributionDataQuery } from 'src/api/reporting';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { FilterType } from '.';
import { sortFunc, TableDefinition } from './TableDefinition';

const ReportingAgentAttributionColumns = ['label', 'calls', 'sales', 'total', 'conv', 'avg'] as const;

type ReportingAgentAttributionColumn = typeof ReportingAgentAttributionColumns[number];

const groupLabel = (view: string) => {
  switch (view) {
    case 'agent':
      return 'Agent Name';
    case 'campaign':
      return 'Campaign Name';
    case 'created':
      return 'Created Date';
    default:
      return 'Row Labels';
  }
};

const href = (params: {
  startDate: string;
  endDate: string;
  byCreatedDate: boolean;
  agent: string;
  campaign: string;
  created: string;
  sales: boolean;
}): LocationDescriptor => {
  const searchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    searchParams.append(key, value.toString());
  }

  return {
    pathname: `/reporting/agent-attribution/details`,
    search: searchParams.toString(),
  };
};

type Props = {
  filters: FilterType;
};

const AgentAttributionTable = ({ filters }: Props) => {
  const { replace } = useHistory();
  const query = useSearchQuery();
  const [column, setColumn] = useState<ReportingAgentAttributionColumn>(
    (query.get('column') ?? 'label') as ReportingAgentAttributionColumn
  );
  const [direction, setDirection] = useState<'ascending' | 'descending'>(
    (query.get('direction') ?? 'ascending') as 'ascending' | 'descending'
  );

  const { data: AgentAttributionDataResponse, isLoading: isDataLoading } =
    useGetAgentAttributionDataQuery<AgentAttributionData>(filters);

  const updateSort = useCallback(
    (col: ReportingAgentAttributionColumn) => () => {
      const newDirection = column === col ? (direction === 'ascending' ? 'descending' : 'ascending') : 'ascending';
      setDirection(newDirection);
      setColumn(col);

      // update query params
      query.set('column', col);
      query.set('direction', newDirection);
      replace({ search: query.toString() });
    },
    [column, direction, query, replace]
  );

  const data = useMemo(() => {
    return AgentAttributionDataResponse?.data || [];
  }, [AgentAttributionDataResponse?.data]);

  const summary = useMemo(() => {
    const calls = data.reduce((sum, item) => sum + item.calls, 0);
    const sales = data.reduce((sum, item) => sum + item.sales, 0);
    const total = data.reduce((sum, item) => sum + item.total, 0);

    return {
      calls,
      sales,
      total,
      conv: calls ? sales / calls : 0,
      avg: sales ? total / sales : 0,
    };
  }, [data]);

  const sortedData = useMemo(() => data.sort(sortFunc(column, direction)), [data, column, direction]);

  const columns = useMemo<ColumnDef<AgentAttributionData>[]>(() => {
    const cols: ColumnDef<AgentAttributionData>[] = [];
    cols.push({
      id: 'label',
      header: () => {
        return <strong onClick={updateSort('label')}>{groupLabel(filters.view)}</strong>;
      },
      accessorFn: row => row.label,
      cell: ({ getValue }) => getValue(),
      footer: () => {
        return (
          <div style={{ textAlign: 'right' }}>
            <strong>Grand Total</strong>
          </div>
        );
      },
      sortingFn: 'alphanumeric',
      sortDescFirst: true,
    });
    cols.push({
      id: 'calls',
      header: () => {
        return (
          <strong onClick={updateSort('calls')} style={{ textAlign: 'right' }}>
            Calls
          </strong>
        );
      },
      accessorFn: row => row.calls,
      cell: ({ getValue, row }) => {
        return (
          <div className="pointer" style={{ textAlign: 'right' }}>
            <Link
              to={href({
                startDate: filters.startDate,
                endDate: filters.endDate,
                byCreatedDate: filters.byCreatedDate,
                sales: false,
                agent: filters.view === 'agent' ? row.original.label : '',
                campaign: filters.view === 'campaign' ? row.original.label : '',
                created: filters.view === 'created' ? row.original.label : '',
              })}
            >
              {(getValue() as number).toLocaleString()}
            </Link>
          </div>
        );
      },
      footer: () => {
        return (
          <div className="pointer" style={{ textAlign: 'right' }}>
            <strong>
              <Link
                to={href({
                  startDate: filters.startDate,
                  endDate: filters.endDate,
                  byCreatedDate: filters.byCreatedDate,
                  sales: false,
                  agent: '',
                  campaign: '',
                  created: '',
                })}
              >
                {summary.calls}
              </Link>
            </strong>
          </div>
        );
      },
      sortingFn: 'alphanumeric',
    });
    cols.push({
      id: 'sales',
      header: () => {
        return (
          <strong onClick={updateSort('sales')} style={{ textAlign: 'right' }}>
            Sales
          </strong>
        );
      },
      accessorFn: row => row.sales,
      cell: ({ getValue, row }) => {
        return (
          <div className="pointer" style={{ textAlign: 'right' }}>
            <Link
              to={href({
                startDate: filters.startDate,
                endDate: filters.endDate,
                byCreatedDate: filters.byCreatedDate,
                sales: true,
                agent: filters.view === 'agent' ? row.original.label : '',
                campaign: filters.view === 'campaign' ? row.original.label : '',
                created: filters.view === 'created' ? row.original.label : '',
              })}
            >
              {(getValue() as number).toLocaleString()}
            </Link>
          </div>
        );
      },
      footer: () => {
        return (
          <div className="pointer" style={{ textAlign: 'right' }}>
            <strong>
              <Link
                to={href({
                  startDate: filters.startDate,
                  endDate: filters.endDate,
                  byCreatedDate: filters.byCreatedDate,
                  sales: true,
                  agent: '',
                  campaign: '',
                  created: '',
                })}
              >
                {summary.sales}
              </Link>
            </strong>
          </div>
        );
      },
      sortingFn: 'alphanumeric',
    });
    cols.push({
      id: 'total',
      header: () => {
        return (
          <strong onClick={updateSort('total')} style={{ textAlign: 'right' }}>
            Total $
          </strong>
        );
      },
      accessorFn: row => row.total,
      cell: ({ getValue }) => {
        return (
          <div style={{ textAlign: 'right' }}>
            {(getValue() as number).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
          </div>
        );
      },
      footer: () => {
        return (
          <div style={{ textAlign: 'right' }}>
            <strong>
              ${summary.total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </strong>
          </div>
        );
      },
      sortingFn: 'alphanumeric',
    });
    cols.push({
      id: 'conv',
      header: () => {
        return <strong onClick={updateSort('conv')}>Conv %</strong>;
      },
      accessorFn: row => row.conv,
      cell: ({ getValue }) => {
        return <div style={{ textAlign: 'right' }}>{((getValue() as number) * 100).toFixed(1)}%</div>;
      },
      footer: () => {
        return (
          <div style={{ textAlign: 'right' }}>
            <strong>{(summary.conv * 100).toFixed(1)}%</strong>
          </div>
        );
      },
      sortingFn: 'alphanumeric',
    });
    cols.push({
      id: 'avg',
      header: () => {
        return <strong onClick={updateSort('avg')}>Avg $</strong>;
      },
      accessorFn: row => row.avg,
      cell: ({ getValue }) => {
        return (
          <div style={{ textAlign: 'right' }}>
            ${(getValue() as number).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </div>
        );
      },
      footer: () => {
        return (
          <div style={{ textAlign: 'right' }}>
            <strong>
              ${summary.avg.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </strong>
          </div>
        );
      },
      sortingFn: 'alphanumeric',
    });
    return cols;
  }, [filters, summary, updateSort]);

  return (
    <TableDefinition columns={columns} sortedData={sortedData} sortingLabel={'label'} isDataLoading={isDataLoading} />
  );
};

export default AgentAttributionTable;
