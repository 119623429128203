import { useCallback, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';

import { useSubmitBusinessProfileToTwilioMutation } from 'src/api/admin/account-twilio';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { Account } from 'src/types';

type Props = {
  account: Account;
};

const SubmitOnboardingToTwilioModal = ({ account }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { isLoading, mutateAsync } = useSubmitBusinessProfileToTwilioMutation();

  const onboardingIncomplete = account.onboarding_completed_at === null;

  const onClose = () => {
    setApiMessage(undefined);
    setOpen(false);
  };

  const onSubmit = useCallback(async (): Promise<void> => {
    setApiMessage(undefined);

    try {
      await mutateAsync(account.id);
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [mutateAsync, account.id]);

  return (
    <Modal
      size="tiny"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={onClose}
      trigger={
        <Button type="button" color="blue" disabled={onboardingIncomplete}>
          Submit to Twilio
        </Button>
      }
    >
      <Modal.Header>Submit Business Profile to Twilio</Modal.Header>
      <Modal.Content>
        <p>
          This will verify the business profile through Twilio's Trust Hub and link the associated entities to this
          Account.
        </p>

        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Row>
            <Button fluid type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button loading={isLoading} color="blue" fluid>
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default SubmitOnboardingToTwilioModal;
