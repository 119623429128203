import { Helmet } from 'react-helmet-async';

import Breadcrumbs from 'src/components/Breadcrumbs';
import PaginatedTable from 'src/components/PaginatedTable';
import { Container, Header as PageHeader, Row } from 'src/styles';
import AccountVoiceRateLimitForm from './AccountVoiceRateLimitForm';
import AddVoiceConfig from './AddVoiceConfig';
import ListVoiceConfigsBody from './ListVoiceConfigsBody';

const VoiceConfigsList = () => {
  return (
    <Container>
      <Helmet>
        <title>Voice Configs | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
        <PageHeader style={{ margin: 0 }}>
          <Breadcrumbs crumbs={['Voice', 'Configs']} />
        </PageHeader>

        <AddVoiceConfig />
      </Row>

      <AccountVoiceRateLimitForm />

      <PaginatedTable
        headers={['Status', 'Name', 'Outbound Calls (per minute)', '']}
        renderBody={ListVoiceConfigsBody}
      />
    </Container>
  );
};

export default VoiceConfigsList;
