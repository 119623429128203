import { Helmet } from 'react-helmet-async';

import Breadcrumbs from 'src/components/Breadcrumbs';
import PaginatedTable from 'src/components/PaginatedTable';
import { Container, Header as PageHeader, Row } from 'src/styles';
import AddQualifaiConversation from './AddQualifaiConversation';
import ListQualifaiConversationsBody from './ListQualifaiConversationsBody';

const VoiceConversationsList = () => {
  return (
    <Container>
      <Helmet>
        <title>Voice Conversations | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
        <PageHeader style={{ margin: 0 }}>
          <Breadcrumbs crumbs={['Voice', 'Conversations']} />
        </PageHeader>

        <AddQualifaiConversation />
      </Row>

      <PaginatedTable headers={['Status', 'Name', '']} renderBody={ListQualifaiConversationsBody} />
    </Container>
  );
};

export default VoiceConversationsList;
