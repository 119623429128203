import styled from 'styled-components';

export const BigQueryNodeContent = styled.div`
  padding: 0.5rem;
`;

export const BigQueryNodeContentItem = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #727478;
`;

export const BigQueryNodeFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-top: 1px solid #e0e0e0;
`;

export const AffectedRows = styled.p`
  color: #ffb818;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
`;
