import { Helmet } from 'react-helmet-async';
import { Grid, Message } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import PaginatedTable from 'src/components/PaginatedTable';
import { Container, Header, Row } from 'src/styles';
import { accountLeadscorePlusBillingIsCurrent } from 'src/utils';
import AddDatasetV2 from './AddDatasetV2';
import ListDatasetsV2Body from './ListDatasetsV2Body';

const ListDatasetsV2 = () => {
  const { data: user } = useGetUserProfileQuery();
  const a = user?.active_account;

  return (
    <Container>
      <Helmet>
        <title>Datasets | datascore</title>
      </Helmet>

      {a && !accountLeadscorePlusBillingIsCurrent(a) && (
        <Grid>
          <Grid.Row centered>
            <Grid.Column width={8}>
              <Message error>
                <Message.Header>LeadScore+ Balance At or Below Zero</Message.Header>
                <Message.Content>
                  Data may be getting rejected by one or more of your datasets. Please add funds to your account to keep
                  using all features.
                </Message.Content>
              </Message>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header style={{ marginBottom: 0 }}>Datasets</Header>
        <AddDatasetV2 />
      </Row>

      <PaginatedTable
        headers={['Created At', 'Name', 'Ingested Alltime', '24h', '1h', '5m', 'Queued', '']}
        renderBody={ListDatasetsV2Body}
      />
    </Container>
  );
};

export default ListDatasetsV2;
