import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { Job, JobStatus, PaginatedResponse } from 'src/types';
import { ApiResponse, http } from './http-common';

export type JobResponse = ApiResponse & {
  job?: Job;
};

export type JobsResponse = ApiResponse & {
  jobs: PaginatedResponse<Job>;
};

export const useDeleteJobMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await http.delete<JobResponse>(`/api/jobs/${id}`);
      return res.data.job;
    },
    {
      onSuccess: job => {
        if (!job) return;
        const { id, type, objectid } = job;
        queryClient.invalidateQueries(['jobs', id]);
        queryClient.invalidateQueries(['jobs', type, objectid]);
      },
    }
  );
};

export const useDuplicateJobMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await http.post<JobResponse>(`/api/jobs/${id}/duplicate`);
      return res.data.job;
    },
    {
      onSuccess: job => {
        if (!job) return;
        const { type } = job;
        queryClient.invalidateQueries(['jobs', type]);
      },
    }
  );
};

export type UpdateJobInput = {
  id: number;
  status: JobStatus;
};

export const useUpdateJobMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, ...input }: UpdateJobInput) => {
      const res = await http.post<JobResponse>(`/api/jobs/${id}`, input);
      return res.data.job;
    },
    {
      onSuccess: job => {
        if (!job) return;
        const { id, type, objectid } = job;
        queryClient.invalidateQueries(['jobs', id]);
        queryClient.invalidateQueries(['jobs', type, objectid]);
      },
    }
  );
};

export type GetJobsInput = {
  limit?: number;
  offset?: number;
  type?: number;
  objectid?: string;
  statuses?: JobStatus[];
};

export const useGetJobsQuery = (params: GetJobsInput) => {
  const { type, objectid, limit, offset } = params;

  return useQuery(
    ['jobs', type, objectid, { limit, offset }],
    async () => {
      const res = await http.get<JobsResponse>('/api/jobs', { params });
      return res.data.jobs;
    },
    { refetchInterval: 5000 }
  );
};

export const useGetJobQuery = (id: number) => {
  return useQuery(['jobs', id], async () => {
    const res = await http.get<JobResponse>(`/api/jobs/${id}`);
    return res.data.job;
  });
};
