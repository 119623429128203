import { isEmpty } from 'lodash';
import { useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { useAssignUserAccessToAccountMutation } from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import SelectUsers from 'src/components/SelectUsers';
import { AccountUserAccessRole, AccountUserAccessRoles } from 'src/types';

type ValidationErrors = {
  modelId?: string;
};

type Props = {
  accountId: number;
  omitUserIds: number[];
};

const AssignUsersToAccount = ({ accountId, omitUserIds }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData | undefined>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { isLoading, mutateAsync } = useAssignUserAccessToAccountMutation();
  const [userIds, setUserIds] = useState<number[]>([]);
  const [roles, setRoles] = useState<AccountUserAccessRole[]>(['owner']);
  const roleOptions = AccountUserAccessRoles.map(r => ({ ...r, key: r.value }));

  const onClose = () => {
    setOpen(false);
    setUserIds([]);
    setRoles(['owner']);
    setApiMessage(undefined);
    setErrors({});
  };

  const validate = () => {
    const validationErrors: ValidationErrors = {};

    if (userIds.length === 0) {
      validationErrors.modelId = 'Please select at least one user';
    }

    setErrors(validationErrors);

    return validationErrors;
  };

  const onSubmit = async () => {
    if (!isEmpty(validate())) {
      return;
    }

    setApiMessage(undefined);

    try {
      await mutateAsync({ accountId, userIds, roles });
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button color="blue">
          <Icon name="plus" />
          Assign Users
        </Button>
      }
    >
      <Modal.Header>Assign Users</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <SelectUsers
            label="Users"
            onChange={(_, { value }) => setUserIds(value as number[])}
            error={errors.modelId}
            omitUserIds={omitUserIds}
            multiple
            value={userIds}
          />

          <Form.Select
            multiple
            label="Role"
            options={roleOptions}
            value={roles}
            onChange={(_, data) => setRoles(data.value as AccountUserAccessRole[])}
          />

          <Form.Button loading={isLoading} color="blue" fluid>
            Assign
          </Form.Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AssignUsersToAccount;
