import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Divider, Grid, Header, Icon, Message, Segment, Table } from 'semantic-ui-react';

import { useGetBlacklistQuery } from 'src/api/blacklists';
import Loading from 'src/components/Loading';
import { Container, Header as PageHeader, Row } from 'src/styles';
import theme from 'src/styles/theme';

const blacklistUrl = process.env.REACT_APP_BLACKLIST_API_URL;

const getBlacklistUrl = (hash: string | null): string => {
  if (!hash) return '';
  return `${blacklistUrl}/api/lists/${hash}`;
};

const BlacklistDocs = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const { data: blacklist, isLoading: pageLoading } = useGetBlacklistQuery(Number(params.id));

  if (pageLoading) return <Loading />;

  if (!blacklist) {
    return (
      <Container>
        <Helmet>
          <title>Blacklist Not Found | datascore</title>
        </Helmet>

        <Message error>
          <Message.Header>🚨 Error, Not Found.</Message.Header>
          <Message.Content>Unable to locate blacklist for id: {decodeURIComponent(params.id)}</Message.Content>
        </Message>
      </Container>
    );
  }
  return (
    <Container>
      <Helmet>
        <title>Docs - Blacklist | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center' }}>
        <Button icon color="blue" onClick={history.goBack} style={{ marginRight: '1rem' }}>
          <Icon name="arrow left" />
        </Button>
        <PageHeader style={{ margin: 0 }}>
          <span style={{ color: theme.gray }}>Blacklists</span> <span style={{ padding: '0 0.5rem' }}>/</span> Docs
        </PageHeader>
      </Row>

      <Segment>
        <Header as="h3">Add to Blacklist</Header>

        <p>
          <strong>IMPORTANT!</strong> Make sure you are using <code>POST</code> for these requests. You can add both{' '}
          <code>phone</code> and <code>email</code> on this endpoint.
        </p>

        <p>
          This endpoint accepts data as query string parameters or in any of the following Content-Types:{' '}
          <code>application/json</code>, <code>multipart/form-data</code>, and{' '}
          <code>application/x-www-form-urlencoded</code>.
        </p>

        <p>Phone Example with Query String Paramters</p>

        <Message>
          <pre>POST {getBlacklistUrl(blacklist.hash)}?phone=5034445555</pre>
        </Message>

        <p>Email Example with Query String Paramters</p>

        <Message>
          <pre>POST {getBlacklistUrl(blacklist.hash)}?email=user@domain.com</pre>
        </Message>

        <p>Email + Phone Example with Query String Paramters</p>

        <Message>
          <pre>POST {getBlacklistUrl(blacklist.hash)}?email=user@domain.com&phone=5034445555</pre>
        </Message>

        <p>
          Phone Example with Content-Type: <code>application/json</code>
        </p>

        <Message>
          <pre>
            POST {getBlacklistUrl(blacklist.hash)}
            <br />
            Content-Type: application/json
            <br />
            <br />
            {`{ "phone": "5034445555" }`}
          </pre>
        </Message>

        <p>
          Email Example with Content-Type: <code>application/json</code>
        </p>

        <Message>
          <pre>
            POST {getBlacklistUrl(blacklist.hash)}
            <br />
            Content-Type: application/json
            <br />
            <br />
            {`{ "email": "user@domain.com" }`}
          </pre>
        </Message>

        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3">Success response</Header>

              <Message success>
                <pre>
                  StatusCode: 200 OK
                  <br />
                  Content-type: application/json
                  <br />
                  <br />
                  {`{\n    "success": true\n}`}
                </pre>
              </Message>
            </Grid.Column>

            <Grid.Column>
              <Header as="h3">Error responses</Header>

              <Message error>
                <pre>
                  StatusCode: 400 Bad Request
                  <br />
                  Content-type: application/json
                  <br />
                  <br />
                  {`{\n    "message": "invalid list"\n}`}
                </pre>
              </Message>

              <Message error>
                <pre>
                  StatusCode: 422 Unprocessable Entity
                  <br />
                  Content-type: application/json
                  <br />
                  <br />
                  {`{\n    "message": "invalid phone number"\n}`}
                </pre>
              </Message>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider />

        <Header as="h3">Bulk Add to Blacklist</Header>

        <p>
          <strong>IMPORTANT!</strong> Make sure you are using <code>POST</code> for these requests and sending a body
          with a Content-Type of <code>multipart/form-data</code>.
        </p>

        <p>
          This endpoint accepts a CSV file containing email addresses and/or phone numbers. The first row of the CSV
          should contain a header. If the header values do not match our default search criteria (of either{' '}
          <code>email</code> or <code>phone</code>), you can specify the header names in the request body that should be
          used to locate the data.
        </p>

        <p>
          The content-type of the CSV data provided in the <code>file</code> parameter should be <code>text/csv</code>.
          If we cannot detect it as such, the request will be rejected. This endpoint will also accept compressed CSV
          data using gzip. In this case, the content-type should be <code>application/gzip</code> and after deflation we
          check for <code>text/plain</code>.
        </p>

        <p>
          If your CSV data is delimited using something other than the default (<code>,</code>) you can use the
          <code>delimiter</code> field in the request body to customize this. We currently only accept:{' '}
          <code>Comma</code> (<code>,</code>), <code>Pipe</code> (<code>|</code>) and
          <code>Tab</code> (<code>\t</code>).
        </p>

        <p>
          <strong>Endpoint</strong>
        </p>

        <Message>
          <pre>POST {getBlacklistUrl(blacklist.hash)}/bulk</pre>
        </Message>

        <p>
          <strong>Required Fields</strong>
        </p>

        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Field Name</Table.HeaderCell>
              <Table.HeaderCell>Required</Table.HeaderCell>
              <Table.HeaderCell>Default Value</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>file</Table.Cell>
              <Table.Cell>Yes</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                <code>text/csv</code> data containing emails and/or phones to be blacklisted.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>phone_column</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>phone</Table.Cell>
              <Table.Cell>Header column name where the phone numbers can be found.</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>email_column</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>email</Table.Cell>
              <Table.Cell>Header column name where the email addresses can be found.</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>delimiter</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>Comma</Table.Cell>
              <Table.Cell>
                Options: <code>Comma</code>, <code>Pipe</code>, <code>Tab</code>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3">Success response</Header>

              <Message success>
                <pre>
                  StatusCode: 200 OK
                  <br />
                  Content-type: application/json
                  <br />
                  <br />
                  {`{\n    "emails_added": 0,\n    "phones_added": 10,\n    "success": true\n}`}
                </pre>
              </Message>
            </Grid.Column>

            <Grid.Column>
              <Header as="h3">Error responses</Header>

              <Message error>
                <pre>
                  StatusCode: 400 Bad Request
                  <br />
                  Content-type: application/json
                  <br />
                  <br />
                  {`{\n    "message": "invalid list id"\n}`}
                </pre>
              </Message>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider />

        <Header as="h3">Check Blacklist</Header>

        <p>
          <strong>IMPORTANT!</strong> Make sure you are using <code>GET</code> for these requests. You can check both{' '}
          <code>phone</code> and <code>email</code> on this endpoint.
        </p>

        <p>Phone Example</p>

        <Message>
          <pre>GET {getBlacklistUrl(blacklist.hash)}?phone=5034445555</pre>
        </Message>

        <p>Email Example</p>

        <Message>
          <pre>GET {getBlacklistUrl(blacklist.hash)}?email=user@domain.com</pre>
        </Message>

        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3">Success response</Header>

              <Message success>
                <pre>
                  StatusCode: 200 OK
                  <br />
                  Content-type: application/json
                  <br />
                  <br />
                  {`{\n    "success": true,\n    "blacklisted": true,\n    "cached": false\n}`}
                </pre>
              </Message>
            </Grid.Column>

            <Grid.Column>
              <Header as="h3">Error responses</Header>

              <Message error>
                <pre>
                  StatusCode: 400 Bad Request
                  <br />
                  Content-type: application/json
                  <br />
                  <br />
                  {`{\n    "message": "missing required field: email or phone"\n}`}
                </pre>
              </Message>

              <Message error>
                <pre>
                  StatusCode: 422 Unprocessable Entity
                  <br />
                  Content-type: application/json
                  <br />
                  <br />
                  {`{\n    "message": "invalid input"\n}`}
                </pre>
              </Message>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider />

        <Header as="h3">List Phones</Header>

        <p>This endpoint will return the phones that have been added to the list.</p>

        <Message>
          <pre>GET {getBlacklistUrl(blacklist.hash)}/phones</pre>
        </Message>

        <p>
          <strong>Query String Parameters</strong>
        </p>

        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Parameter</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>start</Table.Cell>
              <Table.Cell>date / datetime</Table.Cell>
              <Table.Cell>
                We parse date and time strings in a variety of formats. If you provide a date without a time, we will
                assume the time is 00:00. All dates and times are in UTC.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>end</Table.Cell>
              <Table.Cell>date / datetime</Table.Cell>
              <Table.Cell>
                We parse date and time strings in a variety of formats. If you provide a date without a time, we will
                assume the time is 00:00. All dates and times are in UTC.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>format</Table.Cell>
              <Table.Cell>string</Table.Cell>
              <Table.Cell>
                Options: <code>csv</code> or <code>json</code>. Defaults to <code>csv</code>.
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>limit</Table.Cell>
              <Table.Cell>int</Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>offset</Table.Cell>
              <Table.Cell>int</Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3">Success response</Header>

              <Message success>
                <pre>
                  StatusCode: 200 OK
                  <br />
                  Content-Type: text/csv
                  <br />
                  <br />
                  added_at,phone_number
                  <br />
                  2006-01-02T14:15:06.000000Z,5035555555
                </pre>
              </Message>

              <Message success>
                <pre>
                  StatusCode: 200 OK
                  <br />
                  Content-Type: application/json
                  <br />
                  <br />
                  {`{\n  "success": true,\n  "data": [\n    {\n      "phone": "5035555555",\n      "added_at": "2006-01-02T14:15:06.000000Z"\n    }\n  ]\n}`}
                </pre>
              </Message>
            </Grid.Column>

            <Grid.Column>
              <Header as="h3">Error responses</Header>

              <Message error>
                <pre>
                  StatusCode: 400 Bad Request
                  <br />
                  Content-type: application/json
                  <br />
                  <br />
                  {`{\n    "message": "invalid start time"\n}`}
                </pre>
              </Message>

              <Message error>
                <pre>
                  StatusCode: 422 Unprocessable Entity
                  <br />
                  Content-type: application/json
                  <br />
                  <br />
                  {`{\n    "message": "invalid input"\n}`}
                </pre>
              </Message>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  );
};

export default BlacklistDocs;
