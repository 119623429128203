import { uniqBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { DropdownItemProps, Form, FormSelectProps, StrictFormSelectProps } from 'semantic-ui-react';

import { useListUsersQuery } from 'src/api/admin/users';

// NOTE: Even though FormSelectProps extends StrictFormSelectProps itself...
// for some reason, Omit<FormSelectProps, 'options'> alone was not providing type hints
// when using <SelectUsers /> in other components.
type Props = Omit<FormSelectProps, 'options'> &
  Omit<StrictFormSelectProps, 'options'> & {
    textKey?: 'email';
    valueKey?: 'id' | 'email';
    omitUserIds?: number[];
  };

const SelectUsers = ({
  onChange: parentOnChange,
  textKey = 'email',
  valueKey = 'id',
  omitUserIds = [],
  ...selectProps
}: Props) => {
  let selected: number[] = [];
  if (Array.isArray(selectProps.value)) {
    selected = selectProps.value.map(v => Number(v));
  } else if (typeof selectProps.value === 'string' || typeof selectProps.value === 'number') {
    selected = [Number(selectProps.value)];
  }

  const [search, setSearch] = useState('');
  const { data: users, isLoading } = useListUsersQuery({ limit: 20, offset: 0, search, selected });
  const [addedOptions, setAddedOptions] = useState<DropdownItemProps[]>([]);

  // NOTE: This effect is used to store api found values as addedOptions so that the
  // selection remains in the input visually after the user has focused out of it
  useEffect(() => {
    if (!users?.data) return;

    const userOptions =
      users?.data.map(u => ({ key: u.id, text: u[textKey], value: u[valueKey] }), [] as DropdownItemProps[]) || [];

    setAddedOptions(prev => uniqBy([...prev, ...userOptions], 'value'));
  }, [textKey, users?.data, valueKey]);

  const onChange = useCallback(
    (event, data) => {
      if (typeof parentOnChange !== 'undefined') {
        parentOnChange(event, data);
      }
      setSearch('');
    },
    [parentOnChange]
  );

  const onAddItem = useCallback((_, { value }) => {
    setAddedOptions(prev => uniqBy([...prev, { key: value, value, text: value }], 'value'));
  }, []);

  const userOptions =
    users?.data.map(u => ({ key: u.id, text: u[textKey], value: u[valueKey] }), [] as DropdownItemProps[]) || [];

  let selectedOptions: DropdownItemProps[] = [];
  if (Array.isArray(selectProps.value)) {
    selectedOptions = selectProps.value.map(v => ({ key: v, value: v, text: v }));
  }

  const options = uniqBy([...addedOptions, ...userOptions, ...selectedOptions], 'value').filter(
    o => !omitUserIds.includes(o.key)
  );

  return (
    <Form.Select
      search
      multiple
      {...selectProps}
      options={options}
      searchQuery={search}
      onSearchChange={(_, { searchQuery }) => setSearch(searchQuery)}
      onChange={onChange}
      loading={isLoading}
      onAddItem={onAddItem}
    />
  );
};

export default SelectUsers;
