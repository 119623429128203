import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';

import { useGetPipelineQuery } from 'src/api/admin/pipelines';
import Breadcrumbs from 'src/components/Breadcrumbs';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader, Row } from 'src/styles';
import EditPipelineForm from './EditPipelineForm';
import { PlaceholderLayout } from './styles';

const AdminPipelinesEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { data: pipeline, isLoading } = useGetPipelineQuery(Number(id));
  const [isFormValid, setIsFormValid] = useState(false);
  // const { goBack } = useHistory();
  // const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  // const { mutateAsync, isLoading: isPipelineLoading } = useRunPipelineMutation();

  // const runPipeline = useCallback(async () => {
  //   setApiMessage(undefined);
  //   try {
  //     await mutateAsync(Number(id));
  //   } catch (e: any) {
  //     apiErrorHandler(e, setApiMessage);
  //   }
  // }, [id, mutateAsync]);

  return (
    <Container>
      {/* <ApiMessage data={apiMessage} /> */}

      <Helmet>
        <title>Edit Pipeline | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center' }}>
        {/* <Button icon color="blue" onClick={goBack} style={{ marginRight: '1rem' }}>
          <Icon name="arrow left" />
        </Button> */}
        <PageHeader style={{ margin: 0 }}>
          <Breadcrumbs crumbs={['Admin', 'Pipelines', 'Edit']} />
        </PageHeader>

        {/* <Button
          color="blue"
          style={{ marginLeft: 'auto', marginRight: '0' }}
          onClick={runPipeline}
          loading={isPipelineLoading}
          disabled={!isFormValid}
        >
          Run Now
        </Button> */}
      </Row>

      <Segment>
        {isLoading ? (
          <PlaceholderLayout />
        ) : typeof pipeline !== 'undefined' ? (
          <EditPipelineForm pipeline={pipeline} isValid={isFormValid} setIsValid={setIsFormValid} />
        ) : (
          <NotFound />
        )}
      </Segment>

      {/* <JobHistory details={AdminPipelineJobDetailsModal} jobType={JobType.Pipeline} /> */}
    </Container>
  );
};

export default AdminPipelinesEdit;
