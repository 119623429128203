import { formatDistanceToNow } from 'date-fns';
import { noop } from 'lodash';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Icon, Label, Popup, Progress, Table } from 'semantic-ui-react';

import { useGetJobsQuery } from 'src/api/jobs';
import DeleteJob from 'src/components/JobHistory/DeleteJob';
import StopJob from 'src/components/JobHistory/StopJob';
import { RenderProps } from 'src/components/PaginatedTable';
import { Row } from 'src/styles';
import { DataflowJob, JobStatus, JobType } from 'src/types';
import { getJobStatusColor } from 'src/utils';
import DataflowJobDetailsModal from './DataflowJobDetailsModal';

const JobsListBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { id } = useParams<{ id: string }>();
  const { data: jobs } = useGetJobsQuery({
    limit,
    offset: (currentPage - 1) * limit,
    type: JobType.Dataflow,
    objectid: id,
  });

  useEffect(() => {
    if (!jobs?.total) return;

    setPageCount(Math.ceil(jobs.total / limit));
  }, [setPageCount, jobs?.total, limit]);

  const dataflowJobs = (jobs?.data || []) as DataflowJob[];

  return dataflowJobs.map(job => (
    <Table.Row key={job.id}>
      <Table.Cell>{job.id}</Table.Cell>
      <Table.Cell>{JobType[job.type]}</Table.Cell>
      <Table.Cell>
        {job.started && formatDistanceToNow(new Date(job.started), { addSuffix: true, includeSeconds: true })}
      </Table.Cell>
      <Table.Cell>
        {job.payload.error ? (
          <Popup
            trigger={
              <Label color={getJobStatusColor(job.status)}>
                {JobStatus[job.status]}
                <Icon name="info circle" style={{ marginRight: 0, marginLeft: '0.5rem' }} />
              </Label>
            }
          >
            {job.payload.error}
          </Popup>
        ) : job.status === JobStatus.Processing || job.status === JobStatus['Processing Externally'] ? (
          <Progress
            indicating
            percent={job.stage === 1 ? job.progress1 * 100 : job.stage === 2 ? job.progress2 * 100 : 0}
            content={job.payload.message || `Stage ${job.stage}`}
          />
        ) : (
          <Label color={getJobStatusColor(job.status)}>{JobStatus[job.status]}</Label>
        )}
      </Table.Cell>
      <Table.Cell>
        {job.heartbeat && formatDistanceToNow(new Date(job.heartbeat), { addSuffix: true, includeSeconds: true })}
      </Table.Cell>
      <Table.Cell collapsing>
        <Row style={{ justifyContent: 'flex-end' }}>
          {job.status === JobStatus.Processing && <StopJob id={job.id} onClose={noop} />}
          {(job.status === JobStatus.Completed || job.status === JobStatus.Ready) && (
            <DataflowJobDetailsModal job={job} />
          )}
          <DeleteJob id={job.id} onClose={noop} />
        </Row>
      </Table.Cell>
    </Table.Row>
  ));
};

export default JobsListBody;
