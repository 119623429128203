import { formatDistanceToNow } from 'date-fns';
import { noop } from 'lodash';
import { Icon, Label, Popup, Progress, Table } from 'semantic-ui-react';

import DeleteJob from 'src/components/JobHistory/DeleteJob';
import StopJob from 'src/components/JobHistory/StopJob';
import { Row } from 'src/styles';
import theme from 'src/styles/theme';
import { Job, JobStatus, JobType, PaginatedResponse } from 'src/types';
import { getJobStatusColor } from 'src/utils';

type Props = {
  jobs?: PaginatedResponse<Job>;
};

const ActiveJobsListBody = ({ jobs }: Props) => {
  if (typeof jobs === 'undefined') return null;

  if (jobs.data.length === 0) {
    return (
      <Table.Row>
        <Table.Cell colSpan={10} style={{ color: theme.gray }}>
          No jobs found
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      {jobs.data.map(job => (
        <Table.Row key={job.id}>
          <Table.Cell collapsing>{job.id}</Table.Cell>
          <Table.Cell>{JobType[job.type]}</Table.Cell>
          <Table.Cell>
            {job.queued && formatDistanceToNow(new Date(job.queued), { addSuffix: true, includeSeconds: true })}
          </Table.Cell>
          <Table.Cell>
            {job.status === JobStatus.Error && job.payload.error ? (
              <Popup
                trigger={
                  <Label color={getJobStatusColor(job.status)}>
                    {JobStatus[job.status]}
                    <Icon name="info circle" style={{ marginRight: 0, marginLeft: '0.5rem' }} />
                  </Label>
                }
              >
                {job.payload.error}
              </Popup>
            ) : job.status === JobStatus.Processing || job.status === JobStatus['Processing Externally'] ? (
              <Progress
                indicating
                percent={job.stage === 1 ? job.progress1 * 100 : job.stage === 2 ? job.progress2 * 100 : 0}
                content={job.payload.message || `Stage ${job.stage}`}
              />
            ) : (
              <Label color={getJobStatusColor(job.status)}>{JobStatus[job.status]}</Label>
            )}
          </Table.Cell>
          <Table.Cell>
            {job.started && formatDistanceToNow(new Date(job.started), { addSuffix: true, includeSeconds: true })}
          </Table.Cell>
          <Table.Cell>
            {job.heartbeat && formatDistanceToNow(new Date(job.heartbeat), { addSuffix: true, includeSeconds: true })}
          </Table.Cell>
          <Table.Cell collapsing>
            <Row style={{ alignItems: 'center' }}>
              {/* <DuplicateJob id={job.id} /> */}
              {[JobStatus.Pending, JobStatus.Processing, JobStatus['Processing Externally']].includes(job.status) && (
                <StopJob id={job.id} onClose={noop} />
              )}
              {job.status !== JobStatus.Completed && job.status !== JobStatus.Cancelled && (
                <DeleteJob id={job.id} onClose={noop} />
              )}
            </Row>
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default ActiveJobsListBody;
