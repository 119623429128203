import { isEmpty } from 'lodash';
import { ChangeEvent, useCallback, useState } from 'react';
import { Button, Form, Icon, InputOnChangeData, Modal } from 'semantic-ui-react';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { AddWebhookConfigParams, useAddWebhookConfigMutation } from 'src/api/webhooks';
import ApiMessage from 'src/components/ApiMessage';

type ValidationErrors = {
  name?: string;
};

const AddWebhookConfig = () => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { mutateAsync, isLoading } = useAddWebhookConfigMutation();
  const [formdata, setFormdata] = useState<AddWebhookConfigParams>({
    name: '',
  });

  const onChange = useCallback((_: ChangeEvent<HTMLInputElement>, { name, value }: InputOnChangeData) => {
    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
    setFormdata({ name: '' });
    setErrors({});
  }, []);

  const onSubmit = async () => {
    const validationErrors: ValidationErrors = {};

    if (formdata.name === '') {
      validationErrors.name = 'Name is required';
    }

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors);
      return;
    }

    try {
      setApiMessage(undefined);
      await mutateAsync(formdata);
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button floated="right" color="blue">
          <Icon name="plus" />
          Add Config
        </Button>
      }
    >
      <Modal.Header>Add Config</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Form.Input label="Name" error={errors.name} name="name" onChange={onChange} />

          <Form.Button loading={isLoading} color="blue" fluid>
            Create
          </Form.Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AddWebhookConfig;
