import { useMutation, useQuery, useQueryClient } from 'react-query';

import { BigqueryDataset, BigqueryTable, PaginatedResponse } from 'src/types';
import { ApiResponse, http } from '../http-common';

export type BigqueryDatasetResponse = ApiResponse & {
  dataset?: BigqueryDataset;
};

export type BigqueryTableResponse = ApiResponse & {
  table?: BigqueryTable;
};

export type BigqueryTablesResponse = ApiResponse & {
  tables: PaginatedResponse<BigqueryTable>;
};

export type AddBigqueryTableInput = {
  table_id: string;
  name: string;
  time_column_name?: string;
};

export const useAddBigqueryTableMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ datasetId, ...input }: { datasetId: number } & AddBigqueryTableInput) => {
      const res = await http.post<BigqueryTableResponse>(`/api/admin/bigquery/datasets/${datasetId}/tables`, input);
      return res.data;
    },
    {
      onSuccess: ({ table }) => {
        if (!table) return;
        queryClient.invalidateQueries(['admin/bigquery/datasets', table.bq_dataset_id, 'tables']);
      },
    }
  );
};

type ListBigqueryDatasetsParams = {
  limit: number;
  offset: number;
};

type ListBigqueryTablesParams = {
  limit: number;
  offset: number;
  accountId?: number;
};

export const useListBigqueryTablesQuery = (params: ListBigqueryTablesParams) => {
  return useQuery(['admin/bigquery/tables', params], async () => {
    const res = await http.get<BigqueryTablesResponse>(`/api/admin/bigquery/tables`, { params });
    return res.data.tables;
  });
};

type ListDatasetTablesParams = { datasetId: number } & ListBigqueryDatasetsParams;

export const useListBigqueryDatasetTablesQuery = ({ datasetId, ...params }: ListDatasetTablesParams) => {
  return useQuery(['admin/bigquery/datasets', datasetId, 'tables', params], async () => {
    const res = await http.get<BigqueryTablesResponse>(`/api/admin/bigquery/datasets/${datasetId}/tables`, { params });
    return res.data.tables;
  });
};

export const useGetBigqueryDatasetQuery = (id: number) => {
  return useQuery(['admin/bigquery/datasets', id], async () => {
    const res = await http.get<BigqueryDatasetResponse>(`/api/admin/bigquery/datasets/${id}`);
    return res.data.dataset;
  });
};

type UpdateBigqueryDatasetInput = {
  id: number;
  name?: string;
};

export const useUpdateBigqueryDatasetMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, ...input }: UpdateBigqueryDatasetInput) => {
      const res = await http.post<BigqueryDatasetResponse>(`/api/admin/bigquery/datasets/${id}`, input);
      return res.data.dataset;
    },
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries(['admin/bigquery/datasets', id]);
      },
    }
  );
};

type RemoveBigqueryTableInput = {
  datasetId: number;
  tableId: number;
};

export const useRemoveBigqueryTableMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ datasetId, tableId }: RemoveBigqueryTableInput) => {
      const res = await http.delete<ApiResponse>(`/api/admin/bigquery/datasets/${datasetId}/tables/${tableId}`);
      return res.data;
    },
    {
      onSuccess: (_, { datasetId }) => {
        queryClient.invalidateQueries(['admin/bigquery/datasets', datasetId]);
      },
    }
  );
};
