import _cloneDeep from 'lodash/cloneDeep';
import _debounce from 'lodash/debounce';
import _set from 'lodash/set';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Divider, DropdownItemProps, Form, Header, Icon, Segment, Select } from 'semantic-ui-react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { useDatasetSettingsUpdateMutation } from 'src/api/bigquery';
import { useListBlacklistsQuery } from 'src/api/blacklists';
import { useListFeedsQuery } from 'src/api/feeds';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Note } from 'src/styles';
import theme from 'src/styles/theme';
import { BaseSchema, BigqueryFilter, BigqueryTable, BigqueryTableSettings, TimeRanges } from 'src/types';
import CarrierNames from './carriers.json';
import DatasetFilters from './DatasetFilters';

const GridLayout = styled.div`
  display: grid;
  grid-template-areas: 'general v1 rules v2 triggers';
  grid-template-columns: 1fr auto 1fr auto 1fr;
  grid-template-rows: auto;
  gap: 1rem;
`;

const Layout: FC = ({ children }) => {
  return (
    <GridLayout>
      {children}

      <Divider className="full" vertical style={{ gridArea: 'v1', position: 'relative' }} />
      <Divider className="full" vertical style={{ gridArea: 'v2', position: 'relative' }} />
    </GridLayout>
  );
};

const DedupByOptions: DropdownItemProps[] = [
  { key: 'phone', text: 'Phone', value: 'phone' },
  { key: 'email', text: 'Email', value: 'email' },
  { key: 'first_last_zip', text: 'firstname, lastname, zip', value: 'first_last_zip' },
  { key: 'full_record', text: 'Full Record', value: 'full_record' },
];

const DedupWithinOptions: DropdownItemProps[] = TimeRanges.map(r => ({
  key: String(r.value),
  value: String(r.value),
  text: r.text,
}));

const ExcludeCarrierOptions: DropdownItemProps[] = CarrierNames.map(name => ({ key: name, value: name, text: name }));

type Props = {
  table: BigqueryTable;
};

const DatasetSettings = ({ table }: Props) => {
  const [saved, setSaved] = useState(true);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync, isLoading: saveLoading } = useDatasetSettingsUpdateMutation();
  const { data: feeds, isLoading: feedsLoading } = useListFeedsQuery({ limit: 100, offset: 0 });
  const [settings, setSettings] = useState<BigqueryTableSettings>({
    name: '',
    filters: [],
    requiredColumns: [],
    excludeCarriers: [],
    checkBlacklist: [],
    excludeConnects: false,
    excludeLandlines: false,
    deduplicate: false,
    deduplicateBy: 'phone',
    deduplicateWithin: '604800',
    customColumns: null,
    feedId: null,
    fieldMappings: null,
    syntheticData: {
      zipcodeEnabled: false,
    },
  });

  useEffect(() => {
    if (!table) return;

    setSettings({
      name: table.name,
      filters: table.settings.filters,
      requiredColumns: table.settings.requiredColumns,
      excludeCarriers: table.settings.excludeCarriers,
      checkBlacklist: table.settings.checkBlacklist,
      excludeConnects: table.settings.excludeConnects,
      excludeLandlines: table.settings.excludeLandlines,
      deduplicate: table.settings.deduplicate,
      deduplicateBy: table.settings.deduplicateBy || 'phone',
      deduplicateWithin: table.settings.deduplicateWithin || '604800',
      customColumns: table.settings.customColumns || null,
      feedId: table.settings.feedId || null,
      fieldMappings: table.settings.fieldMappings || null,
      syntheticData: {
        zipcodeEnabled: table.settings.syntheticData?.zipcodeEnabled || false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addFilter = useCallback(() => {
    setSettings(prev => ({
      ...prev,
      filters: [...(prev.filters || []), { column: '', operator: '', value: '', id: uuid() }],
    }));
  }, []);

  const removeFilter = useCallback((id: string) => {
    setSettings(prev => ({
      ...prev,
      filters: (prev.filters || []).filter(f => f.id !== id),
    }));
  }, []);

  const updateFilter = useCallback((id: string, filter: BigqueryFilter) => {
    setSettings(prev => ({
      ...prev,
      filters: (prev.filters || []).map(f => (f.id === id ? filter : f)),
    }));
  }, []);

  const { data } = useListBlacklistsQuery({ limit: 100, offset: 0 });
  const blacklistOptions = useMemo(
    () =>
      (data?.data || []).map(b => ({
        key: b.id,
        value: b.id,
        text: b.name,
      })),
    [data]
  );

  const requiredColumnsOptions = useMemo(
    () =>
      (table?.columns || []).reduce(
        (acc, c) =>
          c.name === table?.time_column_name
            ? acc
            : [
                ...acc,
                {
                  key: c.name,
                  value: c.name,
                  text: c.name,
                },
              ],
        [] as DropdownItemProps[]
      ),
    [table]
  );

  const saveSettings = useCallback(
    async (settings: BigqueryTableSettings) => {
      try {
        await mutateAsync({ id: table.id, settings });
        setSaved(true);
        setApiMessage(undefined);
      } catch (e) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [mutateAsync, table.id]
  );

  const debouncedSave = useMemo(() => _debounce(saveSettings, 3000), [saveSettings]);

  const onChange = useCallback((_, { checked, name, value }) => {
    setSettings(prev => {
      let v = typeof checked !== 'undefined' ? checked : value;
      if (name === 'feedId') {
        v = Number(v);
      }

      const next = _cloneDeep(prev);
      _set(next, name, v);
      return next;
    });

    setSaved(false);
  }, []);

  const FieldMappingOptions = useMemo(() => {
    // Return list of base fields + custom fields
    const items = BaseSchema.map(item => {
      return {
        key: item.id,
        text: item.name,
        value: item.name,
      };
    });

    const customItems =
      Object.keys(settings.customColumns || {}).map(customColumn => {
        return {
          key: customColumn,
          text: customColumn,
          value: customColumn,
        };
      }) || [];

    return items.concat(customItems);
  }, [settings.customColumns]);

  useEffect(() => {
    debouncedSave(settings);
  }, [debouncedSave, settings]);

  return (
    <>
      <ApiMessage data={apiMessage} />

      <Segment>
        <Form>
          <Button
            size="mini"
            compact
            color={saveLoading ? 'blue' : saved ? 'green' : 'red'}
            style={{ position: 'absolute', top: 0, right: 0, zIndex: 100, margin: 0 }}
          >
            {saveLoading ? <Icon name="spinner" loading /> : <Icon name={saved ? 'check' : 'dont'} />}
            {saveLoading ? 'Saving...' : saved ? 'Saved' : 'Unsaved'}
          </Button>

          <Layout>
            <div style={{ gridArea: 'general' }}>
              <Header>General</Header>

              <Form.Input label="Name" name="name" value={settings.name} onChange={onChange} />

              <Header>Field Mapping</Header>
              <Note>
                If you are unable to customize the field names sent by your API or CRM when posting the data into this
                dataset, you can use these settings to map the data to the corresponding field in the dataset. Create a
                mapping for each field that does not match the dataset's schema.
              </Note>

              {settings.fieldMappings?.map((fieldMapping, i) => (
                <Form.Group key={i} widths="equal">
                  <Form.Input
                    fluid
                    placeholder="From field"
                    onChange={onChange}
                    name={`fieldMappings[${i}].from`}
                    value={fieldMapping.from}
                    label="From"
                  />

                  <Form.Select
                    fluid
                    placeholder={fieldMapping.from || 'parameter name'}
                    value={fieldMapping.to}
                    name={`fieldMappings[${i}].to`}
                    onChange={(_, { value }) => {
                      // Set field value
                      onChange(null, {
                        name: `fieldMappings[${i}].to`,
                        value,
                      });

                      // Find field type
                      const field = BaseSchema.find(item => item.name === value);
                      let type = field?.type?.toString();
                      if (!type) {
                        type = (settings?.customColumns || {})[value?.toString() || ''];
                      }

                      // Set field type
                      onChange(null, {
                        name: `fieldMappings[${i}].type`,
                        value: field?.type,
                      });
                    }}
                    label="To"
                    options={FieldMappingOptions}
                  />

                  <Button
                    type="button"
                    color="red"
                    icon
                    style={{ marginTop: '1.5rem' }}
                    onClick={() => {
                      onChange(null, {
                        name: 'fieldMappings',
                        value: settings.fieldMappings?.filter((_, j) => i !== j),
                      });
                    }}
                  >
                    <Icon name="trash" />
                  </Button>
                </Form.Group>
              ))}

              <Button
                type="button"
                compact
                size="mini"
                color="blue"
                onClick={() => {
                  onChange(null, {
                    name: 'fieldMappings',
                    value: [...(settings.fieldMappings || []), { from: '', to: '', type: 'STRING' }],
                  });
                  console.log([...(settings.fieldMappings || []), { from: '', to: '', type: 'STRING' }]);
                }}
              >
                <Icon name="plus" />
                Add Field Mapping
              </Button>
            </div>

            <div style={{ gridArea: 'rules' }}>
              <Header>Rules</Header>

              <Form.Select
                multiple
                options={requiredColumnsOptions}
                label="Required Columns"
                value={settings.requiredColumns || undefined}
                name="requiredColumns"
                onChange={onChange}
              />

              <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Checkbox
                  label="Deduplicate"
                  checked={settings.deduplicate}
                  name="deduplicate"
                  onChange={onChange}
                />

                <Form.Select
                  options={DedupByOptions}
                  value={settings.deduplicateBy}
                  name="deduplicateBy"
                  onChange={onChange}
                />

                <span>within</span>

                <Form.Field style={{ width: 120 }}>
                  <Select
                    fluid
                    options={DedupWithinOptions}
                    value={settings.deduplicateWithin}
                    name="deduplicateWithin"
                    onChange={onChange}
                  />
                </Form.Field>
              </Form.Group>

              <Header as="h4">Filters</Header>
              <p style={{ color: theme.gray, marginTop: '-0.5rem' }}>
                To filter data in a specific column you must first make it required. Ingested data will be rejected if
                ANY filter does not pass.
              </p>

              <DatasetFilters
                columns={requiredColumnsOptions.filter(c => settings.requiredColumns?.includes(c.value as string))}
                filters={settings.filters || []}
                addFilter={addFilter}
                removeFilter={removeFilter}
                updateFilter={updateFilter}
              />

              <Form.Select
                label="Check Blacklist"
                options={blacklistOptions}
                multiple
                clearable
                search
                value={settings.checkBlacklist || undefined}
                name="checkBlacklist"
                onChange={onChange}
              />

              <Form.Select
                label="Exclude Carriers"
                options={ExcludeCarrierOptions}
                multiple
                search
                clearable
                value={settings.excludeCarriers || undefined}
                name="excludeCarriers"
                onChange={onChange}
              />

              <Form.Checkbox
                label="Exclude Connects"
                checked={settings.excludeConnects}
                name="excludeConnects"
                onChange={onChange}
              />

              <Form.Checkbox
                label="Exclude Landlines"
                checked={settings.excludeLandlines}
                name="excludeLandlines"
                onChange={onChange}
              />
            </div>

            <div style={{ gridArea: 'triggers' }}>
              <Header>Synthetic Data</Header>
              <Note>
                With Synthetic Data enabled, we will attempt to lookup and append various data points to fill out each
                customer record. Synthetic Data will only be added into empty fields and will not overwrite any existing
                data found in the record.
              </Note>

              <Form.Checkbox
                toggle
                label="Enable Zip Code"
                checked={settings.syntheticData?.zipcodeEnabled}
                name="syntheticData.zipcodeEnabled"
                onChange={onChange}
              />
              <Note>
                Enabling this feature will attempt to lookup and append a zip code to the record based on the city and
                state (if available) or the area code of the phone number.
              </Note>

              <Divider />

              <Header>Triggers</Header>

              <Form.Select
                label="Send Realtime to Feed"
                name="feedId"
                loading={feedsLoading}
                value={settings.feedId || ''}
                onChange={onChange}
                clearable
                options={(feeds?.data || []).map(f => ({
                  key: f.id,
                  value: f.id,
                  text: f.name,
                }))}
              />
              <Note>
                Only the incoming records received by the single record API endpoint will be passed through to the feed
                in realtime. Bulk data received via the API endpoint or CSV upload feature will not be sent to the feed.
              </Note>
              <Note>
                Data passed to the feed will be given a <code>utctime</code> of "now" and a <code>score</code> of 1.
              </Note>
            </div>
          </Layout>
        </Form>
      </Segment>
    </>
  );
};

export default DatasetSettings;
