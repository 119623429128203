import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Edge, Node } from 'react-flow-renderer';
import { useHistory } from 'react-router-dom';
import { Button, Dropdown, DropdownItemProps, Icon, Label, Loader } from 'semantic-ui-react';

import { usePublishRevisionMutation } from 'src/api/dataflows';
import { Dataflow } from 'src/types';
import { hasConnectedOutputWidget } from '../widgets/hooks';
import { NodeTypes } from '../widgets/types';
import ErrorModal, { ValidationErrors } from './ErrorModal';
import RunDataflow from './RunDataflow';
import Settings from './Settings';
import { BackButton, FlowHeaderContainer, FlowHeaderLeft, FlowHeaderRight, FlowHeaderTitle } from './styles';

type FlowHeaderProps = {
  dataflow?: Dataflow;
  revisionId?: number;
  unpublishedCount?: number;
  nodes: Node<NodeTypes>[];
  edges: Edge<any>[];
};

const FlowHeader = ({ dataflow, revisionId, unpublishedCount, nodes, edges }: FlowHeaderProps) => {
  const { isLoading: publishIsLoading, mutateAsync: publishRevision } = usePublishRevisionMutation();
  const [runDataflowOpen, setRunDataflowOpen] = useState(false);
  const { goBack, push } = useHistory();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({});

  const dataflowId = dataflow?.id;

  const onClickPublish = useCallback(() => {
    if (!dataflowId || !revisionId) return;
    publishRevision({ dataflowId, revisionId });
  }, [dataflowId, revisionId, publishRevision]);

  const onClickRunJob = useCallback(() => {
    setRunDataflowOpen(true);
  }, []);

  // TODO: We need to inform the user that the dataflow is not valid
  // Maybe with a toast, but also need to be visible at any point
  useEffect(() => {
    const validationErrors: ValidationErrors = {};

    if (!hasConnectedOutputWidget(edges, nodes)) {
      validationErrors.hasOutputWidget = 'The flow needs to ends with at least one Output widget.';
    }

    setErrors(validationErrors);
  }, [nodes, edges]);

  const options = useMemo<DropdownItemProps[]>(
    () => [
      {
        key: 'run',
        text: 'Run Now',
        onClick: () => isEmpty(errors) && onClickRunJob(),
        disabled: !isEmpty(errors),
      },
      {
        key: 'schedule',
        text: 'Schedule',
        onClick: () => setIsSettingsOpen(true),
      },
      {
        key: 'history',
        text: 'History',
        onClick: () => dataflowId && push(`/dataflows/${dataflowId}/history`),
      },
      {
        key: 'settings',
        text: 'Settings',
        onClick: () => setIsSettingsOpen(true),
      },
    ],
    [dataflowId, errors, onClickRunJob, push]
  );

  return (
    <>
      <FlowHeaderContainer>
        <FlowHeaderLeft>
          <BackButton onClick={goBack}>
            <Icon name="arrow left" size="large" />
          </BackButton>
        </FlowHeaderLeft>

        {dataflow ? (
          <Settings open={isSettingsOpen} setOpen={setIsSettingsOpen} dataflow={dataflow} />
        ) : (
          <FlowHeaderTitle />
        )}

        <FlowHeaderRight>
          {!isEmpty(errors) && <ErrorModal errors={errors} />}

          {typeof unpublishedCount === 'undefined' ? (
            <Label style={{ position: 'relative' }}>
              <Loader active size="tiny" />
              &nbsp;&nbsp;&nbsp;
            </Label>
          ) : unpublishedCount === 0 ? (
            <Label color="green">Flow is up-to-date</Label>
          ) : (
            <Label color="red">{unpublishedCount} unpublished revisions</Label>
          )}
          <Button.Group color="blue">
            <Button
              loading={publishIsLoading}
              color="blue"
              disabled={!revisionId || unpublishedCount === 0 || !isEmpty(errors)}
              onClick={onClickPublish}
            >
              Publish
            </Button>
            <Dropdown className="button icon" floating options={options} trigger={<></>} />
          </Button.Group>
        </FlowHeaderRight>
      </FlowHeaderContainer>

      <RunDataflow open={runDataflowOpen} onClose={() => setRunDataflowOpen(false)} />
    </>
  );
};

export default FlowHeader;
