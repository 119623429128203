import styled, { css } from 'styled-components';

export const DropArea = styled.div<{ error: boolean; active: boolean }>`
  cursor: pointer;
  border: 2px dotted #eee;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &:hover {
    border-color: #85b7d9;
  }

  p {
    margin: 0 !important;
  }

  ${p =>
    p.error &&
    css`
      border-color: ${p.theme.red};
    `}

  ${p =>
    p.active &&
    css`
      background-color: #cce2ff;
      border-color: #85b7d9;
    `}
`;
