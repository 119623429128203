import { isEmpty } from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import { useCallback, useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { useAddDatasetColumnMutation } from 'src/api/bigquery';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { BigqueryTable } from 'src/types';

type ValidationErrors = {
  name?: string;
  type?: string;
};

type FormData = {
  name: string;
  type: string;
};

const DatasetAddColumnModal = ({ table }: { table: BigqueryTable }) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({});

  const { mutateAsync, isLoading } = useAddDatasetColumnMutation();
  const [formdata, setFormdata] = useState<FormData>({
    name: '',
    type: '',
  });

  const onChange = useCallback((_, { checked, name, value }) => {
    setFormdata(prev => {
      const v = typeof checked !== 'undefined' ? checked : value;

      const next = _cloneDeep(prev);
      _set(next, name, v);

      return next;
    });
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
    setFormdata({ name: '', type: '' });
    setApiMessage(undefined);
    setErrors({});
  }, []);

  const onSubmit = async () => {
    const validationErrors: ValidationErrors = {};

    if (formdata.name === '') {
      validationErrors.name = 'Name is required';
    }

    if (formdata.type === '') {
      validationErrors.type = 'Type is required';
    }

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors);
      return;
    }

    try {
      setApiMessage(undefined);
      await mutateAsync({ id: table.id, ...formdata });
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button floated="right" color="blue">
          <Icon name="plus" />
          Add Column
        </Button>
      }
    >
      <Modal.Header>Add Dataset Column</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Form.Input label="Name" error={errors.name} name="name" onChange={onChange} />
          <Form.Select
            label="Type"
            placeholder="Type"
            error={errors.type}
            name="type"
            options={[
              { key: 'string', value: 'STRING', text: 'String' },
              { key: 'integer', value: 'INTEGER', text: 'Integer' },
              { key: 'float', value: 'FLOAT', text: 'Float' },
              { key: 'numeric', value: 'NUMERIC', text: 'Numeric' },
              // { key: 'bignumeric', value: 'BIGNUMERIC', text: 'Big Numeric' },
              { key: 'boolean', value: 'BOOLEAN', text: 'Boolean' },
              // { key: 'byte', value: 'BYTE', text: 'Byte' },
              { key: 'date', value: 'DATE', text: 'Date' },
              // { key: 'time', value: 'TIME', text: 'Time' },
              // { key: 'datetime', value: 'DATETIME', text: 'Datetime' },
              { key: 'timestamp', value: 'TIMESTAMP', text: 'Timestamp' },
              // { key: 'geography', value: 'GEOGRAPHY', text: 'Geography' },
              // { key: 'record', value: 'RECORD', text: 'Record' },
              // { key: 'json', value: 'JSON', text: 'Json' },
            ]}
            onChange={onChange}
          />

          <Row>
            <Button type="button" disabled={isLoading} onClick={onClose} fluid>
              Cancel
            </Button>

            <Button loading={isLoading} onClick={onSubmit} color="blue" fluid>
              Add Column
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default DatasetAddColumnModal;
