import { useMemo } from 'react';
import { Segment } from 'semantic-ui-react';

import { Header, HorizontalDivider } from 'src/styles';
import { JobType } from 'src/types';
import PaginatedTable from '../PaginatedTable';
import JobsListBody from './JobsListBody';

const HEADERS = ['ID', 'Created At', 'Job Status', 'Started at', 'Last Updated', ''];

type Props = {
  adminJobs?: boolean;
  details?: (j: any) => JSX.Element;
  jobType?: JobType;
  qsParam?: string | boolean;
};

const JobHistory = ({ adminJobs, details, jobType, qsParam = 'page' }: Props) => {
  const headers = useMemo(() => {
    const h = [...HEADERS];
    if (jobType === JobType.Predict) {
      h.splice(1, 0, 'File Name');
    }
    if (jobType === JobType.DatasetUpload) {
      h.splice(1, 0, 'File Name');
      h.splice(2, 0, 'Dataset Name');
    }

    return h;
  }, [jobType]);

  return (
    <Segment>
      <Header style={{ marginBottom: 0 }}>Job History</Header>

      <HorizontalDivider style={{ margin: '1rem -1rem' }} />

      <PaginatedTable
        headers={headers}
        renderBody={props => <JobsListBody {...props} adminJobs={adminJobs} details={details} jobType={jobType} />}
        qsParam={qsParam}
      />
    </Segment>
  );
};

export default JobHistory;
