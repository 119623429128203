import update from 'immutability-helper';
import { useCallback, useState } from 'react';
import {
  Button,
  CheckboxProps,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  InputOnChangeData,
  TextAreaProps,
} from 'semantic-ui-react';

import { useSaveSendgridInboundParseSettingMutation } from 'src/api/auth/account-sendgrid';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import theme from 'src/styles/theme';
import {
  SendgridInboundParseAction,
  SendgridInboundParseDefaultResponse,
  SendgridInboundParseSetting,
} from 'src/types';
import ParseActionForm from './ParseActionForm';

type Props = {
  parseSetting: SendgridInboundParseSetting;
};

const getInitialActionData = (settingId: number): SendgridInboundParseAction => ({
  id: 0,
  name: '',
  settingId,
  setting: null,
  rules: [],
  triggers: [],
});

const getInitialDefaultResponseData = (): SendgridInboundParseDefaultResponse => ({
  enabled: false,
  fromEmail: '',
  fromName: '',
  replyToEmail: '',
  subject: '',
  bodyHtml: '',
  bodyText: '',
});

const InboundParseSettingForm = ({ parseSetting }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [setting, setSetting] = useState<SendgridInboundParseSetting>(parseSetting);
  const { mutateAsync: saveSetting, isLoading: saveLoading } = useSaveSendgridInboundParseSettingMutation();

  const addAction = useCallback(() => {
    setSetting(prev => update(prev, { actions: { $push: [getInitialActionData(parseSetting.id)] } }));
  }, [parseSetting.id]);

  const onChangeDefaultResponse = useCallback(
    (_, { checked, name, value }: InputOnChangeData | TextAreaProps | CheckboxProps) => {
      setSetting(prev => {
        if (!prev.defaultResponse) {
          prev = update(prev, { defaultResponse: { $set: getInitialDefaultResponseData() } });
        }
        if (typeof checked !== 'undefined') value = checked;
        return update(prev, { defaultResponse: { [name]: { $set: value } } });
      });
    },
    []
  );

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    try {
      await saveSetting({ id: parseSetting.id, setting });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [parseSetting.id, saveSetting, setting]);

  return (
    <Form onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      {setting.actions?.map((a, i) => (
        <div key={`a-${i}`}>
          <ParseActionForm action={a} actionIndex={i} setSetting={setSetting} />
          <Divider />
        </div>
      ))}

      <Button type="button" onClick={addAction}>
        <Icon name="plus" /> Add Action
      </Button>

      <Divider />

      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <Header>Default Response</Header>
            <Form.Radio
              name="enabled"
              label="Enabled"
              toggle
              checked={setting.defaultResponse?.enabled === true}
              // onChange={onChangeDefaultResponse}
            />
            <p style={{ color: theme.gray }}>Coming Soon</p>
          </Grid.Column>
          <Grid.Column width={12} style={{ opacity: setting.defaultResponse?.enabled !== true ? 0.3 : undefined }}>
            <Form.Group widths="equal">
              <Form.Input
                name="fromEmail"
                label="From Email"
                value={setting.defaultResponse?.fromEmail || ''}
                onChange={onChangeDefaultResponse}
              />
              <Form.Input
                name="fromName"
                label="From Display Name"
                value={setting.defaultResponse?.fromName || ''}
                onChange={onChangeDefaultResponse}
              />
            </Form.Group>

            <Form.Input
              name="replyToEmail"
              label="Reply-To Email"
              value={setting.defaultResponse?.replyToEmail || ''}
              onChange={onChangeDefaultResponse}
            />

            <Form.Input
              name="subject"
              label="Subject"
              value={setting.defaultResponse?.subject || ''}
              onChange={onChangeDefaultResponse}
            />

            <Form.TextArea
              name="bodyHtml"
              label="Body HTML"
              value={setting.defaultResponse?.bodyHtml || ''}
              onChange={onChangeDefaultResponse}
            />

            <Form.TextArea
              name="bodyText"
              label="Body Plaintext"
              value={setting.defaultResponse?.bodyText || ''}
              onChange={onChangeDefaultResponse}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider />

      <Button color="blue" loading={saveLoading}>
        Save
      </Button>
    </Form>
  );
};

export default InboundParseSettingForm;
