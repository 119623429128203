import { AxiosError } from 'axios';
import { Grid, Header, Segment } from 'semantic-ui-react';

import { useGetAccountReportingDatascoreQuery } from 'src/api/auth/account-reporting';
import { ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { AccountReportingDatascore } from 'src/types';
import { ReportingFilters } from '.';
import Stat from './Stat';

type Props = {
  config: AccountReportingDatascore;
  filters: ReportingFilters;
};

const DatascoreStats = ({ config, filters }: Props) => {
  const { data, error, isLoading } = useGetAccountReportingDatascoreQuery({
    startDate: filters.startDate,
    endDate: filters.endDate,
    voiceConfigId: filters.voiceConfigId,
  });

  const errorMsg: ApiMessageData | undefined = error
    ? {
        status: Number((error as AxiosError).response?.status) || 500,
        message: (error as AxiosError).response?.data?.message || '',
        success: false,
      }
    : undefined;

  const datascoreCalls = data?.datascoreCalls || [];
  const totalDials = datascoreCalls.reduce((total, { dials }) => total + dials, 0);
  const totalInbounds = datascoreCalls.reduce((total, { inbounds }) => total + inbounds, 0);
  const totalTransfers = datascoreCalls.reduce((total, { transfers }) => total + transfers, 0);
  const totalConnects = datascoreCalls.reduce((total, { connects }) => total + connects, 0);

  let connectDiv = 0;
  if (config.dials) connectDiv += totalDials;
  const connectPct = connectDiv === 0 ? 100 : (totalConnects / connectDiv) * 100 || 0;

  let transfersDiv = 0;
  if (config.dials) transfersDiv += totalDials;
  if (config.inbounds) transfersDiv += totalInbounds;
  const transferPct = transfersDiv === 0 ? 100 : (totalTransfers / transfersDiv) * 100 || 0;

  return (
    <Grid columns={3}>
      <Grid.Row>
        <Grid.Column width={16}>
          <Segment textAlign="center">
            <Header>Datascore</Header>
          </Segment>

          {errorMsg && <ApiMessage data={errorMsg} />}
        </Grid.Column>
      </Grid.Row>

      <Grid.Row style={{ rowGap: '2rem' }}>
        {config.dials && <Stat label="Dials" value={totalDials.toLocaleString()} loading={isLoading} />}
        {config.connects && <Stat label="Connects" value={totalConnects.toLocaleString()} loading={isLoading} />}
        {config.connects && config.connects_pct && (
          <Stat label="Connect %" value={`${connectPct.toFixed(2)}%`} loading={isLoading} />
        )}

        {config.inbounds && <Stat label="Inbounds" value={totalInbounds.toLocaleString()} loading={isLoading} />}
        {config.transfers && <Stat label="Transfers" value={totalTransfers.toLocaleString()} loading={isLoading} />}
        {config.transfers && config.transfers_pct && (
          <Stat label="Transfer %" value={`${transferPct.toFixed(2)}%`} loading={isLoading} />
        )}
      </Grid.Row>

      {/* <DatascoreCallsLineChart calls={datascoreCalls} /> */}
    </Grid>
  );
};

export default DatascoreStats;
