import { useCallback, useMemo, useRef, useState } from 'react';
import { Button, DropdownItemProps, Form, FormProps, Modal } from 'semantic-ui-react';

import {
  useGetTwilioStudioFlowsQuery,
  useReassignTwilioIncomingPhonesStudioFlowMutation,
} from 'src/api/auth/account-twilio';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';

type Props = {
  sids: string[];
  open: boolean;
  onSuccess?: () => void;
  onClose: () => void;
};

const TwilioReassignStudioFlowModal = ({ sids, open, onSuccess, onClose }: Props) => {
  const modalRef = useRef(null);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [selectedWebhookURL, setSelectedWebhookURL] = useState('');
  const { data, isLoading: flowsLoading } = useGetTwilioStudioFlowsQuery();
  const { mutateAsync: reassignPhonesNumber, isLoading } = useReassignTwilioIncomingPhonesStudioFlowMutation();

  const webhookURLOptions = useMemo<DropdownItemProps[]>(
    () =>
      data?.flows?.map(f => ({
        key: f.sid,
        text: f.friendly_name,
        value: f.webhook_url,
      })) || [],
    [data?.flows]
  );

  const closeModal = useCallback(() => {
    if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
      // @ts-ignore
      modalRef.current.handleClose();
      onClose();
    }
  }, [onClose]);

  const onCloseModal = () => {
    setApiMessage(undefined);
    setSelectedWebhookURL('');
    onClose();
  };

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>, data: FormProps): Promise<void> => {
      setApiMessage(undefined);

      if (!selectedWebhookURL) return;

      try {
        await reassignPhonesNumber({ sids, webhookURL: selectedWebhookURL });
        if (onSuccess !== undefined) {
          onSuccess();
        }

        closeModal();
      } catch (e: any) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [closeModal, onSuccess, reassignPhonesNumber, selectedWebhookURL, sids]
  );

  return (
    <Modal size="mini" ref={modalRef} open={open} onClose={onCloseModal}>
      <Modal.Header>Reassign Studio Flow</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Row style={{ marginBottom: '1rem' }}>
            <Form.Select
              label="Studio Flow"
              width="16"
              placeholder="Select flow to assign phone numbers to"
              loading={flowsLoading}
              clearable
              options={[{ key: 'none', value: 'none', text: 'Unassign' }, ...(webhookURLOptions || [])]}
              value={selectedWebhookURL}
              onChange={(_, { value }) => setSelectedWebhookURL(String(value))}
            />
          </Row>

          <Row style={{ gap: '1rem' }}>
            <Button fluid type="button" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              color="red"
              fluid
              loading={isLoading}
              disabled={selectedWebhookURL === ''}
              style={{ marginRight: 0 }}
            >
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default TwilioReassignStudioFlowModal;
