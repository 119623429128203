import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';

import { useGetAkkioModelQuery } from 'src/api/admin/leadscore-plus';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Loading from 'src/components/Loading';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader } from 'src/styles';
import AdminAkkioModelGeneral from './AdminAkkioModelGeneral';

const AdminLeadscorePlusAkkioModelsEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { data: model, isLoading } = useGetAkkioModelQuery(Number(id));

  if (isLoading) {
    return <Loading />;
  }
  if (typeof model === 'undefined') {
    return <NotFound />;
  }

  return (
    <Container>
      <Helmet>
        <title>Edit Model - LeadScore+ - Admin | datascore</title>
      </Helmet>

      <PageHeader style={{ marginBottom: 0 }}>
        <Breadcrumbs crumbs={['Admin', 'LeadScore+', 'Models', 'Edit']} />
      </PageHeader>

      <Segment>
        <AdminAkkioModelGeneral model={model} />
      </Segment>
    </Container>
  );
};

export default AdminLeadscorePlusAkkioModelsEdit;
