import { useRef, useState } from 'react';
import {
  Button,
  DropdownProps,
  Form,
  FormProps,
  Icon,
  InputOnChangeData,
  Modal,
  TextAreaProps,
} from 'semantic-ui-react';

import { EditFilterFormdata, useEditFilterMutation } from 'src/api/filters';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { HorizontalDivider } from 'src/styles';
import { Filter, FilterType } from 'src/types';
import { fieldNameOptions, typeOptions } from './filter-options';

const getInitialFormdata = (filter?: Filter): EditFilterFormdata => {
  const formdata: EditFilterFormdata = { name: '', type: FilterType['Field Equals'], payload: {} };
  if (!filter || !filter.id) return formdata;

  formdata.name = filter.name || '';
  formdata.type = filter.type || 0;
  formdata.payload = filter.payload || {};

  return formdata;
};

type Props = {
  filter: Filter;
};

const EditFilter = ({ filter }: Props) => {
  const modalRef = useRef(null);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [formdata, setFormdata] = useState<EditFilterFormdata>(() => getInitialFormdata(filter));
  const { mutateAsync, isLoading } = useEditFilterMutation();

  const onChange = ({ name, value }: InputOnChangeData | TextAreaProps | DropdownProps) => {
    let newFormdata = { ...formdata };

    if (/^payload/.test(name)) {
      const key = name.split('.')[1];
      newFormdata.payload = {
        ...formdata.payload,
        [key]: value,
      };
    } else {
      newFormdata = { ...formdata, [name]: value };
    }

    setFormdata(newFormdata);
  };

  const onCloseModal = () => {
    setApiMessage(undefined);
    // setFormdata(getInitialFormdata());
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>, data: FormProps): Promise<void> => {
    setApiMessage(undefined);

    try {
      await mutateAsync({ id: filter.id, ...formdata });

      if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
        // @ts-ignore
        modalRef.current.handleClose();
      }
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Modal
      size="tiny"
      ref={modalRef}
      trigger={
        <Button color="blue" icon title="Edit">
          <Icon name="pencil" />
        </Button>
      }
      onClose={onCloseModal}
    >
      <Modal.Header>Edit Filter</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Form.Input label="Filter Name" name="name" value={formdata.name} onChange={(_e, d) => onChange(d)} />

          <HorizontalDivider />

          <Form.Select
            label="Type"
            name="type"
            value={formdata.type}
            onChange={(_e, d) => onChange(d)}
            options={typeOptions}
          />

          {[
            FilterType['Field Equals'],
            FilterType['Field NOT Equals'],
            FilterType['Field Starts With'],
            FilterType['Field NOT Starts With'],
          ].includes(formdata.type) && (
            <>
              <Form.Select
                label="Field Name"
                name="payload.fieldName"
                value={formdata.payload.fieldName}
                onChange={(_e, d) => onChange(d)}
                options={fieldNameOptions}
                search
              />

              <Form.TextArea
                label="Value(s) (One per line)"
                name="payload.value"
                value={formdata.payload.value}
                onChange={(_e, d) => onChange(d)}
              />
            </>
          )}

          <Form.Button color="blue" fluid loading={isLoading}>
            Save
          </Form.Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default EditFilter;
