import { useState } from 'react';
import { Form, InputOnChangeData } from 'semantic-ui-react';

import { useSetModelCostMutation } from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { ModelAccountAccess } from 'src/types';

type Props = {
  modelAccess: ModelAccountAccess;
};

const AdminAccountSetModelCostForm = ({ modelAccess }: Props) => {
  const [rate, setRate] = useState<string>(String(modelAccess.rate || ''));
  const [error, setError] = useState<ApiMessageData>();
  const { isLoading, mutateAsync } = useSetModelCostMutation();

  const onChange = (_e: React.ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData): void => {
    if (!Number.isNaN(Number(value))) {
      setRate(value);
    }
  };

  const onSubmit = async () => {
    setError(undefined);
    try {
      await mutateAsync({
        accountId: modelAccess.account_id,
        modelId: modelAccess.model_id,
        rate: Number(rate),
      });
    } catch (e: any) {
      apiErrorHandler(e, setError);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Form.Input
        placeholder="free"
        onBlur={onSubmit}
        onChange={onChange}
        value={rate}
        loading={isLoading}
        width={6}
        error={error && `${error.status} ${error.message}`}
      />
    </Form>
  );
};

export default AdminAccountSetModelCostForm;
