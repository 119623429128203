import { Helmet } from 'react-helmet-async';
import { Divider, Grid, Header, Segment } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import Loading from 'src/components/Loading';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader } from 'src/styles';
import theme from 'src/styles/theme';
import ChangePasswordForm from './ChangePasswordForm';
import UpdateProfileForm from './UpdateProfileForm';

const AuthUserSettings = () => {
  const { data: user, isLoading } = useGetUserProfileQuery();

  if (isLoading) return <Loading />;

  if (!user) return <NotFound />;

  return (
    <Container>
      <Helmet>
        <title>Settings - User | datascore</title>
      </Helmet>

      <PageHeader>
        <span style={{ color: theme.gray }}>User</span> <span style={{ padding: '0 0.5rem' }}>/</span> Settings
      </PageHeader>

      <Segment>
        <Grid columns={4}>
          <Grid.Row>
            <Grid.Column>
              <Header>Profile</Header>
            </Grid.Column>
            <Grid.Column>
              <UpdateProfileForm />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider />

        <Grid columns={4}>
          <Grid.Row>
            <Grid.Column>
              <Header>Change Password</Header>
            </Grid.Column>
            <Grid.Column>
              <ChangePasswordForm />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  );
};

export default AuthUserSettings;
