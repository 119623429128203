import { useCallback } from 'react';
import { Button } from 'semantic-ui-react';

import { useRemoveBigqueryTableFromAccountMutation } from 'src/api/admin/accounts';

type Props = {
  accountId: number;
  tableId: number;
};

const RemoveBigqueryTableAccess = ({ accountId, tableId }: Props) => {
  const { isLoading, mutateAsync } = useRemoveBigqueryTableFromAccountMutation();

  const onClick = useCallback(async () => {
    await mutateAsync({ accountId, tableIds: [tableId] });
  }, [accountId, tableId, mutateAsync]);

  return <Button icon="trash" color="red" onClick={onClick} loading={isLoading} />;
};

export default RemoveBigqueryTableAccess;
