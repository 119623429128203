import { Link } from 'react-router-dom';
import { Icon, Label } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import logo from 'src/images/datascore.png';
import { accountConnectProBillingIsCurrent, accountLeadscorePlusBillingIsCurrent } from 'src/utils';
import ActiveJobs from './ActiveJobs';
import { HeaderContainer, HeaderMenu, Logo } from './styles';
import UserDropdown from './UserDropdown';

const Header = () => {
  const { data: user } = useGetUserProfileQuery();
  const a = user?.active_account;

  const billingIssue =
    a && !accountLeadscorePlusBillingIsCurrent(a)
      ? 'leadscore-plus'
      : a && !accountConnectProBillingIsCurrent(a)
      ? 'connect-pro'
      : null;

  return (
    <HeaderContainer id="header">
      <Logo href="/">
        <img src={logo} alt="datascore" />
      </Logo>
      <HeaderMenu>
        <ActiveJobs />

        {!!billingIssue && (
          <Link
            to={`/account/billing/settings?tab=${billingIssue}`}
            className="item"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 1rem' }}
          >
            <Label color="red">
              <Icon name="warning circle" /> Billing Issue
            </Label>
          </Link>
        )}

        <UserDropdown />
      </HeaderMenu>
    </HeaderContainer>
  );
};

export default Header;
