import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';

import { useGetUserProfileQuery } from 'src/api/auth';
import Loading from 'src/components/Loading';
import { Container } from 'src/styles';
import StepBusinessProfile from './StepBusinessProfile';
import StepCallCenter from './StepCallCenter';
import { Step, StepProps, STEPS } from './types';

const REGISTERED_STEPS: Record<
  Step,
  {
    prev: Step | null;
    component: FC<StepProps>;
    next: Step | null;
  }
> = {
  'business-profile': {
    prev: null,
    component: StepBusinessProfile,
    next: 'call-center',
  },
  'call-center': {
    prev: 'business-profile',
    component: StepCallCenter,
    next: null,
    // next: 'schedule-interview',
  },
  // 'schedule-interview': {
  //   prev: 'call-center',
  //   component: StepSchedule,
  //   next: null,
  // },
};

const Onboarding = () => {
  const { step: stepParam } = useParams<{ step: string }>();
  const { data: user, isLoading } = useGetUserProfileQuery();
  const history = useHistory();

  const [step, setStep] = useState<Step>(STEPS.BUSINESS_PROFILE);

  useEffect(() => {
    const isValidStep = Object.values(STEPS).includes(stepParam as Step);
    if (!isValidStep) {
      history.push(`/onboarding/${STEPS.BUSINESS_PROFILE}`);
      setStep(STEPS.BUSINESS_PROFILE);
      return;
    }

    setStep(stepParam as Step);
  }, [history, stepParam]);

  const { prev: prevStep, component: StepComponent, next: nextStep } = useMemo(() => REGISTERED_STEPS[step], [step]);

  const updateStep = useCallback(
    (step: Step) => {
      history.push(`/onboarding/${step}`);
      setStep(step);
    },
    [history]
  );

  if (isLoading) {
    return <Loading />;
  }

  if (user?.active_account.onboarding_completed_at) {
    history.push('/welcome');
  }

  return (
    <Container>
      <Helmet>
        <title>Onboarding | datascore</title>
      </Helmet>

      <StepComponent
        onBack={() => prevStep && updateStep(prevStep)}
        onContinue={() => (nextStep ? updateStep(nextStep) : history.push('/welcome'))}
      />
    </Container>
  );
};

export default Onboarding;
