import { MouseEvent, useCallback, useMemo, useState } from 'react';
import {
  Divider,
  DropdownItemProps,
  Form,
  Grid,
  Header,
  Icon,
  Label,
  List,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react';

import { useListAllDatasetListsQuery, useListDatasetsQuery } from 'src/api/datasets';
import { Row } from 'src/styles';
import { BaseSchema, Dataset } from 'src/types';

const betaUrl = process.env.REACT_APP_BETA_URL;

type Props = {
  dataset: Dataset;
  token: string;
};

const DatasetV2Docs = ({ dataset, token }: Props) => {
  const { data: datasets, isLoading: datasetsLoading } = useListDatasetsQuery({ limit: 1000, offset: 0 });
  const { data: lists, isLoading: listsLoading } = useListAllDatasetListsQuery();
  const [selectedDatasetIDs, setSelectedDatasetIDs] = useState<string[]>([]);
  const [selectedListIDs, setSelectedListIDs] = useState<string[]>([]);
  const [selectedListID, setSelectedListID] = useState<string>('');

  const listsOptions = useMemo(() => {
    return (
      lists?.data
        .filter(l => dataset.id === l.dataset_id)
        .map(
          l =>
            ({
              key: l.id,
              value: l.id,
              text: l.name,
            } as DropdownItemProps)
        ) || []
    );
  }, [dataset.id, lists?.data]);

  const allListsOptions = useMemo(() => {
    return (
      lists?.data
        .filter(l => selectedDatasetIDs.length === 0 || selectedDatasetIDs.includes(l.dataset_id))
        .map(
          l =>
            ({
              key: l.id,
              value: l.id,
              text: l.name,
              description: datasets?.data.find(d => d.id === l.dataset_id)?.name,
            } as DropdownItemProps)
        ) || []
    );
  }, [datasets?.data, lists?.data, selectedDatasetIDs]);

  // useEffect(() => {
  //   if (selectedListIds.length > 0) return;
  //   if (listsLoading || !lists?.data.length) return;
  //   const list = lists.data.find(l => l.name === 'Default') || lists.data[0];
  //   setSelectedListIds([list.id]);
  // }, [lists?.data, listsLoading, selectedListIds]);

  const scrollToID = useCallback(
    (id: string) => (e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      const el = document.querySelector(`#${id}`);
      if (el !== null && el instanceof HTMLElement) {
        const offset = 12;

        let offsetTop = window.scrollY + el.getBoundingClientRect().top;
        if (offsetTop >= offset) {
          offsetTop -= offset;
        }

        const adminHeader = document.querySelector('header#admin-header');
        if (adminHeader) {
          offsetTop -= adminHeader.getBoundingClientRect().height;
        }

        const header = document.querySelector('header#header');
        if (header) {
          offsetTop -= header.getBoundingClientRect().height;
        }

        window.scrollTo({
          left: 0,
          top: offsetTop,
          behavior: 'smooth',
        });
      }
    },
    []
  );

  return (
    <Grid style={{ marginTop: 0 }}>
      <Grid.Row>
        <Grid.Column width={10}>
          <Segment id="table-of-contents">
            <Header as="h3">Table of Contents</Header>

            <List bulleted>
              <List.Item>
                <button className="anchor" onClick={scrollToID('insert-single-record')}>
                  Insert a Single Record
                </button>
              </List.Item>
              <List.Item>
                <button className="anchor" onClick={scrollToID('search-by-phone')}>
                  Search Records by Phone
                </button>
              </List.Item>
            </List>
          </Segment>

          <Segment id="insert-single-record">
            <Header as="h3">Insert a Single Record</Header>

            <p>
              The following is a simple example of a POST with JSON data. This endpoint also accepts query string
              parameters and the following content-types: <code>multipart/form-data</code>,{' '}
              <code>application/x-www-form-urlencoded</code>, and <code>application/json</code>.
            </p>

            <p>
              <strong>Please send all data in string format.</strong> The following example contains only a few fields
              to demonstrate the JSON formatting. This dataset may have additional columns defined. You can view the
              full schema on the right side of the page. If you need additional columns added to your dataset, please
              contact an admin.
            </p>

            <p>
              If the data you are sending contains a column that is not defined in your dataset, the data will be
              ignored and the columns will be listed in the <code>ignoredColumns</code> property of the response.
            </p>

            <Message>
              <pre>
                POST {`${betaUrl}/api/datasets-v2/data/${dataset.id}`}
                <br />
                Authorization: Bearer {token}
                <br />
                Content-type: application/json
                <br />
                <br />
                {`{\n    "firstname": "Joe",\n    "lastname": "Sample",\n    "zip": "12345",\n    "phone": "5035555555"\n}`}
              </pre>
            </Message>

            <p>
              If you are unable to send the api key in the Authorization HTTP Header, you also have the option to pass
              it in the URL via the query string parameters instead.
            </p>

            <Message>
              <pre>
                POST {`${betaUrl}/api/datasets-v2/data/${dataset.id}?token=${token}`}
                <br />
                Content-type: application/json
                <br />
                <br />
                {`{\n    "firstname": "Joe",\n    "lastname": "Sample",\n    "zip": "12345",\n    "phone": "5035555555"\n}`}
              </pre>
            </Message>

            <p>
              By default, new records will be written into the "Default" list. If you would like to insert into a
              specific list, you can specify the List ID in the <code>list_id</code> query string parameter. Select a
              list below to customize the exmaple URL.
            </p>

            <Form>
              <Form.Group>
                <Form.Select
                  label="List"
                  placeholder="Select a list"
                  clearable
                  loading={listsLoading}
                  options={listsOptions}
                  value={selectedListID}
                  onChange={(_, { value }) => setSelectedListID(value as string)}
                />
              </Form.Group>
            </Form>

            <Message>
              <pre>
                POST {`${betaUrl}/api/datasets-v2/data/${dataset.id}?list_id=${selectedListID}`}
                <br />
                Authorization: Bearer {token}
                <br />
                Content-type: application/json
                <br />
                <br />
                {`{\n    "firstname": "Joe",\n    "lastname": "Sample",\n    "zip": "12345",\n    "phone": "5035555555"\n}`}
              </pre>
            </Message>

            {/* <Divider />

            <Header as="h3">Send records in bulk</Header>

            <Message>
              <pre>
                POST {`${betaUrl}/api/datasets-v2/data/${dataset.id}/bulk`}
                <br />
                Authorization: Bearer {token}
                <br />
                Content-type: multipart/form-data
                <br />
                <br />
                file=@text.csv
              </pre>
            </Message>

            <p>
              <strong>Sample CSV Data</strong>
            </p>

            <Message>
              <pre>
                firstname,lastname,zip,phone
                <br />
                Joe,Sample,12345,5035555555
                <br />
                Bob,White,44444,9712223333
                <br />
                Lily,Hoyt,98765,5096667777
              </pre>
            </Message> */}

            <Divider />

            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h3">Success response</Header>

                  <Message success>
                    <pre>
                      Status Code: 200 OK
                      <br />
                      Content-type: application/json
                      <br />
                      <br />
                      {`{\n    "success": true,\n    "insertCount": 1\n}`}
                    </pre>
                  </Message>

                  <Message success>
                    <pre>
                      Status Code: 200 OK
                      <br />
                      Content-type: application/json
                      <br />
                      <br />
                      {`{\n    "success": true,\n    "insertCount": 1,\n    "message": "Warning: some data was not imported...",\n    "ignoredColumns": ["foo"]\n}`}
                    </pre>
                  </Message>
                </Grid.Column>

                <Grid.Column>
                  <Header as="h3">Error responses</Header>

                  <Message error>
                    <pre>
                      Status Code: 400 Bad Request
                      <br />
                      Content-type: application/json
                      <br />
                      <br />
                      {`{\n    "success": false,\n    "message": "Invalid dataset"\n}`}
                    </pre>
                  </Message>

                  <Message error>
                    <pre>
                      Status Code: 403 Forbidden
                      <br />
                      Content-type: application/json
                      <br />
                      <br />
                      {`{\n    "success": false,\n    "message": "Forbidden"\n}`}
                    </pre>
                  </Message>

                  <Message error>
                    <pre>
                      Status Code: 422 Unprocessable Entity
                      <br />
                      Content-type: application/json
                      <br />
                      <br />
                      {`{\n    "success": false,\n    "message": "Unprocessable Entity"\n}`}
                    </pre>
                  </Message>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Segment id="search-by-phone">
            <Row style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
              <Header as="h3" style={{ marginBottom: 0 }}>
                Search Records by Phone
              </Header>
              <button className="anchor" onClick={scrollToID('table-of-contents')}>
                Back to top <Icon name="arrow up" />
              </button>
            </Row>

            <p>
              This endpoint allows you to search and retrieve records from your dataset(s) using the phone number of the
              lead. Replace <code>{`{PHONE_NUMBER}`}</code> in the URL examples below with the desired number.
            </p>

            <Message>
              <pre>
                GET {`${betaUrl}/api/datasets-v2/data/search-by-phone/{PHONE_NUMBER}`}
                <br />
                Authorization: Bearer {token}
              </pre>
            </Message>

            <p>
              If you are unable to send the api key in the Authorization HTTP Header, you also have the option to pass
              it in the URL via the query string parameters instead.
            </p>

            <Message>
              <pre>GET {`${betaUrl}/api/datasets-v2/data/search-by-phone/{PHONE_NUMBER}?token=${token}`}</pre>
            </Message>

            <p>
              By default we will search all datasets. If you would like to search within specific datasets(s), you can
              specify the Dataset ID(s) in the <code>dataset_ids</code> query string parameter. If searching multiple
              datasets, separate each ID with a comma. Select dataset(s) below to customize the exmaple URL.
            </p>

            <Form>
              <Form.Group>
                <Form.Select
                  label="Dataset(s)"
                  clearable
                  multiple
                  placeholder="Select a dataset"
                  loading={datasetsLoading}
                  options={datasets?.data.map(l => ({ key: l.id, value: l.id, text: l.name })) || []}
                  value={selectedDatasetIDs}
                  onChange={(_, { value }) => setSelectedDatasetIDs(value as string[])}
                />
              </Form.Group>
            </Form>

            <Message>
              <pre>
                {`GET ${betaUrl}/api/datasets-v2/data/search-by-phone/{PHONE_NUMBER}?token=${token}&dataset_ids=${
                  selectedDatasetIDs.join(',') || ''
                }`}
              </pre>
            </Message>

            <p>
              By default we will search all lists in the selected dataset(s). If you would like to search within
              specific list(s), you can specify the List ID(s) in the <code>list_ids</code> query string parameter. If
              searching multiple lists, separate each ID with a comma. Select list(s) below to customize the exmaple
              URL.
            </p>

            <Form>
              <Form.Group>
                <Form.Select
                  label="List(s)"
                  clearable
                  multiple
                  placeholder="Select a list"
                  loading={listsLoading}
                  options={allListsOptions}
                  value={selectedListIDs}
                  onChange={(_, { value }) => setSelectedListIDs(value as string[])}
                />
              </Form.Group>
            </Form>

            <Message>
              <pre>
                {`GET ${betaUrl}/api/datasets-v2/data/search-by-phone/{PHONE_NUMBER}?token=${token}&dataset_ids=${
                  selectedDatasetIDs.join(',') || ''
                }&list_ids=${selectedListIDs.join(',') || ''}`}
              </pre>
            </Message>

            <p>
              The <code>results[i].data</code> field of each result will contain a different set of fields depending on
              what data was initially inserted into the dataset. Some fields may be empty strings or not defined at all.
            </p>

            <p>
              If the phone number was inserted into the dataset multiple times, it will show up once for each occurrence
              in the dataset.
            </p>

            <Divider />

            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h3">Success response</Header>

                  <Message success>
                    <pre>
                      Status Code: 200 OK
                      <br />
                      Content-type: application/json
                      <br />
                      <br />
                      {`{\n  "results": [\n    {\n      "createdAt": "2024-04-19T23:53:51.316626Z",\n      "data": {\n        "city": "Portland",\n        "firstname": "Joe",\n        "lastname": "Sample",\n        "phone": "5035555555",\n        "state": "OR",\n        "zip": "97202"\n      },\n    {\n      "createdAt": "2024-04-19T23:53:51.316626Z",\n      "data": {\n        "firstname": "Joe",\n        "lastname": "Sample",\n        "phone": "5035555555",\n      },\n      "datasetId": "${
                        dataset.id
                      }",\n      "id": "00000000-0000-0000-0000-000000000000",\n      "listId": "${
                        selectedListIDs || '00000000-0000-0000-0000-000000000000'
                      }"\n    }\n  ]\n}`}
                    </pre>
                  </Message>
                </Grid.Column>

                <Grid.Column>
                  <Header as="h3">Error responses</Header>

                  <Message error>
                    <pre>
                      Status Code: 403 Forbidden
                      <br />
                      Content-type: application/json
                      <br />
                      <br />
                      {`{\n    "message": "Forbidden"\n}`}
                    </pre>
                  </Message>

                  <Message error>
                    <pre>
                      Status Code: 400 Bad Request
                      <br />
                      Content-type: application/json
                      <br />
                      <br />
                      {`{\n    "message": "invalid phone number"\n}`}
                    </pre>
                  </Message>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Grid.Column>

        <Grid.Column width={6}>
          <Segment>
            <Header as="h3">Schema</Header>

            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Column</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Required</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {[...BaseSchema, ...(dataset.custom_fields || [])].map(c => {
                  const isRequired = dataset.rules.required_fields?.includes(c.name);

                  return (
                    <Table.Row key={c.id}>
                      <Table.Cell>{c.name}</Table.Cell>
                      <Table.Cell>{c.type}</Table.Cell>
                      <Table.Cell>
                        <Label color={isRequired ? 'green' : undefined}>{isRequired ? 'Yes' : 'No'}</Label>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>

            <Header as="h3">Notes</Header>

            <p>
              As noted in the documentation on the left, <strong>please send all data in string format.</strong>{' '}
              Although the data is accepted only as strings, validation rules will be applied based on the field type
              defined in the schema above. If validation fails for any field, the data in that field may be lost but the
              row will still be inserted to the dataset.
            </p>

            <p>
              <strong>Data Validation</strong>
            </p>

            <ul>
              <li>
                <code>TIMESTAMP</code> data should be formatted: <code>YYYY-MM-DD HH:MM[:SS[.SSSSSS]]</code>
              </li>
            </ul>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default DatasetV2Docs;
