import { delay } from 'lodash';
import { DragEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactFlow, {
  addEdge,
  Background,
  Connection,
  Controls,
  Edge,
  Node,
  NodeChange,
  ReactFlowInstance,
  ReactFlowProvider,
  updateEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'react-flow-renderer';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { useGetDataflowQuery, useListRevisionsQuery, useSaveRevisionMutation } from 'src/api/dataflows';
import BillingWarning from 'src/components/BillingWarning';
import FlowHeader from './components/FlowHeader';
import { Nodes, NodesKeys, Overlays } from './components/widgets';
import { CombineData } from './components/widgets/Combine/types';
import { useOverlay } from './components/widgets/components/NodeOverlay';
import { getConnectedSources, getConnectedSourcesColumns } from './components/widgets/hooks';
import WidgetsOverlay from './components/widgets/Overlay';
import { Alias } from './components/widgets/Overlay/types';
import { NodeTypes } from './components/widgets/types';
import { FlowContent, FlowLayout, FlowSidebar } from './styles';

const changeReasons: { [key: string]: string } = {
  remove: 'deleted',
  position: 'position-updated',
};

const Flow = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState<NodeTypes>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<any>([]);
  const [rfInstance, setRfInstance] = useState<ReactFlowInstance<NodeTypes, any> | null>(null);
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const { setViewport } = useReactFlow();

  const [currentNode, setCurrentNode] = useState<Node<NodeTypes> | null>(null);
  const [currentOverlay, setCurrentOverlay] = useState<NodesKeys | null | undefined>(null);
  const { isOpen: isWidgetsOverlayOpen, setIsOpen: setWidgetsOverlayOpenState } = useOverlay(true);

  const { id } = useParams<{ id: string }>();
  const dataflowId = useMemo(() => parseInt(id), [id]);

  const { data: { revisions, unpublishedCount } = {} } = useListRevisionsQuery(dataflowId, { limit: 10, offset: 0 });
  const [revision] = revisions?.data || [];
  const { mutateAsync: saveRevision } = useSaveRevisionMutation();

  const { data: dataflow } = useGetDataflowQuery(dataflowId);

  const Overlay = useMemo(() => (currentOverlay ? Overlays[currentOverlay] : null), [currentOverlay]);

  const onPaneClick = useCallback(() => {
    setWidgetsOverlayOpenState(true);
  }, [setWidgetsOverlayOpenState]);

  const openNodeOverlay = useCallback(
    (node: Node<NodeTypes>) => {
      const nodeType = node.type as NodesKeys;
      if (node.type && !Overlays[nodeType]) return;

      setCurrentOverlay(nodeType);
      setCurrentNode(node);
      setWidgetsOverlayOpenState(false);
    },
    [setCurrentOverlay, setWidgetsOverlayOpenState]
  );

  const onSave = useCallback(
    (event: string) => {
      if (!rfInstance || !event) return;

      const flow = rfInstance.toObject();

      saveRevision({
        dataflowId,
        event,
        payload: JSON.stringify(flow),
      });
    },
    [saveRevision, rfInstance, dataflowId]
  );

  const onNodesDelete = useCallback(() => {
    setWidgetsOverlayOpenState(true);
  }, [setWidgetsOverlayOpenState]);

  const updateNode = useCallback(
    (id: string, data: NodeTypes) => {
      setNodes(nodes => {
        nodes = nodes.map(node => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              ...data,
            };

            setCurrentNode(node);
          }

          return node;
        });

        return syncNodesState(edges, nodes);
      });
    },
    [setNodes, edges]
  );

  const saveOnConnect = useCallback(
    (connection: Connection) => {
      if (connection.source === connection.target) return;

      // Prevent the user to connect the same source twice for nodes that has multiple targets
      const sameSource = edges.some(edge => edge.source === connection.source && edge.target === connection.target);
      if (sameSource) return;

      // Prevent user to connect more than one source to a target
      const edge = edges.find(edge => edge.target === connection.target);
      if (edge !== undefined && edge.targetHandle === null) return;

      setEdges(eds => addEdge(connection, eds));

      delay(() => onSave('edge-connected'), 100);
    },
    [setEdges, onSave, edges]
  );

  const saveOnEdgeUpdate = useCallback(
    (oldEdge: Edge<any>, newConnection: Connection) => {
      setEdges(els => updateEdge(oldEdge, newConnection, els));

      delay(() => onSave('edge-updated'), 100);
    },
    [setEdges, onSave]
  );

  const saveOnEdgesChange = useCallback(
    (edges: any[]) => {
      onEdgesChange(edges);

      edges.forEach(edge => {
        const { type } = edge;

        const reason = changeReasons[type];

        if (reason) delay(() => onSave(`edge-${reason}`), 100);
      });
    },
    [onSave, onEdgesChange]
  );

  const saveOnNodeChanges = useCallback(
    (nodes: NodeChange[]) => {
      onNodesChange(nodes);

      nodes.forEach(node => {
        const { type, dragging } = node as any;

        if (type === 'position' && dragging) return;

        const reason = changeReasons[type];

        if (reason) delay(() => onSave(`node-${reason}`), 100);
      });
    },
    [onSave, onNodesChange]
  );

  const onDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    event => {
      event.preventDefault();

      if (!reactFlowWrapper.current || !rfInstance) return;

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const dragData = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof dragData === 'undefined' || !dragData) {
        return;
      }

      const { type, name } = JSON.parse(dragData) as Pick<Alias, 'type' | 'name'>;

      const position = rfInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const newNode = {
        id: uuid(),
        type,
        position,
        data: {
          label: name,
          name,
        },
      };

      setNodes(nds => nds.concat(newNode));

      delay(() => onSave('node-added'), 100);
    },
    [rfInstance, setNodes, onSave]
  );

  useEffect(() => {
    if (!revision?.payload) return;

    const flow = typeof revision.payload === 'string' ? JSON.parse(revision.payload) : revision.payload;
    const { x = 0, y = 0, zoom = 1 } = flow.viewport;

    setNodes(flow.nodes);
    setEdges(flow.edges);
    setViewport({ x, y, zoom });

    const selectedNode = flow.nodes.find((node: any) => node.selected);

    if (selectedNode) {
      openNodeOverlay(selectedNode);
    }
  }, [setNodes, setEdges, setViewport, openNodeOverlay, revision?.payload]);

  return (
    <FlowLayout>
      <FlowHeader
        dataflow={dataflow}
        revisionId={revision?.id}
        unpublishedCount={unpublishedCount}
        nodes={nodes}
        edges={edges}
      />

      <FlowContent ref={reactFlowWrapper}>
        <ReactFlow
          nodeTypes={Nodes}
          nodes={nodes}
          edges={edges}
          onPaneClick={onPaneClick}
          onNodesChange={saveOnNodeChanges}
          onConnect={saveOnConnect}
          onEdgeUpdate={saveOnEdgeUpdate}
          onEdgesChange={saveOnEdgesChange}
          onInit={setRfInstance}
          onNodeClick={(_, node) => openNodeOverlay(node)}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onNodesDelete={onNodesDelete}
        >
          <Background />
          <Controls />
        </ReactFlow>
      </FlowContent>

      <FlowSidebar>
        {!isWidgetsOverlayOpen && Overlay && currentNode ? (
          <Overlay node={currentNode} updateNode={updateNode} onSave={onSave} nodes={nodes} edges={edges} />
        ) : (
          <WidgetsOverlay />
        )}
      </FlowSidebar>
    </FlowLayout>
  );
};

const syncNodesState = (edges: Edge<any>[], nodes: Node<any>[]) => {
  return nodes.map(node => {
    switch (node.type) {
      case 'combine_join':
      case 'combine_union':
        node.data.datasetColumnsOutput = getConnectedSourcesColumns(edges, nodes, node.id);
        (node as Node<CombineData>).data.sources = getConnectedSources(edges, nodes, node.id);
        return node;
      case 'filter':
        node.data.datasetColumnsOutput = getConnectedSourcesColumns(edges, nodes, node.id);
        return node;
      case 'blacklist':
        node.data.datasetColumnsOutput = getConnectedSourcesColumns(edges, nodes, node.id);
        return node;
      default:
        return node;
    }
  });
};

// INFO: this component is needed to be able to access to the React flow store within the Flow component
const DataflowsView = () => (
  <ReactFlowProvider>
    <Helmet>
      <title>Edit - Dataflow | datascore</title>
    </Helmet>

    <BillingWarning />

    <Flow />
  </ReactFlowProvider>
);

export default DataflowsView;
