import { Form } from 'semantic-ui-react';

import { SendgridInboundParseActionTrigger } from 'src/types';

type Props = {
  trigger: SendgridInboundParseActionTrigger;
  onChange: ({ name, value }: { name: string; value: string }) => void;
};

const TriggerSendGridEmailSettings = ({ trigger: action, onChange }: Props) => {
  return (
    <>
      <Form.Group widths="equal">
        <Form.Input
          name="from"
          label="From Email"
          onChange={(_, { name, value }) => onChange({ name, value: String(value) })}
        />
        <Form.Input
          name="replyTo"
          label="Reply-To Email"
          onChange={(_, { name, value }) => onChange({ name, value: String(value) })}
        />
      </Form.Group>
      <Form.TextArea
        name="text"
        label="Body (Text)"
        onChange={(_, { name, value }) => onChange({ name, value: String(value) })}
      />
    </>
  );
};

export default TriggerSendGridEmailSettings;
