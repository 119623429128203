import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';

import { useEditBillingConnectProTransactionMutation } from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { BillingConnectProTransaction } from 'src/types';

type Formdata = {
  title: string;
  note: string;
};

type ValidationErrors = {
  title?: string;
  note?: string;
};

type Props = {
  transaction: BillingConnectProTransaction;
};

const getInitialFormdata = (t: BillingConnectProTransaction): Formdata => {
  return {
    title: t.title || '',
    note: t.note || '',
  };
};

const EditBalanceTransaction = ({ transaction }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData | undefined>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<Formdata>(() => getInitialFormdata(transaction));
  const { isLoading, mutateAsync } = useEditBillingConnectProTransactionMutation();

  const validate = (input: Formdata): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    if (input.title === '') {
      validationErrors.title = 'Title is required';
    }
    if (input.note === '') {
      validationErrors.note = 'Note is required';
    }

    setErrors(validationErrors);

    return validationErrors;
  };

  const onChange = useCallback(({ name, value }: { name: string; value: string }) => {
    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  const onSubmit = useCallback(async () => {
    if (!isEmpty(validate(formdata))) {
      return;
    }

    setApiMessage(undefined);

    try {
      await mutateAsync({ accountId: transaction.accountId, transactionId: transaction.id, ...formdata });
      setOpen(false);
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [formdata, mutateAsync, transaction.accountId, transaction.id]);

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<Button icon="pencil" color="blue" title="Edit" />}
    >
      <Modal.Header>Edit Transaction</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Form.Input
            label="Title"
            name="title"
            onChange={(_, { name, value }) => onChange({ name, value })}
            value={formdata.title}
            error={errors.title}
          />

          <Form.Input
            label="Note"
            name="note"
            onChange={(_, { name, value }) => onChange({ name, value })}
            value={formdata.note}
            error={errors.note}
          />

          <Form.Button loading={isLoading} color="blue" fluid>
            Save
          </Form.Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default EditBalanceTransaction;
