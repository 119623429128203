import { useMutation, useQuery, useQueryClient } from 'react-query';

import { PaginatedResponse, VoiceConfig } from 'src/types';
import { ApiResponse, http } from './http-common';

export type VoiceConfigResponse = ApiResponse & {
  config?: VoiceConfig;
};

export type VoiceConfigsResponse = ApiResponse & {
  configs: PaginatedResponse<VoiceConfig>;
};

export type ListVoiceConfigsParams = {
  limit: number;
  offset: number;
};

export const useListVoiceConfigsQuery = (params: ListVoiceConfigsParams) => {
  return useQuery(['voice', 'configs', params], async () => {
    const res = await http.get<VoiceConfigsResponse>('/api/voice/configs', { params });
    return res.data.configs;
  });
};

export const useGetVoiceConfigQuery = (id: string) => {
  return useQuery(['voice', 'configs', id], async () => {
    if (!id) return undefined;
    const res = await http.get<VoiceConfigResponse>(`/api/voice/configs/${id}`);
    return res.data.config;
  });
};

export type AddVoiceConfigParams = {
  name: string;
};

export const useAddVoiceConfigMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: AddVoiceConfigParams) => {
      const res = await http.post<VoiceConfigResponse>(`/api/voice/configs`, params);
      return res.data.config;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['voice', 'configs']);
      },
    }
  );
};

export type SaveVoiceConfigParams = {
  config: VoiceConfig;
};

export const useSaveVoiceConfigMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ config }: SaveVoiceConfigParams) => {
      const res = await http.post<VoiceConfigResponse>(`/api/voice/configs/${config.id}`, config);
      return res.data.config;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['voice', 'configs']);
      },
    }
  );
};

export const useDeleteVoiceConfigMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      const res = await http.delete<ApiResponse>(`/api/voice/configs/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['voice', 'configs']);
      },
    }
  );
};

export const useDuplicateVoiceConfigMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      const res = await http.post<ApiResponse>(`/api/voice/configs/${id}/duplicate`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['voice', 'configs']);
      },
    }
  );
};
