import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { Divider, Grid, Header, Segment } from 'semantic-ui-react';

import { useGetBigqueryDatasetQuery } from 'src/api/admin/bigquery';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Loading from 'src/components/Loading';
import PaginatedTable from 'src/components/PaginatedTable';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader } from 'src/styles';
import AddTable from './AddTable';
import AdminBigqueryDatasetName from './AdminBigqueryDatasetName';
import AdminBigqueryTablesListBody from './AdminBigqueryTablesListBody';

const AdminBigqueryDatasetEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { data: dataset, isLoading } = useGetBigqueryDatasetQuery(Number(id));

  if (isLoading) {
    return <Loading />;
  }
  if (typeof dataset === 'undefined') {
    return <NotFound />;
  }

  return (
    <Container>
      <Helmet>
        <title>Edit Bigquery Dataset - Admin | datascore</title>
      </Helmet>

      <PageHeader>
        <Breadcrumbs crumbs={['Admin', 'Bigquery Datasets', 'Edit']} />
      </PageHeader>

      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header>General Settings</Header>
            </Grid.Column>
            <Grid.Column width={12}>
              <AdminBigqueryDatasetName dataset={dataset} />
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row>
            <Grid.Column width={4}>
              <Header>Tables</Header>
              <AddTable dataset={dataset} />
            </Grid.Column>
            <Grid.Column width={12}>
              <PaginatedTable
                headers={['Display Name', 'Table ID', 'Time Column', '']}
                renderBody={AdminBigqueryTablesListBody}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  );
};

export default AdminBigqueryDatasetEdit;
