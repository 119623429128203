import { formatDistanceToNow } from 'date-fns';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import {
  Button,
  Dropdown,
  DropdownItemProps,
  Form,
  Header,
  Icon,
  Input,
  Label,
  Placeholder,
  PlaceholderParagraph,
  SemanticCOLORS,
  Table,
} from 'semantic-ui-react';
import styled from 'styled-components';

import {
  useGetTwilioIncomingPhonesJobsQuery,
  useGetTwilioIncomingPhonesQuery,
  useGetTwilioStudioFlowsQuery,
  useGetTwilioTrustProfilesQuery,
  useRefetchTwilioIncomingPhonesMutation,
} from 'src/api/auth/account-twilio';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useListVoiceConfigsQuery } from 'src/api/voice-configs';
import PaginatedTable, { RenderProps } from 'src/components/PaginatedTable';
import { Row } from 'src/styles';
import theme from 'src/styles/theme';
import { JobStatus, TwilioIncomingPhone, TwilioStudioFlow, VoiceConfig } from 'src/types';
import TwilioReassignStudioFlowModal from './TwilioReassignStudioFlowModal';
import TwilioReassignTrustProfilesModal from './TwilioReassignTrustProfilesModal';
import TwilioReassignVoiceConfigModal from './TwilioReassignVoiceConfigModal';
import TwilioReleaseIncomingPhonesModal from './TwilioReleaseIncomingPhonesModal';

const { REACT_APP_BETA_URL: betaURL } = process.env;
const DOWNLOAD_URL = `${betaURL}/api/auth/account/twilio/incoming-phones/download`;
const TABLE_HEADERS = [
  'Purchase Date',
  'Phone Number',
  'Configuration',
  'Business Profile',
  'SHAKEN/STIR',
  'CNAM',
  'Voice Integrity',
];

const ClippedText = styled.span`
  max-width: 400px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const RefetchButton = () => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync, isLoading: mutateLoading } = useRefetchTwilioIncomingPhonesMutation();
  const { data: job, isLoading: jobLoading, refetch } = useGetTwilioIncomingPhonesJobsQuery();

  const activeJobRunning = job?.status === JobStatus.Processing || job?.status === JobStatus.Pending;

  const loading = mutateLoading || jobLoading || activeJobRunning;

  useEffect(() => {
    let timeout: number | null = null;
    if (activeJobRunning) {
      timeout = setInterval(refetch, 3000);
    }
    return () => {
      if (timeout !== null) {
        clearInterval(timeout);
        timeout = null;
      }
    };
  }, [activeJobRunning, refetch]);

  const onClick = useCallback(async () => {
    setApiMessage(undefined);
    try {
      await mutateAsync();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
      setTimeout(() => setApiMessage(undefined), 3000);
    }
  }, [mutateAsync]);

  const jobStage = job && job.stage;
  let jobProgress = 0;
  switch (jobStage) {
    case 1:
      jobProgress = job?.progress1 || 0;
      break;
    case 2:
      jobProgress = job?.progress2 || 0;
      break;
    case 3:
      jobProgress = job?.progress3 || 0;
      break;
  }

  return (
    <Button
      type="button"
      title="Refetch Data from Twilio"
      onClick={!loading ? onClick : undefined}
      color={(apiMessage && !apiMessage.success && 'red') || undefined}
    >
      <Icon name={apiMessage?.message ? 'warning sign' : 'sync alternate'} loading={loading} />
      {apiMessage?.message || (loading ? `Stage ${jobStage} - ${(jobProgress * 100).toFixed(0)}%` : 'Refetch')}
    </Button>
  );
};

type TableBodyProps = RenderProps & {
  isSidSelected: (sid: string) => boolean;
  search: string;
  selectedDates: [Date, Date] | [null, null];
  profiles: string[];
  setSids: Dispatch<SetStateAction<{ sid: string; checked: boolean }[]>>;
  updateSelectedSid: (sid: string, checked: boolean) => void;
};

const TableBody = ({
  currentPage,
  isSidSelected,
  limit,
  search,
  selectedDates,
  setPageCount,
  setSids,
  profiles,
  updateSelectedSid,
}: TableBodyProps) => {
  const { data: sfData, isLoading: sfLoading } = useGetTwilioStudioFlowsQuery();
  const { data: vcData, isLoading: vcLoading } = useListVoiceConfigsQuery({ limit: 1000, offset: 0 });
  const { data: tpData, isLoading: tpLoading } = useGetTwilioTrustProfilesQuery();
  const { data: ipData, isLoading: ipLoading } = useGetTwilioIncomingPhonesQuery({
    limit,
    offset: limit * (currentPage - 1),
    search,
    profiles,
    startDate: selectedDates[0] !== null ? selectedDates[0].toISOString() : '',
    endDate: selectedDates[1] !== null ? selectedDates[1].toISOString() : '',
  });

  const isLoading = sfLoading || vcLoading || tpLoading || ipLoading;

  useEffect(() => {
    const phones = ipData?.phones?.data;
    if (!phones) return;

    setSids(phones.map(p => ({ sid: p.sid, checked: false })));
  }, [ipData?.phones?.data, setSids, updateSelectedSid]);

  useEffect(() => {
    const total = ipData?.phones?.total || 1;
    const count = Math.ceil(total / limit);
    setPageCount(count);
  }, [ipData, limit, setPageCount]);

  const incomingPhones = useMemo<TwilioIncomingPhone[]>(() => ipData?.phones?.data || [], [ipData?.phones?.data]);

  const studioFlows = useMemo(() => {
    const flows = new Map<string, TwilioStudioFlow>();
    if (!sfData?.flows) {
      return flows;
    }

    sfData.flows.forEach(f => {
      flows.set(f.sid, f);
    });

    return flows;
  }, [sfData?.flows]);

  const getStudioFlowName = useCallback(
    (sid: string): string => {
      if (!studioFlows.has(sid)) return '';
      const name = studioFlows.get(sid)?.friendly_name;
      if (!name) return '';
      return `Studio Flow: ${name}`;
    },
    [studioFlows]
  );

  const voiceConfigs = useMemo(() => {
    const configs = new Map<string, VoiceConfig>();
    if (!vcData?.data) {
      return configs;
    }

    vcData.data.forEach(c => {
      configs.set(c.id, c);
    });

    return configs;
  }, [vcData?.data]);

  const getVoiceConfigName = useCallback(
    (url: string): string => {
      if (!/api\/tw\/voice/.test(url)) return '';

      const id =
        url
          .substring(url.indexOf('/api/tw/voice/') + 14)
          .split('/')
          .shift() || '';

      if (!voiceConfigs.has(id)) return '';
      const name = voiceConfigs.get(id)?.name;
      if (!name) return '';
      return `Voice Config: ${name}`;
    },
    [voiceConfigs]
  );

  const trustProfiles = useMemo(() => {
    const profiles = new Map<string, { friendly_name: string; status: string }>();
    if (
      !tpData?.customerProfiles ||
      !tpData?.shakenStirProfiles ||
      !tpData.cnamProfiles ||
      !tpData.voiceIntegrityProfiles
    ) {
      return profiles;
    }

    tpData.customerProfiles.forEach(({ sid, friendly_name, status }) => {
      profiles.set(sid, { friendly_name, status });
    });

    tpData.shakenStirProfiles.forEach(({ sid, friendly_name, status }) => {
      profiles.set(sid, { friendly_name, status });
    });

    tpData.cnamProfiles.forEach(({ sid, friendly_name, status }) => {
      profiles.set(sid, { friendly_name, status });
    });

    tpData.voiceIntegrityProfiles.forEach(({ sid, friendly_name, status }) => {
      profiles.set(sid, { friendly_name, status });
    });

    return profiles;
  }, [tpData]);

  const getTrustProfileName = useCallback(
    (sid: string): string => {
      if (!trustProfiles.has(sid)) return 'None';
      return trustProfiles.get(sid)?.friendly_name || 'None';
    },
    [trustProfiles]
  );

  const getTrustProfileStatus = useCallback(
    (sid: string | null): string | undefined => {
      if (!sid || !trustProfiles.has(sid)) return undefined;
      return trustProfiles.get(sid)?.status;
    },
    [trustProfiles]
  );

  const getTrustProfileStatusColor = useCallback(
    (sid: string | null): SemanticCOLORS | undefined => {
      if (!sid || !trustProfiles.has(sid)) return undefined;

      switch (trustProfiles.get(sid)?.status) {
        case 'twilio-approved':
          return 'green';
        case 'twilio-rejected':
          return 'red';
        default:
          return 'yellow';
      }
    },
    [trustProfiles]
  );

  const onCheckPhone = useCallback(
    (sid: string) => {
      const checked = !isSidSelected(sid);
      updateSelectedSid(sid, checked);
    },
    [isSidSelected, updateSelectedSid]
  );

  if (isLoading) {
    return (
      <>
        {Array(10)
          .fill(0)
          .map((_, i) => (
            <Table.Row key={i} style={{ position: 'relative', height: '48px' }}>
              <Table.Cell>
                <Placeholder>
                  <PlaceholderParagraph>
                    <Placeholder.Line />
                  </PlaceholderParagraph>
                </Placeholder>
              </Table.Cell>
              {TABLE_HEADERS.map(h => (
                <Table.Cell key={h}>
                  <Placeholder>
                    <PlaceholderParagraph>
                      <Placeholder.Line />
                    </PlaceholderParagraph>
                  </Placeholder>
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
      </>
    );
  }

  return (
    <>
      {incomingPhones.map(p => (
        <Table.Row key={p.phone_number}>
          <Table.Cell>
            <Form.Checkbox value={p.sid} checked={isSidSelected(p.sid)} onChange={() => onCheckPhone(p.sid)} />
          </Table.Cell>
          <Table.Cell>
            <Label title={p.created_at}>
              {formatDistanceToNow(new Date(p.created_at), { addSuffix: true, includeSeconds: true })}
            </Label>
          </Table.Cell>
          <Table.Cell>{p.phone_number}</Table.Cell>
          <Table.Cell>
            <Row>
              <strong style={{ width: 80 }}>Voice</strong>
              <ClippedText style={!p.voice_flow_sid && !p.voice_url ? { color: theme.gray } : undefined}>
                {getStudioFlowName(p.voice_flow_sid) ||
                  getVoiceConfigName(p.voice_url) ||
                  p.voice_url ||
                  'Unconfigured'}
              </ClippedText>
            </Row>
            <Row>
              <strong style={{ width: 80 }}>Messaging</strong>
              <ClippedText style={!p.sms_flow_sid && !p.sms_url ? { color: theme.gray } : undefined}>
                {getStudioFlowName(p.sms_flow_sid) || getVoiceConfigName(p.sms_url) || p.sms_url || 'Unconfigured'}
              </ClippedText>
            </Row>
          </Table.Cell>
          <Table.Cell>
            <Label
              color={getTrustProfileStatusColor(p.customer_profile_sid)}
              title={`Status: ${getTrustProfileStatus(p.customer_profile_sid)}`}
            >
              {getTrustProfileName(p.customer_profile_sid || '')}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <Label
              color={getTrustProfileStatusColor(p.shaken_stir_profile_sid)}
              title={`Status: ${getTrustProfileStatus(p.shaken_stir_profile_sid)}`}
            >
              {getTrustProfileName(p.shaken_stir_profile_sid || '')}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <Label
              color={getTrustProfileStatusColor(p.cnam_profile_sid)}
              title={`Status: ${getTrustProfileStatus(p.cnam_profile_sid)}`}
            >
              {getTrustProfileName(p.cnam_profile_sid || '')}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <Label
              color={getTrustProfileStatusColor(p.voice_integrity_profile_sid)}
              title={`Status: ${getTrustProfileStatus(p.voice_integrity_profile_sid)}`}
            >
              {getTrustProfileName(p.voice_integrity_profile_sid || '')}
            </Label>
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

const getSelectedDates = ([start, end]: [Date | null, Date | null]): [Date, Date] | [null, null] => {
  if (start === null || end === null) return [null, null];
  return [start, end];
};

const TwilioPhonesPaginatedTable = () => {
  const [search, setSearch] = useState('');
  const [daterange, setDaterange] = useState<[Date | null, Date | null]>([null, null]);
  const [profiles, setProfiles] = useState<string[]>([]);
  const [sids, setSids] = useState<{ sid: string; checked: boolean }[]>([]);
  const [isReassignVoiceConfigModalOpen, setIsReassignVoiceConfigModalOpen] = useState(false);
  const [isReassignStudioFlowModalOpen, setIsReassignStudioFlowModalOpen] = useState(false);
  const [isReassignTrustProfilesModalOpen, setIsReassignTrustProfilesModalOpen] = useState(false);
  const [isReleaseModalOpen, setIsReleaseModalOpen] = useState(false);
  const { data: sfData, isLoading: sfLoading } = useGetTwilioStudioFlowsQuery();
  const { data: vcData, isLoading: vcLoading } = useListVoiceConfigsQuery({ limit: 1000, offset: 0 });
  const { data: tpData, isLoading: tpLoading } = useGetTwilioTrustProfilesQuery();

  const selectedDates = getSelectedDates(daterange);

  const updateSelectedSid = useCallback(
    (sid: string, checked: boolean) =>
      setSids(prev => {
        return [...prev].map(s => {
          if (s.sid !== sid) return s;
          s.checked = checked;
          return s;
        });
      }),
    []
  );

  const isSidSelected = useCallback(
    (sid: string) => {
      return sids.filter(s => s.sid === sid && s.checked).length > 0;
    },
    [sids]
  );

  const onSelectAll = useCallback((checked: boolean) => {
    setSids(prev => {
      return [...prev].map(s => {
        s.checked = checked;
        return s;
      });
    });
  }, []);

  const onChangeDates = useCallback((daterange: [Date | null, Date | null]) => {
    setDaterange(daterange);
  }, []);

  const selectedSids = sids.filter(s => s.checked).map(s => s.sid);
  const allSelected = sids.length > 0 && selectedSids.length === sids.length;

  const profileOptions = [
    {
      key: 'studioFlow:none',
      value: 'studioFlow:none',
      text: 'None',
      description: 'Studio Flow',
    },
    ...(sfData?.flows?.map(
      (f): DropdownItemProps => ({
        key: f.sid,
        value: `studioFlow:${f.sid}`,
        text: f.friendly_name,
        description: 'Studio Flow',
      })
    ) || []),
    ...(vcData?.data?.map(
      (c): DropdownItemProps => ({
        key: c.id,
        value: `voiceConfig:${c.id}`,
        text: c.name,
        description: 'Voice Config',
      })
    ) || []),

    {
      key: 'customerProfile:none',
      value: 'customerProfile:none',
      text: 'None',
      description: 'Business Profile',
    },
    ...(tpData?.customerProfiles?.map(
      (p): DropdownItemProps => ({
        key: p.sid,
        value: `customerProfile:${p.sid}`,
        text: p.friendly_name,
        description: 'Business Profile',
      })
    ) || []),

    {
      key: 'shakenStir:none',
      value: 'shakenStir:none',
      text: 'None',
      description: 'SHAKEN/STIR',
    },
    ...(tpData?.shakenStirProfiles?.map(
      (p): DropdownItemProps => ({
        key: p.sid,
        value: `shakenStir:${p.sid}`,
        text: p.friendly_name,
        description: 'SHAKEN/STIR',
      })
    ) || []),

    {
      key: 'cnam:none',
      value: 'cnam:none',
      text: 'None',
      description: 'CNAM',
    },
    ...(tpData?.cnamProfiles?.map(
      (p): DropdownItemProps => ({
        key: p.sid,
        value: `cnam:${p.sid}`,
        text: p.friendly_name,
        description: 'CNAM',
      })
    ) || []),

    {
      key: 'voiceIntegrity:none',
      value: 'voiceIntegrity:none',
      text: 'None',
      description: 'Voice Integrity',
    },
    ...(tpData?.voiceIntegrityProfiles?.map(
      (p): DropdownItemProps => ({
        key: p.sid,
        value: `voiceIntegrity:${p.sid}`,
        text: p.friendly_name,
        description: 'Voice Integrity',
      })
    ) || []),
  ];

  return (
    <>
      <Row style={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
        <Header style={{ marginBottom: 0 }}>Active Numbers</Header>

        <div>
          <Button as="a" href={DOWNLOAD_URL} basic>
            <Icon name="download" /> Export CSV
          </Button>

          <Dropdown button floating text="Actions" disabled={selectedSids.length === 0} direction="left">
            <Dropdown.Menu>
              <Dropdown.Item
                key="release"
                text={`Release ${selectedSids.length} Selected`}
                disabled={selectedSids.length === 0}
                onClick={() => setIsReleaseModalOpen(true)}
              />
              <Dropdown.Item
                key="reassign-studio-flow"
                text={`Reassign Studio Flow to ${selectedSids.length} Selected`}
                disabled={selectedSids.length === 0}
                onClick={() => setIsReassignStudioFlowModalOpen(true)}
              />
              <Dropdown.Item
                key="reassign-voice-config"
                text={`Reassign Voice Config to ${selectedSids.length} Selected`}
                disabled={selectedSids.length === 0}
                onClick={() => setIsReassignVoiceConfigModalOpen(true)}
              />
              <Dropdown.Item
                key="reassign-trust-profiles"
                text={`Reassign Trust Profiles to ${selectedSids.length} Selected`}
                disabled={selectedSids.length === 0}
                onClick={() => setIsReassignTrustProfilesModalOpen(true)}
              />
            </Dropdown.Menu>
          </Dropdown>

          <RefetchButton />

          <TwilioReleaseIncomingPhonesModal
            sids={selectedSids}
            open={isReleaseModalOpen}
            onSuccess={() => setSids([])}
            onClose={() => setIsReleaseModalOpen(false)}
          />
          <TwilioReassignStudioFlowModal
            sids={selectedSids}
            open={isReassignStudioFlowModalOpen}
            onSuccess={() => setSids([])}
            onClose={() => setIsReassignStudioFlowModalOpen(false)}
          />
          <TwilioReassignVoiceConfigModal
            sids={selectedSids}
            open={isReassignVoiceConfigModalOpen}
            onSuccess={() => setSids([])}
            onClose={() => setIsReassignVoiceConfigModalOpen(false)}
          />
          <TwilioReassignTrustProfilesModal
            sids={selectedSids}
            open={isReassignTrustProfilesModalOpen}
            onSuccess={() => setSids([])}
            onClose={() => setIsReassignTrustProfilesModalOpen(false)}
          />
        </div>
      </Row>

      <Form onSubmit={e => e.preventDefault()}>
        <Form.Group>
          <Form.Input
            placeholder="Search by digits"
            value={search}
            onChange={(_, { value }) => setSearch(String(value))}
          />

          <div className="field" style={{ width: 240, minWidth: 240 }}>
            <ReactDatePicker
              selectsRange
              startDate={daterange[0]}
              endDate={daterange[1]}
              placeholderText="Purchase Date"
              // TODO: this line is causing the following warning:
              // "Warning: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?"
              customInput={<Input fluid icon="calendar" iconPosition="left" />}
              monthsShown={2}
              showPopperArrow={false}
              onChange={onChangeDates}
            />
          </div>

          <Dropdown
            placeholder="All Studio Flows + Trust Profiles"
            selection
            options={profileOptions}
            multiple
            clearable
            search
            onChange={(_, { value }) => setProfiles(value as string[])}
            value={profiles}
            loading={sfLoading || vcLoading || tpLoading}
            style={{ minWidth: 300, maxWidth: 800 }}
            renderLabel={itme => ({
              content: itme.text,
              detail: itme.description,
            })}
          />

          <Form.Button color="blue" icon>
            <Icon name="search" />
          </Form.Button>
        </Form.Group>
      </Form>

      <PaginatedTable
        checkbox
        onSelectAll={onSelectAll}
        allSelected={allSelected}
        headers={TABLE_HEADERS}
        renderBody={(props: RenderProps) => (
          <TableBody
            {...props}
            selectedDates={selectedDates}
            updateSelectedSid={updateSelectedSid}
            isSidSelected={isSidSelected}
            setSids={setSids}
            search={search}
            profiles={profiles}
          />
        )}
      />
    </>
  );
};

export default TwilioPhonesPaginatedTable;
