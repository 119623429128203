import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Blacklist, Delimiter, PaginatedResponse } from 'src/types';
import { ApiResponse, http } from './http-common';

export type BlacklistResponse = ApiResponse & {
  blacklist?: Blacklist;
};

export type BlacklistsResponse = ApiResponse & {
  blacklists: PaginatedResponse<Blacklist>;
};

export type ListBlacklistsParams = {
  limit: number;
  offset: number;
};

export const useListBlacklistsQuery = (params: ListBlacklistsParams) => {
  return useQuery(['blacklists', params], async () => {
    const res = await http.get<BlacklistsResponse>('/api/blacklists', { params });
    return res.data.blacklists;
  });
};

export const useGetBlacklistQuery = (id: number) => {
  return useQuery(['blacklists', id], async () => {
    if (!id) return undefined;
    const res = await http.get<BlacklistResponse>(`/api/blacklists/${id}`);
    return res.data.blacklist;
  });
};

export type AddBlacklistParams = {
  name: string;
};

export const useAddBlacklistMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: AddBlacklistParams) => {
      const res = await http.post<BlacklistResponse>(`/api/blacklists`, params);
      return res.data.blacklist;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['blacklists']);
      },
    }
  );
};

export type BlacklistBulkAddFormdata = {
  mode: 'file' | 'text';
  // file inputs
  file?: File;
  delimiter: Delimiter;
  fieldMappings: string[];
  hasHeader: boolean;
  // text inputs
  phones: string;
  emails?: string;
};

export type BlacklistBulkAddInput = BlacklistBulkAddFormdata & {
  id: number;
};

export type BlacklistBulkAddResponse = ApiResponse & {
  phones_added?: number;
  emails_added?: number;
};

export const useBlacklistBulkAddMutation = () => {
  return useMutation(async (input: BlacklistBulkAddInput) => {
    if (input.mode === 'file' && !input.file) {
      const err = {
        response: {
          status: 422,
          statusText: 'Unprocessable Entity',
          data: { success: false, errors: [{ error: 'file is required' }] },
        },
      };
      throw err;
    }

    const fd = new FormData();
    fd.append('mode', input.mode);
    if (typeof input.file !== 'undefined') {
      fd.append('file', input.file);
    }
    fd.append('delimiter', input.delimiter);
    fd.append('field_mappings', input.fieldMappings.join(','));
    fd.append('has_header', input.hasHeader ? '1' : '0');
    fd.append('phones', input.phones);
    if (input.emails) {
      fd.append('emails', input.emails);
    }

    const res = await http.post<BlacklistBulkAddResponse>(`/api/blacklists/${input.id}/bulk-add`, fd);
    return res.data;
  }, {});
};
