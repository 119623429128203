import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Grid, Header } from 'semantic-ui-react';

import { useUpdateAccountBandwidthMutation } from 'src/api/admin/account-bandwidth';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Account, BandwidthProfile } from 'src/types';
import CreateBandwidthSubAccountModal from './CreateBandwidthSubAccountModal';

type Props = {
  account: Account;
};

type Formdata = {
  sub_account_id: string;
  purchase_limit: number;
};

type ValidationErrors = {
  sub_account_id?: string;
  purchase_limit?: string;
};

const getInitialFormdata = (p: BandwidthProfile | null): Formdata => {
  return {
    sub_account_id: p?.sub_account_id || '',
    purchase_limit: p?.purchase_limit || 0,
  };
};

const AdminAccountBandwidth = ({ account }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [formdata, setFormdata] = useState<Formdata>(() => getInitialFormdata(account.bandwidth_profile));
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { mutateAsync, isLoading: mutationIsLoading } = useUpdateAccountBandwidthMutation();

  useEffect(() => {
    if (account.bandwidth_profile === null) return;

    setFormdata(getInitialFormdata(account.bandwidth_profile));
  }, [account.bandwidth_profile]);

  const validate = useCallback((input: Formdata): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onChange = useCallback((_, { name, value }) => setFormdata(prev => ({ ...prev, [name]: value })), []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    if (!isEmpty(validate(formdata))) {
      return;
    }

    try {
      await mutateAsync({ id: account.id, ...formdata });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [account.id, mutateAsync, formdata, validate]);

  return (
    <Form onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h4">Billing</Header>

            <Form.Input
              label={
                <label>
                  Sub Account ID
                  <CreateBandwidthSubAccountModal id={account.id} />
                </label>
              }
              name="sub_account_id"
              value={formdata.sub_account_id}
              onChange={onChange}
              error={errors.sub_account_id}
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <Header as="h4">General Settings</Header>

            <Form.Input
              label="Purchase Limit"
              name="purchase_limit"
              type="number"
              min="0"
              value={formdata.purchase_limit}
              error={errors.purchase_limit}
              onChange={e => setFormdata(prev => ({ ...prev, purchase_limit: parseInt(e.target.value) }))}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Button color="blue" loading={mutationIsLoading}>
              Save
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default AdminAccountBandwidth;
