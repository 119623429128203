import { useMutation, useQuery, useQueryClient } from 'react-query';

import { PaginatedResponse, WebhookConfig, WebhookLog } from 'src/types';
import { ApiResponse, http } from '../http-common';

export type WebhookConfigResponse = ApiResponse & {
  config?: WebhookConfig;
};

export type WebhookConfigsResponse = ApiResponse & {
  configs: PaginatedResponse<WebhookConfig>;
};

export type ListWebhookConfigFilters = {
  accountId?: number;
  enabled?: boolean;
  // search?: string;
};

export type ListWebhookConfigsParams = {
  limit: number;
  offset: number;
};

export const useListWebhookConfigsQuery = (params: ListWebhookConfigsParams) => {
  return useQuery(['admin/webhooks', 'configs', params], async () => {
    const res = await http.get<WebhookConfigsResponse>('/api/admin/webhooks/configs', { params });
    return res.data.configs;
  });
};

export const useGetWebhookConfigQuery = (id: string) => {
  return useQuery(['admin/webhooks', 'configs', id], async () => {
    if (!id) return undefined;
    const res = await http.get<WebhookConfigResponse>(`/api/admin/webhooks/configs/${id}`);
    return res.data.config;
  });
};

type WebhookSendTestRequestInput = {
  id: string;
  phone: string;
};

export const useWebhookSendTestRequestMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: WebhookSendTestRequestInput) => {
      const res = await http.post<ApiResponse>(`/api/admin/webhooks/configs/${input.id}/test`, {
        phone: input.phone,
      });
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/webhooks', 'logs']);
      },
    }
  );
};

type UseGetWebhookLogsInput = {
  id: string;
  offset: number;
  limit: number;
  query: string;
};

type UseGetWebhookLogsOutput = {
  logs: PaginatedResponse<WebhookLog>;
};

export const useGetWebhookLogsQuery = (params: UseGetWebhookLogsInput) => {
  const { id, offset, limit, query } = params;
  return useQuery(['admin/webhooks', 'logs', params], async () => {
    if (!id) return undefined;
    const res = await http.get<UseGetWebhookLogsOutput>(`/api/admin/webhooks/configs/${id}/logs`, {
      params: { offset, limit, query },
    });
    return res.data.logs;
  });
};

export type AddWebhookConfigParams = {
  accountId: number;
  name: string;
};

export const useAddWebhookConfigMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: AddWebhookConfigParams) => {
      const res = await http.post<WebhookConfigResponse>(`/api/admin/webhooks/configs`, params);
      return res.data.config;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/webhooks', 'configs']);
      },
    }
  );
};

export type SaveWebhookConfigParams = {
  config: WebhookConfig;
};

export const useSaveWebhookConfigMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: SaveWebhookConfigParams) => {
      const res = await http.post<WebhookConfigResponse>(
        `/api/admin/webhooks/configs/${params.config.id}`,
        params.config
      );
      return res.data.config;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/webhooks', 'configs']);
      },
    }
  );
};

export const useDeleteWebhookConfigMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      const res = await http.delete<ApiResponse>(`/api/admin/webhooks/configs/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/webhooks', 'configs']);
      },
    }
  );
};

export type DuplicateWebhookInput = {
  webhookId: string;
  accountId: number;
  name: string;
};

export const useDuplicatewebhookMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ webhookId, ...input }: DuplicateWebhookInput) => {
      const res = await http.post<ApiResponse>(`/api/admin/webhooks/configs/${webhookId}/duplicate`, input);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/webhooks', 'configs']);
      },
    }
  );
};
