import { useRef, useState } from 'react';
import { Button, Form, FormProps, Icon, Modal } from 'semantic-ui-react';

import { useDeleteConvosoProfileMutation } from 'src/api/convoso';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';

type Props = {
  id: number;
};

const DeleteProfile = ({ id }: Props) => {
  const modalRef = useRef(null);
  const [error, setError] = useState<ApiMessageData>();
  const { mutateAsync, isLoading } = useDeleteConvosoProfileMutation();

  const onCancel = () => {
    if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
      // @ts-ignore
      modalRef.current.handleClose();
    }
  };

  const onCloseModal = () => {
    setError(undefined);
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>, data: FormProps): Promise<void> => {
    setError(undefined);

    try {
      await mutateAsync(id);

      if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
        // @ts-ignore
        modalRef.current.handleClose();
      }
    } catch (e: any) {
      apiErrorHandler(e, setError);
    }
  };

  return (
    <Modal
      size="mini"
      ref={modalRef}
      trigger={
        <Button color="red" icon title="Delete">
          <Icon name="trash" />
        </Button>
      }
      onClose={onCloseModal}
    >
      <Modal.Header>Delete Profile</Modal.Header>
      <Modal.Content>
        <ApiMessage data={error} />

        <Form onSubmit={onSubmit}>
          <p style={{ marginTop: 0 }}>
            <strong>Are you sure?</strong> This action cannot be undone.
          </p>

          <Row>
            <Button fluid type="button" onClick={onCancel}>
              Cancel
            </Button>
            <Button color="red" fluid loading={isLoading}>
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default DeleteProfile;
