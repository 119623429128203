import { Grid, Header, Message, Segment } from 'semantic-ui-react';

import PaginatedTable from 'src/components/PaginatedTable';
import { Account } from 'src/types';
import { accountLeadscorePlusBillingIsCurrent } from 'src/utils';
import AutoPay from './AutoPay';
import BillingLeadscorePlusTransactionsListBody from './BillingLeadscorePlusTransactionsListBody';
import CurrentBalance from './CurrentBalance';

type Props = {
  account: Account;
};

const AccountBillingLeadscorePlusPane = ({ account: a }: Props) => {
  return (
    <>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Segment>
              {!a.billing.leadscorePlus.enabled ? (
                <Message success>
                  <Message.Header>🎉 Free Tier Active</Message.Header>
                  <Message.Content>No payment required at this time.</Message.Content>
                </Message>
              ) : (
                <>
                  {!accountLeadscorePlusBillingIsCurrent(a) && (
                    <Message error>
                      <Message.Header>LeadScore+ Balance At or Below Zero</Message.Header>
                      <Message.Content>Please add funds to your account to keep using all features.</Message.Content>
                    </Message>
                  )}

                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <CurrentBalance
                          account={a}
                          billingConfig="leadscorePlus"
                          currentBalance={a.currentBalanceLeadscorePlus || 0}
                        />
                      </Grid.Column>

                      <Grid.Column>
                        <AutoPay account={a} billingConfig="leadscorePlus" />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </>
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {/* <AccountBillingHistoryOverview /> */}

      <Header as="h4">LeadScore+ Billing History</Header>

      <PaginatedTable
        headers={['Details', 'Created At', 'Amount', '']}
        renderBody={BillingLeadscorePlusTransactionsListBody}
      />
    </>
  );
};

export default AccountBillingLeadscorePlusPane;
