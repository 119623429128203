import { useQuery } from 'react-query';

import { http } from '../http-common';
import { SchedulesResponse } from '../schedules';

export type ListSchedulesParams = {
  limit: number;
  offset: number;
  accountId?: number;
};

export const useListSchedulesQuery = (params: ListSchedulesParams) => {
  return useQuery(['admin/schedules', params], async () => {
    const res = await http.get<SchedulesResponse>('/api/admin/schedules', { params });
    return res.data.schedules;
  });
};
