import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Label, Placeholder, PlaceholderParagraph, Table } from 'semantic-ui-react';

import { useListModelsQuery } from 'src/api/models';
import { RenderProps } from 'src/components/PaginatedTable';
import { Row } from 'src/styles';
import theme from 'src/styles/theme';
import { ModelStage, ModelType } from 'src/types';
import { getModelVersionColor } from 'src/utils';

const PlaceholderRow = () => (
  <Table.Row>
    <Table.Cell collapsing>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell textAlign="right">
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
  </Table.Row>
);

const ListModelsBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: models, isLoading } = useListModelsQuery({ limit, offset: limit * (currentPage - 1) });

  useEffect(() => {
    let count = 1;
    if (typeof models !== 'undefined') {
      count = Math.ceil(models.total / limit);
    }
    setPageCount(count);
  }, [models, limit, setPageCount]);

  if (isLoading) {
    return <PlaceholderRow />;
  }

  if (!models?.data.length) {
    return (
      <Table.Row>
        <Table.Cell colSpan={5}>
          <span style={{ color: theme.gray }}>No models found</span>
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      {models?.data.map(m => (
        <Table.Row key={m.id}>
          <Table.Cell>{m.display_name} </Table.Cell>
          <Table.Cell>{ModelType[m.type]}</Table.Cell>
          <Table.Cell>
            {m.versions?.map(v => (
              <Label key={v.id} color={getModelVersionColor(v)}>
                {v.version}
                <Label.Detail>{ModelStage[v.stage]}</Label.Detail>
              </Label>
            ))}
          </Table.Cell>
          <Table.Cell textAlign="right" collapsing>
            <Row style={{ justifyContent: 'flex-end' }}>
              {m.type === ModelType.Engagement && (
                <Link to={{ pathname: `/models/${encodeURIComponent(m.id)}/schedule`, state: { model: m } }}>
                  <Button as="div" color="blue">
                    Schedule
                  </Button>
                </Link>
              )}
            </Row>
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default ListModelsBody;
