import { useState } from 'react';
import { DropdownProps, Form } from 'semantic-ui-react';

import { useAssignAccountUserRoleMutation } from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { AccountUserAccessRole, AccountUserAccessRoles } from 'src/types';

type Props = {
  accountId: number;
  userId: number;
  roles: AccountUserAccessRole[];
};

const AssignAccountUserAccessRole = ({ accountId, userId, roles }: Props) => {
  const [selectedRoles, setSelectedRoles] = useState<AccountUserAccessRole[]>(roles);
  const { isLoading, mutateAsync } = useAssignAccountUserRoleMutation();
  const [error, setError] = useState<ApiMessageData>();
  const roleOptions = AccountUserAccessRoles.map(r => ({ ...r, key: r.value }));

  const onChange = async (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    try {
      setError(undefined);
      setSelectedRoles(data.value as AccountUserAccessRole[]);
      await mutateAsync({ accountId, userId, roles: data.value as AccountUserAccessRole[] });
    } catch (e: any) {
      setSelectedRoles(selectedRoles);
      apiErrorHandler(e, setError);
    }
  };

  return (
    <Form>
      <Form.Select
        width={6}
        multiple
        options={roleOptions}
        onChange={onChange}
        loading={isLoading}
        value={selectedRoles}
        error={error && `${error.status} ${error.message}`}
      />
    </Form>
  );
};

export default AssignAccountUserAccessRole;
