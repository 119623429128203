import { useEffect } from 'react';
import { Placeholder, PlaceholderParagraph, Table } from 'semantic-ui-react';

import { useListFiltersQuery } from 'src/api/filters';
import { RenderProps } from 'src/components/PaginatedTable';
import { Row } from 'src/styles';
import theme from 'src/styles/theme';
import { FilterType } from 'src/types';
import DeleteFilter from './DeleteFilter';
import EditFilter from './EditFilter';

const PlaceholderRow = () => (
  <Table.Row>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell textAlign="right" collapsing>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
  </Table.Row>
);

const ListFiltersBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: filters, isLoading } = useListFiltersQuery({ limit, offset: limit * (currentPage - 1) });

  useEffect(() => {
    let count = 1;
    if (typeof filters !== 'undefined') {
      count = Math.ceil(filters.total / limit);
    }
    setPageCount(count);
  }, [filters, limit, setPageCount]);

  if (isLoading) {
    return <PlaceholderRow />;
  }

  if (!filters?.data.length) {
    return (
      <Table.Row>
        <Table.Cell colSpan={5}>
          <span style={{ color: theme.gray }}>No filters found</span>
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      {filters?.data.map(f => (
        <Table.Row key={f.id}>
          <Table.Cell>{f.name}</Table.Cell>
          <Table.Cell>{FilterType[f.type]}</Table.Cell>
          <Table.Cell textAlign="right" collapsing>
            <Row>
              <EditFilter filter={f} />
              <DeleteFilter id={f.id} />
            </Row>
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default ListFiltersBody;
