import { useCallback } from 'react';
import { Node } from 'react-flow-renderer';
import { v4 as uuid } from 'uuid';

import { Filter, UpdateNode } from './types';

export const useNodeActions = (updateNode: UpdateNode, { id, data }: Node<{ filters?: Filter[] }>) => {
  const { filters } = data;

  const addFilter = useCallback(() => {
    updateNode(id, {
      filters: [
        ...(filters || []),
        {
          id: uuid(),
        },
      ],
    });
  }, [id, filters, updateNode]);

  const removeFilter = useCallback(
    (filterID: string) => {
      updateNode(id, {
        filters: filters?.filter((f: any) => f.id !== filterID),
      });
    },
    [id, filters, updateNode]
  );

  const updateFilter = useCallback(
    (filter: Filter) => {
      updateNode(id, {
        filters: filters?.map((f: any) => (f.id === filter.id ? { ...f, ...filter } : f)),
      });
    },
    [id, filters, updateNode]
  );

  return {
    addFilter,
    removeFilter,
    updateFilter,
  } as const;
};
