import { isEmpty } from 'lodash';
import { useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { useAssignAkkioModelToAccountMutation } from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import SelectAkkioModels from 'src/components/SelectAkkioModels';

type ValidationErrors = {
  modelId?: string;
};

type Props = {
  accountId: number;
  omitModelIds: number[];
};

const AssignAkkioModelsToAccount = ({ accountId, omitModelIds }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData | undefined>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { isLoading, mutateAsync } = useAssignAkkioModelToAccountMutation();
  const [modelIds, setModelIds] = useState<number[]>([]);

  const onClose = () => {
    setOpen(false);
    setModelIds([]);
    setApiMessage(undefined);
    setErrors({});
  };

  const validate = () => {
    const validationErrors: ValidationErrors = {};

    if (modelIds.length === 0) {
      validationErrors.modelId = 'Please select at least one model';
    }

    setErrors(validationErrors);

    return validationErrors;
  };

  const onSubmit = async () => {
    if (!isEmpty(validate())) {
      return;
    }

    setApiMessage(undefined);

    try {
      await mutateAsync({ accountId, modelIds });
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button color="blue">
          <Icon name="plus" />
          Assign Models
        </Button>
      }
    >
      <Modal.Header>Assign Models</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <SelectAkkioModels
            label="Models"
            onChange={(_, { value }) => setModelIds(value as number[])}
            error={errors.modelId}
            multiple
            omitModelIds={omitModelIds}
            value={modelIds}
          />

          <Form.Button loading={isLoading} color="blue" fluid>
            Assign
          </Form.Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AssignAkkioModelsToAccount;
