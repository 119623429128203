import { DropdownItemProps } from 'semantic-ui-react';

import { FeedFields } from 'src/config';
import { FilterType } from 'src/types';

export const fieldNameOptions: DropdownItemProps[] = FeedFields.map((f: string) => ({ key: f, value: f, text: f }));

export const typeOptions = [
  { value: FilterType['Field Equals'], text: 'Field Equals' },
  { value: FilterType['Field NOT Equals'], text: 'Field Not Equals' },
  { value: FilterType['Field Starts With'], text: 'Field Starts With' },
  { value: FilterType['Field NOT Starts With'], text: 'Field Not Starts With' },
];
