import styled from 'styled-components';

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #fff;
  display: flex;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
  z-index: 5000;
  justify-content: space-between;

  .role--admin & {
    top: 34px;
  }
`;

export const HeaderMenu = styled.div`
  padding: 0 1rem;
  display: flex;
  justify-content: flex-end;
`;

export const HeaderMenuItem = styled.div`
  display: flex;

  a,
  button,
  .ui.dropdown {
    background: transparent;
    border: none;
    color: black;
    cursor: pointer;

    :hover {
      background: #fafafa;
      color: ${p => p.theme.primaryHover};
    }
  }

  /* a, */
  /* button, */
  .ui.dropdown {
    flex: 1;
    display: block;
    padding: 1rem;
  }
`;

export const Logo = styled.a`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 0 1rem;

  img {
    display: block;
    height: 15px;
  }
`;
