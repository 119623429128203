import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

import { useListAccountApiTokensQuery } from 'src/api/accounts';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Loading from 'src/components/Loading';
import { Container, Header as PageHeader, Row } from 'src/styles';
import BillingDocs from './BillingDocs';

const AccountsBillingDocs = () => {
  const history = useHistory();
  const { data: apiTokens, isLoading: tokensLoading } = useListAccountApiTokensQuery({ limit: 1, offset: 0 });

  if (tokensLoading) return <Loading />;

  let token = '{api_token}';
  if (apiTokens && apiTokens.data.length > 0) {
    token = apiTokens.data[0].token;
  }

  return (
    <Container>
      <Helmet>
        <title>Billing Docs | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Row style={{ alignItems: 'center' }}>
          <Button icon color="blue" onClick={history.goBack} style={{ marginRight: '1rem' }}>
            <Icon name="arrow left" />
          </Button>

          <PageHeader style={{ margin: 0 }}>
            <Breadcrumbs crumbs={['Billing', 'Docs']} />
          </PageHeader>
        </Row>
      </Row>

      <BillingDocs token={token} />
    </Container>
  );
};

export default AccountsBillingDocs;
