import { useRef } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { Row } from 'src/styles';

export type ValidationErrors = {
  hasOutputWidget?: string;
};

type ErrorModalProps = {
  errors: ValidationErrors;
};

const ErrorModal = ({ errors }: ErrorModalProps) => {
  const modalRef = useRef(null);

  const onCancel = () => {
    if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
      // @ts-ignore
      modalRef.current.handleClose();
    }
  };

  return (
    <Modal
      size="tiny"
      ref={modalRef}
      trigger={<Icon name="warning sign" size="large" color="yellow" style={{ cursor: 'pointer' }} />}
    >
      <Modal.Header style={{ display: 'flex', alignItems: 'center' }}>
        <Icon name="warning sign" size="small" color="yellow" />
        Flow errors
      </Modal.Header>
      <Modal.Content>
        <p>There are errors in your flow. Please fix them before publishing or running.</p>

        <Form>
          <ul style={{ marginTop: 0, paddingLeft: '1rem' }}>
            {errors.hasOutputWidget && (
              <li>
                <strong>Missing output widget:</strong> {errors.hasOutputWidget}
              </li>
            )}
          </ul>

          <Row>
            <Button fluid type="button" onClick={onCancel}>
              Accept
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default ErrorModal;
