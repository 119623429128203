import { format, parse } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Divider, Form, Header, Segment } from 'semantic-ui-react';

import { useListBillingConnectProTransactionsQuery } from 'src/api/auth/account-billing';
import { useListVoiceConfigsQuery } from 'src/api/voice-configs';
import { Row } from 'src/styles';
import theme from 'src/styles/theme';
import { ReportingTimeRange, ReportingTimeRanges } from 'src/types';
import { reportingTimeRangeToDateStrings } from 'src/utils';

const getInitialStatsFilters = () => {
  return {
    timeRange: 'this month',
    startDate: format(new Date().setDate(1), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  };
};

type ChartData = {
  date: string;
  total: number;
  [key: string]: string | number;
};

type StatsByDate = {
  [date: string]: {
    total: number;
    [key: string]: number;
  };
};

const BarColors = Object.values(theme.palette.charts);

const AccountBillingHistoryOverview = () => {
  const [filters, setFilters] = useState(getInitialStatsFilters());
  const { data: transactions } = useListBillingConnectProTransactionsQuery({ limit: 1000, offset: 0 });
  const { data: voiceConfigs } = useListVoiceConfigsQuery({ limit: 1000, offset: 0 });

  const voiceConfigNames = useMemo(() => {
    const voiceConfigNames: { [id: string]: string } = { unknown: 'unknown' };
    voiceConfigs?.data.forEach(vc => (voiceConfigNames[vc.id] = vc.name));
    return voiceConfigNames;
  }, [voiceConfigs?.data]);

  const onChangeTimeRange = useCallback((_, { value }) => {
    const [startDate, endDate] = reportingTimeRangeToDateStrings(value as ReportingTimeRange);
    setFilters(prev => ({ ...prev, timeRange: value, startDate, endDate }));
  }, []);

  const chartData: ChartData[] = [];

  const [statsByDate, stacks] = useMemo<[StatsByDate, Set<string>]>(() => {
    const statsByDate: StatsByDate = {};
    const stacks = new Set<string>();

    const start = parse(filters.startDate, 'yyyy-MM-dd', new Date());
    const end = new Date(parse(filters.endDate, 'yyyy-MM-dd', new Date()).getTime() + 24 * 60 * 60 * 1000);

    for (let d = start; d < end; d = new Date(d.getTime() + 24 * 60 * 60 * 1000)) {
      statsByDate[format(d, 'yyyy-MM-dd')] = { total: 0 };
    }

    transactions?.data
      .filter(
        t =>
          t.amount < 0 &&
          t.title.startsWith('Daily Usage') &&
          new Date(t.createdAt) >= start &&
          new Date(t.createdAt) <= end
      )
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      .forEach(t => {
        const date = format(new Date(t.createdAt), 'yyyy-MM-dd');

        if (!(date in statsByDate)) {
          statsByDate[date] = {
            total: 0,
          };
        }

        statsByDate[date].total += t.amount;

        let voiceConfigID = 'unknown';
        if (t.payload && t.payload.voice_config_id) {
          if (t.payload.voice_config_id in voiceConfigNames) {
            voiceConfigID = voiceConfigNames[t.payload.voice_config_id];
          } else {
            voiceConfigID = t.payload.voice_config_id;
          }
        }
        stacks.add(voiceConfigID);
        statsByDate[date][voiceConfigID] = t.amount;
      });

    return [statsByDate, stacks];
  }, [filters.endDate, filters.startDate, transactions?.data, voiceConfigNames]);

  Object.keys(statsByDate).forEach(date => {
    const data: ChartData = {
      date,
      total: -statsByDate[date].total,
    };

    Object.keys(statsByDate[date]).forEach(key => {
      if (key !== 'total') {
        data[key] = -statsByDate[date][key];
      }
    });

    chartData.push(data);
  });

  let total = 0;
  const totalByVoiceConfig: { [id: string]: number } = {};

  Object.keys(statsByDate).forEach(date => {
    Object.keys(statsByDate[date]).forEach(key => {
      if (key === 'total') return;

      total += statsByDate[date][key];
      totalByVoiceConfig[key] = (totalByVoiceConfig[key] || 0) + statsByDate[date][key];
    });
  });

  return (
    <Segment>
      <Form>
        <Form.Group>
          <Form.Field>
            <label>
              Time Range{' '}
              <span style={{ opacity: 0.4, fontSize: '0.9em' }}>
                ({filters.startDate} - {filters.endDate})
              </span>
            </label>
            <Form.Select
              onChange={onChangeTimeRange}
              options={ReportingTimeRanges.filter(tr => tr.value !== 'custom').map(tr => ({
                ...tr,
                key: tr.value,
              }))}
              value={filters.timeRange}
            />
          </Form.Field>
        </Form.Group>
      </Form>

      <div style={{ height: 300 }}>
        <ResponsiveContainer>
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
            <Tooltip cursor={{ fill: 'rgba(0,0,0,0.1)' }} />

            {[...stacks].map((id, i) => (
              <Bar key={id} dataKey={id} stackId="a" fill={BarColors[i % BarColors.length]} />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div style={{ marginTop: 20 }}>
        <Divider />
        <Row style={{ justifyContent: 'space-between' }}>
          <Header as="h2" style={{ margin: 0 }}>
            Total
          </Header>
          <Header as="h2" style={{ margin: 0 }}>
            ${(-total || 0).toLocaleString(undefined, { minimumFractionDigits: 2 })}
          </Header>
        </Row>
        {/* <Divider />
        {Object.keys(totalByVoiceConfig)
          .map(id => ({ id, total: totalByVoiceConfig[id] }))
          .sort((a, b) => a.total - b.total)
          .map(({ id, total }) => (
            <Row key={id} style={{ justifyContent: 'space-between', margin: '0.25rem 0' }}>
              <span>{id}</span>
              <span>${(-total || 0).toLocaleString(undefined, { minimumFractionDigits: 2 })}</span>
            </Row>
          ))} */}
      </div>
    </Segment>
  );
};

export default AccountBillingHistoryOverview;
