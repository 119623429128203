import { ColumnDef } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { P1ClosesData, useGetAgentAttributionDataQuery } from 'src/api/reporting';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { FilterType } from '.';
import { sortFunc, TableDefinition } from './TableDefinition';

const ReportingP1ClosesColumns = ['sourcename', 'p1_year', 'p1_month', 'new_closes'] as const;

type ReportingP1ClosesColumn = typeof ReportingP1ClosesColumns[number];

type Props = {
  filters: FilterType;
};

const P1ClosesTable = ({ filters }: Props) => {
  const { replace } = useHistory();
  const query = useSearchQuery();
  const [column, setColumn] = useState<ReportingP1ClosesColumn>(
    (query.get('column') ?? 'label') as ReportingP1ClosesColumn
  );
  const [direction, setDirection] = useState<'ascending' | 'descending'>(
    (query.get('direction') ?? 'ascending') as 'ascending' | 'descending'
  );

  const { data: AgentAttributionDataResponse, isLoading: isDataLoading } =
    useGetAgentAttributionDataQuery<P1ClosesData>(filters);

  const updateSort = useCallback(
    (col: ReportingP1ClosesColumn) => () => {
      const newDirection = column === col ? (direction === 'ascending' ? 'descending' : 'ascending') : 'ascending';
      setDirection(newDirection);
      setColumn(col);

      // update query params
      query.set('column', col);
      query.set('direction', newDirection);
      replace({ search: query.toString() });
    },
    [column, direction, query, replace]
  );

  const data = useMemo(() => {
    return AgentAttributionDataResponse?.data || [];
  }, [AgentAttributionDataResponse?.data]);

  const summary = useMemo(() => {
    return data.reduce((sum, item) => sum + item.new_closes, 0);
  }, [data]);

  const sortedData = useMemo(() => data.sort(sortFunc(column, direction)), [data, column, direction]);

  const columns = useMemo<ColumnDef<P1ClosesData>[]>(() => {
    const cols: ColumnDef<P1ClosesData>[] = [];
    cols.push({
      id: 'sourcename',
      header: () => {
        return <strong onClick={updateSort('sourcename')}>Sourcename</strong>;
      },
      accessorFn: row => row.sourcename,
      cell: ({ getValue }) => getValue(),
      footer: () => {
        return (
          <div style={{ textAlign: 'right' }}>
            <strong>Grand Total</strong>
          </div>
        );
      },
      sortingFn: 'alphanumeric',
      sortDescFirst: true,
    });
    cols.push({
      id: 'p1_year',
      header: () => {
        return (
          <strong onClick={updateSort('p1_year')} style={{ textAlign: 'right' }}>
            P1 Year
          </strong>
        );
      },
      accessorFn: row => row.p1_year,
      cell: ({ getValue }) => (getValue() as number).toLocaleString(),
      footer: () => <div></div>,
      sortingFn: 'alphanumeric',
    });
    cols.push({
      id: 'p1_month',
      header: () => {
        return (
          <strong onClick={updateSort('p1_month')} style={{ textAlign: 'right' }}>
            P1 Month
          </strong>
        );
      },
      accessorFn: row => row.p1_month,
      cell: ({ getValue, row }) => (getValue() as number).toLocaleString(),
      footer: () => <div></div>,
      sortingFn: 'alphanumeric',
    });
    cols.push({
      id: 'new_closes',
      header: () => {
        return (
          <strong onClick={updateSort('new_closes')} style={{ textAlign: 'right' }}>
            New Closes
          </strong>
        );
      },
      accessorFn: row => row.new_closes,
      cell: ({ getValue, row }) => (getValue() as number).toLocaleString(),
      footer: () => {
        return (
          <div style={{ textAlign: 'right' }}>
            <strong>{summary.toLocaleString()}</strong>
          </div>
        );
      },
      sortingFn: 'alphanumeric',
    });

    return cols;
  }, [summary, updateSort]);

  return (
    <TableDefinition
      columns={columns}
      sortedData={sortedData}
      sortingLabel={'sourcename'}
      isDataLoading={isDataLoading}
    />
  );
};

export default P1ClosesTable;
