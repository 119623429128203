import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckboxProps, Form, Pagination, Select, Table } from 'semantic-ui-react';

import useSearchQuery from 'src/hooks/useSearchQuery';
import { Row } from 'src/styles';
import { SortDirectionText } from 'src/types';

const LimitOptions = ['5', '10', '25', '50', '100', '250', '500', '1000'].map(l => ({ key: l, text: l, value: l }));

export type RenderProps = {
  limit: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  setPageCount: (count: number) => void;
};

export type PaginatedTableRow = string | ((data: any[]) => JSX.Element);

type Props = {
  allSelected?: boolean;
  checkbox?: boolean;
  headers: string[];
  onSelectAll?: (checked: boolean) => void;
  renderBody: (props: RenderProps) => (ReactElement | null) | (JSX.Element[] | undefined);
  qsParam?: string | boolean;
  sortable?: boolean;
  onSort?: (column: string) => void;
  sortBy?: string;
  sortDirection?: Lowercase<SortDirectionText>;
};

const PaginatedTable = ({
  headers,
  renderBody,
  checkbox = false,
  allSelected = false,
  onSelectAll,
  qsParam = 'page',
  sortable,
  onSort,
  sortBy,
  sortDirection,
}: Props) => {
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const { push, replace } = useHistory();
  const query = useSearchQuery();

  const updateCurrentPage = useCallback(
    (page: number) => {
      if (!!qsParam && typeof qsParam === 'string') {
        const func = query.has(qsParam) ? push : replace;
        query.set(qsParam, Math.max(1, page).toString());
        func({ search: query.toString() });
      }
      setCurrentPage(page);
    },
    [push, replace, query, qsParam]
  );

  useEffect(() => {
    if (!qsParam || typeof qsParam !== 'string') return;
    const page = query.get(qsParam) || '1';
    setCurrentPage(parseInt(page, 10));
  }, [query, qsParam]);

  const onLimitChange = useCallback(
    (_, { value }) => {
      setLimit(Number(value));
      updateCurrentPage(1);
    },
    [updateCurrentPage]
  );

  const onCheckAll = useCallback(
    (_: React.FormEvent<HTMLInputElement>, { checked = false }: CheckboxProps) => {
      onSelectAll && onSelectAll(checked);
    },
    [onSelectAll]
  );

  return (
    <Table padded sortable={sortable}>
      <Table.Header>
        <Table.Row>
          {checkbox && (
            <Table.HeaderCell collapsing>
              <Form.Checkbox checked={allSelected} onChange={onCheckAll} />
            </Table.HeaderCell>
          )}
          {headers.map((h, i) => (
            <Table.HeaderCell
              key={`${i}-${h}`}
              sorted={sortable && h === sortBy ? sortDirection : undefined}
              onClick={() => sortable && onSort && onSort(h)}
            >
              {h}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>

      <Table.Body>{renderBody({ limit, currentPage, setCurrentPage: updateCurrentPage, setPageCount })}</Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={headers.length + Number(checkbox)}>
            <Row style={{ justifyContent: 'space-between' }}>
              <Pagination
                activePage={currentPage}
                totalPages={pageCount}
                onPageChange={(_, { activePage }) => updateCurrentPage(Number(activePage) || 1)}
              />

              <Select options={LimitOptions} value={String(limit)} onChange={onLimitChange} compact />
            </Row>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default PaginatedTable;
