import { useCallback, useMemo, useRef, useState } from 'react';
import { Button, DropdownItemProps, Form, FormProps, Modal } from 'semantic-ui-react';

import { useReassignTelnyxPhonesVoiceConfigMutation } from 'src/api/auth/account-telnyx';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useListVoiceConfigsQuery } from 'src/api/voice-configs';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';

type Props = {
  sids: string[];
  open: boolean;
  onSuccess?: () => void;
  onClose: () => void;
};

const TelnyxReassignVoiceConfigModal = ({ sids, open, onSuccess, onClose }: Props) => {
  const modalRef = useRef(null);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [selectedConfigID, setSelectedConfigID] = useState('');
  const { data: configs, isLoading: configsLoading } = useListVoiceConfigsQuery({ limit: 1000, offset: 0 });
  const { mutateAsync: reassignPhones, isLoading: reassignLoading } = useReassignTelnyxPhonesVoiceConfigMutation();

  const configOptions = useMemo<DropdownItemProps[]>(
    () =>
      configs?.data?.map(f => ({
        key: f.id,
        text: f.name,
        value: f.id,
      })) || [],
    [configs?.data]
  );

  const closeModal = useCallback(() => {
    if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
      // @ts-ignore
      modalRef.current.handleClose();
      onClose();
    }
  }, [onClose]);

  const onCloseModal = () => {
    setApiMessage(undefined);
    setSelectedConfigID('');
    onClose();
  };

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>, data: FormProps): Promise<void> => {
      setApiMessage(undefined);

      if (!selectedConfigID) return;

      try {
        await reassignPhones({ sids, configID: selectedConfigID });
        if (onSuccess !== undefined) {
          onSuccess();
        }

        closeModal();
      } catch (e: any) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [closeModal, onSuccess, reassignPhones, selectedConfigID, sids]
  );

  return (
    <Modal size="mini" ref={modalRef} open={open} onClose={onCloseModal}>
      <Modal.Header>Reassign Voice Config</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Row style={{ marginBottom: '1rem' }}>
            <Form.Select
              label="Voice Config"
              width="16"
              placeholder="Select config to assign phone numbers to"
              loading={configsLoading}
              clearable
              options={configOptions}
              value={selectedConfigID}
              onChange={(_, { value }) => setSelectedConfigID(String(value))}
            />
          </Row>

          <Row style={{ gap: '1rem' }}>
            <Button fluid type="button" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              color="red"
              fluid
              loading={reassignLoading}
              disabled={selectedConfigID === ''}
              style={{ marginRight: 0 }}
            >
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default TelnyxReassignVoiceConfigModal;
