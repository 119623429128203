import { formatDistanceToNow } from 'date-fns';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Label, Popup, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { useGetWebhookLogsQuery } from 'src/api/webhooks';
import { RenderProps } from 'src/components/PaginatedTable';
import { Row } from 'src/styles';
import theme from 'src/styles/theme';
import { WebhookLog } from 'src/types';
import WebhookLogModal from './WebhookLogModal';

const ClippedText = styled.span`
  display: block;
  max-width: 400px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const WebhookLogsBody = ({ limit, currentPage, setPageCount, query }: RenderProps & { query: string }) => {
  const params = useParams<{ id: string }>();
  const { data: logs } = useGetWebhookLogsQuery({
    limit,
    offset: (currentPage - 1) * limit,
    id: params.id,
    query,
  });

  useEffect(() => {
    if (!logs?.total) return;
    setPageCount(Math.ceil(logs.total / limit));
  }, [logs, limit, setPageCount]);

  if (!logs || logs?.data?.length === 0) {
    return (
      <Table.Row>
        <Table.Cell colSpan={5} style={{ color: theme.gray }}>
          No results found.
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      {logs?.data?.map((log: WebhookLog, i: number) => (
        <Table.Row key={i}>
          <Table.Cell>
            <span title={log.createdAt}>
              {formatDistanceToNow(new Date(log.createdAt), { addSuffix: true, includeSeconds: true })}
            </span>
          </Table.Cell>
          <Table.Cell>{log.durationMs}</Table.Cell>
          <Table.Cell>
            <ClippedText>{log.localReqData}</ClippedText>
          </Table.Cell>
          <Table.Cell>
            <Row style={{ gap: '0.5rem', alignItems: 'center' }}>
              {!!log.proxyResStatus && (
                <Label color={log.proxyResStatus === 200 ? 'green' : 'red'}>{log.proxyResStatus}</Label>
              )}
              <ClippedText>{log.proxyResData}</ClippedText>
            </Row>
          </Table.Cell>
          <Table.Cell>
            {log.localResStatus && log.localResStatus === 200 ? (
              <Label color="green">{log.localResStatus}</Label>
            ) : (
              <Popup trigger={<Label color="red">{log.localResStatus}</Label>}>{log.localError}</Popup>
            )}
          </Table.Cell>
          <Table.Cell collapsing>
            <WebhookLogModal item={log} />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default WebhookLogsBody;
