import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Grid, Header } from 'semantic-ui-react';

import { useUpdateAccountTwilioMutation } from 'src/api/admin/account-twilio';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Account, TwilioProfile } from 'src/types';
import CreateTwilioSubAccountModal from './CreateTwilioSubAccountModal';

type Props = {
  account: Account;
};

type Formdata = {
  account_sid: string;
  auth_token: string;
  purchase_limit: number;
};

type ValidationErrors = {
  account_sid?: string;
  auth_token?: string;
  purchase_limit?: string;
};

const getInitialFormdata = (p: TwilioProfile | null): Formdata => {
  return {
    account_sid: p?.account_sid || '',
    auth_token: p?.auth_token || '',
    purchase_limit: p?.purchase_limit || 0,
  };
};

const AdminAccountTwilio = ({ account }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [formdata, setFormdata] = useState<Formdata>(() => getInitialFormdata(account.twilio_profile));
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { mutateAsync, isLoading: mutationIsLoading } = useUpdateAccountTwilioMutation();

  useEffect(() => {
    if (account.twilio_profile === null) return;

    setFormdata(getInitialFormdata(account.twilio_profile));
  }, [account.twilio_profile]);

  const validate = useCallback((input: Formdata): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onChange = useCallback((_, { name, value }) => setFormdata(prev => ({ ...prev, [name]: value })), []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    if (!isEmpty(validate(formdata))) {
      return;
    }

    try {
      await mutateAsync({ id: account.id, ...formdata });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [account.id, mutateAsync, formdata, validate]);

  return (
    <Form onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h4">Auth Credentials</Header>

            <Form.Input
              label={
                <label>
                  Account SID
                  <CreateTwilioSubAccountModal id={account.id} />
                </label>
              }
              name="account_sid"
              value={formdata.account_sid}
              onChange={onChange}
              error={errors.account_sid}
            />
            <Form.Input
              type="password"
              label="Auth Token"
              name="auth_token"
              value={formdata.auth_token}
              onChange={onChange}
              error={errors.auth_token}
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <Header as="h4">General Settings</Header>

            <Form.Input
              label="Purchase Limit"
              name="purchase_limit"
              type="number"
              min="0"
              value={formdata.purchase_limit}
              error={errors.purchase_limit}
              onChange={e => setFormdata(prev => ({ ...prev, purchase_limit: parseInt(e.target.value) }))}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Button color="blue" loading={mutationIsLoading}>
              Save
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default AdminAccountTwilio;
