import { useCallback, useEffect, useState } from 'react';
import { Button, Form } from 'semantic-ui-react';

import { UpdateUserProfileInput, useGetUserProfileQuery, useUpdateUserProfileMutation } from 'src/api/auth';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { User } from 'src/types';

const getInitialFormdata = (user?: User): UpdateUserProfileInput => {
  return {
    email: user?.email || '',
    firstname: user?.firstname || '',
    lastname: user?.lastname || '',
  };
};

const UpdateProfileForm = () => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { data: user, isLoading: userLoading } = useGetUserProfileQuery();
  const [formdata, setFormdata] = useState<UpdateUserProfileInput>(() => getInitialFormdata(user));
  const { mutateAsync, isLoading: updateLoading } = useUpdateUserProfileMutation();

  useEffect(() => {
    if (userLoading) return;
    setFormdata(getInitialFormdata(user));
  }, [user, userLoading]);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);
    try {
      await mutateAsync(formdata);
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [formdata, mutateAsync]);

  const onChange = useCallback((_, { name, value }) => {
    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  if (userLoading || !user) {
    return null;
  }

  return (
    <Form onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      <Form.Input label="Firstname" value={formdata.firstname} name="firstname" onChange={onChange} />
      <Form.Input label="Lastname" value={formdata.lastname} name="lastname" onChange={onChange} />
      <Form.Input label="Email" value={formdata.email} name="email" onChange={onChange} />

      <Button color="blue" loading={updateLoading}>
        Update Profile
      </Button>
    </Form>
  );
};

export default UpdateProfileForm;
