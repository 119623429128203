import styled, { css } from 'styled-components';

export const MenubarSpacer = styled.div`
  flex: 1;
`;

export const MenubarChildren = styled.div`
  a {
    border-top: 1px solid rgba(0, 0, 0, 0.25);
  }
`;

export const MenubarItem = styled.div<{ open?: boolean }>`
  ${p =>
    p.open &&
    css`
      background-color: #1c1c1c;
    `}

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70px;
    padding: 20px;
    color: #fff;
    position: relative;

    :hover {
      color: #fff;
      background-color: #202020;
      cursor: pointer;
    }

    &.active,
    &.active:hover {
      background-color: #242425;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 10px;
      bottom: 10px;
      left: 0;
      width: 5px;
      background: ${p => p.theme.primary};
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      transform: scaleY(0);
      transition: transform 150ms ease-in-out;
    }

    &.active:after {
      transform: scaleY(1);
    }

    span,
    > i {
      display: block;
    }

    span {
      margin-left: 40px;
    }

    > i {
      position: absolute;
      top: 35px;
      left: 35px;
      transform: translate(-50%, -50%);
      height: 20px;
      margin: 0;
    }
  }

  ${MenubarChildren} {
    display: ${p => (p.open ? 'block' : 'none')};
  }

  ${MenubarChildren} a {
    height: 50px;

    /* span {
      margin-left: 0;
    } */

    i {
      top: 25px;
    }
  }
`;

export const NavContainer = styled.div`
  position: fixed;
  top: 50px;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 200px;

  body.nav--collapsed & {
    width: 70px;
  }

  body.role--admin & {
    top: 84px;
  }
`;

export const Nav = styled.nav`
  background-color: #191919;
  color: #dde2ff;
  z-index: 99;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 2px #bbb;
  width: 200px;
  transition: all 300ms ease-in-out;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  bottom: 0;

  body.nav--collapsed & {
    width: 70px;

    ${MenubarChildren} {
      display: none;
    }

    &:hover {
      width: 200px;

      a span {
        opacity: 1;
        transform: translateX(0);
      }

      ${MenubarItem}:hover ${MenubarChildren} {
        display: block;
      }
    }
  }

  a span {
    opacity: 1;
    transform: translateX(0px);
    transition: all 300ms ease-in-out;

    body.nav--collapsed & {
      opacity: 0;
      transform: translateX(-10px);
    }
  }
`;
