import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useWebhookSendTestRequestMutation } from 'src/api/webhooks';
import ApiMessage from 'src/components/ApiMessage';
import { cleanPhone } from 'src/utils';

type Props = {
  id: string;
};

type ValidationErrors = {
  phone?: string;
};

const WebhookSendTestRequestModal = ({ id }: Props) => {
  const [phone, setPhone] = useState('');
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { mutateAsync, isLoading } = useWebhookSendTestRequestMutation();

  const onClose = useCallback(() => {
    setOpen(false);
    setPhone('');
    setErrors({});
    setApiMessage(undefined);
  }, []);

  const onSubmit = async () => {
    setApiMessage(undefined);

    const validationErrors: ValidationErrors = {};

    if (phone === '') {
      validationErrors.phone = 'Phone is required';
    } else if (phone.length < 10) {
      validationErrors.phone = 'Phone must be at least 10 digits';
    } else if (cleanPhone(phone) === '') {
      validationErrors.phone = 'Phone is invalid';
    }

    setErrors(validationErrors);

    if (!isEmpty(validationErrors)) {
      return;
    }

    try {
      await mutateAsync({ id, phone });
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={<Button color="blue">Send Test Request</Button>}
    >
      <Modal.Header>Test Webhook Config</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Form.Input
            error={errors.phone}
            label="Phone"
            name="phone"
            onChange={(_, { value }) => setPhone(value)}
            value={phone}
          />

          <Form.Button loading={isLoading} color="blue" fluid>
            Send Request
          </Form.Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default WebhookSendTestRequestModal;
