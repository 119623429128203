import { Button, Icon, Label, Modal, Table } from 'semantic-ui-react';

import { BlacklistIngestJob, JobStatus } from 'src/types';

const betaUrl = process.env.REACT_APP_BETA_URL;

const BlacklistJobDetailModal = ({ id: jobId, status, queued, payload }: BlacklistIngestJob) => {
  return (
    <Modal
      trigger={
        <Button title="View Details" icon>
          <Icon name="eye" />
        </Button>
      }
    >
      <Modal.Header>Job Details</Modal.Header>
      <Modal.Content>
        {status === JobStatus.Error && typeof payload.error !== 'undefined' && payload.error.length > 0 && (
          <Label color="red">
            Error
            <Label.Detail>{payload.error}</Label.Detail>
          </Label>
        )}

        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell style={{ width: 200 }}>Created At</Table.Cell>
              <Table.Cell>{queued}</Table.Cell>
            </Table.Row>

            {typeof payload.raw_gs_path !== 'undefined' && (
              <Table.Row>
                <Table.Cell>Raw Data</Table.Cell>
                <Table.Cell>
                  <a href={`${betaUrl}/api/jobs/${jobId}/download?stage=raw`} style={{ marginRight: '1rem' }}>
                    <Icon name="download" /> {payload.raw_gs_path.slice(payload.raw_gs_path.indexOf('0-raw'))}
                  </a>
                  <Label>
                    {payload.raw_num_rows}
                    <Label.Detail>Rows</Label.Detail>
                  </Label>
                </Table.Cell>
              </Table.Row>
            )}

            {typeof payload.parsed_gs_path !== 'undefined' && (
              <Table.Row>
                <Table.Cell>Mapped Data</Table.Cell>
                <Table.Cell>
                  <a href={`${betaUrl}/api/jobs/${jobId}/download?stage=parsed`} style={{ marginRight: '1rem' }}>
                    <Icon name="download" /> {payload.parsed_gs_path.slice(payload.parsed_gs_path.indexOf('1-parsed'))}
                  </a>
                  <Label>
                    {payload.parsed_num_rows}
                    <Label.Detail>Rows</Label.Detail>
                  </Label>
                </Table.Cell>
              </Table.Row>
            )}
            {typeof payload.phones_added !== 'undefined' && (
              <Table.Row>
                <Table.Cell>Number of phones added</Table.Cell>
                <Table.Cell>{payload.phones_added}</Table.Cell>
              </Table.Row>
            )}
            {typeof payload.emails_added !== 'undefined' && (
              <Table.Row>
                <Table.Cell>Number of emails added</Table.Cell>
                <Table.Cell>{payload.emails_added}</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  );
};

export default BlacklistJobDetailModal;
