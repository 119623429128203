import { useCallback, useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { Row } from 'src/styles';

type Props = {
  fieldId: string;
  onRemoveField: (fieldId: string) => void;
};

const AdminWebhookRemoveFieldModal = ({ fieldId, onRemoveField }: Props) => {
  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onSubmit = async () => {
    onRemoveField(fieldId);
    onClose();
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button type="button" color="red" icon style={{ marginTop: 24, width: 40, height: 38 }}>
          <Icon name="trash" />
        </Button>
      }
    >
      <Modal.Header>Remove Field from Config</Modal.Header>
      <Modal.Content>
        <Form onSubmit={onSubmit}>
          <p style={{ marginTop: 0 }}>
            <strong>Are you sure?</strong> This action cannot be undone.
          </p>

          <Row>
            <Button type="button" onClick={onClose} fluid>
              Cancel
            </Button>

            <Button onClick={onSubmit} color="red" fluid>
              Remove Field
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AdminWebhookRemoveFieldModal;
