import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';

import { useUpdateAccountMutation } from 'src/api/admin/accounts';
import { useGetHubspotDealsQuery } from 'src/api/admin/hubspot';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import theme from 'src/styles/theme';
import { Account } from 'src/types';

type Props = {
  account: Account;
};

type Formdata = {
  deal_id: string;
};

type ValidationErrors = {
  deal_id?: string;
};

const getInitialFormdata = (account: Account): Formdata => {
  return {
    deal_id: account.hubspot.deal_id || '',
  };
};

const AdminAccountHubspot = ({ account }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [formdata, setFormdata] = useState<Formdata>(() => getInitialFormdata(account));
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { mutateAsync, isLoading: mutationIsLoading } = useUpdateAccountMutation();
  const { data, isLoading } = useGetHubspotDealsQuery({ limit: 100, after: '' });

  const validate = useCallback((input: Formdata): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onChange = useCallback((_, { name, value }) => setFormdata(prev => ({ ...prev, [name]: value })), []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    if (!isEmpty(validate(formdata))) {
      return;
    }

    try {
      await mutateAsync({ id: account.id, hubspot: { ...formdata } });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [account.id, mutateAsync, formdata, validate]);

  return (
    <Form onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      <Grid>
        <Grid.Row>
          <Grid.Column width={6}>
            {/* <Header as="h4">Deal</Header> */}

            <Form.Select
              label="Deal"
              name="deal_id"
              value={formdata.deal_id}
              onChange={onChange}
              options={
                data?.deals?.map(d => ({
                  key: d.id,
                  value: d.id,
                  text: d.properties.dealname,
                  description: d.id,
                })) || []
              }
              error={errors.deal_id}
              loading={isLoading}
              clearable
              search
            />
            <p style={{ color: theme.gray, margin: 0 }}>
              The selected deal will received revenue / billing updates on a nightly basis if billing is enabled on this
              account.
            </p>
          </Grid.Column>

          <Grid.Column width={6}></Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Button color="blue" loading={mutationIsLoading}>
              Save
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default AdminAccountHubspot;
