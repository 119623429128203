import { FormEvent, useCallback, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useDetachPipelineFilterSetMutation } from 'src/api/pipelines';
import ApiMessage from 'src/components/ApiMessage';
import { Note, Row } from 'src/styles';

type Props = {
  disabled?: boolean;
  pipelineID: number;
};

const DetachPipelineFilterSetModal = ({ disabled, pipelineID }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [open, setOpen] = useState(false);
  const { mutateAsync, isLoading } = useDetachPipelineFilterSetMutation();

  const onClose = useCallback(() => {
    setApiMessage(undefined);
    setOpen(false);
  }, []);

  const onSubmit = useCallback(
    async (evt: FormEvent<HTMLFormElement>) => {
      evt.stopPropagation();

      setApiMessage(undefined);

      try {
        await mutateAsync(pipelineID);
        onClose();
      } catch (e: any) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [mutateAsync, onClose, pipelineID]
  );

  return (
    <Modal
      size="mini"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Form.Button disabled={disabled} type="button">
          Detach
        </Form.Button>
      }
    >
      <Modal.Header>Detach Filter Set from Pipeline</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Note>
            Detaching this Filter Set from the Pipeline will copy the filters from the Filter Set onto the Pipeline
            itself and disassociate the Fitler Set from the Pipeline.
          </Note>

          <Row>
            <Button fluid type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button fluid loading={isLoading} color="blue">
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default DetachPipelineFilterSetModal;
