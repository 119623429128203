import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

import PaginatedTable from 'src/components/PaginatedTable';
import { Container, Header, Row } from 'src/styles';
import JobsListBody from './JobsListBody';

const headers = ['ID', 'type', 'Started at', 'Status', 'Last Updated', 'Actions'];

const DataflowsHistory = () => {
  const { goBack } = useHistory();

  return (
    <Container>
      <Helmet>
        <title>Job History - Dataflow | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center' }}>
        <Button icon color="blue" onClick={goBack} style={{ marginRight: '1rem' }}>
          <Icon name="arrow left" />
        </Button>
        <Header style={{ margin: 0 }}>History</Header>
      </Row>

      <PaginatedTable headers={headers} renderBody={JobsListBody} />
    </Container>
  );
};

export default DataflowsHistory;
