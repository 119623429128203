import { useQuery } from 'react-query';

import { Feed, PaginatedResponse } from 'src/types';
import { ApiResponse, http } from './http-common';

export type FeedResponse = ApiResponse & {
  feed: Feed;
};

export type FeedsResponse = ApiResponse & {
  feeds: PaginatedResponse<Feed>;
};

export const useGetFeedQuery = (id: number) => {
  return useQuery(
    ['feeds', id],
    async () => {
      const res = await http.get<FeedResponse>(`/api/feeds/${id}`);
      return res.data;
    },
    { retry: false }
  );
};

export type ListFeedsParams = {
  limit: number;
  offset: number;
};

export const useListFeedsQuery = (params: ListFeedsParams) => {
  return useQuery(['feeds', params], async () => {
    const res = await http.get<FeedsResponse>(`/api/feeds`, { params });
    return res.data.feeds;
  });
};
