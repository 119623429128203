import { StyledCircle } from './styles';

const Circle = () => {
  return (
    <StyledCircle width="450" height="450" viewBox="0 0 450 450" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M225 450C349.264 450 450 349.264 450 225C450 100.736 349.264 0 225 0C100.736 0 0 100.736 0 225C0 349.264 100.736 450 225 450ZM225 380C310.604 380 380 310.604 380 225C380 139.396 310.604 70 225 70C139.396 70 70 139.396 70 225C70 310.604 139.396 380 225 380Z"
        fill="#5A6CF1"
      />
    </StyledCircle>
  );
};

export default Circle;
