import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Label, Placeholder, PlaceholderParagraph, Table } from 'semantic-ui-react';

import { ListLeadscoreConfigFilters, useListLeadscoreConfigsQuery } from 'src/api/admin/leadscore-plus';
import { RenderProps } from 'src/components/PaginatedTable';
import theme from 'src/styles/theme';
import AdminDeleteLeadscoreConfig from './AdminDeleteLeadscoreConfig';
import DuplicateLeadscoreConfig from './AdminDuplicateLeadscoreConfig';
import ToggleLeadscoreConfigEnabledButton from './AdminToggleLeadscoreConfigEnabledButton';

type Props = RenderProps & {
  filters?: ListLeadscoreConfigFilters;
};

const PlaceholderRow = () => (
  <Table.Row>
    <Table.Cell collapsing>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
  </Table.Row>
);

const AdminListLeadscoreConfigsBody = ({ limit, currentPage, setPageCount, filters }: Props) => {
  const { data: configs, isLoading } = useListLeadscoreConfigsQuery({
    limit,
    offset: limit * (currentPage - 1),
    ...filters,
  });

  useEffect(() => {
    let count = 1;
    if (typeof configs !== 'undefined') {
      count = Math.ceil(configs.total / limit);
    }
    setPageCount(count);
  }, [configs, limit, setPageCount]);

  if (isLoading) {
    return <PlaceholderRow />;
  }

  if (!configs?.data.length) {
    return (
      <Table.Row>
        <Table.Cell colSpan={5}>
          <span style={{ color: theme.gray }}>No configs found</span>
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      {configs?.data.map(c => (
        <Table.Row key={c.id}>
          <Table.Cell collapsing>
            <ToggleLeadscoreConfigEnabledButton config={c} />
          </Table.Cell>
          <Table.Cell>
            <Link to={{ pathname: `/admin/leadscore-plus/configs/${c.id}` }}>
              <strong>{c.name}</strong>
            </Link>
            <span style={{ display: 'block', color: theme.gray, fontSize: '0.8rem', lineHeight: '1rem' }}>{c.id}</span>
          </Table.Cell>

          <Table.Cell>{c.accountId === 0 ? <Label>System</Label> : c.account?.name}</Table.Cell>

          <Table.Cell textAlign="right" collapsing>
            <DuplicateLeadscoreConfig leadscore={c} />
            <Link to={{ pathname: `/admin/leadscore-plus/configs/${c.id}` }}>
              <Button as="div" color="blue" icon title="Edit config">
                <Icon name="pencil" />
              </Button>
            </Link>
            <AdminDeleteLeadscoreConfig id={c.id} />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default AdminListLeadscoreConfigsBody;
