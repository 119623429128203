import { useMutation, useQuery, useQueryClient } from 'react-query';

import { JobStatus, JobType } from 'src/types';
import { http } from '../http-common';
import { JobResponse, JobsResponse } from '../jobs';

export type CreateJobInput = {
  type: JobType;
  args: Record<string, any>;
};

export const useCreateJobMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: CreateJobInput) => {
      const res = await http.post<JobResponse>(`/api/admin/jobs`, input);
      return res.data.job;
    },
    {
      onSuccess: (_, { type }) => {
        queryClient.invalidateQueries(['jobs', type]);
      },
    }
  );
};

export type GetAdminJobsInput = {
  limit?: number;
  offset?: number;
  type?: number;
  objectid?: string;
  statuses?: JobStatus[];
};

export const useGetAdminJobsQuery = (params: GetAdminJobsInput) => {
  const { type, objectid, limit, offset } = params;

  return useQuery(
    ['jobs', type, objectid, { limit, offset }],
    async () => {
      const res = await http.get<JobsResponse>('/api/admin/jobs', { params });
      return res.data.jobs;
    },
    { refetchInterval: 5000 }
  );
};
