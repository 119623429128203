import { format } from 'date-fns';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { Button, CheckboxProps, Dropdown, DropdownProps, Form, Label, Modal, Table } from 'semantic-ui-react';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useAddScheduleMutation, useListSchedulesQuery, useSaveScheduleMutation } from 'src/api/schedules';
import ApiMessage from 'src/components/ApiMessage';
import { Schedule, TimezoneOptions, WeekdayShortName } from 'src/types';

export const getInitialScheduleData = (): Schedule => ({
  id: 0,
  name: '',
  enabled: true,
  // timezone: 'America/New_York',
  config: {
    sun: { enabled: false, start: 0, end: 0 },
    mon: { enabled: false, start: 0, end: 0 },
    tue: { enabled: false, start: 0, end: 0 },
    wed: { enabled: false, start: 0, end: 0 },
    thu: { enabled: false, start: 0, end: 0 },
    fri: { enabled: false, start: 0, end: 0 },
    sat: { enabled: false, start: 0, end: 0 },
  },
});

export const Days: { value: WeekdayShortName; text: string }[] = [
  { value: 'sun', text: 'Sun' },
  { value: 'mon', text: 'Mon' },
  { value: 'tue', text: 'Tues' },
  { value: 'wed', text: 'Wed' },
  { value: 'thu', text: 'Thur' },
  { value: 'fri', text: 'Fri' },
  { value: 'sat', text: 'Sat' },
];

let _now = new Date();
_now.setHours(0, 0, 0, 0);

export const TimeOptions = Array(48)
  .fill(0)
  .map((_, i) => {
    const opt = { key: i, value: i, text: format(_now, 'HH:mm') };
    _now = new Date(_now.getTime() + 1000 * 60 * 30);
    return opt;
  });

type ScheduleTableProps = {
  renderTemplates?: boolean;
  renderTimezone?: boolean;
  schedule: Schedule;
  setSchedule: (s: Schedule) => void;
};

const ScheduleTableForm = ({
  schedule,
  setSchedule,
  renderTemplates = false,
  renderTimezone = false,
}: ScheduleTableProps) => {
  const [apiMessage, setApiMessageData] = useState<ApiMessageData>();
  const [showSaveScheduleModal, setShowSaveScheduleModal] = useState(false);
  const [scheduleName, setScheduleName] = useState('');
  const [selectedScheduleId, setSelectedScheduleId] = useState<number>((schedule && schedule.id) || 0);
  const { data: schedules, isLoading: schedulesLoading } = useListSchedulesQuery({ limit: 100, offset: 0 });
  const { mutateAsync: saveSchedule, isLoading: saveLoading } = useSaveScheduleMutation();
  const { mutateAsync: addSchedule, isLoading: addLoading } = useAddScheduleMutation();

  const savedSchedules = useMemo(
    () => schedules?.data.map(s => ({ key: s.id, value: s.id, text: s.name })) || [],
    [schedules?.data]
  );

  const onSave = useCallback(
    (asNew = false) =>
      async () => {
        setApiMessageData(undefined);
        try {
          if (asNew) {
            schedule.id = 0;
            schedule.name = scheduleName;
            const newSchedule = await addSchedule({ schedule });
            if (newSchedule) setSelectedScheduleId(newSchedule.id);
          } else {
            await saveSchedule({ schedule });
          }
        } catch (e: any) {
          apiErrorHandler(e, setApiMessageData);
        }
      },
    [addSchedule, saveSchedule, schedule, scheduleName]
  );

  const onChangeDropdown = useCallback(
    (_, { value }) => {
      if (!value) {
        // Clear schedule table
        setSchedule(getInitialScheduleData());
        setSelectedScheduleId(0);
        return;
      }

      setSelectedScheduleId(Number(value));

      const selectedSchedule = schedules?.data.find(s => s.id === Number(value));
      if (selectedSchedule) setSchedule(selectedSchedule);
    },
    [schedules?.data, setSchedule]
  );

  const onChangeTimezone = useCallback(
    (_event: SyntheticEvent<HTMLElement, Event>, { name, value }: DropdownProps) => {
      const next: Schedule = _cloneDeep(schedule);
      _set(next, 'timezone', value);
      setSchedule(next);
    },
    [schedule, setSchedule]
  );

  const onChangeEnabled = useCallback(
    (_event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
      const name: any = data.name;
      const [dow]: [WeekdayShortName] = (name || '').split('.');
      const next: Schedule = {
        ...schedule,
        config: {
          ...schedule.config,
          [dow]: { ...schedule.config[dow], enabled: data.checked },
        },
      };
      setSchedule(next);
    },
    [schedule, setSchedule]
  );

  const onChangeTime = useCallback(
    (_event: SyntheticEvent<HTMLElement, Event>, { name, value }: DropdownProps) => {
      const [dow, when]: [WeekdayShortName, 'start' | 'end'] = name.split('.');
      const next: Schedule = {
        ...schedule,
        config: {
          ...schedule.config,
          [dow]: { ...schedule.config[dow], [when]: Number(value) },
        },
      };
      setSchedule(next);
    },
    [schedule, setSchedule]
  );

  const c = schedule.config;

  return (
    <>
      {renderTemplates && (
        <>
          <ApiMessage data={apiMessage} />

          <Form.Group>
            <Form.Dropdown
              style={{ minWidth: 200 }}
              label="Schedule"
              clearable
              placeholder="Select a schedule"
              fluid
              search
              selection
              loading={schedulesLoading}
              options={savedSchedules}
              value={selectedScheduleId || ''}
              onChange={onChangeDropdown}
            />

            <Form.Field style={{ maxWidth: 80 }}>
              <label>&nbsp;</label>
              <Button.Group color="blue" fluid>
                <Button
                  fluid
                  color="blue"
                  type="button"
                  disabled={selectedScheduleId === 0}
                  onClick={onSave(false)}
                  loading={saveLoading || addLoading}
                >
                  Save
                </Button>
                <Dropdown
                  className="button icon"
                  floating
                  options={[
                    {
                      key: 'save-as-new',
                      text: 'Save As New',
                      onClick: () => setShowSaveScheduleModal(true),
                    },
                  ]}
                  trigger={<></>}
                />
              </Button.Group>
            </Form.Field>
          </Form.Group>

          <Modal size="mini" open={showSaveScheduleModal} onClose={() => setShowSaveScheduleModal(false)}>
            <Modal.Header>Save Schedule As</Modal.Header>
            <Modal.Content>
              <Form
                onSubmit={e => {
                  // NOTE: This <Form/> is inside <ScheduleImportForm/> so we need this to
                  // prevent the parent onSubmit() callback from being fired.
                  e.stopPropagation();

                  onSave(true)();
                  setShowSaveScheduleModal(false);
                }}
              >
                <Form.Input
                  fluid
                  label="Schedule Name"
                  type="text"
                  value={scheduleName}
                  onChange={e => setScheduleName(e.target.value)}
                />

                <Form.Button fluid color="blue">
                  Save
                </Form.Button>
              </Form>
            </Modal.Content>
          </Modal>
        </>
      )}

      {renderTimezone && (
        <Form.Select
          style={{ minWidth: 200 }}
          label="Timezone"
          placeholder="Select a timezone"
          fluid
          selection
          options={TimezoneOptions}
          value={schedule.timezone || ''}
          onChange={onChangeTimezone}
        />
      )}

      <Form.Field>
        <Table collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>Start{!renderTimezone && ' (EST)'}</Table.HeaderCell>
              <Table.HeaderCell>End{!renderTimezone && ' (EST)'}</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {Days.map(({ value, text }, index) => (
              <Table.Row
                key={index}
                positive={c[value].enabled}
                negative={c[value].enabled && c[value].end < c[value].start}
              >
                <Table.Cell>
                  <Form.Checkbox
                    name={`${value}.enabled`}
                    checked={c[value].enabled}
                    onChange={onChangeEnabled}
                    label={text}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Select
                    options={TimeOptions}
                    name={`${value}.start`}
                    onChange={onChangeTime}
                    value={c[value].start}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Select
                    options={TimeOptions}
                    name={`${value}.end`}
                    onChange={onChangeTime}
                    value={c[value].end}
                  />
                </Table.Cell>
                <Table.Cell>
                  {c[value].enabled && c[value].end < c[value].start && (
                    <Label color="red">End value cannot be less than start value.</Label>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Form.Field>
    </>
  );
};

export default ScheduleTableForm;
