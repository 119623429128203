import { useCallback, useState } from 'react';
import { Button, Form, Grid, Header } from 'semantic-ui-react';

import { useUpdateAccountMutation } from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Account, AccountCommio } from 'src/types';

type Props = {
  account: Account;
};

const getInitialFormdata = (account: Account): AccountCommio => {
  return {
    account_id: account.commio?.account_id || '',
    account_token: account.commio?.account_token || '',
  };
};

const AdminAccountCommio = ({ account }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [formdata, setFormdata] = useState<AccountCommio>(() => getInitialFormdata(account));
  const { mutateAsync, isLoading: mutationIsLoading } = useUpdateAccountMutation();

  const onChange = useCallback((_, { name, value }) => setFormdata(prev => ({ ...prev, [name]: value })), []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    try {
      await mutateAsync({ id: account.id, commio: { ...formdata } });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [account.id, mutateAsync, formdata]);

  return (
    <Form onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      <Grid>
        <Grid.Row>
          <Grid.Column width={6}>
            <Header as="h4">General Settings</Header>

            <Form.Input label="Account ID" name="account_id" value={formdata.account_id || ''} onChange={onChange} />

            <Form.Input
              label="Account Token"
              name="account_token"
              value={formdata.account_token || ''}
              onChange={onChange}
            />
          </Grid.Column>

          <Grid.Column width={8}></Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Button color="blue" loading={mutationIsLoading}>
              Save
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default AdminAccountCommio;
