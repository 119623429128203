import { Helmet } from 'react-helmet-async';
import { Divider, Grid, Header, Message, Segment } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import Loading from 'src/components/Loading';
import PaginatedTable from 'src/components/PaginatedTable';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader } from 'src/styles';
import theme from 'src/styles/theme';
import { accountConnectProBillingIsCurrent, accountLeadscorePlusBillingIsCurrent } from 'src/utils';
import AccountGeneralSettings from './AccountGeneralSettings';
import AddSchedule from './AddSchedule';
import ListAccountApiTokensBody from './ListAccountApiTokensBody';
import ListAccountSchedulesBody from './ListAccountSchedulesBody';

const AccountSettings = () => {
  const { data: user, isLoading } = useGetUserProfileQuery();

  if (isLoading) return <Loading />;

  if (!user || !user.active_account) return <NotFound />;

  const a = user.active_account;

  return (
    <Container>
      <Helmet>
        <title>Settings - Account | datascore</title>
      </Helmet>

      <PageHeader>
        <span style={{ color: theme.gray }}>Account</span> <span style={{ padding: '0 0.5rem' }}>/</span> Settings
      </PageHeader>

      <Segment>
        <Grid>
          {(!accountLeadscorePlusBillingIsCurrent(a) || !accountConnectProBillingIsCurrent(a)) && (
            <>
              <Grid.Row centered>
                {!accountLeadscorePlusBillingIsCurrent(a) && (
                  <Grid.Column width={8}>
                    <Message error>
                      <Message.Header>LeadScore+ Balance At or Below Zero</Message.Header>
                      <Message.Content>Please add funds to your account to keep using all features.</Message.Content>
                    </Message>
                  </Grid.Column>
                )}
                {!accountConnectProBillingIsCurrent(a) && (
                  <Grid.Column width={8}>
                    <Message error>
                      <Message.Header>ConnectPro Balance At or Below Zero</Message.Header>
                      <Message.Content>Please add funds to your account to keep using all features.</Message.Content>
                    </Message>
                  </Grid.Column>
                )}
              </Grid.Row>

              <Divider />
            </>
          )}

          <Grid.Row>
            <Grid.Column width={4}>
              <Header>General</Header>
            </Grid.Column>
            <Grid.Column width={4}>
              <AccountGeneralSettings account={a} />
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row>
            <Grid.Column width={4}>
              <Header>API Tokens</Header>
              {/* TODO: Add button to create api token */}
            </Grid.Column>
            <Grid.Column width={12}>
              <PaginatedTable
                headers={['Name', 'Enabled', 'Expires', 'Token', '']}
                renderBody={ListAccountApiTokensBody}
              />
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row>
            <Grid.Column width={4}>
              <Header>Schedules</Header>
              <AddSchedule />
            </Grid.Column>
            <Grid.Column width={12}>
              <PaginatedTable headers={['Name', 'Enabled', 'Days', '']} renderBody={ListAccountSchedulesBody} />
            </Grid.Column>
          </Grid.Row>

          {/* TODO: Add User Access Settings */}
        </Grid>
      </Segment>
    </Container>
  );
};

export default AccountSettings;
