import { Handle, Position } from 'react-flow-renderer';
import styled from 'styled-components';

type NodeHandleContainerProps = {
  position: Position;
};

const getNodeHandleContainerPosition = (position: Position) => {
  switch (position) {
    case Position.Top:
      return `
        top: 0;
        left: 0;
      `;
    case Position.Bottom:
      return `
        bottom: 0;
        left: 0;
      `;
    case Position.Right:
    case Position.Left:
    default:
      console.error('Not implemented');
      return '';
  }
};

export const NodeHandleContainer = styled.div<NodeHandleContainerProps>`
  position: absolute;
  display: flex;
  width: 100%;

  ${({ position }) => getNodeHandleContainerPosition(position)}
`;

const getNodeHandlePosition = (position: Position) => {
  switch (position) {
    case Position.Top:
      return `
            top: -50%;
        `;
    case Position.Bottom:
      return `
            bottom: 50%;
        `;
    case Position.Right:
    case Position.Left:
    default:
      console.error('Not implemented');
      return '';
  }
};

export const NodeHandle = styled(Handle)`
  all: unset;
  position: relative;
  display: block;
  width: 100%;
  height: 8px;
  background-color: transparent;

  &:hover {
    background-color: rgba(62, 52, 202, 0.2);

    &:before {
      background-color: ${({ theme }) => theme.brand};
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #555;
    border-radius: 50%;
    border: 1px solid #fff;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    ${({ position }) => getNodeHandlePosition(position)}
  }
`;

export const NodeContainer = styled.div<{ selected?: boolean }>`
  background: #fff;
  box-shadow: 0px 2px 16px -4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 250px;

  &::before {
    box-shadow: 0 0 0 2px #0092ff;
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 146, 255, 0.1);
    border-radius: inherit;
    pointer-events: none;
    display: ${({ selected }) => (selected ? 'block' : 'none')};
  }

  * {
    margin: 0 !important;
  }
`;

export const NodeOverlayHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
`;

export const OverlayContainer = styled.div`
  color: #dde2ff;
  padding: 32px;
`;

export const NodeOverlayTitle = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  margin: 0 !important;
`;

export const NodeOverlayDesription = styled.p`
  color: #959595;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin: 26px 0;
`;

export const NodeOverlayContent = styled.div`
  label {
    color: #fff !important;
  }

  .ui.toggle.checkbox .box:before,
  .ui.toggle.checkbox label:before {
    background: rgba(255, 255, 255, 0.05);
  }

  .ui.toggle.checkbox input:focus ~ .box:before,
  .ui.toggle.checkbox input:focus ~ label:before {
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
  }

  .ui.toggle.checkbox input:checked ~ .box,
  .ui.toggle.checkbox input:checked ~ label {
    color: rgba(255, 255, 255, 0.95) !important;
  }
`;

export const NodeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  gap: 8px;
`;

export const NodeHeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const NodeHeaderTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: 22px;
  color: #252733;
  width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
