import { useCallback, useRef, useState } from 'react';
import { Button, DropdownItemProps, Form, Modal } from 'semantic-ui-react';

import {
  useGetTwilioTrustProfilesQuery,
  useReassignTwilioIncomingPhonesTrustProfilesMutation,
} from 'src/api/auth/account-twilio';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { TrustHubCustomerProfile, TrustHubTrustProduct } from 'src/types';

type Props = {
  sids: string[];
  open: boolean;
  onSuccess?: () => void;
  onClose: () => void;
};

const profileToOption = (p: TrustHubCustomerProfile | TrustHubTrustProduct): DropdownItemProps => ({
  key: p.sid,
  value: p.sid,
  text: p.friendly_name,
  description: p.status.replace('twilio-', ''),
});

const TwilioReassignTrustProfilesModal = ({ sids, open, onSuccess, onClose }: Props) => {
  const modalRef = useRef(null);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [customerProfileSid, setCustomerProfileSid] = useState('');
  const [shakenStirSid, setShakenStirSid] = useState('');
  const [cnamSid, setCnamSid] = useState('');
  const [voiceIntegritySid, setVoiceIntegritySid] = useState('');
  const { data: profiles, isLoading: profilesLoading } = useGetTwilioTrustProfilesQuery();
  const { mutateAsync: reassignPhonesNumber, isLoading } = useReassignTwilioIncomingPhonesTrustProfilesMutation();

  const closeModal = useCallback(() => {
    if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
      // @ts-ignore
      modalRef.current.handleClose();
      onClose();
    }
  }, [onClose]);

  const onCloseModal = () => {
    setApiMessage(undefined);
    setCustomerProfileSid('');
    setShakenStirSid('');
    setCnamSid('');
    setVoiceIntegritySid('');
    onClose();
  };

  const onSubmit = useCallback(async (): Promise<void> => {
    setApiMessage(undefined);

    if (!customerProfileSid && !shakenStirSid && !cnamSid && !voiceIntegritySid) return;

    try {
      await reassignPhonesNumber({ sids, customerProfileSid, shakenStirSid, cnamSid, voiceIntegritySid });
      if (onSuccess !== undefined) {
        onSuccess();
      }

      closeModal();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [
    customerProfileSid,
    reassignPhonesNumber,
    sids,
    shakenStirSid,
    cnamSid,
    voiceIntegritySid,
    onSuccess,
    closeModal,
  ]);

  return (
    <Modal size="small" ref={modalRef} open={open} onClose={onCloseModal}>
      <Modal.Header>Reassign Trust Profile(s)</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <p>Leave any of the fields below blank to keep them unchanged.</p>

        <Form onSubmit={onSubmit}>
          <Form.Group widths="equal">
            <Form.Select
              label="Business Profile"
              placeholder="Do Not Change"
              loading={profilesLoading}
              clearable
              options={[
                { key: 'none', value: 'none', text: 'Unassign' },
                ...(profiles?.customerProfiles.map(profileToOption) || []),
              ]}
              value={customerProfileSid}
              onChange={(_, { value }) => setCustomerProfileSid(String(value))}
            />

            <Form.Select
              label="Shaken/Stir Profile"
              placeholder="Do Not Change"
              loading={profilesLoading}
              clearable
              options={[
                { key: 'none', value: 'none', text: 'Unassign' },
                ...(profiles?.shakenStirProfiles.map(profileToOption) || []),
              ]}
              value={shakenStirSid}
              onChange={(_, { value }) => setShakenStirSid(String(value))}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Select
              label="CNAM Registration"
              placeholder="Do Not Change"
              loading={profilesLoading}
              clearable
              options={[
                { key: 'none', value: 'none', text: 'Unassign' },
                ...(profiles?.cnamProfiles.map(profileToOption) || []),
              ]}
              value={cnamSid}
              onChange={(_, { value }) => setCnamSid(String(value))}
            />

            <Form.Select
              label="Voice Integrity"
              placeholder="Do Not Change"
              loading={profilesLoading}
              clearable
              options={[
                { key: 'none', value: 'none', text: 'Unassign' },
                ...(profiles?.voiceIntegrityProfiles.map(profileToOption) || []),
              ]}
              value={voiceIntegritySid}
              onChange={(_, { value }) => setVoiceIntegritySid(String(value))}
            />
          </Form.Group>

          <Row style={{ gap: '1rem' }}>
            <Button fluid type="button" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              color="blue"
              fluid
              loading={isLoading}
              disabled={customerProfileSid === '' && shakenStirSid === '' && cnamSid === '' && voiceIntegritySid === ''}
              style={{ marginRight: 0 }}
            >
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default TwilioReassignTrustProfilesModal;
