import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Icon, Label, Segment, Table } from 'semantic-ui-react';

import { useGetConvosoFileQuery } from 'src/api/convoso';
import JobHistory from 'src/components/JobHistory';
import Loading from 'src/components/Loading';
import NotFound from 'src/pages/not-found';
import { Container, Header, HorizontalDivider, Row } from 'src/styles';
import { ConvosoFileJob, ConvosoFileStatus, JobType } from 'src/types';
import { getConvosoFileStatusColor } from 'src/utils';
import UploadConvosoFile from './UploadConvosoFile';

const betaUrl = process.env.REACT_APP_BETA_URL;

const ConvosoViewFile = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { data: file, isLoading } = useGetConvosoFileQuery(Number(id));

  if (isLoading) return <Loading />;

  if (!file) return <NotFound />;

  return (
    <Container>
      <Helmet>
        <title>File Details - Convoso | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center' }}>
        <Button icon color="blue" onClick={history.goBack} style={{ marginRight: '1rem' }}>
          <Icon name="arrow left" />
        </Button>
        <Header style={{ margin: 0 }}>{file.filename}</Header>
      </Row>

      <Segment>
        <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Header style={{ marginBottom: 0 }}>File Details</Header>
          <UploadConvosoFile id={file.id} />
        </Row>
        <HorizontalDivider style={{ margin: '1rem -1rem' }} />

        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{file.created}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Filename</Table.Cell>
              <Table.Cell>{file.filename}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Last Status</Table.Cell>
              <Table.Cell>
                <Label color={getConvosoFileStatusColor(file.status)}>{ConvosoFileStatus[file.status]}</Label>
              </Table.Cell>
            </Table.Row>
            {file.payload?.raw_gs_path && (
              <Table.Row>
                <Table.Cell>Raw File Location</Table.Cell>
                <Table.Cell>
                  <a
                    href={`${betaUrl}/api/convoso/files/${file.id}/download?stage=raw`}
                    style={{ marginRight: '1rem' }}
                  >
                    <Icon name="download" /> {file.payload.raw_gs_path.slice(file.payload.raw_gs_path.indexOf('0-raw'))}
                  </a>
                  <Label>
                    {file.payload.raw_num_rows} <Label.Detail>Rows</Label.Detail>
                  </Label>
                </Table.Cell>
              </Table.Row>
            )}
            {file.payload?.parsed_gs_path && (
              <Table.Row>
                <Table.Cell>Parsed File Location</Table.Cell>
                <Table.Cell>
                  <a
                    href={`${betaUrl}/api/convoso/files/${file.id}/download?stage=parsed`}
                    style={{ marginRight: '1rem' }}
                  >
                    <Icon name="download" />{' '}
                    {file.payload.parsed_gs_path.slice(file.payload.parsed_gs_path.indexOf('1-parsed'))}
                  </a>
                  <Label>
                    {file.payload.parsed_num_rows} <Label.Detail>Rows</Label.Detail>
                  </Label>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment>

      <JobHistory
        details={(j: ConvosoFileJob) => (
          <Label color={j.payload.status === 'locked' ? 'orange' : 'green'} style={{ marginRight: '0.25rem' }}>
            {j.payload.status}
          </Label>
        )}
        jobType={JobType['Convoso Upload File']}
      />
    </Container>
  );
};

export default ConvosoViewFile;
