import { useEffect } from 'react';
import { Statistic, Table } from 'semantic-ui-react';

import { useListDatasetListsQuery } from 'src/api/datasets';
import { RenderProps } from 'src/components/PaginatedTable';
import { Dataset } from 'src/types';
import DatasetV2ListsDelete from './DatasetV2ListsDelete';
import DatasetV2ListsRowCount from './DatasetV2ListsRowCount';

type Props = RenderProps & {
  dataset: Dataset;
};

const DatasetV2ListsBody = ({ limit, currentPage, setPageCount, dataset }: Props) => {
  const { data: lists } = useListDatasetListsQuery({ datasetId: dataset.id, limit, offset: limit * (currentPage - 1) });

  useEffect(() => {
    if (!lists?.total) return;
    setPageCount(Math.ceil(lists.total / limit));
  }, [setPageCount, lists?.total, limit]);

  return (
    <>
      {lists?.data.map(list => (
        <Table.Row key={list.id}>
          <Table.Cell collapsing>{list.id}</Table.Cell>
          <Table.Cell>{list.name}</Table.Cell>
          <Table.Cell>
            <Statistic size="mini">
              <Statistic.Value>
                <DatasetV2ListsRowCount datasetId={dataset.id} listId={list.id} />
              </Statistic.Value>
            </Statistic>
          </Table.Cell>
          <Table.Cell collapsing>
            <DatasetV2ListsDelete datasetId={dataset.id} listId={list.id} />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default DatasetV2ListsBody;
