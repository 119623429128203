import { memo } from 'react';
import { Position } from 'react-flow-renderer';
import { Divider, Icon, Popup } from 'semantic-ui-react';

import { useGetBlacklistQuery } from 'src/api/blacklists';
import {
  NodeContainer,
  NodeHandle,
  NodeHeader,
  NodeHeaderTitle,
  NodeHeaderTitleContainer,
} from '../../components/NodeOverlay/styles';
import { NodeContainerProps } from '../../components/NodeOverlay/types';
import { BlacklistData } from '../types';
import { ContentInfoContainer, ContentInfoItem } from './styles';

type ContentInfoProps = {
  data?: BlacklistData;
};

const ContentInfo = ({ data }: ContentInfoProps) => {
  const { listId } = data || {};
  const { data: blacklist } = useGetBlacklistQuery(Number(listId));

  return (
    <ContentInfoContainer>
      <ContentInfoItem>
        List: <b>{blacklist?.name}</b>
      </ContentInfoItem>
    </ContentInfoContainer>
  );
};

const BlacklistNode = ({ data, selected }: NodeContainerProps) => {
  return (
    <NodeContainer selected={selected}>
      <NodeHandle type="target" position={Position.Top} />

      <NodeHeader>
        <NodeHeaderTitleContainer>
          <Icon name="ban" color="violet" />
          <NodeHeaderTitle>{data?.name || 'Blacklist'}</NodeHeaderTitle>
        </NodeHeaderTitleContainer>

        <Popup
          content="Scrubs out data that is blacklisted in one or more lists."
          trigger={<Icon name="info circle" color="grey" style={{ position: 'relative', zIndex: '99' }} />}
        />
      </NodeHeader>

      <Divider />

      <ContentInfo data={data} />

      <NodeHandle type="source" position={Position.Bottom} />
    </NodeContainer>
  );
};

export default memo(BlacklistNode);
