import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Loader } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { AgentAttributionDetailData, useGetAgentAttributionDetailDataQuery } from 'src/api/reporting';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Loading from 'src/components/Loading';
import useSearchQuery from 'src/hooks/useSearchQuery';
import NotFound from 'src/pages/not-found';
import { Container, Header, Row } from 'src/styles';

const { REACT_APP_BETA_URL: betaURL } = process.env;

const ReportingAgentAttributionDetailColumns = [
  'caseid',
  'status',
  'statusgroup',
  'sourcename',
  'campaign_id',
  'call_date',
  'full_name',
  'phone_number',
  'wrap_up',
  'system',
  'p1_unconverted',
  'createddate',
  'scheduled_amount',
  'scheduled_dates',
  'total_payments',
  'sale',

  'phone',
  'document_created_at',
  'document_created_at_et',
  'dataset_id',
  'dataset_name',
  'list_id',
  'list_name',
  'file_id',
  'most_recent_call_date',
  'most_recent_call_date_et',
  'recent_sip_response',
  'recent_call_status',
  'carrier_name',
  'call_count',
  'voicemail_drop_count',
  'recent_bot_campaign_name',
  'recent_bot_disposition_title',
  'recent_bot_duration',
  'recent_bot_transfer_did',
  'recent_bot_transfer_duration',
  'recent_bot_transfer_start_seconds',
  'recent_bot_transcript_has_agent',
  'raw_call_count',
  'is_blacklisted_client',
  'is_blacklisted_federal',
  'transfer_to',
  'blacklist_client_list_id',
  'blacklist_federal_list_id',
  'recent_dialer_disposition',
  'recent_dialer_created_date',
  'recent_dialer_entry_date',
  'e_score',
  'gender',
  'recent_bot_contact_status',
  'recent_call_sys_status',
  'dnc_status',
  'master_lead_status',
  'leadscore',
  'recent_hung_up_source',
] as const;

type ReportingAgentAttributionDetailColumn = typeof ReportingAgentAttributionDetailColumns[number];

const ReportingAgentAttributionDetails = () => {
  const { data: user, isLoading } = useGetUserProfileQuery();
  const { replace } = useHistory();
  const query = useSearchQuery();

  const [column, setColumn] = useState<ReportingAgentAttributionDetailColumn>(
    (query.get('column') ?? 'caseid') as ReportingAgentAttributionDetailColumn
  );
  const [direction, setDirection] = useState<'ascending' | 'descending'>(
    (query.get('direction') ?? 'ascending') as 'ascending' | 'descending'
  );

  const updateSort = useCallback(
    (col: ReportingAgentAttributionDetailColumn) => () => {
      const newDirection = column === col ? (direction === 'ascending' ? 'descending' : 'ascending') : 'ascending';
      setDirection(newDirection);
      setColumn(col);

      // update query params
      query.set('column', col);
      query.set('direction', newDirection);
      replace({ search: query.toString() });
    },
    [column, direction, query, replace]
  );

  const params = useMemo(
    () => ({
      startDate: query.get('startDate') ?? format(new Date(), 'yyyy-MM-dd'),
      endDate: query.get('endDate') ?? format(new Date(), 'yyyy-MM-dd'),
      byCreatedDate: query.get('byCreatedDate') === 'true',
      agent: query.get('agent'),
      campaign: query.get('campaign'),
      created: query.get('created'),
      sales: query.get('sales') === 'true',
    }),
    [query]
  );

  const DOWNLOAD_URL = useMemo(() => {
    const url = new URL(`${betaURL}/api/reporting/agent-attribution-detail/download`);

    const searchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(params)) {
      searchParams.append(key, value?.toString() ?? '');
    }

    url.search = searchParams.toString();

    return url.toString();
  }, [params]);

  const { data: AgentAttributionDetailDataResponse, isLoading: isDataLoading } =
    useGetAgentAttributionDetailDataQuery(params);

  const data = useMemo(() => {
    return AgentAttributionDetailDataResponse?.data || [];
  }, [AgentAttributionDetailDataResponse?.data]);

  const totalPayments = useMemo(() => data.reduce((sum, item) => sum + (item.total_payments ?? 0), 0), [data]);
  const totalSales = useMemo(() => data.reduce((sum, item) => sum + (item.sale ? 1 : 0), 0), [data]);

  const sortedData = useMemo(
    () =>
      data.sort((a, b) => {
        if (!column) return 1;

        const aValue = a[column];
        const bValue = b[column];

        if (aValue && bValue) {
          if (direction === 'ascending') {
            return aValue > bValue ? 1 : -1;
          }

          return aValue < bValue ? 1 : -1;
        }

        if (direction === 'ascending') {
          return a[column] ? 1 : -1;
        }

        return b[column] ? 1 : -1;
      }),
    [data, column, direction]
  );

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'caseid',
      desc: false,
    },
  ]);

  const columns = useMemo<ColumnDef<AgentAttributionDetailData>[]>(() => {
    const cols: ColumnDef<AgentAttributionDetailData>[] = [];
    ReportingAgentAttributionDetailColumns.forEach(c => {
      cols.push({
        id: c,
        header: () => {
          return <strong onClick={updateSort(c)}>{c}</strong>;
        },
        accessorFn: (row: AgentAttributionDetailData) => row[c]?.toLocaleString() || '',
        cell: ({ getValue, row }) => {
          const value = row.original[c];
          const formated =
            c === 'sale' || c === 'is_blacklisted_client' || c === 'is_blacklisted_federal'
              ? value
                ? 'True'
                : 'False'
              : c === 'total_payments'
              ? value
                ? `$${value?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}`
                : ''
              : value;
          return <div style={{ textAlign: 'right' }}>{formated}</div>;
        },
        sortingFn: 'alphanumeric',
      });
    });
    return cols;
  }, [updateSort]);

  const tableInstance = useReactTable({
    columns,
    data: sortedData || [],
    initialState: {
      sorting,
    },
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel<AgentAttributionDetailData>(),
  });

  if (isLoading) return <Loading />;

  if (!user || !user.active_account) return <NotFound />;

  return (
    <Container>
      <Helmet>
        <title>Agent Attribution Detail - Reporting | datascore</title>
      </Helmet>

      <Header>
        <Row style={{ justifyContent: 'space-between' }}>
          <Breadcrumbs crumbs={['Reporting', 'Agent Attribution Detail']} />

          <Button as="a" href={DOWNLOAD_URL} basic style={{ marginBottom: '1rem' }}>
            <Icon name="download" /> Export CSV
          </Button>
        </Row>
      </Header>

      <div className="table-container-fit">
        <table className="tanstack-table">
          <thead>
            {tableInstance.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: 'white',
                        zIndex: 1,
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className={header.column.getCanSort() ? 'pointer' : ''}
                          onClick={header.column.getToggleSortingHandler()}
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === 'asc'
                                ? 'Sort ascending'
                                : header.column.getNextSortingOrder() === 'desc'
                                ? 'Sort descending'
                                : 'Clear sort'
                              : undefined
                          }
                          style={{ textAlign: 'right' }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {isDataLoading ? (
            <tbody>
              <tr>
                <td colSpan={16}>
                  <Loader active inline />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {tableInstance.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
          <tfoot>
            <tr
              key="footer"
              style={{
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'white',
                zIndex: 0,
              }}
            >
              <td style={{ textAlign: 'right' }}>
                <strong>Grand Total</strong>
              </td>
              <td style={{ textAlign: 'right' }}>
                <strong>
                  ${totalPayments.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                </strong>
              </td>
              <td style={{ textAlign: 'right' }}>
                <strong>{totalSales}</strong>
              </td>
              <td colSpan={14}></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </Container>
  );
};

export default ReportingAgentAttributionDetails;
