import { useMutation } from 'react-query';

import { ApiResponse, http } from './http-common';

export type QualifaiAudioGetDurationInput = {
  url: string;
};

export type QualifaiAudioGetDurationResponse = ApiResponse & {
  duration: number;
};

export const useQualifaiAudioGetDurationMutation = () => {
  return useMutation(async (input: QualifaiAudioGetDurationInput) => {
    const res = await http.post<QualifaiAudioGetDurationResponse>(`/api/qualifai/audio/get-duration`, input);
    return res.data.duration;
  });
};

export type QualifaiAudioSttInput = {
  url: string;
};

export type QualifaiAudioSttResponse = ApiResponse & {
  text: string;
};

export const useQualifaiAudioSttMutation = () => {
  return useMutation(async (input: QualifaiAudioSttInput) => {
    const res = await http.post<QualifaiAudioSttResponse>(`/api/qualifai/audio/stt`, input);
    return res.data.text;
  });
};

export type QualifaiAudioTtsInput = {
  text: string;
};

export type QualifaiAudioTtsResponse = ApiResponse & {
  url: string;
};

export const useQualifaiAudioTtsMutation = () => {
  return useMutation(async (input: QualifaiAudioTtsInput) => {
    const res = await http.post<QualifaiAudioTtsResponse>(`/api/qualifai/audio/tts`, input);
    return res.data.url;
  });
};
