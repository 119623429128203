import { Link, useParams } from 'react-router-dom';
import { Button, Icon, Loader, Message } from 'semantic-ui-react';

import { useGetUserInviteQuery } from 'src/api/auth';
import Circle from '../Circle';
import { Container, ContainerLeft, ContainerRight, GridCenter, Title } from '../styles';
import AcceptInviteForm from './AcceptInviteForm';

const AuthAcceptInvite = () => {
  const { token } = useParams<{ token: string }>();
  const { data: invite, isLoading: queryLoading } = useGetUserInviteQuery({ token });

  return (
    <GridCenter>
      <Container key="accept-invite" style={{ width: 920 }}>
        <ContainerLeft style={{ position: 'relative' }}>
          {queryLoading ? (
            <Loader active />
          ) : invite ? (
            <AcceptInviteForm invite={invite} />
          ) : (
            <>
              <Message error visible>
                <Message.Header>🚨 Invalid Invite</Message.Header>
                <Message.Content>
                  We were unable to locate your invitation or it may have expired. Please contact us for assistance:{' '}
                  <a
                    href="mailto:info@datascore.ai"
                    target="_blank"
                    style={{ color: 'inherit', fontWeight: 'bold', textDecoration: 'underline' }}
                    rel="noreferrer"
                  >
                    info@datascore.ai
                  </a>
                </Message.Content>
              </Message>

              <Button as={Link} to="/" color="blue">
                <Icon name="chevron left" />
                Return to Login
              </Button>
            </>
          )}
        </ContainerLeft>

        <ContainerRight>
          <Title to="/">datascore</Title>
          <Circle />
        </ContainerRight>
      </Container>
    </GridCenter>
  );
};

export default AuthAcceptInvite;
