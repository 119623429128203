import { useMutation, useQueryClient } from 'react-query';

import { AccountResponse } from '../accounts';
import { http } from '../http-common';

export type UpdateAccountTelnyxInput = {
  id: number;
  billing_group_id: string;
  purchase_limit: number;
};

export const useUpdateAccountTelnyxMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, ...params }: UpdateAccountTelnyxInput) => {
      const res = await http.post<AccountResponse>(`/api/admin/accounts/${id}/telnyx-profile`, params);
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['admin/accounts', account.id]);
        queryClient.invalidateQueries(['accounts', account.id]);
        queryClient.invalidateQueries(['accounts']);
        queryClient.invalidateQueries(['auth/profile']);
        queryClient.invalidateQueries(['auth/account']);
      },
    }
  );
};
