import { useMutation, useQuery, useQueryClient } from 'react-query';

import { AkkioModel, LeadscoreConfig, PaginatedResponse } from 'src/types';
import { ApiResponse, http } from './http-common';

export type AkkioModelResponse = ApiResponse & {
  model?: AkkioModel;
};

export type AkkioModelsResponse = ApiResponse & {
  models: PaginatedResponse<AkkioModel>;
};

export type LeadscoreConfigResponse = ApiResponse & {
  config?: LeadscoreConfig;
};

export type LeadscoreConfigsResponse = ApiResponse & {
  configs: PaginatedResponse<LeadscoreConfig>;
};

export type ListAkkioModelsParams = {
  limit: number;
  offset: number;
};

export const useListAkkioModelsQuery = (params: ListAkkioModelsParams) => {
  return useQuery(['leadscore-plus', 'akkio-models', params], async () => {
    const res = await http.get<AkkioModelsResponse>('/api/leadscore-plus/akkio-models', { params });
    return res.data.models;
  });
};

export type ListLeadscoreConfigsParams = {
  limit: number;
  offset: number;
};

export const useListLeadscoreConfigsQuery = (params: ListLeadscoreConfigsParams) => {
  return useQuery(['leadscore-plus', 'configs', params], async () => {
    const res = await http.get<LeadscoreConfigsResponse>('/api/leadscore-plus/configs', { params });
    return res.data.configs;
  });
};

export const useGetLeadscoreConfigQuery = (id: string) => {
  return useQuery(['leadscore-plus', 'configs', id], async () => {
    if (!id) return undefined;
    const res = await http.get<LeadscoreConfigResponse>(`/api/leadscore-plus/configs/${id}`);
    return res.data.config;
  });
};

export type AddLeadscoreConfigParams = {
  name: string;
};

export const useAddLeadscoreConfigMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: AddLeadscoreConfigParams) => {
      const res = await http.post<LeadscoreConfigResponse>(`/api/leadscore-plus/configs`, params);
      return res.data.config;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['leadscore-plus', 'configs']);
      },
    }
  );
};

export type SaveLeadscoreConfigParams = {
  config: LeadscoreConfig;
};

export const useSaveLeadscoreConfigMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: SaveLeadscoreConfigParams) => {
      const res = await http.post<LeadscoreConfigResponse>(
        `/api/leadscore-plus/configs/${params.config.id}`,
        params.config
      );
      return res.data.config;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['leadscore-plus', 'configs']);
      },
    }
  );
};

export const useDeleteLeadscoreConfigMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      const res = await http.delete<ApiResponse>(`/api/leadscore-plus/configs/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['leadscore-plus', 'configs']);
      },
    }
  );
};

export const useDuplicateLeadscoreConfigMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      const res = await http.post<ApiResponse>(`/api/leadscore-plus/configs/${id}/duplicate`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['leadscore-plus', 'configs']);
      },
    }
  );
};
