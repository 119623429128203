export const STEPS = {
  BUSINESS_PROFILE: 'business-profile',
  CALL_CENTER: 'call-center',
  // SCHEDULE_INTERVIEW: 'schedule-interview',
} as const;

export type Step = typeof STEPS[keyof typeof STEPS];

export type StepProps = {
  onBack: () => void;
  onContinue: () => void;
};
