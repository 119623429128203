import styled from 'styled-components';

export const FlowLayout = styled.div`
  display: grid;
  flex: 1;
  grid-template-areas: 'h h' 'c s';
  grid-template-columns: 1fr 500px;
  grid-template-rows: 50px 1fr;
  height: 100%;
`;

export const FlowContent = styled.div`
  background-color: #191919;
  grid-area: c;
  border: 1px solid #bbb;
  border-bottom-width: 0;
`;

export const FlowSidebar = styled.div`
  grid-area: s;
  overflow-y: auto;
  background: #191919;
`;
