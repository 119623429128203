import _uniq from 'lodash/uniq';
import { useCallback, useMemo, useState } from 'react';
import { Form } from 'semantic-ui-react';

import { useUpdateAccountMutation } from 'src/api/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Account } from 'src/types';

type ValidationErrors = {
  newEmail?: string;
};

type Props = {
  account: Account;
};

const AccountBillingAlertsForm = ({ account }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [newEmail, setNewEmail] = useState('');
  const { mutateAsync: updateAccount, isLoading: updateLoading } = useUpdateAccountMutation();

  const alertEmails = useMemo(() => {
    const emails = account.billing.alertEmails.split(',');
    if (emails.length === 1 && emails[0] === '') {
      return [];
    }
    return emails;
  }, [account.billing.alertEmails]);

  const onRemove = useCallback(
    (email: string) => async () => {
      setApiMessage(undefined);

      try {
        const next = [...alertEmails];
        next.splice(alertEmails.indexOf(email), 1);
        account.billing.alertEmails = next.length > 0 ? next.join(',') : '';
        await updateAccount({ id: account.id, billing: account.billing });
      } catch (e) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [account.billing, account.id, alertEmails, updateAccount]
  );

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);
    setErrors({});

    if (newEmail === '') {
      setErrors({ newEmail: 'required' });
      return;
    }

    try {
      account.billing.alertEmails = _uniq([...alertEmails, newEmail]).join(',');
      await updateAccount({ id: account.id, billing: account.billing });
      setNewEmail('');
    } catch (e) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [account.billing, account.id, alertEmails, newEmail, updateAccount]);

  return (
    <>
      <Form onSubmit={onSubmit}>
        <ApiMessage data={apiMessage} />

        {alertEmails.map(email => (
          <Form.Group key={email} widths="equal">
            <Form.Input readOnly value={email} />
            <Form.Button icon="trash" type="button" color="red" onClick={onRemove(email)} />
          </Form.Group>
        ))}

        <Form.Group widths="equal">
          <Form.Input value={newEmail} error={errors.newEmail} onChange={(_, { value }) => setNewEmail(value)} />
          <Form.Button icon="plus" color="blue" type="submit" loading={updateLoading} />
        </Form.Group>
      </Form>
    </>
  );
};

export default AccountBillingAlertsForm;
