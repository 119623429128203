import { isEmpty } from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import { useCallback, useMemo, useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { useDeleteDatasetColumnMutation } from 'src/api/bigquery';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { BigqueryTable } from 'src/types';

type ValidationErrors = {
  name?: string;
};

type FormData = {
  name: string;
};

const DatasetDeleteColumnModal = ({ table }: { table: BigqueryTable }) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({});

  const { mutateAsync, isLoading } = useDeleteDatasetColumnMutation();
  const [formdata, setFormdata] = useState<FormData>({
    name: '',
  });

  const onChange = useCallback((_, { checked, name, value }) => {
    setFormdata(prev => {
      const v = typeof checked !== 'undefined' ? checked : value;

      const next = _cloneDeep(prev);
      _set(next, name, v);

      return next;
    });
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
    setFormdata({ name: '' });
    setApiMessage(undefined);
    setErrors({});
  }, []);

  const onSubmit = async () => {
    const validationErrors: ValidationErrors = {};

    if (formdata.name === '') {
      validationErrors.name = 'Name is required';
    }

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors);
      return;
    }

    try {
      setApiMessage(undefined);
      await mutateAsync({ id: table.id, ...formdata });
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  const columns = useMemo(
    () =>
      Object.keys(table?.settings?.customColumns || {}).map(b => ({
        key: b,
        value: b,
        text: b,
      })),
    [table]
  );

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button floated="right" color="red" disabled={columns.length === 0}>
          <Icon name="minus" />
          Delete Column
        </Button>
      }
    >
      <Modal.Header>Delete Dataset Column</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Form.Select
            label="Name"
            placeholder="Name"
            error={errors.name}
            name="name"
            options={columns}
            onChange={onChange}
          />

          <Row>
            <Button type="button" disabled={isLoading} onClick={onClose} fluid>
              Cancel
            </Button>

            <Button loading={isLoading} onClick={onSubmit} color="red" fluid>
              Delete Column
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default DatasetDeleteColumnModal;
