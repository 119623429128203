import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Icon, Segment } from 'semantic-ui-react';

import { useGetVoiceConfigQuery } from 'src/api/voice-configs';
import Breadcrumbs from 'src/components/Breadcrumbs';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader, Row } from 'src/styles';
import EditVoiceConfigForm from './EditVoiceConfigForm';
import { PlaceholderLayout } from './style';

const VoiceConfigEdit = () => {
  const { id } = useParams<{ id: string }>();
  const [isFormValid, setIsFormValid] = useState(false);
  const { goBack } = useHistory();
  const { data: config, isLoading } = useGetVoiceConfigQuery(id);

  return (
    <Container>
      <Helmet>
        <title>Edit Voice Config | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center' }}>
        <Button icon color="blue" onClick={goBack} style={{ marginRight: '1rem' }}>
          <Icon name="arrow left" />
        </Button>
        <PageHeader style={{ margin: 0 }}>
          <Breadcrumbs crumbs={['Voice', 'Configs', 'Edit']} />
        </PageHeader>
      </Row>

      <Segment>
        {isLoading ? (
          <PlaceholderLayout />
        ) : typeof config !== 'undefined' ? (
          <EditVoiceConfigForm config={config} isValid={isFormValid} setIsValid={setIsFormValid} />
        ) : (
          <NotFound />
        )}
      </Segment>
    </Container>
  );
};

export default VoiceConfigEdit;
