import { useCallback, useState } from 'react';
import { Button, DropdownProps, Form, Loader, Message } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import {
  GetTwilioTrustProfilesResponse,
  UpdateTwilioCustomerProfileParams,
  useGetTwilioTrustProfilesQuery,
  useUpdateTwilioCustomerProfileMutation,
} from 'src/api/auth/account-twilio';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { TrustHubTrustProduct, TwilioCustomerProfile } from 'src/types';

type FormProps = {
  customerProfile: TwilioCustomerProfile | null;
  trustProfiles: GetTwilioTrustProfilesResponse;
};

const getInitialFormdata = (p: TwilioCustomerProfile | null): UpdateTwilioCustomerProfileParams => ({
  customerProfileSid: p?.customer_profile_sid || '',
  shakenStirProfileSid: p?.shaken_stir_profile_sid || '',
  cnamProfileSid: p?.cnam_profile_sid || '',
  voiceIntegrityProfileSid: p?.voice_integrity_profile_sid || '',
});

const TrustHubProfilesForm = ({ customerProfile, trustProfiles }: FormProps) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [formdata, setFormdata] = useState<UpdateTwilioCustomerProfileParams>(() =>
    getInitialFormdata(customerProfile)
  );
  const { mutateAsync, isLoading: updateLoading } = useUpdateTwilioCustomerProfileMutation();

  const onChange = useCallback((_, { name, value }: DropdownProps) => {
    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    try {
      await mutateAsync(formdata);
    } catch (e) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [formdata, mutateAsync]);

  const filterTrustProfiles = (trustProfile: TrustHubTrustProduct) => {
    const assignment = trustProfiles.assignments.find(assignment => assignment.trust_product_sid === trustProfile.sid);
    return assignment && assignment.object_sid === formdata.customerProfileSid;
  };

  const hasError = (name: 'shakenStirProfileSid' | 'cnamProfileSid' | 'voiceIntegrityProfileSid'): boolean => {
    return !!formdata[name] && !filterTrustProfiles({ sid: formdata[name] } as TrustHubTrustProduct);
  };

  const validCustomerProfile = (sid: string): boolean => {
    return !sid || !!trustProfiles.customerProfiles.find(profile => profile.sid === sid);
  };

  return (
    <Form onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      <Form.Group widths="equal">
        <Form.Select
          label="Business Profile"
          clearable
          placeholder={
            !validCustomerProfile(formdata.customerProfileSid)
              ? 'Invalid profile selected'
              : trustProfiles.customerProfiles.length === 0
              ? 'No profiles found'
              : 'Select a profile'
          }
          name="customerProfileSid"
          options={trustProfiles.customerProfiles.map(p => ({
            key: p.sid,
            value: p.sid,
            text: p.friendly_name,
            description: p.status.replace('twilio-', ''),
          }))}
          onChange={onChange}
          value={formdata.customerProfileSid}
          error={!validCustomerProfile(formdata.customerProfileSid)}
        />

        <Form.Select
          label="SHAKEN/STIR"
          clearable
          placeholder={
            hasError('shakenStirProfileSid')
              ? 'Invalid profile selected'
              : trustProfiles.shakenStirProfiles.filter(filterTrustProfiles).length === 0
              ? 'No profiles found'
              : 'Select a profile'
          }
          name="shakenStirProfileSid"
          options={trustProfiles.shakenStirProfiles.filter(filterTrustProfiles).map(p => ({
            key: p.sid,
            value: p.sid,
            text: p.friendly_name,
            description: p.status.replace('twilio-', ''),
          }))}
          onChange={onChange}
          value={formdata.shakenStirProfileSid}
          error={hasError('shakenStirProfileSid')}
        />

        <Form.Select
          label="CNAM Registration"
          clearable
          placeholder={
            hasError('cnamProfileSid')
              ? 'Invalid profile selected'
              : trustProfiles.cnamProfiles.filter(filterTrustProfiles).length === 0
              ? 'No profiles found'
              : 'Select a profile'
          }
          name="cnamProfileSid"
          options={trustProfiles.cnamProfiles.filter(filterTrustProfiles).map(p => ({
            key: p.sid,
            value: p.sid,
            text: p.friendly_name,
            description: p.status.replace('twilio-', ''),
          }))}
          onChange={onChange}
          value={formdata.cnamProfileSid}
          error={hasError('cnamProfileSid')}
        />

        <Form.Select
          label="Voice Integrity"
          clearable
          placeholder={
            hasError('voiceIntegrityProfileSid')
              ? 'Invalid profile selected'
              : trustProfiles.voiceIntegrityProfiles.filter(filterTrustProfiles).length === 0
              ? 'No profiles found'
              : 'Select a profile'
          }
          name="voiceIntegrityProfileSid"
          options={trustProfiles.voiceIntegrityProfiles.filter(filterTrustProfiles).map(p => ({
            key: p.sid,
            value: p.sid,
            text: p.friendly_name,
            description: p.status.replace('twilio-', ''),
          }))}
          onChange={onChange}
          value={formdata.voiceIntegrityProfileSid}
          error={hasError('voiceIntegrityProfileSid')}
        />
      </Form.Group>

      <Button color="blue" loading={updateLoading}>
        Save
      </Button>
    </Form>
  );
};

const TrustHubProfiles = () => {
  const { data: user, isLoading: userLoading } = useGetUserProfileQuery();
  const { data: trustProfiles, isLoading: trustLoading } = useGetTwilioTrustProfilesQuery();

  if (userLoading || trustLoading) return <Loader active />;

  if (!trustProfiles) {
    return (
      <Message error>
        <Message.Header>Failed to load trust profiles</Message.Header>
      </Message>
    );
  }

  const customerProfile = user?.active_account.twilio_customer_profile || null;

  return <TrustHubProfilesForm customerProfile={customerProfile} trustProfiles={trustProfiles} />;
};

export default TrustHubProfiles;
