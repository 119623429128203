import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Button, Table } from 'semantic-ui-react';

import { useDuplicateDataflowMutation, useListDataflowsQuery } from 'src/api/dataflows';
import BillingWarning from 'src/components/BillingWarning';
import Loading from 'src/components/Loading';
import { Container, Header, Row } from 'src/styles';
import { Dataflow } from 'src/types';
import AddDataflow from './AddDataflow';

const DataflowsList = () => {
  const { data, isLoading } = useListDataflowsQuery({ limit: 10, offset: 0 });
  const { mutateAsync: duplicateDataflow, isLoading: isDuplicateLoading } = useDuplicateDataflowMutation();

  const dataflows = useMemo(() => data?.data ?? ([] as Dataflow[]), [data]);

  return (
    <Container>
      <Helmet>
        <title>Dataflows | datascore</title>
      </Helmet>

      <BillingWarning />

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header style={{ marginBottom: 0 }}>Dataflows</Header>
        <AddDataflow />
      </Row>

      {isLoading ? (
        <Loading />
      ) : (
        <Table padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Created at</Table.HeaderCell>
              <Table.HeaderCell>&nbsp;</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {dataflows.map(dataflow => (
              <Table.Row key={dataflow.id}>
                <Table.Cell>{dataflow.name}</Table.Cell>
                <Table.Cell>{dataflow.created}</Table.Cell>
                <Table.Cell textAlign="right" collapsing>
                  <Link to={{ pathname: `/dataflows/${encodeURIComponent(dataflow.id)}` }}>
                    <Button icon="pencil" color="blue" />
                  </Link>

                  <Link to={{ pathname: `/dataflows/${encodeURIComponent(dataflow.id)}/history` }}>
                    <Button icon="history" color="blue" />
                  </Link>

                  <Button
                    icon="clone outline"
                    color="blue"
                    onClick={() => duplicateDataflow(dataflow.id)}
                    loading={isDuplicateLoading}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </Container>
  );
};

export default DataflowsList;
