import { Helmet } from 'react-helmet-async';
import { Grid, Message } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import PaginatedTable from 'src/components/PaginatedTable';
import { Container, Header, Row } from 'src/styles';
import theme from 'src/styles/theme';
import { accountLeadscorePlusBillingIsCurrent } from 'src/utils';
import AddLeadscoreConfig from './AddLeadscoreConfig';
import ListLeadscoreConfigsBody from './ListLeadscoreConfigsBody';

const ListLeadscoreConfigs = () => {
  const { data: user } = useGetUserProfileQuery();
  const a = user?.active_account;

  return (
    <Container>
      <Helmet>
        <title>Configs - LeadScore+ | datascore</title>
      </Helmet>

      {a && !accountLeadscorePlusBillingIsCurrent(a) && (
        <Grid>
          <Grid.Row centered>
            <Grid.Column width={8}>
              <Message error>
                <Message.Header>LeadScore+ Balance At or Below Zero</Message.Header>
                <Message.Content>Please add funds to your account to keep using all features.</Message.Content>
              </Message>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header style={{ margin: 0 }}>
          <span style={{ color: theme.gray }}>LeadScore+</span> <span style={{ padding: '0 0.5rem' }}>/</span> Configs
        </Header>
        <AddLeadscoreConfig />
      </Row>

      <PaginatedTable headers={['Status', 'Name', '']} renderBody={ListLeadscoreConfigsBody} />
    </Container>
  );
};

export default ListLeadscoreConfigs;
