import { useCallback, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';

import { apiErrorHandler } from 'src/api/http-common';
import { useQualifaiAudioSttMutation } from 'src/api/qualifai-audio';

type Props = {
  audioUrl: string;
  onSuccess: (text: string) => void;
};

const ButtonTranscribeAudio = ({ audioUrl, onSuccess }: Props) => {
  const { mutateAsync: stt, isLoading: sttIsLoading } = useQualifaiAudioSttMutation();
  const [apiError, setApiError] = useState<string | undefined>();

  const onTranscribeAudio = useCallback(async () => {
    if (!audioUrl) return;

    try {
      const text = await stt({ url: audioUrl });
      onSuccess(text);
    } catch (e: any) {
      const msg = apiErrorHandler(e);
      setApiError(typeof msg.message === 'string' ? msg.message : e.message);
      setTimeout(() => setApiError(undefined), 3000);
    }
  }, [audioUrl, onSuccess, stt]);

  return (
    <Button
      type="button"
      size="mini"
      compact
      color={apiError ? 'red' : 'blue'}
      style={{ position: 'relative', top: -4, float: 'right', margin: 0 }}
      onClick={!apiError ? onTranscribeAudio : undefined}
      loading={sttIsLoading}
    >
      <Icon name={apiError ? 'warning sign' : 'arrow up'} />
      {apiError || 'Transcribe Audio'}
    </Button>
  );
};

export default ButtonTranscribeAudio;
