import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory } from 'react-router-dom';
import { Button, Icon, Menu, Message, Segment, Tab, TabProps } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Loading from 'src/components/Loading';
import useSearchQuery from 'src/hooks/useSearchQuery';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader, Row } from 'src/styles';
import {
  accountConnectProBillingIsCurrent,
  accountLeadscorePlusBillingIsCurrent,
  hasAccountAccessRoles,
} from 'src/utils';
import AccountBillingConnectProPane from './AccountBillingConnectProPane';
import AccountBillingGeneral from './AccountBillingGeneral';
import AccountBillingLeadscorePlusPane from './AccountBillingLeadscorePlusPane';

const AccountsBillingSettings = () => {
  const { data: user, isLoading } = useGetUserProfileQuery();
  const query = useSearchQuery();
  const { push, replace } = useHistory();
  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    if (!query.has('tab')) {
      const search = new URLSearchParams();
      search.set('tab', 'connect-pro');
      replace({ search: search.toString() });
      return;
    }

    switch (query.get('tab')) {
      case 'leadscore-plus':
        setActiveTab(1);
        break;

      default:
      case 'connect-pro':
        setActiveTab(0);
        break;
    }
  }, [query, replace]);

  const onTabChange = useCallback(
    (_event: React.MouseEvent<HTMLDivElement | HTMLAnchorElement>, { activeIndex }: TabProps): void => {
      // setActiveTab(Number(activeIndex));
      const search = new URLSearchParams();
      search.set('tab', activeIndex === 0 ? 'connect-pro' : 'leadscore-plus');
      push({ search: search.toString() });
    },
    [push]
  );

  if (isLoading) return <Loading />;

  if (!user || !user.active_account || !hasAccountAccessRoles(user, ['owner', 'billing'])) return <NotFound />;

  const a = user.active_account;

  const panes = [
    {
      menuItem: () => (
        <Menu.Item
          key="connectPro"
          as="a"
          onClick={e => onTabChange(e, { activeIndex: 0 })}
          className={activeTab === 0 ? 'active' : undefined}
        >
          {!accountConnectProBillingIsCurrent(a) && <Icon name="warning circle" color="red" />}
          Connect Pro
        </Menu.Item>
      ),
      render: () => <AccountBillingConnectProPane account={a} />,
    },
    {
      menuItem: () => (
        <Menu.Item
          key="leadscorePlus"
          as="a"
          onClick={e => onTabChange(e, { activeIndex: 1 })}
          className={activeTab === 1 ? 'active' : undefined}
        >
          {!accountLeadscorePlusBillingIsCurrent(a) && <Icon name="warning circle" color="red" />}
          LeadScore+
        </Menu.Item>
      ),
      render: () => <AccountBillingLeadscorePlusPane account={a} />,
    },
  ];

  return (
    <Container>
      <Helmet>
        <title>Billing - Account | datascore</title>
      </Helmet>

      <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <PageHeader style={{ marginBottom: 0 }}>
          <Breadcrumbs crumbs={['Account', 'Billing']} />
        </PageHeader>

        <Link to={`/account/billing/docs`}>
          <Button color="blue" as="div">
            <Icon name="book" /> API Docs
          </Button>
        </Link>
      </Row>

      {!a.billing.enabled ? (
        <Segment>
          <Message success>
            <Message.Header>🎉 Free Tier Active</Message.Header>
            <Message.Content>No payment required at this time.</Message.Content>
          </Message>
        </Segment>
      ) : (
        <>
          <AccountBillingGeneral account={a} />

          <Tab
            menu={{ secondary: true, pointing: true }}
            onTabChange={onTabChange}
            renderActiveOnly
            activeIndex={activeTab}
            panes={panes}
          />
        </>
      )}
    </Container>
  );
};

export default AccountsBillingSettings;
