import diff from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import { parse } from 'papaparse';
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Button, DropdownProps, Form, FormProps, Label, Message, Table } from 'semantic-ui-react';
import stringSimilarity from 'string-similarity';

import { AddFileInput } from 'src/api/convoso';
import { DelimiterOptions, FileDelimiters } from 'src/config';
import { ConvosoProfile } from 'src/types';

type ValidationErrors = {
  profile_id?: string;
  file?: string;
  delimiter?: string;
  field_mappings?: string;
};

type Props = {
  handleSubmit: (formdata: AddFileInput) => void;
  loading: boolean;
  profiles: ConvosoProfile[];
};

const RequiredFields = ['phone_number', 'first_name', 'last_name', 'address1', 'city', 'state', 'postal_code'];
const ExtraFields = ['email', 'utility_provider', 'electric_bill'];

// For a better string matching, add an alias for fields
const aliasMapping: { [key: string]: string[] } = {
  postal_code: ['postal_code', 'zip'],
};

const FieldMappingOptions = [
  { key: '__skip__', text: 'Do Not Import', value: '__skip__' },
  { key: '__keep__', text: 'Keep As-Is', value: '__keep__' },
  ...RequiredFields.map(f => ({ key: f, text: f, value: f })),
  ...ExtraFields.map(f => ({ key: f, text: f, value: f })),
];

const getInitialFormdata = (): AddFileInput => ({
  profile_id: 0,
  file: undefined,
  delimiter: 'Comma',
  fieldMappings: [],
});

const ImportForm = ({ handleSubmit, loading, profiles }: Props) => {
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<AddFileInput>(() => getInitialFormdata());
  const [filePreview, setFilePreview] = useState<string[][] | null>(null);

  const validate = useCallback((input: AddFileInput): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    if (typeof input.file === 'undefined') {
      validationErrors.file = 'file is required';
    } else if (['text/csv', 'text/plain'].indexOf(input.file.type) === -1) {
      validationErrors.file = 'Invalid file type, please select a CSV';
    }

    const missingMappings: string[] = diff(RequiredFields, input.fieldMappings);
    if (missingMappings.length > 0) {
      validationErrors.field_mappings = missingMappings.join(', ');
    }

    if (input.profile_id <= 0) {
      validationErrors.profile_id = 'profile is required';
    }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  useEffect(() => {
    setFilePreview(null);
    if (typeof formdata.file === 'undefined') {
      return;
    }

    const d = FileDelimiters.has(formdata.delimiter) ? FileDelimiters.get(formdata.delimiter) : ',';

    const parsedRows: string[][] = [];
    parse<string[]>(formdata.file, {
      delimiter: d,
      step: ({ data }, parser) => {
        parsedRows.push(data);
        if (parsedRows.length >= 10) {
          parser.abort();
          return;
        }
      },
      complete: () => {
        setFilePreview(parsedRows);

        setFormdata(prev => {
          const fieldMappingValues = FieldMappingOptions.map(fm => fm.value);

          const headers = parsedRows[0];

          const fm = Array(headers.length).fill('__keep__');

          for (const f of fieldMappingValues) {
            if (f === '__skip__' || f === '__keep__') continue;

            let bestScore = 0;
            let bestIdx = 0;
            headers.forEach((h, i) => {
              if (fm[i] !== '__skip__' && fm[i] !== '__keep__') return;

              const fields = aliasMapping[f.toLowerCase()] || [f.toLowerCase()];

              for (const field of fields) {
                const ratio = stringSimilarity.compareTwoStrings(h.toLowerCase(), field);

                if (ratio >= bestScore) {
                  bestScore = ratio;
                  bestIdx = i;
                }
              }
            });

            if (bestScore >= 0.5) {
              fm[bestIdx] = f;
            }
          }

          const next = {
            ...prev,
            fieldMappings: fm,
          };
          validate(next);
          return next;
        });
      },
    });
  }, [formdata.delimiter, formdata.file, validate]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFormdata(prev => {
        let file;
        if (event.target.files && event.target.files.length) {
          file = event.target.files[0];
        }

        const next = { ...prev, file };
        validate(next);
        return next;
      });
    },
    [validate]
  );

  const onChangeSelect = useCallback(
    (_event: SyntheticEvent<HTMLElement, Event>, { name, value }: DropdownProps) => {
      setFormdata(prev => {
        const next = { ...prev, [name]: value };
        validate(next);
        return next;
      });
    },
    [validate]
  );

  const onChangeFieldMapping = useCallback(
    (i: number) =>
      (_event: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        setFormdata(prev => {
          const newMappings = [...prev.fieldMappings];
          newMappings[i] = `${value}`;
          const next = { ...prev, fieldMappings: newMappings };
          validate(next);
          return next;
        });
      },
    [validate]
  );

  const onSubmit = useCallback(
    async (_event: React.FormEvent<HTMLFormElement>, _data: FormProps) => {
      if (!isEmpty(validate(formdata))) {
        return;
      }

      await handleSubmit(formdata);

      setFormdata(getInitialFormdata());
      setFilePreview(null);
    },
    [formdata, handleSubmit, validate]
  );

  const ignoreAllKept = useCallback(() => {
    setFormdata(prev => {
      const next = {
        ...prev,
        fieldMappings: prev.fieldMappings.map(fm => {
          if (fm !== '__keep__') return fm;
          return '__skip__';
        }),
      };
      validate(next);
      return next;
    });
  }, [validate]);

  const keepAllIgnored = useCallback(() => {
    setFormdata(prev => {
      const next = {
        ...prev,
        fieldMappings: prev.fieldMappings.map(fm => {
          if (fm !== '__skip__') return fm;
          return '__keep__';
        }),
      };
      validate(next);
      return next;
    });
  }, [validate]);

  const profileOptions = profiles.map(p => ({ value: p.id, text: p.name }));

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group widths="equal">
        <Form.Field error={!!errors.file}>
          <label>File</label>
          <input type="file" name="file" onChange={onChange} />
          {errors.file && (
            <Label pointing prompt>
              {errors.file}
            </Label>
          )}
        </Form.Field>

        <Form.Select
          label="Profile"
          value={formdata.profile_id}
          options={profileOptions}
          name="profile_id"
          onChange={onChangeSelect}
          error={errors.profile_id}
        />

        <Form.Select
          label="Delimiter"
          value={formdata.delimiter}
          options={DelimiterOptions}
          name="delimiter"
          onChange={onChangeSelect}
          error={errors.delimiter}
        />
      </Form.Group>

      {errors.field_mappings && (
        <Message error visible>
          <Message.Header>🚨 Missing required field mappings:</Message.Header>
          <Message.Content>{errors.field_mappings}</Message.Content>
        </Message>
      )}

      {filePreview && (
        <>
          <div style={{ marginBottom: '1rem' }}>
            <Button type="button" content={`Ignore All "Kept As-Is"`} onClick={ignoreAllKept} />
            <Button type="button" content={`Keep All "Do Not Import"`} onClick={keepAllIgnored} />
          </div>

          <div style={{ overflowX: 'scroll', marginBottom: '1rem' }}>
            <Table>
              <Table.Header>
                <Table.Row>
                  {filePreview[0].map((_cell: string, i: number) => (
                    <Table.HeaderCell key={i}>
                      <Form.Select
                        upward={false}
                        options={FieldMappingOptions.filter(
                          o =>
                            o.value === '__skip__' ||
                            o.value === '__keep__' ||
                            o.value === formdata.fieldMappings[i] ||
                            formdata.fieldMappings.indexOf(o.value) === -1
                        )}
                        value={formdata.fieldMappings[i]}
                        onChange={onChangeFieldMapping(i)}
                        style={
                          formdata.fieldMappings[i] === '__skip__'
                            ? { background: 'transparent', color: 'rgba(0,0,0,0.25)' }
                            : formdata.fieldMappings[i] === '__keep__'
                            ? undefined
                            : {
                                borderColor: 'rgba(44, 102, 45, 0.5)',
                                background: '#fcfff5',
                                color: '#2c662d',
                              }
                        }
                      />
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {filePreview
                  .filter((r: string[]) => r && r.length === filePreview[0].length)
                  .map((row: string[], rowIdx: number) => (
                    <Table.Row key={rowIdx}>
                      {row.map((cell: string, cellIdx: number) => (
                        <Table.Cell key={`${rowIdx}-${cellIdx}`}>{cell}</Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </div>
        </>
      )}

      <Button content="Upload" color="blue" loading={loading} />
    </Form>
  );
};

export default ImportForm;
