import { isEmpty } from 'lodash';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Button, DropdownItemProps, Form, InputOnChangeData, Progress, Table } from 'semantic-ui-react';

import {
  PostalCodeStats,
  PurchaseData,
  PurchasePostalCodesInput,
  useGetTwilioStudioFlowsQuery,
  usePurchasePostalCodesMutation,
} from 'src/api/auth/account-twilio';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  stats: PostalCodeStats;
};

type ValidationErrors = {
  howMany?: string;
  webhookUrl?: string;
};

type Formdata = {
  howMany: string;
  group: 'city' | 'state';
  webhookUrl: string;
};

type StateCounts = { [key: string]: string };

const PurchasePostalCodesForm = ({ onCancel, onSuccess, stats }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<Formdata>({ howMany: '1', group: 'city', webhookUrl: '' });
  const [showProgress, setShowProgress] = useState(false);
  const [preparing, setPreparing] = useState(false);
  const [progress, setProgress] = useState({ amount: 0, total: 0 });
  const [stateCounts, setStateCounts] = useState<StateCounts>(
    Object.keys(stats.byState).reduce((counts, s) => {
      return { ...counts, [s]: '1' };
    }, {} as StateCounts)
  );
  const { mutateAsync: purchasePostalCodes, isLoading: purchaseLoading } = usePurchasePostalCodesMutation();

  const { data, isLoading: flowsLoading } = useGetTwilioStudioFlowsQuery();
  const webhookUrlOptions = useMemo<DropdownItemProps[]>(
    () =>
      data?.flows?.map(f => ({
        key: f.sid,
        text: f.friendly_name,
        value: f.webhook_url,
      })) || [],
    [data?.flows]
  );

  const validate = useCallback((input: Formdata): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    if (Number.isNaN(Number(input.howMany))) {
      validationErrors.howMany = 'must be a number';
    } else if (Number(input.howMany) % 1 !== 0) {
      validationErrors.howMany = 'must be a whole number';
    } else if (Number(input.howMany) <= 0) {
      validationErrors.howMany = 'must be greater than 0';
    }

    if (input.webhookUrl === '') {
      validationErrors.webhookUrl = 'required';
    }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onChangeGroup = useCallback((_, { value }) => {
    setFormdata(prev => ({ ...prev, group: value === 'city' ? 'city' : 'state' }));
  }, []);

  const onChangeHowMany = useCallback(
    (_, { value }) => {
      const howMany = String(value);

      setStateCounts(
        Object.keys(stats.byState).reduce((counts, s) => {
          return { ...counts, [s]: howMany };
        }, {} as StateCounts)
      );

      setFormdata(prev => {
        const next = { ...prev, howMany };
        validate(next);
        return next;
      });
    },
    [stats.byState, validate]
  );

  const onChangeStateCount = useCallback(
    (s: string) =>
      (_: ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData) => {
        setStateCounts(prev => ({
          ...prev,
          [s]: String(value),
        }));
      },
    []
  );

  const onChangeWebhookUrl = useCallback(
    (_, { value }) =>
      setFormdata(prev => {
        const next = { ...prev, webhookUrl: String(value) };
        validate(next);
        return next;
      }),
    [validate]
  );

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);
    setShowProgress(false);
    setProgress({ amount: 0, total: 0 });

    if (!isEmpty(validate(formdata))) {
      return;
    }

    try {
      const input: PurchasePostalCodesInput = { group: formdata.group, webhookUrl: formdata.webhookUrl, counts: [] };

      setPreparing(true);

      switch (formdata.group) {
        case 'city': {
          const purchaseCounts: PurchaseData[] = Object.keys(stats.byCity).map(s => {
            const [city, state] = s.split(',');
            return { city, state, count: Number(formdata.howMany), latLng: stats.byCity[s].latLng };
          });

          setPreparing(false);

          const total = purchaseCounts.length;

          const batchSize = 10;

          if (total > batchSize) {
            setShowProgress(true);
            setProgress({ amount: 0, total });
          }

          while (purchaseCounts.length > 0) {
            await Promise.all(
              Array(5)
                .fill(0)
                .map(_ => {
                  return new Promise<void>(async res => {
                    if (purchaseCounts.length <= 0) return res();

                    const batch = purchaseCounts.splice(0, batchSize);
                    await purchasePostalCodes({ ...input, counts: batch });
                    // console.log(batch);
                    setProgress(prev => ({ ...prev, amount: prev.amount + batch.length }));
                    res();
                  });
                })
            );
          }

          setProgress({ total, amount: total });
          break;
        }

        case 'state':
          const purchaseCounts: PurchaseData[] = Object.keys(stateCounts).map(s => ({
            state: s,
            count: Number(stateCounts[s]),
          }));

          setPreparing(false);

          const total = purchaseCounts.reduce((total, { count }) => total + count, 0);

          setShowProgress(true);
          setProgress({ amount: 0, total });

          const batchSize = 10;
          while (purchaseCounts.length > 0) {
            let purchased = 0;
            const [{ state, count: desired }] = purchaseCounts.splice(0, 1);

            while (purchased < desired) {
              const remaining = desired - purchased;
              const count = batchSize < remaining ? batchSize : remaining;
              await purchasePostalCodes({ ...input, counts: [{ state, count }] });

              purchased += count;
              setProgress(prev => ({ ...prev, amount: prev.amount + count }));
            }
          }

          setProgress({ total, amount: total });
          break;
      }

      onSuccess();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [formdata, onSuccess, purchasePostalCodes, stateCounts, stats.byCity, validate]);

  let phoneCount = 0;

  switch (formdata.group) {
    case 'city':
      let multiplier = 1;
      if (!Number.isNaN(Number(formdata.howMany))) {
        multiplier = Number(formdata.howMany);
      }

      phoneCount = stats.cities * multiplier;
      break;

    case 'state':
      Object.keys(stateCounts).forEach(s => {
        phoneCount += Number(stateCounts[s]);
      });
      break;
  }

  return (
    <Form onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Raw</Table.HeaderCell>
            <Table.HeaderCell>Unique</Table.HeaderCell>
            <Table.HeaderCell>Valid</Table.HeaderCell>
            <Table.HeaderCell>
              <Form.Radio
                label="Cities"
                name="group"
                value="city"
                checked={formdata.group === 'city'}
                onChange={onChangeGroup}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Form.Radio
                label="States"
                name="group"
                value="state"
                checked={formdata.group === 'state'}
                onChange={onChangeGroup}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{stats.raw}</Table.Cell>
            <Table.Cell>{stats.unique}</Table.Cell>
            <Table.Cell>{stats.valid}</Table.Cell>
            <Table.Cell>{stats.cities}</Table.Cell>
            <Table.Cell>{stats.states}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Form.Group widths="equal">
        <Form.Input
          label={`How many phone numbers would you like to purchase per ${formdata.group}?`}
          value={formdata.howMany}
          onChange={onChangeHowMany}
          error={errors.howMany}
        />

        <Form.Select
          label="Select flow to assign new numbers to"
          loading={flowsLoading}
          clearable
          options={webhookUrlOptions}
          value={formdata.webhookUrl}
          onChange={onChangeWebhookUrl}
          error={errors.webhookUrl}
        />
      </Form.Group>

      {formdata.group === 'state' && (
        <Table celled compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>State</Table.HeaderCell>
              <Table.HeaderCell>Zip Count</Table.HeaderCell>
              <Table.HeaderCell>Purchase Count</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {Object.keys(stats.byState).map(s => (
            <Table.Row>
              <Table.Cell>
                <strong>{s}</strong>
              </Table.Cell>
              <Table.Cell>{stats.byState[s]}</Table.Cell>
              <Table.Cell collapsing>
                <Form.Input value={stateCounts[s]} onChange={onChangeStateCount(s)} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table>
      )}

      {showProgress && (
        <Progress percent={(progress.amount / progress.total) * 100} active color="green">
          {progress.amount} / {progress.total} Purchased
        </Progress>
      )}

      <Row>
        <Button fluid type="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="green" fluid loading={preparing || purchaseLoading}>
          Buy {phoneCount} Phones
        </Button>
      </Row>
    </Form>
  );
};

export default PurchasePostalCodesForm;
