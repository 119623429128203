import { Button, Icon, Label, Modal, Table } from 'semantic-ui-react';

import { DatasetUploadJob, JobStatus } from 'src/types';

const betaUrl = process.env.REACT_APP_BETA_URL;

const DatasetsJobDetailModal = ({ id: jobId, status, queued, payload }: DatasetUploadJob) => {
  return (
    <Modal
      trigger={
        <Button title="View Details" icon>
          <Icon name="eye" />
        </Button>
      }
    >
      <Modal.Header>Job Details</Modal.Header>
      <Modal.Content>
        {status === JobStatus.Error && typeof payload.error !== 'undefined' && payload.error.length > 0 && (
          <Label color="red">
            Error
            <Label.Detail>{payload.error}</Label.Detail>
          </Label>
        )}

        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell style={{ width: 200 }}>Created At</Table.Cell>
              <Table.Cell>{queued}</Table.Cell>
            </Table.Row>

            {typeof payload.raw_gs_path !== 'undefined' && (
              <Table.Row>
                <Table.Cell>Raw Data</Table.Cell>
                <Table.Cell>
                  <a href={`${betaUrl}/api/jobs/${jobId}/download?stage=raw`} style={{ marginRight: '1rem' }}>
                    <Icon name="download" /> {payload.raw_gs_path.slice(payload.raw_gs_path.indexOf('0-raw'))}
                  </a>
                  <Label>
                    {payload.raw_num_rows}
                    <Label.Detail>Rows</Label.Detail>
                  </Label>
                </Table.Cell>
              </Table.Row>
            )}

            <Table.Row>
              <Table.Cell>Insert Count</Table.Cell>
              <Table.Cell>{payload.success_count ?? 0}</Table.Cell>
            </Table.Row>

            {!!payload.column_count_error_count && (
              <Table.Row>
                <Table.Cell>Rows with invalid column count</Table.Cell>
                <Table.Cell>{payload.column_count_error_count}</Table.Cell>
              </Table.Row>
            )}
            {!!payload.invalid_row_error_count && (
              <Table.Row>
                <Table.Cell>Invalid Rows</Table.Cell>
                <Table.Cell>{payload.invalid_row_error_count}</Table.Cell>
              </Table.Row>
            )}
            {!!payload.required_fields_error_count && (
              <Table.Row>
                <Table.Cell>Rows missing required fields</Table.Cell>
                <Table.Cell>{payload.required_fields_error_count}</Table.Cell>
              </Table.Row>
            )}
            {!!payload.required_fields_error_count && (
              <Table.Row>
                <Table.Cell>Rows missing required fields</Table.Cell>
                <Table.Cell>{payload.required_fields_error_count}</Table.Cell>
              </Table.Row>
            )}
            {!!payload.filtered_fields_error_count && (
              <Table.Row>
                <Table.Cell>Rows filtered out</Table.Cell>
                <Table.Cell>{payload.filtered_fields_error_count}</Table.Cell>
              </Table.Row>
            )}
            {!!payload.duplicates_error_count && (
              <Table.Row>
                <Table.Cell>Duplicate rows</Table.Cell>
                <Table.Cell>{payload.duplicates_error_count}</Table.Cell>
              </Table.Row>
            )}
            {!!payload.blacklist_error_count && (
              <Table.Row>
                <Table.Cell>Blacklisted rows</Table.Cell>
                <Table.Cell>{payload.blacklist_error_count}</Table.Cell>
              </Table.Row>
            )}
            {!!payload.excluded_carrier_error_count && (
              <Table.Row>
                <Table.Cell>Excluded carriers</Table.Cell>
                <Table.Cell>{payload.excluded_carrier_error_count}</Table.Cell>
              </Table.Row>
            )}
            {!!payload.connects_error_count && (
              <Table.Row>
                <Table.Cell>Excluded connects</Table.Cell>
                <Table.Cell>{payload.connects_error_count}</Table.Cell>
              </Table.Row>
            )}
            {!!payload.landlines_error_count && (
              <Table.Row>
                <Table.Cell>Excluded landlines</Table.Cell>
                <Table.Cell>{payload.landlines_error_count}</Table.Cell>
              </Table.Row>
            )}
            {!!payload.insert_failed_error_count && (
              <Table.Row>
                <Table.Cell>Rows that failed to insert</Table.Cell>
                <Table.Cell>{payload.insert_failed_error_count}</Table.Cell>
              </Table.Row>
            )}

            <Table.Row>
              <Table.Cell>Total Errors</Table.Cell>
              <Table.Cell>{payload.error_count ?? 0}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  );
};

export default DatasetsJobDetailModal;
