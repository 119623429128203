import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Icon, Loader } from 'semantic-ui-react';

import {
  GetLeadSummaryFilters,
  LeadSummary,
  OrderDirection,
  ReportingLeadSummaryColumn,
  ReportingLeadSummaryColumns,
  useGetLeadSummaryQuery,
} from 'src/api/reporting';
import Breadcrumbs from 'src/components/Breadcrumbs';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { Container, Header, Row } from 'src/styles';

const betaUrl = process.env.REACT_APP_BETA_URL;

const getFiltersFromQuery = (query: URLSearchParams): GetLeadSummaryFilters => {
  // const search = query.get('search') || undefined;

  const column = query.get('column') as ReportingLeadSummaryColumn;

  let direction: OrderDirection = 'ascending';
  if (query.get('direction') === 'descending') {
    direction = 'descending';
  }

  return {
    order_col: column || 'phone',
    order_dir: direction,
    master_lead_status: query.get('master_lead_status') || undefined,
    dataset_ids: query.getAll('dataset_ids[]') || undefined,
    doc_date_range: query.get('doc_date_range') || undefined,
    call_date_range: query.get('call_date_range') || undefined,
  };
};

const ReportingLeadSummary = () => {
  const query = useSearchQuery();
  const [filters, setFilters] = useState<GetLeadSummaryFilters>(() => getFiltersFromQuery(query));
  const { data: leadSummary, isLoading } = useGetLeadSummaryQuery({
    limit: 1000,
    offset: 0,
    ...filters,
  });

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(prev => !prev);
  };

  useEffect(() => {
    setFilters(getFiltersFromQuery(query));
  }, [query]);

  const [sorting, setSorting] = useState<SortingState>([]);

  const columns = useMemo<ColumnDef<LeadSummary>[]>(() => {
    const cols: ColumnDef<LeadSummary>[] = [];
    ReportingLeadSummaryColumns.forEach(c => {
      cols.push({
        id: c,
        header: c,
        accessorFn: (row: LeadSummary) => row[c]?.toLocaleString() || '',
        cell: ({ getValue }) => getValue(),
        sortingFn: 'alphanumeric',
      });
    });
    return cols;
  }, []);

  const tableInstance = useReactTable({
    columns,
    data: leadSummary?.data || [],
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel<LeadSummary>(),
  });

  const getCommonPinningStyles = (column: Column<LeadSummary>, isHeader: boolean): CSSProperties => {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
    const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

    return {
      boxShadow: isLastLeftPinnedColumn
        ? '-4px 0 4px -4px gray inset'
        : isFirstRightPinnedColumn
        ? '4px 0 4px -4px gray inset'
        : isHeader
        ? '0 -4px 4px -4px gray inset'
        : undefined,
      left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
      right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
      opacity: isPinned ? 0.95 : 1,
      position: isPinned || isHeader ? 'sticky' : 'relative',
      width: column.getSize(),
      zIndex: isHeader && isPinned ? 100 : isHeader ? 10 : isPinned ? 1 : 0,
      backgroundColor: 'white',
      top: -1,
    };
  };

  return (
    <Container>
      <Helmet>
        <title>Lead Summary - Reporting | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
        <Header style={{ marginBottom: 0 }}>
          <Breadcrumbs crumbs={['Reporting', 'Lead Summary']} />
        </Header>
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <Button onClick={toggleDropdown}>
            <Icon name="filter" />
            Columns
          </Button>
          {isOpen && (
            <div
              style={{
                position: 'absolute',
                width: 'max-content',
                zIndex: 1000,
                backgroundColor: 'white',
                border: '1px solid rgba(0, 0, 0, 0.15)',
                borderRadius: '5px',
                padding: '10px',
                top: '100%',
                left: 0,
              }}
            >
              <div className="px-1 border-b border-black">
                <label>
                  <input
                    {...{
                      type: 'checkbox',
                      checked: tableInstance.getIsAllColumnsVisible(),
                      onChange: tableInstance.getToggleAllColumnsVisibilityHandler(),
                    }}
                  />{' '}
                  Toggle All
                </label>
                <br />
                {tableInstance.getAllLeafColumns().map(column => {
                  return (
                    <div key={column.id} className="px-1">
                      <label>
                        <input
                          {...{
                            type: 'checkbox',
                            checked: column.getIsVisible(),
                            onChange: column.getToggleVisibilityHandler(),
                          }}
                        />{' '}
                        {column.id}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <Button as="a" href={`${betaUrl}/api/reporting/lead-summary/download?${query.toString()}`}>
            <Icon name="download" />
            Download CSV
          </Button>
        </div>
      </Row>

      <div className="table-container-fit">
        <table className="tanstack-table">
          <thead>
            {tableInstance.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  const { column } = header;
                  return (
                    <th key={header.id} colSpan={header.colSpan} style={{ ...getCommonPinningStyles(column, true) }}>
                      {header.isPlaceholder ? null : (
                        <div
                          className={header.column.getCanSort() ? 'pointer' : ''}
                          onClick={header.column.getToggleSortingHandler()}
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === 'asc'
                                ? 'Sort ascending'
                                : header.column.getNextSortingOrder() === 'desc'
                                ? 'Sort descending'
                                : 'Clear sort'
                              : undefined
                          }
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                      {!header.isPlaceholder && header.column.getCanPin() && (
                        <div className="flex gap-1 justify-center">
                          {header.column.getIsPinned() !== 'left' ? (
                            <Button
                              onClick={() => {
                                header.column.pin('left');
                              }}
                              style={{ padding: '0', minWidth: 'auto', height: 'auto', backgroundColor: 'transparent' }}
                            >
                              <Icon name="arrow left"></Icon>
                            </Button>
                          ) : null}
                          {header.column.getIsPinned() ? (
                            <Button
                              onClick={() => {
                                header.column.pin(false);
                              }}
                              style={{ padding: '0', minWidth: 'auto', height: 'auto', backgroundColor: 'transparent' }}
                            >
                              <Icon name="undo"></Icon>
                            </Button>
                          ) : null}
                          {header.column.getIsPinned() !== 'right' ? (
                            <Button
                              onClick={() => {
                                header.column.pin('right');
                              }}
                              style={{ padding: '0', minWidth: 'auto', height: 'auto', backgroundColor: 'transparent' }}
                            >
                              <Icon name="arrow right"></Icon>
                            </Button>
                          ) : null}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {isLoading ? (
            <tbody>
              <tr>
                <td colSpan={35}>
                  <Loader active inline />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {tableInstance.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    const { column } = cell;
                    return (
                      <td key={cell.id} style={{ ...getCommonPinningStyles(column, false) }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </Container>
  );
};

export default ReportingLeadSummary;
