import { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Divider, Grid, Header, Icon, Loader, Message, Segment, Table } from 'semantic-ui-react';

import { useListAccountApiTokensQuery } from 'src/api/accounts';
import { useFixAccountSendGridWebhookUrlMutation, useGetSendGridProfileQuery } from 'src/api/auth/account-sendgrid';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import Loading from 'src/components/Loading';
import { Container, Header as PageHeader } from 'src/styles';
import theme from 'src/styles/theme';
import AddSendgridInboundParseSetting from './AddSendgridInboundParseSetting';
import SendGridCredentialsForm from './SendGridCredentialsForm';
import SendGridInboundParseSettings from './SendGridInboundParseSettings';

const betaUrl = process.env.REACT_APP_BETA_URL;

const AccountSendGrid = () => {
  const { data: apiTokens, isLoading: apiTokensLoading } = useListAccountApiTokensQuery({ limit: 1, offset: 0 });
  const {
    data: sgProfile,
    isFetching: profileFetching,
    isLoading: profileLoading,
    error: profileError,
  } = useGetSendGridProfileQuery();
  const { mutateAsync: fixWebhookUrl, isLoading: fixLoading } = useFixAccountSendGridWebhookUrlMutation();

  const onFixWebhookUrl = useCallback(async () => {
    try {
      await fixWebhookUrl();
    } catch (e: any) {
      // handle the error
    }
  }, [fixWebhookUrl]);

  const apiMessage = useMemo<ApiMessageData | undefined>(() => {
    if (profileFetching || profileLoading || sgProfile?.hasValidApiKey === true) return;

    if (profileError) return apiErrorHandler(profileError);

    if (sgProfile?.apiKey && sgProfile?.hasValidApiKey === false) {
      return { success: false, status: 401, message: 'Unauthorized - Invalid API Key' };
    }

    return;
  }, [profileError, profileFetching, profileLoading, sgProfile?.apiKey, sgProfile?.hasValidApiKey]);

  const apiToken = useMemo<string | null>(() => {
    if (apiTokensLoading) return null;
    if (!apiTokens || !apiTokens.data || apiTokens.data.length === 0) return null;
    return apiTokens?.data[0].token;
  }, [apiTokens, apiTokensLoading]);

  const desiredUrl = useMemo(() => {
    if (apiToken === null) return null;
    return `${betaUrl}/api/sg/webhook-events?token=${apiToken}`;
  }, [apiToken]);

  if (apiTokensLoading || (!sgProfile && !apiMessage)) return <Loading />;

  const isWebhookUrlCorrect = sgProfile?.webhookEventsSetting?.url === desiredUrl;

  return (
    <Container>
      <Helmet>
        <title>SendGrid - Account | datascore</title>
      </Helmet>

      <PageHeader>
        <span style={{ color: theme.gray }}>Account</span> <span style={{ padding: '0 0.5rem' }}>/</span> SendGrid
      </PageHeader>

      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header>Auth Credentials</Header>
            </Grid.Column>
            <Grid.Column width={4}>
              <SendGridCredentialsForm profile={sgProfile} />
            </Grid.Column>
            <Grid.Column width={4}>
              {(profileFetching || profileLoading) && <Loader active />}
              {apiMessage && <ApiMessage data={apiMessage} />}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {!profileLoading && !apiMessage && sgProfile?.hasValidApiKey && (
          <>
            <Divider />

            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Header>Webhook Events</Header>
                </Grid.Column>
                <Grid.Column width={12}>
                  {/* <Button color={profile.webhookEventsSetting?.enabled ? `green` : undefined} size="small" compact>
                    {profile.webhookEventsSetting?.enabled ? 'Enabled' : 'Disabled'}
                  </Button> */}

                  {!isWebhookUrlCorrect && (
                    <Message error>
                      <Button type="button" floated="right" color="red" loading={fixLoading} onClick={onFixWebhookUrl}>
                        Fix it for me!
                      </Button>

                      <Message.Header>Webhook URL Does Not Match</Message.Header>
                      <Message.Content>
                        Please copy the <strong>Desired URL</strong> below and update the <strong>HTTP Post URL</strong>{' '}
                        field found inside the <strong>Event Webhook</strong> section of your{' '}
                        <a href="https://app.sendgrid.com/settings/mail_settings" target="_blank" rel="noreferrer">
                          Mail Settings
                        </a>{' '}
                        in your SendGrid account... or... you can simply click the button to the right!
                      </Message.Content>
                    </Message>
                  )}

                  <Table definition>
                    <Table.Row>
                      <Table.Cell collapsing>Current URL</Table.Cell>
                      <Table.Cell>{sgProfile.webhookEventsSetting?.url}</Table.Cell>
                      <Table.Cell collapsing></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>Desired URL</Table.Cell>
                      <Table.Cell>{desiredUrl}</Table.Cell>
                      <Table.Cell collapsing>
                        <Icon
                          color={isWebhookUrlCorrect ? 'green' : 'red'}
                          name={isWebhookUrlCorrect ? 'check' : 'close'}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Divider />

            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Header>Inbound Parse</Header>
                  <AddSendgridInboundParseSetting />
                </Grid.Column>
                <Grid.Column width={12}>
                  <SendGridInboundParseSettings settings={sgProfile.inboundParseSettings} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        )}
      </Segment>
    </Container>
  );
};

export default AccountSendGrid;
