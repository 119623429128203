import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Icon, Message } from 'semantic-ui-react';

import { useGetDatasetQuery } from 'src/api/datasets';
import JobHistory from 'src/components/JobHistory';
import Loading from 'src/components/Loading';
import { Container, Header as PageHeader, Row } from 'src/styles';
import theme from 'src/styles/theme';
import { JobType } from 'src/types';
import DatasetIngestJobDetailsModal from './DatasetIngestJobDetailsModal';
import DatasetV2Lists from './DatasetV2Lists';
import DatasetV2Settings from './DatasetV2Settings';
import DatasetV2Stats from './DatasetV2Stats';

const EditDataset = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const { data: dataset, isLoading: tableLoading } = useGetDatasetQuery(params.id);
  // const { data: apiTokens, isLoading: tokensLoading } = useListAccountApiTokensQuery({ limit: 1, offset: 0 });

  if (tableLoading) return <Loading />;

  if (!dataset) {
    return (
      <Container>
        <Helmet>
          <title>Dataset Not Found | datascore</title>
        </Helmet>

        <Message error>
          <Message.Header>🚨 Error, Not Found.</Message.Header>
          <Message.Content>Unable to locate datset for id: {decodeURIComponent(params.id)}</Message.Content>
        </Message>
      </Container>
    );
  }

  return (
    <Container>
      <Helmet>
        <title>Edit Dataset | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Row style={{ alignItems: 'center' }}>
          <Button icon color="blue" onClick={history.goBack} style={{ marginRight: '1rem' }}>
            <Icon name="arrow left" />
          </Button>

          <PageHeader style={{ margin: 0 }}>
            <span style={{ color: theme.gray }}>Datasets v2</span> <span style={{ padding: '0 0.5rem' }}>/</span>{' '}
            {dataset.name}
          </PageHeader>
        </Row>
      </Row>

      <DatasetV2Settings dataset={dataset} />

      <DatasetV2Lists dataset={dataset} />

      <JobHistory details={DatasetIngestJobDetailsModal} jobType={JobType.DatasetsV2Upload} />

      <DatasetV2Stats dataset={dataset} />
    </Container>
  );
};

export default EditDataset;
