import { useCallback, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Button, Form, Modal } from 'semantic-ui-react';

import { useTwilioCallQueueCancelAllMutation } from 'src/api/auth/account-twilio';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';

const CancelCallQueueModal = () => {
  const modalRef = useRef(null);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync, isLoading } = useTwilioCallQueueCancelAllMutation();
  const queryClient = useQueryClient();

  const onCancel = useCallback(() => {
    if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
      // @ts-ignore
      modalRef.current.handleClose();
    }
  }, []);

  const onClose = useCallback(() => {
    setApiMessage(undefined);
    queryClient.invalidateQueries(['auth/account', 'twilio/call-queue/current-job']);
  }, [queryClient]);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    try {
      await mutateAsync();
      setApiMessage({ success: true, message: `Background job started.`, status: 200 });
      setTimeout(() => {
        setApiMessage(undefined);
        onCancel();
      }, 3000);
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [mutateAsync, onCancel]);

  return (
    <Modal
      size="mini"
      ref={modalRef}
      trigger={
        <Button type="button" color="red">
          Cancel Call Queue
        </Button>
      }
      onClose={onClose}
    >
      <Modal.Header>Cancel Call Queue?</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        {!apiMessage?.success ? (
          <Form onSubmit={onSubmit}>
            <p style={{ marginTop: 0 }}>
              <strong>Are you sure?</strong> This action cannot be undone.
            </p>

            <Row>
              <Button fluid type="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button color="red" fluid loading={isLoading}>
                Confirm
              </Button>
            </Row>
          </Form>
        ) : (
          <Button color="blue" fluid onClick={onCancel}>
            Done
          </Button>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default CancelCallQueueModal;
