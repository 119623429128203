import { memo } from 'react';
import { Position } from 'react-flow-renderer';
import { Icon, Popup } from 'semantic-ui-react';

import {
  NodeContainer,
  NodeHandle,
  NodeHandleContainer,
  NodeHeader,
  NodeHeaderTitle,
  NodeHeaderTitleContainer,
} from '../../components/NodeOverlay/styles';
import { NodeContainerProps } from '../../components/NodeOverlay/types';

const FilterNode = ({ data, selected }: NodeContainerProps) => {
  return (
    <NodeContainer selected={selected}>
      <NodeHandleContainer position={Position.Top}>
        <NodeHandle type="target" position={Position.Top} />
      </NodeHandleContainer>

      <NodeHeader>
        <NodeHeaderTitleContainer>
          <Icon name="filter" color="violet" />
          <NodeHeaderTitle>{data?.name || 'Filter'}</NodeHeaderTitle>
        </NodeHeaderTitleContainer>

        <Popup
          content="Filter a data source."
          trigger={<Icon name="info circle" color="grey" style={{ position: 'relative', zIndex: '99' }} />}
        />
      </NodeHeader>

      <NodeHandleContainer position={Position.Bottom}>
        <NodeHandle type="source" position={Position.Bottom} />
      </NodeHandleContainer>
    </NodeContainer>
  );
};

export default memo(FilterNode);
