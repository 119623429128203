import { cloneDeep, isEmpty, set } from 'lodash';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { Button, Divider, Form, Grid, Header, Icon, InputOnChangeData } from 'semantic-ui-react';
import styled from 'styled-components';

import { useUpdateAkkioModelMutation } from 'src/api/admin/leadscore-plus';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import BodyTemplateEditor from 'src/components/BodyTemplateEditor';
import {
  BrooksCimaCertPropertyOptions,
  BrooksPhoneLookupPropertyOptions,
  DsCensusPropertyOptions,
  DsMetadataPropertyOptions,
} from 'src/data';
import { Note } from 'src/styles';
import { AkkioModel, BaseSchema, RequestMethods } from 'src/types';

const RequestTags: string[] = [
  ...BaseSchema.map(s => s.name),
  ...DsMetadataPropertyOptions.map(o => o.value as string),
  ...DsCensusPropertyOptions.map(o => o.value as string),
  ...BrooksCimaCertPropertyOptions.map(o => o.value as string),
  ...BrooksPhoneLookupPropertyOptions.map(o => o.value as string),
];

const GridLayout = styled.div`
  display: grid;
  grid-template-areas: 'general v2 request';
  grid-template-columns: 2fr auto 6fr;
  grid-template-rows: auto;
  gap: 1rem;

  @media screen and (max-width: 1200px) {
    grid-template-areas: 'general' 'v2' 'request';
    grid-template-columns: 1fr;
    grid-template-rows: auto;

    .computer-mt1 {
      margin-top: 1rem !important;
    }
  }
`;

type LayoutProps = {
  children?: ReactNode;
};

const Layout: FC<LayoutProps> = ({ children }) => {
  const [vertical, setVertical] = useState<boolean>(window ? window.innerWidth > 1200 : true);

  useEffect(() => {
    const onResize = () => {
      setVertical(window.innerWidth > 1200);
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <GridLayout>
      {children}

      <Divider className="full" vertical={vertical} style={{ gridArea: 'v2', position: 'relative' }} />

      {/* <Divider className="full" vertical style={{ gridArea: 'v3', position: 'relative' }} /> */}
    </GridLayout>
  );
};

type ValidationErrors = {
  // General
  name?: string;
  enabled?: string;
  customFields?: { [id: string]: string };
  dataEnrichment?: string;
  // Request
  url?: string;
  method?: string;
  timeout?: string;
  contentType?: string;
  bodyTemplate?: string;
};

type Props = {
  model: AkkioModel;
};

const getInitialFormdata = (model: AkkioModel): AkkioModel => {
  const next = cloneDeep(model);

  // Set any missing defaults and/or apply data type conversions
  // if (next.schedule && !next.schedule.timezone) {
  //   next.schedule.timezone = 'America/New_York';
  // }

  return next;
};

const validate = (m: AkkioModel): ValidationErrors => {
  const errors = {} as ValidationErrors;

  // General
  if (!m.name.trim()) {
    errors.name = 'Name is required';
  }

  // if (!m.config.request.url) {
  //   errors.url = 'URL is required';
  // } else
  if (m.config.request.url && !/http(s)?:\/\//.test(m.config.request.url)) {
    errors.url = 'URL must start with http:// or https://';
  }

  if (!m.config.request.method) {
    errors.method = 'Method is required';
  }

  // if (!m.config.request.contentType) {
  //   errors.contentType = 'Content-Type is required';
  // }

  return errors;
};

interface Pair {
  key: string;
  value: string;
}

type HTTPHeader = Pair;

const convertPairs = (h: { [key: string]: string }): Pair[] => {
  if (h == null) {
    return [];
  }

  const keys = Object.keys(h);
  return keys.map(k => {
    return { key: k, value: h[k] };
  });
};

const AdminAkkioModelGeneral = ({ model }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [formdata, setFormdata] = useState<AkkioModel>(() => getInitialFormdata(model));
  const { isLoading: saveLoading, mutateAsync } = useUpdateAkkioModelMutation();
  const [errors, setErrors] = useState<ValidationErrors>(validate(model));
  const [saved, setSaved] = useState(true);
  const [isValid, setIsValid] = useState<boolean>(isEmpty(validate(model)));
  const [headers, setHeaders] = useState<HTTPHeader[]>(convertPairs(model.config.request.headers));

  // TODO: This does not work when using back/forward browser buttons
  // At least in Chrome, it only prevents page close and reload
  useEffect(() => {
    const preventNavigation = (e: any) => {
      e.preventDefault();
      e.returnValue = '';
      return '';
    };

    if (!saved) {
      window.addEventListener('beforeunload', preventNavigation);
      window.addEventListener('popstate', preventNavigation);
    }

    return () => {
      window.removeEventListener('beforeunload', preventNavigation);
      window.removeEventListener('popstate', preventNavigation);
    };
  }, [saved]);

  const onChange = useCallback((_: any, { checked, name, value }: any) => {
    setFormdata(prev => {
      // Convert numeric values to numbers
      let v = typeof checked !== 'undefined' ? checked : value;
      if (['config.request.timeout'].includes(name)) {
        v = Number(v);
        if (Number.isNaN(v)) {
          v = 0;
        }
      }

      const next = cloneDeep(prev);
      set(next, name, v);

      const errors = validate(next);
      setErrors(errors);
      setIsValid(isEmpty(errors));

      return next;
    });

    setSaved(false);
  }, []);

  const onChangeHeader = useCallback((_: any, { name, value }: InputOnChangeData) => {
    setHeaders(prev => {
      const next = cloneDeep(prev);
      set(next, name, value);
      return next;
    });

    setSaved(false);
  }, []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    const validationErrors = validate(formdata);
    setErrors(validationErrors);
    setIsValid(isEmpty(validationErrors));

    const newHeaders: { [key: string]: string } = {};
    for (const header of headers) {
      if (header.key !== '') {
        newHeaders[header.key as keyof typeof newHeaders] = header.value;
      }
    }
    formdata.config.request.headers = newHeaders;

    try {
      await mutateAsync(formdata);
      setSaved(true);
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [formdata, headers, mutateAsync]);

  return (
    <Form style={{ position: 'relative' }} onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      <div
        style={{ position: 'absolute', top: '0', right: '0', zIndex: 100, display: 'flex', justifyContent: 'flex-end' }}
      >
        {isValid ? (
          <Button size="mini" compact color="green" style={{ marginLeft: '0.5rem' }} type="button">
            <Icon name="check" />
            Valid
          </Button>
        ) : (
          <Button size="mini" compact color="red" style={{ marginLeft: '0.5rem' }} type="button">
            <Icon name="dont" />
            Invalid
          </Button>
        )}

        <Button size="mini" compact color={saveLoading ? 'blue' : saved ? 'green' : 'red'} style={{ margin: 0 }}>
          {saveLoading ? <Icon name="spinner" loading /> : <Icon name={saved ? 'check' : 'dont'} />}
          {saveLoading ? 'Saving...' : saved ? 'Saved' : 'Unsaved'}
        </Button>
      </div>

      <Layout>
        <div style={{ gridArea: 'general' }}>
          <Header>General</Header>

          <Form.Input label="Name" name="name" value={formdata.name} onChange={onChange} />

          <Form.Checkbox
            label="Enabled"
            name="enabled"
            toggle
            checked={formdata.enabled}
            onChange={onChange}
            error={errors.enabled}
          />

          <Divider />

          <Header>Enrichment</Header>

          <Form.Checkbox
            label="Requires Census"
            name="config.requiresCensus"
            toggle
            checked={formdata.config.requiresCensus}
            onChange={onChange}
          />

          <Form.Checkbox
            label="Requires Phone Lookup"
            name="config.requiresPhoneLookup"
            toggle
            checked={formdata.config.requiresPhoneLookup}
            onChange={onChange}
          />

          <Form.Checkbox
            label="Requires CIMA Cert"
            name="config.requiresCimaCert"
            toggle
            checked={formdata.config.requiresCimaCert}
            onChange={onChange}
          />

          <Divider />

          <Header>Response</Header>
          <Note>
            With these settings together, Akkio should be appending fields to the response like:{' '}
            <code>Probability [Outcome] is [Positive]</code> and <code>Probability [Outcome] is [Negative]</code>.
          </Note>

          <Form.Input
            label="Outcome Field Name"
            name="config.response.outcomeFieldname"
            placeholder="Outcome"
            value={formdata.config.response.outcomeFieldname}
            onChange={onChange}
          />
          <Note>
            The name of the key in the json response of the model that contains the positive or negative value for the
            outcome of the prediction.
          </Note>

          <Form.Input
            label="Positive Value"
            name="config.response.positiveValue"
            placeholder="Positive"
            value={formdata.config.response.positiveValue}
            onChange={onChange}
          />
          <Note>The value in the outcome field that indicates the prediction was positive.</Note>

          <Form.Input
            label="Negative Value"
            name="config.response.negativeValue"
            placeholder="Negative"
            value={formdata.config.response.negativeValue}
            onChange={onChange}
          />
          <Note>The value in the outcome field that indicates the prediction was negative.</Note>
        </div>

        <div style={{ gridArea: 'request' }}>
          <Header>Request</Header>

          <Grid>
            <Grid.Row>
              <Grid.Column computer={16} largeScreen={7} widescreen={7}>
                <Form.Input
                  placeholder="https://api.akkio.com/v1/models"
                  label="URL"
                  value={formdata.config.request.url}
                  name="config.request.url"
                  onChange={onChange}
                  error={errors.url}
                />

                <Form.Group widths="equal">
                  <Form.Select
                    fluid
                    placeholder="Select a method"
                    label="Method"
                    value={formdata.config.request.method}
                    name="config.request.method"
                    onChange={onChange}
                    error={errors.method}
                    clearable
                    options={RequestMethods.map(m => ({ ...m, key: m.value }))}
                  />

                  <Form.Input
                    fluid
                    label="Timeout (seconds)"
                    value={formdata.config.request.timeout || ''}
                    placeholder="3"
                    type="number"
                    name="config.request.timeout"
                    onChange={onChange}
                    error={errors.timeout}
                  />
                </Form.Group>

                {/* <Form.Select
                  placeholder="Select a content-type"
                  label="Content-Type"
                  value={formdata.config.request.contentType}
                  name="config.request.contentType"
                  onChange={onChange}
                  error={errors.contentType}
                  clearable
                  options={RequestContentTypes.map(t => ({ ...t, key: t.value }))}
                /> */}

                <Form.Field>
                  <label>Headers</label>
                  {headers.map((h, idx) => (
                    <Form.Group key={idx} widths="equal">
                      <Form.Input
                        fluid
                        name={`[${idx}].key`}
                        placeholder="key"
                        value={h.key}
                        onChange={onChangeHeader}
                      />
                      <Form.Input
                        fluid
                        name={`[${idx}].value]`}
                        placeholder="value"
                        value={h.value}
                        onChange={onChangeHeader}
                      />
                      <Button
                        type="button"
                        color="red"
                        icon
                        onClick={() => {
                          setHeaders([...headers.slice(0, idx), ...headers.slice(idx + 1)]);
                          setSaved(false);
                        }}
                      >
                        <Icon name="trash" />
                      </Button>
                    </Form.Group>
                  ))}
                  <Button
                    type="button"
                    compact
                    size="mini"
                    color="blue"
                    onClick={() => {
                      setHeaders([...headers, { key: '', value: '' }]);
                    }}
                  >
                    <Icon name="plus" />
                    Add Header
                  </Button>
                </Form.Field>
              </Grid.Column>

              <Grid.Column className="computer-mt1" computer={16} largeScreen={9} widescreen={9}>
                <Form.Field>
                  <label>Body Template</label>
                  <BodyTemplateEditor
                    initialValue={formdata.config.request.bodyTemplate}
                    onChange={value => onChange(null, { name: 'config.request.bodyTemplate', value })}
                    tags={RequestTags}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Layout>
    </Form>
  );
};

export default AdminAkkioModelGeneral;
