import { useMemo } from 'react';
import { Button, DropdownItemProps, Form, Icon } from 'semantic-ui-react';

import { useListFiltersQuery } from 'src/api/filters';
import theme from 'src/styles/theme';
import { ComparisonOperatorOptions, GlobalRuleFilter, RuleFilter } from 'src/types';

type Props = {
  columns: DropdownItemProps[];
  customFilters: RuleFilter[];
  globalFilters: GlobalRuleFilter[];
  addFilter: () => void;
  removeFilter: (id: string) => void;
  updateFilter: (id: string, filter: RuleFilter) => void;
  addGlobalFilter: () => void;
  updateGlobalFilter: (id: string, filter: GlobalRuleFilter) => void;
  removeGlobalFilter: (id: string) => void;
};

const DatasetV2Filters = ({
  columns,
  customFilters,
  globalFilters,
  addFilter,
  removeFilter,
  updateFilter,
  addGlobalFilter,
  updateGlobalFilter,
  removeGlobalFilter,
}: Props) => {
  const { data: filters, isLoading } = useListFiltersQuery({ limit: 100, offset: 0 });

  const filterOptions = useMemo(() => {
    return (
      filters?.data.map(f => ({
        key: f.id,
        text: f.name,
        value: f.id,
      })) || []
    );
  }, [filters]);

  return (
    <>
      {customFilters.map(f => (
        <div key={f.id}>
          <Form.Group widths="equal">
            <Form.Select
              fluid
              placeholder="Column"
              clearable
              options={columns}
              value={f.field}
              onChange={(_, { value }) => updateFilter(f.id, { ...f, field: value as string })}
            />

            <Form.Select
              fluid
              placeholder="Operator"
              clearable
              options={ComparisonOperatorOptions.filter(
                o => !['null', 'not_null', 'time_after', 'time_before'].includes(o.value)
              )}
              value={f.operator}
              onChange={(_, { value }) => updateFilter(f.id, { ...f, operator: value as string })}
            />

            {f.operator !== 'null' && (
              <Form.Input
                fluid
                placeholder="Value(s)"
                value={f.value || ''}
                onChange={(_, { value }) => updateFilter(f.id, { ...f, value })}
              />
            )}

            <Button type="button" color="red" icon onClick={() => removeFilter(f.id)}>
              <Icon name="trash" />
            </Button>
          </Form.Group>

          {(f.operator === 'in' || f.operator === 'not_in') && (
            <p style={{ color: theme.gray, marginTop: '-0.75rem' }}>Separate multiple values with a comma.</p>
          )}
        </div>
      ))}

      {globalFilters.map(f => (
        <Form.Group widths="equal" key={f.id}>
          <Form.Dropdown
            placeholder="Select a filter"
            fluid
            selection
            options={filterOptions}
            loading={isLoading}
            onChange={(_, { value }) => {
              const selectedOption = filterOptions.find(option => option.value === value);
              if (selectedOption) {
                updateGlobalFilter(f.id, {
                  ...f,
                  filter_id: selectedOption.value,
                });
              }
            }}
            value={f.filter_id}
          />
          <Button type="button" color="red" icon onClick={() => removeGlobalFilter(f.id)}>
            <Icon name="trash" />
          </Button>
        </Form.Group>
      ))}

      <Button type="button" compact size="mini" color="blue" style={{ marginBottom: '1rem' }} onClick={addFilter}>
        <Icon name="plus" /> Add Custom Filter
      </Button>

      <Button type="button" compact size="mini" color="blue" style={{ marginBottom: '1rem' }} onClick={addGlobalFilter}>
        <Icon name="plus" /> Add Global Filter
      </Button>
    </>
  );
};

export default DatasetV2Filters;
