import { useEffect } from 'react';
import { Table } from 'semantic-ui-react';

import { useListConvosoProfilesQuery } from 'src/api/convoso';
import { RenderProps } from 'src/components/PaginatedTable';
import { Row } from 'src/styles';
import DeleteProfile from './DeleteProfile';
// import EditProfile from './EditProfile';

const ListConvosoProfilesBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: profiles } = useListConvosoProfilesQuery({ limit, offset: limit * (currentPage - 1) });

  useEffect(() => {
    let count = 1;
    if (typeof profiles !== 'undefined') {
      count = Math.ceil(profiles.total / limit);
    }
    setPageCount(count);
  }, [profiles, limit, setPageCount]);

  return profiles?.data.map(p => (
    <Table.Row key={p.id}>
      <Table.Cell>{p.name}</Table.Cell>
      <Table.Cell>
        {p.auth_token
          .split('')
          .map(_ => '•')
          .join('')}
      </Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        <Row>
          {/* <EditProfile onClose={reloadProfiles} profile={p} /> */}
          <DeleteProfile id={p.id} />
        </Row>
      </Table.Cell>
    </Table.Row>
  ));
};

export default ListConvosoProfilesBody;
