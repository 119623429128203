import { isEmpty } from 'lodash';
import diff from 'lodash/difference';
import { parse } from 'papaparse';
import { ChangeEvent, KeyboardEvent, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, DropdownItemProps, DropdownProps, Form, Label, Message, Table } from 'semantic-ui-react';
import stringSimilarity from 'string-similarity';

import {
  DatasetUploadFormdata,
  DatasetUploadResponse,
  useDatasetListCreateMutation,
  useDatasetUploadMutation,
  useGetDatasetQuery,
  useListDatasetListsQuery,
} from 'src/api/datasets';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { DelimiterOptions, FileDelimiters } from 'src/config';
import { Row } from 'src/styles';
import { BaseSchema, Delimiter } from 'src/types';

type ValidationErrors = {
  file?: string;
  field_mappings?: string;
  listId?: string;
};

// For a better string matching, add an alias for fields
const aliasMapping: { [key: string]: string[] } = {
  phone: ['phone', 'phone_number', 'phone1', 'primary_phone'],
};

const getInitialFormdata = (): DatasetUploadFormdata => ({
  file: undefined,
  delimiter: 'Comma',
  fieldMappings: [],
  hasHeader: true,
  listId: '',
});

type Props = {
  datasetId: string;
  onCancel: () => void;
  onSuccess: (res: DatasetUploadResponse) => void;
};

const UploadToDatasetV2Form = ({ datasetId, onCancel, onSuccess }: Props) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<DatasetUploadFormdata>(() => getInitialFormdata());
  const [filePreview, setFilePreview] = useState<string[][] | null>(null);
  const [rowCounts, setRowCounts] = useState<{ total: number; qualified: number }>();
  const { data: dataset, isLoading: datasetLoading } = useGetDatasetQuery(datasetId);
  const { mutateAsync: upload, isLoading: uploadLoading } = useDatasetUploadMutation();
  const { data: lists, isLoading: listsLoading } = useListDatasetListsQuery({
    datasetId,
    limit: 1000,
    offset: 0,
  });
  const { mutateAsync: createList, isLoading: createListLoading } = useDatasetListCreateMutation();
  const [showCreateList, setShowCreateList] = useState<boolean>(false);
  const [newListName, setNewListName] = useState<string>('');

  useEffect(() => {
    if (formdata.listId) return;
    if (!lists || !lists.data.length) return;
    setFormdata(prev => ({ ...prev, listId: lists.data[0].id }));
  }, [formdata.listId, lists]);

  const requiredFields = useMemo(() => {
    if (datasetLoading) return;
    if (!dataset || !dataset.rules.required_fields || dataset.rules.required_fields.length === 0) return;

    return dataset.rules.required_fields;
  }, [dataset, datasetLoading]);

  const datasetFields = useMemo(() => {
    if (datasetLoading) return [];
    if (!dataset || !dataset.custom_fields || dataset.custom_fields.length === 0) return [];

    return dataset.custom_fields.map(f => f.name);
  }, [dataset, datasetLoading]);

  const fieldMappingOptions = useMemo(
    () => [
      { key: '__skip__', text: 'Do Not Import', value: '__skip__' },
      { key: '__keep__', text: 'Keep As-Is', value: '__keep__' },
      ...BaseSchema.map(f => ({ key: f.name, text: f.name, value: f.name })),
      ...datasetFields.map(f => ({ key: f, text: f, value: f })),
    ],
    [datasetFields]
  );

  const listOptions: DropdownItemProps[] =
    lists?.data.map(list => {
      return {
        key: list.id,
        value: list.id,
        text: list.name,
      };
    }) || [];

  const validate = useCallback(
    (input: DatasetUploadFormdata): ValidationErrors => {
      const validationErrors: ValidationErrors = {};

      if (typeof input.file === 'undefined') {
        validationErrors.file = 'file is required';
      } else if (['text/csv', 'text/plain'].indexOf(input.file.type) === -1) {
        validationErrors.file = 'Invalid file type, please select a CSV';
      }

      const missingMappings: string[] = diff(requiredFields, input.fieldMappings);
      if (typeof validationErrors.file === 'undefined' && missingMappings.length > 0) {
        validationErrors.field_mappings = missingMappings.join(', ');
      }

      if (!input.listId) {
        validationErrors.listId = 'No list selected.';
      }

      setErrors(validationErrors);

      return validationErrors;
    },
    [requiredFields]
  );

  useEffect(() => {
    if (typeof formdata.file === 'undefined') {
      setFilePreview(null);
      return;
    }
    if (typeof errors.file !== 'undefined') {
      setFilePreview(null);
      return;
    }

    const d = FileDelimiters.has(formdata.delimiter) ? FileDelimiters.get(formdata.delimiter) : ',';

    const parsedRows: string[][] = [];
    parse<string[]>(formdata.file, {
      delimiter: d,
      step: ({ data }, parser) => {
        parsedRows.push(data);
        if (parsedRows.length >= 5) {
          parser.abort();
          return;
        }
      },
      complete: () => {
        setFilePreview(parsedRows);

        setFormdata(prev => {
          const fieldMappingValues = fieldMappingOptions.map(fm => fm.value);

          const headers = parsedRows[0];

          const fm = Array(headers.length).fill('__skip__');

          for (const f of fieldMappingValues) {
            if (f === '__skip__' || f === '__keep__') continue;

            let bestScore = 0;
            let bestIdx = 0;
            headers.forEach((h, i) => {
              if (fm[i] !== '__skip__' && fm[i] !== '__keep__') return;

              const fields = aliasMapping[f.toLowerCase()] || [f.toLowerCase()];

              for (const field of fields) {
                const ratio = stringSimilarity.compareTwoStrings(h.toLowerCase(), field);

                if (ratio >= bestScore) {
                  bestScore = ratio;
                  bestIdx = i;
                }
              }
            });

            if (bestScore >= 0.5) {
              fm[bestIdx] = f;
            }
          }

          const next = {
            ...prev,
            fieldMappings: fm,
          };
          validate(next);
          return next;
        });
      },
    });
  }, [errors.file, fieldMappingOptions, formdata.delimiter, formdata.file, validate]);

  useEffect(() => {
    if (typeof formdata.file === 'undefined') {
      setRowCounts(undefined);
      return;
    }
    if (typeof errors.file !== 'undefined') {
      setRowCounts(undefined);
      return;
    }

    // const idx = {
    //   phone: formdata.fieldMappings.indexOf('phone'),
    // };

    const d = FileDelimiters.has(formdata.delimiter) ? FileDelimiters.get(formdata.delimiter) : ',';

    let total = formdata.hasHeader ? -1 : 0;
    let qualified = formdata.hasHeader ? -1 : 0;
    parse<string[]>(formdata.file, {
      delimiter: d,
      step: ({ data }) => {
        total++;

        // if (idx.phone === -1) return;
        // if (!data[idx.phone]) return;

        // const phone = data[idx.phone].replace(/\D/g, '').replace(/^1/, '');
        // if (phone.length !== 10) return;

        qualified++;
      },
      complete: () => {
        setRowCounts({ total, qualified });
      },
    });
  }, [errors.file, formdata.delimiter, formdata.file, formdata.fieldMappings, formdata.hasHeader]);

  const onChangeFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFormdata(prev => {
        let file;
        if (event.target.files && event.target.files.length) {
          file = event.target.files[0];
        }

        const next = { ...prev, file };
        validate(next);
        return next;
      });
    },
    [validate]
  );

  const onChangeDelimiter = useCallback((_event: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
    setFormdata(prev => ({ ...prev, delimiter: value as Delimiter }));
  }, []);

  const onChangeList = useCallback(
    (_event: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
      setFormdata(prev => {
        const next = { ...prev, listId: value as string };
        validate(next);
        return next;
      });
    },
    [validate]
  );

  const onChangeFieldMapping = useCallback(
    (i: number) =>
      (_event: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        setFormdata(prev => {
          const newMappings = [...prev.fieldMappings];
          newMappings[i] = `${value}`;
          const next = { ...prev, fieldMappings: newMappings };
          validate(next);
          return next;
        });
      },
    [validate]
  );

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    if (!isEmpty(validate(formdata))) {
      return;
    }

    try {
      const res = await upload({ id: datasetId, ...formdata });
      onSuccess(res);
      setFormdata(getInitialFormdata());
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [validate, formdata, upload, datasetId, onSuccess]);

  const ignoreAllKept = useCallback(() => {
    setFormdata(prev => {
      const next = {
        ...prev,
        fieldMappings: prev.fieldMappings.map(fm => {
          if (fm !== '__keep__') return fm;
          return '__skip__';
        }),
      };
      validate(next);
      return next;
    });
  }, [validate]);

  const keepAllIgnored = useCallback(() => {
    setFormdata(prev => {
      const next = {
        ...prev,
        fieldMappings: prev.fieldMappings.map(fm => {
          if (fm !== '__skip__') return fm;
          return '__keep__';
        }),
      };
      validate(next);
      return next;
    });
  }, [validate]);

  const createNewList = useCallback(async () => {
    if (!newListName) return;
    const resp = await createList({ datasetId, name: newListName });
    const listId = resp.list?.id;
    if (listId) {
      setFormdata(prev => {
        const next = { ...prev, listId };
        validate(next);
        return next;
      });
    }
    setNewListName('');
    setShowCreateList(false);
  }, [createList, datasetId, newListName, validate]);

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group>
        <Form.Select
          label="List"
          options={listOptions}
          name="list"
          placeholder={listOptions.length === 0 ? 'No lists found' : 'Select a list'}
          onChange={onChangeList}
          error={errors.listId}
          loading={listsLoading || createListLoading}
          value={formdata.listId}
        />
        {!showCreateList ? (
          <Form.Button type="button" style={{ marginTop: 22 }} onClick={() => setShowCreateList(true)}>
            Create List
          </Form.Button>
        ) : (
          <>
            <Form.Input
              placeholder="List name"
              value={newListName}
              onChange={(_, { value }) => setNewListName(value)}
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  createNewList();
                }
              }}
              disabled={createListLoading}
              loading={createListLoading}
              style={{ marginTop: 22 }}
            />
            <Form.Button type="button" onClick={createNewList} loading={createListLoading} style={{ marginTop: 22 }}>
              Save
            </Form.Button>
          </>
        )}
      </Form.Group>

      {typeof rowCounts !== 'undefined' && (
        <Message success visible>
          <Message.Header>File Details</Message.Header>
          <Message.Content>
            Your file contains <strong>{rowCounts.qualified}</strong> qualified records out of{' '}
            <strong>{rowCounts.total}</strong> total rows.
          </Message.Content>
        </Message>
      )}

      {errors.field_mappings && (
        <Message error visible>
          <Message.Header>🚨 Missing required field mappings:</Message.Header>
          <Message.Content>{errors.field_mappings}</Message.Content>
        </Message>
      )}

      <Form.Group widths="equal">
        <Form.Field error={errors.file}>
          <label>File</label>
          <input type="file" name="file" onChange={onChangeFile} ref={fileInputRef} />
          {errors.file && (
            <Label pointing prompt>
              {errors.file}
            </Label>
          )}
        </Form.Field>

        <Form.Select
          label="Delimiter"
          value={formdata.delimiter}
          options={DelimiterOptions}
          name="delimiter"
          onChange={onChangeDelimiter}
        />
      </Form.Group>

      {filePreview && (
        <>
          <Form.Checkbox
            label="First line contains a header?"
            checked={formdata.hasHeader}
            onChange={(_, { checked }) => setFormdata(prev => ({ ...prev, hasHeader: Boolean(checked) }))}
          />

          <div style={{ marginBottom: '1rem' }}>
            <Button type="button" content={`Ignore All "Kept As-Is"`} onClick={ignoreAllKept} />
            <Button type="button" content={`Keep All "Do Not Import"`} onClick={keepAllIgnored} />
          </div>

          <div style={{ overflowX: 'scroll', marginBottom: '1rem' }}>
            <Table>
              <Table.Header>
                <Table.Row>
                  {filePreview[0].map((_cell: string, i: number) => (
                    <Table.HeaderCell key={i}>
                      <Form.Select
                        upward={false}
                        options={fieldMappingOptions.filter(
                          o =>
                            o.value === '__skip__' ||
                            o.value === '__keep__' ||
                            o.value === formdata.fieldMappings[i] ||
                            formdata.fieldMappings.indexOf(o.value) === -1
                        )}
                        value={formdata.fieldMappings[i]}
                        onChange={onChangeFieldMapping(i)}
                        style={
                          formdata.fieldMappings[i] === '__skip__'
                            ? { background: 'transparent', color: 'rgba(0,0,0,0.25)' }
                            : formdata.fieldMappings[i] === '__keep__'
                            ? undefined
                            : {
                                borderColor: 'rgba(44, 102, 45, 0.5)',
                                background: '#fcfff5',
                                color: '#2c662d',
                              }
                        }
                      />
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
                {formdata.hasHeader && (
                  <Table.Row>
                    {filePreview[0].map((_cell: string, i: number) => (
                      <Table.HeaderCell key={i}>{_cell}</Table.HeaderCell>
                    ))}
                  </Table.Row>
                )}
              </Table.Header>
              <Table.Body>
                {filePreview
                  .filter((r: string[], i) => r && r.length === filePreview[0].length && (i > 0 || !formdata.hasHeader))
                  .map((row: string[], rowIdx: number) => (
                    <Table.Row key={rowIdx}>
                      {row.map((cell: string, cellIdx: number) => (
                        <Table.Cell key={`${rowIdx}-${cellIdx}`}>{cell}</Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </div>
        </>
      )}

      <ApiMessage data={apiMessage} />

      <Row>
        <Button fluid type="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="blue" fluid loading={uploadLoading}>
          Upload to Dataset
        </Button>
      </Row>
    </Form>
  );
};

export default UploadToDatasetV2Form;
