import { useQuery } from 'react-query';

import { PaginatedResponse, QualifaiDialogflowBot } from 'src/types';
import { ApiResponse, http } from './http-common';

export type QualifaiDialogflowBotsResponse = ApiResponse & {
  bots: PaginatedResponse<QualifaiDialogflowBot>;
};

export type QualifaiDialogflowBotResponse = ApiResponse & {
  bot: QualifaiDialogflowBot;
};

export const useListAllQualifaiDialogflowBotsQuery = () => {
  return useQuery(['qualifai', 'dialogflow/bots'], async () => {
    const res = await http.get<QualifaiDialogflowBotsResponse>(`/api/qualifai/dialogflow/bots`);
    return res.data.bots;
  });
};

export const useGetQualifaiDialogflowBotQuery = (projectId: string) => {
  return useQuery(['qualifai', 'dialogflow/bots', projectId], async () => {
    const res = await http.get<QualifaiDialogflowBotResponse>(`/api/qualifai/dialogflow/bots/${projectId}`);
    return res.data.bot;
  });
};
