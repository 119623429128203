import { useMutation, useQuery, useQueryClient } from 'react-query';

import type {
  Account,
  AccountApiToken,
  AccountBilling,
  BillingConnectProTransaction,
  BillingLeadscorePlusTransaction,
  BillingSubscriptionPayment,
  PaginatedResponse,
} from '../types';
import { ApiResponse, http } from './http-common';

export type AccountResponse = ApiResponse & {
  account?: Account;
};

export type AccountsResponse = ApiResponse & {
  accounts: PaginatedResponse<Account>;
};

export const ReportingStatResponseAttributes = [
  'account_name',
  'time',
  'account_id',
  'dials',
  'connects',
  'connect_pct',
  'inbounds',
  'transfers',
  'transfer_pct',
  'humans',
  'xfer_to_callcenter',
  'xfer_to_callcenter_pct',
  'created_by_email',
] as const;
type AttributeKeys = typeof ReportingStatResponseAttributes[number];
export type ReportingStatResponse = ApiResponse & {
  [K in AttributeKeys]: K extends 'time' | 'account_name'
    ? string
    : K extends 'account_id' | 'dials' | 'connects' | 'inbounds' | 'transfers' | 'humans' | 'xfer_to_callcenter'
    ? number
    : K extends 'connect_pct' | 'transfer_pct' | 'xfer_to_callcenter_pct'
    ? number | undefined
    : never;
};
export type ReportingStatsResponse = ApiResponse & {
  data: ReportingStatResponse[];
};

export type BillingConnectProTransactionResponse = ApiResponse & {
  transaction: BillingConnectProTransaction;
};

export type BillingConnectProTransactionsResponse = ApiResponse & {
  transactions: PaginatedResponse<BillingConnectProTransaction>;
};

export type BillingLeadscorePlusTransactionResponse = ApiResponse & {
  transaction: BillingLeadscorePlusTransaction;
};

export type BillingLeadscorePlusTransactionsResponse = ApiResponse & {
  transactions: PaginatedResponse<BillingLeadscorePlusTransaction>;
};

export type AccountBillingSubscriptionPaymentsResponse = ApiResponse & {
  payments: PaginatedResponse<BillingSubscriptionPayment>;
};

export type AccountApiTokensResponse = ApiResponse & {
  apiTokens: PaginatedResponse<AccountApiToken>;
};

export type AddAccountInput = {
  name: string;
};

export const useAddAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ name }: AddAccountInput) => {
      const res = await http.post<AccountResponse>('/api/accounts', { name });
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        // queryClient.removeQueries(['accounts', account.id]); // drop the cached result.
        queryClient.invalidateQueries(['accounts']);
      },
    }
  );
};

export type ListAccountApiTokensParams = {
  limit: number;
  offset: number;
};

export const useListAccountApiTokensQuery = (params: ListAccountApiTokensParams) => {
  return useQuery(['auth/account', 'api-tokens', params], async () => {
    const res = await http.get<AccountApiTokensResponse>(`/api/auth/account/api-tokens`, { params });
    return res.data.apiTokens;
  });
};

export type ListAccountsParams = {
  limit: number;
  offset: number;
};

export const useListAccountsQuery = (params: ListAccountsParams) => {
  return useQuery(['accounts', params], async () => {
    const res = await http.get<AccountsResponse>('/api/accounts', { params });
    return res.data.accounts;
  });
};

export const useGetAccountQuery = (id: number) => {
  return useQuery(['accounts', id], async () => {
    const res = await http.get<AccountResponse>(`/api/accounts/${id}`);
    return res.data.account;
  });
};

export type UpdateAccountInput = {
  id: number;
  name?: string;
  test_phones?: string;
  billing?: AccountBilling;
  voice_outbound_limit?: number;
};

export const useUpdateAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, ...input }: UpdateAccountInput) => {
      const res = await http.post<AccountResponse>(`/api/accounts/${id}`, input);
      return res.data.account;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['accounts']);
        queryClient.invalidateQueries(['auth/profile']);
        queryClient.invalidateQueries(['auth/account']);
        // queryClient.removeQueries(['auth/profile']);
      },
    }
  );
};
