import { useRef, useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

import { AddFileInput, useAddFileMutation, useListConvosoProfilesQuery } from 'src/api/convoso';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import ImportForm from './ImportForm';

const AddConvosoFile = () => {
  const modalRef = useRef(null);
  const { data: profiles } = useListConvosoProfilesQuery({ limit: 10, offset: 0 });
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync, isLoading } = useAddFileMutation();

  const onCloseModal = () => {
    setApiMessage(undefined);
  };

  const handleSubmit = async (input: AddFileInput): Promise<void> => {
    setApiMessage(undefined);

    try {
      const data = await mutateAsync(input);

      if (!data.success) {
        const e = { success: false, status: 400, message: data.message || 'Unknown Error' };
        setApiMessage(e);
        throw e;
      }

      if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
        // @ts-ignore
        modalRef.current.handleClose();
      }
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
      throw e;
    }
  };

  return (
    <Modal
      ref={modalRef}
      trigger={
        <Button color="blue">
          <Icon name="plus" /> Add File
        </Button>
      }
      onClose={onCloseModal}
    >
      <Modal.Header>Add File</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <ImportForm handleSubmit={handleSubmit} loading={isLoading} profiles={profiles?.data || []} />
      </Modal.Content>
    </Modal>
  );
};

export default AddConvosoFile;
