import { Helmet } from 'react-helmet-async';

import Breadcrumbs from 'src/components/Breadcrumbs';
import PaginatedTable, { RenderProps } from 'src/components/PaginatedTable';
import { Container, Header, Row } from 'src/styles';
import AddAkkioModel from './AddAkkioModel';
import AdminLeadscorePlusAkkioModelsListBody from './AdminLeadscorePlusAkkioModelsListBody';

const AdminLeadscorePlusAkkioModelsList = () => {
  return (
    <Container>
      <Helmet>
        <title>Models - LeadScore+ - Admin | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header style={{ marginBottom: 0 }}>
          <Breadcrumbs crumbs={['Admin', 'LeadScore+', 'Models']} />
        </Header>

        <AddAkkioModel />
      </Row>

      <PaginatedTable
        headers={['Status', 'Name', '']}
        renderBody={(props: RenderProps) => <AdminLeadscorePlusAkkioModelsListBody {...props} />}
      />
    </Container>
  );
};

export default AdminLeadscorePlusAkkioModelsList;
