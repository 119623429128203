import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, Divider, Form, Grid, Icon, Label, Modal, Segment } from 'semantic-ui-react';

import {
  useCreateBillingConnectProTransactionMutation,
  useCreateBillingLeadscorePlusTransactionMutation,
} from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { Account, AccountBillingConfig } from 'src/types';

type ValidationErrors = {
  amount?: string;
};

type Props = {
  account: Account;
  billingConfig: AccountBillingConfig;
};

const AdminAccountBalanceAdjustment = ({ account, billingConfig }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData | undefined>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [amount, setAmount] = useState<string>('0');
  const [note, setNote] = useState('');
  const { isLoading: createConnectProLoading, mutateAsync: createConnectProTransaction } =
    useCreateBillingConnectProTransactionMutation();
  const { isLoading: createLeadscoreLoading, mutateAsync: createLeadscorePlusTransaction } =
    useCreateBillingLeadscorePlusTransactionMutation();

  const onClose = useCallback(() => {
    setAmount('0');
    setApiMessage(undefined);
    setErrors({});
    setNote('');
    setOpen(false);
  }, []);

  const validate = useCallback((input: string) => {
    const validationErrors: ValidationErrors = {};

    if (Number.isNaN(Number(input))) {
      validationErrors.amount = 'invalid number';
    } else if (Number(input) === 0) {
      validationErrors.amount = 'please enter a number other than 0';
    }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onChange = useCallback(
    (_, { value }) => {
      setAmount(value);
      validate(value);
    },
    [validate]
  );

  const onSubmit = useCallback(async () => {
    if (!isEmpty(validate(amount))) {
      return;
    }

    setApiMessage(undefined);

    try {
      const mutateAsync =
        billingConfig === 'leadscorePlus' ? createLeadscorePlusTransaction : createConnectProTransaction;
      await mutateAsync({ accountId: account.id, amount: Number(amount), note });
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [
    validate,
    amount,
    billingConfig,
    createLeadscorePlusTransaction,
    createConnectProTransaction,
    account.id,
    note,
    onClose,
  ]);

  const balanceKey = billingConfig === 'connectPro' ? 'ConnectPro' : 'LeadscorePlus';
  const currentBalance = Number(account[`currentBalance${balanceKey}`] || 0);

  const newBalance = currentBalance + (Number(amount) || 0);

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button compact type="button">
          <Icon name="sort" /> Adjust
        </Button>
      }
    >
      <Modal.Header>Adjust Balance</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <p>Enter a positive or negative amount to adjust the current balance by.</p>

        <Form onSubmit={onSubmit}>
          <Form.Input label="Amount" onChange={onChange} value={amount} error={errors.amount} />

          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form.Field>
                    <label>Current balance</label>
                    <Label color={currentBalance <= 0 ? 'red' : 'green'}>{currentBalance.toFixed(2)}</Label>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                  <Form.Field>
                    <label>New balance</label>
                    <Label color={newBalance <= 0 ? 'red' : 'green'}>{newBalance.toFixed(2)}</Label>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider vertical>
              <Icon name="arrow right" color="grey" />
            </Divider>
          </Segment>

          <Form.Input label="Note" onChange={(_, { value }) => setNote(value)} value={note} />

          <Row>
            <Button type="button" onClick={onClose} fluid>
              Cancel
            </Button>
            <Button loading={createLeadscoreLoading || createConnectProLoading} color="blue" fluid>
              Submit
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AdminAccountBalanceAdjustment;
