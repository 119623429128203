import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  Delimiter,
  PaginatedResponse,
  TrustHubCustomerProfile,
  TrustHubTrustProduct,
  TrustHubTrustProductAssignments,
  TwilioAvailablePhone,
  TwilioAvailablePhoneSearchType,
  TwilioIncomingPhone,
  TwilioIncomingPhonesReassignTrustProfilesJob,
  TwilioIncomingPhonesRefetchJob,
  TwilioStudioFlow,
} from 'src/types';
import { ApiResponse, http } from '../http-common';

type GetTwilioCallQueueSizeResponse = ApiResponse & {
  count: number;
};

export const useGetTwilioCallQueueCallCountQuery = () => {
  return useQuery(
    ['auth/account', 'twilio/call-queue/call-count'],
    async () => {
      const res = await http.get<GetTwilioCallQueueSizeResponse>(`/api/auth/account/twilio/call-queue/call-count`);
      return res.data;
    },
    { retry: false, refetchInterval: 15e3 }
  );
};

type GetTwilioCallQueueJobsResponse = {
  job?: TwilioIncomingPhonesRefetchJob | TwilioIncomingPhonesReassignTrustProfilesJob;
};

export const useGetTwilioCallQueueCurrentJobQuery = () => {
  const queryClient = useQueryClient();

  return useQuery(
    ['auth/account', 'twilio/call-queue/current-job'],
    async () => {
      const res = await http.get<GetTwilioCallQueueJobsResponse>(`/api/auth/account/twilio/call-queue/current-job`);
      return res.data.job;
    },
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'twilio/call-queue/call-count']);
      },
    }
  );
};

export const useTwilioCallQueueCancelAllMutation = () => {
  return useMutation(async () => {
    const res = await http.post<ApiResponse>(`/api/auth/account/twilio/call-queue/cancel-all`);
    return res.data;
  });
};

export type FlushNonConnectsParams = {
  flowSid: string;
};

type FlushNonConnectsResponse = ApiResponse & { deleteCount: number };

export const useFlushNonConnectsMutation = () => {
  return useMutation(async (params: FlushNonConnectsParams) => {
    const res = await http.delete<FlushNonConnectsResponse>(`/api/auth/account/twilio/non-connects`, {
      params,
    });
    return res.data;
  });
};

export type ReleaseTwilioIncomingPhonesParams = {
  sids: string[];
};

export const useReleaseTwilioIncomingPhonesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: ReleaseTwilioIncomingPhonesParams) => {
      const res = await http.delete<ApiResponse>(`/api/auth/account/twilio/incoming-phones`, { params });
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'twilio/incoming-phones']);
      },
    }
  );
};

export type ProvisionTwilioAvailablePhonesParams = {
  phoneNumbers: string[];
  webhookUrl: string;
  voiceConfigID: string;
};

export const useProvisionTwilioAvailablePhonesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: ProvisionTwilioAvailablePhonesParams) => {
      const res = await http.post<ApiResponse>(`/api/auth/account/twilio/available-phones`, params);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'twilio/incoming-phones']);
      },
    }
  );
};

export type GetTwilioAvailablePhonesParams = {
  limit: number;
  search: string;
  searchType: TwilioAvailablePhoneSearchType;
};

export type GetTwilioAvailablePhonesResponse = {
  phones: TwilioAvailablePhone[];
};

export const useGetTwilioAvailablePhonesMutation = () => {
  return useMutation(async (params: GetTwilioAvailablePhonesParams) => {
    const res = await http.get<GetTwilioAvailablePhonesResponse>(`/api/auth/account/twilio/available-phones`, {
      params,
    });
    return res.data;
  }, {});
};

export type GetPostalCodeStatsInput = {
  mode: 'file' | 'text';
  // file inputs
  file?: File;
  delimiter: Delimiter;
  fieldMappings: string[];
  hasHeader: boolean;
  // text inputs
  zips: string;
};

export type CityCounts = { [city: string]: { count: number; latLng: number[] } };

export type PostalCodeStats = {
  raw: number;
  unique: number;
  valid: number;
  cities: number;
  byCity: CityCounts;
  states: number;
  byState: { [state: string]: number };
};

export type GetPostalCodeStatsResponse = ApiResponse & {
  stats: PostalCodeStats;
};

export const useGetPostalCodeStatsMutation = () => {
  return useMutation(async (input: GetPostalCodeStatsInput) => {
    if (input.mode === 'file' && !input.file) {
      const err = {
        response: {
          status: 422,
          statusText: 'Unprocessable Entity',
          data: { success: false, errors: [{ error: 'file is required' }] },
        },
      };
      throw err;
    }

    const fd = new FormData();
    fd.append('mode', input.mode);
    if (typeof input.file !== 'undefined') {
      fd.append('file', input.file);
    }
    fd.append('delimiter', input.delimiter);
    fd.append('field_mappings', input.fieldMappings.join(','));
    fd.append('has_header', input.hasHeader ? '1' : '0');
    fd.append('zips', input.zips);

    const res = await http.post<GetPostalCodeStatsResponse>(`/api/auth/account/twilio/postal-code-stats`, fd);
    return res.data.stats;
  }, {});
};

export type PurchaseData = {
  city?: string;
  state: string;
  count: number;
  latLng?: number[];
};

export type PurchasePostalCodesInput = {
  group: 'city' | 'state';
  webhookUrl: string;
  counts: PurchaseData[];
};

export type PurchasePostalCodesResponse = ApiResponse & {
  purchaseCounts: { [key: string]: number };
};

export const usePurchasePostalCodesMutation = () => {
  return useMutation(async (input: PurchasePostalCodesInput) => {
    const res = await http.post<PurchasePostalCodesResponse>(`/api/auth/account/twilio/purchase-postal-codes`, input);
    return res.data.purchaseCounts;
  }, {});
};

export type GetTwilioIncomingPhonesResponse = ApiResponse & {
  phones: PaginatedResponse<TwilioIncomingPhone>;
};

export type GetTwilioIncomingPhonesParams = {
  limit: number;
  offset: number;
  search: string;
  startDate?: string;
  endDate?: string;
  profiles?: string[];
};

export const useGetTwilioIncomingPhonesQuery = (params: GetTwilioIncomingPhonesParams) => {
  return useQuery(
    ['auth/account', 'twilio/incoming-phones', params],
    async () => {
      const res = await http.get<GetTwilioIncomingPhonesResponse>(`/api/auth/account/twilio/incoming-phones`, {
        params,
      });
      return res.data;
    },
    { retry: false }
  );
};

type GetTwilioIncomingPhonesRefetchJobResponse = {
  job?: TwilioIncomingPhonesRefetchJob | TwilioIncomingPhonesReassignTrustProfilesJob;
};

export const useGetTwilioIncomingPhonesJobsQuery = () => {
  const queryClient = useQueryClient();

  return useQuery(
    ['auth/account', 'twilio/incoming-phones/jobs'],
    async () => {
      const res = await http.get<GetTwilioIncomingPhonesRefetchJobResponse>(
        `/api/auth/account/twilio/incoming-phones/jobs`
      );
      return res.data.job;
    },
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'twilio/incoming-phones']);
      },
    }
  );
};

export const useRefetchTwilioIncomingPhonesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const res = await http.post<ApiResponse>(`/api/auth/account/twilio/incoming-phones/jobs/refetch`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'twilio/incoming-phones/jobs']);
      },
    }
  );
};

export type ReassignTwilioIncomingPhonesTrustProfilesParams = {
  sids: string[];
  customerProfileSid: string;
  shakenStirSid: string;
  cnamSid: string;
  voiceIntegritySid: string;
};

export const useReassignTwilioIncomingPhonesTrustProfilesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: ReassignTwilioIncomingPhonesTrustProfilesParams) => {
      const res = await http.post<ApiResponse>(
        `/api/auth/account/twilio/incoming-phones/jobs/reassign-trust-profiles`,
        data
      );
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'twilio/incoming-phones/jobs']);
      },
    }
  );
};

export type ReassignTwilioIncomingPhonesStudioFlowParams = {
  sids: string[];
  webhookURL: string;
};

export const useReassignTwilioIncomingPhonesStudioFlowMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: ReassignTwilioIncomingPhonesStudioFlowParams) => {
      const res = await http.post<ApiResponse>(`/api/auth/account/twilio/incoming-phones/reassign-studio-flow`, data);
      return res.data;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['auth/account', 'twilio/incoming-phones']);
      },
    }
  );
};

export type ReassignTwilioIncomingPhonesVoiceConfigParams = {
  sids: string[];
  configID: string;
};

export const useReassignTwilioIncomingPhonesVoiceConfigMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: ReassignTwilioIncomingPhonesVoiceConfigParams) => {
      const res = await http.post<ApiResponse>(
        `/api/auth/account/twilio/incoming-phones/jobs/reassign-voice-config`,
        data
      );
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'twilio/incoming-phones/jobs']);
      },
    }
  );
};

export type GetTwilioTotalIncomingPhoneCountResponse = ApiResponse & {
  count: number;
};

export const useGetTwilioTotalIncomingPhoneCountQuery = () => {
  return useQuery(
    ['auth/account', 'twilio/incoming-phones', 'count'],
    async () => {
      const res = await http.get<GetTwilioTotalIncomingPhoneCountResponse>(
        `/api/auth/account/twilio/incoming-phones/count`
      );
      return res.data;
    },
    { retry: false }
  );
};

export type GetTwilioStudioFlowsResponse = ApiResponse & {
  flows: TwilioStudioFlow[] | null;
};

export type GetTwilioStudioFlowsParams = {
  limit?: number;
  // pageNumber: string;
  // pageToken: string;
};

export const useGetTwilioStudioFlowsQuery = (params?: GetTwilioStudioFlowsParams) => {
  return useQuery(
    ['auth/account', 'twilio/studio-flows', params],
    async () => {
      const res = await http.get<GetTwilioStudioFlowsResponse>(`/api/auth/account/twilio/studio-flows`, {
        params,
      });
      return res.data;
    },
    { retry: false }
  );
};

export type GetTwilioTrustProfilesResponse = ApiResponse & {
  customerProfiles: TrustHubCustomerProfile[];
  shakenStirProfiles: TrustHubTrustProduct[];
  cnamProfiles: TrustHubTrustProduct[];
  voiceIntegrityProfiles: TrustHubTrustProduct[];
  assignments: TrustHubTrustProductAssignments[];
};

export const useGetTwilioTrustProfilesQuery = () => {
  return useQuery(
    ['auth/account', 'twilio/trust-profiles'],
    async () => {
      const res = await http.get<GetTwilioTrustProfilesResponse>(`/api/auth/account/twilio/trust-profiles`);
      return res.data;
    },
    { retry: false }
  );
};

export type UpdateTwilioCustomerProfileParams = {
  customerProfileSid: string;
  shakenStirProfileSid: string;
  cnamProfileSid: string;
  voiceIntegrityProfileSid: string;
};

export const useUpdateTwilioCustomerProfileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: UpdateTwilioCustomerProfileParams) => {
      const res = await http.post<ApiResponse>(`/api/auth/account/twilio/customer-profile`, data);
      return res.data;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['auth/account', 'twilio/trust-profiles']);
        queryClient.invalidateQueries(['auth/profile']);
      },
    }
  );
};

export type CreateTrustProductResponse = ApiResponse & {
  reviewStatus?: string;
};

export type CreateShakenStirTrustProductInput = {
  friendlyName: string;
};

export const useCreateShakenStirTrustProductMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: CreateShakenStirTrustProductInput) => {
      const res = await http.post<CreateTrustProductResponse>(`/api/auth/account/twilio/shaken-stir`, data);
      return res.data;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['auth/account', 'twilio/trust-profiles']);
        // NOTE: using removeQueries here to force the trust hub profiles dropdown(s) to re-render and set the new value(s) into state
        queryClient.removeQueries(['auth/profile']);
      },
    }
  );
};

export type CreateCnamTrustProductInput = {
  friendlyName: string;
  displayName: string;
};

export const useCreateCnamTrustProductMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: CreateCnamTrustProductInput) => {
      const res = await http.post<CreateTrustProductResponse>(`/api/auth/account/twilio/cnam`, data);
      return res.data;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['auth/account', 'twilio/trust-profiles']);
        // NOTE: using removeQueries here to force the trust hub profiles dropdown(s) to re-render and set the new value(s) into state
        queryClient.removeQueries(['auth/profile']);
      },
    }
  );
};

export type CreateVoiceIntegrityTrustProductInput = {
  friendlyName: string;
  useCase: string;
  avgDailyCalls: number;
  employeeCount: number;
  notes: string;
};

export const useCreateVoiceIntegrityTrustProductMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: CreateVoiceIntegrityTrustProductInput) => {
      const res = await http.post<CreateTrustProductResponse>(`/api/auth/account/twilio/voice-integrity`, data);
      return res.data;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['auth/account', 'twilio/trust-profiles']);
        // NOTE: using removeQueries here to force the trust hub profiles dropdown(s) to re-render and set the new value(s) into state
        queryClient.removeQueries(['auth/profile']);
      },
    }
  );
};
