import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { SendgridInboundParseSetting, SendgridProfile } from 'src/types';
import { ApiResponse, http } from '../http-common';

type SendGridProfileResponse = ApiResponse & {
  sendgridProfile?: SendgridProfile;
};

export type UpdateAccountSendGridInput = {
  apiKey: string;
};

export const useUpdateAccountSendGridMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: UpdateAccountSendGridInput) => {
      const res = await http.post<SendGridProfileResponse>(`/api/auth/account/sendgrid`, input);
      return res.data.sendgridProfile;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'sendgrid']);
      },
    }
  );
};

export const useFixAccountSendGridWebhookUrlMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const res = await http.post<ApiResponse>(`/api/auth/account/sendgrid/fix-webhook-url`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'sendgrid']);
      },
    }
  );
};

export const useGetSendGridProfileQuery = () => {
  return useQuery(
    ['auth/account', 'sendgrid'],
    async () => {
      const res = await http.get<SendGridProfileResponse>(`/api/auth/account/sendgrid`);
      return res.data.sendgridProfile;
    },
    { retry: false }
  );
};

type SendGridInboundParseSettingResponse = ApiResponse & {
  sendgridInboundParseSetting?: SendgridInboundParseSetting;
};

export const useGetSendGridInboundParseSettingQuery = (id: number) => {
  return useQuery(['auth/account', 'sendgrid', 'inbound-parse', id], async () => {
    const res = await http.get<SendGridInboundParseSettingResponse>(`/api/auth/account/sendgrid/inbound-parse/${id}`);
    return res.data.sendgridInboundParseSetting;
  });
};

export type SaveSendgridInboundParseSettingInput = {
  id: number;
  setting?: SendgridInboundParseSetting;
};

export const useSaveSendgridInboundParseSettingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, ...params }: SaveSendgridInboundParseSettingInput) => {
      const res = await http.post<SendGridInboundParseSettingResponse>(
        `/api/auth/account/sendgrid/inbound-parse/${id}`,
        params
      );
      return res.data.sendgridInboundParseSetting;
    },
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries(['auth/account', 'sendgrid', 'inbound-parse', id]);
        // queryClient.removeQueries(['auth/account', 'sendgrid', 'inbound-parse', id]);
      },
    }
  );
};

export type CreateSendgridInboundParseSettingInput = {
  name: string;
};

export const useCreateSendgridInboundParseSettingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: CreateSendgridInboundParseSettingInput) => {
      const res = await http.post<SendGridInboundParseSettingResponse>(
        `/api/auth/account/sendgrid/inbound-parse`,
        params
      );
      return res.data.sendgridInboundParseSetting;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'sendgrid']);
      },
    }
  );
};

export const useDeleteSendgridInboundParseSettingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await http.delete<ApiResponse>(`/api/auth/account/sendgrid/inbound-parse/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'sendgrid']);
      },
    }
  );
};
