import { useCallback, useMemo, useState } from 'react';
import { Cell, Pie, PieChart, Sector } from 'recharts';
import { Placeholder } from 'semantic-ui-react';

import { Row } from 'src/styles';
import theme from 'src/styles/theme';
import { Colors } from './config';

const renderActiveShape = (props: any) => {
  // const RADIAN = Math.PI / 180;
  const { cx, cy, /* midAngle, */ innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } =
    props;
  // const sin = Math.sin(-RADIAN * midAngle);
  // const cos = Math.cos(-RADIAN * midAngle);
  // const sx = cx + (outerRadius + 8) * cos;
  // const sy = cy + (outerRadius + 8) * sin;
  // const mx = cx + (outerRadius + 20) * cos;
  // const my = cy + (outerRadius + 20) * sin;
  // const ex = mx + (cos >= 0 ? 1 : -1) * 24;
  // const ey = my;
  // const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={-10} textAnchor="middle" fill={fill} fontWeight="bold">
        {payload.label}
      </text>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#333">{`${value}`}</text>
      <text x={cx} y={cy} dy={22} textAnchor="middle" fill="#999" fontSize="0.8em">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 4}
        outerRadius={outerRadius + 8}
        fill={fill}
        opacity={0.3}
      />
      {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={14} textAnchor={textAnchor} fill="#999" fontSize="0.8em">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text> */}
    </g>
  );
};

type PieData = {
  label: string;
  count: number;
  id?: number;
  fill?: string;
};

const PieChartWithLegend = ({ data, loading = false }: { data: PieData[]; loading?: boolean }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [hiddenIndexes, setHiddenIndexes] = useState<number[]>([]);

  const total = data.reduce((total, { count }) => total + count, 0);

  const formattedData = useMemo(() => {
    return data
      .sort((a, b) => b.count - a.count)
      .map((row, i) => ({
        ...row,
        id: i,
        fill: Colors[i % Colors.length],
      }));
  }, [data]);

  const filteredData = useMemo(() => {
    return formattedData.filter((_, i) => !hiddenIndexes.includes(i));
  }, [formattedData, hiddenIndexes]);

  const onClickPieLegend = useCallback(
    (i: number) => () => {
      if (hiddenIndexes.includes(i)) {
        setHiddenIndexes(hiddenIndexes.filter(index => index !== i));
        setActiveIndex(filteredData.findIndex(({ id }) => id === i - 1) + 1);
      } else {
        setHiddenIndexes([...hiddenIndexes, i]);
        setActiveIndex(0);
      }
    },
    [filteredData, hiddenIndexes]
  );

  const onPieEnter = useCallback((_, index) => setActiveIndex(index), []);

  return (
    <Row>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        {loading ? (
          <Placeholder style={{ minWidth: 280 }}>
            <Placeholder.Image />
          </Placeholder>
        ) : (
          <PieChart width={280} height={280}>
            <Pie
              isAnimationActive={false}
              data={filteredData}
              dataKey="count"
              innerRadius={80}
              outerRadius={120}
              activeShape={renderActiveShape}
              activeIndex={activeIndex}
              onMouseEnter={onPieEnter}
            >
              {filteredData.map(({ fill }, i) => (
                <Cell key={`cell-${i}`} fill={fill} />
              ))}
            </Pie>
          </PieChart>
        )}
      </div>

      <div style={{ height: 280, overflowY: 'auto' }}>
        {loading ? (
          <Placeholder style={{ minWidth: 200 }}>
            <Placeholder.Paragraph>
              {Array(5)
                .fill(0)
                .map((_, i) => (
                  <Placeholder.Line key={i} />
                ))}
            </Placeholder.Paragraph>
          </Placeholder>
        ) : (
          data.map(({ label, count }, i) => (
            <div
              key={label}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: 6,
                padding: '1px 0',
                opacity: hiddenIndexes.includes(i) ? 0.3 : 1,
              }}
              onMouseOver={() => {
                if (!hiddenIndexes.includes(i)) {
                  onPieEnter(
                    null,
                    filteredData.findIndex(({ id }) => id === i)
                  );
                }
              }}
              onClick={onClickPieLegend(i)}
              className={activeIndex === i ? 'active' : undefined}
            >
              <span
                style={{
                  backgroundColor: Colors[i % Colors.length],
                  width: 12,
                  height: 12,
                  borderRadius: 6,
                  display: 'inline-block',
                }}
              ></span>
              <span style={{ flex: 1, textAlign: 'left', whiteSpace: 'nowrap' }}>{label}</span>{' '}
              <span style={{ color: theme.gray, width: 60, textAlign: 'right' }}>
                {((count / total) * 100).toFixed(2)}%
              </span>
            </div>
          ))
        )}
      </div>
    </Row>
  );
};

export default PieChartWithLegend;
