import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Form, Header, Icon, Segment } from 'semantic-ui-react';

import { useGetWebhookConfigQuery } from 'src/api/webhooks';
import Breadcrumbs from 'src/components/Breadcrumbs';
import PaginatedTable from 'src/components/PaginatedTable';
import useSearchQuery from 'src/hooks/useSearchQuery';
import NotFound from 'src/pages/not-found';
import { Container, Header as PageHeader, Row } from 'src/styles';
import EditWebhookConfigForm from './EditWebhookConfigForm';
import { PlaceholderLayout } from './style';
import WebhookLogsBody from './WebhookLogsBody';
import WebhookSendTestRequestModal from './WebhookSendTestRequestModal';

const betaURL = process.env.REACT_APP_BETA_URL;

const WebhookConfigEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { push, goBack } = useHistory();
  const query = useSearchQuery();
  const { data: config, isLoading } = useGetWebhookConfigQuery(id);

  const getSearchFromQuery = (query: URLSearchParams): string => {
    return query.get('search') || '';
  };

  const [searchQuery, setSearchQuery] = useState<string>(() => getSearchFromQuery(query));
  const [appliedQuery, setAppliedQuery] = useState<string>(() => getSearchFromQuery(query));

  const onSubmit = useCallback(() => {
    query.set('search', searchQuery);
    query.set('page', '1');
    setAppliedQuery(searchQuery);
    push({ search: query.toString() });
  }, [push, query, searchQuery]);

  return (
    <Container>
      <Helmet>
        <title>Edit Webhook Config | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Row style={{ alignItems: 'center' }}>
          <Button icon color="blue" onClick={goBack} style={{ marginRight: '1rem' }}>
            <Icon name="arrow left" />
          </Button>
          <PageHeader style={{ margin: 0 }}>
            <Breadcrumbs crumbs={['Webhooks', 'Edit']} />
          </PageHeader>
        </Row>
      </Row>

      <Segment>
        {isLoading ? (
          <PlaceholderLayout />
        ) : typeof config !== 'undefined' ? (
          <EditWebhookConfigForm
            config={config}
            initialHeaders={config?.request?.headers}
            webhookConfigData={config?.data}
          />
        ) : (
          <NotFound />
        )}
      </Segment>

      {!isLoading && (
        <>
          <Segment>
            <Row style={{ justifyContent: 'space-between', marginBottom: '1rem' }}>
              <Header style={{ margin: 0 }}>Logs</Header>
              <Row>
                <a href={`${betaURL}/api/webhooks/configs/${id}/download/logs?query=${appliedQuery}`}>
                  <Button as="div" color="blue" icon title="Download logs">
                    <Icon name="download" />
                  </Button>
                </a>
                <WebhookSendTestRequestModal id={id} />
              </Row>
            </Row>

            <Form onSubmit={onSubmit}>
              <Form.Group>
                <Form.Input
                  label="Search"
                  placeholder="by Incoming Data"
                  value={searchQuery || ''}
                  onChange={(_, { value }) => setSearchQuery(value)}
                  onBlur={onSubmit}
                />

                <Form.Button label="&nbsp;" icon>
                  <Icon name="search" />
                </Form.Button>
              </Form.Group>
            </Form>

            <PaginatedTable
              headers={['Created At', 'Duration (ms)', 'Incoming Data', 'Remote Response', 'Final Status / Error', '']}
              renderBody={props => <WebhookLogsBody {...props} query={appliedQuery} />}
              qsParam={'page'}
            />
          </Segment>
        </>
      )}
    </Container>
  );
};

export default WebhookConfigEdit;
