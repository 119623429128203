import { useQuery } from 'react-query';

import { QualifaiScorecard } from 'src/types';
import { ApiResponse, http } from './http-common';

export type QualifaiScorecardsResponse = ApiResponse & {
  scorecards: QualifaiScorecard[];
};

export const useListQualifaiScorecardsQuery = () => {
  return useQuery(['qualifai', 'scorecards'], async () => {
    const res = await http.get<QualifaiScorecardsResponse>('/api/qualifai/scorecards');
    return res.data.scorecards;
  });
};
