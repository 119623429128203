import { Helmet } from 'react-helmet-async';
import { Message } from 'semantic-ui-react';

import { Center, Container } from 'src/styles';

const NotFound = () => (
  <Container flex>
    <Helmet>
      <title>404 - Not Found | datascore</title>
    </Helmet>

    <Center>
      <Message color="red">
        <Message.Header>🚨 Error!</Message.Header>
        <p>The page you are looking for could not be found.</p>
      </Message>
    </Center>
  </Container>
);

export default NotFound;
