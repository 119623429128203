import { format, formatDistanceToNow } from 'date-fns';
import { useEffect } from 'react';
import { Label, Table } from 'semantic-ui-react';

import { useListBillingSubscriptionPaymentsQuery } from 'src/api/auth/account-billing';
import { RenderProps } from 'src/components/PaginatedTable';
import { Row } from 'src/styles';
import theme from 'src/styles/theme';

const AccountBillingSubscriptionPaymentsListBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: payments } = useListBillingSubscriptionPaymentsQuery({
    limit,
    offset: limit * (currentPage - 1),
  });

  useEffect(() => {
    let count = 1;
    if (typeof payments !== 'undefined') {
      count = Math.ceil(payments.total / limit);
    }
    setPageCount(count);
  }, [payments, limit, setPageCount]);

  if (!payments || payments?.data.length === 0) {
    return (
      <Table.Row>
        <Table.Cell colSpan={5} style={{ color: theme.gray }}>
          No payments found.
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      {payments?.data.map(p => {
        const monthDate = new Date().setMonth(p.payload.month - 1);
        const monthStr = format(monthDate, 'MMMM');

        return (
          <Table.Row key={p.id}>
            <Table.Cell>
              <Row style={{ flexDirection: 'column' }}>
                <strong>Payment for month: {monthStr}</strong>
                <span style={{ color: theme.gray }}>Charge ID: {p.payload['stripe.chargeID']}</span>
              </Row>
            </Table.Cell>
            <Table.Cell>
              <Label title={p.created_at}>
                {formatDistanceToNow(new Date(p.created_at), { addSuffix: true, includeSeconds: true })}
              </Label>
            </Table.Cell>
            <Table.Cell>
              <Label>{p.amount.toFixed(2)}</Label>
            </Table.Cell>
            <Table.Cell>
              <Label color={p.status === 'success' ? 'green' : 'red'}>
                {p.status === 'success' ? 'Success' : 'Failed'}
              </Label>
            </Table.Cell>
            <Table.Cell textAlign="right" collapsing>
              {/* <SwitchAccountButton id={t.id} />
              <Button icon="pencil" color="blue" onClick={() => history.push(`/admin/accounts/${t.id}`)} title="Edit" /> */}
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
};

export default AccountBillingSubscriptionPaymentsListBody;
