import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { useListBigqueryDatasetTablesQuery } from 'src/api/admin/bigquery';
import { RenderProps } from 'src/components/PaginatedTable';
import RemoveBigqueryTable from './RemoveBigqueryDatasetAccess';

const AdminBigqueryTablesListBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { id } = useParams<{ id: string }>();
  const { data: tables } = useListBigqueryDatasetTablesQuery({
    datasetId: Number(id),
    limit,
    offset: limit * (currentPage - 1),
  });

  useEffect(() => {
    let count = 1;
    if (typeof tables !== 'undefined') {
      count = Math.ceil(tables.total / limit);
    }
    setPageCount(count);
  }, [tables, limit, setPageCount]);

  return tables?.data.map(table => {
    return (
      <Table.Row key={table.id}>
        <Table.Cell>{table.name}</Table.Cell>
        <Table.Cell>{table.table_id}</Table.Cell>
        <Table.Cell>{table.time_column_name}</Table.Cell>
        <Table.Cell textAlign="right" collapsing>
          <RemoveBigqueryTable datasetId={table.bq_dataset_id} tableId={table.id} />
        </Table.Cell>
      </Table.Row>
    );
  });
};

export default AdminBigqueryTablesListBody;
