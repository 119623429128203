import upperFirst from 'lodash/upperFirst';
import { useEffect } from 'react';
import { Label, Table } from 'semantic-ui-react';

import { useListSchedulesQuery } from 'src/api/schedules';
import { RenderProps } from 'src/components/PaginatedTable';
import { Row } from 'src/styles';
import theme from 'src/styles/theme';
import EditSchedule from './EditSchedule';

const ListAccountSchedulesBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: schedules } = useListSchedulesQuery({ limit, offset: limit * (currentPage - 1) });

  useEffect(() => {
    let count = 1;
    if (typeof schedules !== 'undefined') {
      count = Math.ceil(schedules.total / limit);
    }
    setPageCount(count);
  }, [schedules, limit, setPageCount]);

  return schedules?.data.map(s => (
    <Table.Row key={s.id}>
      <Table.Cell>{s.name || <span style={{ color: theme.gray }}>Not Available</span>}</Table.Cell>
      <Table.Cell>{s.enabled ? <Label color="green">Enabled</Label> : <Label>Disabled</Label>}</Table.Cell>
      <Table.Cell>
        {Object.entries(s.config)
          .filter(([_, d]) => d.enabled)
          .map(([dow]) => (
            <Label key={dow}>{upperFirst(dow)}</Label>
          ))}
      </Table.Cell>
      <Table.Cell textAlign="right" collapsing>
        <Row style={{ justifyContent: 'flex-end' }}>
          <EditSchedule schedule={s} />
        </Row>
      </Table.Cell>
    </Table.Row>
  ));
};

export default ListAccountSchedulesBody;
