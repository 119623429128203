import { Label, Modal } from 'semantic-ui-react';

import { useGetJobsQuery } from 'src/api/jobs';
import { JobStatus } from 'src/types';
import PaginatedTable from '../PaginatedTable';
import ActiveJobsListBody from './ActiveJobsListBody';

const ActiveJobs = () => {
  const limit = 100;

  const { data: jobs } = useGetJobsQuery({
    limit,
    offset: 0,
    statuses: [JobStatus.Pending, JobStatus.Preprocessing, JobStatus.Processing, JobStatus['Processing Externally']],
  });

  const total = jobs?.total || 0;

  // if (total === 0) return null;

  const labelText = total === 1 ? 'Job' : 'Jobs';
  const totalText = total > limit - 1 ? `${limit - 1}+` : total;

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 1rem' }}>
      <Modal
        trigger={
          <Label color={total > 0 ? 'blue' : undefined} style={{ cursor: 'pointer' }}>
            {totalText} Active {labelText}
          </Label>
        }
        size="large"
      >
        <Modal.Header>Active Jobs</Modal.Header>
        <Modal.Content>
          <PaginatedTable
            headers={['ID', 'Job Type', 'Created At', 'Job Status', 'Started at', 'Last Updated', '']}
            renderBody={() => <ActiveJobsListBody jobs={jobs} />}
            qsParam={false}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default ActiveJobs;
