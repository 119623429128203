import { isEmpty } from 'lodash';
import { parse } from 'papaparse';
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Button, DropdownProps, Form, Label, Message, Table } from 'semantic-ui-react';
import stringSimilarity from 'string-similarity';

import { BlacklistBulkAddFormdata, BlacklistBulkAddResponse, useBlacklistBulkAddMutation } from 'src/api/blacklists';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { DelimiterOptions, FileDelimiters } from 'src/config';
import { Row } from 'src/styles';
import { Blacklist, Delimiter } from 'src/types';

type ValidationErrors = {
  file?: string;
  field_mappings?: string;
  emails?: string;
  phones?: string;
};

const RequiredFields = ['phone', 'email'];

const fieldMappingOptions = [
  { key: '__skip__', text: 'Do Not Import', value: '__skip__' },
  ...RequiredFields.map(f => ({ key: f, text: f, value: f })),
];

// For a better string matching, add an alias for fields
const aliasMapping: { [key: string]: string[] } = {
  phone: ['phone', 'phone_number', 'phone1', 'primary_phone'],
  email: ['email', 'email_address'],
};

const getInitialFormdata = (): BlacklistBulkAddFormdata => ({
  mode: 'file',
  // file inputs
  file: undefined,
  delimiter: 'Comma',
  fieldMappings: [],
  hasHeader: true,
  // text inputs
  emails: '',
  phones: '',
});

type Props = {
  blacklist: Blacklist;
  onCancel: () => void;
  onSuccess: (res: BlacklistBulkAddResponse) => void;
};

const BlacklistAddForm = ({ blacklist, onCancel, onSuccess }: Props) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<BlacklistBulkAddFormdata>(() => getInitialFormdata());
  const [filePreview, setFilePreview] = useState<string[][] | null>(null);
  const [rowCounts, setRowCounts] = useState<{ total: number; qualified: number }>();
  const { mutateAsync: bulkAdd, isLoading: bulkAddLoading } = useBlacklistBulkAddMutation();

  const validate = useCallback((input: BlacklistBulkAddFormdata): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    switch (input.mode) {
      case 'file':
        if (typeof input.file === 'undefined') {
          validationErrors.file = 'file is required';
        } else if (['text/csv', 'text/plain'].indexOf(input.file.type) === -1) {
          validationErrors.file = 'Invalid file type, please select a CSV';
        }

        if (
          typeof validationErrors.file === 'undefined' &&
          !input.fieldMappings.some(f => ['email', 'phone'].includes(f))
        ) {
          validationErrors.field_mappings = 'Please select at least one of: email, phone';
        }
        break;

      case 'text':
        if (input.phones === '' && input.emails === '') {
          validationErrors.phones = 'required';
        }
        break;
    }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  useEffect(() => {
    if (typeof formdata.file === 'undefined') {
      setFilePreview(null);
      return;
    }
    if (typeof errors.file !== 'undefined') {
      setFilePreview(null);
      return;
    }

    const d = FileDelimiters.has(formdata.delimiter) ? FileDelimiters.get(formdata.delimiter) : ',';

    const parsedRows: string[][] = [];
    parse<string[]>(formdata.file, {
      delimiter: d,
      step: ({ data }, parser) => {
        parsedRows.push(data);
        if (parsedRows.length >= 5) {
          parser.abort();
          return;
        }
      },
      complete: () => {
        setFilePreview(parsedRows);

        setFormdata(prev => {
          const fieldMappingValues = fieldMappingOptions.map(fm => fm.value);

          const headers = parsedRows[0];

          const fm = Array(headers.length).fill('__skip__');

          for (const f of fieldMappingValues) {
            if (f === '__skip__' || f === '__keep__') continue;

            let bestScore = 0;
            let bestIdx = 0;
            headers.forEach((h, i) => {
              if (fm[i] !== '__skip__' && fm[i] !== '__keep__') return;

              const fields = aliasMapping[f.toLowerCase()] || [f.toLowerCase()];

              for (const field of fields) {
                const ratio = stringSimilarity.compareTwoStrings(h.toLowerCase(), field);

                if (ratio >= bestScore) {
                  bestScore = ratio;
                  bestIdx = i;
                }
              }
            });

            if (bestScore >= 0.5) {
              fm[bestIdx] = f;
            }
          }

          if (fm.length === 1 && fm[0] === '__skip__') {
            fm[0] = 'phone';
          }

          const next = {
            ...prev,
            fieldMappings: fm,
          };
          validate(next);
          return next;
        });
      },
    });
  }, [errors.file, formdata.delimiter, formdata.file, validate]);

  useEffect(() => {
    if (typeof formdata.file === 'undefined') {
      setRowCounts(undefined);
      return;
    }
    if (typeof errors.file !== 'undefined') {
      setRowCounts(undefined);
      return;
    }

    const idx = {
      phone: formdata.fieldMappings.indexOf('phone'),
      email: formdata.fieldMappings.indexOf('email'),
    };

    const d = FileDelimiters.has(formdata.delimiter) ? FileDelimiters.get(formdata.delimiter) : ',';

    let total = 0;
    let qualified = 0;
    parse<string[]>(formdata.file, {
      delimiter: d,
      step: ({ data }) => {
        total++;

        if (idx.phone === -1 && idx.email === -1) return;
        if (!data[idx.phone] && !data[idx.email]) return;

        const phone = (data[idx.phone] || '').replace(/\D/g, '').replace(/^1/, '');
        if (!data[idx.email] && phone.length !== 10) return;

        qualified++;
      },
      complete: () => {
        setRowCounts({ total, qualified });
      },
    });
  }, [errors.file, formdata.delimiter, formdata.file, formdata.fieldMappings]);

  const onChangeFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFormdata(prev => {
        let file;
        if (event.target.files && event.target.files.length) {
          file = event.target.files[0];
        }

        const next = { ...prev, file };
        validate(next);
        return next;
      });
    },
    [validate]
  );

  const onChangeDelimiter = useCallback((_event: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
    setFormdata(prev => ({ ...prev, delimiter: value as Delimiter }));
  }, []);

  const onChangeFieldMapping = useCallback(
    (i: number) =>
      (_event: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        setFormdata(prev => {
          const newMappings = [...prev.fieldMappings];
          newMappings[i] = `${value}`;
          const next = { ...prev, fieldMappings: newMappings };
          validate(next);
          return next;
        });
      },
    [validate]
  );

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    if (!isEmpty(validate(formdata))) {
      return;
    }

    try {
      const res = await bulkAdd({ id: blacklist.id, ...formdata });
      onSuccess(res);
      setFormdata(getInitialFormdata());
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [validate, formdata, bulkAdd, blacklist.id, onSuccess]);

  return (
    <Form onSubmit={onSubmit}>
      <Form.Select
        width={8}
        label="Input Mode"
        name="inputMode"
        options={[
          { key: 'file', text: 'Upload a CSV', value: 'file' },
          { key: 'text', text: 'Enter Manually', value: 'text' },
        ]}
        value={formdata.mode}
        onChange={(_, { value }) =>
          setFormdata(prev => ({ ...prev, mode: String(value) === 'file' ? 'file' : 'text' }))
        }
      />

      {formdata.mode === 'file' ? (
        <>
          {typeof rowCounts !== 'undefined' && (
            <Message success visible>
              <Message.Header>File Details</Message.Header>
              <Message.Content>
                Your file contains <strong>{rowCounts.qualified}</strong> qualified records out of{' '}
                <strong>{rowCounts.total}</strong> total rows.
              </Message.Content>
            </Message>
          )}

          {errors.field_mappings && (
            <Message error visible>
              <Message.Header>🚨 Missing required field mappings</Message.Header>
              <Message.Content>{errors.field_mappings}</Message.Content>
            </Message>
          )}

          <Form.Group widths="equal">
            <Form.Field error={errors.file}>
              <label>File</label>
              <input type="file" name="file" onChange={onChangeFile} ref={fileInputRef} />
              {errors.file && (
                <Label pointing prompt>
                  {errors.file}
                </Label>
              )}
            </Form.Field>

            <Form.Select
              label="Delimiter"
              value={formdata.delimiter}
              options={DelimiterOptions}
              name="delimiter"
              onChange={onChangeDelimiter}
            />
          </Form.Group>

          {filePreview && (
            <>
              <Form.Checkbox
                label="First line contains a header?"
                checked={formdata.hasHeader}
                onChange={(_, { checked }) => setFormdata(prev => ({ ...prev, hasHeader: Boolean(checked) }))}
              />

              <div style={{ overflowX: 'scroll', marginBottom: '1rem' }}>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      {filePreview[0].map((_cell: string, i: number) => (
                        <Table.HeaderCell key={i}>
                          <Form.Select
                            upward={false}
                            options={fieldMappingOptions.filter(
                              o =>
                                o.value === '__skip__' ||
                                o.value === '__keep__' ||
                                o.value === formdata.fieldMappings[i] ||
                                formdata.fieldMappings.indexOf(o.value) === -1
                            )}
                            value={formdata.fieldMappings[i]}
                            onChange={onChangeFieldMapping(i)}
                            style={
                              formdata.fieldMappings[i] === '__skip__'
                                ? { background: 'transparent', color: 'rgba(0,0,0,0.25)' }
                                : formdata.fieldMappings[i] === '__keep__'
                                ? undefined
                                : {
                                    borderColor: 'rgba(44, 102, 45, 0.5)',
                                    background: '#fcfff5',
                                    color: '#2c662d',
                                  }
                            }
                          />
                        </Table.HeaderCell>
                      ))}
                    </Table.Row>
                    {formdata.hasHeader && (
                      <Table.Row>
                        {filePreview[0].map((_cell: string, i: number) => (
                          <Table.HeaderCell key={i}>{_cell}</Table.HeaderCell>
                        ))}
                      </Table.Row>
                    )}
                  </Table.Header>
                  <Table.Body>
                    {filePreview
                      .filter(
                        (r: string[], i) => r && r.length === filePreview[0].length && (i > 0 || !formdata.hasHeader)
                      )
                      .map((row: string[], rowIdx: number) => (
                        <Table.Row key={rowIdx}>
                          {row.map((cell: string, cellIdx: number) => (
                            <Table.Cell key={`${rowIdx}-${cellIdx}`}>{cell}</Table.Cell>
                          ))}
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <Form.TextArea
            label="Phone Numbers"
            placeholder="Please enter one per line"
            error={errors.phones}
            onChange={(_, { value }) => setFormdata(prev => ({ ...prev, phones: String(value) }))}
          />
          <Form.TextArea
            label="Email Address"
            placeholder="Please enter one per line"
            error={errors.emails}
            onChange={(_, { value }) => setFormdata(prev => ({ ...prev, emails: String(value) }))}
          />
        </>
      )}

      <ApiMessage data={apiMessage} />

      <Row>
        <Button fluid type="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="blue" fluid loading={bulkAddLoading}>
          Add to List
        </Button>
      </Row>
    </Form>
  );
};

export default BlacklistAddForm;
