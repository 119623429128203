import { useMutation, useQuery, useQueryClient } from 'react-query';

import { JobType, PaginatedResponse, Pipeline } from 'src/types';
import { ApiResponse, http } from '../http-common';

export type PipelineResponse = ApiResponse & {
  pipeline?: Pipeline;
};

export type PipelinesResponse = ApiResponse & {
  pipelines: PaginatedResponse<Pipeline>;
};

export type ListPipelinesFilters = {
  accountId?: number;
  enabled?: boolean;
  search?: string;
};

export type ListPipelinesParams = ListPipelinesFilters & {
  limit: number;
  offset: number;
};

export const useListPipelinesQuery = (params: ListPipelinesParams) => {
  return useQuery(['admin/pipelines', 'configs', params], async () => {
    const res = await http.get<PipelinesResponse>('/api/admin/pipelines/configs', { params });
    return res.data.pipelines;
  });
};

export const useGetPipelineQuery = (id: number) => {
  return useQuery(['admin/pipelines', 'configs', id], async () => {
    if (!id) return undefined;
    const res = await http.get<PipelineResponse>(`/api/admin/pipelines/configs/${id}`);
    return res.data.pipeline;
  });
};

export const useRunPipelineMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await http.post<ApiResponse>(`/api/admin/pipelines/configs/${id}/run`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['jobs', JobType.Pipeline]);
      },
    }
  );
};

export type AddPipelineParams = {
  accountId: number;
  name: string;
};

export const useAddPipelineMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: AddPipelineParams) => {
      const res = await http.post<PipelineResponse>(`/api/admin/pipelines/configs`, params);
      return res.data.pipeline;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/pipelines', 'configs']);
      },
    }
  );
};

export type SavePipelineParams = {
  pipeline: Pipeline;
};

export const useSavePipelineMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: SavePipelineParams) => {
      const res = await http.post<PipelineResponse>(`/api/admin/pipelines/configs/${params.pipeline.id}`, params);
      return res.data.pipeline;
    },
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries(['admin/pipelines', 'configs']);
      },
    }
  );
};

export const useDeletePipelineMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await http.delete<ApiResponse>(`/api/admin/pipelines/configs/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/pipelines', 'configs']);
      },
    }
  );
};

export type DuplicatePipelineInput = {
  pipelineId: number;
  accountId: number;
  name: string;
};

export const useDuplicatePipelineMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ pipelineId, ...input }: DuplicatePipelineInput) => {
      const res = await http.post<ApiResponse>(`/api/admin/pipelines/configs/${pipelineId}/duplicate`, input);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/pipelines', 'configs']);
      },
    }
  );
};
