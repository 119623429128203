import { Helmet } from 'react-helmet-async';

import PaginatedTable from 'src/components/PaginatedTable';
import { Container, Header, Row } from 'src/styles';
import AddFilter from './AddFilter';
import ListFiltersBody from './ListFiltersBody';

const FiltersList = () => {
  return (
    <Container>
      <Helmet>
        <title>Filters | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header style={{ marginBottom: 0 }}>Filters</Header>
        <AddFilter />
      </Row>

      <PaginatedTable headers={['Name', 'Type', '']} renderBody={ListFiltersBody} />
    </Container>
  );
};

export default FiltersList;
