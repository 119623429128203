import { useCallback, useState } from 'react';
import { Checkbox, Form } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { useSaveVoiceConfigMutation } from 'src/api/voice-configs';
import { Row } from 'src/styles';
import { VoiceConfig } from 'src/types';

type Props = {
  config: VoiceConfig;
};

type Formdata = {
  limit: string;
  enabled: boolean;
};

const VoiceConfigOutboundRateLimit = ({ config }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { data: user } = useGetUserProfileQuery();
  const voice = user?.active_account?.voice;
  const { isLoading, mutateAsync } = useSaveVoiceConfigMutation();
  const [formdata, setFormdata] = useState<Formdata>({
    limit: config.outbound.rate_limit_value > 0 ? String(config.outbound.rate_limit_value) : '',
    enabled: config.outbound.rate_limit_enabled || false,
  });

  const onSubmit = useCallback(
    async (formdata: Formdata) => {
      setApiMessage(undefined);
      try {
        config.outbound.rate_limit_value = Number(formdata.limit);
        config.outbound.rate_limit_enabled = formdata.enabled;
        await mutateAsync({ config });
      } catch (e: any) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [config, mutateAsync]
  );

  const onChangeEnabled = useCallback(
    (_, { checked }) => {
      setFormdata(prev => {
        const next = { ...prev, enabled: checked || false };
        onSubmit(next);
        return next;
      });
    },
    [onSubmit]
  );

  let defaultLimit = 60;
  if (voice?.outbound_limit_max) {
    defaultLimit = voice.outbound_limit_max;
  }
  if (voice?.outbound_limit) {
    defaultLimit = voice.outbound_limit;
  }

  return (
    <Form onSubmit={() => onSubmit(formdata)}>
      <Row style={{ alignItems: 'center', gap: '0.5rem' }}>
        <Checkbox toggle checked={formdata.enabled} onChange={onChangeEnabled} />

        <Form.Input
          disabled={!formdata.enabled}
          width={5}
          placeholder={defaultLimit}
          value={formdata.limit}
          onBlur={() => onSubmit(formdata)}
          onChange={(_, { value }) => setFormdata(prev => ({ ...prev, limit: value }))}
          loading={isLoading}
          error={apiMessage && apiMessage.message}
        />
      </Row>
    </Form>
  );
};

export default VoiceConfigOutboundRateLimit;
