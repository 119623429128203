import { Header, Label } from 'semantic-ui-react';

import { Row } from 'src/styles';
import { Account, AccountBillingConfig } from 'src/types';
import AccountBillingAutoPay from './AccountBillingAutoPay';
import AccountBillingDisableAutoPay from './AccountBillingDisableAutoPay';

type Props = {
  account: Account;
  billingConfig: AccountBillingConfig;
};

const AutoPay = ({ account: a, billingConfig }: Props) => {
  return (
    <>
      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header as="h4" style={{ marginBottom: 0 }}>
          AutoPay
        </Header>

        <Label color={a.billing[billingConfig].autopay.enabled ? 'green' : undefined}>
          {a.billing[billingConfig].autopay.enabled ? 'ON' : 'OFF'}
        </Label>
      </Row>

      <p>Enroll in AutoPay to prevent your balance from ever going below zero.</p>

      <Row>
        <AccountBillingAutoPay account={a} billingConfig={billingConfig} />
        {a.billing[billingConfig].autopay.enabled && (
          <AccountBillingDisableAutoPay account={a} billingConfig={billingConfig} />
        )}
      </Row>
    </>
  );
};

export default AutoPay;
