import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, Form, Grid, Header } from 'semantic-ui-react';

import { useUpdateAccountTelnyxMutation } from 'src/api/admin/account-telnyx';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Account } from 'src/types';

type Props = {
  account: Account;
};

type Formdata = {
  billing_group_id: string;
  purchase_limit: number;
};

type ValidationErrors = {
  billing_group_id?: string;
  purchase_limit?: string;
};

const getInitialFormdata = (account: Account): Formdata => {
  return {
    billing_group_id: account.telnyx_profile?.billing_group_id || '',
    purchase_limit: account.telnyx_profile?.purchase_limit || 0,
  };
};

const AdminAccountTelnyx = ({ account }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [formdata, setFormdata] = useState<Formdata>(() => getInitialFormdata(account));
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { mutateAsync, isLoading: mutationIsLoading } = useUpdateAccountTelnyxMutation();

  const validate = useCallback((input: Formdata): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onChange = useCallback((_, { name, value }) => setFormdata(prev => ({ ...prev, [name]: value })), []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    if (!isEmpty(validate(formdata))) {
      return;
    }

    try {
      await mutateAsync({ id: account.id, ...formdata });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [account.id, mutateAsync, formdata, validate]);

  return (
    <Form onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h4">Billing</Header>

            <Form.Input
              label="Billing Group ID"
              name="billing_group_id"
              value={formdata.billing_group_id}
              onChange={onChange}
              error={errors.billing_group_id}
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <Header as="h4">General Settings</Header>

            <Form.Input
              label="Purchase Limit"
              name="purchase_limit"
              type="number"
              min="0"
              value={formdata.purchase_limit}
              error={errors.purchase_limit}
              onChange={e => setFormdata(prev => ({ ...prev, purchase_limit: parseInt(e.target.value) }))}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Button color="blue" loading={mutationIsLoading}>
              Save
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default AdminAccountTelnyx;
