import { Button, Icon } from 'semantic-ui-react';

import { useDuplicateVoiceConfigMutation } from 'src/api/voice-configs';

type Props = {
  id: string;
};

const DuplicateVoiceConfig = ({ id }: Props) => {
  const { mutateAsync, isLoading } = useDuplicateVoiceConfigMutation();

  const onClick = function () {
    mutateAsync(id);
  };
  return (
    <Button title="Copy" icon color="green" onClick={onClick} loading={isLoading}>
      <Icon name="copy" />
    </Button>
  );
};

export default DuplicateVoiceConfig;
