import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';

import { useGetUserProfileQuery } from 'src/api/auth';
import { useCreateCnamTrustProductMutation } from 'src/api/auth/account-twilio';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';

type ValidationErrors = {
  friendlyName?: string;
  displayName?: string;
};

type Formdata = {
  friendlyName: string;
  displayName: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
};

const TrustHubCnamModal = ({ open, onClose }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [formdata, setFormdata] = useState<Formdata>({
    friendlyName: '',
    displayName: '',
  });
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { data: user } = useGetUserProfileQuery();
  const { mutateAsync, isLoading: createLoading } = useCreateCnamTrustProductMutation();

  const handleClose = useCallback(() => {
    setApiMessage(undefined);
    setFormdata({ friendlyName: '', displayName: '' });
    setErrors({});

    onClose();
  }, [onClose]);

  const validate = useCallback((input: Formdata): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    // if (!input.friendlyName) validationErrors.friendlyName = 'Required';
    if (!input.displayName) validationErrors.displayName = 'Required';

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onChange = useCallback((_, { name, value }) => {
    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    if (!isEmpty(validate(formdata))) return;

    try {
      const { reviewStatus } = await mutateAsync({
        friendlyName: formdata.friendlyName,
        displayName: formdata.displayName,
      });

      setApiMessage({
        success: true,
        status: 200,
        message: `Profile created successfully. Review status: ${reviewStatus}`,
      });
    } catch (e) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [formdata, mutateAsync, validate]);

  let friendlyName = '';
  const account = user?.active_account;
  if (account && account.name) {
    friendlyName = account.name;
  }
  const customerProfile = account?.business_profile;
  if (customerProfile && customerProfile.business_name) {
    friendlyName = customerProfile.business_name;
  }

  return (
    <Modal open={open} size="tiny" onClose={handleClose}>
      <Modal.Header>CNAM Registration</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        {apiMessage?.success ? (
          <Button type="button" onClick={handleClose} fluid color="green">
            Done
          </Button>
        ) : (
          <Form onSubmit={onSubmit}>
            <Form.Input
              name="friendlyName"
              label="Friendly Name"
              placeholder={friendlyName}
              value={formdata.friendlyName}
              onChange={onChange}
              error={errors.friendlyName}
            />

            <Form.Input
              name="displayName"
              label="Display Name"
              value={formdata.displayName}
              onChange={onChange}
              error={errors.displayName}
            />

            <Button fluid color="blue" loading={createLoading}>
              Create
            </Button>
          </Form>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default TrustHubCnamModal;
