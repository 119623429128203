import { Button, DropdownItemProps, Form, Icon } from 'semantic-ui-react';

import theme from 'src/styles/theme';
import { BigqueryFilter, ComparisonOperatorOptions } from 'src/types';

type Props = {
  columns: DropdownItemProps[];
  filters: BigqueryFilter[];
  addFilter: () => void;
  removeFilter: (id: string) => void;
  updateFilter: (id: string, filter: BigqueryFilter) => void;
};

const DatasetFilters = ({ columns, filters, addFilter, removeFilter, updateFilter }: Props) => {
  return (
    <>
      {filters.map(f => (
        <>
          <Form.Group key={f.id} widths={4}>
            <Form.Select
              fluid
              placeholder="Column"
              clearable
              options={columns}
              value={f.column}
              onChange={(_, { value }) => updateFilter(f.id, { ...f, column: value as string })}
            />

            <Form.Select
              fluid
              placeholder="Operator"
              clearable
              options={ComparisonOperatorOptions.filter(
                o => !['null', 'not_null', 'time_after', 'time_before'].includes(o.value)
              )}
              value={f.operator}
              onChange={(_, { value }) => updateFilter(f.id, { ...f, operator: value as string })}
            />

            {f.operator !== 'null' && (
              <Form.Input
                fluid
                placeholder="Value(s)"
                value={f.value || ''}
                onChange={(_, { value }) => updateFilter(f.id, { ...f, value })}
              />
            )}

            <Button type="button" color="red" icon onClick={() => removeFilter(f.id)}>
              <Icon name="trash" />
            </Button>
          </Form.Group>

          {(f.operator === 'in' || f.operator === 'not_in') && (
            <p style={{ color: theme.gray, marginTop: '-0.75rem' }}>Separate multiple values with a comma.</p>
          )}
        </>
      ))}

      <Button
        type="button"
        compact
        size="mini"
        color="blue"
        style={{ marginBottom: '1rem' }}
        onClick={() => addFilter()}
      >
        <Icon name="plus" /> Add Filter
      </Button>
    </>
  );
};

export default DatasetFilters;
