import { useMemo } from 'react';
import { DropdownItemProps, Form, Message } from 'semantic-ui-react';

import { useListBlacklistsQuery } from 'src/api/blacklists';
import { SendgridInboundParseActionTrigger } from 'src/types';

const betaUrl = process.env.REACT_APP_BETA_CLIENT_URL;

type Props = {
  trigger: SendgridInboundParseActionTrigger;
  onChange: ({ name, value }: { name: string; value: string }) => void;
};

const TriggerDsBlacklistSettings = ({ trigger: action, onChange }: Props) => {
  const { data, isLoading } = useListBlacklistsQuery({ limit: 100, offset: 0 });

  const dsBlacklistOptions = useMemo<DropdownItemProps[]>(() => {
    if (isLoading) return [{ key: 'loading', value: '', text: 'Loading...', disabled: true }];
    if (!data?.data) return [{ key: 'not-found', value: '', text: 'No results found', disabled: true }];
    return data.data.filter(b => b.hash !== null).map(b => ({ key: b.id, value: String(b.hash), text: b.name }));
  }, [data?.data, isLoading]);

  return (
    <>
      <Form.Select
        label="Datascore Blacklist to add the lead to"
        name="blacklist_hash"
        options={dsBlacklistOptions}
        onChange={(_, { name, value }) => onChange({ name, value: String(value) })}
        loading={isLoading}
        value={action.payload.blacklist_hash || ''}
        // error={dsBlacklistOptions.length === 1 && dsBlacklistOptions[0].disabled}
      />
      {dsBlacklistOptions.length === 1 && dsBlacklistOptions[0].disabled && (
        <Message error visible>
          <Message.Header>No blacklists found</Message.Header>
          <Message.Content>
            Have you created any <a href={`${betaUrl}/blacklists`}>Blacklists</a> yet?
          </Message.Content>
        </Message>
      )}
    </>
  );
};

export default TriggerDsBlacklistSettings;
