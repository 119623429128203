import { Table } from 'semantic-ui-react';

import { Account } from 'src/types';
import RemoveBigqueryTableAccess from './RemoveBigqueryTableAccess';

type Props = {
  account: Account;
};

const AdminAccountBigqueryTablesListBody = ({ account }: Props) => {
  if (account.bigquery_table_access === null) return null;

  return (
    <>
      {account.bigquery_table_access.map(aa => {
        return (
          <Table.Row key={aa.table.id}>
            <Table.Cell>{aa.table.name}</Table.Cell>
            <Table.Cell>{aa.table.table_id}</Table.Cell>
            <Table.Cell textAlign="right" collapsing>
              <RemoveBigqueryTableAccess accountId={account.id} tableId={aa.table.id} />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
};

export default AdminAccountBigqueryTablesListBody;
