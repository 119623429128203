import { useQuery } from 'react-query';

import { DatasetsResponse } from '../datasets';
import { http } from '../http-common';

export type ListDatasetsParams = {
  limit: number;
  offset: number;
  accountId?: number;
};

export const useListDatasetsQuery = (params: ListDatasetsParams) => {
  return useQuery(['admin/datasets', params], async () => {
    const res = await http.get<DatasetsResponse>('/api/admin/datasets-v2', { params });
    return res.data.datasets;
  });
};
