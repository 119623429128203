import { useCallback, useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { useListAccountsQuery } from 'src/api/admin/accounts';
import { DuplicateLeadscoreInput, useDuplicateLeadscoreConfigMutation } from 'src/api/admin/leadscore-plus';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Note, Row } from 'src/styles';
import { LeadscoreConfig } from 'src/types';

type Props = {
  leadscore: LeadscoreConfig;
};

const AdminDuplicateLeadscoreConfig = ({ leadscore }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync, isLoading } = useDuplicateLeadscoreConfigMutation();
  const { data: accounts, isLoading: accountsLoading } = useListAccountsQuery({
    limit: 1000,
    offset: 0,
    enabled: true,
  });
  const [formdata, setFormdata] = useState<DuplicateLeadscoreInput>({
    leadscoreId: leadscore.id,
    accountId: -1,
    name: '',
  });
  const [error, setError] = useState<string | null>(null);

  const onClose = useCallback(() => {
    setApiMessage(undefined);
    setOpen(false);
  }, []);

  const onSubmit = useCallback(async (): Promise<void> => {
    setApiMessage(undefined);
    setError(null);

    if (formdata.accountId < 0) {
      setError('Please select an account');
      return;
    }

    try {
      await mutateAsync(formdata);
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [formdata, mutateAsync, onClose]);

  const accountOptions = [
    { key: 0, text: 'System', value: 0 },
    ...(accounts?.data.map(a => ({ key: a.id, text: a.name, value: a.id })) || []),
  ];

  if (apiMessage) {
    return (
      <Button type="button" color="red">
        {apiMessage.message}
      </Button>
    );
  }

  return (
    <Modal
      size="mini"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={onClose}
      trigger={
        <Button color="green" icon title="Duplicate">
          <Icon name="copy" />
        </Button>
      }
    >
      <Modal.Header>Duplicate LeadScore</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Note>Which account would you like to clone this leadscore to?</Note>

          <Form.Select
            placeholder="Select an account"
            loading={accountsLoading}
            label="Account"
            value={formdata.accountId >= 0 ? formdata.accountId : ''}
            options={accountOptions}
            onChange={(_, { value }) => setFormdata(prev => ({ ...prev, accountId: value as number }))}
            error={error}
          />

          <Form.Input
            label="Rename LeadScore?"
            placeholder={`${leadscore.name} (Copy)`}
            value={formdata.name}
            onChange={(_, { value }) => setFormdata(prev => ({ ...prev, name: value }))}
          />

          <Row>
            <Button fluid type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button color="green" fluid loading={isLoading}>
              Clone
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AdminDuplicateLeadscoreConfig;
