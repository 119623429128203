import { useMemo, useState } from 'react';
import { Button, Icon, Label, Modal, Table } from 'semantic-ui-react';

import { useGetFeedQuery } from 'src/api/feeds';
import { JobStatus, ScheduleJob, ScheduleJobPayload, ThresholdRange } from 'src/types';
import EngagementCountsChart from './EngagementCountsChart';
import { LookaheadOptions } from './ScheduleImportForm';
import ScheduleTable from './ScheduleTable';
import ThresholdCountsChart from './ThresholdCountsChart';

const betaUrl = process.env.REACT_APP_BETA_URL;

const getLookaheadText = (lookahead: number) => LookaheadOptions.filter(({ value }) => value === lookahead)[0].text;

const getInitialRangeState = (payload: ScheduleJobPayload): ThresholdRange => {
  const range: ThresholdRange = {
    start: 0.0,
    total: payload.predicted_num_rows || 0,
  };

  if (typeof payload.threshold_counts !== 'undefined') {
    range.end = Number(Object.keys(payload.threshold_counts).pop()) + 0.1;
  }

  return range;
};

const ScheduleJobDetailsModal = ({ objectid: modelId, id: jobId, status, queued, payload }: ScheduleJob) => {
  const { data } = useGetFeedQuery(payload.feed_id || 0);
  const [range, setRange] = useState<ThresholdRange>(() => getInitialRangeState(payload));

  const [start, end] = useMemo<[number, number]>(() => {
    if (typeof range.start === 'undefined' || typeof range.end === 'undefined') return [0, 1];
    return [Number(range.start.toFixed(1)), Number(range.end.toFixed(1))];
  }, [range]);

  return (
    <Modal
      trigger={
        <Button title="View Details" icon>
          <Icon name="eye" />
        </Button>
      }
    >
      <Modal.Header>Job Details</Modal.Header>
      <Modal.Content>
        {status === JobStatus.Error && typeof payload.error !== 'undefined' && payload.error.length > 0 && (
          <Label color="red">
            Error
            <Label.Detail>{payload.error}</Label.Detail>
          </Label>
        )}

        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{queued}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Model ID</Table.Cell>
              <Table.Cell>{payload.model_id}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Start Date</Table.Cell>
              <Table.Cell>{payload.start_date}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Lookahead</Table.Cell>
              <Table.Cell>{getLookaheadText(payload.lookahead)}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Hourly Capacity</Table.Cell>
              <Table.Cell>{payload.hourly_capacity}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Schedule</Table.Cell>
              <Table.Cell>
                <ScheduleTable schedule={payload.schedule} />
              </Table.Cell>
            </Table.Row>

            {payload.feed_id && (
              <Table.Row>
                <Table.Cell>Feed ID</Table.Cell>
                <Table.Cell style={{ position: 'relative' }}>
                  <Label>
                    ID
                    <Label.Detail>{payload.feed_id}</Label.Detail>
                  </Label>
                  {data && data.feed && (
                    <>
                      <Label>
                        Name
                        <Label.Detail>{data.feed.name}</Label.Detail>
                      </Label>
                      <Label>
                        URL
                        <Label.Detail>{data.feed.url}</Label.Detail>
                      </Label>
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
            )}

            {payload.job_id && (
              <Table.Row>
                <Table.Cell>Databricks Job</Table.Cell>
                <Table.Cell>
                  <Label>
                    Job
                    <Label.Detail>{payload.job_id}</Label.Detail>
                  </Label>

                  {payload.run_id && (
                    <Label>
                      Run
                      <Label.Detail>{payload.run_id}</Label.Detail>
                    </Label>
                  )}
                </Table.Cell>
              </Table.Row>
            )}

            <Table.Row>
              <Table.Cell>Raw Data</Table.Cell>
              <Table.Cell>
                <a
                  href={`${betaUrl}/api/models/${modelId}/scheduler/download?job_id=${jobId}&stage=raw`}
                  style={{ marginRight: '1rem' }}
                >
                  <Icon name="download" /> {payload.raw_gs_path.slice(payload.raw_gs_path.indexOf('0-raw'))}
                </a>
                <Label>
                  {payload.raw_num_rows} <Label.Detail>Rows</Label.Detail>
                </Label>
              </Table.Cell>
            </Table.Row>

            {typeof payload.parsed_gs_path !== 'undefined' && (
              <Table.Row>
                <Table.Cell>Parsed Data</Table.Cell>
                <Table.Cell>
                  <a
                    href={`${betaUrl}/api/models/${modelId}/scheduler/download?job_id=${jobId}&stage=parsed`}
                    style={{ marginRight: '1rem' }}
                  >
                    <Icon name="download" /> {payload.parsed_gs_path.slice(payload.parsed_gs_path.indexOf('1-parsed'))}
                  </a>
                  {typeof payload.parsed_num_rows !== 'undefined' && (
                    <Label>
                      {payload.parsed_num_rows} <Label.Detail>Rows</Label.Detail>
                    </Label>
                  )}
                </Table.Cell>
              </Table.Row>
            )}

            {typeof payload.predicted_gs_path !== 'undefined' && (
              <Table.Row>
                <Table.Cell>Predicted Data</Table.Cell>
                <Table.Cell>
                  <a
                    href={`${betaUrl}/api/models/${modelId}/scheduler/download?job_id=${jobId}`}
                    style={{ marginRight: '1rem' }}
                  >
                    <Icon name="download" />{' '}
                    {payload.predicted_gs_path.slice(payload.predicted_gs_path.indexOf('2-predictions'))}
                  </a>
                  {typeof payload.predicted_num_rows !== 'undefined' && (
                    <Label>
                      {payload.predicted_num_rows} <Label.Detail>Rows</Label.Detail>
                    </Label>
                  )}
                </Table.Cell>
              </Table.Row>
            )}

            {typeof payload.threshold_counts !== 'undefined' && (
              <>
                <Table.Row>
                  <Table.Cell>Threshold Counts</Table.Cell>
                  <Table.Cell>
                    <div style={{ height: 300 }}>
                      <ThresholdCountsChart
                        thresholdCounts={payload.threshold_counts}
                        range={range}
                        setRange={setRange}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    Row Count with Threshold:
                    <br />
                    {start} - {end}
                  </Table.Cell>
                  <Table.Cell>{range.total}</Table.Cell>
                </Table.Row>
              </>
            )}

            {typeof payload.engagement_counts !== 'undefined' && (
              <Table.Row>
                <Table.Cell>Threshold Counts</Table.Cell>
                <Table.Cell>
                  <div style={{ height: 300 }}>
                    <EngagementCountsChart engagementCounts={payload.engagement_counts} start={start} end={end} />
                  </div>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>

        {typeof payload.predicted_gs_path !== 'undefined' && (
          <Button as="a" color="blue" href={`${betaUrl}/api/models/${modelId}/scheduler/download?job_id=${jobId}`}>
            Download All Predictions
          </Button>
        )}

        {typeof payload.threshold_counts !== 'undefined' && (
          <Button
            as="a"
            color="blue"
            href={`${betaUrl}/api/models/${modelId}/scheduler/download?job_id=${jobId}&start=${start}&end=${end}`}
          >
            Download {range.total} Rows Between Threshold: {start} - {end}
          </Button>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default ScheduleJobDetailsModal;
