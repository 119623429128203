import { useCallback, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Card, Form, Image, Loader, Message } from 'semantic-ui-react';

import { useStorageUploadPublicFileMutation } from 'src/api/storage';
import { DropArea } from './styles';

type Props = {
  fileType?: 'audio' | 'image';
  label?: string;
  name: string;
  onChange: (e: any, data: { name: string; value: string }) => void;
  preview?: boolean;
  value?: string;
};

const PublicFileUploader = ({ value, label, onChange, name, preview = true, fileType }: Props) => {
  const { mutateAsync, isLoading } = useStorageUploadPublicFileMutation();
  const [error, setError] = useState<string | undefined>();

  const onDrop = useCallback(
    async files => {
      const [f] = files;
      if (!f) {
        return;
      }

      setError(undefined);

      try {
        const res = await mutateAsync({ file: f });
        if (!res.url) {
          setError('URL not found in response');
          return;
        }

        onChange(null, { name, value: res.url });
      } catch (e: any) {
        setError(e.message);
      }
    },
    [mutateAsync, name, onChange]
  );

  const dropzoneOpts: DropzoneOptions = { onDrop };
  if (fileType === 'audio') {
    // https://www.twilio.com/docs/voice/twiml/play#supported-audio-file-types
    dropzoneOpts.accept = [
      '.mp3',
      '.mpeg',
      'audio/mpeg',

      '.wav',
      'audio/wav',
      'audio/wave',
      'audio/x-wav',

      '.aif',
      '.aiff',
      '.aifc',
      'audio/aiff',
      'audio/x-aifc',
      'audio/x-aiff',

      '.gsm',
      'audio/x-gsm',
      'audio/gsm',

      '.ulaw',
      'audio/ulaw',
    ];
  }

  const d1 = useDropzone(dropzoneOpts);

  return (
    <Form.Field style={{ marginTop: '-0.5rem' }}>
      {label && <label>{label}</label>}

      {error && (
        <Message error visible>
          <strong>{error}</strong> Please try again
        </Message>
      )}

      <DropArea {...d1.getRootProps()} active={d1.isDragActive} error={error !== null}>
        <input {...d1.getInputProps()} />
        {isLoading ? (
          <Loader inline active />
        ) : d1.isDragActive ? (
          <p>Drop a file here OR click to select one</p>
        ) : (
          <p>Drop a file here OR click to select one</p>
        )}
      </DropArea>

      {preview && fileType === 'image' && value && (
        <Card>
          <Image src={value} size="medium" />
        </Card>
      )}

      {preview && fileType === 'audio' && value && (
        <audio src={value} preload="metadata" controls style={{ display: 'block', marginTop: '0.5rem' }} />
      )}
    </Form.Field>
  );
};

export default PublicFileUploader;
