import { useCallback, useRef, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';

import { useSaveWebhookConfigMutation } from 'src/api/admin/webhooks';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { WebhookConfig } from 'src/types';

type Props = {
  config: WebhookConfig;
};

const AdminToggleWebhookConfigEnabledButton = ({ config }: Props) => {
  const modalRef = useRef(null);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync, isLoading } = useSaveWebhookConfigMutation();

  const onCancel = useCallback(() => {
    if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
      // @ts-ignore
      modalRef.current.handleClose();
    }
  }, []);

  const onCloseModal = useCallback(() => {
    setApiMessage(undefined);
  }, []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    try {
      await mutateAsync({ config: { ...config, enabled: !config.enabled } });

      if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
        // @ts-ignore
        modalRef.current.handleClose();
      }
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [mutateAsync, config]);

  return (
    <Modal
      size="mini"
      ref={modalRef}
      trigger={
        <Button type="button" color={config.enabled ? 'green' : 'red'} size="tiny">
          {config.enabled ? 'Enabled' : 'Disabled'}
        </Button>
      }
      onClose={onCloseModal}
    >
      <Modal.Header>{config.enabled ? 'Disable' : 'Enabled'} config</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <p style={{ marginTop: 0 }}>
            <strong>Are you sure?</strong> This action cannot be undone.
          </p>

          <Row>
            <Button fluid type="button" onClick={onCancel}>
              Cancel
            </Button>
            <Button color={config.enabled ? 'red' : 'green'} fluid loading={isLoading}>
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AdminToggleWebhookConfigEnabledButton;
