import { FC } from 'react';
import { Divider, Header, Placeholder } from 'semantic-ui-react';
import styled from 'styled-components';

export const GridLayout = styled.div`
  display: grid;
  grid-template-areas:
    'admin admin admin admin admin'
    'general v2 inbound v3 outbound';
  grid-template-columns: 1fr auto 1fr auto 1fr;
  grid-template-rows: auto auto;
  gap: 1rem;
`;

export const Layout: FC = ({ children }) => {
  return (
    <GridLayout>
      {children}

      <Divider className="full" vertical style={{ gridArea: 'v2', position: 'relative' }} />

      <Divider className="full" vertical style={{ gridArea: 'v3', position: 'relative' }} />
    </GridLayout>
  );
};

export const PlaceholderItem = ({ small }: { small?: boolean }) => (
  <Placeholder fluid>
    <Placeholder.Header image>
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder.Header>

    {!small && (
      <>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>

        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>

        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </>
    )}
  </Placeholder>
);

export const PlaceholderLayout = () => {
  return (
    <Layout>
      <div style={{ gridArea: 'general' }}>
        <Header>General</Header>
        <PlaceholderItem />
      </div>

      <div style={{ gridArea: 'inbound' }}>
        <Header>Inbound</Header>
        <PlaceholderItem />
      </div>

      <div style={{ gridArea: 'outbound' }}>
        <Header>Outbound</Header>
        <PlaceholderItem />
      </div>
    </Layout>
  );
};
