import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Label, Table } from 'semantic-ui-react';

import { useListConvosoFilesQuery } from 'src/api/convoso';
import { RenderProps } from 'src/components/PaginatedTable';
import { ConvosoFileStatus } from 'src/types';
import { getConvosoFileStatusColor } from 'src/utils';

const ListConvosoFilesBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: files } = useListConvosoFilesQuery({ limit, offset: limit * (currentPage - 1) });

  useEffect(() => {
    let count = 1;
    if (typeof files !== 'undefined') {
      count = Math.ceil(files.total / limit);
    }
    setPageCount(count);
  }, [files, limit, setPageCount]);

  return files?.data.map(f => (
    <Table.Row key={f.id}>
      <Table.Cell>
        <Link to={`/convoso/files/${f.id}`}>{f.id}</Link>
      </Table.Cell>
      <Table.Cell>
        <Link to={`/convoso/files/${f.id}`}>{f.filename}</Link>
      </Table.Cell>
      <Table.Cell>{f.created}</Table.Cell>
      <Table.Cell>
        <Label color={getConvosoFileStatusColor(f.status)}>{ConvosoFileStatus[f.status]}</Label>
      </Table.Cell>
      {/* <Table.Cell textAlign="right" collapsing>
        <Row>
          <EditFile onClose={reloadFiles} file={f} />
          <DeleteFile onClose={reloadFiles} id={f.id} />
        </Row>
      </Table.Cell> */}
    </Table.Row>
  ));
};

export default ListConvosoFilesBody;
