export const Colors = [
  '#2E48B4',
  '#008D8F',
  '#3EAD66',
  '#5E9603',
  '#F2B705',
  '#D97904',
  '#D92B04',
  '#8C0E03',
  '#991670',
  '#6B1799',
];
