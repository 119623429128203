import _sortBy from 'lodash/sortBy';
import { useMemo, useState } from 'react';
import { Placeholder, Table } from 'semantic-ui-react';

import { Row } from 'src/styles';
import { Colors } from './config';

export type CarrierData = { [key: string]: any } & StrictCarrierData;

type StrictCarrierData = {
  name: string;
  '0 - 10s': number;
  '10 - 120s': number;
  '> 120s': number;
  '> 300s': number;
  total: number;
};

type SortableTableProps = {
  carrierData: CarrierData[];
  loading?: boolean;
  maxCount: number;
};

const SortableCarrierTable = ({ carrierData, loading = false, maxCount }: SortableTableProps) => {
  const [direction, setDirection] = useState<'ascending' | 'descending' | undefined>('descending');
  const [column, setColumn] = useState<string | null>('total');

  const data = useMemo(() => {
    const sortedData = _sortBy(carrierData, [column]);
    if (direction === 'ascending') {
      return sortedData;
    }
    return sortedData.reverse();
  }, [carrierData, column, direction]);

  const updateSort = (col: string) => () => {
    setDirection(column === col ? (direction === 'ascending' ? 'descending' : 'ascending') : direction);
    setColumn(col);
  };

  return (
    <Table compact="very" striped sortable selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell collapsing>Carrier Name</Table.HeaderCell>
          <Table.HeaderCell sorted={column === '0 - 10s' ? direction : undefined} onClick={updateSort('0 - 10s')}>
            0 - 10s
          </Table.HeaderCell>
          <Table.HeaderCell sorted={column === '10 - 120s' ? direction : undefined} onClick={updateSort('10 - 120s')}>
            10 - 120s
          </Table.HeaderCell>
          <Table.HeaderCell sorted={column === '> 120s' ? direction : undefined} onClick={updateSort('> 120s')}>
            &gt; 120s
          </Table.HeaderCell>
          <Table.HeaderCell sorted={column === '> 300s' ? direction : undefined} onClick={updateSort('> 300s')}>
            &gt; 300s
          </Table.HeaderCell>
          <Table.HeaderCell width={8} sorted={column === 'total' ? direction : undefined} onClick={updateSort('total')}>
            Total
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {loading
          ? Array(10)
              .fill(0)
              .map((_, i) => (
                <Table.Row key={`r${i}`}>
                  {Array(6)
                    .fill(0)
                    .map((_, j) => (
                      <Table.Cell key={`r${i}-c${j}`}>
                        <Placeholder>
                          <Placeholder.Line />
                        </Placeholder>
                      </Table.Cell>
                    ))}
                </Table.Row>
              ))
          : data.map((row, i) => (
              <Table.Row key={row.name}>
                <Table.Cell>
                  <span style={{ whiteSpace: 'nowrap', opacity: !row.name ? 0.3 : undefined }}>
                    {row.name || 'None'}
                  </span>
                </Table.Cell>
                <Table.Cell>
                  <span style={{ opacity: row['0 - 10s'] === 0 ? 0.3 : undefined }}>{row['0 - 10s']}</span>
                </Table.Cell>
                <Table.Cell>
                  <span style={{ opacity: row['10 - 120s'] === 0 ? 0.3 : undefined }}>{row['10 - 120s']}</span>
                </Table.Cell>
                <Table.Cell>
                  <span style={{ opacity: row['> 120s'] === 0 ? 0.3 : undefined }}>{row['> 120s']}</span>
                </Table.Cell>
                <Table.Cell>
                  <span style={{ opacity: row['> 300s'] === 0 ? 0.3 : undefined }}>{row['> 300s']}</span>
                </Table.Cell>
                <Table.Cell>
                  <Row style={{ alignItems: 'center', gap: 6 }}>
                    <span
                      style={{
                        display: 'inline-block',
                        height: 20,
                        width: `${(row.total / maxCount) * 100}%`,
                        backgroundColor: Colors[i % Colors.length],
                      }}
                    ></span>
                    {row.total}
                  </Row>
                </Table.Cell>
              </Table.Row>
            ))}
      </Table.Body>
    </Table>
  );
};

export default SortableCarrierTable;
