import { useMutation, useQuery, useQueryClient } from 'react-query';

import { BigqueryTable, BigqueryTableSettings, Delimiter, PaginatedResponse } from 'src/types';
import { ApiResponse, http } from './http-common';

export type BigqueryTableResponse = ApiResponse & {
  table?: BigqueryTable;
};

export type BigqueryTablesResponse = ApiResponse & {
  tables: PaginatedResponse<BigqueryTable>;
};

export type BigqueryTableIngestedCountResponse = ApiResponse & {
  count?: number;
};

export type ListBigqueryTablesParams = {
  limit: number;
  offset: number;
  includeColumns?: boolean;
};

export const useListBigqueryTablesQuery = (params: ListBigqueryTablesParams) => {
  return useQuery(
    ['bigquery/tables', params],
    async () => {
      const res = await http.get<BigqueryTablesResponse>('/api/bigquery/tables', { params });
      return res.data.tables;
    },
    { refetchInterval: 5000 }
  );
};

export const useGetBigqueryTablesIngestedCountQuery = (id: number) => {
  return useQuery(
    ['bigquery/tables', id, 'ingested'],
    async () => {
      if (!id) return;

      const res = await http.get<BigqueryTableIngestedCountResponse>(`/api/bigquery/tables/${id}/ingested`);
      return res.data.count;
    },
    { refetchInterval: 5000 }
  );
};

export const useGetBigqueryTableQuery = (id: number) => {
  return useQuery(['bigquery/tables', id], async () => {
    if (!id) return;

    const res = await http.get<BigqueryTableResponse>(`/api/bigquery/tables/${id}`);
    return res.data.table;
  });
};

export type AddBigqueryTableParams = {
  name: string;
};

export const useAddBigqueryTableMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: AddBigqueryTableParams) => {
      const res = await http.post<BigqueryTableResponse>(`/api/bigquery/tables`, params);
      return res.data.table;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bigquery/tables']);
      },
    }
  );
};

export type AddDatasetColumnParams = {
  name: string;
  type: string;
  id: number;
};

export const useAddDatasetColumnMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: AddDatasetColumnParams) => {
      const res = await http.post<BigqueryTableResponse>(`/api/bigquery/tables/${params.id}/column`, params);
      return res.data.table;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bigquery/tables']);
      },
    }
  );
};

export type DeleteDatasetColumnParams = {
  name: string;
  id: number;
};

export const useDeleteDatasetColumnMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: DeleteDatasetColumnParams) => {
      const res = await http.delete<BigqueryTableResponse>(`/api/bigquery/tables/${params.id}/column`, {
        data: params,
      });
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bigquery/tables']);
      },
    }
  );
};

export type DatasetUploadFormdata = {
  file?: File;
  delimiter: Delimiter;
  fieldMappings: string[];
  hasHeader: boolean;
};

export type DatasetUploadInput = DatasetUploadFormdata & {
  id: number;
};

export type DatasetUploadResponse = ApiResponse & {
  tmpRawPath: string;
};

export const useDatasetUploadMutation = () => {
  return useMutation(async (input: DatasetUploadInput) => {
    if (!input.file) {
      const err = {
        response: {
          status: 422,
          statusText: 'Unprocessable Entity',
          data: { success: false, errors: [{ error: 'file is required' }] },
        },
      };
      throw err;
    }

    const fd = new FormData();
    fd.append('file', input.file);
    fd.append('delimiter', input.delimiter);
    fd.append('field_mappings', input.fieldMappings.join(','));
    fd.append('has_header', input.hasHeader ? '1' : '0');

    const res = await http.post<DatasetUploadResponse>(`/api/bigquery/tables/${input.id}/upload`, fd);
    return res.data;
  }, {});
};

export const useDatasetSettingsUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: { id: number; settings: BigqueryTableSettings }) => {
      const res = await http.put<ApiResponse>(`/api/bigquery/tables/${params.id}`, { settings: params.settings });
      return res.data.success;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bigquery/tables']);
      },
    }
  );
};

export const useDeleteDatasetMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await http.delete<ApiResponse>(`/api/bigquery/tables/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bigquery/tables']);
      },
    }
  );
};
