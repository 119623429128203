import axios, { AxiosError } from 'axios';

const { REACT_APP_BETA_URL: betaURL } = process.env;

export type ApiResponse = {
  success: boolean;
  message?: string;
  errors?: APIValidationError[];
};

export type ApiMessageData = { success: boolean; status?: number; message?: string | string[] };

export const apiErrorHandler = (e: any, setMessage?: (m: ApiMessageData) => void): ApiMessageData => {
  const err: AxiosError<ApiResponse> = e;

  if (err.response) {
    const { data, status, statusText } = err.response;

    let message: string | string[] = statusText;
    if (data && data.message) {
      message = data.message;
    }

    // Unprocessable Entity
    if (status === 422 && typeof data.errors !== 'undefined' && data.errors.length > 0) {
      message = data.errors.map(error => error.error);
    }

    const msgData = { success: false, status, message };
    setMessage && setMessage(msgData);
    return msgData;
  }

  const msgData = { success: false, status: 400, message: 'Unknown API Error Occurred' };
  setMessage && setMessage(msgData);
  return msgData;
};

export type APIValidationError = {
  field: string;
  error: string;
};

export const http = axios.create({
  baseURL: betaURL,
  withCredentials: true,
  headers: {
    'Content-type': 'application/json',
  },
});
