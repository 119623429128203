import { useCallback, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';

import { useUpdateAkkioModelMutation } from 'src/api/admin/leadscore-plus';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { AkkioModel } from 'src/types';

type Props = {
  model: AkkioModel;
};

const ToggleAkkioModelEnabledButton = ({ model }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync, isLoading } = useUpdateAkkioModelMutation();

  const onClose = useCallback(() => {
    setApiMessage(undefined);
    setOpen(false);
  }, []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    try {
      await mutateAsync({ id: model.id, enabled: !model.enabled });
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [mutateAsync, model.id, model.enabled, onClose]);

  return (
    <Modal
      size="mini"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={onClose}
      trigger={
        <Button type="button" color={model.enabled ? 'green' : 'red'} size="tiny">
          {model.enabled ? 'Enabled' : 'Disabled'}
        </Button>
      }
    >
      <Modal.Header>{model.enabled ? 'Disable' : 'Enabled'} config</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <p style={{ marginTop: 0 }}>
            <strong>Are you sure?</strong> This action cannot be undone.
          </p>

          <Row>
            <Button fluid type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button color={model.enabled ? 'red' : 'green'} fluid loading={isLoading}>
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default ToggleAkkioModelEnabledButton;
