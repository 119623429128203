import { useMutation, useQuery, useQueryClient } from 'react-query';

import { PaginatedResponse, Schedule } from 'src/types';
import { ApiResponse, http } from './http-common';

export type ScheduleResponse = ApiResponse & {
  schedule?: Schedule;
};

export type SchedulesResponse = ApiResponse & {
  schedules: PaginatedResponse<Schedule>;
};

export type ListSchedulesParams = {
  limit: number;
  offset: number;
};

export const useListSchedulesQuery = (params: ListSchedulesParams) => {
  return useQuery(['schedules', params], async () => {
    const res = await http.get<SchedulesResponse>('/api/schedules', { params });
    return res.data.schedules;
  });
};

export const useGetScheduleQuery = (id: number) => {
  return useQuery(['schedules', id], async () => {
    if (!id) return undefined;
    const res = await http.get<ScheduleResponse>(`/api/schedules/${id}`);
    return res.data.schedule;
  });
};

export type AddScheduleParams = {
  schedule: Schedule;
};

export const useAddScheduleMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: AddScheduleParams) => {
      const res = await http.post<ScheduleResponse>(`/api/schedules`, params);
      return res.data.schedule;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['schedules']);
      },
    }
  );
};

export type SaveScheduleInput = {
  schedule: Schedule;
};

export const useSaveScheduleMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: SaveScheduleInput) => {
      const res = await http.post<ScheduleResponse>(`/api/schedules/${input.schedule.id}`, input);
      return res.data.schedule;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['schedules']);
      },
    }
  );
};
