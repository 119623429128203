import { useCallback } from 'react';
import { Button } from 'semantic-ui-react';

import { useRemoveModelFromAccountMutation } from 'src/api/admin/accounts';

type Props = {
  accountId: number;
  modelId: number;
};

const RemoveModel = ({ accountId, modelId }: Props) => {
  const { isLoading, mutateAsync } = useRemoveModelFromAccountMutation();

  const onClick = useCallback(async () => {
    await mutateAsync({ accountId, modelIds: [modelId] });
  }, [accountId, modelId, mutateAsync]);

  return <Button icon="trash" color="red" onClick={onClick} loading={isLoading} />;
};

export default RemoveModel;
