import { useEffect } from 'react';

import { AdminGetAccountsReportingParams } from 'src/api/admin/account-reporting';
import { ListAccountsFilters, useListAccountsQuery } from 'src/api/admin/accounts';
import { RenderProps } from 'src/components/PaginatedTable';
import AdminAccountsReportingRow from './AdminAccountsReportingRow';

type Props = RenderProps & {
  filters?: ListAccountsFilters;
  statsFilters: AdminGetAccountsReportingParams;
};

const AdminAccountsReportingBody = ({ limit, currentPage, setPageCount, filters, statsFilters }: Props) => {
  const { data: accounts } = useListAccountsQuery({ limit, offset: limit * (currentPage - 1), ...filters });

  useEffect(() => {
    let count = 1;
    if (typeof accounts !== 'undefined') {
      count = Math.ceil(accounts.total / limit);
    }
    setPageCount(count);
  }, [accounts, limit, setPageCount]);

  return (
    <>
      {accounts?.data.map(a => (
        <AdminAccountsReportingRow key={a.id} account={a} filters={statsFilters} />
      ))}
    </>
  );
};

export default AdminAccountsReportingBody;
