import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Tos from './pages/public/Tos';
import AuthAcceptInvite from './pages/user/auth/accept-invite';
import AuthForgotPassword from './pages/user/auth/forgot-password';
import AuthLogin from './pages/user/auth/login';
import AuthResetPassword from './pages/user/auth/reset-password';

const AuthRoutes = () => {
  const { pathname } = useLocation();
  return (
    <Switch>
      <Route exact path="/tos" component={Tos} />

      <Route exact path="/auth/accept-invite/:token" component={AuthAcceptInvite} />
      <Route exact path="/auth/forgot-password" component={AuthForgotPassword} />
      <Route exact path="/auth/reset-password/:token" component={AuthResetPassword} />
      <Route exact path="/auth/login" component={AuthLogin} />

      <Redirect path="*" to={{ pathname: '/auth/login', state: { redirect: pathname } }} />
    </Switch>
  );
};

export default AuthRoutes;
