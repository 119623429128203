import { isEmpty } from 'lodash';
import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { Form, InputOnChangeData, Modal } from 'semantic-ui-react';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { ConvertPipelineFiltersToSetParams, useConvertPipelineFiltersToSetMutation } from 'src/api/pipelines';
import ApiMessage from 'src/components/ApiMessage';
import { Note } from 'src/styles';

type ValidationErrors = {
  name?: string;
};

type Props = {
  disabled?: boolean;
  pipelineID: number;
};

const ConvertPipelineFiltersToSetModal = ({ disabled, pipelineID }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { mutateAsync, isLoading } = useConvertPipelineFiltersToSetMutation();
  const [formdata, setFormdata] = useState<ConvertPipelineFiltersToSetParams>({
    pipelineID,
    name: '',
  });

  const onChange = useCallback((_: ChangeEvent<HTMLInputElement>, { name, value }: InputOnChangeData) => {
    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  const onClose = useCallback(() => {
    setApiMessage(undefined);
    setOpen(false);
    setFormdata({ pipelineID, name: '' });
    setErrors({});
  }, [pipelineID]);

  const onSubmit = useCallback(
    async (evt: FormEvent<HTMLFormElement>) => {
      setApiMessage(undefined);

      const validationErrors: ValidationErrors = {};

      if (formdata.name === '') {
        validationErrors.name = 'Name is required';
      }

      if (!isEmpty(validationErrors)) {
        setErrors(validationErrors);
        return;
      }

      try {
        await mutateAsync(formdata);
        onClose();
      } catch (e: any) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [formdata, mutateAsync, onClose]
  );

  return (
    <Modal
      size="mini"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Form.Button disabled={disabled} type="button">
          Create Set
        </Form.Button>
      }
    >
      <Modal.Header>Convert Current Filters to Filter Set</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Note>
            The existing filters on this Pipeline will be converted to a Filter Set. The Pipeline's filters will then be
            removed from the Pipeline and instead live only inside the Filter Set.
          </Note>

          <Form.Input label="Name" error={errors.name} name="name" onChange={onChange} />

          <Form.Button loading={isLoading} color="blue" fluid>
            Create
          </Form.Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default ConvertPipelineFiltersToSetModal;
