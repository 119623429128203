import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  Delimiter,
  PaginatedResponse,
  TelnyxAvailablePhone,
  TelnyxAvailablePhoneSearchType,
  TelnyxPhone,
  TelnyxPhonesRefetchJob,
  TelnyxTexmlApp,
} from 'src/types';
import { ApiResponse, http } from '../http-common';

export type ReleaseTelnyxPhonesParams = {
  sids: string[];
};

export const useReleaseTelnyxPhonesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: ReleaseTelnyxPhonesParams) => {
      const res = await http.delete<ApiResponse>(`/api/auth/account/telnyx/phones`, { params });
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'telnyx/phones']);
      },
    }
  );
};

export type ProvisionTelnyxAvailablePhonesParams = {
  phoneNumbers: string[];
  voiceConfigID: string;
};

export const useProvisionTelnyxAvailablePhonesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: ProvisionTelnyxAvailablePhonesParams) => {
      const res = await http.post<ApiResponse>(`/api/auth/account/telnyx/available-phones`, params);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'telnyx/phones']);
      },
    }
  );
};

export type GetTelnyxAvailablePhonesParams = {
  limit: number;
  search: string;
  searchType: TelnyxAvailablePhoneSearchType;
};

export type GetTelnyxAvailablePhonesResponse = {
  phones: TelnyxAvailablePhone[];
};

export const useGetTelnyxAvailablePhonesMutation = () => {
  return useMutation(async (params: GetTelnyxAvailablePhonesParams) => {
    const res = await http.get<GetTelnyxAvailablePhonesResponse>(`/api/auth/account/telnyx/available-phones`, {
      params,
    });
    return res.data;
  }, {});
};

export type GetPostalCodeStatsInput = {
  mode: 'file' | 'text';
  // file inputs
  file?: File;
  delimiter: Delimiter;
  fieldMappings: string[];
  hasHeader: boolean;
  // text inputs
  zips: string;
};

export type CityCounts = { [city: string]: { count: number; latLng: number[] } };

export type PostalCodeStats = {
  raw: number;
  unique: number;
  valid: number;
  cities: number;
  byCity: CityCounts;
  states: number;
  byState: { [state: string]: number };
};

export type GetPostalCodeStatsResponse = ApiResponse & {
  stats: PostalCodeStats;
};

export const useGetPostalCodeStatsMutation = () => {
  return useMutation(async (input: GetPostalCodeStatsInput) => {
    if (input.mode === 'file' && !input.file) {
      const err = {
        response: {
          status: 422,
          statusText: 'Unprocessable Entity',
          data: { success: false, errors: [{ error: 'file is required' }] },
        },
      };
      throw err;
    }

    const fd = new FormData();
    fd.append('mode', input.mode);
    if (typeof input.file !== 'undefined') {
      fd.append('file', input.file);
    }
    fd.append('delimiter', input.delimiter);
    fd.append('field_mappings', input.fieldMappings.join(','));
    fd.append('has_header', input.hasHeader ? '1' : '0');
    fd.append('zips', input.zips);

    const res = await http.post<GetPostalCodeStatsResponse>(`/api/auth/account/telnyx/postal-code-stats`, fd);
    return res.data.stats;
  }, {});
};

export type PurchaseData = {
  city?: string;
  state: string;
  count: number;
  latLng?: number[];
};

export type PurchasePostalCodesInput = {
  group: 'city' | 'state';
  webhookUrl: string;
  counts: PurchaseData[];
};

export type PurchasePostalCodesResponse = ApiResponse & {
  purchaseCounts: { [key: string]: number };
};

export const usePurchasePostalCodesMutation = () => {
  return useMutation(async (input: PurchasePostalCodesInput) => {
    const res = await http.post<PurchasePostalCodesResponse>(`/api/auth/account/telnyx/purchase-postal-codes`, input);
    return res.data.purchaseCounts;
  }, {});
};

export type GetTelnyxPhonesResponse = ApiResponse & {
  phones: PaginatedResponse<TelnyxPhone>;
};

export type GetTelnyxPhonesParams = {
  limit: number;
  offset: number;
  search: string;
  startDate?: string;
  endDate?: string;
  profiles?: string[];
};

export const useGetTelnyxPhonesQuery = (params: GetTelnyxPhonesParams) => {
  return useQuery(
    ['auth/account', 'telnyx/phones', params],
    async () => {
      const res = await http.get<GetTelnyxPhonesResponse>(`/api/auth/account/telnyx/phones`, {
        params,
      });
      return res.data;
    },
    { retry: false }
  );
};

type GetTelnyxJobsRecentResponse = {
  job?: TelnyxPhonesRefetchJob;
};

export const useGetTelnyxJobsRecentQuery = () => {
  const queryClient = useQueryClient();

  return useQuery(
    ['auth/account', 'telnyx/jobs'],
    async () => {
      const res = await http.get<GetTelnyxJobsRecentResponse>(`/api/auth/account/telnyx/jobs/recent`);
      return res.data.job;
    },
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'telnyx/phones']);
      },
    }
  );
};

export const useRefetchTelnyxPhonesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const res = await http.post<ApiResponse>(`/api/auth/account/telnyx/jobs/phones-refetch`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'telnyx/jobs']);
      },
    }
  );
};

export type ReassignTelnyxPhonesVoiceConfigParams = {
  sids: string[];
  configID: string;
};

export const useReassignTelnyxPhonesVoiceConfigMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: ReassignTelnyxPhonesVoiceConfigParams) => {
      const res = await http.post<ApiResponse>(`/api/auth/account/telnyx/jobs/phones-reassign-voice-config`, data);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['auth/account', 'telnyx/jobs']);
      },
    }
  );
};

export type GetTelnyxTotalPhoneCountResponse = ApiResponse & {
  count: number;
};

export const useGetTelnyxTotalIncomingPhoneCountQuery = () => {
  return useQuery(
    ['auth/account', 'telnyx/phones', 'count'],
    async () => {
      const res = await http.get<GetTelnyxTotalPhoneCountResponse>(`/api/auth/account/telnyx/phones/count`);
      return res.data;
    },
    { retry: false }
  );
};

export type GetTelnyxTexmlAppsResponse = {
  apps?: TelnyxTexmlApp[];
};

export const useGetTelnyxTexmlAppsQuery = () => {
  return useQuery(
    ['auth/account', 'telnyx/texml-apps'],
    async () => {
      const res = await http.get<GetTelnyxTexmlAppsResponse>(`/api/auth/account/telnyx/texml-apps`);
      return res.data;
    },
    {}
  );
};
