import { useMutation, useQuery, useQueryClient } from 'react-query';

import { PaginatedResponse, PipelineFilterSet } from 'src/types';
import { ApiResponse, http } from './http-common';

export type PipelineFilterSetResponse = ApiResponse & {
  set?: PipelineFilterSet;
};

export type PipelineFilterSetsResponse = ApiResponse & {
  sets: PaginatedResponse<PipelineFilterSet>;
};

export type ListPipelineFilterSetsParams = {
  limit: number;
  offset: number;
};

export const useListPipelineFilterSetsQuery = (params: ListPipelineFilterSetsParams) => {
  return useQuery(['pipelines', 'filter-sets', params], async () => {
    const res = await http.get<PipelineFilterSetsResponse>('/api/pipelines/filter-sets', { params });
    return res.data.sets;
  });
};

export const useGetPipelineFilterSetQuery = (id: number) => {
  return useQuery(['pipelines', 'filter-sets', id], async () => {
    if (!id) return undefined;
    const res = await http.get<PipelineFilterSetResponse>(`/api/pipelines/filter-sets/${id}`);
    return res.data.set;
  });
};

export type AddPipelineFilterSetParams = {
  name: string;
  fitlers: PipelineFilterSet[];
};

export const useAddPipelineFilterSetMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: AddPipelineFilterSetParams) => {
      const res = await http.post<PipelineFilterSetResponse>(`/api/pipelines/filter-sets`, params);
      return res.data.set;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['pipelines', 'filter-sets']);
      },
    }
  );
};

export type SavePipelineFilterSetParams = {
  set: PipelineFilterSet;
};

export const useSavePipelineFilterSetMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: SavePipelineFilterSetParams) => {
      const res = await http.post<PipelineFilterSetResponse>(`/api/pipelines/filter-sets/${params.set.id}`, params);
      return res.data.set;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['pipelines', 'filter-sets']);
      },
    }
  );
};

export const useDeletePipelineFilterSetMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await http.delete<ApiResponse>(`/api/pipelines/filter-sets/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['pipelines', 'filter-sets']);
      },
    }
  );
};
