import { FC, useCallback, useState } from 'react';

import {
  NodeOverlayContent,
  NodeOverlayDesription,
  NodeOverlayHeader,
  NodeOverlayTitle,
  OverlayContainer,
} from './styles';

type NodeOverlayComponents = {
  Header: FC;
  Title: FC;
  Description: FC;
  Content: FC;
};

const NodeOverlay: FC & NodeOverlayComponents = ({ children }) => <OverlayContainer>{children}</OverlayContainer>;

NodeOverlay.Header = NodeOverlayHeader;
NodeOverlay.Title = NodeOverlayTitle;
NodeOverlay.Description = NodeOverlayDesription;
NodeOverlay.Content = NodeOverlayContent;

export default NodeOverlay;

export const useOverlay = (defaultState = false) => {
  const [isOpen, setIsOpen] = useState(defaultState);

  const toggle = useCallback(() => {
    setIsOpen(prev => !prev);
  }, [setIsOpen]);

  return {
    isOpen,
    setIsOpen,
    toggle,
  } as const;
};
