import { parseInt } from 'lodash';
import { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Button, DropdownItemProps, Form, Grid, Input, Label, Modal } from 'semantic-ui-react';

import { useUpdateDataflowMutation } from 'src/api/dataflows';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { Dataflow, DataflowSettings, WeekdayLongName } from 'src/types';
import { FlowHeaderTitle } from './styles';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  dataflow: Dataflow;
};

const SCHEDULE_OPTIONS: DropdownItemProps[] = [
  { key: 'daily', value: 'daily', text: 'Day(s)' },
  { key: 'weekly', value: 'weekly', text: 'Week(s)' },
  // { key: 'monthly', value: 'monthly', text: 'Month(s)' },
];

const DAYS_OF_THE_MONTH: DropdownItemProps[] = [
  { key: '1', value: '1', text: '1st' },
  { key: '15', value: '15', text: '15th' },
  { key: '27', value: '27', text: '27th' },
];

const DAYS_OF_THE_WEEK: { key: WeekdayLongName; text: string }[] = [
  { key: 'Sunday', text: 'S' },
  { key: 'Monday', text: 'M' },
  { key: 'Tuesday', text: 'T' },
  { key: 'Wednesday', text: 'W' },
  { key: 'Thursday', text: 'T' },
  { key: 'Friday', text: 'F' },
  { key: 'Saturday', text: 'S' },
];

const getDataflowSettingsInitialState = (dataflow: Dataflow): DataflowSettings => {
  const currentHour = new Date();
  currentHour.setMinutes(0, 0, 0);

  const settings: DataflowSettings = {
    name: dataflow.name || '',
    schedule_enabled: dataflow.schedule_enabled || false,
    schedule: {
      repetition: {
        type: 'daily',
        hour: currentHour,
        interval: 1,
      },
      ends: {
        option: 'never',
        // on: new Date(),
        // after: 0,
      },
    },
  };

  if (!dataflow || !dataflow.schedule) {
    return settings;
  }

  if (dataflow.schedule.repetition) {
    settings.schedule.repetition = {
      ...dataflow.schedule.repetition,
      hour: dataflow.schedule.repetition.hour ? new Date(dataflow.schedule.repetition.hour) : currentHour,
    };
  }

  if (dataflow.schedule.ends) {
    settings.schedule.ends = {
      ...dataflow.schedule.ends,
      on: dataflow.schedule.ends.on ? new Date(dataflow.schedule.ends.on) : undefined,
    };
  }

  return settings;
};

const Settings = ({ open, setOpen, dataflow }: Props) => {
  const { mutateAsync, isLoading } = useUpdateDataflowMutation();
  const [formdata, setFormdata] = useState<DataflowSettings>(() => getDataflowSettingsInitialState(dataflow));
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();

  const onSubmit = useCallback(async () => {
    if (!dataflow.id) return;

    try {
      await mutateAsync({ dataflowId: dataflow.id, ...formdata });
      setOpen(false);
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [dataflow.id, formdata, setOpen, mutateAsync]);

  const onChangeEnds = useCallback((_, { value }) => {
    if (typeof value !== 'string') return;
    if (value !== 'never' && value !== 'on' && value !== 'after') return;

    setFormdata(prev => ({
      ...prev,
      schedule: { ...prev.schedule, ends: { ...prev.schedule.ends, option: value } },
    }));
  }, []);

  const onChangeEnabled = useCallback((_, { checked }) => {
    setFormdata(prev => ({
      ...prev,
      schedule_enabled: checked,
    }));
  }, []);

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<FlowHeaderTitle>{dataflow.name}</FlowHeaderTitle>}
    >
      <Modal.Header>Flow Settings</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Form.Input
            label="Name"
            type="text"
            value={formdata.name}
            onChange={(_, { value }) => setFormdata(prev => ({ ...prev, name: value }))}
          />

          <Row style={{ justifyContent: 'space-between' }}>
            <h3>Schedule</h3>
            <Form.Checkbox
              toggle
              name="schedule_enabled"
              checked={formdata.schedule_enabled}
              onChange={onChangeEnabled}
            />
          </Row>

          <div style={{ marginBottom: '2rem', opacity: !formdata.schedule_enabled ? 0.25 : undefined }}>
            <Form.Field inline>
              <DatePicker
                selected={formdata.schedule.repetition.hour}
                onChange={date => {
                  if (date === null) return;

                  setFormdata(prev => ({
                    ...prev,
                    schedule: { ...prev.schedule, repetition: { ...prev.schedule.repetition, hour: date } },
                  }));
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                timeCaption="Time"
                dateFormat="h:mm aa"
                popperPlacement="bottom"
                customInput={<Input label="At" labelPosition="left" icon="clock" />}
              />
            </Form.Field>

            <Form.Group>
              <Form.Field inline>
                <Input
                  min="1"
                  type="number"
                  label="Every"
                  labelPosition="left"
                  value={formdata.schedule.repetition.interval}
                  onChange={(_, { value }) => {
                    setFormdata(prev => ({
                      ...prev,
                      schedule: {
                        ...prev.schedule,
                        repetition: { ...prev.schedule.repetition, interval: parseInt(value) },
                      },
                    }));
                  }}
                />
              </Form.Field>

              <Form.Select
                options={SCHEDULE_OPTIONS}
                value={formdata.schedule.repetition.type}
                onChange={(_, { value }) => {
                  setFormdata(prev => ({
                    ...prev,
                    schedule: {
                      ...prev.schedule,
                      repetition: { ...prev.schedule.repetition, type: value as string },
                    },
                  }));
                }}
              />
            </Form.Group>

            {formdata.schedule.repetition.type === 'weekly' && (
              <Form.Field>
                <label>On</label>
                <Form.Group grouped>
                  {DAYS_OF_THE_WEEK.map(day => (
                    <Label
                      style={{ cursor: 'pointer' }}
                      key={day.key}
                      circular
                      color={formdata.schedule.repetition.weekday === day.key ? 'blue' : undefined}
                      onClick={() => {
                        setFormdata(prev => ({
                          ...prev,
                          schedule: {
                            ...prev.schedule,
                            repetition: {
                              ...prev.schedule.repetition,
                              weekday: day.key,
                            },
                          },
                        }));
                      }}
                    >
                      {day.text}
                    </Label>
                  ))}
                </Form.Group>
              </Form.Field>
            )}

            {formdata.schedule.repetition.type === 'monthly' && (
              <>
                <h4>Repeat on</h4>

                <Form.Select
                  width="1"
                  options={DAYS_OF_THE_MONTH}
                  value={formdata.schedule.repetition.dayOfTheMonth || ''}
                  onChange={(_, { value }) => {
                    setFormdata(prev => ({
                      ...prev,
                      schedule: {
                        ...prev.schedule,
                        repetition: { ...prev.schedule.repetition, dayOfTheMonth: value as string },
                      },
                    }));
                  }}
                />
              </>
            )}

            <h4>Ends</h4>
            <Form.Group grouped>
              <Grid columns="6">
                <Grid.Row>
                  <Grid.Column>
                    <Form.Radio
                      label="Never"
                      name="ends"
                      value="never"
                      checked={formdata.schedule.ends.option === 'never'}
                      onChange={onChangeEnds}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Form.Radio
                      label="On"
                      name="ends"
                      value="on"
                      checked={formdata.schedule.ends.option === 'on'}
                      onChange={onChangeEnds}
                    />
                  </Grid.Column>

                  <Grid.Column width={8}>
                    <DatePicker
                      onChange={daterange => {
                        setFormdata(prev => ({
                          ...prev,
                          schedule: { ...prev.schedule, ends: { ...prev.schedule.ends, on: daterange || undefined } },
                        }));
                      }}
                      disabled={formdata.schedule.ends.option !== 'on'}
                      selected={formdata.schedule.ends.on || new Date()}
                      customInput={<Input icon="calendar" />}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    <Form.Radio
                      label="After"
                      name="ends"
                      value="after"
                      checked={formdata.schedule.ends.option === 'after'}
                      onChange={onChangeEnds}
                    />
                  </Grid.Column>

                  <Grid.Column>
                    <Input
                      min="1"
                      type="number"
                      label="occurrences"
                      labelPosition="right"
                      value={formdata.schedule.ends.after || 0}
                      disabled={formdata.schedule.ends.option !== 'after'}
                      onChange={(_, { value }) => {
                        setFormdata(prev => ({
                          ...prev,
                          schedule: { ...prev.schedule, ends: { ...prev.schedule.ends, after: parseInt(value) } },
                        }));
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form.Group>
          </div>

          <Row>
            <Button type="button" fluid onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button color="blue" fluid loading={isLoading}>
              Save
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default Settings;
