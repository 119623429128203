import { useCallback, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';

import { useUpdateAccountMutation } from 'src/api/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { Account, AccountBillingConfig } from 'src/types';

type Props = {
  account: Account;
  billingConfig: AccountBillingConfig;
};

const AccountBillingDisableAutoPay = ({ account, billingConfig }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync, isLoading } = useUpdateAccountMutation();

  const onClose = useCallback(() => {
    setApiMessage(undefined);
    setOpen(false);
  }, []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    try {
      account.billing[billingConfig].autopay.enabled = false;
      await mutateAsync({ id: account.id, billing: account.billing });
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }

    setTimeout(onClose, 3000);
  }, [account.billing, account.id, billingConfig, mutateAsync, onClose]);

  return (
    <Modal
      size="mini"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button type="button" fluid>
          Disable AutoPay
        </Button>
      }
    >
      <Modal.Header>Disable AutoPay</Modal.Header>
      <Modal.Content>
        <Form onSubmit={onSubmit}>
          <ApiMessage data={apiMessage} />

          <p style={{ marginTop: 0 }}>
            <strong>Are you sure?</strong> You can always opt back in.
          </p>

          <Row>
            <Button fluid type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button color="red" fluid loading={isLoading}>
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AccountBillingDisableAutoPay;
