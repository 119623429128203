import styled from 'styled-components';

export const AddPaymentMethodButton = styled.button`
  border: 2px dashed #eee;
  background: transparent;
  border-radius: 8px;
  cursor: pointer;
  color: ${p => p.theme.primary};
  width: 100%;
  height: 100%;
  min-height: 60px;

  &:hover {
    background: rgba(89, 106, 240, 0.05);
  }
`;
