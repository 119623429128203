import 'react-datepicker/dist/react-datepicker.css';

import { delay } from 'lodash';
import { FocusEvent, useCallback, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { DropdownItemProps, Form, Icon } from 'semantic-ui-react';

import { useGetWidgetDataQuery, WidgetType } from 'src/api/dataflows';
import Filters from '../../components/Filters';
import NodeOverlay from '../../components/NodeOverlay';
import { OverlayProps } from '../../types';
import { BigQueryData } from '../types';

const RELATIVE_TIME_RANGES: { [key: string]: { label: string; startDate: number; endDate: number } } = {
  last_24_hours: {
    label: 'Last 24 hours',
    startDate: 86400,
    endDate: 0,
  },
  previous_24_hours: {
    label: 'Previous 24 hours',
    startDate: 172800,
    endDate: 86400,
  },
  last_7_days: {
    label: 'Last 7 days',
    startDate: 604800,
    endDate: 0,
  },
  previous_7_days: {
    label: 'Previous 7 days',
    startDate: 1209600,
    endDate: 604800,
  },
  last_30_days: {
    label: 'Last 30 days',
    startDate: 2592000,
    endDate: 0,
  },
  previous_30_days: {
    label: 'Previous 30 days',
    startDate: 5184000,
    endDate: 2592000,
  },
};

const RELATIVE_TIME_RANGE_OPTIONS: DropdownItemProps[] = Object.keys(RELATIVE_TIME_RANGES).map(key => ({
  key,
  value: key,
  text: RELATIVE_TIME_RANGES[key].label,
}));

const BigQueryOverlay = ({ node, updateNode, onSave }: OverlayProps<BigQueryData>) => {
  const { data } = node;

  const [startDate, endDate] = useMemo(
    () => data.daterange?.map(date => (date ? new Date(date) : undefined)) || [],
    [data.daterange]
  );

  const saveOnStateUpdate = useCallback(
    // INFO: This delay is needed to because setNodes is async but we can not wait for it to finish
    () => delay(() => onSave('state-updated'), 100),
    [onSave]
  );

  const { data: widgetData } = useGetWidgetDataQuery(WidgetType.BigQuery);

  const tables = useMemo(() => {
    if (!widgetData) return [];

    return widgetData.map(({ table_id, name }) => ({ key: table_id, value: table_id, text: name }));
  }, [widgetData]);

  const columns: DropdownItemProps[] = useMemo(() => {
    if (!widgetData || !data.table) return [];

    const dataset = widgetData.find(({ table_id }) => table_id === data.table);

    const datasetColumns =
      dataset?.columns?.map(({ name }) => ({
        key: name,
        value: name,
        text: name,
        selected: false,
      })) || [];

    return datasetColumns;
  }, [widgetData, data.table]);

  useEffect(
    () => {
      if (columns.length === 0) return;
      if (typeof data.datasetColumns !== 'undefined' && data.datasetColumns.length > 0) return;

      const selectedColumns = columns.reduce((acc, { key, selected }) => {
        if (selected) acc.push(key);
        return acc;
      }, [] as string[]);

      if (
        (typeof data.datasetColumns === 'undefined' || data.datasetColumns.length === 0) &&
        selectedColumns.length > 0
      ) {
        updateNode(node.id, { datasetColumns: selectedColumns });
      }
    },
    // NOTE: We only need to run this effect once, after we have the columns data
    // eslint-disable-next-line
    [columns]
  );

  useEffect(() => {
    const newColumns = columns.filter(({ key }) => data?.datasetColumns?.includes(key));

    updateNode(node.id, { datasetColumnsOutput: newColumns });
  }, [columns, data?.datasetColumns, node.id, updateNode]);

  return (
    <NodeOverlay>
      <NodeOverlay.Header>
        <Icon name="searchengin" color="violet" size="large" />

        <NodeOverlay.Title>BigQuery</NodeOverlay.Title>
      </NodeOverlay.Header>

      <NodeOverlay.Description>Load data from BigQuery table.</NodeOverlay.Description>

      <NodeOverlay.Content>
        <Form>
          <Form.Input
            label="Name"
            type="text"
            defaultValue={data.name}
            onBlur={(event: FocusEvent<HTMLInputElement>) => {
              const value = event.target.value;

              updateNode(node.id, { name: value });
              saveOnStateUpdate();
            }}
          />

          <Form.Dropdown
            label="Table"
            options={tables}
            search
            selection
            value={data.table}
            onChange={(_, { value }) => {
              updateNode(node.id, { table: String(value) });
              saveOnStateUpdate();
            }}
          />

          <Form.Dropdown
            label="Columns"
            multiple
            search
            disabled={columns.length === 0}
            clearable
            selection
            options={columns}
            value={data.datasetColumns || []}
            onChange={(_, { value }) => {
              updateNode(node.id, { datasetColumns: value as string[] });
              saveOnStateUpdate();
            }}
          />

          <h3>Schedule</h3>

          <Form.Checkbox
            toggle
            label="Use fixed date range"
            checked={data.hasFixedDateRange}
            onChange={(_, { checked }) => {
              updateNode(node.id, {
                hasFixedDateRange: checked,
              });
              saveOnStateUpdate();
            }}
          />

          {data.hasFixedDateRange ? (
            <DatePicker
              selectsRange
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              // TODO: this line is causing the following warning:
              // "Warning: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?"
              customInput={<Form.Input label="Fixed Time Range" icon="calendar" />}
              popperPlacement="bottom"
              onChange={daterange => {
                updateNode(node.id, { daterange });
                saveOnStateUpdate();
              }}
            />
          ) : (
            <Form.Dropdown
              label="Relative Time Range"
              options={RELATIVE_TIME_RANGE_OPTIONS}
              value={data.relativeDateRange?.key}
              selection
              onChange={(_, data) => {
                const key = data.value as string;
                updateNode(node.id, {
                  relativeDateRange: {
                    ...RELATIVE_TIME_RANGES[key],
                    key,
                  },
                });
                saveOnStateUpdate();
              }}
            />
          )}

          <h3>Filters</h3>

          <Filters
            filters={data.filters}
            columns={columns}
            table={data.table}
            updateNode={updateNode}
            node={node}
            onSave={saveOnStateUpdate}
          />
        </Form>
      </NodeOverlay.Content>
    </NodeOverlay>
  );
};

export default BigQueryOverlay;
