import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, DropdownProps, Form, Icon, InputOnChangeData, Modal } from 'semantic-ui-react';

import { useListAccountsQuery } from 'src/api/admin/accounts';
import { AddWebhookConfigParams, useAddWebhookConfigMutation } from 'src/api/admin/webhooks';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Note } from 'src/styles';

type ValidationErrors = {
  accountId?: string;
  name?: string;
};

const AdminAddWebhookConfig = () => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { data: accounts, isLoading: accountsLoading } = useListAccountsQuery({
    limit: 1000,
    offset: 0,
    enabled: true,
  });
  const { mutateAsync, isLoading } = useAddWebhookConfigMutation();
  const [formdata, setFormdata] = useState<AddWebhookConfigParams>({
    accountId: -1,
    name: '',
  });

  const onChange = useCallback((_, { name, value }: InputOnChangeData | DropdownProps) => {
    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
    setFormdata({ name: '', accountId: -1 });
    setErrors({});
  }, []);

  const onSubmit = async () => {
    const validationErrors: ValidationErrors = {};

    if (formdata.name === '') {
      validationErrors.name = 'Name is required';
    }

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors);
      return;
    }

    try {
      setApiMessage(undefined);
      await mutateAsync(formdata);
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };
  const accountOptions = [
    { key: 0, text: 'System', value: 0 },
    ...(accounts?.data.map(a => ({ key: a.id, text: a.name, value: a.id })) || []),
  ];

  return (
    <Modal
      size="mini"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button floated="right" color="blue">
          <Icon name="plus" />
          Add Webhook
        </Button>
      }
    >
      <Modal.Header>Add Webhook</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Note>Which account would you like to add this webhook to?</Note>

          <Form.Select
            search
            clearable
            placeholder="Select an account"
            loading={accountsLoading}
            label="Account"
            name="accountId"
            value={formdata.accountId >= 0 ? formdata.accountId : ''}
            options={accountOptions}
            onChange={onChange}
            error={errors.accountId}
          />

          <Form.Input label="Webhook Name" error={errors.name} name="name" onChange={onChange} />

          <Form.Button loading={isLoading} color="blue" fluid>
            Create
          </Form.Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AdminAddWebhookConfig;
