import { useCallback, useRef, useState } from 'react';
import { Button, Form, Modal, Progress } from 'semantic-ui-react';

import { useReleaseBandwidthPhonesMutation } from 'src/api/auth/account-bandwidth';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';

type Props = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  sids: string[];
};

const BandwidthReleasePhonesModal = ({ open, onClose, onSuccess, sids }: Props) => {
  const modalRef = useRef(null);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [releasing, setReleasing] = useState<string[]>([]);
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState({ amount: 0, total: 0 });
  const { mutateAsync: releasePhones, isLoading: releaseLoading } = useReleaseBandwidthPhonesMutation();

  const onCancel = useCallback(() => {
    if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
      // @ts-ignore
      modalRef.current.handleClose();
      onClose();
    }
  }, [onClose]);

  const onCloseModal = () => {
    setApiMessage(undefined);
    setShowProgress(false);
    setProgress({ amount: 0, total: 0 });
    setReleasing([]);
    onClose();
  };

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    try {
      setShowProgress(true);

      const total = sids.length;
      setProgress({ total, amount: 0 });

      setReleasing(sids);

      const toRelease = [...sids];
      while (toRelease.length > 0) {
        const batch = toRelease.splice(0, 10);
        await releasePhones({ phones: batch });
        setProgress(prev => ({ total, amount: prev.amount + batch.length }));
      }

      onSuccess();
      onCancel();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [onCancel, onSuccess, releasePhones, sids]);

  return (
    <Modal size="mini" open={open} ref={modalRef} onClose={onCloseModal}>
      <Modal.Header>Release {Math.max(sids.length, releasing.length)} Active Phone Numbers</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <p style={{ marginTop: 0 }}>
            <strong>Are you sure?</strong> This action cannot be undone.
          </p>

          {showProgress && (
            <Progress percent={(progress.amount / progress.total) * 100} active color="green">
              {progress.amount} / {progress.total} Deleted
            </Progress>
          )}

          <Row>
            <Button fluid type="button" onClick={onCancel}>
              Cancel
            </Button>
            <Button color="red" fluid loading={releaseLoading}>
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default BandwidthReleasePhonesModal;
