import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, PaymentIntent } from '@stripe/stripe-js';
import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Button, Divider, Form, Grid, Icon, Label, Loader, Message, Modal, Segment, Table } from 'semantic-ui-react';

import {
  useAccountBillingCancelPaymentIntentMutation,
  useAccountBillingPaymentIntentMutation,
} from 'src/api/auth/account-billing';
import { useGetAccountBillingDetailsQuery } from 'src/api/billing';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { BillingPaymentAmounts } from 'src/config';
import PaymentButton from 'src/pages/user/models/payment/PaymentButton';
import { Account, AccountBillingConfig } from 'src/types';
import { calculatePaymentAmount } from 'src/utils';
import PaymentMethod from './PaymentMethod';

type ValidationErrors = {
  amount?: string;
};

type Props = {
  account: Account;
  billingConfig: AccountBillingConfig;
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AccountBillingAddFunds = ({ account, billingConfig }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData | undefined>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [amount, setAmount] = useState<string>('');
  const [clientSecret, setClientSecret] = useState<string>('');
  const [paymentIntentId, setPaymentIntentId] = useState<string>('');
  const [selectedPaymentMethodID, setSelectedPaymentMethodID] = useState<string>('');
  const { data: paymentMethods, isLoading: paymentMethodsLoading } = useGetAccountBillingDetailsQuery();
  const { mutateAsync: paymentIntent, isLoading: intentIsLoading } = useAccountBillingPaymentIntentMutation();
  const { mutateAsync: cancelIntent } = useAccountBillingCancelPaymentIntentMutation();
  const queryClient = useQueryClient();

  const onClose = useCallback(() => {
    setApiMessage(undefined);
    setErrors({});
    setAmount('');
    setSelectedPaymentMethodID('');
    setPaymentIntentId('');
    setClientSecret('');
    setOpen(false);

    if (paymentIntentId !== '') {
      cancelIntent({ paymentIntentId });
    }

    queryClient.invalidateQueries(['auth/account']);
    queryClient.invalidateQueries(['auth/profile']);
  }, [cancelIntent, paymentIntentId, queryClient]);

  const validate = useCallback((input: string) => {
    const validationErrors: ValidationErrors = {};

    if (Number.isNaN(Number(input))) {
      validationErrors.amount = 'invalid number';
    } else if (Number(input) <= 0) {
      validationErrors.amount = 'please select an amount';
    }
    // else if (Number(input) % 500 !== 0) {
    //   validationErrors.amount = 'amount must be divisible by 500';
    // }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onChange = useCallback(
    (_, { value }) => {
      setAmount(value);
      setSelectedPaymentMethodID('');
      setClientSecret('');
      setPaymentIntentId('');
      validate(value);

      if (paymentIntentId !== '') {
        cancelIntent({ paymentIntentId });
      }
    },
    [cancelIntent, paymentIntentId, validate]
  );

  const selectPaymentMethod = useCallback(
    (id: string) => async () => {
      setApiMessage(undefined);
      setSelectedPaymentMethodID(id);

      if (id === '' && paymentIntentId !== '') {
        cancelIntent({ paymentIntentId });
      }

      if (!id || Number.isNaN(Number(amount)) || Number(amount) < 500) return;

      try {
        const data = await paymentIntent({
          action: `add-funds-${billingConfig}`,
          paymentMethodId: id,
          amount: Number(amount),
        });
        setClientSecret(data.clientSecret);
        setPaymentIntentId(data.paymentIntentId);
      } catch (e: any) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [amount, billingConfig, cancelIntent, paymentIntent, paymentIntentId]
  );

  const onSubmit = useCallback(async (pi?: PaymentIntent) => {
    setApiMessage(undefined);

    if (!pi) {
      setApiMessage({ success: false, status: 400, message: 'Invalid or missing payment intent.' });
      return;
    }

    // CC payments are processed immediately and should result in the "succeeded" status
    // ACH payments take a few days to process and should result in the "processing" status
    if (pi.status !== 'succeeded' && pi.status !== 'processing') {
      // console.log(pi);
      setApiMessage({ success: false, status: 400, message: `Payment failed. ${pi.description}` });
      return;
    }

    setApiMessage({ success: true, status: 200, message: 'Thank you! You payment was successful.' });
    setPaymentIntentId('');
    setClientSecret('');

    // NOTE: closing the modal automatically causes cancelIntent() to be called when it
    // shouldn't be. This in turn causes an error in the backend with message:
    // "You cannot cancel this PaymentIntent because it has a status of succeeded."
    //
    // This is likely because the version of the onClose() callback being called here
    // contains the paymentIntentId still.
    //
    // setTimeout(() => onClose(true), 3000);
  }, []);

  const balanceKey = billingConfig === 'connectPro' ? 'ConnectPro' : 'LeadscorePlus';
  const currentBalance = account[`currentBalance${balanceKey}`] || 0;

  const newBalance = currentBalance + (Number(amount) || 0);

  const selectedPaymentMethodType = paymentMethods?.find(pm => pm.id === selectedPaymentMethodID)?.type;

  const subtotal = Number(amount) || 0;
  const fees =
    subtotal > 0 && selectedPaymentMethodType
      ? calculatePaymentAmount(subtotal, selectedPaymentMethodType) - subtotal
      : 0;
  const total = subtotal + fees;

  const depositRequired =
    (billingConfig === 'leadscorePlus' &&
      !account.billing.leadscorePlus.deposit.paid &&
      account.billing.leadscorePlus.deposit.amount !== null &&
      account.billing.leadscorePlus.deposit.amount > 0) ||
    (billingConfig === 'connectPro' &&
      !account.billing.connectPro.deposit.paid &&
      account.billing.connectPro.deposit.amount !== null &&
      account.billing.connectPro.deposit.amount > 0);

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button color="blue" fluid>
          <Icon name="plus" /> Add Funds
        </Button>
      }
    >
      <Modal.Header>Add Funds</Modal.Header>
      <Modal.Content>
        {apiMessage?.success === true ? (
          <>
            <ApiMessage data={apiMessage} />
            <Button type="button" color="blue" onClick={onClose} fluid>
              Done
            </Button>
          </>
        ) : (
          <>
            <p>Enter an amount you'd like to add to your account balance.</p>

            {depositRequired && (
              <Message warning>
                <Message.Header>Minimum Deposit Required</Message.Header>
                <Message.Content>
                  To activate your account, a minimum deposit of $
                  {billingConfig === 'leadscorePlus'
                    ? account.billing.leadscorePlus.deposit.amount
                    : account.billing.connectPro.deposit.amount}{' '}
                  is required.
                </Message.Content>
              </Message>
            )}

            <Form>
              <Form.Select
                label="Amount"
                onChange={onChange}
                value={amount}
                error={errors.amount}
                clearable
                options={BillingPaymentAmounts.map(a => ({
                  key: a,
                  value: String(a),
                  disabled:
                    Boolean(
                      billingConfig === 'connectPro' &&
                        !account.billing.connectPro.deposit.paid &&
                        account.billing.connectPro.deposit.amount &&
                        a < account.billing.connectPro.deposit.amount
                    ) ||
                    Boolean(
                      billingConfig === 'leadscorePlus' &&
                        !account.billing.leadscorePlus.deposit.paid &&
                        account.billing.leadscorePlus.deposit.amount &&
                        a < account.billing.leadscorePlus.deposit.amount
                    ),
                  text: '$' + Number(a).toLocaleString(),
                }))}
              />

              <Segment>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Form.Field>
                        <label>Current balance</label>
                        <Label color={currentBalance <= 0 ? 'red' : 'green'}>{currentBalance.toFixed(2)}</Label>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={8} textAlign="right">
                      <Form.Field>
                        <label>New balance</label>
                        <Label color={newBalance <= 0 ? 'red' : 'green'}>{newBalance.toFixed(2)}</Label>
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Divider vertical>
                  <Icon name="arrow right" color="grey" />
                </Divider>
              </Segment>

              <Form.Field disabled={subtotal < 500 || typeof errors.amount !== 'undefined'}>
                <label>Select a payment method</label>
                <div style={{ position: 'relative', marginBottom: '1rem', minHeight: '4rem' }}>
                  {paymentMethodsLoading ? (
                    <Loader active size="tiny" />
                  ) : !paymentMethods || paymentMethods?.length === 0 ? (
                    <Message error visible>
                      <Message.Header>No Payment Methods Found</Message.Header>
                      <Message.Content>Please add a payment method on the previous screen to continue.</Message.Content>
                    </Message>
                  ) : (
                    <Grid columns={2}>
                      <Grid.Row>
                        {paymentMethods?.map(pm => (
                          <Grid.Column key={pm.id}>
                            <PaymentMethod
                              paymentMethod={pm}
                              onClick={selectPaymentMethod(pm.id === selectedPaymentMethodID ? '' : pm.id)}
                              selected={pm.id === selectedPaymentMethodID}
                            />
                          </Grid.Column>
                        ))}
                      </Grid.Row>
                    </Grid>
                  )}
                </div>
              </Form.Field>

              {selectedPaymentMethodType && (
                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={1}>Amount</Table.Cell>
                      <Table.Cell width={1} textAlign="right">
                        {subtotal.toFixed(2)}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={1}>Processing Fee</Table.Cell>
                      <Table.Cell width={1} textAlign="right">
                        {fees.toFixed(2)}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={1}>Total</Table.Cell>
                      <Table.Cell width={1} textAlign="right">
                        <strong>{total.toFixed(2)}</strong>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              )}

              <ApiMessage data={apiMessage} />

              <Elements stripe={stripePromise}>
                <PaymentButton
                  fluid
                  disabled={subtotal <= 0 || selectedPaymentMethodID === '' || typeof errors.amount !== 'undefined'}
                  clientSecret={clientSecret}
                  paymentMethodId={selectedPaymentMethodID}
                  paymentMethodType={selectedPaymentMethodType}
                  loading={intentIsLoading}
                  setApiMessage={setApiMessage}
                  onSubmit={onSubmit}
                  content={total > 0 ? `Authorize Payment of $${total.toFixed(2)}` : 'Authorize Payment'}
                />
              </Elements>
            </Form>
          </>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default AccountBillingAddFunds;
