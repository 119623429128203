import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, CheckboxProps, Form, Icon, InputOnChangeData, Message, Modal } from 'semantic-ui-react';

import { AddAccountInput, useAddAccountMutation } from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Note } from 'src/styles';
import { cleanName, validEmail } from 'src/utils';

type ValidationErrors = {
  name?: string;
  datasetName?: string;
  ownerEmail?: string;
};

const getInitialFormdata = (): AddAccountInput => ({
  name: '',
  ownerEmail: '',
  inviteOwner: true,
  datasetName: '',
});

const AddAccount = () => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [formdata, setFormdata] = useState<AddAccountInput>(() => getInitialFormdata());
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { isLoading, mutateAsync } = useAddAccountMutation();

  const onClose = useCallback(() => {
    setApiMessage(undefined);
    setFormdata(getInitialFormdata());
    setErrors({});
    setOpen(false);
  }, []);

  const onChange = useCallback((_, { checked, name, value }: CheckboxProps | InputOnChangeData) => {
    const v = typeof checked !== 'undefined' ? checked : value;
    setFormdata(prev => ({ ...prev, [name]: v }));
  }, []);

  const onSubmit = async () => {
    setApiMessage(undefined);

    const validationErrors: ValidationErrors = {};

    if (formdata.name === '') {
      validationErrors.name = 'Name is required';
    }

    let datasetName = formdata.datasetName;
    if (datasetName === '') {
      datasetName = cleanName(formdata.name);
    }
    if (/^[0-9]/.test(datasetName)) {
      validationErrors.datasetName = 'Must not start with a number';
    } else if (/[^a-zA-Z0-9_]/.test(datasetName)) {
      validationErrors.datasetName = 'May only contain letters, numbers and underscores';
    }

    if (formdata.ownerEmail === '') {
      validationErrors.ownerEmail = 'Owner Email is required';
    } else if (!validEmail(formdata.ownerEmail)) {
      validationErrors.ownerEmail = 'Must be a valid email address';
    }

    setErrors(validationErrors);

    if (!isEmpty(validationErrors)) {
      return;
    }

    try {
      await mutateAsync(formdata);
      setOpen(false);
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button floated="right" color="blue">
          <Icon name="plus" />
          Add Account
        </Button>
      }
    >
      <Modal.Header>Add Account</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Form.Input label="Name" error={errors.name} name="name" value={formdata.name} onChange={onChange} />

          <Form.Input
            label="Dataset Name"
            placeholder={cleanName(formdata.name)}
            error={errors.datasetName}
            name="datasetName"
            value={formdata.datasetName}
            onChange={onChange}
          />

          <Message warning visible={formdata.datasetName.length > 0}>
            <strong>WARNING!</strong> This field is for developer user only! Don't change <strong>Dataset Name</strong>{' '}
            unless you are sure you know what you are doing!
          </Message>

          <Form.Input
            label="Owner Email"
            error={errors.ownerEmail}
            name="ownerEmail"
            value={formdata.ownerEmail}
            onChange={onChange}
          />
          <Note>This email will be used when creating the customer in Stripe.</Note>

          <Form.Checkbox
            label={`Send user invite?`}
            name="inviteOwner"
            checked={formdata.inviteOwner}
            onChange={onChange}
          />

          {/* <SelectUsers
            label="Owner(s)"
            error={errors.owners}
            name="owners"
            value={formdata.owners}
            onChange={(e, d) => onChange(e, d)}
            valueKey="email"
            // textKey="email"
            allowAdditions
            additionLabel="Invite "
          /> */}

          <Button loading={isLoading} color="blue" fluid>
            Create
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AddAccount;
