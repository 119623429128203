import 'react-datepicker/dist/react-datepicker.css';

import axios from 'axios';
import diff from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import { parse } from 'papaparse';
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  Button,
  DropdownProps,
  Form,
  FormProps,
  Grid,
  Icon,
  InputOnChangeData,
  Label,
  Message,
  Table,
} from 'semantic-ui-react';
import stringSimilarity from 'string-similarity';

import { useGetUserProfileQuery } from 'src/api/auth';
import { ScheduleModelFormdata } from 'src/api/models';
import SelectDsFeed from 'src/components/SelectDsFeed';
import { DelimiterOptions, FileDelimiters } from 'src/config';
import { HorizontalDivider } from 'src/styles';
import { Delimiter, Model, Schedule } from 'src/types';
import ScheduleTableForm, { Days, getInitialScheduleData } from './ScheduleTableForm';

const { REACT_APP_API_URL: apiURL } = process.env;

type ParseScheduleResponse = {
  mm_ranges: number[][];
  month_starting: string; // json date
  session_capacities: number[][];
  start_date: string; // json date
};

type ValidationErrors = {
  file?: string;
  field_mappings?: string;
  schedule?: string;
  start_date?: string;
  hourly_capacity?: string;
};

type Props = {
  handleSubmit: (formdata: ScheduleModelFormdata) => void;
  loading: boolean;
  model?: Model;
};

export const LookaheadOptions = [
  { key: '1 hour', value: 2, text: '1 hour' },
  { key: '2 hours', value: 4, text: '2 hours' },
  { key: '4 hours', value: 8, text: '4 hours' },
  { key: '8 hours', value: 16, text: '8 hours' },
  { key: '1 day', value: 48, text: '1 day' },
  { key: '2 days', value: 96, text: '2 days' },
  { key: '3 days', value: 144, text: '3 days' },
  { key: '4 days', value: 192, text: '4 days' },
  { key: '5 days', value: 240, text: '5 days' },
  { key: '6 days', value: 288, text: '6 days' },
  { key: '1 week', value: 336, text: '1 week' },
  { key: '2 weeks', value: 672, text: '2 weeks' },
  { key: '3 weeks', value: 1008, text: '3 weeks' },
  { key: '1 month', value: 1344, text: '1 month' },
];

const ExtraFields = [
  'phone',
  'email',
  'called_count',
  'address1',
  'address2',
  'city',
  'state',
  'ip',
  'source',
  'dob',
  'age',
];

// For a better string matching, add an alias for fields
const aliasMapping: { [key: string]: string[] } = {
  phone: ['phone_number'],
  zip: ['zip', 'postal_code'],
  dob: ['date_of_birth', 'birth_date'],
};

const getInitialFieldMappingOptions = (customFields: string[] = [], requiredFields: string[] = []) => {
  return [
    { key: '__skip__', text: 'Do Not Import', value: '__skip__' },
    { key: '__keep__', text: 'Keep As-Is', value: '__keep__' },
    ...Array.from(new Set([...requiredFields, ...ExtraFields, ...customFields])).map(f => ({
      key: f,
      text: f,
      value: f,
    })),
  ];
};

const getInitialFormdata = (): ScheduleModelFormdata => ({
  file: undefined,
  delimiter: 'Comma',
  fieldMappings: [],
  schedule: getInitialScheduleData(),
  startDate: new Date(),
  lookahead: 672, // 2 weeks
  hourlyCapacity: 0,
  feedId: null,
});

const ScheduleImportForm = ({ handleSubmit, model, loading }: Props) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<ScheduleModelFormdata>(() => getInitialFormdata());
  const [filePreview, setFilePreview] = useState<string[][] | null>(null);
  const [fieldMappingOptions, setFieldMappingOptions] = useState(() => getInitialFieldMappingOptions());
  const [rowCounts, setRowCounts] = useState<{ total: number; qualified: number }>();
  const { data: user } = useGetUserProfileQuery();

  const requiredFields = useMemo(
    () => Array.from(new Set(['first', 'last', 'zip', ...(model?.required_fields || [])])),
    [model?.required_fields]
  );

  useEffect(() => {
    setFieldMappingOptions(getInitialFieldMappingOptions(model?.custom_fields, requiredFields));
  }, [model?.custom_fields, requiredFields]);

  const validate = useCallback(
    (input: ScheduleModelFormdata): ValidationErrors => {
      const validationErrors: ValidationErrors = {};

      Days.forEach(({ value }) => {
        if (
          input.schedule.config[value].enabled &&
          input.schedule.config[value].end < input.schedule.config[value].start
        ) {
          validationErrors.schedule = 'Please check schedule for errors';
        }
      });

      if (typeof input.file === 'undefined') {
        validationErrors.file = 'file is required';
      } else if (['text/csv', 'text/plain'].indexOf(input.file.type) === -1) {
        validationErrors.file = 'Invalid file type, please select a CSV';
      }

      if (input.hourlyCapacity === 0) {
        validationErrors.hourly_capacity = 'Please set or calculate a capacity.';
      }

      if (input.startDate === null) {
        validationErrors.start_date = 'Start date is required.';
      }

      const missingMappings: string[] = diff(requiredFields, input.fieldMappings);
      if (missingMappings.length > 0) {
        validationErrors.field_mappings = missingMappings.join(', ');
      }

      setErrors(validationErrors);

      return validationErrors;
    },
    [requiredFields]
  );

  useEffect(() => {
    if (typeof formdata.file === 'undefined') {
      setFilePreview(null);
      return;
    }
    if (typeof errors.file !== 'undefined') {
      setFilePreview(null);
      return;
    }

    const d = FileDelimiters.has(formdata.delimiter) ? FileDelimiters.get(formdata.delimiter) : ',';

    const parsedRows: string[][] = [];
    parse<string[]>(formdata.file, {
      delimiter: d,
      step: ({ data }, parser) => {
        parsedRows.push(data);
        if (parsedRows.length >= 10) {
          parser.abort();
          return;
        }
      },
      complete: () => {
        setFilePreview(parsedRows);

        setFormdata(prev => {
          const fieldMappingValues = fieldMappingOptions.map(fm => fm.value);

          const headers = parsedRows[0];

          const fm = Array(headers.length).fill('__keep__');

          for (const f of fieldMappingValues) {
            if (f === '__skip__' || f === '__keep__') continue;

            let bestScore = 0;
            let bestIdx = 0;
            headers.forEach((h, i) => {
              if (fm[i] !== '__skip__' && fm[i] !== '__keep__') return;

              const fields = aliasMapping[f.toLowerCase()] || [f.toLowerCase()];

              for (const field of fields) {
                const ratio = stringSimilarity.compareTwoStrings(h.toLowerCase(), field);

                if (ratio >= bestScore) {
                  bestScore = ratio;
                  bestIdx = i;
                }
              }
            });

            if (bestScore >= 0.5) {
              fm[bestIdx] = f;
            }
          }

          const next: ScheduleModelFormdata = {
            ...prev,
            fieldMappings: fm,
          };
          validate(next);
          return next;
        });
      },
    });
  }, [errors.file, fieldMappingOptions, formdata.delimiter, formdata.file, validate]);

  useEffect(() => {
    if (typeof formdata.file === 'undefined') {
      setRowCounts(undefined);
      return;
    }
    if (typeof errors.file !== 'undefined') {
      setRowCounts(undefined);
      return;
    }

    const idx: { [k: string]: number } = {
      // first: formdata.fieldMappings.indexOf('first'),
      // last: formdata.fieldMappings.indexOf('last'),
      // zip: formdata.fieldMappings.indexOf('zip'),
      // calledCount: formdata.fieldMappings.indexOf('called_count'),
    };
    requiredFields.forEach(f => {
      idx[f] = formdata.fieldMappings.indexOf(f);
    });

    const d = FileDelimiters.has(formdata.delimiter) ? FileDelimiters.get(formdata.delimiter) : ',';

    let total = 0;
    let qualified = 0;
    parse<string[]>(formdata.file, {
      delimiter: d,
      step: ({ data }) => {
        total++;

        for (let i = 0; i < requiredFields.length; i++) {
          const f = requiredFields[i];
          if (idx[f] === -1 || !data[idx[f]]) return;
        }

        if (requiredFields.includes('zip') && idx.zip) {
          const zip = (data[idx.zip] || '').replace(/\D/g, '');
          if (zip.length === 0 || zip === '0') return;
        }

        if (requiredFields.includes('age') && idx.age) {
          const age = Number(data[idx.age] || '');
          if (Number.isNaN(age) || age <= 0) return;
        }

        // const calledCount = data[idx.calledCount].replace(/\D/g, '');
        // if (calledCount.length === 0) return;

        qualified++;
      },
      complete: () => {
        setRowCounts({ total, qualified });
      },
    });
  }, [errors.file, formdata.delimiter, formdata.file, formdata.fieldMappings, requiredFields]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFormdata(prev => {
        let file;
        if (event.target.files && event.target.files.length) {
          file = event.target.files[0];
        }

        const next: ScheduleModelFormdata = { ...prev, file };
        validate(next);
        return next;
      });
    },
    [validate]
  );

  const onChangeDelimiter = useCallback(
    (_event: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
      setFormdata({ ...formdata, delimiter: value as Delimiter });
    },
    [formdata]
  );

  const onChangeLookahead = useCallback(
    (_event: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
      setFormdata(prev => {
        const next: ScheduleModelFormdata = {
          ...prev,
          lookahead: Number(value),
        };
        validate(next);
        return next;
      });
    },
    [validate]
  );

  const onChangeFieldMapping = useCallback(
    (i: number) =>
      (_event: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        setFormdata(prev => {
          const newMappings = [...prev.fieldMappings];
          newMappings[i] = `${value}`;
          const next: ScheduleModelFormdata = { ...prev, fieldMappings: newMappings };
          validate(next);
          return next;
        });
      },
    [validate]
  );

  const onChangeFeedId = useCallback(({ value }: { name: string; value: string }) => {
    setFormdata(prev => ({ ...prev, feedId: Number(value) }));
  }, []);

  const onChangeStartDate = useCallback(date => {
    setFormdata(prev => ({ ...prev, startDate: date }));
  }, []);

  const onChangeSchedule = useCallback(
    (schedule: Schedule) => {
      setFormdata(prev => {
        const next: ScheduleModelFormdata = { ...prev, schedule };
        validate(next);
        return next;
      });
    },
    [validate]
  );

  const onSubmit = useCallback(
    async (_event: React.FormEvent<HTMLFormElement>, _data: FormProps) => {
      if (!isEmpty(validate(formdata))) {
        return;
      }

      try {
        await handleSubmit(formdata);
        setFormdata(getInitialFormdata());
        setFilePreview(null);
        setRowCounts(undefined);
        if (fileInputRef.current !== null) {
          fileInputRef.current.value = '';
        }
      } catch (err) {
        // already being caught and displayed by parent
      }
    },
    [formdata, handleSubmit, validate]
  );

  const onChangeCapacity = useCallback((_event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setFormdata(prev => ({
      ...prev,
      hourlyCapacity: Number(data.value) || 0,
    }));
  }, []);

  const calculateBestHourlyCapacity = useCallback(async () => {
    if (typeof rowCounts === 'undefined' || rowCounts.qualified === 0) return;
    if (!formdata.startDate || !formdata.lookahead) return;

    try {
      const startDate = new Date(formdata.startDate);
      startDate.setHours(0, 0, 0, 0);

      const data = {
        schedule: formdata.schedule,
        start_date: startDate,
        lookahead: formdata.lookahead,
        hourly_capacity: formdata.hourlyCapacity,
      };
      const res = await axios.post<ParseScheduleResponse>(`${apiURL}/parse_schedule`, data);

      const sessionMinutes = 30;
      let sesssionCount = 0;
      res.data.session_capacities.forEach(sc => {
        sesssionCount += sc.length;
      });
      if (sesssionCount === 0) return;

      const bestHourlyCapacity = Math.ceil((rowCounts.qualified / sesssionCount / sessionMinutes) * 60);

      setFormdata(prev => {
        const next: ScheduleModelFormdata = {
          ...prev,
          hourlyCapacity: bestHourlyCapacity,
        };
        validate(next);
        return next;
      });
    } catch (e) {
      // do nothing
    }
  }, [formdata.hourlyCapacity, formdata.lookahead, formdata.schedule, formdata.startDate, rowCounts, validate]);

  const ignoreAllKept = useCallback(() => {
    setFormdata(prev => {
      const next: ScheduleModelFormdata = {
        ...prev,
        fieldMappings: prev.fieldMappings.map(fm => {
          if (fm !== '__keep__') return fm;
          return '__skip__';
        }),
      };
      validate(next);
      return next;
    });
  }, [validate]);

  const keepAllIgnored = useCallback(() => {
    setFormdata(prev => {
      const next: ScheduleModelFormdata = {
        ...prev,
        fieldMappings: prev.fieldMappings.map(fm => {
          if (fm !== '__skip__') return fm;
          return '__keep__';
        }),
      };
      validate(next);
      return next;
    });
  }, [validate]);

  const hourlyCapacityRecommendedMax = (user?.active_account.voice?.outbound_limit_max || 60) * 60 * 1.2;

  return (
    <Form onSubmit={onSubmit}>
      {typeof rowCounts !== 'undefined' && (
        <Message success visible>
          <Message.Header>File Details</Message.Header>
          <Message.Content>
            Your file contains <strong>{rowCounts.qualified}</strong> qualified records out of{' '}
            <strong>{rowCounts.total}</strong> total rows.
          </Message.Content>
        </Message>
      )}

      {errors.field_mappings && (
        <Message error visible>
          <Message.Header>🚨 Missing required field mappings:</Message.Header>
          <Message.Content>{errors.field_mappings}</Message.Content>
        </Message>
      )}

      <Form.Group widths="equal">
        <Form.Field error={'file' in errors}>
          <label>File</label>
          <input type="file" name="file" onChange={onChange} ref={fileInputRef} />
          {errors.file && (
            <Label pointing prompt>
              {errors.file}
            </Label>
          )}
        </Form.Field>

        <Form.Select
          label="Delimiter"
          value={formdata.delimiter}
          options={DelimiterOptions}
          name="delimiter"
          onChange={onChangeDelimiter}
        />
      </Form.Group>

      {filePreview && (
        <>
          <div style={{ marginBottom: '1rem' }}>
            <Button type="button" content={`Ignore All "Kept As-Is"`} onClick={ignoreAllKept} />
            <Button type="button" content={`Keep All "Do Not Import"`} onClick={keepAllIgnored} />
          </div>

          <div style={{ overflowX: 'scroll', marginBottom: '1rem' }}>
            <Table>
              <Table.Header>
                <Table.Row>
                  {filePreview[0].map((_cell: string, i: number) => (
                    <Table.HeaderCell key={i}>
                      <Form.Select
                        upward={false}
                        options={fieldMappingOptions.filter(
                          o =>
                            o.value === '__skip__' ||
                            o.value === '__keep__' ||
                            o.value === formdata.fieldMappings[i] ||
                            formdata.fieldMappings.indexOf(o.value) === -1
                        )}
                        value={formdata.fieldMappings[i]}
                        onChange={onChangeFieldMapping(i)}
                        style={
                          formdata.fieldMappings[i] === '__skip__'
                            ? { background: 'transparent', color: 'rgba(0,0,0,0.25)' }
                            : formdata.fieldMappings[i] === '__keep__'
                            ? undefined
                            : {
                                borderColor: 'rgba(44, 102, 45, 0.5)',
                                background: '#fcfff5',
                                color: '#2c662d',
                              }
                        }
                      />
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {filePreview
                  .filter((r: string[]) => r && r.length === filePreview[0].length)
                  .map((row: string[], rowIdx: number) => (
                    <Table.Row key={rowIdx}>
                      {row.map((cell: string, cellIdx: number) => (
                        <Table.Cell key={`${rowIdx}-${cellIdx}`}>{cell}</Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </div>
        </>
      )}

      <HorizontalDivider />

      {errors.schedule && (
        <Message error visible>
          <Message.Header>🚨 Schedule validation error:</Message.Header>
          <Message.Content>{errors.schedule}</Message.Content>
        </Message>
      )}

      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <ScheduleTableForm schedule={formdata.schedule} setSchedule={onChangeSchedule} renderTemplates />
          </Grid.Column>
          <Grid.Column>
            <Form.Field error={'start_date' in errors}>
              <DatePicker
                selected={formdata.startDate}
                onChange={onChangeStartDate}
                // TODO: this line is causing the following warning:
                // Warning: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?
                customInput={<Form.Input label="Start Date" icon="calendar" iconPosition="left" />}
                minDate={new Date()}
                popperPlacement="bottom"
              />
              {errors.start_date && (
                <Label pointing prompt>
                  {errors.start_date}
                </Label>
              )}
            </Form.Field>

            <Form.Select
              label="Lookahead"
              options={LookaheadOptions}
              name="Lookahead"
              value={formdata.lookahead}
              onChange={onChangeLookahead}
            />

            <Form.Input
              label="Hourly Capacity"
              placeholder={formdata.hourlyCapacity === 0 ? 'No limit' : undefined}
              name="hourly_capacity"
              value={formdata.hourlyCapacity}
              onChange={onChangeCapacity}
              style={{ marginRight: '0.5rem' }}
              error={errors?.hourly_capacity}
            />

            {formdata.hourlyCapacity > hourlyCapacityRecommendedMax && (
              <Message warning visible>
                It is recomended that the hourly capacity does not exceed {hourlyCapacityRecommendedMax}
              </Message>
            )}

            <Button icon onClick={calculateBestHourlyCapacity} style={{ margin: 0 }} type="button">
              <Icon name="calculator" /> Calculate hourly capacity for even distribution
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <HorizontalDivider />

      <Form.Group widths="equal">
        <SelectDsFeed
          label="(Optional) Ingest to Feed on Complete"
          name="feedId"
          value={String(formdata.feedId || '')}
          onChange={onChangeFeedId}
          clearable
          search
        />
        <Form.Field />
        <Form.Field />
      </Form.Group>

      <Button content="Upload" color="blue" loading={loading} />
    </Form>
  );
};

export default ScheduleImportForm;
