import isEmpty from 'lodash/isEmpty';
import { useRef, useState } from 'react';
import { Button, Form, FormProps, Icon, InputOnChangeData, Modal } from 'semantic-ui-react';

import { AddConvosoProfileInput, useAddConvosoProfileMutation } from 'src/api/convoso';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';

const getInitialFormdata = (): AddConvosoProfileInput => ({
  name: '',
  auth_token: '',
});

type ValidationErrors = {
  name?: string;
  auth_token?: string;
};

const AddConvosoProfile = () => {
  const modalRef = useRef(null);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<AddConvosoProfileInput>(() => getInitialFormdata());
  const { mutateAsync, isLoading } = useAddConvosoProfileMutation();

  const onCloseModal = () => {
    setApiMessage(undefined);
    setFormdata(getInitialFormdata());
  };

  const onSubmit = async (_event: React.FormEvent<HTMLFormElement>, _data: FormProps) => {
    const validationErrors: ValidationErrors = {};

    if (formdata.name === '') {
      validationErrors.name = 'name is required';
    } else if (formdata.name.length < 2) {
      validationErrors.name = 'name must be at least 2 characters';
    }

    if (formdata.auth_token === '') {
      validationErrors.auth_token = 'auth token is required';
    } else if (formdata.auth_token.length < 16) {
      validationErrors.auth_token = 'auth token must be at least 16 characters';
    }

    setErrors(validationErrors);

    if (!isEmpty(validationErrors)) {
      return;
    }

    setApiMessage(undefined);

    try {
      await mutateAsync(formdata);

      setFormdata(getInitialFormdata());

      if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
        // @ts-ignore
        modalRef.current.handleClose();
      }
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>, { name, value }: InputOnChangeData): void => {
    setFormdata({ ...formdata, [name]: value });
  };

  return (
    <Modal
      size="mini"
      ref={modalRef}
      trigger={
        <Button color="blue">
          <Icon name="plus" /> Add Profile
        </Button>
      }
      onClose={onCloseModal}
    >
      <Modal.Header>Add Profile</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Form.Input error={errors.name} label="Name" name="name" value={formdata.name} onChange={onInputChange} />

          <Form.Input
            error={errors.auth_token}
            label="Auth Token"
            name="auth_token"
            value={formdata.auth_token}
            onChange={onInputChange}
            type="password"
          />

          <Button content="Create" color="blue" loading={isLoading} fluid />
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AddConvosoProfile;
