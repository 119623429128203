import { useMutation, useQuery, useQueryClient } from 'react-query';

import { JobType, PaginatedResponse, Pipeline } from 'src/types';
import { ApiResponse, http } from './http-common';

export type PipelineResponse = ApiResponse & {
  pipeline?: Pipeline;
};

export type PipelinesResponse = ApiResponse & {
  pipelines: PaginatedResponse<Pipeline>;
};

export type PipelineMetaValues = ApiResponse & {
  metaValues: Record<string, string[]>;
};

export type ListPipelinesParams = {
  limit: number;
  offset: number;
};

export const useListPipelinesQuery = (params: ListPipelinesParams) => {
  return useQuery(['pipelines', 'configs', params], async () => {
    const res = await http.get<PipelinesResponse>('/api/pipelines/configs', { params });
    return res.data.pipelines;
  });
};

export const useGetPipelineQuery = (id: number) => {
  return useQuery(['pipelines', 'configs', id], async () => {
    if (!id) return undefined;
    const res = await http.get<PipelineResponse>(`/api/pipelines/configs/${id}`);
    return res.data.pipeline;
  });
};

export const useRunPipelineMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await http.post<ApiResponse>(`/api/pipelines/configs/${id}/run`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['jobs', JobType.Pipeline]);
      },
    }
  );
};

export const useGetPipelineMetaValues = (id: number) => {
  return useQuery(['pipelines', 'configs', id, 'meta-values'], async () => {
    const res = await http.get<PipelineMetaValues>(`/api/pipelines/configs/${id}/meta-values`);
    return res.data.metaValues;
  });
};

export type AddPipelineParams = {
  name: string;
};

export const useAddPipelineMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: AddPipelineParams) => {
      const res = await http.post<PipelineResponse>(`/api/pipelines/configs`, params);
      return res.data.pipeline;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['pipelines', 'configs']);
      },
    }
  );
};

export type SavePipelineParams = {
  pipeline: Pipeline;
};

export const useSavePipelineMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: SavePipelineParams) => {
      const res = await http.post<PipelineResponse>(`/api/pipelines/configs/${params.pipeline.id}`, params);
      return res.data.pipeline;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['pipelines', 'configs']);
      },
    }
  );
};

export const useDeletePipelineMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await http.delete<ApiResponse>(`/api/pipelines/configs/${id}`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['pipelines', 'configs']);
      },
    }
  );
};

export const useDuplicatePipelineMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await http.post<ApiResponse>(`/api/pipelines/configs/${id}/duplicate`);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['pipelines', 'configs']);
      },
    }
  );
};

export type ConvertPipelineFiltersToSetParams = {
  pipelineID: number;
  name: string;
};

export const useConvertPipelineFiltersToSetMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ pipelineID, ...params }: ConvertPipelineFiltersToSetParams) => {
      const res = await http.post<PipelineResponse>(
        `/api/pipelines/configs/${pipelineID}/convert-filters-to-set`,
        params
      );
      return res.data.pipeline;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/pipelines', 'configs']);
        queryClient.invalidateQueries(['pipelines', 'configs']);
        queryClient.invalidateQueries(['pipelines', 'filter-sets']);
      },
    }
  );
};

export const useDetachPipelineFilterSetMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (pipelineID: number) => {
      const res = await http.post<PipelineResponse>(`/api/pipelines/configs/${pipelineID}/detach-filter-set`);
      return res.data.pipeline;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/pipelines', 'configs']);
        queryClient.invalidateQueries(['pipelines', 'configs']);
      },
    }
  );
};
