import { useRef, useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { useDeleteDatasetMutation } from 'src/api/bigquery';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';

const DeleteDataset = ({ id }: { id: number }) => {
  const modalRef = useRef(null);
  const [error, setError] = useState<ApiMessageData>();
  const { mutateAsync, isLoading } = useDeleteDatasetMutation();

  const onCancel = () => {
    if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
      // @ts-ignore
      modalRef.current.handleClose();
    }
  };

  const onCloseModal = () => {
    setError(undefined);
  };

  const onSubmit = async (): Promise<void> => {
    setError(undefined);

    try {
      await mutateAsync(id);
      onCancel();
    } catch (e: any) {
      apiErrorHandler(e, setError);
    }
  };

  return (
    <Modal
      size="mini"
      ref={modalRef}
      trigger={
        <Button color="red" icon title="Delete">
          <Icon name="trash" />
        </Button>
      }
      onClose={onCloseModal}
    >
      <Modal.Header>Delete Dataset</Modal.Header>
      <Modal.Content>
        <ApiMessage data={error} />

        <Form onSubmit={onSubmit}>
          <p style={{ marginTop: 0 }}>
            <strong>Are you sure?</strong> This action cannot be undone.
          </p>

          <Row>
            <Button fluid type="button" onClick={onCancel}>
              Cancel
            </Button>
            <Button color="red" fluid loading={isLoading}>
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default DeleteDataset;
