import { DragEvent } from 'react';
import { Icon } from 'semantic-ui-react';

import NodeOverlay from '../components/NodeOverlay';
import { Widget, WidgetRow } from './styles';
import { Alias, NodeType } from './types';

const NodesAlias: readonly Alias[] = [
  {
    name: 'BigQuery',
    icon: <Icon name="searchengin" color="violet" size="large" />,
    type: 'bigquery',
  },
  {
    name: 'Blacklist',
    icon: <Icon name="ban" color="violet" size="large" />,
    type: 'blacklist',
  },
  {
    name: 'Filter',
    icon: <Icon name="filter" color="violet" size="large" />,
    type: 'filter',
  },
  // {
  //   name: 'Combine - Join',
  //   icon: <Icon name="random" color="violet" size="large" />,
  //   type: 'combine_join',
  // },
  {
    name: 'Combine - Union',
    icon: <Icon name="random" color="violet" size="large" />,
    type: 'combine_union',
  },
  {
    name: 'Output',
    icon: <Icon name="rss" color="violet" size="large" />,
    type: '_output',
  },
] as const;

const WidgetsOverlay = () => {
  const onDragStart = (event: DragEvent<HTMLDivElement>, nodeType: NodeType, name: string) => {
    const data = {
      type: nodeType,
      name,
    };

    event.dataTransfer.setData('application/reactflow', JSON.stringify(data));
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <NodeOverlay>
      <NodeOverlay.Header>
        <NodeOverlay.Title>Widgets</NodeOverlay.Title>
      </NodeOverlay.Header>

      {NodesAlias.map(({ name, icon, type }) => (
        <WidgetRow key={name}>
          <Widget onDragStart={event => onDragStart(event, type, name)} draggable>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {icon} {name}
            </div>
          </Widget>
        </WidgetRow>
      ))}
    </NodeOverlay>
  );
};

export default WidgetsOverlay;
