import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Table } from 'semantic-ui-react';

import { useListAccountApiTokensQuery } from 'src/api/accounts';
import { Row } from 'src/styles';
import { SendgridInboundParseSetting } from 'src/types';
import DeleteSendgridInboundParseSetting from './DeleteSendgridInboundParseSetting';

const betaUrl = process.env.REACT_APP_BETA_URL;

type Props = {
  settings?: SendgridInboundParseSetting[];
};

const SendGridInboundParseSettings = ({ settings }: Props) => {
  const { data: apiTokens, isLoading: apiTokensLoading } = useListAccountApiTokensQuery({ limit: 1, offset: 0 });

  const apiToken = useMemo<string | null>(() => {
    if (apiTokensLoading) return null;
    if (!apiTokens || !apiTokens.data || apiTokens.data.length === 0) return null;
    return apiTokens?.data[0].token;
  }, [apiTokens, apiTokensLoading]);

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>URL</Table.HeaderCell>
          <Table.HeaderCell collapsing></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {settings?.map(s => (
          <Table.Row key={s.id}>
            <Table.Cell>{s.name}</Table.Cell>
            <Table.Cell>{`${betaUrl}/api/sg/inbound-parse/${s.token}?token=${apiToken}`}</Table.Cell>
            <Table.Cell>
              <Row>
                <Link to={{ pathname: `/account/sendgrid/inbound-parse/${s.id}` }}>
                  <Button as="div" icon color="blue">
                    <Icon name="pencil" />
                  </Button>
                </Link>
                <DeleteSendgridInboundParseSetting id={s.id} />
              </Row>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default SendGridInboundParseSettings;
