import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

import { useListAccountsQuery } from 'src/api/admin/accounts';
import { ListWebhookConfigFilters } from 'src/api/admin/webhooks';
import Breadcrumbs from 'src/components/Breadcrumbs';
import PaginatedTable from 'src/components/PaginatedTable';
import useSearchQuery from 'src/hooks/useSearchQuery';
import { Container, Header, Row } from 'src/styles';
import AdminAddWebhookConfig from './AdminAddWebhookConfig';
import AdminListWebhookConfigsBody from './AdminListWebhookConfigsBody';

export const STATUS_FILTERS = [
  { key: 'enabled', value: true, text: 'Enabled' },
  { key: 'disabled', value: false, text: 'Disabled' },
];

const getFiltersFromQuery = (query: URLSearchParams): ListWebhookConfigFilters => {
  let accountId = Number(query.get('accountId'));
  if (query.get('accountId') === '' || isNaN(accountId)) {
    accountId = -1;
  }

  let enabled;
  switch (query.get('enabled')) {
    case '1':
      enabled = true;
      break;

    case '0':
      enabled = false;
      break;

    default:
      enabled = undefined;
      break;
  }

  return { accountId, enabled };
};

const AdminWebhookConfigsList = () => {
  const { push, replace } = useHistory();
  const query = useSearchQuery();
  const [filters, setFilters] = useState<ListWebhookConfigFilters>(() => getFiltersFromQuery(query));
  const { data: accounts, isLoading: accountsLoading } = useListAccountsQuery({
    limit: 1000,
    offset: 0,
    enabled: true,
  });

  useEffect(() => {
    let shouldReplace = false;

    if (!query.has('accountId')) {
      query.set('accountId', '');
      shouldReplace = true;
    }

    if (!query.has('enabled')) {
      query.set('enabled', '1');
      shouldReplace = true;
    }

    if (shouldReplace) {
      replace({ search: query.toString() });
    }
  });

  useEffect(() => {
    setFilters(getFiltersFromQuery(query));
  }, [query]);

  const onSubmit = useCallback(() => {
    query.set('page', '1');
    push({ search: query.toString() });
  }, [push, query]);

  const accountOptions = [
    { key: -1, text: 'All', value: -1 },
    { key: 0, text: 'System', value: 0 },
    ...(accounts?.data.map(a => ({ key: a.id, text: a.name, value: a.id })) || []),
  ];

  return (
    <Container>
      <Helmet>
        <title>Webhooks Configs | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header style={{ marginBottom: 0 }}>
          <Breadcrumbs crumbs={['Admin', 'Webhooks', 'Configs']} />
        </Header>
        <AdminAddWebhookConfig />
      </Row>

      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Dropdown
            label="Status"
            placeholder="All"
            selection
            clearable
            options={STATUS_FILTERS}
            value={typeof filters.enabled === 'undefined' ? '' : filters.enabled}
            onChange={(_, { value }) => {
              let enabled = '';
              switch (value) {
                case '':
                  enabled = '';
                  break;
                default:
                  enabled = Number(value).toString();
                  break;
              }

              query.set('enabled', enabled);
              query.set('page', '1');
              push({ search: query.toString() });

              // NOTE: the useEffect hook above (watching query) will set the filter state for us
            }}
          />

          <Form.Dropdown
            loading={accountsLoading}
            label="Account"
            placeholder="All"
            selection
            search
            clearable
            options={accountOptions}
            value={filters.accountId !== -1 ? filters.accountId : ''}
            onChange={(_, { value }) => {
              let accountId = Number(value);
              if (value === '' || isNaN(accountId)) {
                accountId = -1;
              }

              query.set('accountId', accountId !== -1 ? accountId.toString() : '');
              query.set('page', '1');
              push({ search: query.toString() });

              // NOTE: the useEffect hook above (watching query) will set the filter state for us
            }}
          />

          {/* <Form.Input
            label="Search"
            placeholder="by name"
            value={searchQuery || ''}
            onChange={(_, { value }) => setSearchQuery(value)}
            onBlur={onSubmit}
          />

          <Form.Button label="&nbsp;" icon>
            <Icon name="search" />
          </Form.Button> */}
        </Form.Group>
      </Form>

      <PaginatedTable
        headers={['Status', 'Name', 'URL', 'Account', '']}
        renderBody={props => <AdminListWebhookConfigsBody {...props} filters={filters} />}
      />
    </Container>
  );
};

export default AdminWebhookConfigsList;
