import { isEmpty } from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import { useCallback, useState } from 'react';
import { Button, Form, Header, Label } from 'semantic-ui-react';

import { Row } from 'src/styles';
import { AccountMetadata, Weekday, WeekdayShortNames } from 'src/types';
import AdminAccountMetadataRebuildButton from './AdminAccountMetadataRebuildButton';

type ValidationErrors = {
  name?: string;
  scheduleEvery?: string;
  scheduleFrequency?: string;
  scheduleDays?: string;
};

type Props = {
  metadata: AccountMetadata;
  onChange: (metadata: AccountMetadata) => void;
  onError: (message: string) => void;
};

enum MetadataScheduleFrequency {
  'hours' = 'hours',
  'days' = 'days',
  'weeks' = 'weeks',
  'months' = 'months',
}
const FrequencyOptions = [
  { key: MetadataScheduleFrequency.hours, value: MetadataScheduleFrequency.hours, text: 'Hour(s)' },
  { key: MetadataScheduleFrequency.days, value: MetadataScheduleFrequency.days, text: 'Day(s)' },
  { key: MetadataScheduleFrequency.weeks, value: MetadataScheduleFrequency.weeks, text: 'Week(s)' },
];

const AdminAccountSettingsMetadata = (props: Props) => {
  const [errors, setErrors] = useState<ValidationErrors>({} as ValidationErrors);

  const frequencyDayChooserTitle =
    props.metadata.schedule.frequency === MetadataScheduleFrequency.days ? 'Days of the Week' : 'Day of the Week';

  const validate = useCallback(
    (m: AccountMetadata) => {
      const errors = {} as ValidationErrors;

      if (!m.schedule.every) {
        errors.scheduleEvery = 'Every is required';
      }

      if (!m.schedule.frequency) {
        errors.scheduleFrequency = 'Frequency is required';
      }

      if (m.schedule.frequency === MetadataScheduleFrequency.weeks && isEmpty(m.schedule.days)) {
        errors.scheduleDays = 'Day of the week is required';
      }

      if (m.schedule.frequency === MetadataScheduleFrequency.weeks && m.schedule.days.length > 1) {
        errors.scheduleDays = 'Only one day can be selected';
      }

      const isValid = isEmpty(errors);
      setErrors(errors);
      props.onError(isValid || m.enabled === false ? '' : 'Please correct the errors above');

      return isValid;
    },
    [props]
  );

  const onChange = useCallback(
    (_, { checked, name, value }) => {
      if (['schedule.every'].includes(name)) {
        value = Number(value);
        if (Number.isNaN(value)) {
          value = 0;
        }
      }

      // schedule.frequency
      if (name === 'schedule.frequency') {
        if (value === MetadataScheduleFrequency.weeks) {
          _set(props.metadata, 'schedule.days', [0]);
        } else {
          _set(props.metadata, 'schedule.days', [0, 1, 2, 3, 4, 5, 6]);
        }
      }

      const next = _cloneDeep(props.metadata);
      if (checked !== undefined) {
        _set(next, name, checked);
      } else {
        _set(next, name, value);
      }
      props.onChange(next);
      validate(next);
    },
    [props, validate]
  );

  const frequencyDayChooserChange = useCallback(
    (i: Weekday) => {
      const prev = props.metadata.schedule.days;
      let next = [] as Weekday[];
      if (props.metadata.schedule.frequency === MetadataScheduleFrequency.weeks) {
        next = prev.includes(i) ? [] : [i];
      } else {
        if (prev.includes(i)) {
          next = prev.filter(v => v !== i);
        } else {
          next = prev.concat([i]);
        }
      }
      onChange(null, { name: 'schedule.days', value: next });
    },
    [props.metadata.schedule.days, props.metadata.schedule.frequency, onChange]
  );

  return (
    <div>
      <Header as="h4">Metadata</Header>

      <Form.Group>
        <Form.Checkbox
          toggle
          label="Enable Metadata"
          name="enabled"
          checked={props.metadata.enabled}
          onChange={onChange}
          style={{ width: 190 }}
        />

        <Form.Input
          label="Every"
          style={{ width: 50 }}
          value={props.metadata.schedule.every || ''}
          name="schedule.every"
          onChange={onChange}
          error={errors.scheduleEvery}
          disabled={props.metadata.enabled === false}
        />

        <Form.Select
          label="Frequency"
          options={FrequencyOptions}
          name="schedule.frequency"
          value={props.metadata.schedule.frequency || ''}
          onChange={onChange}
          disabled={props.metadata.enabled === false}
          style={{ width: 180, minWidth: 0 }}
        />

        {(props.metadata.schedule.frequency === MetadataScheduleFrequency.weeks ||
          (props.metadata.schedule.frequency === MetadataScheduleFrequency.days &&
            props.metadata.schedule.every === 1)) && (
          <Form.Field error={!!errors.scheduleDays} disabled={props.metadata.enabled === false}>
            <label>{frequencyDayChooserTitle}</label>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                height: 38,
                alignItems: 'center',
                gap: '0.5rem',
              }}
            >
              {WeekdayShortNames.map((dow, i) => (
                <Button
                  key={dow}
                  type="button"
                  circular
                  size="small"
                  style={{
                    padding: 0,
                    width: 28,
                    height: 28,
                    margin: 0,
                    ...(!Boolean(errors.scheduleDays) ? {} : { backgroundColor: '#fdf1f1', color: '#9f3a38' }),
                  }}
                  color={props.metadata.schedule.days.includes(i) ? 'blue' : undefined}
                  onClick={() => frequencyDayChooserChange(i)}
                >
                  {dow[0].toUpperCase()}
                </Button>
              ))}
            </div>
            {errors.scheduleDays && props.metadata.enabled && (
              <Label prompt color="red" pointing>
                {errors.scheduleDays}
              </Label>
            )}
          </Form.Field>
        )}
      </Form.Group>

      <Row style={{ marginTop: '1rem' }}>
        <AdminAccountMetadataRebuildButton />
      </Row>
    </div>
  );
};

export default AdminAccountSettingsMetadata;
