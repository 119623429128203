import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, List, Modal } from 'semantic-ui-react';

import { useArchiveAccountMutation } from 'src/api/admin/accounts';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import theme from 'src/styles/theme';

type Props = {
  accountId: number;
};

const ArchiveAccountButton = ({ accountId }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync, isLoading } = useArchiveAccountMutation();
  const [confirm, setConfirm] = useState('');
  const [error, setError] = useState<string>();
  const { push } = useHistory();

  const onClose = useCallback(() => {
    setApiMessage(undefined);
    setError(undefined);
    setConfirm('');
    setOpen(false);
  }, []);

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);
    setError(undefined);

    if (confirm !== 'ARCHIVE') {
      setError('Enter ARCHIVE to confirm.');
      return;
    }

    try {
      await mutateAsync(accountId);
      onClose();
      push('/admin/accounts');
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [accountId, confirm, mutateAsync, onClose, push]);

  return (
    <Modal
      size="mini"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button color="red" type="button">
          Archive Account
        </Button>
      }
    >
      <Modal.Header>Archive Account</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <p style={{ marginTop: 0 }}>
            <strong>Are you sure?</strong> This action cannot be undone.
          </p>

          <p>By pressing Confirm below you will cause the following actions to occur:</p>

          <List bulleted>
            <List.Item>Backup and delete the BigQuery Dataset.</List.Item>
            <List.Item>Release all Bandwidth Phones.</List.Item>
            <List.Item>Delete Bandwidth Sub-Account, Location + Apps.</List.Item>
            <List.Item style={{ color: theme.gray }}>TODO: Release all Telnyx Phones.</List.Item>
            <List.Item style={{ color: theme.gray }}>TODO: Delete Telnyx Billing Group + Apps.</List.Item>
            <List.Item>Release all Twilio Phones.</List.Item>
            <List.Item>Suspend the Twilio Sub-Account.</List.Item>
          </List>

          <Form.Input
            label="To archive this account, type ARCHIVE:"
            placeholder="ARCHIVE"
            value={confirm}
            onChange={(_, { value }) => setConfirm(value)}
            error={error}
          />

          <Row>
            <Button fluid type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button color="red" fluid loading={isLoading}>
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default ArchiveAccountButton;
