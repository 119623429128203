import { format } from 'date-fns';
import { Button, Header, Icon, Label, Modal, Segment, Table } from 'semantic-ui-react';

import { Note, Row } from 'src/styles';
import { VoiceCallDetailRecord } from 'src/types';

type Props = { recording: VoiceCallDetailRecord };

const VoiceCallDetailsModal = ({ recording: r }: Props) => {
  return (
    <Modal
      size="fullscreen"
      trigger={
        <Button title="View Details" icon>
          <Icon name="eye" />
        </Button>
      }
    >
      <Modal.Content>
        <Row style={{ gap: '1rem' }}>
          <div style={{ flex: 1 }}>
            <Header>Call Details</Header>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Call ID</Table.Cell>
                  <Table.Cell>
                    <Note>{r.id}</Note>
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell style={{ width: 130 }}>Started At</Table.Cell>
                  <Table.Cell>{format(new Date(r.startedAt), 'PPPppp')}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell style={{ width: 130 }}>Ended At</Table.Cell>
                  <Table.Cell>{format(new Date(r.endedAt), 'PPPppp')}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Customer Phone</Table.Cell>
                  <Table.Cell>{r.phone}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Dialer Phone</Table.Cell>
                  <Table.Cell>{r.dialerPhone}</Table.Cell>
                </Table.Row>

                {r.transferCallId && (
                  <>
                    <Table.Row>
                      <Table.Cell>Transfer Call ID</Table.Cell>
                      <Table.Cell>
                        <Note>{r.transferCallId}</Note>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>Transfer To</Table.Cell>
                      <Table.Cell>{r.transferTo}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell style={{ minWidth: 170 }}>Transfer Duration (ms)</Table.Cell>
                      <Table.Cell>{r.transferDurationMs}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>Transfer Started At</Table.Cell>
                      <Table.Cell>{format(new Date(r.transferStartedAt), 'PPPppp')}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>Transfer Ended At</Table.Cell>
                      <Table.Cell>{format(new Date(r.transferEndedAt), 'PPPppp')}</Table.Cell>
                    </Table.Row>
                  </>
                )}

                <Table.Row>
                  <Table.Cell>Disposition</Table.Cell>
                  <Table.Cell>{r.disposition && <Label>{r.disposition}</Label>}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Direction</Table.Cell>
                  <Table.Cell>
                    <Label>{r.direction}</Label>
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Voice Config</Table.Cell>
                  <Table.Cell>{r.voiceConfigName}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Conversation</Table.Cell>
                  <Table.Cell>{r.conversationName}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Scorecard ID</Table.Cell>
                  <Table.Cell>
                    <Note>{r.scorecardId}</Note>
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Source</Table.Cell>
                  <Table.Cell>
                    <Label>{r.source}</Label>
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Call Duration (ms)</Table.Cell>
                  <Table.Cell>{r.durationMs}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Dialog Duration (ms)</Table.Cell>
                  <Table.Cell>{r.dialogDurationMs}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell style={{ minWidth: 160 }}>Speech Duration (ms)</Table.Cell>
                  <Table.Cell>{r.speechDurationMs}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Recording ID</Table.Cell>
                  <Table.Cell>
                    <Note>{r.recordingId}</Note>
                  </Table.Cell>
                </Table.Row>

                {r.recordingDuration > 0 && (
                  <Table.Row>
                    <Table.Cell>Recording Duration</Table.Cell>
                    <Table.Cell>{r.recordingDuration}</Table.Cell>
                  </Table.Row>
                )}

                <Table.Row>
                  <Table.Cell>Recording</Table.Cell>
                  <Table.Cell>
                    <audio controls src={r.recordingUrl} style={{ display: 'block', height: '3rem', width: '100%' }} />
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Recording Storage Path</Table.Cell>
                  <Table.Cell>
                    <Note>{r.recordingStoragePath}</Note>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>

          {!!r.transcript && (
            <div style={{ display: 'flex', flex: 2, flexDirection: 'column' }}>
              <Header>Transcript</Header>
              <Segment style={{ flex: 1, marginTop: 0 }}>
                <pre style={{ margin: 0, overflowY: 'auto' }}>{r.transcript}</pre>
              </Segment>
            </div>
          )}
        </Row>
      </Modal.Content>
    </Modal>
  );
};

export default VoiceCallDetailsModal;
