import { Helmet } from 'react-helmet-async';

import Breadcrumbs from 'src/components/Breadcrumbs';
import PaginatedTable from 'src/components/PaginatedTable';
import { Container, Header, Row } from 'src/styles';
import AdminBillingLeadscorePlusTransactionsListBody from './AdminBillingLeadscorePlusTransactionsListBody';

const AdminAccountsBillingLeadscorePlusHistory = () => {
  return (
    <Container>
      <Helmet>
        <title>Billing LeadScore+ History - Accounts - Admin | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header style={{ marginBottom: 0 }}>
          <Breadcrumbs crumbs={['Admin', 'Accounts', 'Billing', 'LeadScore+ History']} />
        </Header>
      </Row>

      <PaginatedTable
        headers={['Details', 'Created At', 'Amount', '']}
        renderBody={AdminBillingLeadscorePlusTransactionsListBody}
      />
    </Container>
  );
};

export default AdminAccountsBillingLeadscorePlusHistory;
