import { useCallback, useRef, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';

import { useFlushNonConnectsMutation } from 'src/api/auth/account-twilio';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';
import { TwilioStudioFlow } from 'src/types';

type Props = {
  flow: TwilioStudioFlow;
};

const FlushNonConnects = ({ flow }: Props) => {
  const modalRef = useRef(null);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync, isLoading } = useFlushNonConnectsMutation();

  const onCancel = () => {
    if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
      // @ts-ignore
      modalRef.current.handleClose();
    }
  };

  const onClose = () => {
    setApiMessage(undefined);
  };

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    try {
      const res = await mutateAsync({ flowSid: flow.sid });
      setApiMessage({ success: true, message: `${res.deleteCount} records deleted successfully.`, status: 200 });
      setTimeout(() => {
        setApiMessage(undefined);
        onCancel();
      }, 3000);
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [mutateAsync, flow.sid]);

  return (
    <Modal
      size="mini"
      ref={modalRef}
      trigger={
        <Button type="button" color="red">
          Flush Non-Connects
        </Button>
      }
      onClose={onClose}
    >
      <Modal.Header>Flush Non-Connects from {flow.friendly_name}?</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        {!apiMessage?.success ? (
          <Form onSubmit={onSubmit}>
            <p style={{ marginTop: 0 }}>
              <strong>Are you sure?</strong> This action cannot be undone.
            </p>

            <Row>
              <Button fluid type="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button color="red" fluid loading={isLoading}>
                Confirm
              </Button>
            </Row>
          </Form>
        ) : (
          <Button color="blue" fluid onClick={onClose}>
            Done
          </Button>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default FlushNonConnects;
