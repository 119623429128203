import { Helmet } from 'react-helmet-async';

import Breadcrumbs from 'src/components/Breadcrumbs';
import PaginatedTable from 'src/components/PaginatedTable';
import { Container, Header, Row } from 'src/styles';
import AdminModelsListBody from './AdminModelsListBody';
import RefreshModels from './RefreshModels';

const AdminModelsList = () => {
  return (
    <Container>
      <Helmet>
        <title>Models - Admin | datascore</title>
      </Helmet>

      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Header style={{ marginBottom: 0 }}>
          <Breadcrumbs crumbs={['Admin', 'Models']} />
        </Header>
        <RefreshModels />
      </Row>

      <PaginatedTable
        headers={['Status', 'Display Name', 'Source', 'Type', 'Name', 'Versions', '']}
        renderBody={AdminModelsListBody}
      />
    </Container>
  );
};

export default AdminModelsList;
