import { FC } from 'react';

import { BigQueryNode, BigQueryOverlay } from './BigQuery';
import { BlacklistNode, BlacklistOverlay } from './Blacklist';
import { CombineUnionNode, CombineUnionOverlay } from './Combine';
import { FilterNode, FilterOverlay } from './Filter';
import { OutputNode, OutputOverlay } from './Output';
import { OverlayProps } from './types';

export const Nodes = {
  bigquery: BigQueryNode,
  blacklist: BlacklistNode,
  filter: FilterNode,
  _output: OutputNode,
  // combine_join: CombineJoinNode,
  combine_union: CombineUnionNode,
};

export type NodesKeys = keyof typeof Nodes;

export const Overlays: { [key in NodesKeys]: FC<OverlayProps> } = {
  bigquery: BigQueryOverlay,
  blacklist: BlacklistOverlay,
  filter: FilterOverlay,
  _output: OutputOverlay,
  // combine_join: CombineJoinOverlay,
  combine_union: CombineUnionOverlay,
};
