import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Placeholder, PlaceholderParagraph, Table } from 'semantic-ui-react';

import { useListBlacklistsQuery } from 'src/api/blacklists';
import { RenderProps } from 'src/components/PaginatedTable';
import theme from 'src/styles/theme';
import BlacklistAddModal from './BlacklistAddModal';

const blacklistApiUrl = process.env.REACT_APP_BLACKLIST_API_URL;

const getBlacklistUrl = (hash: string | null): string => {
  if (!hash) return '';
  return `${blacklistApiUrl}/api/lists/${hash}`;
};

const PlaceholderRow = () => (
  <Table.Row>
    <Table.Cell collapsing>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
  </Table.Row>
);

const ListBlacklistsBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: blacklists, isLoading } = useListBlacklistsQuery({ limit, offset: limit * (currentPage - 1) });

  useEffect(() => {
    let count = 1;
    if (typeof blacklists !== 'undefined') {
      count = Math.ceil(blacklists.total / limit);
    }
    setPageCount(count);
  }, [blacklists, limit, setPageCount]);

  if (isLoading) {
    return <PlaceholderRow />;
  }

  if (!blacklists?.data.length) {
    return (
      <Table.Row>
        <Table.Cell colSpan={5}>
          <span style={{ color: theme.gray }}>No blacklists found</span>
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      {blacklists?.data.map(b => (
        <Table.Row key={b.id}>
          <Table.Cell>{b.id}</Table.Cell>
          <Table.Cell>{b.name}</Table.Cell>
          <Table.Cell>{getBlacklistUrl(b.hash)}</Table.Cell>
          <Table.Cell textAlign="right" collapsing>
            {!!b.hash && (
              <>
                <BlacklistAddModal blacklist={b} />
                <Link to={{ pathname: `/blacklists/${b.id}/docs` }}>
                  <Button as="div" color="blue" icon title="View API Docs">
                    <Icon name="book" /> API Docs
                  </Button>
                </Link>
              </>
            )}
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default ListBlacklistsBody;
