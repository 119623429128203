import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, Form } from 'semantic-ui-react';

import { UpdateAccountSendGridInput, useUpdateAccountSendGridMutation } from 'src/api/auth/account-sendgrid';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { SendgridProfile } from 'src/types';

type Props = {
  profile?: SendgridProfile;
};

type ValidationErrors = {
  apiKey?: string;
};

const getInitialFormdata = (profile?: SendgridProfile): UpdateAccountSendGridInput => {
  return {
    apiKey: profile?.apiKey || '',
  };
};

const SendGridCredentialsForm = ({ profile }: Props) => {
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [formdata, setFormdata] = useState<UpdateAccountSendGridInput>(() => getInitialFormdata(profile));
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { mutateAsync, isLoading: mutationIsLoading } = useUpdateAccountSendGridMutation();

  const validate = useCallback((input: UpdateAccountSendGridInput): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    // if (!input.apiKey) {
    //   validationErrors.apiKey = 'api key is required';
    // }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onChange = useCallback((_, { name, value }) => setFormdata(prev => ({ ...prev, [name]: value })), []);

  const onSubmit = useCallback(async () => {
    if (!isEmpty(validate(formdata))) {
      return;
    }

    try {
      await mutateAsync(formdata);
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [mutateAsync, formdata, validate]);

  return (
    <Form onSubmit={onSubmit}>
      <ApiMessage data={apiMessage} />

      <Form.Input label="API Key" name="apiKey" value={formdata.apiKey} onChange={onChange} error={errors.apiKey} />

      <Button color="blue" loading={mutationIsLoading}>
        Update
      </Button>
    </Form>
  );
};

export default SendGridCredentialsForm;
