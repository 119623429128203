import { useCallback, useRef, useState } from 'react';
import { Button, Form, FormProps, Icon, Modal } from 'semantic-ui-react';

import { useDeleteUserInviteMutation } from 'src/api/admin/user-invites';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { Row } from 'src/styles';

type Props = {
  id: number;
};

const DeleteUserInvite = ({ id }: Props) => {
  const modalRef = useRef(null);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { isLoading, mutateAsync } = useDeleteUserInviteMutation();

  const onCancel = () => {
    if (typeof modalRef.current !== 'undefined' && modalRef.current !== null) {
      // @ts-ignore
      modalRef.current.handleClose();
    }
  };

  const onClose = () => {
    setApiMessage(undefined);
  };

  const onSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>, data: FormProps): Promise<void> => {
      setApiMessage(undefined);

      try {
        const data = await mutateAsync(id);

        if (!data.success) {
          setApiMessage({ success: false, status: 400, message: data.message || 'Unknown Error' });
          return;
        }

        onCancel();
      } catch (e: any) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [id, mutateAsync]
  );

  return (
    <Modal
      size="mini"
      ref={modalRef}
      trigger={
        <Button color="red" icon title="Delete">
          <Icon name="trash" />
        </Button>
      }
      onClose={onClose}
    >
      <Modal.Header>Delete Invite</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <p style={{ marginTop: 0 }}>
            <strong>Are you sure?</strong> This action cannot be undone.
          </p>

          <Row>
            <Button fluid type="button" onClick={onCancel}>
              Cancel
            </Button>
            <Button color="red" fluid loading={isLoading}>
              Confirm
            </Button>
          </Row>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default DeleteUserInvite;
