import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { AddBigqueryTableInput, useAddBigqueryTableMutation } from 'src/api/admin/bigquery';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { BigqueryDataset } from 'src/types';

type Props = {
  dataset: BigqueryDataset;
};

type ValidationErrors = {
  table_id?: string;
  name?: string;
  time_column_name?: string;
};

const AddTable = ({ dataset }: Props) => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const { isLoading, mutateAsync } = useAddBigqueryTableMutation();
  const [formdata, setFormdata] = useState<AddBigqueryTableInput>({
    table_id: '',
    name: '',
    time_column_name: '',
  });

  const handleClose = useCallback(() => {
    setApiMessage(undefined);
    setFormdata({ table_id: '', name: '', time_column_name: '' });
    setErrors({});
    setOpen(false);
  }, []);

  const onChange = useCallback((_, { name, value }) => {
    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  const onSubmit = async () => {
    setApiMessage(undefined);

    const validationErrors: ValidationErrors = {};

    if (formdata.table_id === '') {
      validationErrors.table_id = 'Table ID is required';
    } else if (formdata.table_id.split('.').length !== 3) {
      validationErrors.table_id = 'Invalid format for Table ID';
    } else if (formdata.table_id.slice(0, dataset.dataset_id.length) !== dataset.dataset_id) {
      validationErrors.table_id = 'That table does not belong to this dataset';
    }

    if (formdata.name === '') {
      validationErrors.name = 'Name is required';
    }

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors);
      return;
    }

    try {
      await mutateAsync({ datasetId: dataset.id, ...formdata });
      handleClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={handleClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button color="blue">
          <Icon name="plus" />
          Add Table
        </Button>
      }
    >
      <Modal.Header>Add Table</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Form.Input
            label="Table ID"
            placeholder={`${dataset.dataset_id}.table_name`}
            error={errors.table_id}
            name="table_id"
            value={formdata.table_id}
            onChange={onChange}
          />

          <Form.Input
            label="Display Name"
            placeholder="CLIENT_NAME Call Logs (Realtime)"
            error={errors.name}
            name="name"
            value={formdata.name}
            onChange={onChange}
          />

          {/* TODO: load a list of columns to pick from using bigquery api */}
          {/* TODO: auto complete to one of known column list? */}
          <Form.Input
            label="Time Column"
            error={errors.time_column_name}
            name="time_column_name"
            value={formdata.time_column_name}
            onChange={onChange}
          />

          <Button loading={isLoading} color="blue" fluid>
            Create
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AddTable;
