import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { Filter, FilterType, PaginatedResponse } from '../types';
import { ApiResponse, http } from './http-common';

export type FilterResponse = ApiResponse & {
  filter?: Filter;
};

export type FiltersResponse = ApiResponse & {
  filters: PaginatedResponse<Filter>;
};

export type AddFilterInput = {
  name: string;
  type: FilterType;
  payload: any;
};

export const useAddFilterMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: AddFilterInput) => {
      const res = await http.post<FilterResponse>('/api/filters', input);
      return res.data.filter;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['filters']);
      },
    }
  );
};

export const useDeleteFilterMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await http.delete<FilterResponse>(`/api/filters/${id}`);
      return res.data.filter;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['filters']);
      },
    }
  );
};

export type EditFilterFormdata = {
  name: string;
  type: FilterType;
  payload: any;
};

export type EditFilterInput = EditFilterFormdata & {
  id: number;
};

export const useEditFilterMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, ...input }: EditFilterInput) => {
      const res = await http.post<FilterResponse>(`/api/filters/${id}`, input);
      return res.data.filter;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['filters']);
      },
    }
  );
};

export type ListFiltersInput = {
  limit?: number;
  offset?: number;
};

export const useListFiltersQuery = (params: ListFiltersInput) => {
  return useQuery(['filters', params], async () => {
    const res = await http.get<FiltersResponse>('/api/filters', { params });
    return res.data.filters;
  });
};
