import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Placeholder, PlaceholderParagraph, Table } from 'semantic-ui-react';

import { useListLeadscoreConfigsQuery } from 'src/api/leadscore-plus';
import { RenderProps } from 'src/components/PaginatedTable';
import theme from 'src/styles/theme';
import DeleteLeadscoreConfig from './DeleteLeadscoreConfig';
import DuplicateLeadscoreConfig from './DuplicateLeadscoreConfig';
import ToggleLeadscoreConfigEnabledButton from './ToggleLeadscoreConfigEnabledButton';

const PlaceholderRow = () => (
  <Table.Row>
    <Table.Cell collapsing>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Placeholder>
        <PlaceholderParagraph>
          <Placeholder.Line />
        </PlaceholderParagraph>
      </Placeholder>
    </Table.Cell>
  </Table.Row>
);

const ListLeadscoreConfigsBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: configs, isLoading } = useListLeadscoreConfigsQuery({ limit, offset: limit * (currentPage - 1) });

  useEffect(() => {
    let count = 1;
    if (typeof configs !== 'undefined') {
      count = Math.ceil(configs.total / limit);
    }
    setPageCount(count);
  }, [configs, limit, setPageCount]);

  if (isLoading) {
    return <PlaceholderRow />;
  }

  if (!configs?.data.length) {
    return (
      <Table.Row>
        <Table.Cell colSpan={5}>
          <span style={{ color: theme.gray }}>No configs found</span>
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      {configs?.data.map(c => (
        <Table.Row key={c.id}>
          <Table.Cell collapsing>
            <ToggleLeadscoreConfigEnabledButton config={c} />
          </Table.Cell>
          <Table.Cell>
            <Link to={{ pathname: `/leadscore-plus/configs/${c.id}` }}>
              <strong>{c.name}</strong>
            </Link>
            <span style={{ display: 'block', color: theme.gray, fontSize: '0.8rem', lineHeight: '1rem' }}>{c.id}</span>
          </Table.Cell>
          <Table.Cell textAlign="right" collapsing>
            <DuplicateLeadscoreConfig id={c.id} />
            <Link to={{ pathname: `/leadscore-plus/configs/${c.id}` }}>
              <Button as="div" color="blue" icon title="Edit config">
                <Icon name="pencil" />
              </Button>
            </Link>
            <DeleteLeadscoreConfig id={c.id} />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default ListLeadscoreConfigsBody;
