import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ReportingTimeRange } from 'src/types';
import { AccountResponse } from '../accounts';
import { ApiResponse, http } from '../http-common';

export type UpdateAccountBandwidthInput = {
  id: number;
  sub_account_id: string;
  purchase_limit: number;
};

export const useUpdateAccountBandwidthMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, ...params }: UpdateAccountBandwidthInput) => {
      const res = await http.post<AccountResponse>(`/api/admin/accounts/${id}/bandwidth-profile`, params);
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['admin/accounts', account.id]);
        queryClient.invalidateQueries(['accounts', account.id]);
        queryClient.invalidateQueries(['accounts']);
        queryClient.invalidateQueries(['auth/profile']);
        queryClient.invalidateQueries(['auth/account']);
      },
    }
  );
};

export const useCreateBandwidthSubAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await http.post<AccountResponse>(`/api/admin/accounts/${id}/create-bandwidth-subaccount`);
      return res.data.account;
    },
    {
      onSuccess: account => {
        if (!account) return;
        queryClient.invalidateQueries(['admin/accounts', account.id]);
        queryClient.invalidateQueries(['accounts', account.id]);
        queryClient.invalidateQueries(['accounts']);
        queryClient.invalidateQueries(['auth/profile']);
        queryClient.invalidateQueries(['auth/account']);
      },
    }
  );
};

export type BandwidthCallStatsResponse = ApiResponse & {
  stats?: {
    completed: number;
    busy: number;
    failed: number;
    no_answer: number;
    avg_duration: number;
    less_than_6s: number;
    greater_than_30s: number;
  };
};

export type BandwidthCallStatsParams = {
  directions?: string[];
  statuses?: string[];
  timeRange: ReportingTimeRange;
  startDate: string;
  endDate: string;
};

export const useGetAccountBandwidthCallStatsQuery = (id: number, params: BandwidthCallStatsParams) => {
  return useQuery(['admin/accounts', id, 'bandwidth-call-stats', params], async () => {
    const res = await http.get<BandwidthCallStatsResponse>(`/api/admin/accounts/${id}/bandwidth-call-stats`, {
      params,
    });
    return res.data.stats;
  });
};
