import { isEmpty } from 'lodash';
import { ChangeEvent, useCallback, useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { useAddDataflowMutation } from 'src/api/dataflows';

type ValidationErrors = {
  name?: string;
};

const AddDataflow = () => {
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState({ name: '' });
  const { mutateAsync: addDataflow, isLoading } = useAddDataflowMutation();

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      setFormdata({ ...formdata, [name]: value });
    },
    [setFormdata, formdata]
  );

  const onSubmit = async () => {
    const validationErrors: ValidationErrors = {};

    if (formdata.name === '') {
      validationErrors.name = 'Name is required';
    }

    if (!isEmpty(validationErrors)) {
      setErrors(validationErrors);
      return;
    }

    await addDataflow(formdata);
    setOpen(false);
  };

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        <Button floated="right" color="blue">
          <Icon name="plus" />
          Add Dataflow
        </Button>
      }
    >
      <Modal.Header>Add Dataflow</Modal.Header>
      <Modal.Content>
        <Form onSubmit={onSubmit} onChange={onChange}>
          <Form.Input label="Name" error={errors.name} name="name" />

          <Form.Button loading={isLoading} color="blue" fluid>
            Create
          </Form.Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AddDataflow;
